/**
 * Generic component for multiple choice questions with custom labels
 */

import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { CustomTextField } from '../../../../../components';
import { ValidationErrorNotice } from '../../../../../components/Notice';
import { AddListItemButton } from '../../../../../components/SelectableTile';
import Subheader from '../../../../../components/Subheader';
import DebouncedInput from '../../../../../edit/DebouncedInput';
import {
  AnchorLabels,
  CustomQuestionTypeOptions,
  CustomQuestionTypes,
  RandomizationTypes,
} from '../../../constants';
import { serializeErrors } from '../../../helpers';
import AdditionalCustomChoices from '../shared/AdditionalCustomChoices';
import { MultipleChoiceCustomChoice } from '../shared/CustomChoice';

function MultipleChoiceConfiguration({
  wevo,
  customQuestion,
  showHeading = true,
  onCustomQuestionChanged,
  errors,
}) {
  const [deleteCounter, setDeleteCounter] = useState(0);

  const type = customQuestion?.question?.type;
  const labelsType = customQuestion?.question?.labelsType;
  const questionTypeLimits = CustomQuestionTypeOptions.find((option) => option.value === type)?.choiceLimits;

  const defaultChoices = questionTypeLimits?.default || 0;
  const minChoices = questionTypeLimits?.min || 0;
  const maxChoices = questionTypeLimits?.max || 0;
  const anchorChoices = customQuestion?.question?.randomization?.anchorChoices || [];
  const exclusiveChoices = customQuestion?.question?.randomization?.exclusiveChoices || [];

  const labels =
    customQuestion?.question?.labels || new Array(defaultChoices).fill(defaultChoices).map((_) => '');
  const nonAnchoredLabels = labels?.filter((label, index) => !anchorChoices.includes(index));
  const anchoredLabels = labels?.filter((label, index) => anchorChoices.includes(index));

  const otherChoiceIndex = anchorChoices?.find((choice) => !exclusiveChoices.includes(choice)) ?? null;
  const noneOfTheAboveChoiceIndex = exclusiveChoices?.[0] ?? null;

  const canAddOptions = labels.length < maxChoices;
  const canAddOtherChoice = [CustomQuestionTypes.MultipleChoice].includes(type);
  const canAddNoneChoice = [CustomQuestionTypes.MultipleChoice].includes(type);
  const canRandomizeChoices = [CustomQuestionTypes.MultipleChoice, CustomQuestionTypes.RankOrder].includes(
    type
  );

  const isDeleteable = [CustomQuestionTypes.MultipleChoice, CustomQuestionTypes.RankOrder].includes(type);
  // this condition should only be true for actual multiple choice questions, not custom scale labels
  const canDeleteChoices = minChoices < maxChoices && labels.length > minChoices;

  const isRandomized = customQuestion?.question?.randomization?.type === RandomizationTypes.All;

  const hasEmptyValues = (items = []) => {
    return items?.some((item) => item?.length < 1);
  };

  const handleChoiceAdded = (newLabel = '') => {
    let updatedLabels = [...nonAnchoredLabels];
    let updatedAnchorChoices = [...anchorChoices];
    let updatedExclusiveChoices = [...exclusiveChoices];

    // add a regular label
    if (newLabel === '') {
      updatedLabels.push(newLabel);

      // since anchored and exclusive labels are at the end, we include the labels in updatedLabels
      // and shift the indexes of the items in the updatedAnchorChoices by 1
      if (!isEmpty(updatedAnchorChoices)) {
        updatedAnchorChoices.forEach((index) => updatedLabels.push(labels[index]));
        updatedAnchorChoices = updatedAnchorChoices.map((anchorIndex) => anchorIndex + 1);
      }
      if (!isEmpty(updatedExclusiveChoices)) {
        updatedExclusiveChoices = updatedExclusiveChoices.map((exclusiveIndex) => exclusiveIndex + 1);
      }
    }

    //add other label
    if (newLabel === AnchorLabels.Other) {
      updatedLabels.push(newLabel);
      const otherIndex = updatedLabels.length - 1;
      updatedAnchorChoices = updatedAnchorChoices?.map((anchorIndex) => anchorIndex + 1);
      // add index to the beginning of the array of anchored choices
      updatedAnchorChoices.unshift(otherIndex);
      // update index of exclusive item
      if (!isEmpty(updatedExclusiveChoices)) {
        updatedLabels.push(labels[updatedExclusiveChoices[0]]);
        updatedExclusiveChoices[0] = updatedExclusiveChoices[0] + 1;
      }
    }
    // add none of the above label
    if (newLabel === AnchorLabels.NoneOfTheAbove) {
      updatedLabels = [...labels, newLabel];

      const noneIndex = updatedLabels.length - 1;
      updatedAnchorChoices.push(noneIndex);
      updatedExclusiveChoices.push(noneIndex);
    }

    onCustomQuestionChanged({
      customQuestion,
      updateFields: {
        labels: updatedLabels,
        labelsType,
        randomization: {
          type: isRandomized ? RandomizationTypes.All : RandomizationTypes.None,
          anchorChoices: updatedAnchorChoices,
          exclusiveChoices: updatedExclusiveChoices,
        },
      },
    });
  };

  const handleChoiceChanged = (newValue, oldValue, index) => {
    const newLabels = [...labels];
    newLabels[index] = newValue;

    onCustomQuestionChanged({
      customQuestion,
      updateFields: { labels: newLabels, labelsType },
    });
  };

  const handleChoiceRemoved = (choice, index) => {
    const newLabels = labels.slice(0, index).concat(labels.slice(index + 1));
    let updatedAnchorChoices = [...anchorChoices];
    let updatedExclusiveChoices = [...exclusiveChoices];

    // this assumes that the exclusive choice is anchored and is the last label
    // remove the index from both the exclusive choices and anchor choices
    if (updatedExclusiveChoices?.includes(index)) {
      updatedExclusiveChoices.pop(index);
      updatedAnchorChoices.pop(index);
    }
    // if the choice being removed is an anchored choice, remove it from the array of anchored choices
    // and update the anchored choices and exclusiveChoices by shifting the indexes by -1
    else if (updatedAnchorChoices?.includes(index)) {
      updatedAnchorChoices = updatedAnchorChoices
        ?.filter((choiceIndex) => choiceIndex !== index)
        ?.map((choiceIndex) => choiceIndex - 1);
      updatedExclusiveChoices = updatedExclusiveChoices?.map((exclusiveIndex) => exclusiveIndex - 1);
    }
    // update the anchored choices and exclusiveChoices by shifting the indexes by -1
    else {
      updatedAnchorChoices = updatedAnchorChoices?.map((anchorIndex) => anchorIndex - 1);
      updatedExclusiveChoices = updatedExclusiveChoices?.map((exclusiveIndex) => exclusiveIndex - 1);
    }

    onCustomQuestionChanged({
      customQuestion,
      updateFields: {
        labels: newLabels,
        labelsType,
        randomization: {
          type: isRandomized ? RandomizationTypes.All : RandomizationTypes.None,
          anchorChoices: updatedAnchorChoices,
          exclusiveChoices: updatedExclusiveChoices,
        },
      },
    });

    setDeleteCounter(deleteCounter + 1);
  };

  const handleRandomizationChanged = (isChecked) => {
    const type = isChecked ? RandomizationTypes.All : RandomizationTypes.None;
    const otherParams = type !== CustomQuestionTypes.RankOrder ? { anchorChoices, exclusiveChoices } : {};

    onCustomQuestionChanged({
      customQuestion,
      updateFields: {
        randomization: {
          type,
          ...otherParams,
        },
      },
    });
  };

  return (
    <Box>
      {showHeading && <Subheader name={'Enter your custom choices'} />}
      <Box my={2}>
        {nonAnchoredLabels.map((label, index) => (
          /**
            It's VERY IMPORTANT that the key changes when the label changes and that we include the
            index so that there are no key collisions. This is because the underlying choice text field
            uses internal state and debounces updates. A consequence of this is that if not remounted, the
            internal state will not match the new label state. Unfortunately, this trades off performance
            on deletes, since we're remounting many times when a choice gets deleted
          */
          <Box key={`${index}_${deleteCounter}`} mb={1}>
            <MultipleChoiceCustomChoice
              customChoice={label}
              isDeletable={isDeleteable}
              canDeleteChoice={canDeleteChoices}
              handleCustomChoiceChange={(newValue) => handleChoiceChanged(newValue, label, index)}
              handleCustomChoiceDelete={(value) => handleChoiceRemoved(value, index)}
            />
          </Box>
        ))}
        {hasEmptyValues(nonAnchoredLabels) && errors?.labels?.length > 0 && (
          <Box my={1}>
            <ValidationErrorNotice message={serializeErrors(errors.labels)} />
          </Box>
        )}
      </Box>
      {canAddOptions && (
        <Box mt={4}>
          <AddListItemButton width="100%" size="small" onClick={() => handleChoiceAdded('')} />
        </Box>
      )}
      {(canAddOtherChoice || canAddNoneChoice || canRandomizeChoices) && (
        <Box mt={2}>
          <AdditionalCustomChoices
            canAddOtherChoice={canAddOtherChoice}
            canAddNoneChoice={canAddNoneChoice}
            canRandomizeChoices={canRandomizeChoices}
            labels={labels}
            otherChoiceIndex={otherChoiceIndex}
            noneOfTheAboveChoiceIndex={noneOfTheAboveChoiceIndex}
            isRandomized={isRandomized}
            handleChoiceAdded={handleChoiceAdded}
            handleChoiceRemoved={handleChoiceRemoved}
            handleChoiceChanged={handleChoiceChanged}
            handleRandomizationChanged={handleRandomizationChanged}
          />
          {hasEmptyValues(anchoredLabels) && errors?.labels?.length > 0 && (
            <Box my={1}>
              <ValidationErrorNotice message={serializeErrors(errors.labels)} />
            </Box>
          )}
        </Box>
      )}
      <Box mb={2} />
      <Typography mb={1}>Follow-up question</Typography>
      <DebouncedInput
        value={customQuestion.question.followUpQuestionText || ''}
        onChange={(value) => {
          onCustomQuestionChanged({
            customQuestion: customQuestion,
            updateFields: { followUpQuestionText: value },
          });
        }}
        debounceMs={500}
        renderInput={({ value, onChange }) => (
          <CustomTextField
            value={value}
            sx={{
              '& .MuiInputBase-root': {
                fontSize: 14,
              },
            }}
            onChange={onChange}
          />
        )}
      />
      {errors?.followUpQuestionText?.length > 0 && (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(errors.followUpQuestionText)} />
        </Box>
      )}
    </Box>
  );
}

export default MultipleChoiceConfiguration;
