import { Typography } from '@mui/material';
import { MetricTypes, MetricTypeToText } from '../../../../modules/intake/constants';
import IntakeModal from '../../components/IntakeModal';

const getMetricTextFromType = (metricType) => {
  switch (metricType) {
    case MetricTypes.MastercardCds:
      return MetricTypeToText.CDS;
    case MetricTypes.MastercardDqs:
      return MetricTypeToText.DQS;
    default:
      return MetricTypeToText.Wevo;
  }
};

function DiscardChangesModal({ oldMetricType, newMetricType, ...rest }) {
  const oldMetricTypeText = getMetricTextFromType(oldMetricType);
  const newMetricTypeText = getMetricTextFromType(newMetricType);
  const content = (
    <Typography fontSize={13}>
      Switching between {oldMetricTypeText} and {newMetricTypeText} may delete certain details of the test that
      are not included in the {newMetricTypeText} test type. Is that okay?
    </Typography>
  );
  return <IntakeModal title="Switching UX Metric" content={content} {...rest} />;
}

export default DiscardChangesModal;
