import { Typography } from '@mui/material';
import { AudienceCategory } from '../../../../../../modules/wevos/constants';
import SurveySectionPlaceholder from '../survey-elements/SurveySectionPlaceholder';

function ConsumerDemographicsPreview() {
  return (
    <SurveySectionPlaceholder title="Demographics Block">
      <Typography>Respondents are asked 10-15 demographic classification questions.</Typography>
    </SurveySectionPlaceholder>
  );
}

function BusinessDemographicsPreview() {
  return (
    <SurveySectionPlaceholder title="Demographics Block">
      <Typography>
        Respondents are asked 12-17 demographic and firmographic classification questions.
      </Typography>
    </SurveySectionPlaceholder>
  );
}

function DemographicsPreview({ wevo }) {
  if (String(wevo?.audienceCategory?.id) === String(AudienceCategory.B2B.id)) {
    return <BusinessDemographicsPreview />;
  }

  return <ConsumerDemographicsPreview />;
}

export default DemographicsPreview;
