import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { default as React, useMemo } from 'react';
import { WevoTestType } from '../../../../../modules/wevos/constants';
import { serializeErrors } from '../../audience/helpers/validation';
import { ValidationKeys } from '../../helpers/validation/assets';
import { isCustomSurveyType } from '../../helpers/wevo';
import AlertMessage from '../shared/AlertMessage';
import FieldRow from '../shared/FieldRow';
import Assets from './Assets';

const useStyles = makeStyles((theme) => ({
  fieldText: {
    marginLeft: theme.spacing(1.8),
    marginTop: theme.spacing(1.5),
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
    color: '#212A37',
  },
  assets: {
    marginLeft: theme.spacing(1.8),
    marginTop: theme.spacing(1.5),
  },
}));

const BuildSection = ({ wevo, assetErrors }) => {
  const classes = useStyles();

  const isCustomSurvey = isCustomSurveyType(wevo);
  const isCompare = Boolean(wevo?.testType === WevoTestType.Compare);
  const numRespondents = wevo?.numTargetedRespondentsPerPage;

  const features = [
    'Sentiment Map(s)',
    'Expectations',
    'Qualitative Insights',
    'Diagnostics',
    'Key Findings',
    'Custom Questions',
  ];
  const includeFeatures = useMemo(() => {
    return {
      'Sentiment Map(s)': wevo?.details?.includeSentimentMaps ?? true,
      Expectations: wevo?.details?.includeExpectations ?? true,
      'Qualitative Insights': wevo?.details?.includeQualitativeExpectations ?? true,
      Diagnostics: wevo?.details?.includeDiagnostics ?? true,
      'Key Findings': wevo?.details?.includeKeyFindings ?? true,
      'Custom Questions': wevo?.details?.includeCustomQuestions ?? true,
    };
  }, [wevo?.details]);

  const wevoAssetErrors = assetErrors?.wevoErrors?.[String(wevo?.id)] ?? {};

  const pageLimitExceededErrors = wevoAssetErrors?.[ValidationKeys.PageLimitExceeded];
  const insufficientPagesErrors = wevoAssetErrors?.[ValidationKeys.InsufficientPages];
  const hasPageAssetErrors = pageLimitExceededErrors?.length > 0 || insufficientPagesErrors?.length > 0;

  return (
    <>
      {/* Sample size */}
      <FieldRow label={'Sample size'} divider={true}>
        <Typography className={classes.fieldText}>
          {numRespondents ? `${numRespondents} respondents` : 'N/A'}
        </Typography>
      </FieldRow>
      {/* Includes */}
      <FieldRow label={'Includes'} divider={true}>
        {features?.map(
          (feature, index) =>
            includeFeatures[feature] && (
              <Typography
                className={classes.fieldText}
                key={index}
                sx={{ paddingLeft: feature === 'Qualitative Insights' ? 2 : 0 }}>
                {feature === 'Qualitative Insights' ? `- With ${feature}` : feature}
              </Typography>
            )
        )}
      </FieldRow>
      {/* Compare */}
      {!isCustomSurvey && (
        <FieldRow label={'Compare'} divider={true}>
          <Typography className={classes.fieldText}>{isCompare ? 'Yes' : 'No'}</Typography>
        </FieldRow>
      )}
      {/* Asset(s) */}
      {(!isCustomSurvey || (isCustomSurvey && hasPageAssetErrors)) && (
        <FieldRow label={'Asset(s)'}>
          {pageLimitExceededErrors?.length > 0 && (
            <AlertMessage message={serializeErrors(pageLimitExceededErrors)} />
          )}

          {insufficientPagesErrors?.length > 0 && (
            <AlertMessage message={serializeErrors(insufficientPagesErrors)} />
          )}
          {!isCustomSurvey && (
            <Box className={classes.assets}>
              {(wevo?.pages ?? []).map((page) => (
                <Box key={page.id} mb={2}>
                  {assetErrors?.pageErrors?.[String(page.id)]?.[ValidationKeys.Name] ? (
                    <Box mb={1}>
                      <AlertMessage
                        message={serializeErrors(assetErrors.pageErrors[String(page.id)][ValidationKeys.Name])}
                      />
                    </Box>
                  ) : (
                    <Typography fontSize={13} mb={1}>
                      {page.name}
                    </Typography>
                  )}
                  <Assets draft={wevo} page={page} errors={assetErrors} />
                </Box>
              ))}
            </Box>
          )}
        </FieldRow>
      )}
    </>
  );
};

export default BuildSection;
