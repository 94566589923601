import styled from '@emotion/styled';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { CircularProgress, Grid, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CustomTextField } from '../../../../../components';
import DebouncedInput from '../../../../../edit/DebouncedInput';
import OutcomeSelect from './OutcomeSelect';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: grey[600],
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

function CustomChoice({ option, onChange, icon, isDisabled }) {
  return (
    <Grid container direction="row" alignItems="center" sx={{ columnGap: 1 }}>
      <Grid item display="flex" alignItems="center">
        {icon}
      </Grid>
      <Grid item xs>
        <DebouncedInput
          value={option}
          onChange={(value) => onChange(value)}
          debounceMs={500}
          renderInput={({ value, onChange }) => {
            return (
              <CustomTextField
                fullWidth
                type="text"
                variant="outlined"
                value={value}
                placeholder="Please enter an option..."
                onChange={onChange}
                name="question option"
                autoComplete="off"
                disabled={isDisabled}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
}

function CustomChoiceAndOutcome({
  optionAndOutcome,
  questionType,
  onCustomChoiceChange,
  onOutcomeChange,
  onCustomChoiceDelete,
  isDeletable = false,
  isAddingOption,
  isDeletingOption,
  canDeleteChoice = false,
}) {
  return (
    <Grid container direction="row" alignItems="center" sx={{ columnGap: 1 }}>
      <Grid item sx={{ flexGrow: 1 }}>
        <CustomChoice
          option={optionAndOutcome?.optionText}
          onChange={onCustomChoiceChange}
          icon={<RadioButtonUncheckedIcon sx={{ fontSize: 'large', color: '#C7D6DF' }} />}
          isDisabled={isAddingOption || isDeletingOption}
        />
      </Grid>
      <Grid item sx={{ width: '30%', minWidth: '160px' }}>
        <OutcomeSelect
          selectedOutcome={optionAndOutcome?.outcome}
          questionType={questionType}
          isDisabled={isAddingOption || isDeletingOption}
          onChange={onOutcomeChange}
        />
      </Grid>
      {isDeletable && (
        <Grid item>
          <StyledIconButton
            aria-label="delete"
            size="small"
            onClick={() => onCustomChoiceDelete()}
            disabled={!canDeleteChoice || isAddingOption || isDeletingOption}>
            {isDeletingOption ? <CircularProgress size="1rem" /> : <IndeterminateCheckBoxIcon />}
          </StyledIconButton>
        </Grid>
      )}
    </Grid>
  );
}

export default CustomChoiceAndOutcome;
