import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { convertScoresToPercentages, roundPercentages } from '../../../modules/report/helpers';
import { GraphIdContext } from '../dashboard/components/CustomQuestionCard';

/*
 * We added this component for screen readers to be able to read graphs' content
 */
const ChartAccessibility = ({ id, labels, scores, percentages = [], data, numsOfRespondents }) => {
  const content = useMemo(() => {
    if (scores) {
      const roundedPercentages = percentages || roundPercentages(convertScoresToPercentages(scores), 1);
      return `Graph content: ${String(
        labels.map((label, index) => `${label} ${roundedPercentages?.[index]}%`)
      )}`;
    } else if (data) {
      const dataWithPercentages = data?.map((dataset, index) => ({
        ...dataset,
        data: dataset?.data?.map((score) => ((score / numsOfRespondents[index]) * 100).toFixed(1) || 0),
      }));
      return `Graph content: ${String(
        labels.map((label, index) => {
          const barData = dataWithPercentages?.map((dataset) => `${dataset.label} ${dataset?.data?.[index]}%`);
          return `${label} : ${barData}`;
        })
      )}`;
    } else return '';
  }, [labels, scores, percentages, data, numsOfRespondents]);

  return (
    <Grid item sx={{ display: 'none', maxHeight: '0px' }} id={React.useContext(GraphIdContext)}>
      {content}
    </Grid>
  );
};

export default ChartAccessibility;
