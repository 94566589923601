import { Box, Button, Tooltip, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { Link as RouteLink, generatePath } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { getAppBarColor } from '../../helpers';
import {
  AssetBadgeLabelStyle,
  getBadgeLabel,
  isAutomatedInsightSessionShareLink,
} from '../../modules/automated-insights/helpers';
import { Paths } from '../../routes';
import { TrackEvent } from '../analytics';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ReactComponent as OpenLinkIcon } from '../../assets/pulse-open-link.svg';
import AssetBadge from './AssetBadge';

export default function LinkedSessionsDropdown({ session, buttonStyles, menuPaperStyles }) {
  const { track } = useAnalytics();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);

  return (
    <>
      <Tooltip title={session?.name} placement="bottom-start">
        <Button
          variant="text"
          id="session-select-button"
          aria-controls={dropdownOpen ? 'session-select-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={dropdownOpen ? 'true' : undefined}
          sx={{
            textTransform: 'none',
            justifyContent: 'flex-start',
            maxWidth: '100%',
            padding: '4px 12px',
            ...(buttonStyles ?? {}),
          }}
          disableRipple
          onClick={(ev) => {
            setDropdownOpen(!dropdownOpen);
            setDropdownAnchorEl(ev.currentTarget);
          }}>
          <Typography
            component="span"
            color="primary"
            fontWeight={600}
            sx={{
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              textWrap: 'nowrap',
            }}>
            {session?.name}
          </Typography>
          <Typography component="span" pl={2} color="primary">
            {dropdownOpen ? (
              <ExpandLessIcon sx={{ verticalAlign: 'middle' }} />
            ) : (
              <ExpandMoreIcon sx={{ verticalAlign: 'middle' }} />
            )}
          </Typography>
        </Button>
      </Tooltip>
      <Menu
        id="session-select-menu"
        MenuListProps={{
          'aria-labelledby': 'session-select-button',
        }}
        PaperProps={{
          sx: { background: getAppBarColor('dark'), ...(menuPaperStyles ?? {}) },
        }}
        slotProps={{ root: { style: { zIndex: 9999 } } }}
        open={dropdownOpen}
        anchorEl={dropdownAnchorEl}
        onClose={() => {
          setDropdownOpen(false);
          setDropdownAnchorEl(null);
        }}>
        {(session?.linkedSessions ?? []).map((linkedSession, index) => {
          const isShareLink = isAutomatedInsightSessionShareLink(session?.id);
          const sessionId = isShareLink ? linkedSession.shareId : linkedSession.id;

          return (
            <RouteLink
              key={linkedSession.id}
              to={generatePath(Paths.automatedInsights.session, {
                sessionId,
              })}
              onClick={() => {
                setDropdownOpen(false);
                setDropdownAnchorEl(null);
              }}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}>
              <MenuItem
                sx={{ px: 4, py: 1 }}
                onClick={() => {
                  track(TrackEvent.CLICKED_COMPARE_PULSE_LINKED_SESSION, {
                    compareSessionId: session?.id,
                    linkedSessionId: linkedSession.id,
                  });
                }}>
                <Box mr={2}>
                  <AssetBadge
                    text={getBadgeLabel(index, AssetBadgeLabelStyle.Alphabetical)}
                    backgroundColor={'rgba(255, 255, 255, 0.40)'}
                    color={'#FFFFFF'}
                  />
                </Box>
                <Typography color="primary" sx={{ textOverflow: 'ellipsis', overflowX: 'hidden' }}>
                  {linkedSession.name}
                </Typography>
                <Box component="span" px={1}>
                  <OpenLinkIcon />
                </Box>
              </MenuItem>
            </RouteLink>
          );
        })}
      </Menu>
    </>
  );
}
