import { styled, Tooltip, tooltipClasses } from '@mui/material';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#262B34',
    fontSize: '12px',
    color: '#FFFFFF',
    '.MuiTooltip-arrow': {
      color: '#262B34',
    },
  },
}));

const IntakeTooltip = ({ children, ...otherProps }) => {
  return <StyledTooltip {...otherProps}>{children}</StyledTooltip>;
};

export default IntakeTooltip;
