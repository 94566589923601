import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Grid, IconButton, Link, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import LockIcon from '../../assets/lockIcon.png';
import {
  AutomatedInsightSectionToDisplayName,
  AutomatedInsightSections,
} from '../../modules/automated-insights/constants';
import { TrackEvent } from '../analytics';
import QuestionsChat from './QuestionsChat';
import { ExplorePlansButton } from './ui/Button';

const ExpandCollapse = ({ handleButtonClick }) => {
  return (
    <Grid
      container
      justifyContent={'end'}
      spacing={2}
      sx={{ paddingTop: 0.5, paddingBottom: 1, paddingRight: 3 }}>
      <Grid item sx={{ marginRight: -0.75 }}>
        <Link
          onClick={() => handleButtonClick(true)}
          sx={{
            color: '#43BCFF',
            textDecoration: 'none',
            cursor: 'pointer',
          }}>
          <Typography
            sx={{
              fontWeight: 400,
              color: grey[900],
              fontSize: '12px',
              '&:hover': {
                fontWeight: 500,
                color: 'black',
              },
            }}>
            Expand all
          </Typography>
        </Link>
      </Grid>
      <Grid item sx={{ marginTop: -0.5 }}>
        <Typography variant="body1" sx={{ color: grey[400] }}>
          |
        </Typography>
      </Grid>
      <Grid item sx={{ marginLeft: -0.75 }}>
        <Link
          onClick={() => handleButtonClick(false)}
          sx={{
            color: '#43BCFF',
            textDecoration: 'none',
            cursor: 'pointer',
          }}>
          <Typography
            sx={{
              fontWeight: 400,
              color: grey[900],
              fontSize: '12px',
              '&:hover': {
                fontWeight: 500,
                color: 'black',
              },
            }}>
            Collapse all
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

// ownerUserId is the user id of the owner of the pulse, while chatUserId is the user that is logged in
// that is trying to send a chat message (based on a share link).
// This will be empty if the user is not logged in.
const ChatDrawer = ({
  sessionId,
  selectedSegment,
  ownerUserId,
  chatUserId,
  open,
  onClose,
  sessionType,
  hideReportContent,
  selectedTab,
  chatEnabled,
  selectedQuantTheme = null,
}) => {
  const [expanded, setExpanded] = useState(true);

  const [chatMessages, setChatMessages] = useState([]);
  const [openChatMessages, setOpenChatMessages] = useState(Array(999).fill(true).flat());
  const [showExpandCollapse, setShowExpandCollapse] = useState(false);

  const handleButtonClick = (openAll) => {
    if (openAll) {
      setOpenChatMessages(Array(chatMessages?.length).fill(true).flat());
    } else {
      setOpenChatMessages([]);
    }
  };

  const handleOpenMessageAccordion = (index, open) => {
    const array = [...openChatMessages];
    array[index] = open;
    setOpenChatMessages(array);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // For pulse owner, if the current selected tab is not part of the user's tier, we block this tab and display a blocking message.
  // If the selected tab is Audience, we don't show this message.
  return chatUserId &&
    hideReportContent &&
    selectedTab !== AutomatedInsightSectionToDisplayName[AutomatedInsightSections.MyAudience] &&
    selectedTab !== AutomatedInsightSectionToDisplayName[AutomatedInsightSections.Audience] &&
    selectedTab !== null ? (
    <Box
      sx={{
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: '0 0 20px 20px',
        height: hideReportContent ? 'max(70%, calc(100vh - 350px))' : 'calc(100vh - 300px)', // Set fixed height conditionally
        minHeight: '425px', // for mobile when hiding report content
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.30)',
        overflowY: hideReportContent ? 'auto' : 'visible', // Add scroll if content exceeds height when fixed
      }}>
      <Grid
        container
        justifyContent={'center'}
        spacing={2}
        alignItems={'center'}
        alignContent={'center'}
        sx={{
          height: 'inherit',
          borderRadius: '20px',
          overflow: 'hidden',
          paddingX: 1,
        }}>
        <Grid item>
          <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 1.5 }}>
            <img src={LockIcon} alt="selectable" style={{ width: '40px', height: 'auto' }} />
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, textAlign: 'center', fontSize: '15px', marginBottom: 1 }}>
            Upgrade to unlock full access
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center', fontSize: '13px', marginBottom: 3 }}>
            This feature is not included in your subscription
          </Typography>
          <Grid item sx={{ textAlign: 'center' }}>
            <ExplorePlansButton
              blockedFeature={selectedTab}
              trackEventName={TrackEvent.PULSE_USER_CLICKED_EXPLORE_PLANS}
              height={'38px'}
              width={'136px'}
              fontSize={'13px'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ) : chatEnabled ? (
    <Box
      sx={{
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        borderRadius: '20px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.25)',
        transition: (theme) =>
          theme.transitions.create('height', {
            easing: theme.transitions.easing.sharp,
            duration: '100ms',
          }),
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 0,
          ...(open && {
            opacity: 1,
            transition: 'opacity 0.5s ease-in-out',
          }),
        }}>
        <IconButton
          aria-label="close chat"
          onClick={() => onClose(false)}
          sx={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.25)',

            '&:hover': {
              backgroundColor: '#FFFFFF',
              color: (theme) => theme.palette.secondary.main,
            },
          }}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <Grid
        container
        flexDirection="column"
        sx={{
          height: '100%',
          borderRadius: '20px',
          overflow: 'hidden',
          flexWrap: 'nowrap',
        }}>
        <Grid
          container
          item
          justifyContent={{ xs: 'flex-end', sm: 'space-between' }}
          sx={{
            position: 'relative', // prevents the box shadow from being hidden by the element below it
            boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.1)',
          }}>
          <Grid item alignSelf="center" mr={1} mt={1} sx={{ width: '100%' }}>
            <Grid container justifyContent={'flex-end'}>
              {showExpandCollapse ? (
                <Grid item mt={0.25}>
                  <ExpandCollapse
                    expanded={expanded}
                    onExpand={handleExpandClick}
                    handleButtonClick={handleButtonClick}
                  />
                </Grid>
              ) : null}
              <Grid item mb={1}>
                <IconButton aria-label="close" size="small" onClick={() => onClose(false)}>
                  <CloseRoundedIcon sx={{ fontSize: '18px', color: 'black' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <QuestionsChat
            sessionId={sessionId}
            selectedSegment={selectedSegment}
            ownerUserId={ownerUserId}
            chatUserId={chatUserId}
            showToolbar={true}
            showCarousel={true}
            selectedQuantTheme={selectedQuantTheme}
            sessionType={sessionType}
            setChatMessages={setChatMessages}
            setShowExpandCollapse={setShowExpandCollapse}
            openChatMessages={openChatMessages}
            handleOpenMessageAccordion={handleOpenMessageAccordion}
          />
        </Grid>
      </Grid>
    </Box>
  ) : null;
};

export default ChatDrawer;
