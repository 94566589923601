import { Box, Typography } from '@mui/material';
import { ReactComponent as PreviewIcon } from '../../../../src/assets/eye.svg';
import { ReactComponent as InfoIconFilled } from '../../../../src/assets/info-icon-filled.svg';
import IntakeTooltip from './IntakeTooltip';
import ToggleSwitch from './ToggleSwitch';

const Header = ({
  name,
  description,
  iconComponent,
  hasPreview,
  isRequired,
  tooltipProps,
  toggleIcon,
  toggleSwitchProps,
  nameProps = {},
}) => {
  const isToggled = toggleSwitchProps?.checked || false;

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" component="h2" sx={{ fontWeight: 700 }} {...nameProps}>
            {name}
            {isRequired && <span>*</span>}
          </Typography>
          <Box ml={1} display="flex" alignItems="center" columnGap={1}>
            {hasPreview && (
              <IntakeTooltip title={'This will be visible to respondents'} arrow placement="right">
                <PreviewIcon width="20px" />
              </IntakeTooltip>
            )}
            {tooltipProps && (
              <IntakeTooltip {...tooltipProps}>
                <InfoIconFilled height="20px" width="20px" />
              </IntakeTooltip>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          {iconComponent}
          {toggleSwitchProps && toggleIcon && (isToggled ? toggleIcon.on : toggleIcon.off)}
          {toggleSwitchProps && <ToggleSwitch {...toggleSwitchProps} />}
        </Box>
      </Box>
      {description && (
        <Typography variant="caption" sx={{ color: '#878787', lineHeight: 1.5 }}>
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default Header;
