import { IntakeStepIds } from '../modules/intake/constants';

export const basePath = '/wevos/:wevoId/edit';

export const start = `${basePath}/${IntakeStepIds.Start}`;
export const testGoal = `${basePath}/${IntakeStepIds.TestGoal}`;
export const audience = `${basePath}/${IntakeStepIds.Audience}`;
export const testType = `${basePath}/${IntakeStepIds.TestType}`;
export const details = `${basePath}/${IntakeStepIds.Details}`;
export const review = `${basePath}/${IntakeStepIds.Review}`;

// new intake form paths
export const intakeBasePath = '/wevos/:wevoId/intake';

export const intakeStart = `${intakeBasePath}/start`;

export const intakeAudience = `${intakeBasePath}/audience`;

export const intakeBuild = `${intakeBasePath}/build`;
export const intakeSetup = `${intakeBuild}/setup`;
export const intakeAsset = `${intakeBuild}/asset`;

export const intakeDetails = `${intakeBasePath}/details`;
export const intakeGoal = `${intakeDetails}/goal`;
export const intakePrimerAndTask = `${intakeDetails}/primer-and-task`;
export const intakeExpectations = `${intakeDetails}/expectations`;
export const intakeCustomQuestions = `${intakeDetails}/custom-questions`;

export const intakeReview = `${intakeBasePath}/review`;
