import { Box } from '@mui/material';
import { CustomTextField, Header } from '../../components';
import { ValidationErrorNotice } from '../../components/Notice';
import { ReorderableList } from '../../components/ReorderableList';
import { AddListItemButton } from '../../components/SelectableTile';
import DebouncedInput from '../../edit/DebouncedInput';
import { serializeErrors, ValidationKeys } from '../helpers/validation/assets';
import PageConfiguration from './PageConfiguration';

function CompareConfiguration({
  wevo,
  competitorPages,
  onAddCompetitor,
  onDeleteCompetitor,
  onPageNameChanged,
  onReorderCompetitor,
  onReorderStep,
  onDeleteStep,
  onStepNameChanged,
  onFilesUploaded,
  onAssetTypeChanged,
  onIsPrototypeChanged,
  onJourneyUrlChanged,
  isAddingPage,
  isDeletingPage,
  isDeletingStep,
  isUploadingSteps,
  errors,
}) {
  const wevoErrors = errors?.wevoErrors?.[String(wevo.id)] ?? {};

  const pageLimitExceeded = wevoErrors?.[ValidationKeys.PageLimitExceeded]?.length > 0;
  const insufficientPages = wevoErrors?.[ValidationKeys.InsufficientPages]?.length > 0;
  const hasPageErrors = pageLimitExceeded || insufficientPages;

  const pageErrors = () => {
    if (pageLimitExceeded) {
      return serializeErrors(wevoErrors?.[ValidationKeys.PageLimitExceeded]);
    }

    if (insufficientPages) {
      return serializeErrors(wevoErrors?.[ValidationKeys.InsufficientPages]);
    }

    return '';
  };

  return (
    <Box>
      <Header
        name="Compare"
        description="Select the compare visual your audience will give feedback on."
        hasPreview={true}
      />
      {hasPageErrors && (
        <Box my={1}>
          <ValidationErrorNotice message={pageErrors()} />
        </Box>
      )}
      <Box mb={2} />
      <Box>
        <ReorderableList
          onDragEnd={(props) => {
            if (!onReorderCompetitor) return;

            const source = props.source;
            const destination = props.destination;

            if (!destination) {
              return;
            }

            if (source.index === destination.index) {
              return;
            }

            // n.b. competitors are always offset by 1 since the first page is the "focus page"
            // to absract over this in the "compare" component, we handle that by offsetting the source
            //  and destination index by 1.

            const page = wevo?.pages?.[source.index + 1];

            onReorderCompetitor({
              page,
              sourceIndex: source.index + 1,
              destinationIndex: destination.index + 1,
            });
          }}
          items={competitorPages ?? []}
          renderTitle={(item, index) => (
            <DebouncedInput
              value={item?.name || ''}
              onChange={(value) => onPageNameChanged({ page: item, newName: value })}
              debounceMs={500}
              renderInput={({ value, onChange }) => (
                <CustomTextField
                  value={value}
                  sx={{
                    width: '90%',
                    background: 'white',
                    borderRadius: 4,
                    '& .MuiInputBase-root': {
                      fontWeight: 600,
                      fontSize: 14,
                    },
                  }}
                  onChange={onChange}
                />
              )}
            />
          )}
          renderItem={(item, index) => (
            <Box>
              <PageConfiguration
                wevo={wevo}
                page={item}
                canDelete={true}
                isDeleting={isDeletingPage}
                onDelete={onDeleteCompetitor}
                onFilesUploaded={onFilesUploaded}
                onReorderStep={onReorderStep}
                onDeleteStep={onDeleteStep}
                onStepNameChanged={onStepNameChanged}
                onAssetTypeChanged={onAssetTypeChanged}
                onIsPrototypeChanged={onIsPrototypeChanged}
                onJourneyUrlChanged={onJourneyUrlChanged}
                isDeletingStep={isDeletingStep}
                isUploadingSteps={isUploadingSteps}
                errors={errors}
              />
            </Box>
          )}
        />
      </Box>
      <Box mb={2} />
      <AddListItemButton
        width="100%"
        size="small"
        onClick={() => onAddCompetitor({ wevo })}
        isLoading={isAddingPage}
        disabled={isAddingPage}
      />
    </Box>
  );
}

export default CompareConfiguration;
