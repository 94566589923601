import { useMutation } from 'react-query';
import axios from '../../../api';

const createPreviewSurveyLinkRequest = async ({ wevoId, recipientEmails }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/preview-survey-link-request`,
    method: 'POST',
    data: { recipientEmails },
  });
  return response.data;
};

export default function useCreatePreviewSurveyLinkRequest() {
  return useMutation(createPreviewSurveyLinkRequest);
}
