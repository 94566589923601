import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import InfoIcon from '@mui/icons-material/Info';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { debounce as _debounce, isEmpty as _isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useWevo from '../../../hooks/useWevo';
import { CustomQuestionTypes, PagesApplyTo } from '../../../modules/intake/constants';
import { WevoTestType, WevoType } from '../../../modules/wevos/constants';
import { snackbar } from '../../../notifications';
import theme from '../../../theme';
import QuestionTypeScale from '../edit/QuestionTypeScale';
import useUpdateCustomQuestion from '../hooks/useUpdateCustomQuestion';
import DeleteCustomQuestionDialog from './DeleteCustomQuestionDialog';
import { CustomQuestionPageScope, CustomQuestionStepScope } from './ScopesForQuestion.js';

const styles = {
  root: {
    paddingTop: (theme) => theme.spacing(5),
  },
  infoIcon: {
    marginLeft: (theme) => theme.spacing(1),
    verticalAlign: 'bottom',
    color: grey[600],
  },
  deleteIcon: {
    color: 'white',
    verticalAlign: 'bottom',
    fontSize: '20px',
  },
  deleteText: {
    color: 'white',
    fontSize: '13px',
  },
  delete: {
    cursor: 'pointer',
    marginTop: '20px',
  },
  section: {
    marginTop: (theme) => theme.spacing(2),
  },
  text: {
    marginTop: (theme) => theme.spacing(2),
  },
  pagesRadioButtons: {
    marginTop: '10px',
    flexDirection: 'column',
  },
  radioButtonLabel: {
    marginLeft: (theme) => theme.spacing(2),
  },
  reorderIcon: {
    verticalAlign: 'bottom',
    marginRight: '10px',
  },
  tooltip: {
    fontSize: '20px',
  },
};

const CustomQuestion = (props) => {
  const {
    groupDetails,
    deleteCustomQuestion,
    setQuestionsValidity,
    dragHandleProps,
    exceedsLimits,
    currentNumberOfQuestions,
    customQuestions,
    setCustomQuestions,
    questionNumber,
  } = props;

  const { wevoId } = useParams();
  const { data: draft } = useWevo(wevoId);
  const draftType = draft?.type;
  const testType = draft?.testType;
  const pages =
    draftType === WevoType.Classic ? draft?.pages ?? [] : draft?.pages?.flatMap((page) => page?.steps ?? []);

  const groupId = groupDetails?.groupId;

  const [scopes, setScopes] = useState(groupDetails?.scopes ?? []);

  const [question, setQuestion] = useState(groupDetails?.question ?? {});
  const [questionText, setQuestionText] = useState(question?.questionText ?? '');
  const [followUpText, setFollowUpText] = useState(question?.followUpQuestionText ?? '');
  const [name, setName] = useState(groupDetails?.name ?? '');

  const [qType, setQType] = useState(question?.type ?? '');

  const [isQuestionOpen, setIsQuestionOpen] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [isQuestionValid, setIsQuestionValid] = useState(questionText ? true : false);
  const [isFollowUpQuestionValid, setIsFollowUpQuestionValid] = useState(followUpText ? true : false);
  const [isValidTypeAndLabels, setIsValidTypeAndLabels] = useState(true);
  const [isScopesValid, setIsScopesValid] = useState(groupDetails?.isScopesSectionValid);
  const [errors, setErrors] = useState({});

  const { mutate: updateCustomQuestion } = useUpdateCustomQuestion();

  const findPagesApplyTo = () => {
    const foundStep = scopes.find((scope) => scope.stepId !== null);
    if (scopes.length > 0 && foundStep) {
      return PagesApplyTo.InsightPages;
    } else {
      if (draftType === WevoType.Journey) {
        return PagesApplyTo.JourneyLevel;
      } else {
        return PagesApplyTo.InsightPages;
      }
    }
  };

  const [checkedPagesApplyTo, setCheckedPagesApplyTo] = useState(findPagesApplyTo());

  /* Update questionText */
  const debouncedUpdateQuestionText = useMemo(
    () =>
      _debounce(
        (newText) =>
          updateCustomQuestion(
            { id: wevoId, groupId: groupId, questionText: newText || '' },
            {
              onError: (err, variables, { previousCustomQuestionsState }) => {
                const index = previousCustomQuestionsState.findIndex(
                  (q) => String(q.groupId) === String(variables.groupId)
                );
                if (index >= 0) {
                  const prevText = previousCustomQuestionsState?.[index]?.question?.questionText ?? '';
                  setQuestionText(prevText);
                  setQuestion((question) => ({
                    ...question,
                    questionText: prevText,
                  }));
                  setIsQuestionValid(!_isEmpty(prevText));
                }

                snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating custom question');
              },
            }
          ),

        1000
      ),
    [updateCustomQuestion, wevoId, groupId]
  );

  /* Update question field: customQuestionText */
  const handleCustomQuestionTextChanged = useCallback(
    (ev) => {
      const newText = ev.target.value;
      setQuestionText(newText);
      setQuestion((question) => ({
        ...question,
        questionText: newText,
      }));
      setIsQuestionValid(!(_isEmpty(newText) || newText.trim().length === 0));
      debouncedUpdateQuestionText(newText);
    },
    [debouncedUpdateQuestionText]
  );

  /* Update name */
  const debouncedUpdateQuestionName = useMemo(
    () =>
      _debounce(
        (newQuestionName) =>
          updateCustomQuestion(
            { id: wevoId, groupId: groupId, name: newQuestionName || '' },
            {
              onError: (err, variables, { previousCustomQuestionsState }) => {
                const index = previousCustomQuestionsState?.findIndex(
                  (q) => String(q.groupId) === String(groupId)
                );
                if (index >= 0) {
                  const prevName = previousCustomQuestionsState?.[index]?.name ?? '';
                  setName(prevName);
                }

                snackbar.error(
                  err?.response?.data?.humanReadableMessage ?? 'Error updating custom question name'
                );
              },
            }
          ),
        1000
      ),
    [wevoId, groupId, updateCustomQuestion]
  );

  /* Update followUpQuestionText */
  const debouncedUpdateFollowUpQuestionText = useMemo(
    () =>
      _debounce(
        (newText) =>
          updateCustomQuestion(
            { id: wevoId, groupId: groupId, followUpQuestionText: newText || '' },
            {
              onError: (err, variables, { previousCustomQuestionsState }) => {
                const index = previousCustomQuestionsState?.findIndex(
                  (q) => String(q.groupId) === String(groupId)
                );
                if (index >= 0) {
                  const prevText = previousCustomQuestionsState?.[index]?.question?.followUpQuestionText ?? '';
                  setFollowUpText(prevText);
                  setQuestion((question) => ({
                    ...question,
                    followUpQuestionText: prevText,
                  }));
                  setIsFollowUpQuestionValid(!_isEmpty(prevText));
                }

                snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating custom question');
              },
            }
          ),

        1000
      ),
    [wevoId, groupId, updateCustomQuestion]
  );

  /* Update custom question name field: name */
  const handleQuestionNameChanged = useCallback(
    (ev) => {
      const newQuestionName = ev.target.value;
      setName(newQuestionName);
      debouncedUpdateQuestionName(newQuestionName);
    },
    [debouncedUpdateQuestionName]
  );

  /* Update question field: followUpQuestionText */
  const handleFollowUpQuestionTextChanged = useCallback(
    (ev) => {
      const newText = ev.target.value;
      setFollowUpText(newText);
      setQuestion((question) => ({
        ...question,
        followUpQuestionText: newText,
      }));
      setIsFollowUpQuestionValid(!(_isEmpty(newText) || newText.trim().length === 0));
      debouncedUpdateFollowUpQuestionText(newText);
    },
    [debouncedUpdateFollowUpQuestionText]
  );

  /* Update scopes */
  const updateScopes = useCallback(
    (updateScopes, checkedPagesApplyTo) =>
      updateCustomQuestion(
        { id: wevoId, groupId: groupId, scopes: updateScopes },
        {
          onSuccess: (group) => {
            setIsScopesValid(group.isScopesSectionValid);
          },
          onError: (err, variables, { previousCustomQuestionsState }) => {
            const index = previousCustomQuestionsState.findIndex((q) => String(q.groupId) === String(groupId));
            if (index >= 0) {
              const prevScopes = previousCustomQuestionsState?.[index]?.scopes ?? [];
              setScopes(prevScopes);
            }
            if (checkedPagesApplyTo === PagesApplyTo.JourneyLevel) {
              setCheckedPagesApplyTo(PagesApplyTo.InsightPages);
            }

            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating custom question');
          },
        }
      ),
    [wevoId, groupId, updateCustomQuestion]
  );

  const handleDeleteCustomQuestionClick = () => {
    deleteCustomQuestion(groupId);
  };

  const handleOpenQuestionChange = () => {
    setIsQuestionOpen(!isQuestionOpen);
  };

  const handlePagesApplyToSelect = (ev) => {
    if (ev.target.value !== checkedPagesApplyTo) {
      setCheckedPagesApplyTo(ev.target.value);

      if (ev.target.value === PagesApplyTo.JourneyLevel) {
        if (pages) {
          const newScopes = (draft?.pages ?? []).map((page) => ({
            wevoPageId: page.id,
            stepId: null,
          }));
          updateScopes(newScopes, PagesApplyTo.JourneyLevel);
        }
      } else if (ev.target.value === PagesApplyTo.InsightPages) {
        const defaultStep = draft?.pages?.[0]?.steps?.[0];

        if (defaultStep) {
          const newScopes = [{ wevoPageId: String(defaultStep.wevoPageId), stepId: String(defaultStep.id) }];
          setScopes(newScopes);
          updateScopes(newScopes);
        }
      }
    }
  };

  const toggleShowDeleteCustomQuestionDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  useEffect(() => {
    if (!isQuestionValid) {
      setErrors((errors) => ({
        ...errors,
        questionText: { message: 'Please enter a custom question' },
      }));
    } else {
      setErrors((errors) => ({
        ...errors,
        questionText: false,
      }));
    }
  }, [isQuestionValid]);

  useEffect(() => {
    if (qType !== CustomQuestionTypes.Heatmap) {
      setIsFollowUpQuestionValid(true);
    } else {
      setIsFollowUpQuestionValid(followUpText.length > 0);
    }

    if (!followUpText && qType === CustomQuestionTypes.Heatmap) {
      setErrors((errors) => ({
        ...errors,
        followUpQuestionText: { message: 'Please enter a follow up question' },
      }));
    } else {
      setErrors((errors) => ({
        ...errors,
        followUpQuestionText: false,
      }));
    }
  }, [followUpText, qType]);

  useEffect(() => {
    if (isValidTypeAndLabels) {
      setErrors((errors) => ({
        ...errors,
        options: false,
        labels: false,
      }));
    }
  }, [isValidTypeAndLabels]);

  useEffect(() => {
    if (!isScopesValid) {
      setErrors((errors) => ({
        ...errors,
        scopes: { message: 'Please choose at least one page' },
      }));
    } else {
      setErrors((errors) => ({
        ...errors,
        scopes: false,
      }));
    }
  }, [isScopesValid]);

  useEffect(() => {
    setQuestionsValidity((questionsValidity) => {
      const newData = { ...questionsValidity };
      if (qType === CustomQuestionTypes.Heatmap) {
        newData[String(groupId)] =
          isQuestionValid && isFollowUpQuestionValid && isValidTypeAndLabels && isScopesValid;
      } else {
        newData[String(groupId)] = isQuestionValid && isValidTypeAndLabels && isScopesValid;
      }
      return newData;
    });
  }, [
    setQuestionsValidity,
    groupId,
    isQuestionValid,
    isFollowUpQuestionValid,
    isValidTypeAndLabels,
    isScopesValid,
    qType,
  ]);

  useEffect(() => {
    if (
      draftType === WevoType.Journey &&
      scopes.every((scope) => !_isEmpty(scope?.wevoPageId) && _isEmpty(scope?.stepId))
    ) {
      setCheckedPagesApplyTo(PagesApplyTo.JourneyLevel);
    } else if (!groupDetails?.isScopesSectionValid) {
      setCheckedPagesApplyTo(PagesApplyTo.InsightPages);
    }
  }, [draftType, scopes, groupDetails?.isScopesSectionValid]);

  const shouldShowScopeOptions = useMemo(() => {
    const draftType = draft.type;

    // classic tests only have insights pages, no concept of a "journey-level" question
    const isClassic = draftType === WevoType.Classic;

    return !isClassic;
  }, [draft]);

  const shouldDisableInsightsPageOption = useMemo(() => {
    const draftType = draft.type;
    const testType = draft.testType;
    const numPages = (draft?.pages ?? []).length;
    const isCompareJourney = draftType === WevoType.Journey && testType === WevoTestType.Compare;
    const isInvalidSinglePageJourney =
      draftType === WevoType.Journey && testType === WevoTestType.Page && numPages > 1;
    return isCompareJourney || isInvalidSinglePageJourney;
  }, [draft]);

  const disabledInsightsPageOptionHelpText = useMemo(() => {
    const isCompareJourney = draftType === WevoType.Journey && testType === WevoTestType.Compare;

    if (isCompareJourney) {
      return '"Insights Page" custom questions are not available for Compare Journeys.';
    }

    const numPages = (draft?.pages ?? []).length;
    const isInvalidSinglePageJourney =
      draftType === WevoType.Journey && testType === WevoTestType.Page && numPages > 1;

    if (isInvalidSinglePageJourney) {
      const pageDelta = numPages - 1;
      const pageNoun = pageDelta === 1 ? 'page' : 'pages';

      return `Please delete ${
        numPages - 1
      } ${pageNoun} to enable "Insights Page" custom question for this test.`;
    }

    return '';
  }, [draft, draftType, testType]);

  return (
    <Accordion expanded={isQuestionOpen} key={question.groupId} onChange={handleOpenQuestionChange}>
      <AccordionSummary
        {...dragHandleProps}
        expandIcon={isQuestionOpen ? <RemoveIcon fontSize="small" /> : <AddIcon fontSize="small" />}
        aria-controls="Custom-Questions"
        id="Custom-Question-1"
        sx={{ backgroundColor: isQuestionOpen ? theme.palette.primary.main : '' }}>
        <Typography variant="h5" sx={{ color: isQuestionOpen ? 'white' : 'black' }}>
          <DragIndicatorIcon fontSize="small" sx={styles.reorderIcon} />
          {`Custom Question ${questionNumber}`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} direction="row">
          <Grid item sx={styles.section} xs={12}>
            {shouldShowScopeOptions && (
              <Fragment>
                <Typography variant="h5">What pages does your custom question apply to?</Typography>
                <FormControl variant="standard" component="fieldset">
                  <RadioGroup
                    sx={styles.pagesRadioButtons}
                    name="pagesType"
                    value={checkedPagesApplyTo}
                    onChange={handlePagesApplyToSelect}>
                    <Tooltip
                      title={
                        qType === CustomQuestionTypes.Heatmap
                          ? 'Custom Click Maps are only supported for "Insight Pages" scoped questions'
                          : ''
                      }
                      placement="right"
                      arrow>
                      <FormControlLabel
                        sx={styles.radioButtonLabel}
                        key={PagesApplyTo.JourneyLevel}
                        value={PagesApplyTo.JourneyLevel}
                        control={<Radio color="primary" />}
                        label={<Typography variant="body1">Journey</Typography>}
                        disabled={qType === CustomQuestionTypes.Heatmap}
                      />
                    </Tooltip>
                    <Tooltip title={disabledInsightsPageOptionHelpText} placement="right" arrow>
                      <FormControlLabel
                        sx={styles.radioButtonLabel}
                        key={PagesApplyTo.InsightPages}
                        value={PagesApplyTo.InsightPages}
                        control={<Radio color="primary" disabled={shouldDisableInsightsPageOption} />}
                        label={
                          <Typography
                            variant="body1"
                            sx={{ color: shouldDisableInsightsPageOption ? grey[500] : 'initial' }}>
                            Insight Pages
                          </Typography>
                        }
                      />
                    </Tooltip>
                  </RadioGroup>
                </FormControl>
              </Fragment>
            )}
            {/* If the user clicked the Insight Pages or if test type is "Classic" */}
            {(draftType === WevoType.Classic || checkedPagesApplyTo === PagesApplyTo.InsightPages) && (
              <Grid
                container
                item
                sx={draftType === WevoType.Journey ? styles.section : {}}
                justifyContent="flex-start">
                <Grid item>
                  <Typography variant="h5">
                    Select the page(s) this custom question applies to:
                    <Tooltip
                      title={
                        <Fragment>
                          <span>This custom question will be applied to all the pages selected.</span>
                          <Box py={1} />
                          <span>
                            If you want to ask a new custom question, please click
                            <em> add a custom question</em> below.
                          </span>
                        </Fragment>
                      }
                      placement="right"
                      sx={styles.tooltip}>
                      <InfoIcon sx={styles.infoIcon} />
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {draftType === WevoType.Classic && (
                    <CustomQuestionPageScope
                      draft={draft}
                      groupId={groupId}
                      scopes={scopes}
                      draftType={draftType}
                      setScopes={setScopes}
                      updateScopes={updateScopes}
                      errors={errors}
                      wevoId={wevoId}
                      isScopesValid={isScopesValid}
                      exceedsLimits={exceedsLimits}
                      currentNumberOfQuestions={currentNumberOfQuestions}
                    />
                  )}
                  {checkedPagesApplyTo === PagesApplyTo.InsightPages &&
                    draftType === WevoType.Journey &&
                    draft?.testType === WevoTestType.Page && (
                      <CustomQuestionStepScope
                        draft={draft}
                        groupId={groupId}
                        scopes={scopes}
                        draftType={draftType}
                        setScopes={setScopes}
                        updateScopes={updateScopes}
                        errors={errors}
                        wevoId={wevoId}
                        isScopesValid={isScopesValid}
                        exceedsLimits={exceedsLimits}
                        currentNumberOfQuestions={currentNumberOfQuestions}
                      />
                    )}
                </Grid>
              </Grid>
            )}
            {draftType === WevoType.Journey &&
              testType === WevoTestType.Compare &&
              checkedPagesApplyTo === PagesApplyTo.JourneyLevel && (
                <Grid
                  container
                  item
                  sx={draftType === WevoType.Journey ? styles.section : {}}
                  justifyContent="flex-start">
                  <Grid item>
                    <Typography variant="h5">
                      Select the journey(s) this custom question applies to:
                      <Tooltip
                        title={
                          <Fragment>
                            <span>This custom question will be applied to all the journeys selected.</span>
                            <Box py={1} />
                            <span>
                              If you want to ask a new custom question, please click
                              <em> add a custom question</em> below.
                            </span>
                          </Fragment>
                        }
                        placement="right"
                        sx={styles.tooltip}>
                        <InfoIcon sx={styles.infoIcon} />
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomQuestionPageScope
                      draft={draft}
                      groupId={groupId}
                      scopes={scopes}
                      draftType={draftType}
                      setScopes={setScopes}
                      updateScopes={updateScopes}
                      errors={errors}
                      wevoId={wevoId}
                      isScopesValid={isScopesValid}
                      exceedsLimits={exceedsLimits}
                      currentNumberOfQuestions={currentNumberOfQuestions}
                    />
                  </Grid>
                </Grid>
              )}
            {draftType === WevoType.Journey &&
              testType === WevoTestType.Page &&
              (draft?.pages ?? []).length > 1 && (
                <Box mt={1} sx={{ textAlign: 'center' }}>
                  <Typography variant="caption" sx={styles.deleteText}>
                    {disabledInsightsPageOptionHelpText}
                  </Typography>
                </Box>
              )}
          </Grid>
          <Grid item sx={styles.section} xs={12}>
            <Typography variant="h5">
              Enter your custom question
              <Tooltip
                title={`If the question type you want is not listed, please contact us at support@wevo.ai`}
                placement="right"
                sx={styles.tooltip}>
                <InfoIcon sx={styles.infoIcon} />
              </Tooltip>
            </Typography>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={name || ''}
              label="Question Name"
              onChange={handleQuestionNameChanged}
              type="text"
              inputProps={{ maxLength: '20' }} // Allows up to 20 characters for a custom question name
              name="customQuestionName"
              autoComplete="off"
              sx={styles.text}
              error={Boolean(errors?.name)}
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
              helperText={
                errors?.name?.message ??
                '(Optional) This name will be used to identify your question throughout the report. Max. 20 characters'
              }
            />
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={questionText || ''}
              label="Question Text"
              onChange={handleCustomQuestionTextChanged}
              type="text"
              inputProps={{ maxLength: '1000' }} // Allows up to 1,000 characters for a custom question
              name="customQuestion"
              autoComplete="off"
              sx={styles.text}
              error={Boolean(errors?.questionText)}
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
              helperText={
                errors?.questionText?.message ??
                `Enter your custom question.${
                  qType === CustomQuestionTypes.MultiSelect
                    ? ' We will include instructions for how respondents should answer your question.'
                    : ''
                }`
              }
            />
          </Grid>
          <Grid item xs={12}>
            <QuestionTypeScale
              question={question}
              setFollowUpText={setFollowUpText}
              setQType={setQType}
              setIsValidTypeAndLabels={setIsValidTypeAndLabels}
              wevoId={wevoId}
              groupId={groupDetails.groupId}
              checkedPagesApplyTo={checkedPagesApplyTo}
              customQuestions={customQuestions}
              setCustomQuestions={setCustomQuestions}
              errors={errors}
            />
          </Grid>
          {qType !== CustomQuestionTypes.CustomQualitative && (
            <Grid item xs={12}>
              <Typography variant="h5">
                {qType !== CustomQuestionTypes.Heatmap
                  ? '(Optional) Enter your follow up question'
                  : 'Enter your follow up question'}
                <Tooltip
                  title={
                    qType !== CustomQuestionTypes.Heatmap
                      ? 'Add an additional question to clarify or deepen the previous response (optional).'
                      : 'Add an additional question to clarify or deepen the previous response.'
                  }
                  placement="right"
                  sx={styles.tooltip}>
                  <InfoIcon sx={styles.infoIcon} />
                </Tooltip>
              </Typography>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                value={followUpText || ''}
                type="text"
                inputProps={{ maxLength: '1000' }} // Allows up to 1,000 characters for a followUp question
                onChange={handleFollowUpQuestionTextChanged}
                name="followUpQuestion"
                autoComplete="off"
                sx={styles.text}
                error={Boolean(errors?.followUpQuestionText)}
                helperText={errors?.followUpQuestionText?.message}
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <Button
              variant="contained"
              size="medium"
              onClick={() => toggleShowDeleteCustomQuestionDialog()}
              sx={styles.delete}
              startIcon={<DeleteIcon sx={styles.deleteIcon} />}>
              <Typography sx={styles.deleteText}>{'Delete'}</Typography>
            </Button>
          </Grid>
          <DeleteCustomQuestionDialog
            open={showDeleteDialog}
            deleteCustomQuestion={handleDeleteCustomQuestionClick}
            closeCallback={toggleShowDeleteCustomQuestionDialog}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

CustomQuestion.propTypes = {
  groupDetails: PropTypes.object.isRequired,
  deleteCustomQuestion: PropTypes.func.isRequired,
  setQuestionsValidity: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.object,
  exceedsLimits: PropTypes.bool.isRequired,
  currentNumberOfQuestions: PropTypes.number.isRequired,
  customQuestions: PropTypes.array.isRequired,
  setCustomQuestions: PropTypes.func.isRequired,
};

export default CustomQuestion;
