import HeadsetRoundedIcon from '@mui/icons-material/HeadsetRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { getUserCustomizations } from '../../modules/user/selectors';
import { TrackEvent } from '../analytics';
import PricingModal from '../intake/edit/PricingModal';

const useStyles = makeStyles((theme) => ({
  myTests: {
    textTransform: 'none',
    minWidth: 'fit-content',
    color: 'white',
    '& .MuiSvgIcon-root': {
      minWidth: theme.spacing(4),
    },
  },
  pricingHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  white: {
    color: 'white',
  },
  closeIconGrid: {
    marginRight: theme.spacing(-1),
  },
  dialogContent: {
    marginTop: theme.spacing(1),
    height: (window.innerHeight * 6) / 8,
  },
  serviceCard: {
    border: '1px solid',
    borderColor: grey[500],
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    padding: theme.spacing(1),
    color: 'inherit',
    [theme.breakpoints.down('xl')]: {
      height: '55px',
    },
    [theme.breakpoints.up('md')]: {
      height: '36px',
    },
    textAlign: 'left',
  },
  serviceCreditCard: {
    padding: theme.spacing(1.25),
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    color: 'inherit',
    [theme.breakpoints.down('xl')]: {
      height: '55px',
    },
    [theme.breakpoints.up('md')]: {
      height: '36px',
    },
    textAlign: 'right',
  },
  audienceCard: {
    border: '1px solid',
    borderColor: grey[500],
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    padding: theme.spacing(1),
    color: 'inherit',
    height: '36px',
    width: '70px',
    textAlign: 'center',
  },
  audienceCardBYOP: {
    border: '1px solid',
    borderColor: grey[500],
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    padding: theme.spacing(1),
    color: 'inherit',
    height: '36px',
    width: '90px',
    textAlign: 'center',
  },
  creditsCard: {
    padding: theme.spacing(1.25),
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    color: 'inherit',
    height: '36px',
    width: '80px',
    textAlign: 'center',
  },
  font: {
    fontSize: '12px',
  },
  fontBYOP: {
    fontSize: '11px',
    marginTop: theme.spacing(-1),
  },
  link: {
    color: theme.palette.primary.main,
  },
  menuFont: {
    fontSize: '13px',
  },
}));

const SupportButton = (props) => {
  const { label } = props;
  const classes = useStyles();
  const { track } = useAnalytics();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openPricing, setOpenPricing] = useState(false);

  const userCustomizations = useSelector(getUserCustomizations);
  const supportEmail = userCustomizations?.supportEmail;
  const isMastercard = Boolean(userCustomizations?.isMastercard);
  const isFeasibilityEnabled = userCustomizations?.feasibility?.enabled;

  function handleMyTestsClick(ev) {
    setAnchorEl(ev.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  const handleContactUsClicked = () => {
    closeMenu();
  };

  const handleFAQClicked = () => {
    track(TrackEvent.USERLYTICS_CLICKED_FAQ, { fromComponent: 'appBar' });
    closeMenu();

    if (isMastercard) {
      window.open('/faq', '_blank');
    } else {
      window.open(process.env.REACT_APP_HELP_CENTER_HOST, '_blank');
    }
  };

  const handlePricingClicked = () => {
    track(TrackEvent.USERLYTICS_CLICKED_PRICING, { fromComponent: 'appBar' });
    if (isMastercard) {
      closeMenu();
    }
    setOpenPricing(true);
  };

  const handleClose = () => {
    setOpenPricing(false);
  };

  const handleFeasibilityRequestClicked = () => {
    history.push('/feasibility-request/create');
    closeMenu();
  };

  const getContactUsComponent = () => {
    let helpHref, target;
    if (supportEmail) {
      helpHref = `mailto:${supportEmail}`;
      target = '_top';
    } else {
      helpHref = `${process.env.REACT_APP_HELP_CENTER_HOST}/contact-us`;
      target = '_blank';
    }

    return (
      <a href={helpHref} target={target} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Typography
          style={{
            cursor: 'pointer',
            fontSize: '13px',
          }}>
          Contact Us
        </Typography>
      </a>
    );
  };

  return (
    <>
      <Button
        className={classes.myTests}
        onClick={handleMyTestsClick}
        startIcon={<HeadsetRoundedIcon />}
        endIcon={Boolean(anchorEl) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
        {label}
      </Button>
      <Menu variant="menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
        <MenuItem onClick={handleContactUsClicked}>{getContactUsComponent()}</MenuItem>
        <MenuItem onClick={handleFAQClicked}>
          <Typography className={classes.menuFont}>FAQ</Typography>
        </MenuItem>
        {isMastercard && (
          <MenuItem onClick={handlePricingClicked}>
            <Typography className={classes.menuFont}>Pricing</Typography>
          </MenuItem>
        )}
        {isFeasibilityEnabled && (
          <MenuItem onClick={handleFeasibilityRequestClicked}>
            <Typography className={classes.menuFont}>Feasibility Request</Typography>
          </MenuItem>
        )}
      </Menu>
      <PricingModal handleClose={handleClose} openPricing={openPricing} />
    </>
  );
};

SupportButton.propTypes = {
  label: PropTypes.string.isRequired,
};

export default SupportButton;
