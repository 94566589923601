import { Typography } from '@mui/material';
import IntakeModal from '../../../components/IntakeModal';

function ExpectationsModal(props) {
  const content = (
    <Typography fontSize={13}>
      While valuable, <strong>Expectations</strong> extend the delivery timeline of your study. Are you sure
      you want to add them to your WEVO?
    </Typography>
  );

  return <IntakeModal title="Expectations" content={content} {...props} />;
}

function QualitativeInsightsModal(props) {
  const content = (
    <Typography fontSize={13}>
      While valuable, <strong>Qualitative Insights</strong> extend the delivery timeline of your study. Are you
      sure you want to add them to your WEVO?
    </Typography>
  );

  return <IntakeModal title="Qualitative Insights" content={content} {...props} />;
}

export { ExpectationsModal, QualitativeInsightsModal };
