import AccountCircle from '@mui/icons-material/AccountCircle';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindowsOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Box, Chip, Grid, Slide, Typography, useScrollTrigger } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { get as _get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useAnalytics } from 'use-analytics';
import wevoLogo from '../../assets/wevo_logo_wide_color.png';
import { getAppBarColor } from '../../helpers';
import useWevo from '../../hooks/useWevo';
import { canUseAutomatedInsightSessions, canUseCore } from '../../modules/automated-insights/helpers';
import { selectIsLeftNavOpen, toggleLeftNav } from '../../modules/left-nav/leftNavSlice';
import { Components } from '../../modules/report/constants.js';
import { isAuthenticated } from '../../modules/user/helpers';
import { getUserCustomizations, getUserProfile } from '../../modules/user/selectors';
import { DEVICE_NAME_TO_DEVICE_ID, isShareLink } from '../../modules/wevos/constants';
import { Paths } from '../../routes';
import { TrackEvent } from '../analytics';
import WarningBanner from '../reports/components/WarningBanner.js';
import MyTestsButton from './MyTestsButton';
import SupportButton from './SupportButton';
import NameTextField from './components/NameTextField.js';

const cookies = new Cookies();

const styles = {
  guestViewChip: {
    marginLeft: (theme) => theme.spacing(1),
    backgroundColor: (theme) => theme.palette.primary.light,
    color: 'white',
    fontSize: 'smaller',
  },
  appBar: {
    backgroundColor: getAppBarColor(),
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  font: {
    fontSize: '13px',
  },
};

function DeviceIdIcon({ deviceId }) {
  if (deviceId === DEVICE_NAME_TO_DEVICE_ID.desktop) {
    return <DesktopWindowsIcon sx={{ marginRight: 1 }} />;
  }
  if (deviceId === DEVICE_NAME_TO_DEVICE_ID.mobile) {
    return <SmartphoneIcon sx={{ marginRight: 1 }} />;
  }
  return null;
}

DeviceIdIcon.propTypes = {
  deviceId: PropTypes.number.isRequired,
};

const AppBarContainer = (props) => {
  const { children } = props;

  const sentimentMapMatch = useRouteMatch({
    path: [Paths.reports.sentimentMap, Paths.reports.limitedSentimentMap],
    exact: true,
  });

  const scrollTriggerConfig = !sentimentMapMatch
    ? { disableHysteresis: true, threshold: 0 }
    : { threshold: 50 };

  const trigger = useScrollTrigger(scrollTriggerConfig);

  if (sentimentMapMatch) {
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {React.cloneElement(children, { elevation: 0 })}
      </Slide>
    );
  } else {
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }
};

AppBarContainer.propTypes = {
  children: PropTypes.element.isRequired,
};

const MenuAppBar = ({ name, onNameChanged }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [anchorEl, setAnchorEl] = useState(null);
  const { track } = useAnalytics();

  const user = useSelector(getUserProfile);
  const userCustomizations = useSelector(getUserCustomizations);

  const open = Boolean(anchorEl);
  const reportsMatch = useRouteMatch({
    path: Paths.reports.basePath,
  });
  const reportsDashboardMatch = useRouteMatch({
    path: Paths.reports.dashboard,
    exact: true,
  });
  const intakeMatch = useRouteMatch({
    path: Paths.intake.basePath,
  });
  const newIntakeMatch = useRouteMatch({
    path: Paths.intake.intakeBasePath,
  });
  const limitedReportMatch = useRouteMatch({
    path: [
      ...Paths.reports.LimitedDashboardPaths,
      ...Paths.reports.LimitedSentimentMapPaths,
      Paths.reports.limitedExperienceMap,
    ],
    exact: true,
  });

  const isLimitedReport = Boolean(limitedReportMatch);
  const isLeftNavOpen = useSelector(selectIsLeftNavOpen);
  const showLeftNav = Boolean(reportsMatch) || isLimitedReport;

  const { data: wevo, isError } = useWevo(
    _get(reportsMatch, ['params', 'wevoId'], '') ||
      _get(intakeMatch, ['params', 'wevoId'], '') ||
      _get(limitedReportMatch, ['params', 'wevoId'], '') ||
      _get(newIntakeMatch, ['params', 'wevoId'], '')
  );
  const deviceId = useMemo(() => _get(wevo, 'devices.0'), [wevo]);

  const showWevoName =
    ((Boolean(reportsMatch) && !Boolean(reportsDashboardMatch)) ||
      Boolean(intakeMatch) ||
      Boolean(newIntakeMatch)) &&
    Boolean(_get(wevo, 'name', ''));

  const isUserlytics = Boolean(userCustomizations?.integrations?.userlytics);
  const isMastercard = Boolean(userCustomizations?.isMastercard);
  const userlyticsDomain = userCustomizations?.integrations?.userlyticsDomain;
  const companyLogo =
    reportsMatch || isLimitedReport ? wevo?.company?.customizations?.logo : userCustomizations?.logo;
  const studyName = newIntakeMatch ? name : wevo?.name;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    track(TrackEvent.USER_LOGGED_OUT);
    cookies.remove('authToken', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
    handleClose();
    window.location.replace(`${process.env.REACT_APP_HELLO_HOST}/login`);
  };

  const handleLogoClick = () => {
    window.location.replace(process.env.REACT_APP_AURORA_HOST);
  };

  const toggleSideDrawer = () => {
    dispatch(toggleLeftNav());
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <AppBarContainer>
        <AppBar position="fixed" sx={styles.appBar}>
          <Toolbar>
            {showLeftNav && !isError && (
              <IconButton
                onClick={toggleSideDrawer}
                edge="start"
                color="inherit"
                aria-label="menu"
                size="large"
                sx={{ marginRight: 1 }}>
                {isLeftNavOpen ? <MenuOpenIcon /> : <MenuIcon />}
              </IconButton>
            )}
            <div style={{ width: !showWevoName && '100%' }}>
              <IconButton onClick={handleLogoClick} edge="start" size="large">
                <img src={wevoLogo} alt="the WEVO logo" style={{ maxHeight: '20px' }} />
              </IconButton>
            </div>
            {showWevoName && (
              <Box display="flex" width="100%" minWidth={0} paddingX={1} paddingTop={1} alignItems="center">
                {!newIntakeMatch && Boolean(deviceId) && <DeviceIdIcon deviceId={deviceId} />}
                {newIntakeMatch && <ChevronRightRoundedIcon sx={{ marginRight: 1 }} />}
                <NameTextField
                  wevoId={wevo?.id}
                  name={studyName}
                  isReadOnly={!newIntakeMatch}
                  onNameChanged={onNameChanged}
                />
                {isShareLink(wevo?.id ?? '') && (
                  <Chip sx={styles.guestViewChip} label="Guest View" size="small" />
                )}
              </Box>
            )}
            {isAuthenticated() && !isLimitedReport && (
              <>
                {companyLogo && isMediumScreen && (
                  <Grid>
                    <img
                      src={companyLogo}
                      alt="Company logo"
                      style={{
                        maxHeight: theme.spacing(3.5),
                        marginRight: theme.spacing(1),
                        marginTop: theme.spacing(1),
                      }}
                    />
                  </Grid>
                )}
                <SupportButton label={isSmallScreen ? '' : 'Support'} />
                <MyTestsButton
                  label={isSmallScreen ? '' : 'My Studies'}
                  isUserlytics={isUserlytics}
                  isMastercard={isMastercard}
                  userlyticsDomain={userlyticsDomain}
                  canUseAutomatedInsights={canUseAutomatedInsightSessions(user, userCustomizations)}
                  canUsePro={canUseCore(userCustomizations)}
                />
                <>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    size="large">
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={open}
                    onClose={handleClose}>
                    <Link to="/profile" style={styles.link} onClick={handleClose}>
                      <MenuItem>
                        <Typography sx={styles.font}>Account</Typography>
                      </MenuItem>
                    </Link>
                    <Link to="/profile/password" style={styles.link} onClick={handleClose}>
                      <MenuItem>
                        <Typography sx={styles.font}>Password</Typography>
                      </MenuItem>
                    </Link>
                    <MenuItem onClick={handleLogoutClick}>
                      <Typography sx={styles.font}>Logout</Typography>
                    </MenuItem>
                  </Menu>
                </>
              </>
            )}
            {(!isAuthenticated() || isLimitedReport) && (
              <>
                {isLimitedReport && <Box display="flex" width="100%" minWidth={0} p={1} />}
                {companyLogo && isMediumScreen && (
                  <Grid>
                    <img
                      src={wevo?.company?.customizations?.logo}
                      alt="Company logo"
                      style={{ maxHeight: theme.spacing(3.5), marginTop: theme.spacing(1) }}
                    />
                  </Grid>
                )}
              </>
            )}
          </Toolbar>
          {wevo?.isPreviewing && <WarningBanner component={Components.Report} severity="info" color="error" />}
        </AppBar>
      </AppBarContainer>
    </div>
  );
};

export default MenuAppBar;
