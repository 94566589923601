/**
 * Generic component for questions with custom labels
 */

import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as InfoIconFilled } from '../../../../../../../../assets/info-icon-filled.svg';
import { CustomScreenerTypes } from '../../../../../../../../modules/intake/constants';
import { CustomFormControlCheckbox, CustomTextField, IntakeTooltip } from '../../../../../../components';
import { ValidationErrorNotice } from '../../../../../../components/Notice';
import { AddListItemButton } from '../../../../../../components/SelectableTile';
import Subheader from '../../../../../../components/Subheader';
import DebouncedInput from '../../../../../../edit/DebouncedInput';
import { AnchorLabels } from '../../../../../custom-questions/constants';
import { CustomScreenerTypeOptions } from '../../../../constants';
import { serializeErrors } from '../../../../helpers/validation';
import CustomChoiceAndOutcome from '../CustomChoiceAndOutcome';
import OutcomeSelect from '../OutcomeSelect';

function MultipleChoiceConfiguration({
  customScreener,
  showHeading = true,
  onCustomScreenerChanged,
  onOptionAdded,
  onOptionDeleted,
  isAddingOption,
  isDeletingOption,
  errors,
}) {
  const [deleteCounter, setDeleteCounter] = useState(0);

  const type = customScreener?.questionType;
  const questionTypeLimits = CustomScreenerTypeOptions.find((option) => option.value === type)?.choiceLimits;

  const minChoices = questionTypeLimits?.min || 0;
  const maxChoices = questionTypeLimits?.max || 0;

  const options = customScreener?.options;
  const nonAnchoredOptions = options?.filter((option) => option?.anchorIndex === null);
  const anchoredOptions = options?.filter((option) => option?.anchorIndex !== null);

  const otherChoiceIndex =
    options?.findIndex((option) => !option?.isExclusive && option?.anchorIndex !== null) > 0
      ? options?.findIndex((option) => !option?.isExclusive && option?.anchorIndex !== null)
      : null;
  const noneOfTheAboveChoiceIndex =
    options?.findIndex((option) => option?.isExclusive) > 0
      ? options?.findIndex((option) => option?.isExclusive)
      : null;

  const canAddOptions = options.length < maxChoices;
  const canAddOtherOption = [CustomScreenerTypes.MultipleChoice, CustomScreenerTypes.MultiSelect].includes(
    type
  );
  const canAddNoneOption = [CustomScreenerTypes.MultipleChoice, CustomScreenerTypes.MultiSelect].includes(
    type
  );
  const canRandomizeOptions = [CustomScreenerTypes.MultipleChoice, CustomScreenerTypes.MultiSelect].includes(
    type
  );

  const isDeleteable = [CustomScreenerTypes.MultipleChoice, CustomScreenerTypes.MultiSelect].includes(type);
  // this condition should only be true for actual multiple choice questions, not custom scale labels
  const canDeleteChoices = minChoices < maxChoices && options.length > minChoices;

  const isRandomized = customScreener?.isRandomized;

  const hasEmptyValues = (items = []) => {
    return items?.some((item) => item?.optionText?.length < 1);
  };

  const handleChoiceAdded = (newLabel) => {
    let option = {};

    const newChoiceIndex = options?.length;
    if (newLabel === AnchorLabels.NoneOfTheAbove) {
      const anchorIndex = otherChoiceIndex ? 1 : 0;
      option = {
        optionText: AnchorLabels.NoneOfTheAbove,
        sortOrder: newChoiceIndex,
        anchorIndex,
        isExclusive: true,
      };
    } else if (newLabel === AnchorLabels.Other) {
      const sortOrder = nonAnchoredOptions?.length;
      option = { optionText: AnchorLabels.Other, sortOrder, anchorIndex: 0, isExclusive: false };
    } else {
      option = {
        optionText: '',
        sortOrder: nonAnchoredOptions?.length,
        AnchorIndex: null,
        isExclusive: false,
      };
    }

    onOptionAdded(customScreener.id, option);
  };

  const handleChoiceChanged = (optionId, newValue, oldValue) => {
    if (newValue === oldValue) {
      return;
    }
    const updateFields = { optionText: newValue };
    onCustomScreenerChanged({ customScreener, optionId, updateFields });
  };

  const handleOutcomeChanged = (optionId, newOutcome, oldOutcome) => {
    if (newOutcome === oldOutcome) {
      return;
    }
    const updateFields = { outcome: newOutcome };
    onCustomScreenerChanged({ customScreener, optionId, updateFields });
  };

  const handleChoiceRemoved = (optionId) => {
    onOptionDeleted({ customScreenerId: customScreener?.id, optionId });
    setDeleteCounter(deleteCounter + 1);
  };

  const handleRandomizationChanged = (randomize) => {
    const updateFields = { isRandomized: randomize };
    onCustomScreenerChanged({ customScreener, updateFields });
  };

  return (
    <Box>
      {showHeading && <Subheader name={'Enter your custom choices'} />}
      <Box my={2}>
        {nonAnchoredOptions.map((option, index) => (
          /**
            It's VERY IMPORTANT that the key changes when the label changes and that we include the
            index so that there are no key collisions. This is because the underlying choice text field
            uses internal state and debounces updates. A consequence of this is that if not remounted, the
            internal state will not match the new label state. Unfortunately, this trades off performance
            on deletes, since we're remounting many times when a choice gets deleted
          */
          <Box key={`${index}_${deleteCounter}`} mb={1}>
            <CustomChoiceAndOutcome
              optionAndOutcome={option}
              questionType={type}
              isDeletable={isDeleteable}
              isAddingOption={isAddingOption}
              isDeletingOption={isDeletingOption}
              canDeleteChoice={canDeleteChoices}
              onCustomChoiceChange={(newValue) =>
                handleChoiceChanged(option?.id, newValue, option?.optionText)
              }
              onOutcomeChange={(newOutcome) => handleOutcomeChanged(option?.id, newOutcome, option?.outcome)}
              onCustomChoiceDelete={() => handleChoiceRemoved(option?.id)}
            />
          </Box>
        ))}
        {hasEmptyValues(nonAnchoredOptions) && errors?.labels?.length > 0 && (
          <Box my={1}>
            <ValidationErrorNotice message={serializeErrors(errors.labels)} />
          </Box>
        )}
      </Box>
      {canAddOptions && (
        <Box mt={4}>
          <AddListItemButton
            width="100%"
            size="small"
            isLoading={isAddingOption}
            isDisabled={isAddingOption || isDeletingOption}
            onClick={() => handleChoiceAdded()}
          />
        </Box>
      )}
      {canAddOtherOption && (
        <Box mt={2}>
          <CustomFormControlCheckbox
            key={`${otherChoiceIndex}_${deleteCounter}`}
            checked={otherChoiceIndex !== null}
            label={"Add an 'Other' answer option"}
            disabled={isAddingOption || isDeletingOption}
            onChange={(ev) =>
              ev.target.checked
                ? handleChoiceAdded(AnchorLabels.Other)
                : handleChoiceRemoved(options?.[otherChoiceIndex]?.id)
            }
          />
          {otherChoiceIndex && (
            <Grid container sx={{ marginBottom: 1, paddingLeft: '26px', columnGap: 1 }}>
              <Grid item sx={{ flexGrow: 1 }}>
                <DebouncedInput
                  value={options?.[otherChoiceIndex]?.optionText}
                  onChange={(value) =>
                    handleChoiceChanged(
                      options?.[otherChoiceIndex]?.id,
                      value,
                      options?.[otherChoiceIndex]?.optionText
                    )
                  }
                  debounceMs={500}
                  renderInput={({ value, onChange }) => (
                    <CustomTextField
                      value={value}
                      label="Label"
                      disabled={isDeletingOption}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        '& .MuiInputBase-root': {
                          fontSize: 14,
                        },
                      }}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item sx={{ width: '30%', minWidth: '160px' }}>
                <OutcomeSelect
                  selectedOutcome={options?.[otherChoiceIndex]?.outcome}
                  questionType={type}
                  onChange={(newOutcome) =>
                    handleOutcomeChanged(
                      options?.[otherChoiceIndex]?.id,
                      newOutcome,
                      options?.[otherChoiceIndex]?.outcome
                    )
                  }
                />
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      {canAddNoneOption && (
        <Box>
          <Box display="flex" alignItems="center">
            <CustomFormControlCheckbox
              key={`${noneOfTheAboveChoiceIndex}_${deleteCounter}`}
              checked={!!noneOfTheAboveChoiceIndex}
              label={"Add a 'None of the above' answer option"}
              disabled={isAddingOption || isDeletingOption}
              onChange={(ev) =>
                ev.target.checked
                  ? handleChoiceAdded(AnchorLabels.NoneOfTheAbove)
                  : handleChoiceRemoved(options?.[noneOfTheAboveChoiceIndex]?.id)
              }
            />
            <IntakeTooltip title="If respondents select this option, they won't be able to select any of the other options.">
              <InfoIconFilled />
            </IntakeTooltip>
          </Box>
          {noneOfTheAboveChoiceIndex && (
            <Grid container sx={{ marginBottom: 1, paddingLeft: '26px', columnGap: 1 }}>
              <Grid item sx={{ flexGrow: 1 }}>
                <DebouncedInput
                  value={options?.[noneOfTheAboveChoiceIndex]?.optionText}
                  onChange={(value) =>
                    handleChoiceChanged(
                      options?.[noneOfTheAboveChoiceIndex]?.id,
                      value,
                      options?.[noneOfTheAboveChoiceIndex]?.optionText
                    )
                  }
                  debounceMs={500}
                  renderInput={({ value, onChange }) => (
                    <CustomTextField
                      value={value}
                      label="Label"
                      disabled={isDeletingOption}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        '& .MuiInputBase-root': {
                          fontSize: 14,
                        },
                      }}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid item sx={{ width: '30%', minWidth: '160px' }}>
                <OutcomeSelect
                  selectedOutcome={options?.[noneOfTheAboveChoiceIndex]?.outcome}
                  questionType={type}
                  onChange={(newOutcome) =>
                    handleOutcomeChanged(
                      options?.[noneOfTheAboveChoiceIndex]?.id,
                      newOutcome,
                      options?.[noneOfTheAboveChoiceIndex]?.outcome
                    )
                  }
                />
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      {hasEmptyValues(anchoredOptions) && errors?.labels?.length > 0 && (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(errors.labels)} />
        </Box>
      )}
      {canRandomizeOptions && (
        <Box display="flex" alignItems="center">
          <CustomFormControlCheckbox
            checked={isRandomized}
            label={'Randomize options'}
            disabled={isAddingOption || isDeletingOption}
            onChange={(ev) => handleRandomizationChanged(ev.target.checked)}
          />
          <IntakeTooltip title="If selected, all options for this question will be randomized to minimize survey bias, except 'Other' and 'None of the above', which will remain at the bottom.">
            <InfoIconFilled />
          </IntakeTooltip>
        </Box>
      )}
    </Box>
  );
}

export default MultipleChoiceConfiguration;
