import { InputAdornment, styled, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchInput = styled(TextField, {
  shouldForwardProp: (prop) => !['borderColor', 'hoverBorderColor', 'backgroundColor', 'color'].includes(prop),
})(
  ({
    theme,
    backgroundColor = 'transparent',
    borderColor = '#DEDEDE',
    color = '#000000',
    hoverBorderColor = '#000000',
  }) => ({
    '& .MuiInputBase-root': {
      fontSize: '12px',
      borderRadius: '16.5px',
      color: color,
      backgroundColor: backgroundColor,
    },
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: borderColor,
      },
      '&:hover fieldset': {
        borderColor: hoverBorderColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${borderColor}`,
      },
    },
  })
);

const SearchInputField = ({
  searchTerm,
  onInputChange,
  size = 'small',
  backgroundColor,
  borderColor,
  color,
  hoverBorderColor,
  iconColor,
}) => {
  return (
    <SearchInput
      autoFocus
      variant="outlined"
      value={searchTerm}
      onChange={onInputChange}
      placeholder="Search"
      size={size}
      fullWidth
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      color={color}
      hoverBorderColor={hoverBorderColor}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ fontSize: 15, color: iconColor || '#DEDEDE' }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInputField;
