import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';

/**
 * Creates a new wevo (includes a placeholder image)
 */

const createWevo = async ({ teamId, ownerName, ownerEmail }) => {
  const response = await axios({
    url: `/api/v3/wevos/create`,
    method: 'POST',
    data: { teamId, ownerName, ownerEmail },
  });

  return response?.data?.wevo;
};

export default function useCreateWevo() {
  const queryClient = useQueryClient();
  return useMutation(createWevo, {
    mutationKey: 'createWevo',
    onSuccess: (wevo) => {
      queryClient.invalidateQueries(['wevoData', { wevoId: wevo.id }]);
    },
  });
}
