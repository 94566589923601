import BoltIcon from '@mui/icons-material/Bolt';
import { Box, Chip, Grid, Switch, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { default as React, useCallback, useState } from 'react';
import { snackbar } from '../../../../../notifications';
import theme from '../../../../../theme';
import useSaveWevo from '../../../hooks/useSaveWevo';

const QualitativeExpectationsSelect = ({ draft }) => {
  const { mutate: saveWevo } = useSaveWevo();

  const [includeQualitativeExpectations, setIncludeQualitiativeExpectations] = useState(
    draft?.details?.includeQualitativeExpectations ?? false
  );

  const handleIncludeExpectationsSelect = useCallback(
    (ev) => {
      const prevVal = includeQualitativeExpectations;
      const newVal = ev.target.checked;
      setIncludeQualitiativeExpectations(newVal);
      saveWevo(
        { id: draft.id, includeQualitativeExpectations: newVal },
        {
          onError: (err) => {
            setIncludeQualitiativeExpectations(prevVal);
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    },
    [saveWevo, draft, includeQualitativeExpectations, setIncludeQualitiativeExpectations]
  );

  return (
    <>
      <Box mb={2}>
        <Typography paragraph variant="h5">
          Qualitative Expectations
        </Typography>
      </Box>
      <Box>
        <Grid container justifyContent="start" spacing={2} sx={{ flexWrap: 'nowrap' }}>
          <Grid item sx={{ marginTop: -0.75, marginLeft: -1 }}>
            <Switch
              checked={includeQualitativeExpectations}
              onChange={handleIncludeExpectationsSelect}
              color="primary"
              size="medium"
            />
          </Grid>
          <Grid item>
            <Grid container justifyContent="start" spacing={2}>
              <Grid item container justifyContent="start" spacing={2} xs={12} sx={{ flexWrap: 'nowrap' }}>
                <Grid item>
                  <Typography>Quotes from users in the Expectations section</Typography>
                </Grid>
                {!includeQualitativeExpectations && (
                  <Grid item xs={3}>
                    <Chip
                      label={'Faster'}
                      size="small"
                      icon={<BoltIcon color={theme.palette.primary.main} />}
                      variant="outlined"
                      sx={{
                        color: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                        borderWidth: '1px',
                        borderRadius: '5px',
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item container xs={12}>
                <Typography variant="body2">
                  Turn on if you would like to see user quotes in the Expectations section of WEVO report.
                  Please note that doing so will slow the delivery time of the entire report.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

QualitativeExpectationsSelect.propTypes = {
  draft: PropTypes.object,
};

export default QualitativeExpectationsSelect;
