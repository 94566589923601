import { Box, Grid, Typography } from '@mui/material';
import { memo, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { BounceLoader } from 'react-spinners';
import { ReactComponent as UploadIcon } from '../../../assets/intake-image-upload-icon.svg';
import { ACCEPTED_FILE_TYPES, FILE_ERROR_CODES, MAX_DEEP_DIVE_STEPS, MAX_FILE_SIZE } from '../form/constants';
import { getFilesWithError } from '../form/helpers/files';

const FileTooLargeError = ({ fileRejections }) => {
  const largeFiles = getFilesWithError(fileRejections, FILE_ERROR_CODES.FILE_TOO_LARGE);

  if (largeFiles.length === 0) return <></>;

  return (
    <Box>
      <Typography variant="caption" sx={{ display: 'block', color: '#878787' }}>
        Files must be less than 20 MB:
      </Typography>
      <Box>
        {largeFiles?.map((file, index) => (
          <Typography
            key={`${index}-${file?.file?.name}`}
            variant="caption"
            sx={{
              display: 'block',
              color: '#878787',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {file?.file?.name}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

const AssetUploadCard = ({ handleFileInput, multiple, isUploading, maxFiles = 1, disabled = false }) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    accept: ACCEPTED_FILE_TYPES,
    maxFiles: maxFiles,
    maxSize: MAX_FILE_SIZE,
    multiple: multiple,
    onDrop: handleFileInput,
  });

  const hasFileTooLarge = useMemo(() => {
    const largeFiles = getFilesWithError(fileRejections, FILE_ERROR_CODES.FILE_TOO_LARGE);
    return largeFiles?.length > 0;
  }, [fileRejections]);

  const tooManyFilesError = useMemo(() => {
    if (fileRejections.length > 0) {
      const filesExceedingLimit = getFilesWithError(fileRejections, FILE_ERROR_CODES.TOO_MANY_FILES);
      if (filesExceedingLimit.length === 0) return '';

      return `You can only upload up to ${MAX_DEEP_DIVE_STEPS} files`;
    }
    return '';
  }, [fileRejections]);

  const getCardContent = () => {
    return (
      <>
        {!isUploading && (
          <>
            <input {...getInputProps()} disabled={disabled} />
            <Grid container item direction="column">
              <Grid item xs={12} sx={{ width: '100%' }}>
                <Box>
                  <Box mt={2} mb={1.5}>
                    <UploadIcon
                      style={{
                        width: 30,
                        height: 30,
                      }}
                    />
                  </Box>
                  <Typography color="primary" fontSize={14} fontWeight={400} sx={{ color: '#212A37' }}>
                    Drag and drop or Choose one or multiple files to upload
                  </Typography>
                  <Typography variant="caption" sx={{ color: '#878787' }}>
                    PNG, JPG, or JPEG
                  </Typography>
                </Box>
              </Grid>
              {isDragReject && (
                <Grid item xs={12} mt={1} sx={{ width: '100%' }}>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    Sorry, either the file type is not accepted or too many files were uploaded.
                  </Typography>
                </Grid>
              )}
              {hasFileTooLarge && (
                <Grid item xs={12} mt={1} sx={{ width: '100%' }}>
                  <FileTooLargeError fileRejections={fileRejections} />
                </Grid>
              )}
              {tooManyFilesError && (
                <Grid item xs={12} mt={1} sx={{ width: '100%' }}>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {tooManyFilesError}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}
        {isUploading && (
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <BounceLoader color="#212A37" size={40} aria-label="loading spinner" />
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'text.secondary' }}>Uploading...</Typography>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        borderRadius: '10px',
        backgroundColor: isDragActive && !isDragReject ? '#F3F8FB' : '#FFFFFF',
        '&:hover': {
          backgroundColor: '#F3F8FB',
        },
        '&:focus-visible': {
          backgroundColor: '#F3F8FB',
        },
      }}>
      <Grid
        container
        sx={{
          minHeight: 175,
          border: isDragActive && !isDragReject ? '1.5px solid #3B6CAB' : '1.5px solid #C7D6DF',
          borderRadius: '10px',
        }}>
        {disabled ? (
          <Grid
            container
            item
            alignItems="center"
            sx={{
              textAlign: 'center',
              padding: 2,
            }}>
            {getCardContent()}
          </Grid>
        ) : (
          <Grid
            container
            item
            alignItems="center"
            {...getRootProps()}
            sx={{
              textAlign: 'center',
              padding: 2,
            }}>
            {getCardContent()}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default memo(AssetUploadCard);
