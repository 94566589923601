import { Box, Typography } from '@mui/material';

function EndOfSurveyBlock() {
  return (
    <Box>
      <Typography textAlign="center">We thank you for your time spent taking this survey.</Typography>
      <Box mb={2} />
      <Typography textAlign="center">Your response has been recorded.</Typography>
      <Box mb={2} />
      <Typography textAlign="center" fontSize={12} color="##878787">
        (End of Survey)
      </Typography>
      <Box mb={2} />
    </Box>
  );
}

export default EndOfSurveyBlock;
