import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { isNumber } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import useCreateFeasibilityRequest from '../../hooks/useFeasibility';
import * as UserActions from '../../modules/user/actions';
import { isAuthenticated } from '../../modules/user/helpers';
import { getUserCustomizations, getUserProfile } from '../../modules/user/selectors';
import { snackbar } from '../../notifications';
import { TrackEvent, useTrackPageLoad } from '../analytics';

const SurveyContentOptions = [
  '',
  'Live website link',
  'Protoype Link (Figma, staging, etc.)',
  'No link',
  'Not sure at this time',
];

const StudyTypes = {
  WevoPro: 'wevo_pro',
  WevoVideo: 'wevo_video',
};

const DefaultStudyTypeOptions = [
  { label: 'Wevo Pro', value: StudyTypes.WevoPro },
  { label: 'Wevo Video', value: StudyTypes.WevoVideo },
];

const AudienceProfiles = {
  B2B: 'B2B',
  B2C: 'B2C',
};

const NumericInputField = forwardRef(({ value, min, max, onChange, error, ...rest }, ref) => {
  const handleNumberInputChanged = (ev) => {
    const newValue = Number(ev.target.value);

    if (!isNumber(newValue) || isNaN(newValue)) {
      return;
    }

    onChange && onChange(Math.min(Math.max(newValue, min), max));
  };

  return (
    <TextField
      size="small"
      sx={formStyles.inputField}
      inputRef={ref}
      onChange={handleNumberInputChanged}
      value={value}
      variant="outlined"
      fullWidth
      error={error}
      InputProps={{ inputProps: { min: min, max: max } }}
      {...rest}
    />
  );
});

export function CreateFeasibilitySuccessPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const feasibilityRequestId = queryParams.get('feasibilityRequestId');

  return (
    <Box m={4}>
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Box sx={{ background: 'white', p: 4, borderRadius: 4 }}>
            <Typography variant="h2" textAlign="center">
              Your feasibility request has been successfully submitted.
            </Typography>
            <Box mb={4}></Box>
            {feasibilityRequestId && (
              <Typography variant="h3" textAlign="center">
                Your Feasibility Request ID is {feasibilityRequestId}. Please keep it for your records.
              </Typography>
            )}
            <Box mb={4}></Box>
            <Typography textAlign="center">
              Thank you for submitting a feasibility request. Our team will review your submission and get in
              touch within 24-48 hours.
            </Typography>
            <Box mb={2}></Box>
            <Box textAlign="center">
              <Link to="/feasibility-request/create">Submit another feasibility request</Link>{' '}
            </Box>
            <Box textAlign="center" my={0.5}>
              OR
            </Box>
            <Box textAlign="center">
              <Link to="/dashboard">Go back to dashboard</Link>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </Box>
  );
}

const formStyles = {
  inputField: {
    marginTop: (theme) => theme.spacing(1),
    marginBottom: (theme) => theme.spacing(1),
  },
  nextBtn: {
    padding: '8px 24px',
  },
  deleteIcon: {
    marginLeft: (theme) => theme.spacing(0.5),
    marginTop: (theme) => theme.spacing(0.2),
    height: '10px',
    color: (theme) => theme.palette.primary.main,
    stroke: (theme) => theme.palette.primary.main,
    strokeWidth: '0.5',
  },
  dropDownCard: {
    height: '40px',
    marginTop: (theme) => theme.spacing(-1),
    marginBottom: (theme) => theme.spacing(1),
  },
  dropDownChip: {
    '&:hover': {
      backgroundColor: grey[100],
    },
    backgroundColor: 'white',
    color: 'grey',
    fontSize: '12px',
    marginTop: (theme) => theme.spacing(1),
    marginLeft: (theme) => theme.spacing(0.75),
  },
  emailChip: {
    backgroundColor: 'white',
    color: 'grey',
    fontSize: '12px',
    marginRight: (theme) => theme.spacing(1),
    marginBottom: (theme) => theme.spacing(1),
    maxWidth: 390,
  },
  chipsArea: {
    maxHeight: 110,
    overflowY: 'auto',
    margin: (theme) => theme.spacing(1, 0),
  },
  container: {
    padding: 0,
  },
};

export function CreateFeasibilityRequestForm({ user, userCustomizations, onSubmit, isLoading }) {
  const buildSchema = () =>
    yup.object({
      firstName: yup.string().trim().required('First name is required.').min(2).label('First name'),
      lastName: yup.string().trim().required('Last name is required.').min(2).label('Last name'),
      emailAddress: yup
        .string()
        .trim()
        .email('Invalid email address')
        .required('You must provide an email address to contact')
        .label('Email address'),
      audienceCriteria: yup
        .string()
        .trim()
        .required('Audience criteria is required.')
        .min(2)
        .label('Audience criteria'),
      contactConsent: yup.bool().test({
        name: 'contact-consent-required',
        test: (value) => value === true,
        // eslint-disable-next-line no-template-curly-in-string
        message:
          'You must consent to receive communications from WEVO in order for us to fulfill this request.',
      }),
    });

  const customStudyTypeOverrides = userCustomizations?.feasibility?.studyTypeOptions;
  const studyTypeOptions = customStudyTypeOverrides || DefaultStudyTypeOptions;
  const optionsByValue = studyTypeOptions.reduce((acc, cur) => {
    acc[cur.value] = cur;
    return acc;
  }, {});

  const formDefaults = () => {
    return {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      emailAddress: user?.email || '',
      targetN: 0,
      audienceCriteria: '',
      assetDescription: '',
      audienceProfiles: [AudienceProfiles.B2B, AudienceProfiles.B2C],
      studyTypes: customStudyTypeOverrides
        ? customStudyTypeOverrides.map((option) => option.value)
        : [StudyTypes.WevoPro, StudyTypes.WevoVideo],
      contactConsent: false,
      file: null,
    };
  };

  const [defaultValues] = useState(formDefaults());

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
    resolver: yupResolver(buildSchema()),
  });

  return (
    <div>
      <Box mb={2}>
        <Typography variant="h2" textAlign="center" mb={2}>
          Feasibility Request Form
        </Typography>

        <Typography fontSize={12} textAlign="center" fontStyle="italic" mb={2}>
          Thank you for your interest in running a test with Wevo! Please fill out the following details to
          help us assess the feasibility of reaching your desired audience.
        </Typography>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)} id="create-feasibility-request-form">
        <Controller
          control={control}
          name="firstName"
          render={({ field: { onChange, onBlur, ref, value }, fieldState: { error, invalid } }) => (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Typography sx={{ lineHeight: 1.5 }} fontSize={14} fontWeight={500}>
                First Name*
              </Typography>
              <TextField
                size="small"
                sx={formStyles.inputField}
                type="text"
                onBlur={onBlur}
                onChange={onChange}
                inputRef={ref}
                variant="outlined"
                fullWidth
                value={value}
                required
                autoFocus
                error={invalid}
              />
              {error?.message && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field: { onChange, onBlur, ref, value }, fieldState: { error, invalid } }) => (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Typography sx={{ lineHeight: 1.5 }} fontSize={14} fontWeight={500}>
                Last Name*
              </Typography>
              <TextField
                size="small"
                sx={formStyles.inputField}
                type="text"
                onBlur={onBlur}
                onChange={onChange}
                inputRef={ref}
                variant="outlined"
                fullWidth
                value={value}
                required
                error={invalid}
              />
              {error?.message && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="emailAddress"
          render={({ field: { onChange, onBlur, ref, value }, fieldState: { error, invalid } }) => (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Typography sx={{ lineHeight: 1.5 }} fontSize={14} fontWeight={500}>
                Email Address*
              </Typography>
              <TextField
                size="small"
                sx={formStyles.inputField}
                inputRef={ref}
                type="email"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                required
                variant="outlined"
                fullWidth
                autoComplete="email"
                error={invalid}
              />
              {error?.message && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="targetN"
          render={({ field: { onBlur, onChange, ref, value }, fieldState: { error, invalid } }) => (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Typography sx={{ lineHeight: 1.5 }} fontSize={14} fontWeight={500}>
                Target N
              </Typography>
              <Typography variant="caption" sx={{ mb: 1 }}>
                Total Number of Respondents per Test. If this is not yet known, leave as zero
              </Typography>
              <NumericInputField
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                min={0}
                max={350}
                ref={ref}
              />
              {error?.message && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="audienceCriteria"
          render={({ field: { onBlur, onChange, ref, value }, fieldState: { error, invalid } }) => (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Typography sx={{ lineHeight: 1.5 }} fontSize={14} fontWeight={500}>
                Audience Criteria*
              </Typography>
              <Typography variant="caption">Please enter the details of your target audience</Typography>
              <TextField
                ref={ref}
                placeholder=""
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                variant="outlined"
                multiline
                minRows={4}
                error={invalid}
              />
              {error?.message && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="assetDescription"
          render={({ field: { onBlur, onChange, ref, value }, fieldState: { error, invalid } }) => (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Typography sx={{ lineHeight: 1.5 }} fontSize={14} fontWeight={500}>
                Asset Description
              </Typography>
              <Typography variant="caption">
                If known, please indicate if the survey will contain any links (live website or
                prototype/staging)
              </Typography>
              <Select
                size="small"
                sx={formStyles.inputField}
                inputRef={ref}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                variant="outlined"
                fullWidth
                error={invalid}
                placeholder="Please select the option that best describes your study asset">
                {SurveyContentOptions.map((option, index) => {
                  return (
                    <MenuItem value={option} key={index}>
                      {option === '' ? '---' : option}
                    </MenuItem>
                  );
                })}
              </Select>
              {error?.message && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="audienceProfiles"
          render={({ field: { onBlur, onChange, ref, value }, fieldState: { error, invalid } }) => (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Typography sx={{ lineHeight: 1.5 }} fontSize={14} fontWeight={500}>
                Audience Profiles
              </Typography>
              <Typography variant="caption">
                If known, please indicate if you are interested in B2B, B2C, or both
              </Typography>
              <Select
                size="small"
                multiple
                sx={formStyles.inputField}
                inputRef={ref}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                variant="outlined"
                fullWidth
                error={invalid}
                renderValue={(selected) => selected.join(', ')}
                placeholder="Please select the options that best describe your intended audience profiles">
                {[AudienceProfiles.B2B, AudienceProfiles.B2C].map((option, index) => {
                  return (
                    <MenuItem value={option} key={index}>
                      <Checkbox checked={value.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  );
                })}
              </Select>
              {error?.message && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="studyTypes"
          render={({ field: { onBlur, onChange, ref, value }, fieldState: { error, invalid } }) => (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Typography sx={{ lineHeight: 1.5 }} fontSize={14} fontWeight={500}>
                Study Types
              </Typography>
              <Typography variant="caption">
                The study formats you are interested in inquiring about
              </Typography>
              <Select
                size="small"
                multiple
                sx={formStyles.inputField}
                inputRef={ref}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                variant="outlined"
                fullWidth
                error={invalid}
                renderValue={(selected) => selected.map((value) => optionsByValue[value].label).join(', ')}
                placeholder="Please select the options that best describe your intended study formats">
                {studyTypeOptions.map((option, index) => {
                  return (
                    <MenuItem value={option.value} key={index}>
                      <Checkbox checked={value.indexOf(option.value) > -1} />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  );
                })}
              </Select>
              {error?.message && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="file"
          render={({ field: { onBlur, onChange, ref, value }, fieldState: { error, invalid } }) => (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Typography sx={{ lineHeight: 1.5 }} fontSize={14} fontWeight={500}>
                Attachment Upload
              </Typography>
              <Typography variant="caption">
                If you have a file you'd like to attach, please enter it here
              </Typography>
              <TextField
                size="small"
                sx={formStyles.inputField}
                inputRef={ref}
                type="file"
                onChange={(ev) => {
                  onChange(ev.target.files);
                }}
                onBlur={onBlur}
                variant="outlined"
                fullWidth
                error={invalid}
              />
              {error?.message && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="contactConsent"
          render={({ field: { onBlur, onChange, ref, value }, fieldState: { error, invalid } }) => (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <FormControlLabel
                control={<Checkbox ref={ref} checked={value} onChange={onChange} onBlur={onBlur} />}
                label={<Typography>I agree to receive followup communications from WEVO.*</Typography>}
              />
              <FormHelperText></FormHelperText>
              {error?.message && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </FormControl>
          )}
        />
        <Typography variant="caption">
          By clicking submit below, you consent to allow WEVO to store and process the personal information
          submitted above to provide you the content requested.
        </Typography>
        <Box textAlign="right">
          <Button type="submit" color="primary" variant="contained" disabled={isLoading || !isValid}>
            Submit
          </Button>
        </Box>
      </form>
    </div>
  );
}

export function CreateFeasibilityRequestFormContainer() {
  useTrackPageLoad({
    name: TrackEvent.VIEWED_FEASIBILITY_FORM,
  });

  const userProfile = useSelector(getUserProfile);
  const userCustomizations = useSelector(getUserCustomizations);
  const [isProcessing, setIsProcessing] = useState(false);
  const history = useHistory();

  const { mutate: createFeasbilityRequest } = useCreateFeasibilityRequest();

  const dispatch = useDispatch();

  // Make sure the user of the app is authenticated
  useEffect(() => {
    if (!isAuthenticated()) {
      dispatch(UserActions.fetchUserInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (data) => {
    setIsProcessing(true);

    const requestParams = {
      ...data,
      // handle FileList object coming from form; note if user clicks 'choose file' a second time, but cancels
      // data.file is a FileList object with no items, so we also map that to null
      file: data?.file ? data.file[0] || null : null,
    };

    createFeasbilityRequest(requestParams, {
      onSuccess: (data) => {
        history.push(`/feasibility-request/success?feasibilityRequestId=${data?.feasibilityRequest?.id}`);
        setIsProcessing(false);
      },
      onError: () => {
        snackbar.error('An error occurred processing your request. Please contact us.');
        setIsProcessing(false);
      },
    });
  };

  if (!userProfile?.id || !userCustomizations) {
    return (
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!userCustomizations?.feasibility?.enabled) {
    history.push('/dashboard');
    return <></>;
  }

  return (
    <Box m={4}>
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Box sx={{ background: 'white', p: 4, borderRadius: 4 }}>
            <CreateFeasibilityRequestForm
              user={userProfile}
              userCustomizations={userCustomizations}
              onSubmit={handleSubmit}
              isLoading={isProcessing}
            />
          </Box>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </Box>
  );
}
