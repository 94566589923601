import { cloneDeep, isNil } from 'lodash';

const TOP_LEVEL_FIELDS = ['name', 'scopes'];
const QUESTION_FIELDS = [
  'labels',
  'labelsType',
  'questionText',
  'followUpQuestionText',
  'type',
  'randomization',
];

// When we do optimistic updates, the structure we send to the backend must be merged at multiple levels
// to match the structure when we read it back out. This helper function handles merging the updates in a way
// that emulates what would happen if we re-queried the backend
export const mergeUpdates = ({ customQuestion, updateFields }) => {
  const newState = cloneDeep(customQuestion);

  for (const [key, value] of Object.entries(updateFields || {})) {
    if (TOP_LEVEL_FIELDS.includes(key)) {
      newState[key] = value;
    }

    if (QUESTION_FIELDS.includes(key)) {
      newState.question[key] = value;
    }

    // if we unset the labelsType, the backend automatically resets the labels
    if (key === 'labelsType' && isNil(value)) {
      newState.question.labels = [];
    }

    if (key === 'validation') {
      newState.question.rangeType = value.rangeType;
      newState.question.number = value.number;
    }
  }

  return newState;
};
