import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as PulseInfoIcon } from '../../../src/assets/pulse-info-icon.svg';
import {
  AutomatedInsightQuantToChatMenu,
  AutomatedInsightSections,
  AutomatedInsightSessionType,
} from '../../modules/automated-insights/constants';
import { TrackEvent } from '../analytics';
import Carousel from '../reports/components/Carousel';
import PromptsSelect from './PromptsSelect';

const SUGGESTIONS = [
  'For exploratory research, open-ended questions tend to be the most effective.',
  'For hypothesis testing, be clear and specific, especially regarding the output that you are seeking.',
  'Avoid using words like “you”; instead use “respondents”, “the audience”, or “users.”',
  'If the question is complex, it is best to break it down into two questions.',
];

const SECTIONS = {
  [AutomatedInsightSessionType.Page]: [
    'TopRated',
    AutomatedInsightSections.KeyFindings,
    AutomatedInsightSections.SentimentMap,
    AutomatedInsightSections.PersonaIntent,
    AutomatedInsightSections.RecommendedExperiments,
    'EQSIntuitive',
    'EQSTrusted',
    'EQSValuable',
  ],
  [AutomatedInsightSessionType.Experience]: [
    'TopRated',
    AutomatedInsightSections.KeyFindings,
    AutomatedInsightSections.SentimentMap,
    AutomatedInsightSections.PersonaIntent,
    AutomatedInsightSections.RecommendedExperiments,
    'EQSIntuitive',
    'EQSTrusted',
    'EQSValuable',
  ],
};

const TipsTooltip = styled(({ className, maxWidth, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FCFCFC',
    color: theme.palette.text.primary,
    borderRadius: '24px',
    border: '1px solid #E0E0E0',
    maxWidth: maxWidth,
  },
}));

const Tips = () => {
  return (
    <Box p={2}>
      <Typography component="span" sx={{ fontSize: 12 }}>
        Tips on using the chat feature in WEVO Pulse:
      </Typography>
      <Box>
        <ul style={{ marginBottom: 0, paddingInlineStart: '24px' }}>
          {SUGGESTIONS.map((item, index) => (
            <li key={index}>
              <Typography fontSize="12px">{item}</Typography>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

const QuestionExample = ({ question, isSelected, disabled = false }) => (
  <Box
    sx={{
      minHeight: '70px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: isSelected ? 'rgba(43, 77, 101, 0.11)' : '#FCFCFC',
      borderRadius: '16px',
      border: '0.5px solid #E0E0E0',
      paddingX: 2,
      paddingY: 1,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: disabled ? '#FCFCFC' : 'rgba(43, 77, 101, 0.11)',
      },
    }}>
    {disabled ? (
      <Tooltip title="Only one message can be sent on a Pulse at once." placement="top">
        <Typography fontSize="11px">{question}</Typography>
      </Tooltip>
    ) : (
      <Typography fontSize="11px">{question}</Typography>
    )}
  </Box>
);

const ChatInfo = ({
  sessionId,
  chatUserId,
  ownerUserId,
  currentInput,
  onQuestionSelection,
  showCarousel,
  sessionType,
  submitChat,
  showChatTextbox,
  canSend,
  prompts = [],
  quantCategory = null,
}) => {
  const [expandChatInfo, setExpandChatInfo] = useState(true);
  const [showToggleTooltip, setShowToggleTooltip] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState('TopRated');

  useEffect(() => {
    setSelectedMenuItem(quantCategory ? AutomatedInsightQuantToChatMenu[quantCategory] : 'TopRated');
  }, [quantCategory]);

  const { track } = useAnalytics();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMdAndUp = useMediaQuery(theme.breakpoints.up('md'));
  const isLgAndUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isXlAndUp = useMediaQuery(theme.breakpoints.up('xl'));

  const isSessionOwner = !!chatUserId && chatUserId === ownerUserId;
  const suggestedPromptsDisabled = !showChatTextbox && !canSend;

  const { width: containerWidth, ref: containerRef } = useResizeDetector();

  const handleChatInfoToggled = () => {
    setExpandChatInfo(!expandChatInfo);
    setShowToggleTooltip(false);
  };

  const handleQuestionClick = ({ item }) => {
    track(TrackEvent.SELECTED_SUGGESTED_PULSE_CHAT_QUESTION, {
      sessionId,
      question: item,
      isSessionOwner,
      isAutheticated: !!chatUserId,
    });
    onQuestionSelection(item);
    if (!showChatTextbox) {
      submitChat(item);
    }
  };

  const getNumOfItemsToDisplay = () => {
    if (isXlAndUp) {
      return 3.1;
    } else if (isLgAndUp) {
      return 2.5;
    } else if (isSm || isMdAndUp) {
      return 2.2;
    } else {
      return 1.2;
    }
  };

  const changeSection = (ev) => {
    const newSelectedMenuItem = ev.target.value;

    if (newSelectedMenuItem !== selectedMenuItem) {
      setSelectedMenuItem(newSelectedMenuItem);
      track(TrackEvent.SELECTED_PULSE_CHAT_SUGGESTED_PROMPTS_MENU_ITEM, {
        suggestedPromptsMenuItem: newSelectedMenuItem,
        isSessionOwner,
        isAutheticated: !!chatUserId,
      });
    }
  };

  return (
    <Grid container p={2} spacing={1}>
      <Grid item container justifyContent="space-between" alignItems="center" ref={containerRef}>
        <Box>
          <Grid container>
            <TipsTooltip placement="right" maxWidth={containerWidth * 0.9} title={<Tips />}>
              <IconButton aria-label="tips" size="small">
                <PulseInfoIcon />
              </IconButton>
            </TipsTooltip>
            <Box sx={{ marginLeft: 0.7 }}>
              <PromptsSelect
                selectedMenuItem={selectedMenuItem}
                sections={SECTIONS?.[sessionType] || SECTIONS[AutomatedInsightSessionType.Page]}
                onMenuItemChange={changeSection}
              />
            </Box>
          </Grid>
        </Box>
        <Box onClick={handleChatInfoToggled} sx={{ cursor: 'pointer' }}>
          {!expandChatInfo && (
            <Typography variant="caption" pr={2}>
              View Suggested Prompts
            </Typography>
          )}
          <Tooltip
            open={showToggleTooltip}
            title={expandChatInfo ? 'Hide suggested prompts' : ''}
            placement="top-end"
            disableFocusListener={true}
            onOpen={() => setShowToggleTooltip(true)}
            onClose={() => setShowToggleTooltip(false)}>
            <span>
              <IconButton sx={{ width: 14, height: 14 }}>
                {expandChatInfo ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Grid>
      <Grid
        item
        container
        spacing={1}
        alignItems="stretch"
        sx={{
          display: expandChatInfo ? 'flex' : 'none',
          borderRadius: '20px',
        }}>
        {showCarousel ? (
          <Carousel
            items={prompts?.[selectedMenuItem]}
            itemComponent={({ index, style, item }) => {
              return (
                <QuestionExample
                  question={item}
                  isSelected={item === currentInput}
                  disabled={suggestedPromptsDisabled}
                />
              );
            }}
            numItemsDisplayed={getNumOfItemsToDisplay()}
            itemHeight={isLgAndUp ? 80 : 90}
            backgroundColor={'#FFFFFF'}
            arrowHeight={16}
            onSelection={!suggestedPromptsDisabled && handleQuestionClick}
          />
        ) : (
          prompts?.[selectedMenuItem].map((question, index) => (
            <Grid
              item
              xs={6}
              key={index}
              onClick={() => !suggestedPromptsDisabled && handleQuestionClick(question)}>
              <QuestionExample
                question={question}
                isSelected={showChatTextbox && question === currentInput}
                disabled={suggestedPromptsDisabled}
              />
            </Grid>
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default ChatInfo;
