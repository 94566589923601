import { groupBy, isEmpty, isNil } from 'lodash';
import { CustomScreenerTypes, OutcomeOptions } from '../../../../../modules/intake/constants';
import { CustomScreenerTypeOptionsByType, ValidationKeys } from '../constants';

const CustomScreenerTypeToValidationHandler = {
  [CustomScreenerTypes.FiveLikertScale]: validateLikertQuestion,
  [CustomScreenerTypes.SevenLikertScale]: validateLikertQuestion,
  [CustomScreenerTypes.YesNoTrueFalse]: validateBinaryQuestion,
  [CustomScreenerTypes.MultipleChoice]: validateMultipleChoiceQuestion,
  [CustomScreenerTypes.MultiSelect]: validateMultipleSelectionQuestion,
};

export const validateCustomScreeners = (customScreeners, configuration) => {
  const errors = (customScreeners ?? []).reduce((acc, cur) => {
    acc[cur.id] = [];
    return acc;
  }, {});

  for (const customScreener of customScreeners ?? []) {
    errors[customScreener.id] = validateCustomScreener(customScreener);
  }

  const globalErrors = validateGlobalErrors(customScreeners ?? [], configuration?.global || {});

  return {
    questionErrors: errors,
    globalErrors,
  };
};

export const validateCustomScreener = (customScreener) => {
  const errors = [];

  const questionTypeValidator = CustomScreenerTypeToValidationHandler?.[customScreener?.questionType];

  if (questionTypeValidator) {
    errors.push(...questionTypeValidator({ customScreener }));
  }

  return groupBy(errors, 'key');
};

const validateGlobalErrors = (customScreeners, { questionLimit, forceNoLimit }) => {
  const globalErrors = [];

  if (!isNil(questionLimit) && !forceNoLimit) {
    const limitExceeded = (customScreeners?.length || 0) - questionLimit;

    const question = limitExceeded === 1 ? 'question' : 'questions';
    if (limitExceeded > 0) {
      globalErrors.push({
        key: ValidationKeys.LimitExceeded,
        reason: `Custom screener question limit exceeded. Please remove ${limitExceeded} ${question}.`,
      });
    }
  }

  return groupBy(globalErrors, 'key');
};

function validateMultipleChoiceQuestion({ customScreener }) {
  return validateGenericMultipleChoiceQuestion({ customScreener });
}

function validateLikertQuestion({ customScreener }) {
  return validateGenericMultipleChoiceQuestion({ customScreener });
}

function validateBinaryQuestion({ customScreener }) {
  return validateGenericMultipleChoiceQuestion({ customScreener });
}

function validateMultipleSelectionQuestion({ customScreener }) {
  const errors = [
    ...validateQuestionName({ customScreener }),
    ...validateQuestionText({ customScreener }),
    ...validateLabelsContent({ customScreener }),
    ...validateOutcomes({ customScreener }),
  ];

  return errors;
}

function validateGenericMultipleChoiceQuestion({ customScreener }) {
  // this function abstracts over the common validations for any question type
  // that presents multiple choices to a respondent and requires that they pick exactly one option
  const errors = [
    ...validateQuestionName({ customScreener }),
    ...validateQuestionText({ customScreener }),
    ...validateLabelsContent({ customScreener }),
    ...validateOutcomes({ customScreener }),
  ];

  const limits = CustomScreenerTypeOptionsByType[customScreener?.questionType]?.choiceLimits;

  if (!limits) {
    return errors;
  }
  const labelsType = customScreener?.details?.labelsType;

  if (isEmpty(labelsType)) {
    errors.push({ key: ValidationKeys.LabelsType, reason: 'Scale is required.' });
  }

  return errors;
}

function validateQuestionName({ customScreener }) {
  // This field is optional, so don't validate it if not present
  // If present, then it should be at least one character and at most 20 characters
  const questionName = customScreener?.name;

  if (isEmpty(questionName)) {
    return [];
  }

  if (questionName.length < 1 || questionName.length > 20) {
    return [
      {
        key: ValidationKeys.QuestionName,
        reason:
          'Question name must contain between 2-20 characters. This name will be used to identify your question throughout the report.',
      },
    ];
  }

  return [];
}

function validateQuestionText({ customScreener }) {
  // Required, must be not null and greater than two characters
  const questionText = customScreener?.questionText;

  if (isEmpty(questionText)) {
    return [
      {
        key: ValidationKeys.QuestionText,
        reason: 'Required field.',
      },
    ];
  }

  if (questionText.length < 2) {
    return [
      {
        key: ValidationKeys.QuestionText,
        reason: 'Question text must contain at least two characters.',
      },
    ];
  }

  return [];
}

function validateLabelsContent({ customScreener }) {
  const labels = customScreener?.options?.map((option) => option?.optionText) ?? [];

  if (labels.some((label) => isEmpty(label))) {
    return [{ key: ValidationKeys.Labels, reason: 'Please make sure none of your choices are blank.' }];
  }

  return [];
}

function validateOutcomes({ customScreener }) {
  const allReject = (customScreener?.options ?? [])?.every(
    (option) => option?.outcome === OutcomeOptions.Reject
  );
  const outcomeOptions = [CustomScreenerTypes.MultiSelect].includes(customScreener?.questionType)
    ? '"Must Select" or "May Select"'
    : '"Is Accepted"';

  if (allReject) {
    return [
      {
        key: ValidationKeys.Labels, // using labels key so that this error can be displayed along with the labels error when serialized
        reason: `Please make sure to select ${outcomeOptions} for at least one option.`,
      },
    ];
  }

  return [];
}

export const serializeErrors = (errors) => (errors ?? []).map((error) => error.reason).join(' ');

export function hasCustomScreenerErrors(validationResult) {
  for (const errors of Object.values(validationResult?.questionErrors ?? {})) {
    if (Object.keys(errors)?.length > 0) return true;
  }

  if (Object.keys(validationResult?.globalErrors ?? {})?.length > 0) return true;

  return false;
}
