import { Box } from '@mui/material';
import { DeleteButton } from '../../components/Buttons';
import AssetTypeSelector from './AssetTypeSelector';
import SentimentMapsConfiguration from './SentimentMapsConfiguration';

function PageConfiguration({
  wevo,
  page,
  canDelete = false,
  isDeleting = false,
  onDelete,
  onFilesUploaded,
  onReorderStep,
  onDeleteStep,
  onStepNameChanged,
  onAssetTypeChanged,
  onIsPrototypeChanged,
  onJourneyUrlChanged,
  isDeletingStep,
  isUploadingSteps,
  errors,
}) {
  const pageErrors = errors?.pageErrors?.[String(page?.id)] ?? {};

  return (
    <Box>
      <Box>
        <AssetTypeSelector
          assetType={page?.assetType}
          onAssetTypeChanged={({ newAssetType }) => onAssetTypeChanged({ page, newAssetType })}
          isPrototype={page?.isPrototype || false}
          onIsPrototypeChanged={({ newIsPrototype }) => onIsPrototypeChanged({ page, newIsPrototype })}
          journeyUrl={page?.journeyStartUrl || ''}
          onJourneyUrlChanged={({ newJourneyUrl }) => onJourneyUrlChanged({ page, newJourneyUrl })}
          errors={pageErrors}
        />
      </Box>
      <Box mb={4} />
      {page?.assetType && (
        <Box>
          <SentimentMapsConfiguration
            wevo={wevo}
            page={page}
            onFilesUploaded={onFilesUploaded}
            onDeleteStep={onDeleteStep}
            onReorderStep={onReorderStep}
            onStepNameChanged={onStepNameChanged}
            isUploadingSteps={isUploadingSteps}
            isDeletingStep={isDeletingStep}
            errors={errors}
          />
        </Box>
      )}
      {canDelete && (
        <DeleteButton isLoading={isDeleting} onClick={() => onDelete && onDelete({ wevo, page })} />
      )}
    </Box>
  );
}

export default PageConfiguration;
