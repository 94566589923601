import { BarController, Chart, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import truncate from 'truncate';
import { memoAreEqual } from '../../../helpers.js';
import { RANK_ORDER_CHART_LABEL } from '../../../modules/report/constants.js';
import { chunkString } from '../../../modules/report/helpers.js';
import theme from '../../../theme.js';

Chart.register(BarController, Legend, Tooltip);

const RankOrderChart = React.memo(
  ({
    labels,
    data,
    numsOfRespondents,
    isDashboard,
    isPptComponent,
    width = '',
    height = '260px',
    componentId,
  }) => {
    const canvasRef = useRef(null);
    const chartRef = useRef(null);

    const dataWithPercentages = data?.map((dataset, index) => ({
      ...dataset,
      data: dataset?.data?.map((score) => (score / numsOfRespondents[index]) * 100 || 0), // Convert scores to percentages
    }));

    const showCustomLegend = useMemo(() => {
      const longestLabelLength = data.reduce((maxLength, dataset) => {
        return Math.max(maxLength, dataset?.label?.length);
      }, 0);

      const maxLength = isDashboard
        ? RANK_ORDER_CHART_LABEL.dashboardMaxCharsInLine
        : RANK_ORDER_CHART_LABEL.maxCharsInLine;

      return longestLabelLength > maxLength;
    }, [data, isDashboard]);

    const config = useMemo(
      () => ({
        type: 'bar',
        data: {
          labels: labels,
          datasets: dataWithPercentages,
        },
        plugins: [ChartDataLabels],
        options: {
          animation: {
            duration: 0, // general animation time, for performance
          },
          indexAxis: 'y',
          responsive: !isPptComponent,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: !showCustomLegend,
              ...(!showCustomLegend && {
                position: 'bottom',
                align: 'start',
                onClick: () => {}, // Disables legend item click
              }),
            },
            tooltip: {
              position: 'average',
              backgroundColor: 'rgba(97, 97, 97, .95)',
              padding: 10,
              caretSize: 0,
              titleFont: {
                family: theme.typography.fontFamily,
                weight: theme.typography.fontWeightBold,
              },
              bodyFont: {
                family: theme.typography.fontFamily,
              },
              callbacks: {
                label: function (context) {
                  const score = data?.[context.datasetIndex]?.data?.[context.dataIndex];
                  const label = data?.[context.datasetIndex]?.label;
                  const truncatedLabel = truncate(label, RANK_ORDER_CHART_LABEL.tooltipMaxChars);
                  const currentPercentage = context?.raw.toFixed(1);

                  const formattedLabel =
                    score > 0 ? `${truncatedLabel}: ${score} (${currentPercentage}%)` : '';

                  const maxCharsInLine = isDashboard
                    ? RANK_ORDER_CHART_LABEL.dashboardMaxCharsInLine
                    : RANK_ORDER_CHART_LABEL.tooltipMaxCharsInLine;

                  return chunkString(formattedLabel, maxCharsInLine);
                },
                title: function (context) {
                  const title = context[0]?.label;
                  return title;
                },
              },
            },
            datalabels: {
              color: 'white',
              formatter: function (value) {
                const percentage = value.toFixed(1);
                return value > 0 ? `${percentage}%` : null;
              },
              font: {
                weight: 'bold',
                size: 13,
              },
              display: function (context) {
                // Retrieve the bar element for the current data point
                const meta = context.chart.getDatasetMeta(context.datasetIndex);
                const barElement = meta.data[context.dataIndex];

                // Ensure the bar element exists
                if (!barElement) {
                  return false;
                }

                // Calculate the width (for horizontal bars: difference between x and base)
                const width = Math.abs(barElement.x - barElement.base);

                // Only display the label if the width is greater than or equal to 40 pixels
                return width >= 40;
              },
            },
          },
          datasets: {
            bar: {
              barPercentage: 0.99,
              backgroundColor: theme.palette.primary.light,
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
                drawBorder: false,
                drawTicks: false,
              },
              ticks: { display: false },
            },
            y: {
              stacked: true,
              grid: {
                display: false,
                drawBorder: true,
              },
            },
          },
        },
      }),
      [data, dataWithPercentages, isDashboard, isPptComponent, labels, showCustomLegend]
    );

    useEffect(() => {
      if (canvasRef.current && !chartRef.current) {
        const ctx = canvasRef.current.getContext('2d');
        chartRef.current = new Chart(ctx, config);
      } else if (chartRef.current) {
        chartRef.current.data = config?.data;
        chartRef.current.options = config?.options;

        chartRef.current.update();
      }

      return () => {
        if (chartRef.current) {
          chartRef.current.destroy();
          chartRef.current = null;
        }
      };
    }, [config]);

    return (
      <>
        <div style={{ height }}>
          <canvas id={componentId} height={height} width={width} ref={canvasRef}></canvas>
        </div>
        {/* Custom Legend */}
        {showCustomLegend && (
          <div className="custom-legend" style={{ textAlign: 'left' }}>
            {data?.map(({ label, backgroundColor }, index) => (
              <div
                key={index}
                className="legend-item"
                style={{ display: 'flex', columnGap: '8px', marginBottom: '8px' }}>
                <div
                  className="legend-color"
                  style={{
                    width: '40px',
                    minWidth: '40px',
                    height: '12px',
                    marginTop: '4px',
                    backgroundColor: backgroundColor,
                    border: '1px solid #666666',
                  }}></div>
                <span
                  className="legend-label"
                  style={{
                    color: '#666666',
                    fontSize: '12px',
                    fontWeight: 500,
                  }}>
                  {label}
                </span>
              </div>
            ))}
          </div>
        )}
      </>
    );
  },
  memoAreEqual
);

RankOrderChart.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  numsOfRespondents: PropTypes.array.isRequired,
  isDashboard: PropTypes.bool,
  isPptComponent: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  componentId: PropTypes.string,
};

export default RankOrderChart;
