import styled from '@emotion/styled';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Button, CircularProgress, Divider, Grid, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import React from 'react';
import theme from '../../../theme';

const CustomFieldTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 245,
    padding: 8,
  },
});

const ContactCsmTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 380,
    minHeight: 95,
    padding: 18,
    borderRadius: '30px',
    backgroundColor: 'white',
    color: 'black',
    border: `1px solid ${grey[300]}`,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
});

export const Products = {
  Pro: 'pro',
  Pulse: 'pulse',
  Video: 'video',
};

export const ProductDisplayName = {
  pro: 'Pro',
  pulse: 'Pulse',
  video: 'Video',
};

const borderColor = {
  [Products.Pro]: '#e1f1fd',
  [Products.Pulse]: '#f1f8ee',
  [Products.Video]: '#fef7e1',
};

const titleColor = {
  [Products.Pro]: '#0f8fda',
  [Products.Pulse]: '#63b74d',
  [Products.Video]: '#e3ac03',
};

const styles = {
  root: { width: '285px', marginBottom: '40px', cursor: 'pointer' },
  card: {
    height: '100%',
    width: '100%',
  },
  cardSelected: {
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  checkBoxImageGrid: {
    height: '56px',
  },
  topSectionGrid: {
    marginBottom: '16px',
  },
  productImage: {
    textAlign: 'center',
    marginLeft: '-32px',
  },
  img: { width: '100px', height: 'auto' },
  proImg: { width: '85px', height: 'auto' },
  startButton: {
    color: 'white',
    height: '40px',
    width: '140px',
    borderRadius: '30px',
  },
  startFont: {
    fontSize: '15px',
  },
  fieldSubTitleGrid: { height: '40px', textAlign: 'center' },
  fieldSubTitleLightGrid: { height: '32px', backgroundColor: '#F2F8FB', textAlign: 'center' },
  fieldText: { marginTop: '-10px', fontSize: '15px' },
  checkIcon: {
    fontSize: '24px',
    color: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
    strokeWidth: 1,
  },
  asterisk: {
    marginTop: '-30px',
    marginLeft: '28px',
  },
  centerText: { textAlign: 'center' },
  divider: { width: '100%', color: 'grey' },
  dividerGrid: { marginBottom: '20px' },
};

const SelectableProductCard = (props) => {
  const {
    image,
    title,
    participants,
    duration,
    price,
    includesLive,
    includesPreLive,
    includesCompare,
    includesCompetitors,
    includesLocalized,
    includesKeyFindings,
    includesSentimentMap,
    includesExperience,
    includesUsabilityMetrics,
    includesVideo,
    isVideoSupported,
    product,
    isSelected,
    isLoading,
    onStartClick,
    setProductSelected,
    enabled,
  } = props;

  let checkbox;
  if (isSelected) {
    checkbox = <RadioButtonChecked color="primary" fontSize="medium" />;
  } else {
    checkbox = <RadioButtonUncheckedIcon fontSize="medium" sx={{ color: enabled ? 'black' : grey[300] }} />;
  }

  const doneIconColor = enabled ? theme.palette.primary.main : grey[300];

  return (
    <Grid item sx={styles.root}>
      <Grid container spacing={2} alignItems="flex-end">
        <Card
          elevation={isSelected ? 4 : 1}
          sx={{
            borderRadius: '30px',
            border: `5px solid ${
              enabled ? (isSelected ? theme.palette.primary.main : borderColor[product]) : grey[100]
            }`,
          }}>
          <ContactCsmTooltip
            title={
              !enabled && (
                <>
                  <Typography variant="h5" sx={{ marginTop: '3px', marginBottom: '10px' }}>
                    <b>{`Activate WEVO ${ProductDisplayName[product]}`}</b>
                  </Typography>
                  <a href="mailto:customersuccess@wevo.ai" target="_top" style={{ textDecoration: 'none' }}>
                    <Typography
                      variant="body1"
                      component="span"
                      style={{
                        color: theme.palette.primary.main,
                        cursor: 'pointer',
                      }}>
                      Contact your CSM
                    </Typography>
                  </a>
                  <Typography variant="body1" component="span">
                    {` to enable ${ProductDisplayName[product]} studies.`}
                  </Typography>
                </>
              )
            }
            placement="top"
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -310],
                  },
                },
              ],
            }}>
            <div onClick={enabled ? setProductSelected : () => {}}>
              <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <Grid container>
                  <Grid item xs={12} sx={styles.checkBoxImageGrid}>
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item xs={4} sx={{ marginLeft: 2 }}>
                        {checkbox}
                      </Grid>
                      <Grid item xs={4} sx={styles.productImage}>
                        <img
                          src={image}
                          alt="selectable"
                          style={product === Products.Pro ? styles.proImg : styles.img}
                        />
                      </Grid>
                      <Grid item xs={4} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ ...styles.topSectionGrid, height: isSelected ? '80px' : '40px' }}>
                    <Grid container spacing={2} justifyItems="center">
                      <Grid item xs={12} sx={styles.centerText}>
                        <Typography
                          sx={{
                            color: enabled ? titleColor[product] : grey[300],
                            fontSize: 18,
                            fontWeight: 450,
                            marginTop: isSelected ? '-6px' : '8px',
                          }}>
                          {title}
                        </Typography>
                      </Grid>
                      {isSelected && (
                        <Grid item xs={12} sx={styles.centerText}>
                          <Button variant="contained" onClick={onStartClick} sx={styles.startButton}>
                            <Typography>
                              {isLoading && <CircularProgress size={16} sx={{ mr: 1, color: 'white' }} />}
                              <b>START</b>
                            </Typography>
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={styles.dividerGrid}>
                    <Divider sx={styles.divider} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
                        <Typography variant="body2" sx={{ ...styles.fieldText, color: !enabled && grey[400] }}>
                          {participants}
                        </Typography>
                      </Grid>
                      <Grid item sx={styles.fieldSubTitleLightGrid} xs={12}>
                        <Typography variant="body2" sx={{ ...styles.fieldText, color: !enabled && grey[400] }}>
                          {duration}
                        </Typography>
                      </Grid>
                      <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
                        <Typography variant="body2" sx={{ ...styles.fieldText, color: !enabled && grey[400] }}>
                          {price}
                        </Typography>
                      </Grid>
                      <Grid item sx={styles.fieldSubTitleLightGrid} xs={12} />
                      <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
                        <Typography variant="body2" sx={styles.fieldText}>
                          {includesLive && (
                            <DoneRoundedIcon
                              sx={{ ...styles.checkIcon, color: doneIconColor, stroke: doneIconColor }}
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item sx={styles.fieldSubTitleLightGrid} xs={12}>
                        <Typography variant="body2" sx={styles.fieldText}>
                          {includesPreLive && (
                            <DoneRoundedIcon
                              sx={{ ...styles.checkIcon, color: doneIconColor, stroke: doneIconColor }}
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
                        <Typography variant="body2" sx={styles.fieldText}>
                          {includesCompare && (
                            <DoneRoundedIcon
                              sx={{ ...styles.checkIcon, color: doneIconColor, stroke: doneIconColor }}
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item sx={styles.fieldSubTitleLightGrid} xs={12}>
                        <Typography variant="body2" sx={styles.fieldText}>
                          {includesCompetitors && (
                            <DoneRoundedIcon
                              sx={{ ...styles.checkIcon, color: doneIconColor, stroke: doneIconColor }}
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
                        {includesLocalized ? (
                          [Products.Pulse, Products.Video].includes(product) ? (
                            <Typography component="span" variant="body2" sx={styles.fieldText}>
                              <CustomFieldTooltip
                                title={
                                  product === Products.Pulse
                                    ? `WEVO Pulse currently supports six languages. It can analyze websites, 
                                  campaigns and messaging in: English, Spanish, Portuguese, German, Italian, 
                                  and French. The analysis appears in English.`
                                    : `You can write your test instructions and questions in any language or 
                                  alphabet. In addition, for video based studies, you can set the language of 
                                  the recorder UI and participant landing pages into 20 different languages.`
                                }
                                placement="bottom">
                                <DoneRoundedIcon
                                  sx={{
                                    ...styles.checkIcon,
                                    color: doneIconColor,
                                    stroke: doneIconColor,
                                    marginTop: '-8px',
                                  }}
                                />
                              </CustomFieldTooltip>
                              <div style={{ ...styles.asterisk, color: !enabled && grey[400] }}>*</div>
                            </Typography>
                          ) : (
                            <DoneRoundedIcon
                              sx={{
                                ...styles.checkIcon,
                                color: doneIconColor,
                                stroke: doneIconColor,
                                marginTop: '-8px',
                              }}
                            />
                          )
                        ) : null}
                      </Grid>
                      <Grid item sx={styles.fieldSubTitleLightGrid} xs={12} />
                      <Grid item sx={{ ...styles.fieldSubTitleGrid }} xs={12}>
                        <Typography component="span" variant="body2" sx={styles.fieldText}>
                          {includesKeyFindings ? (
                            product === Products.Video ? (
                              <>
                                <CustomFieldTooltip
                                  title={`Available at an additional cost. Please contact your CSM`}
                                  placement="bottom">
                                  <DoneRoundedIcon
                                    sx={{
                                      ...styles.checkIcon,
                                      color: doneIconColor,
                                      stroke: doneIconColor,
                                      marginTop: '-8px',
                                    }}
                                  />
                                </CustomFieldTooltip>
                                <div style={{ ...styles.asterisk, color: !enabled && grey[400] }}>*</div>
                              </>
                            ) : (
                              <DoneRoundedIcon
                                sx={{
                                  ...styles.checkIcon,
                                  color: doneIconColor,
                                  stroke: doneIconColor,
                                  marginTop: '-8px',
                                }}
                              />
                            )
                          ) : null}
                        </Typography>
                      </Grid>
                      <Grid item sx={styles.fieldSubTitleLightGrid} xs={12}>
                        <Typography variant="body2" sx={styles.fieldText}>
                          {includesSentimentMap && (
                            <DoneRoundedIcon
                              sx={{ ...styles.checkIcon, color: doneIconColor, stroke: doneIconColor }}
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
                        <Typography variant="body2" sx={styles.fieldText}>
                          {includesExperience && (
                            <DoneRoundedIcon
                              sx={{ ...styles.checkIcon, color: doneIconColor, stroke: doneIconColor }}
                            />
                          )}
                        </Typography>
                      </Grid>
                      {isVideoSupported && (
                        <>
                          <Grid item sx={styles.fieldSubTitleLightGrid} xs={12}>
                            <Typography variant="body2" sx={styles.fieldText}>
                              {includesUsabilityMetrics && (
                                <DoneRoundedIcon
                                  sx={{ ...styles.checkIcon, color: doneIconColor, stroke: doneIconColor }}
                                />
                              )}
                            </Typography>
                          </Grid>
                          <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
                            <Typography variant="body2" sx={styles.fieldText}>
                              {includesVideo && (
                                <DoneRoundedIcon
                                  sx={{ ...styles.checkIcon, color: doneIconColor, stroke: doneIconColor }}
                                />
                              )}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </div>
          </ContactCsmTooltip>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SelectableProductCard;
