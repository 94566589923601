import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useCallback } from 'react';
import AssetUploadCard from './AssetUploadCard';

export default function AssetUploadList({ files, onChange, maxFiles, isUploading, error }) {
  const handleFileInput = useCallback(
    (newFiles) => {
      onChange([...files, ...newFiles]);
    },
    [files, onChange]
  );

  const handleDeleteFile = useCallback(
    (index) => onChange(files.slice(0, index).concat(files.slice(index + 1))),
    [files, onChange]
  );

  return (
    <Box>
      <Grid container rowGap={1} alignItems="center" justifyContent="center" mb={2}>
        <Grid item xs md={10} lg={8}>
          <AssetUploadCard
            handleFileInput={handleFileInput}
            multiple={maxFiles > 1}
            iconColor={'rgba(255, 255, 255, 0.32)'}
            isUploading={isUploading}
            maxFiles={maxFiles}
            background={files.length > 0 ? 'transparent' : null}
          />
        </Grid>
      </Grid>
      <Grid container rowGap={1} alignItems="center" justifyContent="center">
        {files.map((file, index) => {
          return (
            <Grid item key={index} xs={12}>
              <Grid
                container
                sx={{
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: { md: 'center' },
                  justifyContent: 'center',
                  rowGap: 1,
                }}>
                <Grid item md={1}>
                  <Typography color="primary" variant="body2" sx={{ width: '60px' }} noWrap>
                    Page {index + 1}
                  </Typography>
                </Grid>
                <Grid item md={9} lg={8}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      columnGap: 1,
                      borderRadius: 2,
                      border: '1px solid rgba(217, 217, 217, 0.2)',
                      color: 'rgba(221, 221, 221, 0.83)',
                      paddingLeft: 2,
                      paddingRight: 0,
                    }}>
                    <Box
                      sx={{
                        width: '100%',
                        overflowX: 'hidden',
                        wordBreak: 'break-all',
                      }}>
                      <Typography>{file.name}</Typography>
                    </Box>
                    <IconButton
                      onClick={() => handleDeleteFile(index)}
                      color="primary"
                      sx={{
                        textTransform: 'none',
                        opacity: 0.6,
                        '&:hover': {
                          backgroundColor: 'transparent',
                          opacity: 0.8,
                        },
                        '&:focus': {
                          opacity: 0.8,
                        },
                      }}>
                      <CancelRoundedIcon style={{ height: '20px', width: '20px' }} />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item md={1} />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
