import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as ComingSoonIcon } from '../../../src/assets/coming-soon.svg';
import { AutomatedInsightSessionType } from '../../modules/automated-insights/constants';

const features = {
  [AutomatedInsightSessionType.Page]: [
    { label: 'Takeaways', value: true },
    { label: 'Sentiment Map', value: true },
    { label: 'Persona Intent', value: true },
    { label: 'Recommendations', value: true },
    { label: 'EQS Score', value: true },
    { label: 'Benchmarks', value: true },
    { label: 'Insights Assistant', value: true },
    { label: 'Task to Complete', value: true },
    { label: 'Context', value: true },
  ],
  [AutomatedInsightSessionType.Experience]: [
    { label: 'Takeaways', value: true },
    { label: 'Sentiment Map', value: true },
    { label: 'Persona Intent', value: true },
    { label: 'Recommendations', value: true },
    { label: 'EQS Score', value: true },
    { label: 'Benchmarks', value: true },
    { label: 'Insights Assistant', value: true },
    { label: 'Task to Complete', value: true },
    { label: 'Context', value: true },
  ],
  [AutomatedInsightSessionType.Compare]: [
    { label: 'Takeaways', value: true },
    { label: 'Task to Complete', value: true },
    { label: 'Context', value: true },
  ],
};

const FeaturesList = ({ sessionType }) => {
  return (
    <Grid container rowGap={1} sx={{ minWidth: '212px' }}>
      {features[sessionType]?.map((feature) => (
        <Grid item key={feature.label} xs={12}>
          <Grid container alignItems="center" gap={1}>
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              {feature.value ? (
                <DoneRoundedIcon sx={{ height: '12px', color: '#32DAA9' }} />
              ) : (
                <ClearRoundedIcon sx={{ height: '12px', color: '#F44336' }} />
              )}
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography component="span" fontSize={12} sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                  {feature.label}
                </Typography>
                {!feature.value && <ComingSoonIcon />}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default FeaturesList;
