import { Box, Divider, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ValidationKeys as CustomQuestionValidationKeys } from '../../custom-questions/constants';
import { ValidationKeys as AssetValidationKeys } from '../../helpers/validation/assets';
import { serializeErrors } from '../../helpers/validation/shared';
import { ValidationKeys as WevoValidationKeys } from '../../helpers/validation/wevo';
import { isCustomSurveyType } from '../../helpers/wevo';
import AlertMessage from '../shared/AlertMessage';
import AssetPreview from '../shared/AssetPreview';
import FieldRow from '../shared/FieldRow';
import GroupReview from './GroupReview';

const useStyles = makeStyles((theme) => ({
  fieldText: {
    marginLeft: theme.spacing(1.8),
    marginTop: theme.spacing(1.5),
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
    color: '#212A37',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const DetailsSection = ({ wevo, customQuestions, customQuestionErrors, pageErrors, wevoErrors }) => {
  const classes = useStyles();

  const isCustomSurvey = isCustomSurveyType(wevo);

  const primer = wevo?.pages?.[0]?.primer;
  // primer context is only required if there is a primer Id
  // so if there is no primer id or Context, then it will be blank
  const primerContext = wevo?.pages?.[0]?.primerContext || '';
  const taskToComplete = wevo?.details?.taskToComplete || '';

  // expectations are only required if the report includes expectations
  // otherwise it will be blank
  const expectations = wevo?.visitorObjective || '';
  const includeExpectations = wevo?.details?.includeExpectations;

  return (
    <>
      {/* Goal */}
      <FieldRow label={'Goal'} divider={true}>
        {wevo?.description && <Typography className={classes.fieldText}>{wevo?.description}</Typography>}
        {wevoErrors?.[WevoValidationKeys.Goal]?.length > 0 && (
          <AlertMessage message={serializeErrors(wevoErrors[WevoValidationKeys.Goal])} />
        )}
      </FieldRow>
      {/* Primer */}
      <FieldRow label={'Primer'}>
        {pageErrors?.[AssetValidationKeys.PrimerContext]?.length > 0 ? (
          <AlertMessage message={serializeErrors(pageErrors[AssetValidationKeys.PrimerContext])} />
        ) : (
          <Typography className={classes.fieldText}>{primerContext}</Typography>
        )}
      </FieldRow>
      {primer && (
        <FieldRow label={''}>
          <Box ml={2}>
            <AssetPreview asset={primer} />
          </Box>
        </FieldRow>
      )}
      <Divider sx={{ my: 2, borderColor: '#C7D6DF', borderWidth: '1px' }} />
      {/* Task */}
      {/* Task To Complete is not required for custom surveys */}
      {!isCustomSurvey && (
        <FieldRow label={'Task'} divider={true}>
          {wevoErrors?.[WevoValidationKeys.TaskToComplete]?.length > 0 ? (
            <AlertMessage message={serializeErrors(wevoErrors[WevoValidationKeys.TaskToComplete])} />
          ) : (
            <Typography className={classes.fieldText}>{taskToComplete}</Typography>
          )}
        </FieldRow>
      )}
      {/* Expectations */}
      {includeExpectations && (
        <FieldRow label={'Expectations'} divider={true}>
          {wevoErrors?.[WevoValidationKeys.VisitorObjective]?.length > 0 ? (
            <AlertMessage message={serializeErrors(wevoErrors[WevoValidationKeys.VisitorObjective])} />
          ) : (
            <Typography className={classes.fieldText}>{expectations}</Typography>
          )}
        </FieldRow>
      )}
      {/* Custom Questions */}
      <FieldRow label={'Custom Questions'}>
        {customQuestionErrors?.globalErrors?.[CustomQuestionValidationKeys.LimitExceeded]?.length > 0 && (
          <AlertMessage
            message={serializeErrors(
              customQuestionErrors.globalErrors[CustomQuestionValidationKeys.LimitExceeded]
            )}
          />
        )}
        {/* first custom question */}
        <div style={{ marginTop: '8px' }}>
          {customQuestions?.[0] ? (
            <GroupReview
              isCustomSurvey={isCustomSurvey}
              group={customQuestions?.[0]}
              type={wevo?.type}
              pages={wevo?.pages ?? []}
              index={0}
              errors={customQuestionErrors?.questionErrors?.[customQuestions?.[0]?.groupId] ?? {}}
            />
          ) : null}
        </div>
      </FieldRow>
      {/* rest of custom questions */}
      {customQuestions?.slice(1, customQuestions?.length)?.length
        ? customQuestions?.slice(1, customQuestions?.length)?.map((group, index) => (
            <FieldRow label={''} key={index}>
              <Divider sx={{ my: 2, borderColor: '#C7D6DF', borderWidth: '1px' }} />
              <GroupReview
                isCustomSurvey={isCustomSurvey}
                group={group}
                type={wevo?.type}
                pages={wevo?.pages ?? []}
                index={index + 1}
                errors={customQuestionErrors?.questionErrors?.[group?.groupId] ?? {}}
              />
            </FieldRow>
          ))
        : null}
    </>
  );
};

export default DetailsSection;
