import { Box, CircularProgress, Typography } from '@mui/material';
import cuid from 'cuid';
import { cloneDeep, isNil } from 'lodash';
import { useContext, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { snackbar } from '../../../../notifications';
import { Paths } from '../../../../routes';
import { CustomTextField, Header } from '../../components';
import { ValidationErrorNotice } from '../../components/Notice';
import DebouncedInput from '../../edit/DebouncedInput';
import { useBulkUpdateAssetsV2 as useBulkUpdateAssets } from '../../hooks/useBulkUpdateAssets';
import useDeletePrimer from '../../hooks/useDeletePrimer';
import useSaveWevo from '../../hooks/useSaveWevo';
import useUploadPrimer from '../../hooks/useUploadPrimer';
import { IntakeWevoContext } from '../context/IntakeWevoContext';
import { serializeFileRejections } from '../helpers/files';
import { ValidationKeys as AssestValidationKeys, serializeErrors } from '../helpers/validation/assets';
import { ValidationKeys as WevoValidationKeys } from '../helpers/validation/wevo';
import { isCustomSurveyType, optimisticMergePageUpdates, optimisticMergeUpdates } from '../helpers/wevo';
import PrimerImage from './PrimerImage';

function PrimerTaskIntakeSection() {
  const { wevo, setWevo, setIsWevoSyncing, reloadWevoFromRemote, wevoErrors, assetErrors } =
    useContext(IntakeWevoContext);

  const { mutateAsync: saveWevoAsync } = useSaveWevo();
  const { mutateAsync: bulkUpdateAssetsAsync } = useBulkUpdateAssets();
  const { mutateAsync: uploadPrimerAsync } = useUploadPrimer();
  const { mutateAsync: deletePrimerAsync } = useDeletePrimer();

  const [isUploadingPrimer, setIsUploadingPrimer] = useState(false);
  const [isDeletingPrimer, setIsDeletingPrimer] = useState(false);

  const handleUpdateWevo = async ({ wevo, updateFields }) => {
    const previousState = cloneDeep(wevo);

    const newState = optimisticMergeUpdates({ wevo, updateFields });

    try {
      setIsWevoSyncing(true);
      setWevo(newState);
      await saveWevoAsync({ id: wevo.id, ...updateFields });
    } catch (err) {
      setWevo(previousState);
      snackbar.error('Failed to save changes. Please wait a moment and try again or contact us.');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  // this function is used primarily for primerContext which is a little weird because it's the same
  // for all pages, but exists at the page level
  const handleBulkUpdatePages = async ({ wevo, updateFields }) => {
    if (!wevo?.pages || wevo?.pages?.length < 1) return;

    const previousWevoState = cloneDeep(wevo);
    const optimisticState = cloneDeep(wevo);
    optimisticState.pages = optimisticState.pages.map((page) =>
      optimisticMergePageUpdates({ page, updateFields })
    );

    setWevo(optimisticState);

    try {
      setIsWevoSyncing(true);

      const changes = (wevo?.pages ?? []).map((page) => ({
        pageId: page.id,
        ...updateFields,
      }));

      await bulkUpdateAssetsAsync({
        wevoId: wevo.id,
        changes,
      });
    } catch (err) {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating primer');
      setWevo(previousWevoState);
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleUploadPrimerImage = async ({ files, fileRejections }) => {
    if (fileRejections && fileRejections.length > 0) {
      snackbar.error(`An error occurred uploading primer. ${serializeFileRejections(fileRejections)}.`);
      return;
    }

    files = Array.isArray(files) ? files : [files];

    try {
      setIsWevoSyncing(true);
      setIsUploadingPrimer(true);

      for (const file of files) {
        await uploadPrimerAsync({
          uploadId: cuid(),
          image: file,
          wevoId: wevo.id,
        });
      }

      reloadWevoFromRemote();
    } catch (err) {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating primer');
    } finally {
      setIsWevoSyncing(false);
      setIsUploadingPrimer(false);
    }
  };

  // this function deletes the primer image for all pages; n.b. in the future we should make an api
  // that supports deleting primer for all pages in a wevo transactionally
  const handleDeletePrimer = async ({ wevo }) => {
    const previousWevoState = cloneDeep(wevo);
    const optimisticState = cloneDeep(wevo);
    optimisticState.pages = optimisticState.pages.map((page) =>
      optimisticMergePageUpdates({ page, updateFields: { primer: null, primerId: null } })
    );

    setWevo(optimisticState);

    try {
      setIsWevoSyncing(true);
      setIsDeletingPrimer(true);

      await Promise.all(
        (wevo?.pages ?? []).map(async (page) => {
          return deletePrimerAsync({ wevoId: wevo.id, pageId: page.id, deletePrimerContext: false });
        })
      );
    } catch (err) {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting primer');
      setWevo(previousWevoState);
    } finally {
      setIsWevoSyncing(false);
      setIsDeletingPrimer(false);
    }
  };

  const handleTaskToCompleteChanged = async ({ newTaskToComplete }) => {
    return handleUpdateWevo({ wevo, updateFields: { taskToComplete: newTaskToComplete } });
  };

  const handlePrimerContextChanged = async ({ newPrimerContext }) => {
    return handleBulkUpdatePages({ wevo, updateFields: { primerContext: newPrimerContext } });
  };

  if (!wevo) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: 'calc(100vh - 64px)', width: '100%', overflowY: 'auto' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!wevo?.pages || wevo?.pages?.length < 1) {
    // in theory a user should never see this in the new intake form, but just in case
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography>
          Please upload an <Link to={generatePath(Paths.intake.intakeAsset, { wevoId: wevo.id })}>asset</Link>{' '}
          in order to set up a primer and task.
        </Typography>
      </Box>
    );
  }

  return (
    <PrimerTaskConfiguration
      wevo={wevo}
      onPrimerUploaded={handleUploadPrimerImage}
      onDeletePrimerImage={handleDeletePrimer}
      onPrimerContextChanged={handlePrimerContextChanged}
      onTaskToCompleteChanged={handleTaskToCompleteChanged}
      isUploadingPrimer={isUploadingPrimer}
      isDeletingPrimer={isDeletingPrimer}
      wevoErrors={wevoErrors?.wevoErrors?.[String(wevo.id)] || {}}
      assetErrors={assetErrors?.pageErrors?.[String(wevo?.pages?.[0]?.id)] || {}}
    />
  );
}

function PrimerTaskConfiguration({
  wevo,
  onPrimerUploaded,
  onDeletePrimerImage,
  onPrimerContextChanged,
  onTaskToCompleteChanged,
  isUploadingPrimer,
  isDeletingPrimer,
  wevoErrors,
  assetErrors,
}) {
  // Primer context actually exists on all pages and gets bulk updated across all pages,
  // so we just grab the first page and assume if it doesn't exist there, it doesn't exist on the other pages
  const primerId = wevo?.pages?.[0]?.primerId;
  const primerContext = wevo?.pages?.[0]?.primerContext;

  // includes the full image variant record
  const primer = wevo?.pages?.[0]?.primer;

  // kind of hacky internal state because we can't easily stage empty primer images
  const [showImage, setShowImage] = useState(!isNil(primerId));

  const isCustomSurvey = isCustomSurveyType(wevo);
  const isTaskRequired = !isCustomSurvey;

  const handleDeletePrimerImage = () => {
    !isNil(primerId) && onDeletePrimerImage && onDeletePrimerImage({ wevo, primerId });
    setShowImage(false);
  };

  const handleToggleShowImage = () => {
    if (isUploadingPrimer || isDeletingPrimer) return;

    if (showImage) {
      // if we were showing the image and there is a primerId, delete it and hide the image
      handleDeletePrimerImage();
    } else {
      // otherwise, show the image uploader
      setShowImage(true);
    }
  };

  return (
    <Box>
      <Header
        name="Primer Context"
        hasPreview={true}
        description="Primers are used to help set the context for respondents before they arrive on the
first test page."
      />
      <Box mb={2} />
      <Box>
        <DebouncedInput
          value={primerContext || ''}
          onChange={(value) => onPrimerContextChanged({ wevo, newPrimerContext: value })}
          debounceMs={500}
          renderInput={({ value, onChange }) => (
            <CustomTextField
              value={value}
              multiline
              minRows={4}
              placeholder={'Example: Imagine you are looking for a pair of shoes.'}
              sx={{
                '& .MuiInputBase-root': {
                  fontSize: 14,
                },
              }}
              onChange={onChange}
            />
          )}
        />
      </Box>
      {assetErrors?.[AssestValidationKeys.PrimerContext]?.length > 0 && (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(assetErrors[AssestValidationKeys.PrimerContext])} />
        </Box>
      )}
      <Box mb={3} />
      <Header
        name="Show Image"
        description="Image will be displayed along with the primer context."
        toggleSwitchProps={{
          onChange: () => handleToggleShowImage(),
          checked: showImage,
          disabled: isDeletingPrimer || isUploadingPrimer,
        }}
        nameProps={{ sx: { fontWeight: 500 } }}
      />
      <Box mb={3} />
      {showImage && (
        <>
          <PrimerImage
            primer={primer}
            onFileUploaded={onPrimerUploaded}
            isUploading={isUploadingPrimer}
            isDeletingPrimer={isDeletingPrimer}
          />
          <Box mb={4} />
        </>
      )}
      {isTaskRequired && (
        <>
          <Header
            name="Task"
            isRequired={isTaskRequired}
            hasPreview={true}
            description="Task is the action you want respondents to complete when visiting your experience."
          />
          <Box mb={2} />
          <Box>
            <DebouncedInput
              value={wevo?.details?.taskToComplete || ''}
              onChange={(value) => onTaskToCompleteChanged({ newTaskToComplete: value })}
              debounceMs={500}
              renderInput={({ value, onChange }) => (
                <CustomTextField
                  value={value}
                  multiline
                  minRows={4}
                  placeholder={'Example: Explore the homepage and add a pair of shoes to your cart.'}
                  sx={{
                    '& .MuiInputBase-root': {
                      fontSize: 14,
                    },
                  }}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          {wevoErrors?.[WevoValidationKeys.TaskToComplete]?.length > 0 && (
            <Box my={1}>
              <ValidationErrorNotice
                message={serializeErrors(wevoErrors[WevoValidationKeys.TaskToComplete])}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default PrimerTaskIntakeSection;
