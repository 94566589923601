/**
 * Generic component for multiple choice questions. Note that this covers:
 *  - Multiple Choice
 *  - Likert 5
 *  - Likert 7
 *  - Yes/No and True/False
 */

import { Box, Typography } from '@mui/material';
import { CustomTextField } from '../../../../components';
import { ValidationErrorNotice } from '../../../../components/Notice';
import DebouncedInput from '../../../../edit/DebouncedInput';
import { CustomQuestionTypes } from '../../constants';
import { serializeErrors } from '../../helpers';
import MultipleChoiceConfiguration from './multiple-choice/MultipleChoiceConfiguration';
import {
  LikertScaleConfiguration,
  YesNoTrueFalseConfiguration,
} from './multiple-choice/PresetScaleMultipleChoiceQuestion';

function MultipleChoiceQuestion({ wevo, customQuestion, onCustomQuestionChanged, errors }) {
  const renderOptions = () => {
    const questionType = customQuestion?.question?.type;

    switch (questionType) {
      case CustomQuestionTypes.MultipleChoice:
      case CustomQuestionTypes.RankOrder:
        return (
          <MultipleChoiceConfiguration
            wevo={wevo}
            customQuestion={customQuestion}
            onCustomQuestionChanged={onCustomQuestionChanged}
            errors={errors}
          />
        );
      case CustomQuestionTypes.FiveLikertScale:
      case CustomQuestionTypes.SevenLikertScale:
        return (
          <LikertScaleConfiguration
            wevo={wevo}
            customQuestion={customQuestion}
            onCustomQuestionChanged={onCustomQuestionChanged}
            errors={errors}
          />
        );
      case CustomQuestionTypes.YesNoTrueFalse:
        return (
          <YesNoTrueFalseConfiguration
            wevo={wevo}
            customQuestion={customQuestion}
            onCustomQuestionChanged={onCustomQuestionChanged}
            errors={errors}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Box>
      <Typography mb={1}>Enter your question*</Typography>
      <DebouncedInput
        // need to remount when question type changes to reset the debounced input field
        key={`${customQuestion.groupId}_${customQuestion?.question?.type}`}
        value={customQuestion.question.questionText || ''}
        onChange={(value) => {
          onCustomQuestionChanged({
            customQuestion: customQuestion,
            updateFields: { questionText: value },
          });
        }}
        debounceMs={500}
        renderInput={({ value, onChange }) => (
          <CustomTextField
            value={value}
            sx={{
              '& .MuiInputBase-root': {
                fontSize: 14,
              },
            }}
            onChange={onChange}
          />
        )}
      />
      {errors?.questionText?.length > 0 && (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(errors.questionText)} />
        </Box>
      )}
      <Box mb={3} />
      {renderOptions()}
    </Box>
  );
}

export default MultipleChoiceQuestion;
