import _ from 'lodash';
import moment from 'moment';
import { MetricTypes } from '../intake/constants';

export let WevoType = {};
export let WevoTestType = {};
export let ComponentType = {};
export let QuoteType = {};
export let UserType = {};
export let JTBDType = {};
export let BenchmarkScope = {};

export const setWevosConstants = (constants) => {
  WevoType = constants?.WevoType;
  WevoTestType = constants?.WevoTestType;
  ComponentType = constants?.ComponentType;
  QuoteType = constants?.QuoteType;
  UserType = constants?.UserType;
  JTBDType = constants?.JTBDType;
  BenchmarkScope = constants?.BenchmarkScope;
};

export const wevoStatus = { draft: 'draft', completed: 'completed', pending: 'pending', running: 'running' };

export const OrderedWevoStatuses = [
  wevoStatus.draft,
  wevoStatus.pending,
  wevoStatus.running,
  wevoStatus.completed,
];

export const displayWevoStatus = {
  draft: 'Draft',
  completed: 'Completed',
  pending: 'Pending',
  running: 'Running',
};

export const AudienceDisplay = {
  SelectAudience: 'selectAudience',
  SavedAudience: 'savedAudience',
};

export const wevoActivityState = { active: 'Active', archived: 'Archived' };

export const DEVICE_NAME_TO_DEVICE_ID = {
  desktop: 1,
  mobile: 2,
};

export const DEVICE_ID_TO_NAME = {
  1: 'desktop',
  2: 'mobile',
};

export const AudienceCategory = {
  B2C: { id: 1, name: 'Consumers' },
  B2B: { id: 2, name: 'Businesses' },
};

export const CustomQuestionOption = {
  Enabled: 'enabled',
  Disabled: 'disabled',
};

export const KeyFindingOption = {
  Optional: 'optional',
};

export const MastercardDqsOption = {
  Enabled: 'enabled',
  Disabled: 'disabled',
};

export const MastercardCdsOption = {
  Enabled: 'enabled',
  Disabled: 'disabled',
};

export const KeyFindingValues = {
  Yes: 'Yes',
  No: 'No',
};

export const CreditUsageReporting = {
  Enabled: 'enabled',
  Disabled: 'disabled',
};

export const AuthenticExperienceOption = {
  Enabled: 'enabled',
  Disabled: 'disabled',
};

export const CompareJourneysOption = {
  Enabled: 'enabled',
  Disabled: 'disabled',
};

export const UsabilityOption = {
  Enabled: 'enabled',
  Disabled: 'disabled',
};

export const LiteOption = {
  Enabled: 'enabled',
  Disabled: 'disabled',
};

export const DecreaseSampleSizeOption = {
  Disabled: 'disabled',
};

const MIN_SHARE_LINK_LENGTH = 8;
/**
 * Determines if link is a share link:
 *  - Must be a string
 *  - Must be at least 8 characters long
 *  - Must not be fully numeric
 *
 * @param {string} link
 */
export function isShareLink(link) {
  return typeof link === 'string' && link.length >= MIN_SHARE_LINK_LENGTH && isNaN(link);
}

/**
 * Returns audience category name given an ID. If category ID does not exist, return undefined.
 *
 * @param {number} audienceCategoryId
 * @returns {string}
 */
export function getAudienceCategoryNameById(audienceCategoryId) {
  if (!audienceCategoryId) {
    return;
  }

  for (const key in AudienceCategory) {
    const category = AudienceCategory[key];
    if (category && category.id === audienceCategoryId) {
      return category.name;
    }
  }

  return;
}

export const DEFAULT_AUDIENCE_ATTRIBUTES = {
  gender: {
    id: 2,
    label: 'gender',
    name: 'Gender',
    segments: [
      { id: 7, percentage: 50, isSelected: true, label: 'Female' },
      { id: 8, percentage: 50, isSelected: true, label: 'Male' },
    ],
    isCustomizedQuota: false,
    isSelected: false,
  },
  age: {
    id: 3,
    label: 'age',
    name: 'Age',
    segments: [
      { id: 11, percentage: 20, isSelected: true, label: '18 - 24', lowerBound: 18, upperBound: 24 },
      { id: 10, percentage: 20, isSelected: true, label: '25 - 34', lowerBound: 25, upperBound: 34 },
      { id: 15, percentage: 20, isSelected: true, label: '35 - 44', lowerBound: 35, upperBound: 44 },
      { id: 14, percentage: 20, isSelected: true, label: '45 - 54', lowerBound: 45, upperBound: 54 },
      { id: 13, percentage: 20, isSelected: true, label: '55+', lowerBound: 55, upperBound: Infinity },
    ],
    isCustomizedQuota: false,
    isSelected: false,
  },
  income: {
    id: 1,
    label: 'income',
    name: 'Income',
    segments: [
      { id: 2, percentage: 25, isSelected: true, label: '0 - 50k', lowerBound: 0, upperBound: 49 },
      { id: 6, percentage: 25, isSelected: true, label: '50k - 100k', lowerBound: 50, upperBound: 99 },
      {
        id: 5,
        percentage: 25,
        isSelected: true,
        label: '100k - 150k',
        lowerBound: 100,
        upperBound: 149,
      },
      { id: 4, percentage: 25, isSelected: true, label: '150k+', lowerBound: 150, upperBound: Infinity },
    ],
    isCustomizedQuota: false,
    isSelected: false,
  },
  education: {
    id: 4,
    label: 'education',
    name: 'Education',
    segments: [
      {
        id: 16,
        percentage: 20,
        isSelected: true,
        label: 'Some HS',
        lowerBound: 1,
        upperBound: 1,
      },
      {
        id: 17,
        percentage: 20,
        isSelected: true,
        label: 'HS/GED',
        lowerBound: 2,
        upperBound: 2,
      },
      { id: 18, percentage: 20, isSelected: true, label: 'Some College', lowerBound: 3, upperBound: 3 },
      {
        id: 19,
        percentage: 20,
        isSelected: true,
        label: 'AA/AS',
        lowerBound: 4,
        upperBound: 4,
      },
      {
        id: 20,
        percentage: 20,
        isSelected: true,
        label: 'BA/BS+',
        lowerBound: 5,
        upperBound: 5,
      },
    ],
    isCustomizedQuota: false,
    isSelected: false,
  },
  revenue: {
    id: 10,
    label: 'revenue',
    name: 'Revenue',
    segments: [
      { id: 36, percentage: 15, isSelected: true, label: '<50k', lowerBound: 0, upperBound: 49 },
      { id: 37, percentage: 15, isSelected: true, label: '50k - 249k', lowerBound: 50, upperBound: 249 },
      { id: 38, percentage: 14, isSelected: true, label: '250k - 499k', lowerBound: 250, upperBound: 499 },
      { id: 39, percentage: 14, isSelected: true, label: '500k - 749k', lowerBound: 500, upperBound: 749 },
      { id: 40, percentage: 14, isSelected: true, label: '750k - 999k', lowerBound: 750, upperBound: 999 },
      { id: 41, percentage: 14, isSelected: true, label: '1M - <2M', lowerBound: 1000, upperBound: 1999 },
      { id: 42, percentage: 14, isSelected: true, label: '2M - <5M', lowerBound: 2000, upperBound: 4999 },
      { id: 43, percentage: 0, isSelected: false, label: '5M - <10M', lowerBound: 5000, upperBound: 9999 },
      { id: 44, percentage: 0, isSelected: false, label: '10M - <25M', lowerBound: 10000, upperBound: 24999 },
      { id: 45, percentage: 0, isSelected: false, label: '25M - <50M', lowerBound: 25000, upperBound: 49999 },
      { id: 46, percentage: 0, isSelected: false, label: '50M - <100M', lowerBound: 50000, upperBound: 99999 },
      {
        id: 47,
        percentage: 0,
        isSelected: false,
        label: '100M - <500M',
        lowerBound: 100000,
        upperBound: 499999,
      },
      {
        id: 48,
        percentage: 0,
        isSelected: false,
        label: '500M - <1B',
        lowerBound: 500000,
        upperBound: 999999,
      },
      {
        id: 49,
        percentage: 0,
        isSelected: false,
        label: '1B - <10B',
        lowerBound: 1000000,
        upperBound: 9999999,
      },
      {
        id: 50,
        percentage: 0,
        isSelected: false,
        label: '10B or more',
        lowerBound: 10000000,
        upperBound: Infinity,
      },
      {
        id: 51,
        percentage: 0,
        isSelected: false,
        label: "Don't know",
      },
    ],
    isCustomizedQuota: false,
    isSelected: false,
  },
  jobRole: {
    id: 11,
    label: 'job role',
    name: 'Job Role',
    segments: [
      { id: 52, percentage: 15, isSelected: true, label: 'Business Owner' },
      { id: 53, percentage: 15, isSelected: true, label: 'Senior Management' },
      {
        id: 54,
        percentage: 14,
        isSelected: true,
        label: 'Middle Management',
      },
      { id: 55, percentage: 14, isSelected: true, label: 'Front Line Management' },
      { id: 56, percentage: 14, isSelected: true, label: 'Experienced: Professional or Non-Management' },
      { id: 57, percentage: 14, isSelected: true, label: 'Entry Level' },
      { id: 58, percentage: 14, isSelected: true, label: 'Home Based Business Owner' },
      { id: 59, percentage: 0, isSelected: false, label: 'Other' },
    ],
    isCustomizedQuota: false,
    isSelected: false,
  },
  companySize: {
    id: 12,
    label: 'company size',
    name: 'Company Size',
    segments: [
      { id: 60, percentage: 15, isSelected: true, label: '1' },
      { id: 61, percentage: 15, isSelected: true, label: '2 - 5' },
      { id: 62, percentage: 14, isSelected: true, label: '6 - 9' },
      { id: 63, percentage: 14, isSelected: true, label: '10 - 49' },
      { id: 64, percentage: 14, isSelected: true, label: '50 - 99' },
      { id: 65, percentage: 14, isSelected: true, label: '100 - 249' },
      { id: 66, percentage: 14, isSelected: true, label: '250 - 499' },
      { id: 67, percentage: 0, isSelected: false, label: '500 - 999' },
      { id: 68, percentage: 0, isSelected: false, label: '1,000 - 2,499' },
      { id: 69, percentage: 0, isSelected: false, label: '2,500 - 4,999' },
      { id: 70, percentage: 0, isSelected: false, label: '5,000 - 9,999' },
      { id: 71, percentage: 0, isSelected: false, label: '10,000 - 24,999' },
      { id: 72, percentage: 0, isSelected: false, label: '25,000 or more' },
      { id: 73, percentage: 0, isSelected: false, label: "Don't know" },
    ],
    isCustomizedQuota: false,
    isSelected: false,
  },
  companyAge: {
    id: 13,
    label: 'company age',
    name: 'Company Age',
    segments: [
      { id: 74, percentage: 20, isSelected: true, label: 'Less than 1 year' },
      { id: 75, percentage: 20, isSelected: true, label: '1-2 years' },
      { id: 76, percentage: 20, isSelected: true, label: '3-5 years' },
      { id: 77, percentage: 20, isSelected: true, label: '6-9 years' },
      { id: 78, percentage: 20, isSelected: true, label: '10+ years' },
    ],
  },
  employmentStatus: {
    id: 14,
    label: 'employment status',
    name: 'Employment Status',
    segments: [
      { id: 79, percentage: 8, isSelected: true, label: 'Full-time (30 or more hours per week)' },
      { id: 80, percentage: 8, isSelected: true, label: 'Part-time' },
      { id: 81, percentage: 8, isSelected: true, label: 'Contract, Freelance or Temporary Employee' },
      { id: 82, percentage: 8, isSelected: true, label: 'Self-employed' },
      { id: 83, percentage: 8, isSelected: true, label: 'Semi-retired' },
      { id: 84, percentage: 8, isSelected: true, label: 'Retired' },
      { id: 85, percentage: 8, isSelected: true, label: 'Homemaker' },
      { id: 86, percentage: 8, isSelected: true, label: 'Stay-at-Home Parent' },
      { id: 87, percentage: 8, isSelected: true, label: 'Full-time Student' },
      {
        id: 88,
        percentage: 7,
        isSelected: true,
        label: 'Part-time Student (working MORE than 30 hours per week)',
      },
      {
        id: 89,
        percentage: 7,
        isSelected: true,
        label: 'Part-time Student (working LESS than 30 hours per week)',
      },
      { id: 90, percentage: 7, isSelected: true, label: 'Unemployed' },
      { id: 91, percentage: 7, isSelected: true, label: 'Disabled' },
      { id: 92, percentage: 0, isSelected: false, label: 'None of the above' },
    ],
  },
  educationStudentStatus: {
    id: 15,
    label: 'education student status',
    name: 'Education Student Status',
    segments: [
      { id: 93, percentage: 10, isSelected: true, label: 'Full-time student' },
      { id: 94, percentage: 9, isSelected: true, label: 'Part-time student' },
      {
        id: 95,
        percentage: 9,
        isSelected: true,
        label: 'Not currently studying, but will be studying full-time in the next 12 months',
      },
      {
        id: 96,
        percentage: 9,
        isSelected: true,
        label: 'Not currently studying, but will be studying part-time in the next 12 months',
      },
      {
        id: 97,
        percentage: 9,
        isSelected: true,
        label: 'Not studying and will not be doing so in the future',
      },
      { id: 98, percentage: 9, isSelected: true, label: 'Recently finished their continuing education' },
      { id: 99, percentage: 9, isSelected: true, label: 'Currently working full-time' },
      { id: 100, percentage: 9, isSelected: true, label: 'Studying Year 11 or 12' },
      { id: 101, percentage: 9, isSelected: true, label: 'Studying an undergraduate degree at university' },
      { id: 102, percentage: 9, isSelected: true, label: 'Studying a postgraduate degree at university' },
      { id: 103, percentage: 9, isSelected: true, label: 'Retired' },
      { id: 104, percentage: 0, isSelected: false, label: 'None of the above' },
    ],
  },

  // MasterCard custom demographics: Only for reports, will not show up in Test Intake
  mastercardRelationship: {
    id: 16,
    label: 'mastercard business relationship',
    name: 'MasterCard Business Relationship',
    segments: [
      { id: 105, percentage: 25, isSelected: false, label: 'Issuer' },
      { id: 106, percentage: 25, isSelected: false, label: 'Processor' },
      { id: 107, percentage: 25, isSelected: false, label: 'Acquirer' },
      { id: 108, percentage: 25, isSelected: false, label: 'Other' },
    ],
    isCustomizedQuota: false,
  },
  mastercardAdminType: {
    id: 17,
    label: 'mastercard admin type',
    name: 'MasterCard Admin Type',
    segments: [
      { id: 109, percentage: 34, isSelected: false, label: 'Security Administrator' },
      { id: 110, percentage: 33, isSelected: false, label: 'Business Administrator' },
      { id: 111, percentage: 33, isSelected: false, label: 'Neither' },
    ],
    isCustomizedQuota: false,
  },
  mastercardRole: {
    id: 18,
    label: 'mastercard role',
    name: 'MasterCard Role',
    segments: [
      { id: 112, percentage: 34, isSelected: false, label: 'Primary Owner or Liaison' },
      { id: 113, percentage: 33, isSelected: false, label: 'Specific Responsibilities' },
      { id: 114, percentage: 33, isSelected: false, label: 'Other' },
    ],
    isCustomizedQuota: false,
  },
  mastercardContactCustomerService: {
    id: 19,
    label: 'mastercard contact customer service',
    name: 'MasterCard Contact Customer Service',
    segments: [
      { id: 115, percentage: 25, isSelected: false, label: 'Never' },
      { id: 116, percentage: 25, isSelected: false, label: '1-2 times' },
      { id: 117, percentage: 25, isSelected: false, label: '3-18 times' },
      { id: 118, percentage: 25, isSelected: false, label: 'More than 18 times' },
    ],
    isCustomizedQuota: false,
  },
};

/**
 * For an audience ID, get the human-readable name.
 *
 * E.g. audience ID 13 maps to "Company Age"
 *
 * @param {number} audienceId
 * @returns {string} Human readable audience name or empty string if ID is not found
 */
export function audienceIdToName(audienceId) {
  const foundAudience = Object.values(DEFAULT_AUDIENCE_ATTRIBUTES).find(
    (audience) => audience.id === audienceId
  );

  if (!foundAudience || !foundAudience.name) {
    return '';
  }

  return foundAudience.name;
}

/**
 *
 * @param {number} attributeId
 * @returns {Object} attribute from DEFAULT_AUDIENCE_ATTRIBUTES with the matching attribute ID
 * @see https://lodash.com/docs/4.17.15#find
 */
export function getDefaultAttributeById(attributeId) {
  return _.find(DEFAULT_AUDIENCE_ATTRIBUTES, ['id', attributeId]);
}

export const SEGMENT_ID_TO_SEGMENT_LABEL = {
  8: 'Male',
  7: 'Female',
  11: '18 - 24',
  10: '25 - 34',
  15: '35 - 44',
  14: '45 - 54',
  13: '55+',
  2: '0 - 50k',
  6: '50k - 100k',
  5: '100k - 150k',
  4: '150k+',
  16: 'Some HS',
  17: 'HS/GED',
  18: 'Some College',
  19: 'AA/AS',
  20: 'BA/BS+',
  36: '<50k',
  37: '50k - 249k',
  38: '250k - 499k',
  39: '500k - 749k',
  40: '750k - 999k',
  41: '1M - <2M',
  42: '2M - <5M',
  43: '5M - <10M',
  44: '10M - <25M',
  45: '25M - <50M',
  46: '50M - <100M',
  47: '100M - <500M',
  48: '500M - <1B',
  49: '1B - <10B',
  50: '10B or more',
  52: 'Business Owner',
  53: 'Senior Management',
  54: 'Middle Management',
  55: 'Front Line Management',
  56: 'Experienced: Professional or Non-Management',
  57: 'Entry Level',
  58: 'Home Based Business Owner',
  60: '1',
  61: '2 - 5',
  62: '6 - 9',
  63: '10 - 49',
  64: '50 - 99',
  65: '100 - 249',
  66: '250 - 499',
  67: '500 - 999',
};

export const audienceTableWidth = {
  Education: 130,
  Income: 110,
  Gender: 50,
  Age: 90,
  Revenue: 120,
  'Company Size': 100,
  'Job Role': 150,
};

export const DEFAULT_DRAFT = {
  name: 'New WEVO Test',
  description: '',
  devices: [1],
  audience: { attributes: [DEFAULT_AUDIENCE_ATTRIBUTES.gender] },
  pages: [],
};

export const HIDDEN_SEGMENT_IDS_BY_ATTRIBUTE_ID = {
  11: [59], // job role hides "Other"
  12: [67, 68, 69, 70, 71, 72, 73], // company size hides "Don't know" and all segments greater than 250 - 499
  14: [92], // Employment Status hides "None of the above"
  15: [104], // Education Student Status hides "None of the above"
};

export const TestResultSection = {
  SentimentMap: 'sentimentMap',
  CustomerFeedback: 'customerFeedback',
  Findings: 'findings',
  Expectations: 'expectations',
  ConversionScores: 'conversionScores',
  IndustryBenchmarks: 'industryBenchmarks',
  StatisticsSignificance: 'statisticsSignificance',
};

export const TEST_RESULT_SECTIONS = [
  {
    id: TestResultSection.SentimentMap,
    name: 'Sentiment map',
  },
  {
    id: TestResultSection.CustomerFeedback,
    name: 'Verbatim customer feedback',
  },
  {
    id: TestResultSection.Findings,
    name: 'Summarized data and findings',
  },
  {
    id: TestResultSection.Expectations,
    name: 'Visitor expectations',
  },
  {
    id: TestResultSection.ConversionScores,
    name: 'Predictive conversion scores',
  },
  {
    id: TestResultSection.IndustryBenchmarks,
    name: 'Industry benchmarks',
  },
  {
    id: TestResultSection.StatisticsSignificance,
    name: 'High statistical significance',
  },
];

export const SubscriptionLevel = {
  Starter: 'starter',
  Pro: 'pro',
  Enterprise: 'enterprise',
};

export const StepBenchmarkScore = {
  Good: 'good',
  Neutral: 'neutral',
  Bad: 'bad',
};

// Defines the journey step benchmark score to determine what a good/bad score is.
export const getStepBenchmarkScore = (score) => {
  if (score >= 0.7) {
    return StepBenchmarkScore.Good;
  } else if (score >= 0.5) {
    return StepBenchmarkScore.Neutral;
  } else {
    return StepBenchmarkScore.Bad;
  }
};

export const EmotionWordSentiment = {
  Good: 'good',
  Bad: 'bad',
  Neutral: 'neutral',
};

const DATE_EMOTION_WORD_DEPRECATED = new Date('2021-02-20');

/**
 * Determines whether we should show emotion words or not. Emotion words were deprecated with all
 * new journey wevos.
 */
export const showEmotionWords = (wevo) => {
  const dateCompleted = _.get(wevo, 'dateCompleted');
  if (!dateCompleted) {
    return false;
  }

  // Only tests published before the deprecation date should still show the emotion word.
  return moment(dateCompleted).isBefore(DATE_EMOTION_WORD_DEPRECATED);
};

/**
 * Determines whether an emotion word score is good/bad/neutral.
 */
export const getSentimentFromEmotionWordScore = (emotionWord) => {
  const score = (emotionWord && emotionWord.score) || 0;
  const isSignificant = Boolean(emotionWord && emotionWord.isSignificant);

  if (!isSignificant) {
    return EmotionWordSentiment.Neutral;
  } else if (score > 0) {
    return EmotionWordSentiment.Good;
  } else {
    return EmotionWordSentiment.Bad;
  }
};

/**
 * Defines types of Page Flow transitions.
 */
export const TransitionType = {
  Leave: 'leave',
  Stay: 'stay',
};

export const GettingStartedVideoOptions = {
  SettingUpTest: 'settingUpTest',
  ProductOverview: 'productOverview',
  ResultsReview: 'resultsReview',
};

/**
 * Defines Drivers metadata
 */

export const DriversBenchmarkPoints = 5;

// This is the maximum score that a page can achieve
export const DriversBenchmarkMaximum = 35;

// Ensure ordering is always consistent
export const DriversOrdering = ['Appeal', 'Clarity', 'Credibility', 'Experience', 'Relevance'];

// Benchmark thresholds are based on the standard deviation of scores, which is 5 for Classic Drivers
export const DriversBenchmarkThresholds = Array(DriversBenchmarkPoints).fill(DriversBenchmarkPoints);

export const DriversInfo = {
  Appeal: {
    description: 'How successful the webpage is at communicating product benefits and key value to user.',
  },
  Clarity: {
    description:
      'Your visitor’s ability to quickly understand the purpose of the page, understand  the core benefits and the key features of using  your service.',
  },
  Credibility: {
    description:
      'The level of trustworthiness and believability that your service conveys through your landing page.',
  },
  Experience: {
    description:
      'The ease in which visitors navigate and their overall experience as they attempt to find the information they need.',
  },
  Relevance: {
    description:
      'How well the product and page align with each visitor’s expectation and need for your service.',
  },
};

// Ensure versioned diagnostics are always ordered consistently. Note we will need a more robust solution if we introduce more buckets over time.
export const VersionedDiagnosticsOrdering = [
  'First Impression',
  'Engaging',
  'Intuitive',
  'Trusted',
  'Valuable',
];

export const DqsDiagnosticsOrdering = [
  'First Impression',
  'Differentiated',
  'Satisfaction',
  'Intuitive',
  'Frictionless',
  'Engaging',
  'Trusted',
  'Valuable',
];

export const CdsDiagnosticsOrdering = [
  'First Impression',
  'Understanding',
  'Acceptance',
  'Appeal',
  'Utility',
  'Believability',
];

export const getDiagnosticsOrdering = (metricType) => {
  if (metricType === MetricTypes.MastercardDqs) {
    return DqsDiagnosticsOrdering;
  } else if (metricType === MetricTypes.MastercardCds) {
    return CdsDiagnosticsOrdering;
  } else {
    return VersionedDiagnosticsOrdering;
  }
};

/**
 * Orders list of diagnostics according to a specified ordering scheme
 * (e.g. VersionedDiagnosticsOrdering, DqsDiagnosticsOrdering, or CdsDiagnosticsOrdering)
 *
 * E.g. for DQS, First Impression will be ordered first.
 *
 * @param {Array<object>} diagnosticNames - List of diagnostic names to reorder.
 * @param {Array<string>} orderedDiagnosticNames - Ordered list of diagnostics.
 * @returns {Array<object>} ordered list of diagnostics.
 */
export function orderDiagnostics(metricType, diagnosticNames) {
  const orderedDiagnostics = getDiagnosticsOrdering(metricType);

  // Copy list of diagnostics to protect from in-place sorting.
  const diagnosticsToOrder = [...diagnosticNames];

  diagnosticsToOrder.sort(function (a, b) {
    let indexA = orderedDiagnostics.indexOf(a);
    if (indexA < 0) {
      indexA = Number.MAX_SAFE_INTEGER; // If not in ordered list, sort to the end
    }

    let indexB = orderedDiagnostics.indexOf(b);
    if (indexB < 0) {
      indexB = Number.MAX_SAFE_INTEGER; // If not in ordered list, sort to the end
    }

    return indexA - indexB;
  });

  return diagnosticsToOrder;
}

export const CdsCustomQuestionNames = {
  EndCustomerBenefit: 'End-Customer Benefit',
  UtilityValues: 'Utility/Values',
};

export const TagsOption = {
  AllTags: 'All tags',
};

export const Products = {
  Pro: 'pro',
  Pulse: 'pulse',
  Video: 'video',
};
