import { Box, FormControl, FormLabel, Grid, RadioGroup } from '@mui/material';
import { debounce, isEqual, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { DEFAULT_AUDIENCE_ATTRIBUTES } from '../../../../../../modules/wevos/constants';
import { CustomFormControlRadio } from '../../../../components/CustomFormControlLabel';
import {
  AttributeCustomizationComponentType,
  AttributeIdToDisplayName,
  CustomizationOptions,
} from '../../constants';
import {
  getAttributeById,
  getCustomizationOption,
  getNumberOfSegmentsSelected,
  updateSegmentQuotas,
} from '../../helpers';
import Attribute from './Attribute';
import AttributeQuotas from './AttributeQuotas';

const AttributeCustomization = ({ attribute, selectedOption, onAudienceChange, errors }) => {
  const [internalAttributeSegments, setInternalAttributeSegments] = useState(attribute?.segments);

  const attributeId = attribute?.id;
  const attributeLabel = AttributeIdToDisplayName[attributeId];

  // this helps to make sure the segments that are fetched and saved to state are in sync with the
  // internal state of the segments after changes from inputs (e.g slider, checkboxes, textfields)
  // because we use debounce to delay calls to the onChange handler
  useEffect(() => {
    if (!isNil(attribute?.segments) && isNil(internalAttributeSegments)) {
      setInternalAttributeSegments(attribute?.segments);
      return;
    }

    if (isNil(attribute?.segments) && isNil(internalAttributeSegments)) {
      return;
    }

    //debounced function to propagate changes to the attribute's segments
    const invocation = debounce(
      () => onAudienceChange({ attributeId: attribute?.id, changes: { segments: internalAttributeSegments } }),
      500
    );

    // simplified representations of internalAttributeSegments and segments so that they can be compared.
    // left out fields such as upperbound, lowerbound, and sortOrder

    const newSegmentsComp = internalAttributeSegments.map((segment) => ({
      id: segment.id,
      isSelected: segment.isSelected,
      label: segment.label,
      percentage: segment.percentage,
    }));

    const attributeSegmentsComp = attribute?.segments?.map((segment) => ({
      id: segment.id,
      isSelected: segment.isSelected,
      label: segment.label,
      percentage: segment.percentage,
    }));

    if (!isEqual(attributeSegmentsComp, newSegmentsComp)) {
      // prevents unnecessary calls on mount
      invocation();
    }

    return () => invocation.cancel();
  }, [onAudienceChange, attribute, internalAttributeSegments]);

  const handleRangeChange = ({ newRange, marks }) => {
    if (newRange[0] !== newRange[1]) {
      const lowerBound = marks.find((mark) => mark.value === newRange[0]).intValue;
      const upperBound = marks.find((mark) => mark.value === newRange[1]).intValue;

      const attribute = getAttributeById(DEFAULT_AUDIENCE_ATTRIBUTES, attributeId);
      let newSegments = [...attribute?.segments];
      newSegments = newSegments.map((segment) => ({
        ...segment,
        isSelected: segment.lowerBound >= lowerBound && segment.upperBound <= upperBound,
      }));

      //update quotas even if quotas are not being customized
      newSegments = updateSegmentQuotas(newSegments);

      setInternalAttributeSegments(newSegments);
    }
  };
  const handleCheckboxChange = async ({ segment, isSelected }) => {
    let newSegments = [...internalAttributeSegments];
    const currentNumSelectedSegments = getNumberOfSegmentsSelected(newSegments);
    // must have at least one segment selected
    if (currentNumSelectedSegments === 1 && !isSelected) {
      return;
    }

    const changedSegmentIndex = newSegments.findIndex(
      (newSegment) => Number(newSegment.id) === Number(segment?.id)
    );

    //update the segment that is clicked
    newSegments[changedSegmentIndex] = {
      ...segment,
      isSelected: isSelected,
    };

    //update quotas
    newSegments = updateSegmentQuotas(newSegments);

    setInternalAttributeSegments(newSegments);
  };

  const handleQuotaChange = async ({ segment, value }) => {
    const newPercentage = Number(value);
    let newSegments = [...internalAttributeSegments];
    const numSelected = getNumberOfSegmentsSelected(newSegments);

    if (numSelected === 1) {
      return;
    }

    if (newPercentage >= 5 && newPercentage <= 100) {
      // if there are only two segments selected, adjust them so that they always total 100
      if (numSelected === 2) {
        newSegments = newSegments.map((newSegment) => {
          if (newSegment?.id === segment?.id) {
            return { ...newSegment, percentage: Number(newPercentage) };
          } else {
            if (newSegment.isSelected) {
              return { ...newSegment, percentage: Number(100 - newPercentage) };
            }
            return newSegment;
          }
        });
      } else {
        const changedSegmentIndex = newSegments.findIndex(
          (newSegment) => Number(newSegment.id) === Number(segment?.id)
        );

        //update the segment that is clicked
        newSegments[changedSegmentIndex] = {
          ...newSegments[changedSegmentIndex],
          percentage: Number(newPercentage),
        };
      }
    }
    setInternalAttributeSegments(newSegments);
  };

  const handleCustomizationOptionClick = async (option, attribute) => {
    let updatedAttribute = { ...attribute };

    if (option === CustomizationOptions.None) {
      //reset to default attribute
      updatedAttribute = getAttributeById(DEFAULT_AUDIENCE_ATTRIBUTES, attribute?.id);
    } else if (option === CustomizationOptions.Custom) {
      updatedAttribute = { ...attribute, isSelected: true, isCustomizedQuota: false };
    } else {
      updatedAttribute = { ...attribute, isSelected: true, isCustomizedQuota: true };
    }
    await onAudienceChange({ changes: { attributes: [updatedAttribute] } });
  };

  return (
    <FormControl>
      <FormLabel
        id={`${attributeLabel}-attribute`}
        sx={{ fontSize: '13px', fontWeight: 500, textTransform: 'capitalize', color: '#212A37' }}>
        {attributeLabel}
      </FormLabel>
      <RadioGroup
        aria-labelledby={`${attributeLabel}-attribute`}
        name={`${attributeLabel}-attribute-customization`}
        size="small"
        value={selectedOption}
        onChange={(ev) => handleCustomizationOptionClick(ev.target.value, attribute)}>
        <CustomFormControlRadio
          value={CustomizationOptions.None}
          label={'Non specific criteria'}
          checked={selectedOption === CustomizationOptions.None}
        />
        <CustomFormControlRadio
          value={CustomizationOptions.Custom}
          label={`Select ${attributeLabel}`}
          checked={selectedOption === CustomizationOptions.Custom}
        />
        {selectedOption === CustomizationOptions.Custom && (
          <Box pl={4}>
            <Attribute
              attributeId={attribute?.id}
              segments={internalAttributeSegments}
              displayType={AttributeCustomizationComponentType[attributeId]}
              onCheckboxChange={handleCheckboxChange}
              onRangeChange={handleRangeChange}
              onQuotaChange={handleQuotaChange}
            />
          </Box>
        )}
        <CustomFormControlRadio
          value={CustomizationOptions.Quota}
          label={`Select ${attributeLabel} and percentage distribution`}
          checked={selectedOption === CustomizationOptions.Quota}
        />
        {selectedOption === CustomizationOptions.Quota && (
          <Box pl={4} mt={1}>
            <AttributeQuotas
              attributeId={attributeId}
              segments={internalAttributeSegments}
              displayType={AttributeCustomizationComponentType[attributeId]}
              onCheckboxChange={handleCheckboxChange}
              onRangeChange={handleRangeChange}
              onQuotaChange={handleQuotaChange}
              errors={errors}
            />
          </Box>
        )}
      </RadioGroup>
    </FormControl>
  );
};
const AttributesList = ({ attributes, onAudienceChange, errors }) => {
  return (
    <Grid container>
      {attributes?.map((attribute) => (
        <Grid item key={attribute?.id} xs={12} sx={{ paddingY: 2.5, borderBottom: '1px solid #C7D6DF' }}>
          <AttributeCustomization
            attribute={attribute}
            selectedOption={getCustomizationOption(attribute)}
            onAudienceChange={onAudienceChange}
            errors={errors}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default AttributesList;
