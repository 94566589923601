import { Box } from '@mui/material';
import { ReactComponent as InfoIconFilled } from '../../../../../../../assets/info-icon-filled.svg';
import { CustomFormControlCheckbox, IntakeTooltip } from '../../../../../components';
import CheckboxWithTextField from '../../../../../components/CheckboxWithTextField';
import { AnchorLabels } from '../../../constants';

const AdditionalCustomChoices = ({
  canAddOtherChoice,
  canAddNoneChoice,
  canRandomizeChoices,
  labels,
  otherChoiceIndex,
  noneOfTheAboveChoiceIndex,
  isRandomized,
  handleChoiceAdded,
  handleChoiceRemoved,
  handleChoiceChanged,
  handleRandomizationChanged,
}) => {
  return (
    <Box>
      {canAddOtherChoice && (
        <CheckboxWithTextField
          key="other"
          label={"Add an 'Other' answer option"}
          isChecked={!!otherChoiceIndex}
          inputLabel={'Label'}
          inputValue={labels?.[otherChoiceIndex]}
          onCheckboxChange={(checked) =>
            checked
              ? handleChoiceAdded(AnchorLabels.Other)
              : handleChoiceRemoved(AnchorLabels.Other, otherChoiceIndex)
          }
          onInputChange={(value) => handleChoiceChanged(value, labels?.[otherChoiceIndex], otherChoiceIndex)}
        />
      )}
      {canAddNoneChoice && (
        <CheckboxWithTextField
          key="none"
          label={"Add a 'None of the above' answer option"}
          isChecked={!!noneOfTheAboveChoiceIndex}
          inputLabel={'Label'}
          inputValue={labels?.[noneOfTheAboveChoiceIndex]}
          tooltipProps={{
            title: "If respondents select this option, they won't be able to select any of the other options.",
          }}
          onCheckboxChange={(checked) =>
            checked
              ? handleChoiceAdded(AnchorLabels.NoneOfTheAbove)
              : handleChoiceRemoved(AnchorLabels.NoneOfTheAbove, noneOfTheAboveChoiceIndex)
          }
          onInputChange={(value) =>
            handleChoiceChanged(value, labels?.[noneOfTheAboveChoiceIndex], noneOfTheAboveChoiceIndex)
          }
        />
      )}
      {canRandomizeChoices && (
        <Box display="flex" alignItems="center">
          <CustomFormControlCheckbox
            checked={isRandomized}
            label={'Randomize options'}
            onChange={(ev) => handleRandomizationChanged(ev.target.checked)}
          />
          <IntakeTooltip title="If selected, all options for this question will be randomized to minimize survey bias, except 'Other' and 'None of the above', which will remain at the bottom.">
            <InfoIconFilled />
          </IntakeTooltip>
        </Box>
      )}
    </Box>
  );
};

export default AdditionalCustomChoices;
