import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchPreviousSessions = async () => {
  const response = await axios({
    url: `/api/v2/automated-insight-sessions?includeData=true`,
    method: 'GET',
  });
  return response?.data.automatedInsightSessions ?? [];
};

export default function useFetchPreviousSessions(options) {
  return useQuery(['automatedInsightSessions'], fetchPreviousSessions, options ?? {});
}
