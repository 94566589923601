import { groupBy, isEmpty, isNil } from 'lodash';
import { MAX_GOAL_LENGTH, MetricTypes } from '../../../../../modules/intake/constants';
import { getQuotasTotal } from '../../audience/helpers';
import { isMastercardUUID } from '../tags';
import { isCustomSurveyType } from '../wevo';

export const ValidationKeys = {
  Goal: 'goal',
  OwnerEmail: 'ownerEmail',
  OwnerName: 'ownerName',
  StudyName: 'name',
  TaskToComplete: 'taskToComplete',
  VisitorObjective: 'visitorObjective',
  Tags: 'tags',
  Audience: 'audience',
};

function validateWevo(wevo, options = {}) {
  const errors = [];

  const isCustomSurvey = isCustomSurveyType(wevo);

  const includeExpectations = isNil(wevo?.details?.includeExpectations)
    ? true
    : wevo.details.includeExpectations;

  if (includeExpectations && isEmpty(wevo?.visitorObjective)) {
    errors.push({
      key: ValidationKeys.VisitorObjective,
      reason: 'Required field.',
    });
  }

  if (isEmpty(wevo?.details?.taskToComplete) && !isCustomSurvey) {
    errors.push({
      key: ValidationKeys.TaskToComplete,
      reason: 'Required field.',
    });
  }

  if (isEmpty(wevo?.description)) {
    errors.push({
      key: ValidationKeys.Goal,
      reason: 'Required field.',
    });
  }

  if (wevo?.description?.length > MAX_GOAL_LENGTH) {
    errors.push({
      key: ValidationKeys.Goal,
      reason: `Goal length cannot be greater than ${MAX_GOAL_LENGTH} characters. Please remove ${
        wevo.description.length - MAX_GOAL_LENGTH
      } characters.`,
    });
  }

  if (isEmpty(wevo?.name)) {
    errors.push({
      key: ValidationKeys.StudyName,
      reason: 'Required field.',
    });
  }

  if (isEmpty(wevo?.ownerName)) {
    errors.push({
      key: ValidationKeys.OwnerName,
      reason: 'Required field.',
    });
  }

  if (isEmpty(wevo?.ownerEmail)) {
    errors.push({
      key: ValidationKeys.OwnerEmail,
      reason: 'Required field.',
    });
  }

  for (const additionalValidator of options?.additionalValidators ?? []) {
    errors.push(...additionalValidator({ wevo }));
  }

  return {
    wevoErrors: { [wevo?.id]: groupBy(errors, 'key') },
    audienceErrors: { [wevo?.id]: groupBy(validateAudience(wevo?.audience?.attributes), 'key') },
  };
}

function validateAudience(audienceAttributes) {
  const errors = [];

  for (const attribute of audienceAttributes ?? []) {
    const isCustomized = attribute?.isCustomizedQuota;
    const quotasTotal = getQuotasTotal(attribute?.segments);
    if (isCustomized && quotasTotal !== 100) {
      errors.push({
        key: attribute?.id,
        reason: 'Quotas do not total 100.',
      });
    }
  }
  return errors;
}

function hasValidUUIDTag({ wevo }) {
  const metricType = wevo?.metricType;
  const isMastercardMetric = [MetricTypes.MastercardCds, MetricTypes.MastercardDqs].includes(metricType);
  const uuidTag = wevo?.tags?.find((tag) => isMastercardUUID(tag.name));

  if (isMastercardMetric && !uuidTag) {
    return [
      {
        key: ValidationKeys.Tags,
        reason: 'Please add the UUID for this study.',
      },
    ];
  }

  return [];
}

function hasWevoErrors(validationResult) {
  for (const errors of Object.values(validationResult?.wevoErrors ?? {})) {
    if (Object.keys(errors)?.length > 0) return true;
  }

  return false;
}

function hasAudienceErrors(validationResult) {
  for (const errors of Object.values(validationResult?.audienceErrors ?? {})) {
    if (Object.keys(errors)?.length > 0) return true;
  }

  return false;
}

export { hasAudienceErrors, hasValidUUIDTag, hasWevoErrors, validateWevo };
