import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

const styles = {
  dialogTitle: {
    justifyContent: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#000000',
  },
  seatCard: {
    width: '105px',
    height: '65px',
    borderRadius: '10px',
    alignContent: 'center',
    border: '1px solid rgba(210, 210, 210, 0.25)',
  },
  number: { fontSize: '12px', textAlign: 'center' },
  seatCardHeader: { fontSize: '9.5px', fontWeight: 600, textAlign: 'center' },
};

const DONT_SHOW_AGAIN_KEY = '__dismiss_permanently';

export default function AnnouncementPopup({
  localStorageKey,
  bannerId,
  keyPath,
  title,
  message,
  confirmText,
  onConfirm,
  onDismiss,
  showCloseButton = false,
}) {
  const [show, setShow] = useState(false);
  const [shouldDismissPermanently, setShouldDismissPermanently] = useState(false);

  useEffect(() => {
    const displayHistory = JSON.parse(localStorage.getItem(localStorageKey)) || {};

    if (!displayHistory?.[bannerId]) {
      displayHistory[bannerId] = { [DONT_SHOW_AGAIN_KEY]: false };
    }

    if (displayHistory[bannerId][DONT_SHOW_AGAIN_KEY]) {
      return;
    }

    const finalKey = keyPath[keyPath.length - 1];
    const path = keyPath.slice(0, keyPath.length - 1);

    let currentDepth = displayHistory[bannerId];

    for (const pathPart of path) {
      if (!currentDepth?.[pathPart]) {
        currentDepth[pathPart] = {};
        currentDepth = currentDepth[pathPart];
        continue;
      }

      currentDepth = currentDepth[pathPart];
    }

    const cachedResult = currentDepth?.[finalKey];

    if (!cachedResult) {
      setShow(true);
    }

    currentDepth[finalKey] = new Date();

    localStorage.setItem(localStorageKey, JSON.stringify(displayHistory));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShow]);

  const handleClose = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const handleConfirm = useCallback(() => {
    if (shouldDismissPermanently) {
      const displayHistory = JSON.parse(localStorage.getItem(localStorageKey)) || {};
      displayHistory[bannerId][DONT_SHOW_AGAIN_KEY] = true;
      localStorage.setItem(localStorageKey, JSON.stringify(displayHistory));
    }
    handleClose();
    onConfirm && onConfirm();
  }, [handleClose, onConfirm, shouldDismissPermanently, bannerId, localStorageKey]);

  const handleDismiss = useCallback(
    (ev, reason) => {
      if (reason === 'backdropClick') {
        setShow(false);
        onDismiss && onDismiss();
      }
    },
    [onDismiss, setShow]
  );

  if (show) {
    return (
      <Dialog
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: '20px',
            paddingTop: '16px',
            paddingBottom: '16px',
            width: '550px',
          },
        }}
        keepMounted
        open={show}
        disableAutoFocus //disables rescrolling the window when the dialog is opened
        disableEnforceFocus //allows user to interact outside the dialog
        disableScrollLock //prevents the div from shrinking to make room for a scrollbar
        disablePortal
        onClose={handleDismiss}>
        <DialogTitle sx={{ ...styles.dialogTitle, marginTop: 1, marginBottom: 2 }}>
          <Typography sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 600 }}>{title}</Typography>
          {showCloseButton && (
            <IconButton aria-label="close" size="small" onClick={() => handleClose()} sx={styles.closeIcon}>
              <CloseIcon style={{ fontSize: '16px' }} />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent sx={{ overflow: 'hidden', pb: 0 }}>
          <Grid container spacing={2} justifyContent={'center'} sx={{ alignItems: 'center' }}>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: 'left', fontSize: '14px' }}>{message}</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                sx={{ fontSize: '10px' }}
                control={
                  <Checkbox
                    value={shouldDismissPermanently}
                    size="medium"
                    onChange={(event) => setShouldDismissPermanently(event.target.checked)}
                    sx={{
                      color: '#082739',
                      '&.Mui-checked': {
                        color: '#082739',
                      },
                    }}
                  />
                }
                label={<Typography fontSize={12}>Do not show this message again.</Typography>}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'right', mx: 2 }}>
          <Button
            sx={{
              minWidth: '50px',
              borderRadius: '27px',
              border: '1px solid #082739',
              color: 'rgba(9, 41, 60, 1)',
            }}
            onClick={handleConfirm}>
            <Typography sx={{ textTransform: 'none', fontWeight: 'bold', fontSize: '12px' }}>
              {confirmText}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return <></>;
}
