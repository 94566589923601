import { Box, Divider, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Fragment, useMemo } from 'react';
import {
  AudienceCategory,
  audienceIdToName,
  getAudienceCategoryNameById,
} from '../../../../../modules/wevos/constants';
import { ValidationErrorNotice } from '../../../components/Notice';
import AttributeTable from '../../audience/components/saved-audiences/AttributeTable';
import { CustomScreenerQuestionType } from '../../audience/constants';
import { isCustomizedAudience } from '../../audience/helpers';
import { serializeErrors } from '../../audience/helpers/validation';
import FieldRow, { MultiLineField } from '../shared/FieldRow';

const useStyles = makeStyles((theme) => ({
  fieldText: {
    marginLeft: theme.spacing(1.8),
    marginTop: theme.spacing(1.5),
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
    color: '#212A37',
  },
}));

const AudienceSection = ({ wevo, customScreeners, customScreenerErrors, audienceErrors }) => {
  const classes = useStyles();

  const attributes = wevo?.audience?.attributes;
  const audienceDescription = wevo?.audience?.description || '';
  const audienceCategoryId = wevo?.audienceCategory?.id;
  const isCustomAudience = isCustomizedAudience(attributes);

  const audienceCategory = useMemo(() => {
    return getAudienceCategoryNameById(wevo?.audienceCategory && wevo?.audienceCategory.id) || '';
  }, [wevo]);

  return (
    <>
      {/* Target */}
      <FieldRow label={'Target'} divider={true}>
        <Typography className={classes.fieldText}>{audienceCategory}</Typography>
      </FieldRow>
      {/* Demographic/Firmographic screeners */}
      <FieldRow
        label={
          audienceCategoryId === AudienceCategory.B2C.id ? 'Demographic screeners' : 'Firmographic screeners'
        }>
        <Typography className={classes.fieldText}>
          {isCustomAudience ? 'Customized' : 'General Population'}
        </Typography>
      </FieldRow>
      {/* Customized attributes */}
      {isCustomAudience && (
        <FieldRow label={''}>
          {attributes?.map((attribute) => {
            const nonZeroSegments = attribute?.segments.filter((segment) => segment.percentage > 0);
            return (
              <Box key={attribute?.id} mb={2} ml={1.8}>
                <AttributeTable
                  label={audienceIdToName(Number(attribute?.id))}
                  attribute={attribute}
                  segments={nonZeroSegments}
                  maxWidth={'100%'}
                />
                {audienceErrors?.[attribute?.id]?.length > 0 && (
                  <Box my={1}>
                    <ValidationErrorNotice message={serializeErrors(audienceErrors[attribute?.id])} />
                  </Box>
                )}
              </Box>
            );
          })}
        </FieldRow>
      )}
      <Divider sx={{ my: 2, borderColor: '#C7D6DF', borderWidth: '1px' }} />
      {/* Other screening criteria */}
      <FieldRow label={'Other Screening Criteria'} divider={true}>
        <Typography className={classes.fieldText}>{audienceDescription}</Typography>
      </FieldRow>
      {/* Custom screeenr questions */}
      <FieldRow label={'Additional Screeners'}>
        <Box sx={{ marginTop: 1.5 }}>
          {customScreeners?.map((customScreener, index) => {
            const formattedOptions =
              customScreener?.options?.map((option) => `${option?.optionText} - ${option?.outcome}`) || [];
            const errors = customScreenerErrors?.questionErrors?.[customScreener?.id] ?? {};

            return (
              <Fragment key={customScreener?.id}>
                <Box mb={2} ml={1.8}>
                  <MultiLineField
                    title={customScreener?.name || `Custom Screener ${index + 1}`}
                    titleErrorMessage={errors?.name?.length > 0 ? serializeErrors(errors?.name) : ''}
                    text={customScreener?.questionText}
                    errorMessage={errors?.questionText?.length > 0 ? 'Question is required.' : ''}
                  />
                  <MultiLineField
                    title={'Question Type'}
                    text={CustomScreenerQuestionType[customScreener?.questionType]}
                    sx={{ marginTop: 1.5 }}
                  />
                  <MultiLineField
                    title={'Question Options and Outcomes'}
                    text={formattedOptions.join('\n')}
                    errorMessage={errors?.labels?.length > 0 ? serializeErrors(errors.labels) : ''}
                    sx={{ marginTop: 1.5 }}
                  />
                </Box>
                {index !== customScreeners?.length - 1 && (
                  <Divider sx={{ my: 2, borderColor: '#C7D6DF', borderWidth: '1px' }} />
                )}
              </Fragment>
            );
          })}
        </Box>
      </FieldRow>
    </>
  );
};

export default AudienceSection;
