import { Box, Typography } from '@mui/material';
import { CustomQuestionTypes } from '../../../custom-questions/constants';
import { getQuestionsForScope } from '../../../custom-questions/helpers/scopes';
import { getReportFeatures, isCustomSurveyType } from '../../../helpers/wevo';
import { QuestionNotice } from '../survey-elements/QuestionLogic';
import {
  QuestionText,
  ReadOnlyHeatmapQuestion,
  ReadOnlyHorizontalMultipleChoiceQuestion,
  ReadOnlyVerticalMultipleChoiceQuestion,
  ReadOnlyVerticalMultipleSelectionQuestion,
  ReadOnlyVerticalTextField,
} from '../survey-elements/ReadOnlyField';

function CustomQuestionsPageScopePreview({ wevo, page, customQuestions }) {
  const pageScopeQuestions = getQuestionsForScope(customQuestions || [], {
    wevoPageId: page.id,
    stepId: null,
  });

  return (
    <Box>
      {pageScopeQuestions.map((customQuestion, index) => {
        return (
          <Box mb={2} key={index}>
            <CustomQuestionPreview wevo={wevo} page={page} customQuestion={customQuestion} />
          </Box>
        );
      })}
    </Box>
  );
}

function CustomQuestionsStepScopePreview({ wevo, page, step, customQuestions }) {
  const stepScopeQuestions = getQuestionsForScope(customQuestions || [], {
    wevoPageId: page.id,
    stepId: step.id,
  });

  const isCustomSurvey = isCustomSurveyType(wevo);

  return (
    <Box>
      {stepScopeQuestions
        .filter((customQuestion) => {
          // filter out click maps from preview if custom survey since we don't support those yet
          if (!isCustomSurvey) return true;
          return customQuestion?.question?.type !== CustomQuestionTypes.Heatmap;
        })
        .map((customQuestion, index) => {
          return (
            <Box mb={2} key={index}>
              <CustomQuestionPreview wevo={wevo} page={page} step={step} customQuestion={customQuestion} />
            </Box>
          );
        })}
    </Box>
  );
}

function CustomQuestionsPreview({ wevo, page, customQuestions }) {
  const features = getReportFeatures({ wevo });
  const isCustomSurvey = isCustomSurveyType(wevo);

  if (!features?.includeCustomQuestions) {
    return <QuestionNotice spacing={0} noticeText="This study does not include custom questions." />;
  }

  return (
    <Box>
      <QuestionNotice noticeText="Please note that the question order respondents see may vary based on the assets your question is applied to." />
      <Box mb={2} />
      {customQuestions
        .filter(
          (customQuestion) =>
            !!(customQuestion?.scopes ?? []).find((scope) => String(scope.wevoPageId) === String(page?.id))
        )
        .filter((customQuestion) => {
          // filter out click maps from preview if custom survey since we don't support those yet
          if (!isCustomSurvey) return true;
          return customQuestion?.question?.type !== CustomQuestionTypes.Heatmap;
        })
        .map((customQuestion, index) => {
          let questionComponent = <></>;

          // if a heatmap question, then we should show every single step in this version
          // of the preview
          if (customQuestion?.question?.type === CustomQuestionTypes.Heatmap) {
            questionComponent = (customQuestion?.scopes ?? [])
              .filter((scope) => String(scope.wevoPageId) === String(page.id))
              .map((scope) => {
                const step = (page?.steps ?? []).find((step) => String(step.id) === String(scope.stepId));
                return (
                  <Box key={`${customQuestion.groupId}_${step?.id}`}>
                    <CustomQuestionPreview
                      wevo={wevo}
                      page={page}
                      step={step}
                      customQuestion={customQuestion}
                    />
                    <Box mb={3} />
                  </Box>
                );
              });
          } else {
            questionComponent = (
              <CustomQuestionPreview wevo={wevo} page={page} customQuestion={customQuestion} />
            );
          }

          // we need to default custom question name because it isn't actually persisted unless the user touches the record
          const customQuestionName = customQuestion.name || `Custom Question #${customQuestion.label}`;

          return (
            <Box key={index} mb={3}>
              <Box>
                <Typography fontSize={12} fontWeight={600}>
                  {customQuestionName}
                </Typography>
              </Box>
              <Box mb={1} />
              {questionComponent}
            </Box>
          );
        })}
    </Box>
  );
}

function CustomQuestionPreview({ wevo, page, step, customQuestion }) {
  const customQuestionType = customQuestion?.question?.type;

  switch (customQuestionType) {
    case CustomQuestionTypes.FiveLikertScale:
    case CustomQuestionTypes.SevenLikertScale:
      return <LikertCustomQuestionPreview customQuestion={customQuestion} />;
    case CustomQuestionTypes.YesNoTrueFalse:
      return <BinaryCustomQuestionPreview customQuestion={customQuestion} />;
    case CustomQuestionTypes.MultipleChoice:
      return <MulitpleChoiceCustomQuestionPreview customQuestion={customQuestion} />;
    case CustomQuestionTypes.MultiSelect:
      return <MulitpleSelectionCustomQuestionPreview customQuestion={customQuestion} />;
    case CustomQuestionTypes.Heatmap:
      return <HeatmapCustomQuestionPreview customQuestion={customQuestion} step={step} />;
    case CustomQuestionTypes.RankOrder:
      return <RankOrderCustomQuestionPreview customQuestion={customQuestion} />;
    case CustomQuestionTypes.CustomQualitative:
    default:
      return <QualtiativeCustomQuestionPreview customQuestion={customQuestion} />;
  }
}

function QualtiativeCustomQuestionPreview({ customQuestion }) {
  return (
    <Box>
      <QuestionText questionText={customQuestion?.question?.questionText ?? ''} />
      <Box mb={1} />
      <ReadOnlyVerticalTextField />
    </Box>
  );
}

function FollowUpCustomQuestionPreview({ customQuestion }) {
  return (
    <Box>
      <QuestionText questionText={customQuestion?.question?.followUpQuestionText ?? ''} />
      <Box mb={1} />
      <ReadOnlyVerticalTextField />
    </Box>
  );
}

function HeatmapCustomQuestionPreview({ customQuestion, step }) {
  if (!step) return <></>;

  return (
    <Box>
      <ReadOnlyHeatmapQuestion
        questionText={customQuestion?.question?.questionText ?? ''}
        images={step?.images}
      />
      <Box mb={2} />
      <FollowUpCustomQuestionPreview customQuestion={customQuestion} />
    </Box>
  );
}

function LikertCustomQuestionPreview({ customQuestion }) {
  return (
    <Box>
      <ReadOnlyHorizontalMultipleChoiceQuestion
        questionText={customQuestion?.question?.questionText}
        choices={customQuestion?.question?.labels}
      />
      <Box mb={3} />
      {customQuestion?.question?.followUpQuestionText && (
        <FollowUpCustomQuestionPreview customQuestion={customQuestion} />
      )}
    </Box>
  );
}

function BinaryCustomQuestionPreview({ customQuestion }) {
  return <MulitpleChoiceCustomQuestionPreview customQuestion={customQuestion} />;
}

function MulitpleChoiceCustomQuestionPreview({ customQuestion }) {
  return (
    <Box>
      <ReadOnlyVerticalMultipleChoiceQuestion
        questionText={customQuestion?.question?.questionText}
        choices={customQuestion?.question?.labels}
      />
      <Box mb={3} />
      {customQuestion?.question?.followUpQuestionText && (
        <FollowUpCustomQuestionPreview customQuestion={customQuestion} />
      )}
    </Box>
  );
}

function MulitpleSelectionCustomQuestionPreview({ customQuestion }) {
  return (
    <Box>
      <ReadOnlyVerticalMultipleSelectionQuestion
        questionText={customQuestion?.question?.questionText}
        choices={customQuestion?.question?.labels}
      />
      <Box mb={3} />
      {customQuestion?.question?.followUpQuestionText && (
        <FollowUpCustomQuestionPreview customQuestion={customQuestion} />
      )}
    </Box>
  );
}

function RankOrderCustomQuestionPreview({ customQuestion }) {
  return (
    <Box>
      <ReadOnlyVerticalMultipleChoiceQuestion
        questionText={customQuestion?.question?.questionText}
        choices={customQuestion?.question?.labels}
      />
      <Box mb={3} />
      {customQuestion?.question?.followUpQuestionText && (
        <FollowUpCustomQuestionPreview customQuestion={customQuestion} />
      )}
    </Box>
  );
}

export { CustomQuestionsPageScopePreview, CustomQuestionsPreview, CustomQuestionsStepScopePreview };
