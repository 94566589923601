import { ELEMENT_PARAGRAPH } from '@udecode/plate-headless';
import _ from 'lodash';
import { ComponentType, Products } from '../../modules/wevos/constants';
import { canUseAutomatedInsightSessions, canUseCore } from '../automated-insights/helpers';
import { PlateEmptyValue } from '../plate/constants';
import { KeyFindingType } from '../report/constants';
import { WevoType } from './constants';

/**
 * Determines if the element is empty.
 */
export const isSlateEmptyElement = (element) => {
  const onlyOneChild = Array(element?.children) && element?.children?.length === 1;
  const childText = element?.children?.[0]?.text;
  const isChildEmptyOrWhitespace = /^\s*$/.test(childText);
  return element?.type === ELEMENT_PARAGRAPH && onlyOneChild && isChildEmptyOrWhitespace;
};

/**
 * Determines if an older test has key findings or not, and whether a new test includes key findings or not.
 * There are 2 types of key findings for older tests:
 *  - markdown: All new tests are markdown key findings
 *  - bullets: Old tests can support bullets
 *
 * @param {Array} keyFindings
 */
export const hasKeyFindings = (wevo, keyFindings) => {
  if (
    !Array.isArray(keyFindings) ||
    !keyFindings.length ||
    wevo?.details?.includeKeyFindings === false ||
    wevo?.isUsabilityTestType ||
    wevo?.isLiteTestType
  ) {
    return false;
  }

  const type = _.get(keyFindings, '0.type');
  let hasFindings = false;

  if (type === KeyFindingType.Markdown) {
    hasFindings = keyFindings.some((keyFinding) => Boolean(keyFinding.finding));
  } else if (type === KeyFindingType.Slate) {
    hasFindings = keyFindings?.some((keyFinding) =>
      keyFinding?.finding?.some((finding) => !isSlateEmptyElement(finding))
    );
  } else {
    hasFindings = keyFindings.some((keyFinding) => Boolean(keyFinding.bullets));
  }

  return hasFindings;
};

/**
 * Checks if user key findings are defined and non-empty
 *
 * @param {Array} userKeyFindings
 */
export function hasUserKeyFindings(userKeyFindings) {
  return userKeyFindings?.finding && !_.isEqual(userKeyFindings?.finding, PlateEmptyValue);
}

/**
 *
 * @param {Array} pages
 * @returns {Array} array of pages sorted by sortOrder
 * @description sorts an array of pages according to their sort order and returns the sorted array. It does not mutate the original array.
 */
export const sortPages = (pages) => {
  let pagesCopy = _.cloneDeep(pages); // make copy to avoid mutating original
  return pagesCopy.sort((a, b) => {
    return a.sortOrder - b.sortOrder;
  });
};

/**
 *
 * @param {object} wevo
 * @returns {boolean} true if wevo is a classic compare pages wevo, otherwise false
 * @description determines if a wevo is a classic compare pages wevo
 */
export const isClassicComparePages = (wevo) => {
  return wevo?.type === WevoType.Classic && (wevo?.pages ?? []).length > 1;
};

/**
 *
 * @param {object} wevo
 * @returns {boolean} true if wevo is a classic single page wevo, otherwise false
 * @description determines if a wevo is a classic single page wevo
 */
export const isClassicSinglePage = (wevo) => {
  return wevo?.type === WevoType.Classic && (wevo?.pages ?? []).length < 2;
};

/**
 * Determines whether wevo has a non-empty component (e.g. diagnostics, etc.)
 *
 * @param {object} wevo
 * @param {ComponentType} componentType
 * @returns
 */
export const hasComponent = (wevo, componentType) => {
  return Boolean(wevo?.components[componentType]);
};

/**
 * Determines which assets to use based on asset scope. If pageId is ommitted or null,
 * we use the wevo as the scope and "pages" are the assets (in a future state we'll further distance pages from assets).
 * If pageId is specified then we use page as the scope and "steps" are the assets (in a future state, every test type will
 * use steps and at that point we'll always require a page in the scope.)
 *
 * @param {object} wevo
 * @param {object} scope
 * @returns
 */
export function getAssetsForAssetScope(wevo, scope) {
  const { pageId } = scope ?? {};

  if (pageId) {
    return sortPages((wevo?.pages ?? []).find((page) => String(pageId) === String(page.id))?.steps ?? []);
  }

  return sortPages(wevo?.pages ?? []);
}

/**
 * Determines if a page has custom questions associated to it
 *
 * @param {Array<object>} customQuestions
 * @param {string} pageId
 * @returns
 */
export function pageHasCustomQuestions(customQuestions, pageId) {
  return customQuestions?.some((customQuestion) =>
    customQuestion?.scopes?.some((scope) => String(scope?.wevoPageId) === String(pageId))
  );
}

/**
 * Determines if a step has custom questions associated to it
 *
 * @param {Array<object>} customQuestions
 * @param {string} stepId
 * @returns
 */
export function stepHasCustomQuestions(customQuestions, stepId) {
  return customQuestions?.some((customQuestion) =>
    customQuestion?.scopes?.some((scope) => String(scope?.stepId) === String(stepId))
  );
}

/* Determines whether wevo has quotes of specific type (e.g. diagnosticsQuotes, etc.)
 *
 * @param {object} wevo
 * @param {Object} quotes
 * @param {ComponentType} componentType
 * @returns
 */
export const hasQuotes = (wevo, quotes, componentType) => {
  if (componentType === ComponentType.Expectation) {
    return wevo?.pages?.some((page) => quotes[page.id]?.quotes && quotes[page.id]?.quotes?.length !== 0);
  }
  return wevo?.pages?.some((page) => quotes[page.id] && quotes[page.id]?.length !== 0);
};

/**
 * Determines which wevo product is initially selected - assumes the products are in the following order: Pro, Pulse, and Userlytics
 *
 * @param {object} user
 * @param {object} userCustomizations
 * @returns
 */
export function getInitialWevoProduct(user, userCustomizations) {
  const proEnabled = canUseCore(userCustomizations);
  const pulseEnabled = canUseAutomatedInsightSessions(user, userCustomizations);
  const isUserlytics = Boolean(userCustomizations?.integrations?.userlytics);

  if (proEnabled) {
    return Products.Pro;
  } else if (pulseEnabled) {
    return Products.Pulse;
  } else if (isUserlytics) {
    return Products.Video;
  } else {
    return null;
  }
}
