import Panel from './Panel';

function PreviewPanel({ content = <></>, controls }) {
  return (
    <Panel scrolls={true} controls={controls} ContentStyles={{ height: controls ? '85%' : '100%' }}>
      {content}
    </Panel>
  );
}

export default PreviewPanel;
