import { Box } from '@mui/material';
import { CustomScreenerTypes } from '../../../../../../../modules/intake/constants';
import { DefaultOutcomeOptions, MultipleSelectionOutcomeOptions } from '../../../constants';
import { CustomSelect } from '../../../../../components';

function OutcomeSelect({ selectedOutcome, questionType, isDisabled, onChange }) {
  const items =
    questionType === CustomScreenerTypes.MultiSelect ? MultipleSelectionOutcomeOptions : DefaultOutcomeOptions;

  const handleOutcomeChanged = (newOutcome) => {
    if (!onChange || !newOutcome) return;

    onChange(newOutcome);
  };

  return (
    <Box>
      <CustomSelect
        size={'medium'}
        value={selectedOutcome || ''}
        items={items}
        disabled={isDisabled}
        onChange={(ev) => handleOutcomeChanged(ev.target.value)}
      />
    </Box>
  );
}

export default OutcomeSelect;
