import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, IconButton, Typography } from '@mui/material';
import { ReactComponent as CancelIcon } from '../../../../assets/intake-cancel-icon.svg';
import { CustomTextField } from '../../components';
import { ValidationErrorNotice } from '../../components/Notice';
import Thumbnail from '../../components/Thumbnail';
import DebouncedInput from '../../edit/DebouncedInput';
import { serializeErrors, ValidationKeys } from '../helpers/validation/assets';

function DraggableStepCard({
  wevo,
  page,
  step,
  dragHandleProps,
  onDeleteStep,
  onStepNameChanged,
  isDeletingStep,
  errors,
}) {
  return (
    <Box sx={{ p: 2, borderRadius: 2, border: '1.5px solid #C7D6DF', background: '#FFFFFF' }}>
      <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
        <IconButton sx={{ padding: 0 }} onClick={() => onDeleteStep && onDeleteStep({ wevo, page, step })}>
          <CancelIcon />
        </IconButton>
      </Box>
      <Box display="flex" sx={{ alignItems: 'center', gap: 2 }}>
        <Box sx={{ width: 25 }}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', width: '100%' }} {...dragHandleProps}>
            <DragIndicatorIcon
              sx={{
                fontSize: 'xx-large',
                verticalAlign: 'bottom',
                marginRight: '10px',
              }}
            />
          </Box>
        </Box>
        <Box sx={{ width: '35%' }}>
          <Box
            sx={{
              p: '1px',
              border: '1px solid #C7D6DF',
              boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
              borderRadius: 4,
            }}>
            <Thumbnail
              boxStyles={{ borderRadius: 12 }}
              imageStyles={{
                borderRadius: 12,
                height: '120px',
              }}
              images={step?.images}
            />
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1, alignSelf: 'start', marginTop: '30px' }}>
          <Typography fontSize={15} fontWeight={500} mb={1}>
            Page name*
          </Typography>
          <DebouncedInput
            value={step?.name || ''}
            onChange={(value) => onStepNameChanged({ wevo, page, step, newName: value })}
            debounceMs={500}
            renderInput={({ value, onChange }) => (
              <CustomTextField
                value={value}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: 14,
                  },
                }}
                onChange={onChange}
              />
            )}
          />
          {errors?.[ValidationKeys.Name] && (
            <Box my={1}>
              <ValidationErrorNotice message={serializeErrors(errors[ValidationKeys.Name])} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export { DraggableStepCard };
