import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import queryString from 'query-string';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { Link, generatePath, useHistory, useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import validator from 'validator';
import { ReactComponent as PulseImageUploadIcon } from '../../assets/pulse-image-upload-icon.svg';
import {
  AutomatedInsightSessionType,
  BlockedFeature,
  DeviceNameToId,
  MAX_PULSE_COMPARE_SESSIONS,
  MAX_PULSE_JOURNEY_ASSETS,
  MAX_PULSE_PAGE_ASSETS,
  MIN_PULSE_COMPARE_SESSIONS,
  PULSE_LEGACY_CREDIT_SUBSCRIPTION_TYPES,
} from '../../modules/automated-insights/constants';
import {
  canUseAutomatedInsightSessions,
  canUsePulseCompares,
  canUsePulseExperiences,
  exceedFileAmount,
  withinFileLimit,
} from '../../modules/automated-insights/helpers';
import { getUserCustomizations, getUserProfile } from '../../modules/user/selectors';
import { snackbar } from '../../notifications';
import { Paths } from '../../routes';
import { TrackEvent, useTrackPageLoad } from '../analytics';

import AssetUploadCard from './AssetUploadCard';
import AssetUploadList from './AssetUploadList';
import DeviceSelection from './DeviceSelection';
import FeatureTooltip from './FeatureTooltip';
import ImportURLField from './ImportURLField';
import ImportURLList from './ImportURLList';
import InfoDrawer from './InfoDrawer';
import SessionSelector from './SessionSelector';
import SessionTypeSelection from './SessionTypeSelection';
import useCreateCompareSession from './hooks/useCompareSession';
import useGenerateInsights from './hooks/useGenerateInsights';
import { useCreateSessionFromURLs, useGenerateImportURL } from './hooks/useImportUrl';
import useUploadAsset from './hooks/useUploadAsset';
import CustomButton from './ui/Button';

const styles = {
  button: {
    height: '34px',
    paddingX: 2,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    color: '#43BCFF',
    textDecoration: 'none',
  },
};

function CreatePageSession({
  onFileInput,
  onImportURL,
  isUploading,
  isStartingUrlImport,
  initialURL,
  freeTrialExpired,
  subscriptionExpired,
  reachedUsageLimit,
  disableFeatures,
  user,
}) {
  return (
    <>
      <AssetUploadCard
        handleFileInput={onFileInput}
        multiple={false}
        iconColor={'rgba(255, 255, 255, 0.32)'}
        isUploading={isUploading}
        disableFeatures={disableFeatures}
        freeTrialExpired={freeTrialExpired}
        subscriptionExpired={subscriptionExpired}
        reachedUsageLimit={reachedUsageLimit}
        user={user}
      />
      <Box textAlign="center" my={3}>
        <Typography color="primary">or</Typography>
      </Box>
      <ImportURLField
        initialURL={initialURL}
        onImportURL={onImportURL}
        isLoading={isStartingUrlImport}
        isFocused={true}
        helperText="Press 'Enter' to get started with your web page."
        disableFeatures={disableFeatures}
        freeTrialExpired={freeTrialExpired}
        subscriptionExpired={subscriptionExpired}
        reachedUsageLimit={reachedUsageLimit}
        user={user}
      />
    </>
  );
}

function CreateMultiAssetSession({
  initialURLs,
  initialMode = 'url',
  sessionType,
  onNext,
  isUploading = false,
  isStartingUrlImport = false,
  isCompareEnabled = false,
  freeTrialExpired,
  subscriptionExpired,
  reachedUsageLimit,
  disableFeatures,
  blockJourneys,
  user,
}) {
  const history = useHistory();
  const location = useLocation();

  const [mode, setMode] = useState(initialMode);
  const [urls, setUrls] = useState(
    initialURLs?.map((url) => ({ url, hasError: false })) || [{ url: '', hasError: false }]
  );
  const [files, setFiles] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const hasValidURLs = useMemo(
    () => urls.length > 0 && withinFileLimit(urls.length, sessionType),
    [urls, sessionType]
  );

  const hasValidFiles = useMemo(
    () => files.length > 0 && withinFileLimit(files.length, sessionType),
    [files, sessionType]
  );

  const handleImageModeSelected = useCallback(() => {
    setMode('image');

    history.push({
      pathname: location.pathname,
      search: new URLSearchParams({ mode: 'image' }).toString(),
    });
  }, [history, location, setMode]);

  const urlListDecorators = useMemo(() => {
    const shouldDisplayImageToggle = urls?.length > 1 || urls.some((urlObj) => !!urlObj?.url);

    if (shouldDisplayImageToggle) {
      return urls.map((urlObj, index) =>
        !urlObj?.hasError ? (
          <Fragment key={index} />
        ) : (
          <WarningAmberRoundedIcon key={index} fontSize="small" color="error" style={{ marginLeft: '16px' }} />
        )
      );
    }
    return urls.map((urlObj, index) =>
      index === 0 ? (
        <FeatureTooltip
          disableFeatures={disableFeatures}
          blockedFeature={BlockedFeature.AssetUpload}
          blockJourneys={blockJourneys && urls.length > 1}
          freeTrialExpired={freeTrialExpired}
          subscriptionExpired={subscriptionExpired}
          reachedUsageLimit={reachedUsageLimit}
          user={user}>
          <Box
            sx={{
              position: 'absolute',
              width: 210,
              marginLeft: 2,
              display: { xs: 'none', lg: 'flex' },
              alignItems: { lg: 'center' },
            }}
            key={index}>
            <Typography component="span" color="primary" pr={1}>
              or
            </Typography>
            <Button
              size="small"
              startIcon={<PulseImageUploadIcon />}
              onClick={!disableFeatures ? () => handleImageModeSelected() : () => {}}
              sx={{ textTransform: 'none', cursor: disableFeatures && 'not-allowed' }}>
              Image/PDF
            </Button>
          </Box>
        </FeatureTooltip>
      ) : (
        <Fragment key={index} />
      )
    );
  }, [
    handleImageModeSelected,
    urls,
    disableFeatures,
    blockJourneys,
    freeTrialExpired,
    subscriptionExpired,
    reachedUsageLimit,
    user,
  ]);

  const urlsErrorMessage = useMemo(() => {
    if (!showErrorMessage) {
      return null;
    }

    const withinLimit = withinFileLimit(urls.length, sessionType);
    const isEmail = urls.length === 1 && urls.every((urlObj) => validator.isEmail(urlObj?.url));
    const validUrls =
      (urls.length === 1 && urls[0]?.url === '') ||
      urls.every((urlObj) => validator.isURL(urlObj?.url) && !validator.isEmail(urlObj?.url));
    const uniqueUrls = new Set(urls?.map((urlObj) => urlObj?.url)).size === urls.length;

    if (!withinLimit) {
      const exceedAmount = exceedFileAmount(urls.length, sessionType);
      const exceedQualifier = exceedAmount > 1 ? 'urls' : 'url';
      return `Asset limit exceeded. Please remove ${exceedAmount} ${exceedQualifier} to proceed.`;
    }
    if (isEmail) return `Please enter a valid url instead of an email address.`;
    if (!validUrls) return `Please make sure all urls are valid to proceed.`;
    if (!uniqueUrls) return `Please make sure all urls are different.`;
    return null;
  }, [urls, showErrorMessage, sessionType]);

  const filesErrorMessage = useMemo(() => {
    const hasFiles = files.length > 0;
    const withinLimit = withinFileLimit(files.length, sessionType);

    if (!hasFiles) return 'Please select at least one file to proceed.';

    if (!withinLimit) {
      const exceedAmount = exceedFileAmount(files.length, sessionType);
      const exceedQualifier = exceedAmount > 1 ? 'files' : 'file';
      return `Asset limit exceeded. Please remove ${exceedAmount} ${exceedQualifier} to proceed.`;
    }

    return null;
  }, [files, sessionType]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const modeParam = queryParams.get('mode');

    if (modeParam) {
      setMode(modeParam);
    } else {
      setMode('url');
    }
  }, [location, setMode]);

  useEffect(() => {
    if (sessionType === AutomatedInsightSessionType.Page) {
      if (urls?.length > 1) {
        const firstURL = urls.slice(0, 1);

        setUrls(firstURL);
      }
      if (files?.length > 1) {
        const firstFile = files.slice(0, 1);

        setFiles(firstFile);
      }
    }
  }, [sessionType, files, urls]);

  const handleURLInputChange = (urls) => {
    // hide the error message if it's still shown when there aren't errors
    if (urls.every((urlObj) => !urlObj?.hasError) && showErrorMessage) {
      setShowErrorMessage(false);
    }
    setUrls(urls);
  };

  const handleNextClick = useCallback(
    ({ urls, files }) => {
      if (urls) {
        const validatedUrls = urls.map((urlObj) =>
          !validator.isURL(urlObj?.url) || validator.isEmail(urlObj?.url) || !urlObj?.url?.length
            ? { ...urlObj, hasError: true }
            : { ...urlObj, hasError: false }
        );
        setUrls(validatedUrls);
        const hasErrors = validatedUrls?.some((urlObj) => urlObj?.hasError);
        const hasUniqueUrls = new Set(urls?.map((urlObj) => urlObj?.url)).size === urls.length;

        if (hasErrors || !hasValidURLs || !hasUniqueUrls) {
          setShowErrorMessage(true);
        } else if (hasValidURLs) {
          const urlsList = urls?.map((urlObj) => urlObj?.url);
          onNext({ urls: urlsList, sessionType });
        }
      } else if (files && hasValidFiles) {
        onNext({ files, sessionType });
      }
      return;
    },
    [hasValidFiles, hasValidURLs, onNext, sessionType]
  );

  const customButton = useMemo(
    () => (
      <span style={{ width: '70%', minWidth: '140px', maxWidth: '200px' }}>
        <CustomButton
          variant="gradient"
          fullWidth
          onClick={() => handleNextClick({ urls })}
          disabled={
            (urls.length <= 1 && !urls[0]?.url?.length) ||
            isStartingUrlImport ||
            disableFeatures ||
            (blockJourneys && urls.length > 1)
          }
          style={{ cursor: disableFeatures && 'not-allowed' }}>
          {isStartingUrlImport ? (
            <Box component="span">
              <Box component="span" pr={1}>
                Setting up your session ...
              </Box>
              <CircularProgress size={12} />
            </Box>
          ) : (
            'Next'
          )}
        </CustomButton>
      </span>
    ),
    [disableFeatures, handleNextClick, isStartingUrlImport, urls, blockJourneys]
  );

  const customButtonImageUpload = useMemo(
    () => (
      <span
        style={{
          width: '70%',
          minWidth: '140px',
          maxWidth: '200px',
        }}>
        <CustomButton
          variant="gradient"
          fullWidth
          onClick={() => handleNextClick({ files })}
          disabled={!hasValidFiles || isUploading || disableFeatures || (blockJourneys && files.length > 1)}>
          {isUploading ? (
            <Box component="span">
              <Box component="span" pr={1}>
                Uploading ...
              </Box>
              <CircularProgress size={12} />
            </Box>
          ) : (
            'Next'
          )}
        </CustomButton>
      </span>
    ),
    [handleNextClick, hasValidFiles, isUploading, files, blockJourneys, disableFeatures]
  );
  const nextMessage = "When you click next, we'll upload the images you provided.";

  return (
    <>
      {mode === 'image' ? (
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <AssetUploadList
                files={files}
                onChange={(uploads) => setFiles(uploads)}
                maxFiles={
                  sessionType === AutomatedInsightSessionType.Experience
                    ? MAX_PULSE_JOURNEY_ASSETS
                    : MAX_PULSE_PAGE_ASSETS
                }
                isUploading={isUploading}
                error={filesErrorMessage}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={4}>
                {disableFeatures || (blockJourneys && files?.length > 1) ? (
                  <FeatureTooltip
                    disableFeatures={disableFeatures}
                    blockedFeature={BlockedFeature.AssetUpload}
                    blockJourneys={blockJourneys}
                    freeTrialExpired={freeTrialExpired}
                    subscriptionExpired={subscriptionExpired}
                    reachedUsageLimit={reachedUsageLimit}
                    user={user}>
                    {customButtonImageUpload}
                  </FeatureTooltip>
                ) : (
                  <Tooltip title={filesErrorMessage ? filesErrorMessage : nextMessage}>
                    {customButtonImageUpload}
                  </Tooltip>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box mt={1}>
          <ImportURLList
            urls={urls}
            onChange={(urls) => handleURLInputChange(urls)}
            decorators={urlListDecorators}
            showAddButton={sessionType === AutomatedInsightSessionType.Experience}
            enableAddButton={
              sessionType === AutomatedInsightSessionType.Experience && urls.length < MAX_PULSE_JOURNEY_ASSETS
            }
            errorMessage={urlsErrorMessage}
            hasGlowingInputField={true}
            disableFeatures={disableFeatures}
            freeTrialExpired={freeTrialExpired}
            subscriptionExpired={subscriptionExpired}
            reachedUsageLimit={reachedUsageLimit}
          />
          {urls?.length <= 1 && urls.every((urlObj) => !urlObj?.url) && (
            <Grid container sx={{ display: { lg: 'none' }, justifyContent: { md: 'center' } }}>
              <Grid item md={9}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography component="span" color="primary" pr={0.5}>
                    or
                  </Typography>
                  <FeatureTooltip
                    disableFeatures={disableFeatures}
                    blockedFeature={BlockedFeature.AssetUpload}
                    blockJourneys={blockJourneys}
                    freeTrialExpired={freeTrialExpired}
                    subscriptionExpired={subscriptionExpired}
                    reachedUsageLimit={reachedUsageLimit}
                    user={user}>
                    <Button
                      size="small"
                      startIcon={<PulseImageUploadIcon style={{ height: '24px', width: '24px' }} />}
                      onClick={!disableFeatures ? () => handleImageModeSelected() : () => {}}
                      sx={{ textTransform: 'none', paddingX: 1, cursor: disableFeatures && 'not-allowed' }}>
                      Image/PDF
                    </Button>
                  </FeatureTooltip>
                </Box>
              </Grid>
            </Grid>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={2}>
            {disableFeatures || (blockJourneys && urls?.length > 1) ? (
              <FeatureTooltip
                disableFeatures={disableFeatures}
                blockedFeature={BlockedFeature.UrlImport}
                blockJourneys={blockJourneys}
                freeTrialExpired={freeTrialExpired}
                subscriptionExpired={subscriptionExpired}
                reachedUsageLimit={reachedUsageLimit}
                user={user}>
                {customButton}
              </FeatureTooltip>
            ) : (
              <Tooltip
                title={
                  urls.length <= 1 && !urls[0]?.url?.length
                    ? 'Please add at least one valid URL to proceed.'
                    : nextMessage
                }>
                {customButton}
              </Tooltip>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

const CreateCompare = ({ previousSessions, isStartingUrlImport = false, onNext }) => {
  const [selectedSessionIds, setSelectedSessionIds] = useState([]);

  const nextMessage = useMemo(() => {
    if (selectedSessionIds?.length < MIN_PULSE_COMPARE_SESSIONS) {
      return 'Please add at least two Pulses to proceed.';
    } else if (selectedSessionIds?.length > MAX_PULSE_COMPARE_SESSIONS) {
      const exceedAmount = exceedFileAmount(selectedSessionIds.length, AutomatedInsightSessionType.Compare);
      const exceedQualifier = exceedAmount > 1 ? 'Pulses' : 'Pulse';
      return `Pulse limit exceeded. Please remove ${exceedAmount} ${exceedQualifier} to proceed.`;
    } else {
      return '';
    }
  }, [selectedSessionIds]);

  const handleSessionSelection = (sessionIds) => {
    setSelectedSessionIds(sessionIds);
  };

  const handleNextClick = () => {
    onNext({ sessionIds: selectedSessionIds, sessionType: AutomatedInsightSessionType.Compare });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 3 }}>
      <SessionSelector
        previousSessions={previousSessions}
        selectedSessionIds={selectedSessionIds}
        onSessionSelectionChange={handleSessionSelection}
      />
      <Box sx={{ width: '70%', minWidth: '140px', maxWidth: '200px' }}>
        <Tooltip title={nextMessage}>
          <span>
            <CustomButton
              variant="gradient"
              fullWidth
              onClick={handleNextClick}
              disabled={
                isStartingUrlImport || selectedSessionIds?.length < 2 || selectedSessionIds?.length > 6
              }>
              {isStartingUrlImport ? (
                <Box component="span">
                  <Box component="span" pr={1}>
                    Setting up your session ...
                  </Box>
                  <CircularProgress size={12} />
                </Box>
              ) : (
                'Next'
              )}
            </CustomButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

const CreateSession = ({ onSidebarButtonClick, onShowAudienceList, previousSessions }) => {
  const { pathname, search } = useLocation();

  const queryParams = useMemo(() => queryString.parse(search), [search]);
  const modeParam = queryParams?.mode;

  const [isUploading, setIsUploading] = useState(false);
  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [isStartingUrlImport, setIsStartingUrlImport] = useState(false);
  const [deviceId, setDeviceId] = useState(DeviceNameToId.Desktop);
  const [sessionType, setSessionType] = useState(AutomatedInsightSessionType.Experience);

  const { track } = useAnalytics();
  const history = useHistory();
  const queryClient = useQueryClient();
  const user = useSelector(getUserProfile);
  const userCustomizations = useSelector(getUserCustomizations);
  const canUseExperiences = useMemo(() => canUsePulseExperiences(userCustomizations), [userCustomizations]);
  const canUseCompares = useMemo(() => canUsePulseCompares(userCustomizations), [userCustomizations]);

  const supportsJourney = user?.pulseSubscription?.features?.experience;
  const blockJourneys = !canUseExperiences || !supportsJourney;

  const supportsCompare = user?.pulseSubscription?.features?.compare;

  const isFreeTrial = useMemo(() => {
    return user?.pulseSubscription?.isFreeTrial;
  }, [user]);
  const freeTrialExpired = isFreeTrial && user?.pulseSubscription?.isExpired;
  const subscriptionExpired = !isFreeTrial && user?.pulseSubscription?.isExpired;

  const pulseRunsBySeat = useMemo(() => {
    return user?.usage?.seatRunCount || 0;
  }, [user]);
  const pulseLimitPerSeat = useMemo(() => {
    return user?.usage?.seatRunLimit || 0;
  }, [user]);
  const reachedUsageLimit = pulseRunsBySeat >= pulseLimitPerSeat;

  const selfServiceSubscription = useMemo(() => {
    if (!_.isNil(user?.pulseSubscription?.overrideRules?.hasSelfServiceBilling)) {
      return user?.pulseSubscription?.overrideRules?.hasSelfServiceBilling;
    }

    const shouldShowSelfServiceSubscriptions =
      userCustomizations?.features?.selfServiceSubscription !== 'false';

    const planSupportsSelfService = !PULSE_LEGACY_CREDIT_SUBSCRIPTION_TYPES.includes(
      user?.pulseSubscription?.pulseSubscriptionTier?.type
    );

    return shouldShowSelfServiceSubscriptions && planSupportsSelfService;
  }, [user, userCustomizations]);

  const disableFeatures =
    user?.pulseSubscription?.pulseSubscriptionTierId !== 'd64c8be4-29e5-4590-b7cc-0cd79f0a7dd6' &&
    user?.pulseSubscription?.pulseSubscriptionTierId !== 'ff7a8a2b-8ec6-42d7-bd44-21e20f68ba7e' &&
    selfServiceSubscription &&
    (freeTrialExpired ||
      subscriptionExpired ||
      (reachedUsageLimit &&
        user?.pulseSubscription?.pulseSubscriptionTierId !== '5861a582-f9ac-4b39-9b62-4cfb902fa68f' &&
        user?.pulseSubscription?.pulseSubscriptionTierId !==
          '03c7d189-622f-486c-9ab3-4cf9d6588a9e')); /* If higest tier (Innovator) - we allow the user to run a new pulse, even if reachedUsageLimit */

  const lockedSessionTypes = useMemo(() => {
    let lockedTypes = [];
    if (!supportsCompare) {
      lockedTypes.push(AutomatedInsightSessionType.Compare);
    }
    if (!supportsJourney) {
      lockedTypes.push(AutomatedInsightSessionType.Experience);
    }
    return lockedTypes;
  }, [supportsCompare, supportsJourney]);

  const { mutateAsync: uploadAsset } = useUploadAsset();
  const { mutate: generateInsights } = useGenerateInsights();

  const { mutate: createSessionFromUrls } = useCreateSessionFromURLs();
  const { mutate: generateImportURL } = useGenerateImportURL();

  const { mutate: createCompareInsightsSession } = useCreateCompareSession();

  useTrackPageLoad({
    name: TrackEvent.VIEWED_PULSE_SESSION_START,
  });

  // open sidebar and close audience sections when component mounts
  useEffect(() => {
    onSidebarButtonClick(true);
    onShowAudienceList(false);

    // If user selected a URL to import (prefilled from marketing landing page), start running it.
    if (queryParams?.url) {
      if (validator.isURL(queryParams?.url) && !validator.isEmail(queryParams?.url)) {
        // URL is valid, start import
        triggerGenerateImportURL(queryParams?.url);
      }
    }

    // If the link includes a partner code, get that url info and start running it
    if (queryParams?.code) {
      triggerGenerateImportURLWithCode(queryParams?.code);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.pulseSubscription && !user?.pulseSubscription?.features?.experience) {
      setSessionType(AutomatedInsightSessionType.Page);
    }
  }, [user]);

  // This is used for Experiences, but also when Pages become Experiences for a multi-page PDF
  const visitSessionSettings = useCallback(
    (sessionId) => {
      onSidebarButtonClick(false);
      queryClient.invalidateQueries('automatedInsightSessions');
      history.push({
        pathname: generatePath(Paths.automatedInsights.sessionSettings, {
          sessionId,
        }),
      });
    },
    [history, onSidebarButtonClick, queryClient]
  );

  const handleDeviceClick = (device) => {
    setDeviceId(device);
  };

  const handleSessionTypeclick = (type) => {
    setSessionType(type);
  };

  const triggerGenerateInsights = useCallback(
    ({ sessionId, createDependencies = false }) => {
      generateInsights(
        { sessionId, createDependencies },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries('automatedInsightSessions');
            history.push({
              pathname: generatePath(Paths.automatedInsights.session, { sessionId }),
              state: { sessionDetails: data },
            });
            onSidebarButtonClick(false);
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error generating insights');
          },
        }
      );
    },
    [generateInsights, history, onSidebarButtonClick, queryClient]
  );

  const handleFileInput = useCallback(
    async (file) => {
      setIsUploading(true);
      const uploadParams = {
        file: file[0],
        name: 'Untitled',
        deviceId,
        type: AutomatedInsightSessionType.Page,
      };
      track(TrackEvent.UPLOADED_PULSE_ASSET);
      await uploadAsset(uploadParams, {
        onSuccess: (data) => {
          const sessionId = data?.id;
          // If the session comes back as an experience, there was a multi-page PDF upload so it's now an experience.
          if (data?.type === AutomatedInsightSessionType.Experience) {
            track(TrackEvent.PULSE_MULTIPAGE_PDF_CHANGE_PAGE_TO_EXPERIENCE, {
              sessionId,
              pdfLength: data?.assets?.length,
            });
            visitSessionSettings(sessionId);
          } else {
            setIsUploading(false);
            triggerGenerateInsights({ sessionId });
          }
        },
        onError: (err) => {
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error uploading asset');
          setIsUploading(false);
        },
      });
    },
    [deviceId, track, uploadAsset, visitSessionSettings, triggerGenerateInsights]
  );

  const triggerGenerateImportURL = useCallback(
    (url) => {
      setIsStartingUrlImport(true);

      generateImportURL(
        { url, deviceId },
        {
          onSuccess: (data) => {
            track(TrackEvent.IMPORTED_PULSE_URL_ASSET, { urlId: data.automatedInsightImportUrl.id });

            onSidebarButtonClick(false);

            history.push({
              pathname: generatePath(Paths.automatedInsights.importURL, {
                importUrlId: data.automatedInsightImportUrl.id,
              }),
              state: { deviceId },
            });
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error generating asset from URL');
          },
          onSettled: () => {
            setIsStartingUrlImport(false);
          },
        }
      );
    },
    [generateImportURL, history, onSidebarButtonClick, track, deviceId]
  );

  const triggerGenerateImportURLWithCode = useCallback(
    (code) => {
      setIsStartingUrlImport(true);

      generateImportURL(
        { code },
        {
          onSuccess: (data) => {
            track(TrackEvent.IMPORTED_PULSE_URL_VIA_PARTNER_CODE, {
              urlId: data.automatedInsightImportUrl.id,
            });

            onSidebarButtonClick(false);

            history.push({
              pathname: generatePath(Paths.automatedInsights.importURL, {
                importUrlId: data.automatedInsightImportUrl.id,
              }),
              state: { deviceId: data?.pulseLinkCode.deviceId },
            });
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error generating asset from URL');
          },
          onSettled: () => {
            setIsStartingUrlImport(false);
          },
        }
      );
    },
    [generateImportURL, history, onSidebarButtonClick, track]
  );

  const createExperienceSessionFromURLs = useCallback(
    (urls) => {
      setIsStartingUrlImport(true);

      createSessionFromUrls(
        { urls, name: 'Untitled', deviceId, type: AutomatedInsightSessionType.Experience },
        {
          onSuccess: (data) => {
            onSidebarButtonClick(false);

            queryClient.invalidateQueries('automatedInsightSessions');

            history.push({
              pathname: generatePath(Paths.automatedInsights.sessionAssets, {
                sessionId: data.automatedInsightSession.id,
              }),
            });
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error generating session from URLs');
          },
          onSettled: () => {
            setIsStartingUrlImport(false);
          },
        }
      );
    },
    [createSessionFromUrls, history, onSidebarButtonClick, deviceId, queryClient]
  );

  const createExperienceSessionFromUploads = useCallback(
    async (files) => {
      setIsUploading(true);
      const uploadParams = {
        files: files,
        name: 'Untitled',
        deviceId,
        type: AutomatedInsightSessionType.Experience,
      };

      await uploadAsset(uploadParams, {
        onSuccess: (data) => {
          visitSessionSettings(data.id);
        },
        onError: (err) => {
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error uploading assets');
          setIsUploading(false);
        },
        onSettled: () => setIsUploading(false),
      });
    },
    [deviceId, uploadAsset, visitSessionSettings]
  );

  const createCompareSession = useCallback(
    async ({ files, urls, sessionIds }) => {
      setIsStartingUrlImport(true);
      setIsUploading(true);

      const params = {
        sessionIds: sessionIds,
        files: files,
        urls: urls,
        name: 'Untitled',
        deviceId,
        type: AutomatedInsightSessionType.Compare,
      };

      createCompareInsightsSession(params, {
        onSuccess: (data) => {
          onSidebarButtonClick(false);

          queryClient.invalidateQueries('automatedInsightSessions');

          if (sessionIds || urls) {
            history.push({
              pathname: generatePath(Paths.automatedInsights.sessionAssets, {
                sessionId: data.automatedInsightSession.id,
              }),
            });
            setIsUploading(false);
            setIsStartingUrlImport(false);
          } else {
            // files go immediately to generating insights
            triggerGenerateInsights({ sessionId: data.automatedInsightSession.id, createDependencies: true });
          }
        },
        onError: (err) => {
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error creating session');
          setIsUploading(false);
          setIsStartingUrlImport(false);
        },
      });
    },
    [
      createCompareInsightsSession,
      history,
      onSidebarButtonClick,
      deviceId,
      queryClient,
      triggerGenerateInsights,
    ]
  );

  const handleRouteCreateSessionRequest = useCallback(
    ({ files, urls, sessionIds, sessionType }) => {
      if (sessionIds?.length && sessionType === AutomatedInsightSessionType.Compare) {
        createCompareSession({ sessionIds });
      }

      if (urls) {
        if (sessionType === AutomatedInsightSessionType.Compare) {
          // if multiple urls and session is a compare, then trigger the compare url flow
          createCompareSession({ urls });
          return;
        } else {
          // if multiple urls and session is an experience, then trigger the experience url flow
          createExperienceSessionFromURLs(urls);
          return;
        }
      }

      if (files) {
        if (sessionType === AutomatedInsightSessionType.Compare) {
          // if multiple files and session is a compare, then trigger the compare upload flow
          createCompareSession({ files });
          return;
        } else {
          // if multiple files and session is an experience, then trigger the experience upload flow
          createExperienceSessionFromUploads(files);
          return;
        }
      }
    },
    [createCompareSession, createExperienceSessionFromURLs, createExperienceSessionFromUploads]
  );

  const toggleInfoDrawer = (open) => {
    setInfoDrawerOpen(open);
  };

  if (_.isNil(user) || _.isNil(userCustomizations)) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!canUseAutomatedInsightSessions(user, userCustomizations)) {
    return window.location.replace('/');
  }

  return (
    <Box pt={5} sx={{ height: 'inherit' }}>
      <Grid container alignContent="space-between" sx={{ minHeight: '100%' }}>
        <Grid item container justifyContent="center" rowSpacing={4}>
          <Grid container justifyContent="center" rowGap={3} sx={{ marginTop: 4 }}>
            {(canUseExperiences || canUseCompares) && (
              <>
                <Grid item xs={12} sm={10} lg={7}>
                  <Link
                    to={pathname}
                    style={{ visibility: modeParam === 'image' ? 'visible' : 'hidden', ...styles.link }}>
                    <ChevronLeftRoundedIcon />
                    Back to URL
                  </Link>
                </Grid>
                <Grid item container xs={12} sm={10} md={11} lg={9}>
                  <Grid container rowGap={1} alignItems="center" justifyContent="center">
                    <Grid item md={1} />
                    <Grid item xs md={9} lg={8}>
                      <Box
                        display="flex"
                        flexDirection={{ xs: 'column', md: 'row' }}
                        gap={2}
                        justifyContent="center">
                        <SessionTypeSelection
                          sessionType={sessionType}
                          onSelection={handleSessionTypeclick}
                          typesLocked={lockedSessionTypes}
                          user={user}
                          isCompareEnabled={canUseCompares}
                        />
                        <DeviceSelection
                          deviceId={deviceId}
                          onDeviceSelection={handleDeviceClick}
                          disableButtonGroup={sessionType === AutomatedInsightSessionType.Compare}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={1} />
                  </Grid>
                </Grid>
                {sessionType === AutomatedInsightSessionType.Compare && (
                  <Grid item xs={12} sm={10} md={11} lg={9}>
                    <Grid container rowGap={1} alignItems="center" justifyContent="center">
                      <Grid item xs md={9} lg={8}>
                        <CreateCompare
                          previousSessions={previousSessions}
                          isStartingUrlImport={isStartingUrlImport}
                          onNext={handleRouteCreateSessionRequest}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {sessionType !== AutomatedInsightSessionType.Compare && (
                  <Grid item xs={12} sm={10} md={11} lg={9}>
                    <CreateMultiAssetSession
                      initialURLs={queryParams?.url ? [queryParams.url] : null}
                      sessionType={sessionType}
                      onNext={handleRouteCreateSessionRequest}
                      isUploading={isUploading}
                      isStartingUrlImport={isStartingUrlImport}
                      isCompareEnabled={canUseCompares}
                      freeTrialExpired={freeTrialExpired}
                      subscriptionExpired={subscriptionExpired}
                      reachedUsageLimit={reachedUsageLimit}
                      disableFeatures={disableFeatures}
                      supportsJourney={user?.pulseSubscription?.features?.experience}
                      blockJourneys={blockJourneys}
                      user={user}
                    />
                  </Grid>
                )}
              </>
            )}
            {!canUseExperiences && !canUseCompares && (
              <>
                <Grid item container xs={12} sm={8} lg={4} justifyContent="center" sx={{ marginTop: 4 }}>
                  <Grid item>
                    <DeviceSelection deviceId={deviceId} onDeviceSelection={handleDeviceClick} />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={8} md={6} lg={4}>
                  <CreatePageSession
                    onFileInput={handleFileInput}
                    isUploading={isUploading}
                    initialURL={queryParams?.url}
                    onImportURL={triggerGenerateImportURL}
                    isStartingUrlImport={isStartingUrlImport}
                    freeTrialExpired={freeTrialExpired}
                    subscriptionExpired={subscriptionExpired}
                    reachedUsageLimit={reachedUsageLimit}
                    disableFeatures={disableFeatures}
                    user={user}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignContent="center"
          justifyContent="center"
          sx={{ paddingY: { xs: 8, lg: 4 } }}>
          <CustomButton variant="secondaryLight" sx={styles.button} onClick={() => toggleInfoDrawer(true)}>
            What can WEVO Pulse do?
          </CustomButton>
        </Grid>
      </Grid>
      <InfoDrawer open={infoDrawerOpen} onClose={toggleInfoDrawer} />
    </Box>
  );
};

export default CreateSession;
