import { Box, Card, CardContent, Checkbox, FormControlLabel, Grid, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import { Limits } from '../../../modules/intake/constants';
import { WevoTestType, WevoType } from '../../../modules/wevos/constants';
import ImagePreview from '../../../ui/ImagePreview';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
  },
  pagesSection: {
    marginTop: theme.spacing(2),
  },
  card: {
    width: '150px',
    height: '167px',
  },
  checkBox: {
    marginLeft: theme.spacing(-1),
  },
  pageName: {
    marginTop: '-17px',
    fontSize: '11px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export default function ScopesForQuestion(props) {
  const {
    draft,
    draftType,
    scopes,
    setScopes,
    updateScopes,
    pages,
    errors,
    isScopesValid,
    exceedsLimits,
    currentNumberOfQuestions,
    handleSelectAll,
    imageAccessor = (page, draft) => page.images,
    nameAccessor = (page, draft) => page.name,
  } = props;

  const classes = useStyles();
  const remainingCustomQuestions = Limits.Journey - currentNumberOfQuestions;
  const unselectedScopes = pages?.length - scopes?.length;

  const usesPageScope =
    draftType === WevoType.Classic ||
    (draftType === WevoType.Journey && draft.testType === WevoTestType.Compare);

  /* Handles page selection */
  const handlePageClick = (ev, page) => {
    const pageID = page.id.toString();
    const checked = ev.target.checked;
    const items = Array.from(scopes);
    let newArray = Array.from(items);

    // Add page/step to scopes
    if (checked) {
      if (!pageExistsInScopes(page)) {
        const newScopeToAdd = usesPageScope
          ? { wevoPageId: pageID, stepId: null }
          : { wevoPageId: page.wevoPageId, stepId: pageID };
        // Add page for Classic
        if (usesPageScope) {
          // Select a page after deselcting all pages. we need to delete the scope with no wevoPageId and stepId
          if (scopes?.length === 1 && !scopes?.[0]?.wevoPageId) {
            newArray = [newScopeToAdd];
          } else {
            newArray.push(newScopeToAdd);
          }
        }
        // Add step for Journey
        else {
          // Add the first step of a journey - we need to delete the default value of Jourey Level
          if (scopes.length === 1 && !scopes?.[0]?.stepId) {
            newArray = [newScopeToAdd];
          } else {
            newArray.push(newScopeToAdd);
          }
        }
      }
    }
    // Removes page/step from scopes
    else {
      const indexToRemove = usesPageScope
        ? scopes.findIndex((item) => item.wevoPageId === pageID)
        : scopes.findIndex((item) => item.stepId === pageID);

      // Remove page for Classic or step for Journey
      newArray = [...items.slice(0, indexToRemove), ...items.slice(indexToRemove + 1)];
    }
    updateScopes(newArray);
    setScopes(newArray);
  };

  const pageExistsInScopes = (page) => {
    const id = page.id;
    const found = usesPageScope
      ? scopes.find((scope) => String(scope.wevoPageId) === String(id))
      : scopes.find((scope) => String(scope.stepId) === String(id));

    return found ? true : false;
  };

  const isSelectAllChecked = pages?.every((page) => pageExistsInScopes(page));
  const checkboxDisabled =
    draftType === WevoType.Journey && draft?.testType === WevoTestType.Page && exceedsLimits && isScopesValid;
  const applyToAllDisabled =
    isSelectAllChecked ||
    (draftType === WevoType.Journey &&
      draft?.testType === WevoTestType.Page &&
      unselectedScopes > remainingCustomQuestions);

  return (
    <>
      <Grid container spacing={3} justifyContent="center" alignItems="center" className={classes.pagesSection}>
        <Grid item xs={3}>
          <Box p={2}>
            <Tooltip
              title={
                draftType === WevoType.Journey &&
                !isSelectAllChecked &&
                unselectedScopes > remainingCustomQuestions &&
                !(remainingCustomQuestions === 0)
                  ? `Note: The number of remaining boxes exceeds the number of custom questions you have remaining. 
            "Apply to all" is disabled. Please manually check the remaining boxes you wish to select.`
                  : ''
              }>
              <FormControlLabel
                label="Apply to all"
                control={
                  <Checkbox
                    checked={isSelectAllChecked}
                    onChange={handleSelectAll}
                    className={classes.checkBox}
                    disabled={applyToAllDisabled}
                  />
                }
              />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={3}>
            {pages &&
              pages.map((page) => {
                return (
                  <Grid item id={page.id} key={page.id}>
                    <Card elevation={2} className={classes.card}>
                      <ImagePreview image={imageAccessor(page, draft)} scopeImage={true} />
                      <CardContent>
                        <Grid container spacing={1} alignItems="center" justifyContent="space-around">
                          <Grid item xs={12}>
                            <Typography align="center" className={classes.pageName}>
                              {nameAccessor(page, draft)}
                            </Typography>
                            <Tooltip
                              title={
                                checkboxDisabled && !pageExistsInScopes(page)
                                  ? `Note: You have reached your limit of ${currentNumberOfQuestions} custom questions. 
                            Additional checkboxes are disabled.`
                                  : ''
                              }
                              placement="right">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={pageExistsInScopes(page)}
                                    onChange={(ev) => handlePageClick(ev, page)}
                                    className={classes.checkBox}
                                    disabled={checkboxDisabled && !pageExistsInScopes(page)}
                                  />
                                }
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
        {errors?.scopes && (
          <Grid item xs={12}>
            <Typography component="div" align="center" color="error" variant="caption">
              {errors.scopes.message}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}

function CustomQuestionPageScope({
  draft,
  groupId,
  scopes,
  setScopes,
  updateScopes,
  errors,
  isScopesValid,
  exceedsLimits,
  currentNumberOfQuestions,
  imageAccessor = (page, draft) => {
    if (draft?.type === WevoType.Journey) {
      return page?.steps?.[0]?.images;
    }

    return page.images;
  },
  nameAccessor = (page, draft) => {
    if (draft?.type === WevoType.Journey && draft?.testType === WevoTestType.Compare) {
      return `Journey ${page.label}`;
    }

    return page.name;
  },
}) {
  const wevoId = draft.id;
  const draftType = draft?.type;
  const pages = draft?.pages ?? [];

  const handleSelectAll = (ev) => {
    if (!ev.target.checked) {
      return;
    }

    const newScopes = pages.map((page) => ({ wevoPageId: String(page.id), stepId: null }));
    setScopes(newScopes);
    updateScopes(newScopes);
  };

  return (
    <ScopesForQuestion
      draft={draft}
      draftType={draftType}
      scopes={scopes}
      setScopes={setScopes}
      updateScopes={updateScopes}
      errors={errors}
      pages={pages}
      wevoId={wevoId}
      groupId={groupId}
      isScopesValid={isScopesValid}
      exceedsLimits={exceedsLimits}
      currentNumberOfQuestions={currentNumberOfQuestions}
      handleSelectAll={handleSelectAll}
      imageAccessor={imageAccessor}
      nameAccessor={nameAccessor}
    />
  );
}

function CustomQuestionStepScope({
  draft,
  page,
  groupId,
  scopes,
  setScopes,
  updateScopes,
  errors,
  isScopesValid,
  exceedsLimits,
  currentNumberOfQuestions,
  imageAccessor = (step, draft) => step.images,
  nameAccessor = (step, draft) => step.name,
}) {
  const wevoId = draft.id;
  const draftType = draft?.type;
  const steps = !_.isNil(page) ? page?.steps ?? [] : draft?.pages?.flatMap((page) => page?.steps ?? []);

  const handleSelectAll = (ev) => {
    const newScopes = steps.map((step) => ({ wevoPageId: step.wevoPageId, stepId: String(step.id) }));
    setScopes(newScopes);
    updateScopes(newScopes);
  };

  return (
    <ScopesForQuestion
      draft={draft}
      draftType={draftType}
      scopes={scopes}
      setScopes={setScopes}
      updateScopes={updateScopes}
      errors={errors}
      pages={steps}
      wevoId={wevoId}
      groupId={groupId}
      isScopesValid={isScopesValid}
      exceedsLimits={exceedsLimits}
      currentNumberOfQuestions={currentNumberOfQuestions}
      handleSelectAll={handleSelectAll}
      imageAccessor={imageAccessor}
      nameAccessor={nameAccessor}
    />
  );
}

export { CustomQuestionPageScope, CustomQuestionStepScope };
