import { debounce } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';

export default forwardRef(function DebouncedInput({ value, onChange, debounceMs, renderInput }, ref) {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    const invocation = debounce(() => onChange(internalValue), debounceMs);

    if (value !== internalValue) {
      // prevents unnecessary calls on mount
      invocation();
    }

    return () => invocation.cancel();
  }, [debounceMs, internalValue, onChange, value]);

  return renderInput({
    value: internalValue,
    onChange: (event) => setInternalValue(event.target.value),
    setInternalValue,
    ref,
  });
});
