import { useQuery } from 'react-query';
import axios from '../../../api.js';

export const getSavedAudiences = async () => {
  const response = await axios({
    url: `/api/v2/saved-audiences/groups`,
    method: 'GET',
  });
  return response.data.groups;
};

const fetchSavedAudience = async () => {
  return getSavedAudiences();
};

export default function useFetchSavedAudience(options) {
  return useQuery(['savedAudienceData'], fetchSavedAudience, {
    notifyOnChangeProps: 'tracked',
    ...(options ?? {}),
  });
}
