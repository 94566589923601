import { Check } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as SentimentIcon } from '../../../src/assets/Sentiment.svg';
import {
  ClusterTypes,
  ProgressKeys,
  SentimentTypes,
  TaskStatus,
} from '../../modules/automated-insights/constants';
import { InfoBox, WaitingMessage } from './InfoBox';
import SentimentMapFilterButtons from './SentimentMapFilterButtons';
import SentimentMapQuote from './SentimentMapQuote';
import SplashLoadingIndicator from './SplashLoadingIndicator';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '12px',
}));

const SentimentMapAssetSelect = ({ assets, selectedAssetIndex, handleAssetIndexSelected }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const onAssetIndexSelected = (index) => {
    handleAssetIndexSelected(index);
    setOpen(false);
  };

  return (
    <Box>
      <ButtonGroup ref={anchorRef} aria-label="Sentiment Map Asset Selector">
        <Button
          aria-controls={open ? 'button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{
            color: 'black',
            borderRadius: 8,
            fontSize: 12,
            background: 'transparent',
            height: '29px',
            textTransform: 'capitalize',
            border: '1px solid  #D1CFCF',
            '&:hover': {
              background: '#EDEDED',
              border: '1px solid  #D1CFCF',
            },
          }}>
          Page {selectedAssetIndex + 1}
          <Box component="span" px={0.5}></Box>
          {open ? <KeyboardArrowUpIcon sx={{ mr: -0.7 }} /> : <KeyboardArrowDownIcon sx={{ mr: -0.7 }} />}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement={'bottom-end'}
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper
              sx={{
                minWidth: '160px',
                borderRadius: '10px',
                border: '1px solid #DCDCDC',
                marginTop: 0.7,
              }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="asset-menu" autoFocusItem>
                  {(assets ?? []).map((_, index) => (
                    <StyledMenuItem
                      key={index}
                      value={index}
                      disableRipple
                      onClick={() => onAssetIndexSelected(index)}>
                      <Box sx={{ height: '100%', width: '100%' }}>
                        <Typography
                          variant="body2"
                          fontSize={12}
                          fontWeight={index === (selectedAssetIndex || 0) ? 500 : 400}
                          sx={{ minWidth: 65, textTransform: 'capitalize' }}>
                          Page {index + 1}
                          {(selectedAssetIndex || 0) === index && (
                            <Check sx={{ marginLeft: 1, fontSize: '14px' }} />
                          )}
                        </Typography>
                      </Box>
                    </StyledMenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

const getStatusMessage = ({ taskStatus, progressKey }) => {
  switch (taskStatus) {
    case TaskStatus.Pending:
      return 'Request to generate insights is pending';
    case TaskStatus.Enqueued:
      return 'Getting ready to generate insights';
    case TaskStatus.Running:
      return progressKey === ProgressKeys.HeatmapPoints || progressKey === ProgressKeys.Done
        ? 'Generating sentiment map'
        : 'Getting ready to generate sentiment map';
    case TaskStatus.Completed:
      return 'Completed!';
    case TaskStatus.Failed:
      return 'Failed - please try again';
    default:
      return '';
  }
};

const SentimentMapInsights = ({
  session,
  heatmapData,
  themeData,
  taskStatus,
  taskProgress,
  selectedClustersList,
  hasContentiousQuotes,
  isTaskDelayed,
  isTaskWaiting,
  selectedQuote,
  selectedLocation,
  onClusterClick,
  onQuoteClick,
  selectedAssetIndex,
  setSelectedAssetIndex,
}) => {
  const showAssetSelector = useMemo(() => (session?.assets ?? []).length > 1, [session]);

  let quoteRefs = useRef([]);
  const filteredPoints = useMemo(() => {
    let selectedSentiments = [];
    if (selectedClustersList?.includes(ClusterTypes.Likes)) {
      selectedSentiments.push(SentimentTypes.Positive);
    }
    if (selectedClustersList?.includes(ClusterTypes.Dislikes)) {
      selectedSentiments.push(SentimentTypes.Negative);
    }
    if (selectedClustersList?.includes(ClusterTypes.Contentious)) {
      selectedSentiments.push(SentimentTypes.Contentious);
    }
    const result = heatmapData?.reduce((acc, item) => {
      const filteredQuotes = item?.points?.filter((point) => selectedSentiments.includes(point.sentiment));
      if (!_.isEmpty(filteredQuotes)) {
        acc.push(...filteredQuotes);
      }
      return acc;
    }, []);

    return result;
  }, [heatmapData, selectedClustersList]);

  const filteredPointsByTheme = useMemo(() => {
    if (!themeData) {
      return {};
    }
    const mapping = filteredPoints?.reduce((acc, point) => {
      const theme = point?.theme;
      if (theme) {
        if (!acc[theme]) {
          acc[theme] = [];
        }
        acc[theme].push(point);
      }
      return acc;
    }, {});
    return mapping;
  }, [filteredPoints, themeData]);

  const pointsByThemeOrdered = useMemo(() => {
    if (!themeData) {
      return [];
    }
    const result = [];
    themeData.map(
      (theme, index) =>
        !_.isEmpty(filteredPointsByTheme?.[theme]) && result.push(...filteredPointsByTheme?.[theme])
    );
    return result;
  }, [filteredPointsByTheme, themeData]);

  const selectedLocationQuotes = useMemo(() => {
    if (selectedLocation) {
      const pointList = !_.isEmpty(pointsByThemeOrdered) ? pointsByThemeOrdered : filteredPoints;
      return pointList?.filter((quote) => quote?.block?.id === selectedLocation?.blockId);
    }
    return [];
  }, [filteredPoints, selectedLocation, pointsByThemeOrdered]);

  const handleBlockClick = (point) => {
    onQuoteClick(point);
  };

  useEffect(() => {
    if (!_.isNil(selectedLocation)) {
      // if there are multiple quotes for the selected location, we will scroll to the first one
      const pointList = !_.isEmpty(pointsByThemeOrdered) ? pointsByThemeOrdered : filteredPoints;
      const quoteIndex = pointList.findIndex((point) => _.isEqual(selectedLocationQuotes[0], point));
      if (quoteRefs.current[quoteIndex]) {
        quoteRefs.current[quoteIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [filteredPoints, selectedLocation, selectedLocationQuotes, pointsByThemeOrdered]);

  // aside from task completion, we will also need to check if the session results
  // contain heatmap data to ensure that the results fetched really do not contain heatmap points
  const noHeatmapPointsGenerated = useMemo(
    () => taskStatus === TaskStatus.Completed && heatmapData?.length === 0,
    [taskStatus, heatmapData]
  );

  const showTaskStatus = useMemo(
    () => taskStatus !== TaskStatus.Completed && heatmapData?.length === 0,
    [taskStatus, heatmapData]
  );

  const isSelected = (point) =>
    _.isEqual(selectedQuote, point) || selectedLocationQuotes?.some((quote) => _.isEqual(quote, point));

  if (noHeatmapPointsGenerated) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingX: '5%',
        }}>
        <Typography color="text.primary" textAlign="center">
          Oops! Seems like there isn't enough text on this image for us to generate a sentiment map. Upload
          another that has more text.
        </Typography>
      </Box>
    );
  } else if (showTaskStatus) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          paddingX: '5%',
        }}>
        <SplashLoadingIndicator
          message={getStatusMessage({ taskStatus, progressKey: taskProgress?.progressKey })}
          isDelayed={isTaskDelayed}
          isFailed={taskStatus === TaskStatus.Failed}
        />
        {isTaskWaiting && (
          <Box
            sx={{
              width: 'inherit',
              height: 'fit-content',
              position: 'absolute',
              bottom: 0,
              paddingY: 0,
              display: [TaskStatus.Pending, TaskStatus.Enqueued].includes(taskStatus) ? 'initial' : 'none',
            }}
            p={2}>
            <InfoBox sx={{ height: '100%' }} isWarning={true}>
              <WaitingMessage />
            </InfoBox>
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <>
        {!heatmapData ? (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <>
            <Box alignItems={'center'} sx={{ marginLeft: 2.3, marginBottom: 1.25 }}>
              <Grid container spacing={1}>
                <Grid item flexWrap={'nowrap'} mt={0.25} alignSelf="center">
                  <SentimentIcon fontSize="small" height={'22px'} />
                </Grid>
                <Grid item mt={0.5} ml={0.25} xs={10}>
                  <Typography fontSize={13} fontStyle="italic">
                    Sentiment Map - See what's working and what needs a little love on your experience.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {showAssetSelector && (
              <Box display="flex" justifyContent="flex-end" mb={2} px={3}>
                <SentimentMapAssetSelect
                  assets={session?.assets ?? []}
                  selectedAssetIndex={selectedAssetIndex || 0}
                  handleAssetIndexSelected={setSelectedAssetIndex}
                />
              </Box>
            )}
            <Box display="flex" justifyContent="center" mb={2}>
              <SentimentMapFilterButtons
                hasContentious={hasContentiousQuotes}
                selectedOptions={selectedClustersList}
                onToggleClick={onClusterClick}
              />
            </Box>
            <Box
              className="lightContainer"
              sx={{
                height: '90%',
                paddingBottom: showAssetSelector ? { xs: 18, sm: 14 } : { xs: 6 },
                overflow: 'auto',
              }}>
              {_.isEmpty(filteredPointsByTheme)
                ? filteredPoints?.map((point, index) => (
                    <SentimentMapQuote
                      key={`${point?.block?.id}-${index}`}
                      setRef={(el) =>
                        (quoteRefs.current[filteredPoints.findIndex((item) => _.isEqual(item, point))] = el)
                      }
                      point={point}
                      isLastQuote={index === filteredPoints?.length - 1}
                      isLastQuoteInSection={index === filteredPoints?.length - 1}
                      isSelected={isSelected}
                      handleBlockClick={handleBlockClick}
                    />
                  ))
                : themeData.map((theme, index) => (
                    <Fragment key={`theme-${theme}-${index}`}>
                      {!_.isEmpty(filteredPointsByTheme?.[theme]) && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: 3,
                            paddingX: { xs: 2, sm: 3 },
                            mb: 0.5,
                          }}>
                          <Typography variant="body2" fontWeight={600}>
                            {theme}
                          </Typography>
                        </Box>
                      )}
                      {filteredPointsByTheme?.[theme]?.map((point, innerIndex) => (
                        <SentimentMapQuote
                          key={`${point?.block?.id}-${innerIndex}`}
                          setRef={(el) =>
                            (quoteRefs.current[
                              pointsByThemeOrdered.findIndex((item) => _.isEqual(item, point))
                            ] = el)
                          }
                          point={point}
                          isLastQuote={
                            pointsByThemeOrdered.findIndex((item) => _.isEqual(item, point)) ===
                            pointsByThemeOrdered?.length - 1
                          }
                          isLastQuoteInSection={innerIndex === filteredPointsByTheme?.[theme]?.length - 1}
                          isSelected={isSelected}
                          handleBlockClick={handleBlockClick}
                        />
                      ))}
                    </Fragment>
                  ))}
            </Box>
          </>
        )}
      </>
    );
  }
};

export default SentimentMapInsights;
