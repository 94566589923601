import styled from '@emotion/styled';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as EnterpriseIcon } from '../../assets/enterprise-icon-filled.svg';
import { PULSE_SUBSCRIPTION_TYPES } from '../../modules/automated-insights/constants';

const SelectButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'subscriptionType',
})(({ theme, subscriptionType }) => ({
  height: '32px',
  width: '140px',
  borderRadius: '27px',
  border: '1px solid #359DAB',
  fontSize: '12px',
  fontWeight: 'bold',
  textTransform: 'none',
  color: [PULSE_SUBSCRIPTION_TYPES.PremiumAnnual, PULSE_SUBSCRIPTION_TYPES.PremiumMonth].includes(
    subscriptionType
  )
    ? theme.palette.text.secondary
    : theme.palette.text.tertiary,
  background: [PULSE_SUBSCRIPTION_TYPES.PremiumAnnual, PULSE_SUBSCRIPTION_TYPES.PremiumMonth].includes(
    subscriptionType
  )
    ? 'linear-gradient(90deg, #264151 0%, #4BC0C8 100%)'
    : '#F0F5F7',
  '&:hover': {
    background: [PULSE_SUBSCRIPTION_TYPES.PremiumAnnual, PULSE_SUBSCRIPTION_TYPES.PremiumMonth].includes(
      subscriptionType
    )
      ? '#359DAB'
      : '#4BC0C8',
    color: (theme) => theme.palette.text.tertiary,
  },
  '&.Mui-disabled': {
    border:
      ![PULSE_SUBSCRIPTION_TYPES.PremiumAnnual, PULSE_SUBSCRIPTION_TYPES.PremiumMonth].includes(
        subscriptionType
      ) && '1px solid rgba(140, 140, 140, 0.24)',
  },
}));

export const Products = {
  Pro: 'pro',
  Pulse: 'pulse',
  Video: 'video',
};

export const ProductDisplayName = {
  pro: 'Pro',
  pulse: 'Pulse',
  video: 'Video',
};

const styles = {
  root: {
    width: { xs: '285px', xl: 'auto' },
    minWidth: '180px',
    marginBottom: '40px',
    cursor: 'pointer',
  },
  titleGrid: {
    height: '20px',
  },
  topSectionGrid: {
    marginBottom: '16px',
  },
  img: {
    width: '100px',
    height: 'auto',
  },
  fieldSubTitleGrid: {
    padding: 0,
    height: '30px',
    textAlign: 'center',
  },
  fieldText: {
    marginTop: '-10px',
    fontSize: '11px',
  },
  checkIcon: {
    fontSize: '20px',
    color: '#4BC0C8',
  },
  centerText: {
    textAlign: 'center',
  },
  divider: {
    width: '100%',
    color: 'grey',
  },
  textColor: {
    color: (theme) => theme.palette.text.tertiary,
  },
};

const SelectableSubscriptionCard = (props) => {
  const {
    tier,
    title,
    subTitle,
    price,
    totalPrice,
    includeFeatures,
    numberOfPulses,
    hoveredLine,
    setHoveredLine,
    subscriptionType,
    numberOfSeats,
    getDiscountAmount,
    isTierSelected,
    onActionButtonClick,
    isFreePlan,
    isFreeTrial,
  } = props;

  const isDisabled =
    (subscriptionType === PULSE_SUBSCRIPTION_TYPES.Free && numberOfSeats > 1) || isTierSelected;

  return (
    <Grid item sx={styles.root}>
      <Grid container spacing={2} alignItems="flex-end">
        <Card
          elevation={0}
          sx={{
            padding: [PULSE_SUBSCRIPTION_TYPES.PremiumAnnual, PULSE_SUBSCRIPTION_TYPES.PremiumMonth].includes(
              subscriptionType
            )
              ? '1px'
              : 0,
            borderRadius: '10px',
            background: [
              PULSE_SUBSCRIPTION_TYPES.PremiumAnnual,
              PULSE_SUBSCRIPTION_TYPES.PremiumMonth,
            ].includes(subscriptionType)
              ? 'linear-gradient(270deg, #4BC0C8 0%, #264151 100%)'
              : 'none',
          }}>
          {[PULSE_SUBSCRIPTION_TYPES.PremiumAnnual, PULSE_SUBSCRIPTION_TYPES.PremiumMonth].includes(
            subscriptionType
          ) && (
            <CardHeader
              title="Most Popular"
              titleTypographyProps={{
                fontSize: 16,
                fontWeight: 700,
                textAlign: 'center',
                color: (theme) => theme.palette.text.secondary,
              }}
              sx={{
                background: 'linear-gradient(270deg, #4BC0C8 0%, #264151 100%)',
                padding: 0.5,
              }}
            />
          )}
          {[PULSE_SUBSCRIPTION_TYPES.InnovatorAnnual, PULSE_SUBSCRIPTION_TYPES.InnovatorMonth].includes(
            subscriptionType
          ) && (
            <CardHeader
              title="Offered during the free trial"
              titleTypographyProps={{
                fontSize: 10,
                fontWeight: 400,
                textAlign: 'center',
                color: (theme) => theme.palette.text.tertiary,
              }}
              sx={{
                backgroundColor: 'rgba(75, 192, 200, 0.24)',
                padding: 0.5,
              }}
            />
          )}
          <CardContent
            sx={{
              paddingLeft: 0,
              paddingRight: 0,
              borderRadius: [
                PULSE_SUBSCRIPTION_TYPES.PremiumAnnual,
                PULSE_SUBSCRIPTION_TYPES.PremiumMonth,
                PULSE_SUBSCRIPTION_TYPES.InnovatorAnnual,
                PULSE_SUBSCRIPTION_TYPES.InnovatorMonth,
              ].includes(subscriptionType)
                ? '0 0 10px 10px'
                : '10px',
              backgroundColor: [
                PULSE_SUBSCRIPTION_TYPES.PremiumAnnual,
                PULSE_SUBSCRIPTION_TYPES.PremiumMonth,
              ].includes(subscriptionType)
                ? '#F9F9F9'
                : '#F0F5F7',
              border: [PULSE_SUBSCRIPTION_TYPES.PremiumAnnual, PULSE_SUBSCRIPTION_TYPES.PremiumMonth].includes(
                subscriptionType
              )
                ? 'none'
                : '1px solid rgba(255, 255, 255, 0.12)',
            }}>
            <Grid container>
              <Grid item container justifyContent="space-between">
                <Grid item xs={12} sx={{ ...styles.titleGrid, marginLeft: 2 }}>
                  <Typography variant="h5" sx={styles.textColor}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item sx={{ marginLeft: 1, marginRight: 2, marginTop: 0.25 }}>
                  <Card
                    elevation={0}
                    sx={{ backgroundColor: 'rgba(255, 255, 255, 0.07)', width: '100px', height: '17px' }}>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        textAlign: 'center',
                        verticalAlign: 'center',
                        ...styles.textColor,
                      }}>
                      {getDiscountAmount(subscriptionType)
                        ? `$${getDiscountAmount(subscriptionType)} off monthly`
                        : ''}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ ...styles.topSectionGrid, height: '120px', marginLeft: 2 }}>
                <Grid container justifyItems="center">
                  <Grid item container xs={12} sx={{ alignItems: 'center' }}>
                    {subscriptionType !== PULSE_SUBSCRIPTION_TYPES.Enterprise && (
                      <Grid item sx={{ marginRight: 0.5 }}>
                        <Typography
                          sx={{
                            fontSize: 18,
                            ...styles.textColor,
                          }}>
                          <b>$</b>
                        </Typography>
                      </Grid>
                    )}
                    {subscriptionType === PULSE_SUBSCRIPTION_TYPES.Enterprise ? (
                      <Grid item xs={4} sx={{ marginTop: 0.9, marginBottom: 0.5 }}>
                        <EnterpriseIcon fill="#1A2A3A" />
                      </Grid>
                    ) : (
                      <Grid item>
                        <Typography
                          sx={{
                            color: (theme) =>
                              [
                                PULSE_SUBSCRIPTION_TYPES.PremiumAnnual,
                                PULSE_SUBSCRIPTION_TYPES.PremiumMonth,
                              ].includes(subscriptionType)
                                ? '#4BC0C8'
                                : theme.palette.text.tertiary,
                            fontSize: 30,
                            fontWeight: 450,
                          }}>
                          <b>{price}</b>
                        </Typography>
                      </Grid>
                    )}
                    {![PULSE_SUBSCRIPTION_TYPES.Free, PULSE_SUBSCRIPTION_TYPES.Enterprise].includes(
                      subscriptionType
                    ) && (
                      <Grid item sx={{ maxWidth: '80px', marginLeft: 0.5 }}>
                        <Typography
                          sx={{
                            fontSize: 11,
                            ...styles.textColor,
                          }}>
                          <b>seat / </b>
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 11,
                            marginTop: -0.25,
                            ...styles.textColor,
                          }}>
                          <b>month</b>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container sx={{ height: '36px' }}>
                    {totalPrice > price && (
                      <Grid item xs={12} sx={{ marginTop: -0.5, marginBottom: 0.5 }}>
                        <Typography
                          sx={{
                            fontSize: 11,
                            fontWeight: 'bold',
                            ...styles.textColor,
                          }}>
                          Total ${totalPrice} / month
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} sx={{ marginTop: -0.5, marginBottom: 1 }}>
                      <Typography
                        sx={{
                          fontSize: 11,
                          ...styles.textColor,
                        }}>
                        {subTitle}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        marginTop: 1,
                        fontSize: '13px',
                        ...styles.textColor,
                      }}>
                      Includes:
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {includeFeatures.map((feature, index) => (
                    <Grid
                      item
                      sx={{
                        ...styles.fieldSubTitleGrid,
                        padding: 0,
                        backgroundColor: hoveredLine === index && 'rgba(53, 157, 171, 0.06)',
                      }}
                      onMouseEnter={() => setHoveredLine(index)}
                      onMouseLeave={() => setHoveredLine(null)}
                      xs={12}
                      key={index}>
                      {feature ? (
                        <Typography
                          variant="body2"
                          sx={{
                            ...styles.fieldText,
                          }}>
                          <DoneRoundedIcon sx={styles.checkIcon} />
                        </Typography>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            height: '24px',
                            ...styles.fieldText,
                          }}
                        />
                      )}
                    </Grid>
                  ))}
                  <Grid
                    item
                    sx={{
                      ...styles.fieldSubTitleGrid,
                      backgroundColor: hoveredLine === includeFeatures.length && 'rgba(53, 157, 171, 0.06)',
                    }}
                    onMouseEnter={() => setHoveredLine(includeFeatures.length)}
                    onMouseLeave={() => setHoveredLine(null)}
                    xs={12}>
                    {numberOfPulses && (
                      <Typography
                        variant="body2"
                        sx={{
                          ...styles.fieldText,
                          ...styles.textColor,
                          fontSize: '13px',
                        }}>
                        {numberOfPulses}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ ...styles.centerText }}>
                {isTierSelected ? (
                  <Typography
                    sx={{
                      fontSize: '13px',
                      marginBottom: 0.75,
                      marginTop: 0.8,
                      ...styles.textColor,
                    }}>
                    <b>Current plan</b>
                  </Typography>
                ) : (
                  <SelectButton
                    disableElevation
                    disabled={isDisabled}
                    variant="contained"
                    subscriptionType={subscriptionType}
                    onClick={!isTierSelected ? () => onActionButtonClick(tier) : () => {}}>
                    {(!isFreePlan && !isFreeTrial) || subscriptionType === PULSE_SUBSCRIPTION_TYPES.Enterprise
                      ? 'Contact sales'
                      : 'Select'}
                  </SelectButton>
                )}
              </Grid>
            </Grid>
          </CardContent>
          {/* </div> */}
        </Card>
      </Grid>
    </Grid>
  );
};

export default SelectableSubscriptionCard;
