import { Box, Button, Typography } from '@mui/material';
import { IntakeTooltip } from '../components';
import { ConfirmButton } from '../components/Buttons';

function ActionBar({
  wevo,
  onBack,
  hasBackAction,
  onNext,
  onRequestPreview,
  hasRequestPreviewAction,
  onSubmit,
  hasSubmitAction,
  isSubmitDisabled,
  isSubmitting,
  errorMessage,
}) {
  const backButton = hasBackAction ? (
    <Button
      variant="text"
      sx={{
        borderRadius: 6,
        color: '#3B6CAB',
        textTransform: 'none',
        fontWeight: 600,
        height: 40,
        width: 85,
      }}
      onClick={onBack}>
      Back
    </Button>
  ) : (
    <></>
  );

  const nextButton = hasSubmitAction ? (
    <ConfirmButton
      label="Submit"
      sx={{ minWidth: 145, minHeight: 40 }}
      onClick={onSubmit}
      isLoading={isSubmitting}
    />
  ) : (
    <ConfirmButton label="Next" sx={{ minWidth: 145, minHeight: 40 }} onClick={onNext} />
  );

  // hiding the request preview button for now so hasRequestPreviewAction will be false
  const requestPreviewButton = hasRequestPreviewAction ? (
    <IntakeTooltip
      title={
        isSubmitDisabled
          ? 'Please resolve all errors in order to request an interactive preview link.'
          : 'Note: you will receive an interactive preview link in 1-2 business days. If you need to make additional changes to your study after requesting a link, please contact Customer Support before making additional changes to your study.'
      }>
      <span>
        <Button
          variant="outlined"
          sx={{
            borderRadius: 6,
            color: '#3B6CAB',
            textTransform: 'none',
            fontWeight: 600,
            height: 40,
            minWidth: 145,
            whiteSpace: 'nowrap',
          }}
          disabled={isSubmitDisabled}
          onClick={onRequestPreview}>
          Request Interactive Preview
        </Button>
      </span>
    </IntakeTooltip>
  ) : (
    <></>
  );

  return (
    <Box
      display="flex"
      justifyContent={hasSubmitAction && errorMessage ? 'space-between' : 'flex-end'}
      gap={2}
      height="100%"
      alignItems="center">
      {hasSubmitAction && errorMessage && (
        <Box>
          <Typography sx={{ fontSize: '12px', color: '#EE404C' }}>{errorMessage}</Typography>
        </Box>
      )}
      <Box display="flex" gap={1}>
        {backButton}
        {requestPreviewButton}
        {nextButton}
      </Box>
    </Box>
  );
}

export default ActionBar;
