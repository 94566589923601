import { Box, Typography } from '@mui/material';
import { ReactComponent as InfoIconFilled } from '../../../../../../assets/info-icon-filled.svg';

function SurveySectionPlaceholder({ title, children }) {
  return (
    <Box my={2}>
      <Box p={2} sx={{ border: '1.5px solid #C7D6DF', borderRadius: 3 }}>
        <Box display="flex" gap={1} mb={2} alignItems="center">
          <InfoIconFilled width="16px" height="16px" />
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
        </Box>

        {children}
      </Box>
    </Box>
  );
}

export default SurveySectionPlaceholder;
