import styled from '@emotion/styled';
import { Box, CircularProgress, Divider, Grid, Tooltip, Typography, tooltipClasses } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as PersonasIcon } from '../../../src/assets/Personas.svg';
import { ReactComponent as PulseInfoIcon } from '../../../src/assets/pulse-info-icon.svg';
import LockSectionIcon from '../../assets/lockSectionIcon.png';
import { TaskStatus } from '../../modules/automated-insights/constants';
import { getUserProfile } from '../../modules/user/selectors';
import { TrackEvent } from '../analytics';
import AudienceEditor from './AudienceEditor';
import Segment from './Segment';

const CustomTooltip = styled(({ className, maxWidth, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: maxWidth,
  },
}));

const NewAudience = ({ sessionId, onClick }) => {
  const { track } = useAnalytics();
  const user = useSelector(getUserProfile);

  const isFreePlan = useMemo(() => {
    return user?.pulseSubscription?.isFreePlan;
  }, [user]);

  const pulseRunsBySeat = useMemo(() => {
    return user?.usage?.seatRunCount || 0;
  }, [user]);

  const pulseLimitPerSeat = useMemo(() => {
    return user?.usage?.seatRunLimit || 0;
  }, [user]);

  const reachedUsageLimit = pulseRunsBySeat >= pulseLimitPerSeat && user?.pulseSubscription?.isSeatBased;

  const handleClick = () => {
    onClick(null);
    track(TrackEvent.CLICKED_PULSE_NEW_AUDIENCE_BUTTON, {
      sessionId,
    });
  };

  return (
    <Box
      onClick={
        !isFreePlan && user?.pulseSubscription?.features?.personaCustomization && !reachedUsageLimit
          ? handleClick
          : () => {}
      }
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingY: 1,
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        border: '1px solid rgba(217, 217, 217, 0.83)',
        cursor: 'pointer',
      }}>
      <CustomTooltip
        title={
          isFreePlan || !user?.pulseSubscription?.features?.personaCustomization
            ? 'This feature is not included in your subscription.'
            : reachedUsageLimit
            ? 'You’ve reached your usage limit for the current plan.'
            : ''
        }
        maxWidth={'200px'}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item display="flex" alignItems="center" lineHeight="normal">
            <Typography
              variant="caption"
              component="div"
              sx={{ paddingLeft: 2, paddingY: 1, fontWeight: 500, flexWrap: 'nowrap' }}>
              New Persona
            </Typography>
            {(isFreePlan || !user?.pulseSubscription?.features?.personaCustomization || reachedUsageLimit) && (
              <Typography sx={{ marginLeft: 1 }}>
                <img src={LockSectionIcon} alt="selectable" style={{ width: '9px', height: 'auto' }} />
              </Typography>
            )}
          </Grid>
          <Grid item display="flex" alignItems="center">
            <Tooltip
              title="Create and define a new persona to tailor the analysis to your specific audience needs."
              placement="right">
              <PulseInfoIcon width="12px" />
            </Tooltip>
          </Grid>
        </Grid>
      </CustomTooltip>
    </Box>
  );
};

const GeneratingProposedAudience = () => {
  return (
    <Box
      sx={{
        paddingBottom: 2,
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        border: '1px solid rgba(217, 217, 217, 0.83)',
      }}>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="caption" component="div" sx={{ paddingX: 2, paddingY: 1, fontWeight: 500 }}>
            Proposed Persona
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Box
        sx={{
          height: 70,
          paddingTop: 2,
          paddingX: 2,
        }}>
        <Typography sx={{ fontSize: '12px', lineHeight: '20px' }}>
          Check back soon to see the proposed persona details.
        </Typography>
      </Box>
    </Box>
  );
};

const AudienceEditorComponent = ({
  sessionId,
  selectedSegment,
  sessionSegments,
  onInsightsRegeneration,
  onSaveAudience,
  onCancel,
}) => {
  return (
    <Box
      className="lightContainer"
      sx={{
        height: '100%',
        overflow: 'auto',
        paddingBottom: 6,
        paddingX: { xs: 1, sm: 3 },
        backgroundColor: '#F8F8F8',
      }}>
      <AudienceEditor
        sessionId={sessionId}
        selectedSegment={selectedSegment}
        sessionSegments={sessionSegments}
        onInsightsRegeneration={onInsightsRegeneration}
        onSaveAudience={onSaveAudience}
        onCancel={onCancel}
      />
    </Box>
  );
};

const SegmentsContainer = ({
  taskStatus,
  session,
  sessionId,
  selectedSegment,
  sessionSegments,
  segmentsWithoutSessionInsights,
  segmentToResultMap,
  text,
  isCustomizingSegment,
  setIsCustomizingSegment,
  isSessionOwner,
  onSelectSegment,
  onSectionChange,
  onInsightsRegeneration,
}) => {
  const [segmentToEdit, setSegmentToEdit] = useState(null);

  useEffect(() => {
    if (selectedSegment) {
      const target = document.getElementById(selectedSegment.id);

      if (target) {
        target.scrollIntoView();
      }
    }
  }, [selectedSegment]);

  const selectSegmentToEdit = useCallback(
    (segment) => {
      setSegmentToEdit(segment);
      setIsCustomizingSegment(true);
    },
    [setIsCustomizingSegment]
  );

  const finishEditing = useCallback(() => {
    setIsCustomizingSegment(false);
  }, [setIsCustomizingSegment]);

  const cancelCustomization = useCallback(() => {
    setIsCustomizingSegment(false);
  }, [setIsCustomizingSegment]);

  if (isCustomizingSegment) {
    return (
      <AudienceEditorComponent
        sessionId={sessionId}
        selectedSegment={segmentToEdit}
        sessionSegments={sessionSegments}
        onInsightsRegeneration={onInsightsRegeneration}
        onSaveAudience={finishEditing}
        onCancel={cancelCustomization}
      />
    );
  }

  if (!sessionSegments) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  } else if (
    // there aren't any segments to show and the proposed audience isn't currently being generated
    _.isEmpty(sessionSegments) &&
    _.isEmpty(segmentsWithoutSessionInsights) &&
    [TaskStatus.Failed, TaskStatus.Completed].includes(taskStatus)
  ) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography>You don't have any personas yet!</Typography>
      </Box>
    );
  }

  const proposedAudience = (sessionSegments ?? []).find((segment) => segment?.isGenerated);
  const userGeneratedAudiences = (sessionSegments ?? []).filter((segment) => !segment?.isGenerated);

  const isGeneratingInsights = (segmentId) =>
    !segmentToResultMap[segmentId]?.findings && sessionSegments?.some((segment) => segment?.id === segmentId);

  return (
    <Box
      className="lightContainer"
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
        paddingX: 2,
        paddingBottom: 6,
        margin: 0,
      }}>
      <>
        <Box my={1}>
          <Typography fontSize={12} fontWeight={600}>
            {text}
          </Typography>
        </Box>
        {(_.isEmpty(sessionSegments) || !proposedAudience) &&
          ![TaskStatus.Failed, TaskStatus.Completed].includes(taskStatus) && <GeneratingProposedAudience />}
        {proposedAudience && (
          <Grid className="lightContainer" container spacing={2}>
            {[proposedAudience].map((segment) => (
              <Grid item key={segment?.id} xs={12}>
                <Segment
                  segment={segment}
                  isOwner={isSessionOwner}
                  onSelectSegment={onSelectSegment}
                  onSectionChange={onSectionChange}
                  onInsightsRegeneration={onInsightsRegeneration}
                  onSelectSegmentToEdit={selectSegmentToEdit}
                  hasSessionInsights={!!segmentToResultMap[segment?.id]?.findings}
                  hasInsightsGenerating={isGeneratingInsights(segment?.id)}
                  initialIsExpanded={segment?.id === selectedSegment?.id}
                  isGeneratedSegment={true}
                  isSelected={segment?.id === selectedSegment?.id}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Box my={2} />
        <Grid className="lightContainer" container spacing={2}>
          {userGeneratedAudiences?.map((segment) => (
            <Grid item key={segment?.id} xs={12} id={segment?.id}>
              <Segment
                segment={segment}
                isOwner={isSessionOwner}
                onSelectSegment={onSelectSegment}
                onSectionChange={onSectionChange}
                onInsightsRegeneration={onInsightsRegeneration}
                onSelectSegmentToEdit={selectSegmentToEdit}
                hasSessionInsights={!!segmentToResultMap[segment?.id]?.findings}
                hasInsightsGenerating={isGeneratingInsights(segment?.id)}
                initialIsExpanded={segment?.id === selectedSegment?.id}
                isSelected={segment?.id === selectedSegment?.id}
              />
            </Grid>
          ))}
        </Grid>
        <Box mt={2}>
          {isSessionOwner && (
            <NewAudience session={session} sessionId={sessionId} onClick={selectSegmentToEdit} />
          )}
        </Box>
      </>

      {!_.isEmpty(segmentsWithoutSessionInsights) && isSessionOwner && (
        <>
          <Box mt={4} mb={1}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography fontSize={12} fontWeight={600}>
                  Other personas
                </Typography>
              </Grid>
              <Grid item display="flex" alignItems="center">
                <Tooltip
                  title="Pick from personas you've created in past Pulses, making it easy to apply them again in this session. Reuse and go!"
                  placement="right">
                  <PulseInfoIcon width="12px" />
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <Grid className="lightContainer" container spacing={2}>
            {segmentsWithoutSessionInsights?.map((segment) => (
              <Grid item key={segment?.id} xs={12}>
                <Segment
                  segment={segment}
                  isOwner={isSessionOwner}
                  onSelectSegment={onSelectSegment}
                  onSectionChange={onSectionChange}
                  onInsightsRegeneration={onInsightsRegeneration}
                  onSelectSegmentToEdit={selectSegmentToEdit}
                  hasSessionInsights={!!segmentToResultMap[segment?.id]}
                  hasInsightsGenerating={isGeneratingInsights(segment?.id)}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default SegmentsContainer;

export function MultiSessionSegmentsContainer({
  sessions,
  selectedSessions,
  taskStatus,
  sessionId,
  selectedSegment,
  sessionSegments,
  segmentsWithoutSessionInsights,
  segmentToResultMap,
  isSessionOwner,
  onSelectSegment,
  onSectionChange,
  onInsightsRegeneration,
  onSelectSegmentToEdit,
}) {
  const selectedSegmentInfo = useMemo(() => {
    if (!selectedSegment) {
      return <></>;
    }

    const segmentSession = sessions.find(
      (session) => !!(session?.segments ?? []).find((segment) => segment.id === selectedSegment?.id)
    );

    if (!segmentSession) {
      return <></>;
    }

    return (
      <Grid item xs={12}>
        <Box my={1}>
          <Typography fontWeight={600} fontSize={12}>
            {segmentSession.name}
          </Typography>
        </Box>
        <Segment
          segment={selectedSegment}
          isOwner={isSessionOwner}
          showActions={false}
          initialIsExpanded={true}
          canToggle={false}
        />
      </Grid>
    );
  }, [isSessionOwner, selectedSegment, sessions]);

  const proposedSegmentsList = useMemo(() => {
    return (sessions ?? []).map((session) => {
      const proposedAudience = (session?.segments ?? []).find((segment) => segment.isGenerated);

      return (
        <Grid item key={session?.id} xs={12}>
          <Box my={1}>
            <Typography fontWeight={600} fontSize={12}>
              {session.name}
            </Typography>
          </Box>
          {proposedAudience ? (
            <Segment
              segment={proposedAudience}
              isOwner={isSessionOwner}
              showActions={false}
              initialIsExpanded={false}
            />
          ) : (
            <GeneratingProposedAudience />
          )}
        </Grid>
      );
    });
  }, [isSessionOwner, sessions]);

  return (
    <Box
      className="lightContainer"
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
        paddingX: 2,
        margin: 0,
      }}>
      <Grid className="lightContainer" container spacing={2}>
        {selectedSegment ? selectedSegmentInfo : proposedSegmentsList}
      </Grid>
    </Box>
  );
}

export function SessionSegmentDetails(props) {
  // note: this is just a thin wrapper around the segment component for now, but as a fast follow we're going to build on this
  // so adding this here as a placeholder
  return (
    <Box
      className="lightContainer"
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
        paddingX: 3,
        paddingBottom: 6,
        margin: 0,
      }}>
      <Box alignItems={'center'} sx={{ marginBottom: 1.75, marginLeft: -0.7 }}>
        <Grid container spacing={1}>
          <Grid item flexWrap={'nowrap'} mt={0.25}>
            <PersonasIcon fontSize="small" height={'22px'} />
          </Grid>
          <Grid item mt={0.5} ml={0.2}>
            <Typography fontSize={13} fontStyle="italic">
              Personas - Meet the people your experience is trying to reach.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Segment {...props} />
    </Box>
  );
}
