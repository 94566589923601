import { cloneDeep } from 'lodash';

const TOP_LEVEL_FIELDS = ['details', 'isRandomized', 'name', 'options', 'questionText', 'questionType'];
const OPTION_FIELDS = ['anchorIndex', 'isExclusive', 'optionText', 'outcome'];

// When we do optimistic updates, the structure we send to the backend must be merged at multiple levels
// to match the structure when we read it back out. This helper function handles merging the updates in a way
// that emulates what would happen if we re-queried the backend
export const mergeUpdates = ({ customScreener, optionId, updateFields }) => {
  const newState = cloneDeep(customScreener);

  for (const [key, value] of Object.entries(updateFields || {})) {
    if (TOP_LEVEL_FIELDS.includes(key)) {
      newState[key] = value;
    }

    if (OPTION_FIELDS.includes(key)) {
      const optionIndex = newState.options.findIndex((option) => option.id === optionId);
      const option = newState.options[optionIndex];
      newState.options[optionIndex] = { ...option, [key]: value };
    }
  }

  return newState;
};

export const mergeAttributeChanges = ({ audience, attributeId, changes }) => {
  const updatedAudience = { ...audience };

  if (attributeId) {
    // Update segments for a specific attribute
    updatedAudience.attributes = audience.attributes.map((attr) => {
      if (attr.id === attributeId) {
        return {
          ...attr,
          segments: attr.segments.map((segment) => {
            const change = changes?.segments.find((update) => update.id === segment.id);
            return change ? { ...segment, ...change } : segment;
          }),
        };
      }
      return attr;
    });
  } else {
    // Update attributes
    updatedAudience.attributes = audience.attributes.map((attribute) => {
      const change = changes?.attributes.find((updatedAttribute) => updatedAttribute.id === attribute.id);
      return change ? change : attribute;
    });
  }

  return updatedAudience;
};
