import { Typography } from '@mui/material';
import SurveySectionPlaceholder from '../survey-elements/SurveySectionPlaceholder';

function FirstImpressionPreview() {
  return (
    <SurveySectionPlaceholder title="First Impression Block">
      <Typography>
        Respondents are asked 5-8 questions to gauge their First Impression of the experience.
      </Typography>
    </SurveySectionPlaceholder>
  );
}

export default FirstImpressionPreview;
