import { Box, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { isEmpty, isEqual, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useWevo from '../../../hooks/useWevo';
import { Metrics, TestTypes } from '../../../modules/intake/constants';
import {
  getSelectedMetricName,
  metricToMetricType,
  metricTypeToMetric,
} from '../../../modules/intake/helpers';
import * as UserActions from '../../../modules/user/actions';
import { isUserLoaded } from '../../../modules/user/helpers';
import { getUserCustomizations, getUserProfile, getUserTeamId } from '../../../modules/user/selectors';
import { MastercardCdsOption, MastercardDqsOption, WevoType } from '../../../modules/wevos/constants';
import { snackbar } from '../../../notifications';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import CreateTestForm from '../create/CreateTestForm';
import useDeleteQuestions from '../hooks/useDeleteQuestions';
import useSaveWevo from '../hooks/useSaveWevo';

const DefineTest = ({
  fetchUser,
  user,
  teamId,
  setTestType,
  updateDefinitionValidity,
  userCustomizations,
  customQuestionsGroups,
}) => {
  const { wevoId } = useParams();
  const { data: draft } = useWevo(wevoId);

  let history = useHistory();

  // state variables are used to store deltas between pending state on the front end and authoritative state on the backend
  const [selectedMetric, setSelectedMetric] = useState(metricTypeToMetric(draft?.metricType));
  const [metricToSetOnConfirm, setMetricToSetOnConfirm] = useState();
  const [showDiscardMetricDialog, setShowDiscardMetricDialog] = useState(false);
  const [newMetricName, setNewMetricName] = useState('');

  // stores the "optimistic state" of tags and emails for responsiveness
  // we unfortunately have to initialize them after the wevo is loaded
  const [tagsList, setTagsList] = useState();
  const [emailsToNotify, setEmailsToNotify] = useState();

  const enabledDQS = userCustomizations?.features?.mastercardDqs === MastercardDqsOption.Enabled;
  const enabledCDS = userCustomizations?.features?.mastercardCds === MastercardCdsOption.Enabled;
  const showMetricSelection = enabledDQS || enabledCDS;

  useEffect(() => {
    if (!teamId || !isUserLoaded(user)) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!draft || isEmpty(draft)) return;

    if (isNil(tagsList)) {
      setTagsList(draft?.tags || []);
    }

    if (isNil(emailsToNotify)) {
      setEmailsToNotify(draft?.emailsToNotify || []);
    }
  }, [draft, tagsList, setTagsList, emailsToNotify, setEmailsToNotify]);

  useTrackPageLoad({ name: TrackEvent.STARTED_EDIT_TEST, properties: { wevoId: draft?.analyticsId } });

  const { mutate: saveWevo, isLoading } = useSaveWevo();
  const { mutate: deleteQuestions } = useDeleteQuestions();

  const updatedDescription = metricTypeToMetric(draft.metricType) !== selectedMetric ? '' : draft.description;

  const onSubmit = (data) => {
    const { testName, ownerName, ownerEmail } = data;

    const payload = {
      id: wevoId,
      name: testName,
      ownerName,
      ownerEmail,
      description: updatedDescription,
      jobToBeDone: draft.jobToBeDone,
      metricType: metricToMetricType(selectedMetric),
    };

    if (!isEqual(draft.tags.map((tag) => tag.name).sort(), tagsList.map((tag) => tag.name).sort())) {
      payload.tags = tagsList;
    }

    if (!isEqual(draft.emailsToNotify, emailsToNotify)) {
      payload.emailsToNotify = emailsToNotify;
    }

    saveWevo(payload, {
      onSuccess: () => {
        history.push({ pathname: `/wevos/${draft.id}/edit/test-goal` });
      },
      onError: (err) => {
        snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
      },
    });
    // Update test type from Classic to Journey when switching from Wevo metric to DQS/CDS metric
    if ([Metrics.DQS, Metrics.CDS].includes(selectedMetric) && draft.type === WevoType.Classic) {
      setTestType(TestTypes.Journey);
    }
  };

  useEffect(() => {
    setSelectedMetric(() => {
      if (draft?.metricType) {
        return metricTypeToMetric(draft?.metricType);
      }
      return null;
    });
  }, [draft]);

  const handleMetricChange = (newMetric) => {
    if (selectedMetric !== newMetric) {
      setNewMetricName(getSelectedMetricName(newMetric));
      setShowDiscardMetricDialog(true);
      setMetricToSetOnConfirm(newMetric);
    }
  };

  const switchMetric = () => {
    if (metricToSetOnConfirm === Metrics.CDS && !isEmpty(customQuestionsGroups)) {
      customQuestionsGroups.forEach((group) => {
        deleteQuestions(
          { id: wevoId, groupId: group.groupId },
          {
            onSuccess: () => {
              setSelectedMetric(metricToSetOnConfirm);
            },
            onError: () => {
              setMetricToSetOnConfirm(null);
              snackbar.error('Error switching metric type');
            },
          }
        );
      });
    } else setSelectedMetric(metricToSetOnConfirm);
  };

  const toggleShowMetricDialog = () => {
    setShowDiscardMetricDialog((showDiscardMetricDialog) => !showDiscardMetricDialog);
    setMetricToSetOnConfirm(null);
  };

  const handleEmailsToNotifyChanged = (emailsToNotify) => {
    setEmailsToNotify(emailsToNotify);
  };

  const handleTagsChanged = (tagsList) => {
    setTagsList(tagsList);
  };

  return (
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Typography variant="h3">Create Your WEVO</Typography>
      {!isEmpty(draft) && !isLoading && isUserLoaded(user) ? (
        <CreateTestForm
          onSubmit={onSubmit}
          updateDefinitionValidity={updateDefinitionValidity}
          draft={draft}
          user={user}
          selectedMetric={selectedMetric}
          showMetricSelection={showMetricSelection}
          handleMetricChange={handleMetricChange}
          enabledDQS={enabledDQS}
          enabledCDS={enabledCDS}
          showDiscardMetricDialog={showDiscardMetricDialog}
          newMetricName={newMetricName}
          switchMetric={switchMetric}
          toggleShowMetricDialog={toggleShowMetricDialog}
          tagsList={tagsList ?? []}
          onTagsChanged={handleTagsChanged}
          emailsToNotify={emailsToNotify ?? []}
          onEmailsToNotifyChanged={handleEmailsToNotifyChanged}
        />
      ) : (
        <Box mt={4}>
          <CircularProgress size={24} />
        </Box>
      )}
    </Box>
  );
};

DefineTest.propTypes = {
  updateDefinitionValidity: PropTypes.func.isRequired,
  setTestType: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    teamId: getUserTeamId(state),
    userCustomizations: getUserCustomizations(state),
    user: getUserProfile(state),
  };
};

const actionCreators = {
  fetchUser: UserActions.fetchUserInfo,
};

export default connect(mapStateToProps, actionCreators, null, { forwardRef: true })(DefineTest);
