import _ from 'lodash';
import { createElement } from 'react';

export const Environment = {
  Production: 'production',
  Staging: 'staging',
  Development: 'development',
};

export function getAppBarColor(mode = 'light') {
  if (process.env.REACT_APP_ENV_FILE === Environment.Production) {
    return mode === 'light' ? '#276EB0' : '#294151';
  } else if (process.env.REACT_APP_ENV_FILE === Environment.Staging) {
    return '#6F6F6F';
  } else {
    return '#323232';
  }
}

// Need to deep compare props to prevent unnecessary re-renders of complex objects (e.g. scores array)
export const memoAreEqual = (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
};

export const headingRenderer = (props) =>
  createElement(
    `h${props.level}`,
    {
      style: {
        marginLeft: '24px',
        lineHeight: '1.3',
        textAlign: 'left',
      },
    },
    props.children
  );

export const customRenderers = {
  h1: headingRenderer,
  h2: headingRenderer,
  h3: headingRenderer,
  h4: headingRenderer,
  h5: headingRenderer,
  h6: headingRenderer,
  ul: (props) =>
    createElement(
      `ul`,
      {
        style: {
          lineHeight: '1.5', // lineHeight = sets the margin between each bullet's lines
          textAlign: 'left',
          marginTop: '-8px',
          paddingLeft: '16px',
        },
      },
      props.children.map((child, index) => {
        return createElement('div', { key: index, style: { marginBlock: '14px' } }, child); // marginBlock = sets the margin between 2 bullets
      })
    ),
  ol: (props) =>
    createElement(
      `ol`,
      {
        style: {
          lineHeight: '1.5', // lineHeight = sets the margin between each bullet's lines
          textAlign: 'left',
          marginTop: '-8px',
          paddingLeft: '24px',
        },
      },
      props.children.map((child, index) => {
        return createElement('div', { key: index, style: { marginBlock: '14px' } }, child); // marginBlock = sets the margin between 2 bullets
      })
    ),
  strong: (props) =>
    createElement(
      `strong`,
      {
        style: {},
      },
      props.children.map((child, index) => {
        return createElement('strong', { key: index, style: { fontWeight: 500 } }, child); // marginBlock = sets the margin between 2 bullets
      })
    ),
};
