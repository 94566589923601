import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as BusinessesIcon } from '../../../../../../assets/businesses.svg';
import { ReactComponent as ConsumersIcon } from '../../../../../../assets/consumers.svg';
import { ReactComponent as FasterIcon } from '../../../../../../assets/faster.svg';
import { AudienceCategory } from '../../../../../../modules/wevos/constants';
import {
  CustomTextField,
  Header,
  IntakeTooltip,
  SelectableTile,
  StyledListItemButton,
} from '../../../../components';
import DebouncedInput from '../../../../edit/DebouncedInput';
import { DefaultAttributesByAudienceCategoryId } from '../../constants';
import { getCurrentDateTimeFormatted, isCustomizedAudience } from '../../helpers';
import CustomScreeners from '../custom-screeners';
import AttributesList from './AttributesList';

const NewAudience = ({
  audience,
  savedAudienceGroup,
  categoryId,
  onWevoChanged,
  onAudienceChanged,
  onAudienceNameChanged,
  onDeleteSavedAudience,
  errors,
}) => {
  const [audienceCategoryId, setAudienceCategoryId] = useState(categoryId);
  const [isCustomizingAttributes, setIsCustomizingAttributes] = useState(
    isCustomizedAudience(audience?.attributes)
  );
  const [showDescription, setShowDescription] = useState(!!audience?.description);
  const [saveAudience, setSaveAudience] = useState(savedAudienceGroup?.name?.length >= 0);

  const audienceAttributes = audience?.attributes;

  const handleAudienceCategoryChanged = async (selectedCategoryId) => {
    const prevCategoryId = audienceCategoryId;
    const prevIsCustomizingAttributes = isCustomizingAttributes;

    if (selectedCategoryId === audienceCategoryId) {
      return;
    }
    setAudienceCategoryId(selectedCategoryId);

    // the attributes for the new audience category will have isSelected=false and isCustomizedQuota=false
    setIsCustomizingAttributes(false);

    try {
      await onWevoChanged({ changedFields: { audienceCategoryId: selectedCategoryId } });
    } catch (err) {
      setAudienceCategoryId(prevCategoryId);
      setIsCustomizingAttributes(prevIsCustomizingAttributes);
    }
  };

  const handleCustomizationClick = async (isCustomizing) => {
    const prevIsCustomizingAttributes = isCustomizingAttributes;

    if (isCustomizing === isCustomizingAttributes) {
      return;
    }

    setIsCustomizingAttributes(isCustomizing);
    if (!isCustomizing) {
      try {
        await onAudienceChanged({
          changes: { attributes: DefaultAttributesByAudienceCategoryId[audienceCategoryId] },
        });
      } catch (err) {
        setIsCustomizingAttributes(prevIsCustomizingAttributes);
      }
    }
  };

  const handleDescriptionToggleChanged = async (isChecked) => {
    setShowDescription(isChecked);

    if (!isChecked) {
      onAudienceChanged({ changes: { description: '' } });
    }
  };

  const handleDescriptionChange = async (description) => {
    onAudienceChanged({ changes: { description } });
  };

  const handleAudienceNameToggleChanged = async (isChecked) => {
    const prevSaveAudience = saveAudience;

    setSaveAudience(isChecked);
    if (isChecked) {
      const defaultName = `Audience ${getCurrentDateTimeFormatted()}`;
      try {
        await onAudienceNameChanged(defaultName);
      } catch (err) {
        setSaveAudience(prevSaveAudience);
      }
    }

    if (savedAudienceGroup?.id && !isChecked) {
      try {
        await onDeleteSavedAudience(savedAudienceGroup?.id);
      } catch (err) {
        setSaveAudience(prevSaveAudience);
      }
    }
  };

  const handleAudienceNameChanged = async (name) => {
    await onAudienceNameChanged(name);
  };

  return (
    <>
      {/* Target */}
      <Box>
        <Header name={'Target'} description={'Who are you targeting?'} isRequired={true} />
        <Grid container spacing={2} mt={1}>
          <Grid item xs={6}>
            <SelectableTile
              width={'100%'}
              size={'large'}
              label={'Consumers'}
              iconComponent={<ConsumersIcon />}
              isSelected={audienceCategoryId === AudienceCategory.B2C.id}
              onSelect={() => handleAudienceCategoryChanged(AudienceCategory.B2C.id)}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectableTile
              width={'100%'}
              size={'large'}
              label={'Businesses'}
              iconComponent={<BusinessesIcon />}
              isSelected={audienceCategoryId === AudienceCategory.B2B.id}
              onSelect={() => handleAudienceCategoryChanged(AudienceCategory.B2B.id)}
            />
          </Grid>
        </Grid>
      </Box>
      {audienceCategoryId && (
        <>
          {/* Demographic screeners */}
          <Box mt={5}>
            <Header
              name={
                audienceCategoryId === AudienceCategory.B2C.id
                  ? 'Demographic screeners'
                  : 'Firmographic screeners'
              }
              description={
                audienceCategoryId === AudienceCategory.B2C.id
                  ? "We'll run this WEVO with people who match the criteria below. Customize for a more specific audience."
                  : "We'll run this WEVO with people who's employment information match the criteria below. Customize for a more specific audience."
              }
              isRequired={true}
              tooltipProps={{
                title:
                  audienceCategoryId === AudienceCategory.B2C.id
                    ? `Demographic screeners capture the characteristics of your audience in terms of gender, age, income and education.
                        These criteria will be filterable in your report.
                        When selecting the General Population option, your audience will consist of respondents who are eligible to take
                        the survey on a first come first serve basis.
                        When selecting the Customize option, we will do our best to collect responses from respondents who match your criteria distribution.`
                    : `Firmographic screeners capture the characteristics of your audience in terms of revenue, job role, and company size.
                        These criteria will be filterable in your report. When selecting the General Business Audience option, your audience
                        will consist of respondents who are eligible to take the survey on a first come first serve basis.
                        When selecting the Customize option, we will do our best to collect responses from respondents who match your criteria distribution.`,
                arrow: true,
                placement: 'right',
              }}
            />
            <Box>
              <StyledListItemButton
                primaryListItemText={'General Population'}
                secondaryListItemText={
                  audienceCategoryId === AudienceCategory.B2C.id
                    ? 'Non-specific criteria for: Gender, age, income, and education'
                    : 'Non-specific criteria for: Revenue, job role, and company size'
                }
                listItemIcon={
                  <IntakeTooltip title="2-4 days faster" arrow placement="right">
                    <FasterIcon />
                  </IntakeTooltip>
                }
                isSelected={isCustomizingAttributes === false}
                onClick={() => handleCustomizationClick(false)}
              />
              <StyledListItemButton
                primaryListItemText={'Customize'}
                secondaryListItemText={
                  audienceCategoryId === AudienceCategory.B2C.id
                    ? 'Select specific criteria for: Gender, age, income, and education'
                    : 'Select specific criteria for: Revenue, job role, and company size'
                }
                isSelected={isCustomizingAttributes === true}
                onClick={() => handleCustomizationClick(true)}
              />
            </Box>
            <Box mt={1}>
              {isCustomizingAttributes && (
                <AttributesList
                  attributes={audienceAttributes}
                  audienceCategoryId={audienceCategoryId}
                  onAudienceChange={onAudienceChanged}
                  errors={errors}
                />
              )}
            </Box>
          </Box>
          {/* Additional screeners */}
          <Box mt={5}>
            <CustomScreeners />
          </Box>
          {/* Other screening criteria */}
          <Box mt={5}>
            <Header
              name={'Other screening criteria'}
              description={`Enter additional details of your audience that you think were not captured in the regular screening fields above.`}
              tooltipProps={{
                title: `Other screening criteria will require further review by a WEVO team member as requirements
                can change price and time to complete. If changed, you will be asked to approve before launch.
                By default, WEVO may screen for panelists with an interest or intent in your product or industry.`,
                arrow: true,
                placement: 'right',
              }}
              toggleSwitchProps={{
                checked: showDescription,
                onChange: (ev) => handleDescriptionToggleChanged(ev.target.checked),
              }}
            />
            {showDescription && (
              <DebouncedInput
                value={audience?.description ?? ''}
                onChange={(value) => {
                  handleDescriptionChange(value);
                }}
                debounceMs={500}
                renderInput={({ value, onChange }) => (
                  <CustomTextField
                    multiline
                    minRows={3}
                    maxRows={10}
                    value={value}
                    aria-label="other screening criteria"
                    placeholder='Example: To test in specific areas, you can enter: "Geo: UK".'
                    onChange={onChange}
                    sx={{ marginTop: 2 }}
                  />
                )}
              />
            )}
          </Box>
          {/* Save audience */}
          <Box mt={5}>
            <Header
              name={'Save audience'}
              description={'Save this audience for future use.'}
              toggleSwitchProps={{
                checked: saveAudience,
                onChange: (ev) => handleAudienceNameToggleChanged(ev.target.checked),
              }}
            />
            {savedAudienceGroup?.name?.length >= 0 && (
              <Box mt={1}>
                <DebouncedInput
                  value={savedAudienceGroup?.name ?? ''}
                  onChange={(value) => {
                    handleAudienceNameChanged(value);
                  }}
                  debounceMs={500}
                  renderInput={({ value, onChange }) => (
                    <CustomTextField value={value} placeholder={'Audience Name'} onChange={onChange} />
                  )}
                />
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default NewAudience;
