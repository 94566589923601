import { isNil } from 'lodash';
import { CustomQuestionScopeType, CustomQuestionTypes } from '../constants';

export const getScopeType = (customQuestion) => {
  const questionScopes = customQuestion?.scopes || [];

  if (questionScopes.length === 0) {
    return customQuestion?.question?.type === CustomQuestionTypes.Heatmap
      ? CustomQuestionScopeType.Step
      : CustomQuestionScopeType.Page;
  }

  const onlyPageScopes = questionScopes.every((scope) => !isNil(scope.wevoPageId) && isNil(scope.stepId));
  return onlyPageScopes ? CustomQuestionScopeType.Page : CustomQuestionScopeType.Step;
};

export const makeScopeFromPage = ({ page, label }) => {
  const pageId = page.id;
  // Use the first step's image when selecting at the page level
  const stepImages = page?.steps?.[0]?.images || {};

  return {
    scope: { wevoPageId: String(pageId), stepId: null },
    images: stepImages,
    label: label || page.name,
    page,
  };
};

export const makeScopeFromStep = ({ step, label }) => {
  const stepId = step.id;
  const pageId = step.wevoPageId;
  const images = step?.images || {};

  return {
    scope: { wevoPageId: String(pageId), stepId: stepId },
    images: images,
    label: label || step.name,
    step,
  };
};

export const areScopesEqual = (scopeA, scopeB) => {
  if (!scopeA || !scopeB) {
    return false;
  }

  const pagesMatch = String(scopeA.wevoPageId) === String(scopeB.wevoPageId);

  const stepsMatch =
    (isNil(scopeA.stepId) && isNil(scopeB.stepId)) || String(scopeA.stepId) === String(scopeB.stepId);

  return pagesMatch && stepsMatch;
};

export const isScopeSelected = ({ customQuestion, scope }) => {
  const questionScopes = customQuestion?.scopes || [];
  const matchingScope = questionScopes.find((questionScope) => areScopesEqual(questionScope, scope.scope));
  return !isNil(matchingScope);
};

export const defaultScopes = ({ wevo, scopeType }) => {
  // note: this generates scopes as required by the api, which is actually just one property of the front end's scope

  if (scopeType === CustomQuestionScopeType.Page) {
    // when setting the scope type to page, the question applies to all pages in the study
    return (wevo?.pages ?? []).map((page) => makeScopeFromPage({ page }).scope);
  } else {
    // otherwise, the scope type is step, so only apply it to the first step of the first page
    const firstStep = wevo?.pages?.[0]?.steps?.[0];

    if (!firstStep) {
      return [];
    }

    return [makeScopeFromStep({ step: firstStep }).scope];
  }
};

export const isInvalidScope = (scope) => isNil(scope.wevoPageId) && isNil(scope.stepId);

export const getQuestionsForScope = (customQuestions, { wevoPageId, stepId }) => {
  return customQuestions.filter((customQuestion) => {
    return customQuestion.scopes?.find((scope) => areScopesEqual(scope, { wevoPageId, stepId }));
  });
};

export const hasScopes = (customQuestions, { wevoPageId, stepId }) => {
  return getQuestionsForScope(customQuestions, { wevoPageId, stepId })?.length > 0;
};
