import CloseIcon from '@mui/icons-material/Close';
import { Card, Chip, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import * as EmailValidator from 'email-validator';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CustomTextField } from '../../../components';

const styles = makeStyles((theme) => ({
  deleteIcon: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.2),
    height: '10px',
    stroke: theme.palette.primary.main,
    strokeWidth: '0.5',
  },
  dropDownCard: {
    height: '40px',
    marginBottom: theme.spacing(1),
  },
  dropDownChip: {
    '&:hover': {
      backgroundColor: grey[100],
    },
    backgroundColor: '#C7D6DF',
    color: 'black',
    fontSize: '12px',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.75),
  },
  emailChip: {
    backgroundColor: '#C7D6DF',
    color: 'black',
    fontSize: '12px',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: 390,
  },
  chipsArea: {
    maxHeight: 110,
    overflowY: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },
}));

const EMAILS_TO_NOTIFY_LIMIT = 10;

const TeamEmails = ({ wevo, onEmailsToNotifyChanged }) => {
  const [email, setEmail] = useState('');

  const [dropDownItems, setDropDownItems] = useState([]);
  const [scrollToBottom, setScrollToBottom] = useState(false);

  const classes = styles();
  const emailsRef = useRef();

  const emailsToNotify = useMemo(() => {
    return wevo?.emailsToNotify || [];
  }, [wevo]);

  const isValidValue = (value) => {
    if (!isEmail(value) || isInList(value)) {
      return false;
    }

    return true;
  };

  const isEmail = (value) => {
    return EmailValidator.validate(value);
  };

  const isInList = (value) => {
    return emailsToNotify.includes(value);
  };

  const handleEditEmail = async (ev) => {
    const value = ev.target.value.trim();
    setEmail(value);

    if (value && isValidValue(value)) {
      setDropDownItems([email]);
    } else {
      setDropDownItems([]);
    }
  };

  const handleKeyPress = (ev) => {
    if (ev.key === 'Enter' && isValidValue(email)) {
      ev.preventDefault();
      handleAddEmail();
    }
  };

  const handleAddEmail = () => {
    const updatedEmails = [...emailsToNotify, email];
    onEmailsToNotifyChanged({ emailsToNotify: updatedEmails });
    setEmail('');
    setDropDownItems([]);
    setScrollToBottom(true);
  };

  const handleDeleteEmail = (item) => {
    const updatedEmails = [...emailsToNotify];
    const filteredEmails = updatedEmails.filter((i) => i !== item);
    onEmailsToNotifyChanged({ emailsToNotify: filteredEmails });
    setScrollToBottom(false);
  };

  useEffect(() => {
    if (scrollToBottom && emailsToNotify.length > 3) {
      // shows the newly added email in the list of team emails when the section is scrollable
      emailsRef.current.scrollTop = emailsRef.current.scrollHeight;
    }
  }, [scrollToBottom, emailsToNotify]);

  return (
    <>
      <CustomTextField
        required
        type="email"
        value={email}
        onChange={(ev) => {
          handleEditEmail(ev);
        }}
        onKeyPress={(ev) => handleKeyPress(ev)}
        name="StudyName"
        autoComplete="email"
        disabled={emailsToNotify.length >= EMAILS_TO_NOTIFY_LIMIT}
      />
      {Boolean(dropDownItems.length) && (
        <Card className={classes.dropDownCard}>
          <Chip label={email} size="small" onClick={handleAddEmail} className={classes.dropDownChip} />
        </Card>
      )}
      {Boolean(emailsToNotify?.length) && (
        <div className={classes.chipsArea} ref={emailsRef}>
          {emailsToNotify?.map((item, index) => (
            <Tooltip title={item.length > 50 ? item : ''} placement="right" key={index}>
              <Chip
                label={item}
                onDelete={() => handleDeleteEmail(item)}
                size="small"
                deleteIcon={<CloseIcon style={{ color: 'black' }} className={classes.deleteIcon} />}
                className={classes.emailChip}
              />
            </Tooltip>
          ))}
        </div>
      )}
    </>
  );
};

export default TeamEmails;
