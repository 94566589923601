import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, List, ListItem } from '@mui/material';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

function AccordionListItem({ title, children, initialOpen = true, dragHandleProps }) {
  const [isOpen, setIsOpen] = useState(initialOpen);

  return (
    <Box>
      <Accordion
        variant="outlined"
        expanded={isOpen}
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&.MuiAccordion-root': {
            borderRadius: 4,
            borderColor: '#C7D6DF',
          },
          '.MuiAccordionSummary-root': {
            backgroundColor: '#F4F4F6',
            borderRadius: 4,
            '&.Mui-expanded': {
              minHeight: 15,
              backgroundColor: '#F4F4F6',
              borderRadius: '16px 16px 0px 0px', // corresponds to theme units 4 x 4 = 16px
            },
            '&.Mui-expanded > .MuiAccordionSummary-content': {},
          },
          '.MuiAccordionDetails-root': {
            borderRadius: '0 0 16px 16px', // corresponds to theme units 4 x 4 = 16px
            borderTop: '1px solid #C7D6DF',
          },
        }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreRoundedIcon
              sx={{ color: '#212A37 !important', fontSize: 28, cursor: 'pointer' }}
              onClick={() => setIsOpen(!isOpen)}
            />
          }
          sx={{
            '&.MuiAccordionSummary-root': {
              userSelect: 'auto',
            },
            '& .MuiAccordionSummary-content': {
              cursor: 'default',
            },
          }}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', width: '100%' }} {...dragHandleProps}>
            <DragIndicatorIcon
              fontSize="small"
              sx={{
                verticalAlign: 'bottom',
                marginRight: '10px',
              }}
            />
            {title}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 2 }}>
          <Box>{children}</Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

function ReorderableAccordionListItem({
  index,
  item,
  initialOpen,
  BoxProps,
  renderTitle,
  renderItem,
  dragHandleProps,
}) {
  return (
    <Box {...BoxProps}>
      <AccordionListItem
        index={index}
        initialOpen={initialOpen}
        title={renderTitle(item, index)}
        dragHandleProps={dragHandleProps}>
        {renderItem(item, index)}
      </AccordionListItem>
    </Box>
  );
}

function defaultRenderDraggable({
  index,
  item,
  BoxProps = { sx: { mb: 4, ':last-child': { mb: 2 } } },
  renderTitle,
  renderItem,
  dragHandleProps,
}) {
  return (
    <ReorderableAccordionListItem
      index={index}
      item={item}
      title={item.title}
      initialOpen={true}
      BoxProps={BoxProps}
      renderTitle={renderTitle}
      renderItem={renderItem}
      dragHandleProps={dragHandleProps}
    />
  );
}

function ReorderableList({
  items,
  renderTitle = ({ item, index }) => <></>,
  renderItem = ({ item, index }) => <></>,
  onDragEnd,
  droppableId = 'ItemsList',
  renderDraggable = defaultRenderDraggable,
}) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {/* note: droppableId must be a string */}
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <List className={droppableId} {...provided.droppableProps} ref={provided.innerRef}>
            {!isEmpty(items) &&
              items?.map((item, index) => (
                <Draggable
                  // note: draggableId must be a string
                  draggableId={String(item?.id ? item.id : `placeholder_${index}`)}
                  key={item?.id ? item.id : `placeholder_${index}`}
                  index={index}>
                  {(provided) => (
                    <ListItem ref={provided.innerRef} {...provided.draggableProps} sx={{ p: 0 }}>
                      <Grid item xs={12}>
                        {renderDraggable({
                          index,
                          item,
                          renderTitle,
                          renderItem,
                          dragHandleProps: provided.dragHandleProps,
                        })}
                      </Grid>
                    </ListItem>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export { ReorderableList };
