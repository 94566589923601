import { Box, FormControlLabel, Typography } from '@mui/material';
import { cloneDeep, isNil, isNumber } from 'lodash';
import { useState } from 'react';
import { ReactComponent as DisabledIcon } from '../../../../assets/intake-x-icon.svg';
import { ReactComponent as WarningYellowIcon } from '../../../../assets/warning-yellow.svg';
import { MetricTypes } from '../../../../modules/intake/constants';
import { CustomTextField, Header, IntakeTooltip } from '../../components';
import CustomCheckbox from '../../components/Checkbox';
import DebouncedInput from '../../edit/DebouncedInput';
import { CUSTOM_QUESTION_SYSTEM_MAX, CUSTOM_QUESTION_SYSTEM_MIN } from '../constants';
import { ExpectationsModal, QualitativeInsightsModal } from './components/ExpectationsModals';
import { BetaTag } from '../../components/Tag';

function ReportFeaturesList({ numRespondents, features, onChange, metricType }) {
  const isCds = metricType === MetricTypes.MastercardCds;
  const isDqs = metricType === MetricTypes.MastercardDqs;
  const isMastercardTestType = isCds || isDqs;

  const diagnosticsDescription = () => {
    if (numRespondents < 50) {
      return 'Qualtiative Only';
    }

    if (numRespondents < 120) {
      return 'No Statistical Significance';
    }

    return 'Statistical Significance';
  };

  const expectationsDescription = () => {
    return features.includeExpectations
      ? 'Including Expectations in your study may delay results by 2 days'
      : '';
  };
  const qualitativeExpectationsDescription = () => {
    return features.includeQualitativeExpectations
      ? 'Including insights may delay results by 1 - 2 additional days'
      : '';
  };

  return (
    <Box>
      <Header name="Select what is included in your report:" />
      <Box my={2}>
        <BooleanFeatureListItem
          label="Sentiment Maps"
          value={features.includeSentimentMaps}
          disabled={isMastercardTestType}
          tooltipText={isMastercardTestType ? 'Sentiment maps are required for this study type.' : ''}
          onChange={(newValue) => onChange({ ...cloneDeep(features), includeSentimentMaps: newValue })}
          disableUserInteraction={false}
        />
      </Box>
      <Box my={2}>
        {numRespondents < 50 ? (
          <DisabledFeatureListItem label="Diagnostics" />
        ) : (
          <BooleanFeatureListItem
            label="Diagnostics"
            value={features.includeDiagnostics}
            disabled={isMastercardTestType}
            tooltipText={isMastercardTestType ? 'Diagnostics are required for this study type.' : ''}
            onChange={(newValue) => onChange({ ...cloneDeep(features), includeDiagnostics: newValue })}
            description={diagnosticsDescription()}
            disableUserInteraction={false}
          />
        )}
      </Box>
      <Box my={2}>
        <BooleanFeatureListItem
          label="Key Findings"
          value={features.includeKeyFindings}
          tooltipText={isCds ? 'Key findings are required for this study type.' : ''}
          disabled={isCds}
          onChange={(newValue) => onChange({ ...cloneDeep(features), includeKeyFindings: newValue })}
          disableUserInteraction={isCds}
        />
      </Box>
      <Box my={2}>
        {numRespondents < 75 ? (
          <DisabledFeatureListItem label="Expectations" />
        ) : (
          <BooleanFeatureListItem
            label="Expectations"
            value={features.includeExpectations}
            disabled={false}
            onChange={(newValue) => onChange({ ...cloneDeep(features), includeExpectations: newValue })}
            disableUserInteraction={false}
            description={expectationsDescription()}
            descriptionIcon={<WarningYellowIcon />}
            confirmBeforeChange={true}
            ConfirmationModal={ExpectationsModal}
            subItems={[
              {
                label: 'Qualitative Insights',
                endIcon: (
                  <BetaTag
                    tooltipProps={{
                      title:
                        "We're evolving our Qualitative insights to provide even sharper, more actionable findings. While we enhance this feature, it's now available as a Beta option for those seeking exploratory context.",
                      arrow: true,
                      placement: 'right',
                    }}
                  />
                ),
                value: features.includeQualitativeExpectations,
                onChange: (newValue) =>
                  onChange({ ...cloneDeep(features), includeQualitativeExpectations: newValue }),
                description: qualitativeExpectationsDescription(),
                descriptionIcon: <WarningYellowIcon />,
                confirmBeforeChange: true,
                ConfirmationModal: QualitativeInsightsModal,
                isSubItem: true,
              },
            ]}
          />
        )}
      </Box>
      <Box my={2}>
        <NumericFeatureListItem
          prefix="Up to"
          label="Custom Questions"
          value={features.customQuestionLimit}
          checked={features.includeCustomQuestions}
          isValueEditable={false}
          disabled={false}
          tooltipText={
            'Your custom question limit changes based on how many other features are included in your report. You can include any number of custom questions up to the limit for your study.'
          }
          onChange={({ checked, value }) => {
            const updates = {};

            if (!isNil(checked)) {
              updates.includeCustomQuestions = checked;
            }

            if (!isNil(value)) {
              updates.customQuestionLimit = value;
            }

            onChange({ ...cloneDeep(features), ...updates });
          }}
          disableUserInteraction={true}
        />
      </Box>
    </Box>
  );
}

function DisabledFeatureListItem({ label }) {
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box sx={{ width: '28px' }}>
          <Box display="flex">
            <DisabledIcon />
          </Box>
        </Box>
        <IntakeTooltip title="Available on higher sample sizes" arrow placement="right">
          <Typography fontSize={'16px'} fontWeight={500} sx={{ color: '#B3B3B3' }}>
            {label}
          </Typography>
        </IntakeTooltip>
      </Box>
    </Box>
  );
}

function BooleanFeatureListItem({
  label,
  endIcon,
  description,
  descriptionIcon,
  value,
  disabled,
  onChange,
  disableUserInteraction = false,
  tooltipText,
  confirmBeforeChange = false,
  ConfirmationModal, // modal component
  subItems = [],
  isSubItem = false,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingValue, setPendingValue] = useState(false);

  // nb: these are temporary disable styles - it will go away with custom surveys
  const userInteractionInputProps = disableUserInteraction
    ? { disabled: true, sx: { cursor: 'initial' } }
    : {};

  const handleCheckboxChange = (checked) => {
    if (!checked) {
      onChange(checked);
    } else if (confirmBeforeChange) {
      setPendingValue(checked);
      setIsModalOpen(true);
    } else {
      onChange(checked);
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box sx={{ width: '28px' }}>
          <IntakeTooltip title={tooltipText || ''} arrow placement="right">
            <FormControlLabel
              disabled={disabled}
              sx={{ width: '100%' }}
              control={
                <CustomCheckbox
                  inputProps={userInteractionInputProps}
                  checked={value}
                  onChange={(ev) => handleCheckboxChange(ev.target.checked)}
                />
              }
            />
          </IntakeTooltip>
        </Box>
        <Box display="flex" alignItems="center" sx={{ columnGap: 1 }}>
          <Typography fontSize={isSubItem ? '14px' : '16px'} fontWeight={500}>
            {label}
          </Typography>
          {endIcon && endIcon}
        </Box>
      </Box>
      {description && (
        <Box display="flex" alignItems="center" sx={{ marginTop: '-6px', marginLeft: '28px', columnGap: 1 }}>
          {descriptionIcon && descriptionIcon}
          <Typography fontSize={12} fontWeight={400} sx={{ color: '#878787' }}>
            {description}
          </Typography>
        </Box>
      )}

      {/* Nested sub-items */}
      {value && subItems.length > 0 && (
        <Box sx={{ marginLeft: '32px', marginTop: '8px' }}>
          {subItems.map((subItem, index) => (
            <BooleanFeatureListItem key={index} isSubItem={true} {...subItem} />
          ))}
        </Box>
      )}

      {/* Render the custom modal if provided */}
      {ConfirmationModal && (
        <ConfirmationModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          onConfirm={() => {
            onChange(pendingValue);
            setIsModalOpen(false);
          }}
        />
      )}
    </Box>
  );
}

function NumericFeatureListItem({
  prefix,
  label,
  description,
  checked,
  value,
  isValueEditable,
  disabled,
  onChange,
  disableUserInteraction = false,
  tooltipText,
}) {
  // nb: these are temporary disable styles - it will go away with custom surveys
  const userInteractionInputProps = disableUserInteraction
    ? { disabled: true, sx: { cursor: 'initial' } }
    : {};

  return (
    <Box>
      <Box display="flex" alignItems="center">
        {isValueEditable && (
          <Box sx={{ width: '28px' }}>
            <IntakeTooltip title={tooltipText || ''} arrow placement="right">
              <FormControlLabel
                disabled={disabled}
                sx={{ width: '100%' }}
                control={
                  <CustomCheckbox
                    inputProps={userInteractionInputProps}
                    checked={checked}
                    onChange={(ev) => onChange({ checked: ev.target.checked })}
                  />
                }
              />
            </IntakeTooltip>
          </Box>
        )}
        {checked && (
          <Box mr={isValueEditable ? 1 : 0.5}>
            {isValueEditable ? (
              <DebouncedInput
                // n.b. this forces a remount every time the value prop changes
                // effectively makes this "debounce once" for the life of this component
                // it's more expensive, but resilient to out-of-band changes to value that could
                // create discrepancies with the internal state
                key={value}
                value={String(value)}
                onChange={(value) => onChange({ value: Number(value) })}
                debounceMs={500}
                renderInput={({ value, setInternalValue }) => (
                  <CustomTextField
                    value={value}
                    inputProps={userInteractionInputProps}
                    sx={{
                      width: '50px',
                      '& .MuiInputBase-root': {
                        fontSize: 14,
                        height: '30px',
                      },
                    }}
                    onChange={(ev) => {
                      if (ev.target.value.trim() === '') {
                        setInternalValue(String(0));
                        return;
                      }

                      const eventValue = Number(ev.target.value);

                      if (!isNumber(eventValue) || isNaN(eventValue)) {
                        setInternalValue(value);
                        return;
                      }

                      if (value < CUSTOM_QUESTION_SYSTEM_MIN || value > CUSTOM_QUESTION_SYSTEM_MAX) {
                        setInternalValue(value);
                        return;
                      }

                      setInternalValue(String(eventValue));
                    }}
                  />
                )}
              />
            ) : (
              <Typography fontWeight={500}>
                {prefix} {value}
              </Typography>
            )}
          </Box>
        )}
        <Box>
          <Typography fontSize={'16px'} fontWeight={500}>
            {label}
          </Typography>
        </Box>
      </Box>
      {description && (
        <Box display="flex" alignItems="center" sx={{ marginTop: '-10px' }}>
          <Box sx={{ width: '28px' }}></Box>
          <Box>
            <Typography fontSize={12} fontWeight={400} sx={{ color: '#878787' }}>
              {description}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ReportFeaturesList;
