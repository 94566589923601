import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { ReactComponent as RedAlertIcon } from '../../../../../assets/red-alert-icon.svg';

const useStyles = makeStyles((theme) => ({
  alertMessage: {
    marginLeft: theme.spacing(1.8),
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
    color: '#EE404C',
  },
  alertIcon: { marginRight: theme.spacing(-0.5) },
  alertBox: {
    marginTop: theme.spacing(1.2),
    alignItems: 'center',
    display: 'flex',
  },
}));

const AlertMessage = ({ message = '' }) => {
  const classes = useStyles();

  return (
    <Box className={classes.alertBox}>
      <Typography className={classes.alertIcon}>
        <RedAlertIcon height={'15px'} width={'15px'} />
      </Typography>
      <Typography className={classes.alertMessage}>{message}</Typography>
    </Box>
  );
};

export default AlertMessage;
