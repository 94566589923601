import { Box, CircularProgress, Typography } from '@mui/material';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import React, { useContext } from 'react';
import { generatePath } from 'react-router-dom';
import { ReactComponent as AlertIcon } from '../../../../assets/alert-icon.svg';
import { ReactComponent as AudienceIcon } from '../../../../assets/audience-icon-review.svg';
import { ReactComponent as BuildIcon } from '../../../../assets/build-icon-review.svg';
import { ReactComponent as DetailsIcon } from '../../../../assets/details-icon-review.svg';
import { IntakeSectionNames } from '../../../../modules/intake/constants';
import { snackbar } from '../../../../notifications';
import { Paths } from '../../../../routes';
import { AccordionList } from '../../components/AccordionList';
import { InfoNotice } from '../../components/Notice';
import useSaveWevo from '../../hooks/useSaveWevo';
import { IntakeWevoContext } from '../context/IntakeWevoContext';
import { isMastercardUUID } from '../helpers/tags';
import AudienceSection from './audience/AudienceSection';
import BuildSection from './build/BuildSection';
import DetailsSection from './details/DetailsSection';
import { hasSectionErrors } from './helpers/validation';
import StudyInfoSection from './study-info/StudyInfoSection';

const ReviewIntakeSection = () => {
  const {
    wevo,
    setWevo,
    customQuestions,
    customScreeners,
    assetErrors,
    customQuestionErrors,
    customScreenerErrors,
    wevoErrors,
  } = useContext(IntakeWevoContext);
  const { mutateAsync: saveWevo } = useSaveWevo();

  console.log({
    '[Review Errors]': {
      'Asset Errors': assetErrors,
      'Custom Question Errors': customQuestionErrors,
      'Custom Screener Errors': customScreenerErrors,
      'Wevo Errors': wevoErrors,
    },
  });

  if (isNil(wevo)) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: 'calc(100vh - 64px)', width: '100%', overflowY: 'auto' }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleUpdateWevo = async ({ wevo, updateFields }) => {
    const previousWevo = cloneDeep(wevo);

    try {
      setWevo({ ...wevo, ...updateFields });
      await saveWevo({ id: wevo.id, ...updateFields });
    } catch (err) {
      setWevo(previousWevo);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
    }
  };

  const handleStudyNameChanged = ({ name }) => {
    handleUpdateWevo({ wevo, updateFields: { name: name.trim() } });
  };

  const handleOwnerNameChanged = ({ ownerName }) => {
    handleUpdateWevo({ wevo, updateFields: { ownerName: ownerName.trim() } });
  };

  const handleOwnerEmailChanged = ({ ownerEmail }) => {
    handleUpdateWevo({ wevo, updateFields: { ownerEmail: ownerEmail.trim() } });
  };

  const handleEmailsToNotifyChanged = ({ emailsToNotify }) => {
    handleUpdateWevo({ wevo, updateFields: { emailsToNotify } });
  };

  const handleUUIDChanged = async ({ newUUID }) => {
    // only update the tags if uuid is valid or they clear the field
    const isClearedOutField = isEmpty(newUUID);
    const isValidUUID = isMastercardUUID(newUUID);

    if (!isValidUUID && !isClearedOutField) {
      return;
    }

    const oldUUID = wevo?.tags?.find((tag) => isMastercardUUID(tag.name));
    const oldUUIDIndex = oldUUID ? wevo?.tags?.map((tag) => tag.name).indexOf(oldUUID?.name) : -1;

    let newTags = cloneDeep(wevo?.tags ?? []);

    // handle the clearing out of old uuid
    if (isClearedOutField && oldUUID) {
      newTags.splice(oldUUIDIndex, 1);
      return handleUpdateWevo({ wevo, updateFields: { tags: newTags } });
    }

    // otherwise, handle replacing old uuid with new one
    if (!oldUUID) {
      newTags.push({ name: newUUID });
    } else {
      newTags.splice(oldUUIDIndex, 1);
      newTags = [{ name: newUUID }].concat(newTags);
    }

    return handleUpdateWevo({ wevo, updateFields: { tags: newTags } });
  };

  const disclaimerMessage = (() => {
    const referenceDate = new Date();
    const day = referenceDate.getDay();
    // Any tests submitted on a Friday after 2pm, Saturday or Sunday won't field on the same day, so we should show the banner
    if ([0, 6].includes(day)) {
      return `Studies submitted outside of WEVO's operating hours may not be sent to respondents on the day submitted, as all submitted studies undergo quality assurance review. We appreciate your understanding and cooperation in ensuring high standards of quality and accuracy.`;
    }
    const americaNewYorkLocaleTime = referenceDate.toLocaleTimeString('en-US', {
      timeZone: 'America/New_York',
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    });

    // we rely on lexical sorting to deterime if the browser time cast to America/New_York is past 2pm EST / EDT. It's imperfect if you're on the other side of the world,
    // but in that case you would hit the first condition, which is likely still a valid message
    if (day === 5 && americaNewYorkLocaleTime > '14:00')
      return `Studies submitted after 2pm may not be sent to respondents on the day submitted, as all submitted studies undergo quality assurance review. We appreciate your understanding and cooperation in ensuring high standards of quality and accuracy.`;

    return null;
  })();

  return (
    <Box>
      {disclaimerMessage && (
        <Box my={2} sx={{ border: '1px solid #C7D6DF', borderRadius: 2, padding: 2, background: '' }}>
          <InfoNotice message={disclaimerMessage} />
        </Box>
      )}
      <AccordionList
        items={[
          {
            label: 'Study Info',
            id: '1',
            description: (
              <StudyInfoSection
                wevo={wevo}
                errors={wevoErrors?.wevoErrors?.[String(wevo?.id)] ?? {}}
                onUUIDChanged={handleUUIDChanged}
                onStudyNameChanged={handleStudyNameChanged}
                onOwnerNameChanged={handleOwnerNameChanged}
                onOwnerEmailChanged={handleOwnerEmailChanged}
                onEmailsToNotifyChanged={handleEmailsToNotifyChanged}
              />
            ),
            path: Paths.intake.intakeAudience,
          },
          {
            label: IntakeSectionNames.Audience,
            id: '2',
            description: (
              <AudienceSection
                wevo={wevo}
                customScreeners={customScreeners}
                customScreenerErrors={customScreenerErrors}
                audienceErrors={wevoErrors?.audienceErrors?.[String(wevo?.id)] ?? {}}
              />
            ),
            icon: <AudienceIcon fill="black" />,
            path: Paths.intake.intakeAudience,
          },
          {
            label: IntakeSectionNames.Build,
            id: '3',
            description: <BuildSection wevo={wevo} assetErrors={assetErrors} />,
            icon: <BuildIcon fill="black" />,
            path: Paths.intake.intakeSetup,
          },
          {
            label: IntakeSectionNames.Details,
            id: '4',
            description: (
              <DetailsSection
                wevo={wevo}
                customQuestions={customQuestions}
                customQuestionErrors={customQuestionErrors}
                pageErrors={assetErrors?.pageErrors?.[String(wevo?.pages?.[0]?.id)] || {}}
                wevoErrors={wevoErrors?.wevoErrors?.[String(wevo?.id)] ?? {}}
              />
            ),
            icon: <DetailsIcon fill="black" />,
            path: Paths.intake.intakeGoal,
          },
        ]}
        renderTitle={(item) => (
          <Typography sx={{ fontWeight: 700, fontSize: '12px', color: '#212A37', marginLeft: 1 }}>
            {item.label}
          </Typography>
        )}
        renderItem={(item) => <Box>{item.description}</Box>}
        renderIcon={(item) => item.icon}
        renderError={(item) =>
          hasSectionErrors({
            sectionName: item.label,
            wevo,
            assetErrors,
            customQuestionErrors,
            customScreenerErrors,
            wevoErrors,
          }) ? (
            <AlertIcon height={14} />
          ) : (
            <></>
          )
        }
        generatePath={(item) => generatePath(item.path, { wevoId: wevo?.id })}
      />
    </Box>
  );
};

export default ReviewIntakeSection;
