import { useMutation } from 'react-query';
import axios from '../api';

const createFeasibilityRequest = async ({
  firstName,
  lastName,
  emailAddress,
  file,
  targetN,
  audienceCriteria,
  assetDescription,
  audienceProfiles,
  studyTypes,
  contactConsent,
}) => {
  const formData = new FormData();

  formData.append('firstName', firstName);
  formData.append('lastName', lastName);
  formData.append('emailAddress', emailAddress);
  formData.append('audienceCriteria', audienceCriteria);
  formData.append('contactConsent', contactConsent);

  if (file) {
    formData.append('file', file);
  }

  if (targetN) {
    formData.append('targetN', targetN);
  }

  if (assetDescription) {
    formData.append('assetDescription', assetDescription);
  }

  if (audienceProfiles) {
    formData.append('audienceProfiles', audienceProfiles.join(','));
  }

  if (studyTypes) {
    formData.append('studyTypes', studyTypes.join(','));
  }

  const response = await axios({
    url: `/api/v2/feasibility-requests`,
    method: 'POST',
    data: formData,
  });

  return response.data;
};

export default function useCreateFeasibilityRequest(options) {
  return useMutation(createFeasibilityRequest, options);
}
