import { Box, Typography } from '@mui/material';
import { getReportFeatures } from '../../../helpers/wevo';
import PageBreakIndicator from '../survey-elements/PageBreakIndicator';
import { QuestionNotice } from '../survey-elements/QuestionLogic';
import {
  QuestionText,
  ReadOnlyHorizontalTextField,
  ReadOnlyMatrixSingleChoiceQuestion,
  ReadOnlyVerticalTextField,
} from '../survey-elements/ReadOnlyField';

function ExpectationsHopesFearsPreview({ wevo, showDisabledNotice = false }) {
  const features = getReportFeatures({ wevo });

  if (!features?.includeExpectations) {
    return showDisabledNotice ? (
      <QuestionNotice spacing={0} noticeText="This study does not include expectations." />
    ) : (
      <></>
    );
  }

  return (
    <Box mt={2}>
      <Box mb={8}>
        <QuestionText questionText={`When ${wevo?.visitorObjective || ''}, what are your greatest hopes?`} />
        <Box mb={3} />
        <ReadOnlyHorizontalTextField label="Hope 1" />
        <Box mb={2} />
        <ReadOnlyHorizontalTextField label="Hope 2" />
      </Box>
      <Box>
        <Typography>When {wevo?.visitorObjective || ''}, what are your greatest concerns?</Typography>
        <Box mb={3} />
        <ReadOnlyHorizontalTextField label="Concern 1" />
        <Box mb={2} />
        <ReadOnlyHorizontalTextField label="Concern 2" />
      </Box>
    </Box>
  );
}

function ExpectationsFollowupPreview({ wevo }) {
  return (
    <Box>
      <Box>
        <Typography>Earlier, you stated your top two hopes and concerns.</Typography>
        <Box mb={2} />
        <Typography>How well did the experience you viewed today address those expectations?</Typography>
      </Box>
      <Box mb={3} />
      <Box>
        <ReadOnlyMatrixSingleChoiceQuestion
          columnValues={['Did not address', 'Somewhat addressed', 'Fully addressed']}
          rowValues={['{{ Hope 1 }}', '{{ Hope 2 }}']}
        />
      </Box>
      <Box mb={4} />
      <Box>
        <ReadOnlyMatrixSingleChoiceQuestion
          columnValues={['Did not address', 'Somewhat addressed', 'Fully addressed']}
          rowValues={['{{ Fear 1 }}', '{{ Fear 2 }}']}
        />
      </Box>
      <PageBreakIndicator spacing={3} />
      <HopeFollowUpQuestions choicePlaceholder="Hope 1" />
      <Box mb={3} />
      <HopeFollowUpQuestions choicePlaceholder="Hope 2" />
      <Box mb={3} />
      <FearFollowUpQuestions choicePlaceholder="Fear 1" />
      <Box mb={3} />
      <FearFollowUpQuestions choicePlaceholder="Fear 2" />
    </Box>
  );
}

function HopeFollowUpQuestions({ choicePlaceholder }) {
  return (
    <>
      <Box>
        <QuestionText
          questionText={`In a full sentence, please explain how this experience addressed your hope: {{ ${choicePlaceholder} }}`}
          noticeText={
            <>
              This question is only displayed if the respondent said <b>{choicePlaceholder}</b> was fully
              addressed.
            </>
          }
        />
        <Box mb={3} />
        <ReadOnlyVerticalTextField />
      </Box>
      <Box mb={3} />
      <Box>
        <QuestionText
          questionText={`In a full sentence, please explain why this experience somewhat addressed your hope: {{ ${choicePlaceholder} }}`}
          noticeText={
            <>
              This question is only displayed if the respondent said <b>{choicePlaceholder}</b> was somewhat
              addressed.
            </>
          }
        />
        <Box mb={3} />
        <ReadOnlyVerticalTextField />
      </Box>
      <Box mb={3} />
      <Box>
        <QuestionText
          questionText={`In a full sentence, please explain why this experience did not address your hope: {{ ${choicePlaceholder} }}`}
          noticeText={
            <>
              This question is only displayed if the respondent said the experience did not address &nbsp;
              <b>{choicePlaceholder}</b>.
            </>
          }
        />
        <Box mb={3} />
        <ReadOnlyVerticalTextField />
      </Box>
    </>
  );
}

function FearFollowUpQuestions({ choicePlaceholder }) {
  return (
    <>
      <Box>
        <QuestionText
          questionText={`In a full sentence, please explain how this experience addressed your concern: {{ ${choicePlaceholder} }}`}
          noticeText={
            <>
              This question is only displayed if the respondent said <b>{choicePlaceholder}</b> was fully
              addressed.
            </>
          }
        />
        <Box mb={3} />
        <ReadOnlyVerticalTextField />
      </Box>
      <Box mb={3} />
      <Box>
        <QuestionText
          questionText={`In a full sentence, please explain why this experience somewhat addressed your concern: {{ ${choicePlaceholder} }}`}
          noticeText={
            <>
              This question is only displayed if the respondent said <b>{choicePlaceholder}</b> was somewhat
              addressed.
            </>
          }
        />
        <Box mb={3} />
        <ReadOnlyVerticalTextField />
      </Box>
      <Box mb={3} />
      <Box>
        <QuestionText
          questionText={`In a full sentence, please explain why this experience did not address your concern: {{ ${choicePlaceholder} }}`}
          noticeText={
            <>
              This question is only displayed if the respondent said the experience did not address &nbsp;
              <b>{choicePlaceholder}</b>.
            </>
          }
        />
        <Box mb={3} />
        <ReadOnlyVerticalTextField />
      </Box>
    </>
  );
}

export { ExpectationsFollowupPreview, ExpectationsHopesFearsPreview };
