// NOTE: this component is Mastercard ONLY currently, since they are the only customer with mulitple study types

import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { useContext, useState } from 'react';
import { MetricTypes } from '../../../../modules/intake/constants';
import { snackbar } from '../../../../notifications';
import { CustomTextField, Header, StyledListItemButton } from '../../components';
import { ValidationErrorNotice } from '../../components/Notice';
import DebouncedInput from '../../edit/DebouncedInput';
import useDeleteQuestions from '../../hooks/useDeleteQuestions';
import useSaveWevo from '../../hooks/useSaveWevo';
import { IntakeWevoContext } from '../context/IntakeWevoContext';
import { uuidInfo } from '../customers/mastercard';
import { isMastercardUUID, isValidMastercardUUIDInput } from '../helpers/tags';
import { optimisticMergeUpdates } from '../helpers/wevo';
import DiscardChangesModal from './DiscardChangesModal';

function StartIntakeSection() {
  const {
    wevo,
    setWevo,
    isWevoSyncing,
    setIsWevoSyncing,
    customQuestions,
    setCustomQuestions,
    reloadWevoFromRemote,
    reloadCustomQuestionsFromRemote,
  } = useContext(IntakeWevoContext);

  const { mutateAsync: saveWevoAsync, isLoading: isSavingWevo } = useSaveWevo();
  const { mutateAsync: deleteQuestionsAsync, isLoading: isDeletingCustomQuestion } = useDeleteQuestions();

  const handleUpdateWevo = async ({ wevo, updateFields }) => {
    const previousState = cloneDeep(wevo);

    const newState = optimisticMergeUpdates({ wevo, updateFields });

    try {
      setIsWevoSyncing(true);
      setWevo(newState);
      await saveWevoAsync({ id: wevo.id, ...updateFields });
    } catch (err) {
      setWevo(previousState);
      snackbar.error('Failed to save changes. Please wait a moment and try again or contact us.');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleUUIDChanged = async ({ newUUID }) => {
    // only update the tags if uuid is valid or they clear the field
    const isClearedOutField = isEmpty(newUUID);
    const isValidUUID = isMastercardUUID(newUUID);

    if (!isValidUUID && !isClearedOutField) {
      return;
    }

    const oldUUID = wevo?.tags?.find((tag) => isMastercardUUID(tag.name));
    const oldUUIDIndex = oldUUID ? wevo?.tags?.map((tag) => tag.name).indexOf(oldUUID?.name) : -1;

    let newTags = cloneDeep(wevo?.tags ?? []);

    // handle the clearing out of old uuid
    if (isClearedOutField && oldUUID) {
      newTags.splice(oldUUIDIndex, 1);
      return handleUpdateWevo({ wevo, updateFields: { tags: newTags } });
    }

    // otherwise, handle replacing old uuid with new one
    if (!oldUUID) {
      newTags.push({ name: newUUID });
    } else {
      newTags.splice(oldUUIDIndex, 1);
      newTags = [{ name: newUUID }].concat(newTags);
    }

    return handleUpdateWevo({ wevo, updateFields: { tags: newTags } });
  };

  const handleSwitchMetricType = async ({ newMetricType }) => {
    // todo: make this a backend only thing
    const shouldDeleteCustomQuestions =
      newMetricType === MetricTypes.MastercardCds && !isEmpty(customQuestions);

    const previousState = cloneDeep(wevo);

    const newState = optimisticMergeUpdates({ wevo, updateFields: { metricType: newMetricType } });

    try {
      setIsWevoSyncing(true);
      setWevo(newState);
      await saveWevoAsync({ id: wevo.id, metricType: newMetricType });

      if (!shouldDeleteCustomQuestions) {
        await reloadWevoFromRemote();
        snackbar.success('Successfully changed your study metric.');
      }
    } catch (err) {
      setWevo(previousState);
      snackbar.error('Failed to save changes. Please wait a moment and try again or contact us.');
      setIsWevoSyncing(false);
      return;
    }

    if (shouldDeleteCustomQuestions) {
      const previousState = cloneDeep(customQuestions);
      const optimisticState = [];

      try {
        setCustomQuestions(optimisticState);

        for (const customQuestion of customQuestions) {
          await deleteQuestionsAsync({ id: wevo.id, groupId: customQuestion.groupId });
        }

        await reloadWevoFromRemote();
        await reloadCustomQuestionsFromRemote();
        snackbar.success('Successfully changed your study metric.');
      } catch (err) {
        setCustomQuestions(previousState);
        snackbar.error('Failed to save changes. Please wait a moment and try again or contact us.');
      } finally {
        setIsWevoSyncing(false);
      }
    } else {
      setIsWevoSyncing(false);
    }
  };

  if (!wevo) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: 'calc(100vh - 64px)', width: '100%', overflowY: 'auto' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StudyTypeSetup
      wevo={wevo}
      onMetricTypeChanged={handleSwitchMetricType}
      onUUIDChanged={handleUUIDChanged}
      isLoading={isSavingWevo || isDeletingCustomQuestion || isWevoSyncing}
    />
  );
}

function StudyTypeSetup({ wevo, onMetricTypeChanged, onUUIDChanged, isLoading }) {
  const metricType = wevo.metricType;

  const [discardDialogModalOpen, setDiscardDialogModalOpen] = useState(true);
  const [desiredMetricType, setDesiredMetricType] = useState(null);

  const isMastercardMetric = [MetricTypes.MastercardCds, MetricTypes.MastercardDqs].includes(metricType);
  const shouldDisplayUUIDField = true;

  const uuidTag = wevo?.tags?.find((tag) => isMastercardUUID(tag?.name))?.name || '';

  // hack to provide user feedback because we don't actually change the UUID tag unless it is valid or empty
  const [isValidUUID, setIsValidUUID] = useState(isValidMastercardUUIDInput(uuidTag));

  const handleUUIDChanged = (value) => {
    onUUIDChanged && onUUIDChanged({ newUUID: value });
    setIsValidUUID(isValidMastercardUUIDInput(value));
  };

  const handleMetricTypeSelected = (desiredMetricType) => {
    if (desiredMetricType === metricType) {
      return;
    }

    setDesiredMetricType(desiredMetricType);
    setDiscardDialogModalOpen(true);
  };

  const resetModalState = () => {
    setDesiredMetricType(null);
    setDiscardDialogModalOpen(false);
  };

  const handleConfirm = () => {
    resetModalState();
    onMetricTypeChanged({ newMetricType: desiredMetricType });
  };

  const handleCancel = () => {
    resetModalState();
  };

  return (
    <Box>
      <Header name="Study Type" description="Which study type do you want to run." isRequired={true} />
      <Box mb={3} />
      <Typography fontWeight={700} fontSize={12}>
        Exploratory Research
      </Typography>
      <Box mb={2} />
      <StyledListItemButton
        isDisabled={isLoading}
        isSelected={metricType === MetricTypes.Standard}
        onClick={() => handleMetricTypeSelected(MetricTypes.Standard)}
        primaryListItemText={<Typography fontWeight={600}>Standard WEVO</Typography>}
        secondaryListItemText={
          <Box>
            <Typography variant="caption">
              Test how your audience reacts to the overall experience of your product or service.
            </Typography>
            <Box component="p" mt={1} />
            <Typography variant="caption">For exploratory purposes.</Typography>
          </Box>
        }
        secondaryTypographyProps={{ component: 'div' }}
      />
      <Box mb={4} />
      <Typography fontWeight={700} fontSize={12}>
        Mastercard CX Metrics
      </Typography>
      <Box mb={2} />
      <StyledListItemButton
        isDisabled={isLoading}
        isSelected={metricType === MetricTypes.MastercardCds}
        onClick={() => handleMetricTypeSelected(MetricTypes.MastercardCds)}
        primaryListItemText={
          <Box display="flex" justifyContent="space-between" alignItems="baseline">
            <Typography fontWeight={600}>Concept Desirability Score</Typography>
            <Link
              style={{ textDecoration: 'none' }}
              onClick={(ev) => {
                ev.stopPropagation();
              }}
              href="https://mastercard.sharepoint.com/sites/ConceptDesirabilityScore"
              target="_blank"
              rel="noreferrer">
              Learn More
            </Link>
          </Box>
        }
        secondaryListItemText={
          <Box>
            <Typography variant="caption">
              Test how your audience reacts to the concept of your product/service.
            </Typography>
            <Box mt={1} />
            <Typography variant="caption">
              <Box component="span" fontWeight={600}>
                Please note:
              </Box>
              &nbsp;in order to run a CDS, you should have developed and tested concepts, performed a concept
              deep dive and are preparing for Product Council review prior to Studio's Prototype phase.
            </Typography>
          </Box>
        }
        secondaryTypographyProps={{ component: 'div' }}
      />
      <Box mb={2} />
      <StyledListItemButton
        isDisabled={isLoading}
        isSelected={metricType === MetricTypes.MastercardDqs}
        onClick={() => handleMetricTypeSelected(MetricTypes.MastercardDqs)}
        primaryListItemText={
          <Box display="flex" justifyContent="space-between" alignItems="baseline">
            <Typography fontWeight={600}>Design Quality Score</Typography>
            <Link
              style={{ textDecoration: 'none' }}
              onClick={(ev) => {
                ev.stopPropagation();
              }}
              href="https://mastercard.sharepoint.com/sites/DesignQualityScoreDQS9"
              target="_blank"
              rel="noreferrer">
              Learn More
            </Link>
          </Box>
        }
        secondaryListItemText={
          <Box mt={2}>
            <Typography variant="caption">
              Test how your audience begins to interact with the design of your product or service.
            </Typography>
            <Box mt={1} />
            <Typography variant="caption">
              <Box component="span" fontWeight={600}>
                Please note:
              </Box>
              &nbsp;in order to run a DQS, you should have created and tested your experience prototype,
              define, or are in the process of defining, your MVP and are preparing for Product Council prior
              to the Studio's Market Test phase.
            </Typography>
          </Box>
        }
        secondaryTypographyProps={{ component: 'div' }}
      />
      <Box mb={3} />
      {shouldDisplayUUIDField && (
        <>
          <Header
            name="UUID"
            description="If you are launching a CDS or DQS study, please provide your product's UUID."
            isRequired={isMastercardMetric}
            tooltipProps={{
              title: uuidInfo(),
              arrow: true,
              placement: 'right',
            }}
          />
          <Box mb={2} />
          <DebouncedInput
            value={uuidTag}
            onChange={handleUUIDChanged}
            debounceMs={500}
            renderInput={({ value, onChange }) => (
              <CustomTextField
                value={value}
                sx={{
                  background: 'white',
                  borderRadius: 4,
                  '& .MuiInputBase-root': {
                    fontSize: 13,
                  },
                }}
                onChange={onChange}
              />
            )}
          />
          {!isValidUUID && (
            <Box my={1}>
              <ValidationErrorNotice message="Please enter a valid uuid." />
            </Box>
          )}
        </>
      )}
      <DiscardChangesModal
        open={discardDialogModalOpen && !isNil(desiredMetricType)}
        oldMetricType={metricType}
        newMetricType={desiredMetricType}
        onCancel={handleCancel}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        isLoading={isLoading}
      />
    </Box>
  );
}

export default StartIntakeSection;
