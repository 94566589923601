import { Box } from '@mui/material';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { HIDDEN_SEGMENT_IDS_BY_ATTRIBUTE_ID } from '../../../../../../modules/wevos/constants';
import { CustomFormControlCheckbox } from '../../../../components/CustomFormControlLabel';
import { AttributeIdToDisplayName, SegmentDisplayType, SliderMarks } from '../../constants';
import RangeSlider from './RangeSlider';

const CheckboxSegments = ({ segments, onChange }) => {
  return (
    <Box display="flex" flexDirection="column">
      {segments?.map((segment) => (
        <CustomFormControlCheckbox
          key={segment?.id}
          value={segment?.id}
          label={segment?.label}
          checked={segment?.isSelected}
          onChange={(ev) => onChange({ segment, isSelected: ev.target.checked })}
        />
      ))}
    </Box>
  );
};

const Attribute = ({ attributeId, segments, displayType, onCheckboxChange, onRangeChange }) => {
  const label = AttributeIdToDisplayName[attributeId];

  // const marks = SliderMarks[attributeId];

  const segmentsToDisplay = useMemo(() => {
    const hiddenSegments = HIDDEN_SEGMENT_IDS_BY_ATTRIBUTE_ID[attributeId];

    return !isNil(hiddenSegments)
      ? segments.filter((segment) => !hiddenSegments?.includes(segment?.id))
      : segments;
  }, [attributeId, segments]);

  if (displayType === SegmentDisplayType.Slider) {
    return (
      <RangeSlider
        label={label}
        segments={segmentsToDisplay}
        marks={SliderMarks[attributeId]}
        onChange={onRangeChange}
      />
    );
  }

  return <CheckboxSegments segments={segmentsToDisplay} onChange={onCheckboxChange} />;
};

export default Attribute;
