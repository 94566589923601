import { Box, CircularProgress } from '@mui/material';
import { cloneDeep, isEmpty, isEqual, isNil } from 'lodash';
import { useContext, useState } from 'react';
import { MetricTypes } from '../../../../modules/intake/constants';
import { WevoTestType } from '../../../../modules/wevos/constants';
import { snackbar } from '../../../../notifications';
import { Header } from '../../components';
import useSaveWevo from '../../hooks/useSaveWevo';
import useUpdateCustomQuestion from '../../hooks/useUpdateCustomQuestion';
import { IntakeWevoContext } from '../context/IntakeWevoContext';
import { isInvalidScope } from '../custom-questions/helpers';
import {
  calculateCustomQuestionLimit,
  getReportFeatures,
  isCustomSurveyType,
  isReportFeatureFlag,
  optimisticMergeUpdates,
} from '../helpers/wevo';
import { NumRespondentsSlider } from './NumRespondentsSlider';
import ReportFeaturesList from './ReportFeaturesList';
import { getSliderMarks } from './components/CustomSliderMark';
import { ConvertToCustomSurveyModal, ConvertToWevoSurveyModal } from './components/CustomSurveyModals';

function StudySetupIntakeSection() {
  const { wevo, setWevo, customQuestions, reloadCustomQuestionsFromRemote, setIsWevoSyncing, proPricing } =
    useContext(IntakeWevoContext);
  const isCustomSurvey = isCustomSurveyType(wevo);
  const metricType = wevo.metricType;
  const isMastercardMetric = [MetricTypes.MastercardCds, MetricTypes.MastercardDqs].includes(metricType);

  const { mutateAsync: saveWevoAsync } = useSaveWevo();

  // n.b. this is used to 'repair' custom questions that are scopeless after conversion to custom survey
  const { mutateAsync: updateCustomQuestionAsync } = useUpdateCustomQuestion();

  const handleNumRespondentsChanged = async (newNumRespondents) => {
    const additionalUpdates = {};

    if (newNumRespondents < 50) {
      additionalUpdates.includeDiagnostics = false;
    }

    if (newNumRespondents < 60) {
      additionalUpdates.includeExpectations = false;
      additionalUpdates.includeQualitativeExpectations = false;
      additionalUpdates.visitorObjective = '';
    }

    if (wevo.numTargetedRespondentsPerPage <= 50 && newNumRespondents > 50) {
      const oldExpectationsValue = isNil(additionalUpdates.includeExpectations)
        ? wevo?.details?.includeExpectations || false
        : additionalUpdates.includeExpectations;

      additionalUpdates.includeExpectations = isCustomSurvey ? false : oldExpectationsValue;
      additionalUpdates.includeQualitativeExpectations = false;
      additionalUpdates.includeDiagnostics = isCustomSurvey ? false : true;
    }

    const updatedFeatureFlags = Object.entries(additionalUpdates).reduce((acc, [key, val]) => {
      if (isReportFeatureFlag(key)) {
        acc[key] = val;
      }
      return acc;
    }, {});

    if (!isEmpty(updatedFeatureFlags)) {
      additionalUpdates.customQuestionLimit = calculateCustomQuestionLimit({
        reportFeatures: {
          ...getReportFeatures({ wevo }),
          ...updatedFeatureFlags,
        },
      });
    }

    return handleUpdateWevo({
      wevo,
      updateFields: { numRespondents: newNumRespondents, ...additionalUpdates },
    });
  };

  const handleReportFeaturesChanged = async (features) => {
    const updateFields = { ...features };

    updateFields.customQuestionLimit = calculateCustomQuestionLimit({
      reportFeatures: {
        ...getReportFeatures({ wevo }),
        ...features,
      },
    });

    // if we're changing from a custom survey back to a standard wevo survey
    // and it has multiple pages, it was probably a compare before, so set it back
    if (updateFields?.isCustomSurvey === false && wevo?.pages?.length > 1) {
      updateFields.testType = WevoTestType.Compare;
    }

    // if expectations are excluded, then we should also exclude qualitative expectations
    // and clear the visitor objective
    if (updateFields?.includeExpectations === false) {
      updateFields.includeQualitativeExpectations = false;
      updateFields.visitorObjective = '';
    }

    await handleUpdateWevo({ wevo, updateFields });

    const isCustomSurvey = isCustomSurveyType(wevo);

    // safety precaution to ensure that custom surveys always have a valid scope
    if (isCustomSurvey) {
      let customQuestionsChanged = false;

      for (const customQuestion of customQuestions) {
        if (customQuestion?.scopes?.filter((scope) => !isInvalidScope(scope))?.length < 1) {
          await updateCustomQuestionAsync({
            id: wevo.id,
            groupId: customQuestion.groupId,
            scopes: [{ wevoPageId: String(wevo?.pages?.[0]?.id), stepId: null }],
          });

          customQuestionsChanged = true;
        }
      }

      if (customQuestionsChanged) {
        await reloadCustomQuestionsFromRemote();
      }
    }
  };

  const handleTestTypeChanged = async (newTestType) => {
    return handleUpdateWevo({ wevo, updateFields: { testType: newTestType } });
  };

  const handleUpdateWevo = async ({ wevo, updateFields }) => {
    const previousState = cloneDeep(wevo);

    const newState = optimisticMergeUpdates({ wevo, updateFields });

    try {
      setIsWevoSyncing(true);
      setWevo(newState);
      await saveWevoAsync({ id: wevo.id, ...updateFields });
    } catch (err) {
      setWevo(previousState);
      snackbar.error('Failed to save changes. Please wait a moment and try again or contact us.');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  if (!wevo || !proPricing) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: 'calc(100vh - 64px)', width: '100%', overflowY: 'auto' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StudySetup
      wevo={wevo}
      isCompareEnabled={!isMastercardMetric && !isCustomSurveyType(wevo)}
      sampleSizes={proPricing?.sampleSizes ?? []}
      onNumRespondentsChanged={handleNumRespondentsChanged}
      onReportFeaturesChanged={handleReportFeaturesChanged}
      onTestTypeChanged={handleTestTypeChanged}
    />
  );
}

function StudySetup({
  wevo,
  isCompareEnabled = true,
  sampleSizes,
  onNumRespondentsChanged,
  onReportFeaturesChanged,
  onTestTypeChanged,
}) {
  const numRespondents = wevo?.numTargetedRespondentsPerPage;
  const isMastercardTestType = [MetricTypes.MastercardDqs, MetricTypes.MastercardCds].includes(
    wevo?.metricType
  );

  // custom survey <=> wevo survey conversion states
  const [showConvertCustomSurveyModal, setShowConvertCustomSurveyModal] = useState(false);
  const [showConvertWevoSurveyModal, setShowConvertWevoSurveyModal] = useState(false);
  const [pendingNewFeatures, setPendingNewFeatures] = useState(null);

  const features = getReportFeatures({ wevo });

  const testTypeValue = wevo?.testType === WevoTestType.Compare;

  const handleNumRespondentsChanged = (newNumRespondents) => {
    if (numRespondents !== newNumRespondents) {
      if (newNumRespondents < 75) {
        const featureFlags = Object.entries(features).reduce((acc, [flag, value]) => {
          if (['includeDiagnostics', 'includeExpectations', 'includeSentimentMaps'].includes(flag)) {
            acc[flag] = value;
          }
          return acc;
        }, {});

        const newFeaturesActiveCount = Object.values(featureFlags)?.filter((feature) => feature).length;

        // studies below the "default" N no longer support expectations. This can be 60, 75, or 120 depending on the customer
        // if we know that the new feature list will have no wevo features, convert to custom survey
        const noLongerSupportsExpectations =
          newFeaturesActiveCount === 1 && newNumRespondents < 60 && featureFlags?.includeExpectations === true;

        // studies below 50 N no longer support diagnostics or expectations, so if diagnostics is included
        // and we know that we are going to an N that would result in no features, show the convert survey modal
        const noLongerSupportsDiagnostics =
          newNumRespondents < 50 &&
          featureFlags?.includeSentimentMaps === false &&
          newFeaturesActiveCount >= 1;

        if (noLongerSupportsExpectations || noLongerSupportsDiagnostics) {
          const newFeatures = { ...features };

          if (newNumRespondents < 60) {
            newFeatures.includeExpectations = false;
          }

          if (newNumRespondents < 50) {
            newFeatures.includeDiagnostics = false;
          }

          setPendingNewFeatures(newFeatures);
          setShowConvertCustomSurveyModal(true);
        }
      }
      // we always change N even if they don't convert to a custom survey to prevent debounce loops
      onNumRespondentsChanged(newNumRespondents);
    }
  };

  const handleTestTypeChanged = (isCompare) => {
    onTestTypeChanged(isCompare ? WevoTestType.Compare : WevoTestType.Page);
  };

  const handleFeaturesChanged = (newFeatures) => {
    const conversionType = getConversionType({ features, newFeatures });

    if (conversionType === 'customSurvey') {
      setPendingNewFeatures(newFeatures);
      setShowConvertCustomSurveyModal(true);
      return;
    }

    if (conversionType === 'wevoSurvey') {
      setPendingNewFeatures(newFeatures);
      setShowConvertWevoSurveyModal(true);
      return;
    }

    if (!isEqual(features, newFeatures)) {
      onReportFeaturesChanged(newFeatures);
    }
  };

  const getConversionType = ({ features, newFeatures }) => {
    const featureFlags = Object.entries(newFeatures).reduce((acc, [flag, value]) => {
      if (['includeDiagnostics', 'includeExpectations', 'includeSentimentMaps'].includes(flag)) {
        acc[flag] = value;
      }
      return acc;
    }, {});

    // if custom survey and user is requesting additional features beyond custom questions,
    // it is no longer a custom survey, so we should request a change
    const isCustomSurvey = wevo?.details?.isCustomSurvey === true;
    const newFeaturesActiveCount = Object.values(featureFlags)?.filter((feature) => feature).length;

    // converting to a wevo survey
    if (isCustomSurvey && newFeaturesActiveCount > 0) {
      return 'wevoSurvey';
    }

    // converting to a custom survey
    if (!isCustomSurvey && newFeaturesActiveCount === 0) {
      return 'customSurvey';
    }

    return null;
  };

  const handleCloseConvertCustomSurveyModal = () => {
    setPendingNewFeatures(null);
    setShowConvertCustomSurveyModal(false);
  };

  const handleCancelConverCustomSurveyModal = () => {
    handleCloseConvertCustomSurveyModal();

    // handle edge case of changing N with no features
    const isCustomSurvey = wevo?.details?.isCustomSurvey === true;

    const featureFlags = Object.entries(features).reduce((acc, [flag, value]) => {
      if (['includeDiagnostics', 'includeExpectations', 'includeSentimentMaps'].includes(flag)) {
        acc[flag] = value;
      }
      return acc;
    }, {});

    const newFeaturesActiveCount = Object.values(featureFlags)?.filter((feature) => feature).length;

    if (!isCustomSurvey && newFeaturesActiveCount === 0) {
      onReportFeaturesChanged({ ...features, includeSentimentMaps: true });
    }
  };

  const handleConfirmConvertToCustomSurvey = () => {
    onReportFeaturesChanged({ ...pendingNewFeatures, isCustomSurvey: true });
    handleCloseConvertCustomSurveyModal();
  };

  const handleCloseConvertWevoSurveyModal = () => {
    setPendingNewFeatures(null);
    setShowConvertWevoSurveyModal(false);
  };

  const handleConfirmConvertToWevoSurvey = () => {
    onReportFeaturesChanged({ ...pendingNewFeatures, isCustomSurvey: false });
    handleCloseConvertWevoSurveyModal();
  };

  const sampleSizeComponents = getSliderMarks(sampleSizes);

  return (
    <Box>
      <Header name="Sample size" description="How many respondents do you need?" isRequired={true} />
      <Box mb={6} mt={10}>
        <NumRespondentsSlider
          value={numRespondents}
          marks={sampleSizeComponents}
          min={sampleSizeComponents?.[0]?.value}
          max={sampleSizeComponents?.[sampleSizeComponents.length - 1]?.value}
          onChange={handleNumRespondentsChanged}
          disabled={isMastercardTestType}
        />
      </Box>
      <Box my={8}>
        <ReportFeaturesList
          numRespondents={numRespondents}
          features={features}
          onChange={handleFeaturesChanged}
          metricType={wevo?.metricType}
        />
      </Box>
      {isCompareEnabled && (
        <Header
          name={'Compare'}
          description={'Do you want to compare this experience with another one?'}
          tooltipProps={{
            title:
              'Gather insights on how your experience performs compared to an alternative design or a competitor experience.',
            arrow: true,
            placement: 'right',
          }}
          isRequired={true}
          toggleSwitchProps={{
            onChange: (ev) => handleTestTypeChanged(ev.target.checked),
            checked: testTypeValue,
          }}
        />
      )}
      {showConvertCustomSurveyModal && (
        <ConvertToCustomSurveyModal
          open={showConvertCustomSurveyModal}
          onConfirm={handleConfirmConvertToCustomSurvey}
          onClose={handleCloseConvertCustomSurveyModal}
          onCancel={handleCancelConverCustomSurveyModal}
        />
      )}
      {showConvertWevoSurveyModal && (
        <ConvertToWevoSurveyModal
          open={showConvertWevoSurveyModal}
          onConfirm={handleConfirmConvertToWevoSurvey}
          onClose={handleCloseConvertWevoSurveyModal}
          onCancel={handleCloseConvertWevoSurveyModal}
        />
      )}
    </Box>
  );
}

export default StudySetupIntakeSection;
