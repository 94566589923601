import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import InfoIcon from '@mui/icons-material/Info';
import { Box, CircularProgress, Grid, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { CustomScreenerTypes, OutcomeOptions, ScreenerSelectOptions } from '../../../modules/intake/constants';
import { snackbar } from '../../../notifications';
import useUpdateCustomScreener from '../hooks/useUpdateCustomScreener';
import CustomScreenerOptions from './CustomScreenerOptions';

const styles = {
  radioIcon: {
    marginRight: 1,
    fontSize: 'large',
    color: grey[600],
  },
  labelErrors: {
    fontSize: '14px',
    marginLeft: '12px',
  },
  infoIcon: {
    marginLeft: (theme) => theme.spacing(1),
    verticalAlign: 'bottom',
    color: grey[600],
    fontSize: '20px',
  },
};

const CustomScreenerType = ({ wevoId, question, onQuestionChange }) => {
  const [optionsAndOutcomes, setOptionsAndOutcomes] = useState(question?.options || []);
  const [isLoadingNewOptions, setIsLoadingNewOptions] = useState(false);

  const { mutate: updateCustomScreener } = useUpdateCustomScreener();

  const questionId = question?.id;
  const questionType = question?.questionType ?? '';
  const labelsType = question?.details?.labelsType || ScreenerSelectOptions.Custom;

  /* Handles question type selection */
  const handleQuestionTypeClick = (selectedQuestionType) => {
    if (selectedQuestionType !== questionType) {
      updateQuestionAndLabelType({ selectedQuestionType });
    }
  };

  /* Handles label type selection for non-MC and non-MS question types */
  const handleLabelTypeOptionClick = (selectedLabelType) => {
    if (selectedLabelType !== labelsType) {
      updateQuestionAndLabelType({ selectedLabelType });
    }
  };

  /* Update QuestionType */
  const updateQuestionAndLabelType = ({ selectedQuestionType, selectedLabelType }) => {
    let updatedFields = {};
    let details = {};
    if (selectedQuestionType) {
      updatedFields.questionType = selectedQuestionType;

      // when changing from multiselect to another question type, if there are 'may select' outcomes, change them to 'accept'
      if (questionType === CustomScreenerTypes.MultiSelect) {
        const updatedOptions = optionsAndOutcomes?.map((option) => {
          if (option?.outcome === OutcomeOptions.MaySelect) {
            return { ...option, outcome: OutcomeOptions.Accept };
          }
          return option;
        });
        updatedFields.options = updatedOptions;
      }
      if (
        ![CustomScreenerTypes.MultipleChoice, CustomScreenerTypes.MultiSelect].includes(selectedQuestionType)
      ) {
        details.labelsType = ScreenerSelectOptions.Custom;
        updatedFields.details = details;
      }
      setIsLoadingNewOptions(true);
    }

    if (selectedLabelType) {
      details.labelsType = selectedLabelType;
      updatedFields.details = details;
      updatedFields.questionType = questionType;
      if (selectedLabelType !== ScreenerSelectOptions.Custom) {
        setIsLoadingNewOptions(true);
      }
    }

    updateCustomScreener(
      {
        wevoId,
        filterId: questionId,
        changes: updatedFields,
      },
      {
        onSuccess: (filterData) => {
          setOptionsAndOutcomes(filterData?.options);
          setIsLoadingNewOptions(false);
        },
        onError: (err) => {
          setIsLoadingNewOptions(false);
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating custom screener');
        },
      }
    );
  };

  return (
    <Grid container spacing={2} justifyContent="space-evenly" wrap="wrap">
      <Grid item sx={{ width: '50%' }}>
        <TextField
          fullWidth
          select
          variant="outlined"
          id="QuestionType"
          label="Question Type"
          value={questionType}
          InputLabelProps={{ shrink: true }}
          SelectProps={{ displayEmpty: true }}>
          <MenuItem
            value={CustomScreenerTypes.FiveLikertScale}
            onClick={() => handleQuestionTypeClick(CustomScreenerTypes.FiveLikertScale)}>
            5 Likert Scale
          </MenuItem>
          <MenuItem
            value={CustomScreenerTypes.SevenLikertScale}
            onClick={() => handleQuestionTypeClick(CustomScreenerTypes.SevenLikertScale)}>
            7 Likert Scale
          </MenuItem>
          <MenuItem
            value={CustomScreenerTypes.MultipleChoice}
            onClick={() => handleQuestionTypeClick(CustomScreenerTypes.MultipleChoice)}>
            Multiple Choice (up to 10 choices)
          </MenuItem>
          <MenuItem
            value={CustomScreenerTypes.MultiSelect}
            onClick={() => handleQuestionTypeClick(CustomScreenerTypes.MultiSelect)}>
            Multiple Selection (up to 15 choices)
          </MenuItem>
          <MenuItem
            value={CustomScreenerTypes.YesNoTrueFalse}
            onClick={() => handleQuestionTypeClick(CustomScreenerTypes.YesNoTrueFalse)}>
            Yes/No or True/False
          </MenuItem>
        </TextField>
      </Grid>
      <Grid item sx={{ width: '50%' }}>
        {/* Drop down menu for 5 Likert or 7 Likert Type */}
        {(questionType === CustomScreenerTypes.FiveLikertScale ||
          questionType === CustomScreenerTypes.SevenLikertScale) && (
          <TextField
            fullWidth
            select
            variant="outlined"
            id="5LikertTypeOptions"
            label="Scale"
            value={labelsType}
            disabled={isLoadingNewOptions}
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}>
            <MenuItem
              value={ScreenerSelectOptions.AgreeDisagree}
              onClick={() => handleLabelTypeOptionClick(ScreenerSelectOptions.AgreeDisagree)}>
              <Grid container direction="row" alignItems="center">
                Agree <ArrowRightAltIcon sx={{ height: '23px' }} /> Disagree
              </Grid>
            </MenuItem>
            <MenuItem
              value={ScreenerSelectOptions.LikeDislike}
              onClick={() => handleLabelTypeOptionClick(ScreenerSelectOptions.LikeDislike)}>
              <Grid container direction="row" alignItems="center">
                Like <ArrowRightAltIcon sx={{ height: '23px' }} /> Dislike
              </Grid>
            </MenuItem>
            <MenuItem
              value={ScreenerSelectOptions.SatisfiedDissatisfied}
              onClick={() => handleLabelTypeOptionClick(ScreenerSelectOptions.SatisfiedDissatisfied)}>
              <Grid container direction="row" alignItems="center">
                Satisfied <ArrowRightAltIcon sx={{ height: '23px' }} /> Dissatisfied
              </Grid>
            </MenuItem>
            <MenuItem
              value={ScreenerSelectOptions.LikelyUnlikely}
              onClick={() => handleLabelTypeOptionClick(ScreenerSelectOptions.LikelyUnlikely)}>
              <Grid container direction="row" alignItems="center">
                Likely <ArrowRightAltIcon sx={{ height: '23px' }} /> Unlikely
              </Grid>
            </MenuItem>
            <MenuItem
              value={ScreenerSelectOptions.EasyDifficult}
              onClick={() => handleLabelTypeOptionClick(ScreenerSelectOptions.EasyDifficult)}>
              <Grid container direction="row" alignItems="center">
                Easy <ArrowRightAltIcon sx={{ height: '23px' }} /> Difficult
              </Grid>
            </MenuItem>
            <MenuItem
              value={ScreenerSelectOptions.Custom}
              onClick={() => handleLabelTypeOptionClick(ScreenerSelectOptions.Custom)}>
              <Grid container direction="row" alignItems="center">
                Custom
              </Grid>
            </MenuItem>
          </TextField>
        )}

        {/* Drop down menu for Yes/No */}
        {questionType === CustomScreenerTypes.YesNoTrueFalse && (
          <TextField
            fullWidth
            select
            variant="outlined"
            id="YesNoTrueFalseOptions"
            label="Scale"
            value={labelsType}
            disabled={isLoadingNewOptions}
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}>
            <MenuItem
              value={ScreenerSelectOptions.YesNo}
              onClick={() => handleLabelTypeOptionClick(ScreenerSelectOptions.YesNo)}>
              Yes / No
            </MenuItem>
            <MenuItem
              value={ScreenerSelectOptions.TrueFalse}
              onClick={() => handleLabelTypeOptionClick(ScreenerSelectOptions.TrueFalse)}>
              True / False
            </MenuItem>
            <MenuItem
              value={ScreenerSelectOptions.Custom}
              onClick={() => handleLabelTypeOptionClick(ScreenerSelectOptions.Custom)}>
              Custom
            </MenuItem>
          </TextField>
        )}
      </Grid>

      {/* Question Options */}
      <Grid item xs={12}>
        {isLoadingNewOptions ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={24} />
          </Box>
        ) : (
          <>
            {questionType === CustomScreenerTypes.MultiSelect && (
              <Typography variant="h5">
                Enter your options
                <Tooltip
                  title={`Options will be randomized in order to minimize survey biases`}
                  placement="right">
                  <InfoIcon sx={styles.infoIcon} />
                </Tooltip>
              </Typography>
            )}
            <CustomScreenerOptions
              wevoId={wevoId}
              questionId={questionId}
              optionsAndOutcomes={optionsAndOutcomes}
              setOptionsAndOutcomes={setOptionsAndOutcomes}
              questionType={questionType}
              labelsType={labelsType}
              onQuestionChange={onQuestionChange}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CustomScreenerType;
