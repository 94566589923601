import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useQueryClient } from 'react-query';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { MainContainerDimensions, PulseTips, TaskStatus } from '../../modules/automated-insights/constants';
import { snackbar } from '../../notifications';
import { Paths } from '../../routes';
import { TrackEvent, useTrackPageLoad } from '../analytics';
import CustomButton from '../automated-insights/ui/Button';
import { ButtonWithInfoBox } from './InfoBox';
import TaskStatusBar from './TaskStatusBar';
import TipBox from './TipBox';
import useGenerateInsights from './hooks/useGenerateInsights';
import { useCreateSessionFromURLs, useFetchImportURL, useFetchImportUrlTask } from './hooks/useImportUrl';

function ImportURLConfirmationActions({ onAccept, onReject, isLoading, isExpected, ...rest }) {
  return (
    <Box {...rest}>
      <Typography fontWeight={600} fontSize={16}>
        {isExpected
          ? 'Is this page correct?'
          : 'Oops! Looks like some elements on this page might be blocked. Is this the screenshot you were hoping for? If not, please upload a full-page screenshot instead to run a Pulse.'}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', spacing: 2, my: 4 }}>
        <CustomButton variant="secondaryDark" size="small" onClick={onReject} disabled={isLoading}>
          No
        </CustomButton>
        <CustomButton
          variant="gradient"
          size="small"
          sx={{ marginLeft: 1 }}
          onClick={onAccept}
          disabled={isLoading}>
          Yes
        </CustomButton>
      </Box>
    </Box>
  );
}

export function ImportURLLoadingInfo({ taskStatus, ...rest }) {
  const STATUS_TO_PERCENTAGE = {
    [TaskStatus.Pending]: 0,
    [TaskStatus.Enqueued]: 20,
    [TaskStatus.Running]: 65,
    [TaskStatus.Completed]: 100,
    [TaskStatus.Failed]: 0,
  };

  const STATUS_TO_MESSAGE = {
    [TaskStatus.Pending]: 'Your request to generate an asset is pending.',
    [TaskStatus.Enqueued]: 'Getting ready to generate an asset from your web page.',
    [TaskStatus.Running]: 'Generating an asset from your web page. This may take a few moments.',
    [TaskStatus.Completed]: 'Finished generating an asset from your web page.',
    [TaskStatus.Failed]: 'An error occurred trying to generate an asset from your web page.',
  };

  return (
    <Box {...rest}>
      <TaskStatusBar
        message={STATUS_TO_MESSAGE?.[taskStatus] ?? ''}
        percentage={STATUS_TO_PERCENTAGE?.[taskStatus] ?? 0}
        isCompleted={taskStatus === TaskStatus.Completed}
      />
    </Box>
  );
}

export function ImportURLErrorInfo({ onReject, message, ...rest }) {
  return (
    <Box {...rest}>
      <Typography variant="body1">{message}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', spacing: 2, my: 4 }}>
        <CustomButton variant="secondaryDark" size="small" onClick={onReject}>
          Try Again
        </CustomButton>
      </Box>
    </Box>
  );
}

function ImportURLConfirmBox({
  taskStatus,
  onAccept,
  onReject,
  isLoading,
  isExpected,
  tipsStartOpen,
  message,
}) {
  const includeScreenshotTips = taskStatus === TaskStatus.Completed || tipsStartOpen;
  const randomPulseTips = useMemo(() => {
    return _.shuffle(PulseTips);
  }, []);

  const [currentTipIndex, setCurrentTipIndex] = useState(0);

  // switch tips after 10 seconds
  useEffect(() => {
    let intervalId;
    intervalId = setInterval(() => {
      setCurrentTipIndex((prevIndex) => (prevIndex + 1) % randomPulseTips.length);
    }, 10000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [randomPulseTips]);

  return (
    <Paper
      elevation={0}
      sx={{
        height: MainContainerDimensions.Height,
        minHeight: MainContainerDimensions.MinHeight,
        borderRadius: '20px',
      }}>
      <Box
        sx={{
          display: taskStatus ? 'block' : 'none',
          height: '100%',
          width: '100%',
        }}>
        <Box
          sx={{
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}>
          <Box
            sx={{
              maxWidth: '85%',
              textAlign: 'center',
              mb: 6,
            }}>
            <ImportURLConfirmationActions
              onAccept={onAccept}
              onReject={onReject}
              isLoading={isLoading}
              isExpected={isExpected}
              sx={{ display: taskStatus === TaskStatus.Completed ? 'initial' : 'none' }}
            />
            <ImportURLLoadingInfo
              taskStatus={taskStatus}
              sx={{
                display: ![TaskStatus.Completed, TaskStatus.Failed].includes(taskStatus) ? 'initial' : 'none',
              }}
            />
            <ImportURLErrorInfo
              sx={{ display: taskStatus === TaskStatus.Failed ? 'initial' : 'none' }}
              onReject={onReject}
              message={message}
            />
          </Box>
          <ButtonWithInfoBox
            includeTips={includeScreenshotTips}
            buttonContainerStyles={{
              position: 'absolute',
              bottom: (theme) => theme.spacing(2),
              left: (theme) => ({ xs: theme.spacing(1), md: theme.spacing(2) }),
              display: [TaskStatus.Completed, TaskStatus.Failed].includes(taskStatus) ? 'initial' : 'none',
            }}
            infoBoxContainerStyles={{
              width: '100%',
              height: includeScreenshotTips ? '40%' : 'fit-content',
              position: 'absolute',
              bottom: 0,
            }}
            tipsStartOpen={tipsStartOpen || !isExpected}
          />
          <TipBox
            sx={{
              width: { xs: '92%', md: '80%' },
              height: '25%',
              minHeight: '68px',
              position: 'absolute',
              bottom: (theme) => theme.spacing(2),
              display: ![TaskStatus.Completed, TaskStatus.Failed].includes(taskStatus) ? 'initial' : 'none',
            }}
            text={randomPulseTips[currentTipIndex]}
          />
        </Box>
      </Box>
    </Paper>
  );
}

export default function ImportURLConfirmation() {
  const { importUrlId } = useParams();
  const { track } = useAnalytics();
  const history = useHistory();
  const queryClient = useQueryClient();

  const location = useLocation();
  const deviceId = location?.state?.deviceId;
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isExpected, setIsExpected] = useState(true);
  const [tipsStartOpen, setTipsStartOpen] = useState(false);

  const handleOnClick = () => {
    document.body.style.overflow = isOpen ? '' : 'hidden';
    setIsOpen((isOpen) => !isOpen);
  };

  useTrackPageLoad({
    name: TrackEvent.VIEWED_PULSE_SESSION_IMPORT_URL_CONFIRMATION,
    properties: { urlIds: [importUrlId] },
  });

  const [taskStatus, setTaskStatus] = useState(null);
  const [message, setMessage] = useState(
    'An error occurred retrieving this web page. Please try again in a few minutes.'
  );

  const { data: importResponse, isLoading } = useFetchImportURL(
    { automatedInsightImportUrlId: importUrlId },
    {
      onError: (err) => {
        snackbar.error(
          err?.response?.data?.humanReadableMessage ??
            'A problem occurred while importing this URL. Please try again.'
        );
        history.push({
          pathname: Paths.automatedInsights.basePath,
        });
      },
    }
  );

  const { isLoading: isTaskLoading } = useFetchImportUrlTask(
    { automatedInsightImportUrlId: importUrlId },
    {
      onSuccess: (data) => {
        // if for some reason there is no task, this is likely an issue in the workflow and the import will never complete
        if (!data) {
          snackbar.error('A problem occurred while importing this URL. Please try again.');

          history.push({
            pathname: Paths.automatedInsights.basePath,
          });
        }

        const status = data?.status;
        setMessage(data?.displayMessage);

        if (status !== taskStatus) {
          setTaskStatus(status);

          if (status === TaskStatus.Completed) {
            queryClient.invalidateQueries([
              'automatedInsightSessionImportUrl',
              { automatedInsightImportUrlId: importUrlId },
            ]);

            if (!_.isNil(data?.isExpected)) {
              setIsExpected(data?.isExpected);
            }
          }

          if (status === TaskStatus.Failed && data?.tipsStartOpen) {
            setTipsStartOpen(true);
          }
        }
      },
      onError: (err) => {
        snackbar.error(
          err?.response?.data?.humanReadableMessage ??
            'A problem occurred while importing this URL. Please try again.'
        );
        history.push({
          pathname: Paths.automatedInsights.basePath,
        });
      },
      refetchInterval: [TaskStatus.Completed, TaskStatus.Failed].includes(taskStatus) ? false : 1000,
      refetchIntervalInBackground: true,
    }
  );

  const { mutate: createSessionFromUrl } = useCreateSessionFromURLs();
  const { mutate: generateInsights } = useGenerateInsights();

  const handleAcceptImportResult = () => {
    track(TrackEvent.CLICKED_PULSE_GENERATE_SESSION_FROM_URL, { urlId: importUrlId });
    setIsCreatingSession(true);

    createSessionFromUrl(
      {
        automatedInsightImportUrlId: importResponse.automatedInsightImportUrl.id,
        // name and deviceId may be written over in v2 api if the above import url has a pulse link code
        name: 'Untitled',
        deviceId: deviceId,
      },
      {
        onSuccess: (data) => {
          const sessionId = data?.automatedInsightSession?.id;
          const segmentId = data?.segment?.id;

          generateInsights(
            { sessionId, segmentId },
            {
              onSuccess: () => {
                queryClient.invalidateQueries('automatedInsightSessions');
                history.replace({
                  pathname: generatePath(Paths.automatedInsights.session, { sessionId }),
                  state: { sessionDetails: data },
                });
              },
              onSettled: () => {
                setIsCreatingSession(false);
              },
            }
          );
        },
        onError: (err) => {
          snackbar.error(
            err?.response?.data?.humanReadableMessage ??
              'An error occurred starting a new session with this asset.'
          );
          setIsCreatingSession(false);
        },
      }
    );
  };

  const handleRejectImportResult = () => {
    track(TrackEvent.CLICKED_PULSE_CANCEL_GENERATE_SESSION_FROM_URL, { urlId: importUrlId });

    history.replace({
      pathname: Paths.automatedInsights.basePath,
    });
  };

  const imageURL = useMemo(() => {
    if (_.isNil(importResponse)) {
      return null;
    }

    return importResponse?.automatedInsightImportUrl?.image?.url;
  }, [importResponse]);

  if (isLoading || isTaskLoading) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={2} pt={3}>
      <Grid container item spacing={6}>
        <Grid item xs={12} style={{ marginTop: '2px' }}>
          {/* just spacing so that the boxes lineup when the textbox for the title is added after user accepts url */}
        </Grid>
        <Grid item xs={12} md={6} pb={2} order={{ xs: 1, sm: 1, md: 0 }}>
          {imageURL ? (
            <>
              <Box
                sx={{
                  height: MainContainerDimensions.Height,
                  minHeight: MainContainerDimensions.MinHeight,
                  overflow: 'auto',
                  borderRadius: '20px',
                  lineHeight: 0, //removes gap at the bottom of the image
                  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                    display: 'none', // chrome and safari
                  },
                  msOverflowStyle: 'none', // IE and edge
                  scrollbarWidth: 'none', // firefox
                  cursor: 'pointer',
                }}>
                <img src={imageURL} alt="page" style={{ width: '100%' }} onClick={handleOnClick} />
              </Box>
              {isOpen && (
                <Lightbox
                  mainSrc={imageURL}
                  onCloseRequest={handleOnClick}
                  reactModalStyle={{ overlay: { zIndex: 9000 } }}
                />
              )}
            </>
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid rgba(255, 255, 255, 0.3)',
                borderRadius: '20px',
              }}>
              <CircularProgress color="primary" />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6} pb={2} order={{ xs: 0, sm: 0, md: 1 }}>
          <ImportURLConfirmBox
            taskStatus={taskStatus}
            onAccept={handleAcceptImportResult}
            onReject={handleRejectImportResult}
            isLoading={isCreatingSession}
            isExpected={isExpected}
            message={message}
            tipsStartOpen={tipsStartOpen}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
