import { Box, Typography } from '@mui/material';
import { CustomScreenerTypes } from '../../../../../../../modules/intake/constants';
import { CustomTextField } from '../../../../../components';
import { ValidationErrorNotice } from '../../../../../components/Notice';
import DebouncedInput from '../../../../../edit/DebouncedInput';
import { serializeErrors } from '../../../helpers/validation';
import MultipleChoiceConfiguration from './multiple-choice/MultipleChoiceConfiguration';
import { PresetScaleConfiguration } from './multiple-choice/PresetScaleMultipleChoiceConfiguration';

function CustomScreenerDetails({
  customScreener,
  errors,
  onChange,
  onOptionAdded,
  onOptionDeleted,
  onLoadingNewOptions,
  isAddingOption,
  isDeletingOption,
  isLoadingNewOptions,
}) {
  const renderOptions = () => {
    const questionType = customScreener?.questionType;

    switch (questionType) {
      case CustomScreenerTypes.MultiSelect:
      case CustomScreenerTypes.MultipleChoice:
        return (
          <MultipleChoiceConfiguration
            customScreener={customScreener}
            onCustomScreenerChanged={onChange}
            onOptionAdded={onOptionAdded}
            onOptionDeleted={onOptionDeleted}
            onLoadingNewOptions={onLoadingNewOptions}
            isAddingOption={isAddingOption}
            isDeletingOption={isDeletingOption}
            isLoadingNewOptions={isLoadingNewOptions}
            errors={errors}
          />
        );
      case CustomScreenerTypes.FiveLikertScale:
      case CustomScreenerTypes.SevenLikertScale:
      case CustomScreenerTypes.YesNoTrueFalse:
        return (
          <PresetScaleConfiguration
            customScreener={customScreener}
            onCustomScreenerChanged={onChange}
            onOptionAdded={onOptionAdded}
            onOptionDeleted={onOptionDeleted}
            onLoadingNewOptions={onLoadingNewOptions}
            isAddingOption={isAddingOption}
            isLoadingNewOptions={isLoadingNewOptions}
            errors={errors}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Box>
      <Typography mb={1} fontSize={13} fontWeight={500}>
        Enter your question*
      </Typography>
      <DebouncedInput
        // need to remount when question type changes to reset the debounced input field
        key={`${customScreener.id}_${customScreener?.questionType}`}
        value={customScreener.questionText || ''}
        onChange={(value) => {
          onChange({
            customScreener: customScreener,
            updateFields: { questionText: value },
          });
        }}
        debounceMs={500}
        renderInput={({ value, onChange }) => (
          <CustomTextField
            value={value}
            sx={{
              '& .MuiInputBase-root': {
                fontSize: 14,
              },
            }}
            onChange={onChange}
          />
        )}
      />
      {errors?.questionText?.length > 0 && (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(errors.questionText)} />
        </Box>
      )}
      <Box mb={3} />
      {renderOptions()}
    </Box>
  );
}

export default CustomScreenerDetails;
