import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  buttonClasses,
  Grid,
  IconButton,
  inputClasses,
  Paper,
  Popover,
  styled,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  // disabling eslint no-unused-vars warning because I'm using this to improve the Intellisense
  // eslint-disable-next-line no-unused-vars
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  // disabling eslint no-unused-vars warning because I'm using this to improve the Intellisense
  // eslint-disable-next-line no-unused-vars
  Row,
  useReactTable,
} from '@tanstack/react-table';
import moment, { utc } from 'moment';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import useArchiveWevo from '../../hooks/useArchiveWevo';
import useCopyTest from '../../hooks/useCopyTest';
import useDeleteWevo from '../../hooks/useDeleteWevo';
import useRestoreWevo from '../../hooks/useRestoreWevo';
import useWevos from '../../hooks/useWevos';
import { canUseAutomatedInsightSessions } from '../../modules/automated-insights/helpers';
import { JobToBeDone } from '../../modules/intake/constants';
import { WevoStatus } from '../../modules/report/constants';
import { getUserCustomizations, getUserProfile } from '../../modules/user/selectors';
import {
  DEVICE_NAME_TO_DEVICE_ID,
  displayWevoStatus,
  OrderedWevoStatuses,
  TagsOption,
  wevoActivityState,
  wevoStatus,
  WevoType,
} from '../../modules/wevos/constants';
import { snackbar } from '../../notifications';
import { Paths } from '../../routes';
import DeviceIcon from '../../ui/DeviceIcon';
import { TrackEvent, useTrackPageLoad } from '../analytics';
import TagsBox from '../dashboard/TagsBox';
import { CreatePulseButton, CreateTestButton } from './CreateTestButton';
import DashboardTable from './DashboardTable';
import DeleteWevoDialog from './DeleteWevoDialog';
import DeviceFilter from './DeviceFilter';
import StatusFilter from './StatusFilter';
import Tags from './Tags';
import WevoActionButton from './WevoActionButton';
import WevoOptionsButton from './WevoOptionsButton';
import WevoOptionsMenu from './WevoOptionsMenu';

const TEST_NAME_MAX_LENGTH = 30;

const getElapsedTime = (start, end) => {
  const t1 = moment(start);
  const t2 = moment(end);
  const duration = moment.duration(t2.diff(t1)).asMilliseconds();
  return utc(duration).format('D[d], H[h], m[m]');
};

const wevoStatusFontColors = {
  draft: 'primary.main',
  pending: 'rgb(168, 168, 168)', // light gray, color picked from legacy app
  running: 'inherit',
  completed: 'secondary.main',
};

const styles = {
  paper: {
    marginTop: 2,
    paddingBottom: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  createTestButton: {
    textAlign: 'end',
    [`& .${buttonClasses.root}`]: {
      textTransform: 'none',
    },
  },
};

const SearchTextField = styled(TextField)(({ theme }) => ({
  [`& .${inputClasses.input}`]: {
    padding: theme.spacing(1),
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '16ch',
      '&:focus': {
        width: '24ch',
      },
    },
  },
}));

/**
 *
 * @param {Object} props
 * @param {function} props.getValue
 * @param {Row<Wevo>} props.row
 */
const WevoStatusCell = ({ getValue, row }) => {
  const value = getValue();
  return (
    <div>
      <Typography sx={{ color: wevoStatusFontColors[value] }}>{displayWevoStatus[value]}</Typography>
      {value === wevoStatus['completed'] && (
        <Typography variant="caption" sx={{ color: 'rgb(168,168,168)' }}>
          {getElapsedTime(row.original.date_published, row.original.date_completed)}
        </Typography>
      )}
    </div>
  );
};

/**
 * Array of column objects to use with react-table
 * @type {ColumnDef<Wevo>[]}
 */
const columns = [
  {
    accessorKey: 'devices',
    header: 'Devices',
    enableSorting: false,
    enableColumnFilter: true,
    filterFn: (row, columnId, filterValue) => {
      const deviceIds = row
        .getValue(columnId)
        .map((deviceName) => DEVICE_NAME_TO_DEVICE_ID[deviceName.toLowerCase()]);
      return deviceIds.some((deviceId) => deviceId === filterValue);
    },
    cell: ({ getValue }) => {
      return getValue().map((device) => (
        <DeviceIcon key={`device-icon-${device}`} deviceId={DEVICE_NAME_TO_DEVICE_ID[device.toLowerCase()]} />
      ));
    },
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'date_modified',
    header: 'Updated',
    enableColumnFilter: false,
    enableSorting: true,
    sortingFn: 'datetime',
    sortDescFirst: true,
    cell: ({ getValue }) => (
      <div>
        <Typography>{moment(getValue()).format('M-D-YYYY')}</Typography>
        <Typography>{moment(getValue()).format('h:mm a')}</Typography>
      </div>
    ),
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: WevoStatusCell,
    enableColumnFilter: true,
    enableSorting: true,
    filterFn: 'arrIncludesSome',
    sortingFn: (rowA, rowB, columnId) => {
      const a = OrderedWevoStatuses.indexOf(rowA.getValue(columnId));
      const b = OrderedWevoStatuses.indexOf(rowB.getValue(columnId));

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  {
    id: 'actionBtn',
    header: 'Action',
    enableColumnFilter: false,
    enableSorting: false,
    cell: ({ row }) => {
      const { original: wevo } = row;
      return (
        <WevoActionButton
          wevo={{
            status: wevo.status,
            id: wevo.id,
            teamId: wevo.owner_team_id,
            url: wevo.url,
            isPreviewing: wevo.isPreviewing && wevo.status === WevoStatus.Running,
            isJourney: wevo.type === WevoType.Journey,
            isLite: wevo.jobToBeDone === JobToBeDone.Lite,
          }}
        />
      );
    },
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'is_archived',
    enableColumnFilter: true,
    enableSorting: false,
    enableHiding: true,
    filterFn: 'equals',
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'ownerEmail',
    header: 'Owner Email',
    cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'visitorObjective',
    header: 'Visitor Objective',
    cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'created_by_user_id',
    header: 'Creator',
    cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'product_name',
    header: 'Product',
    cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'productOffer',
    header: 'Product Offer',
    cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'description',
    header: 'Test Goal',
    cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'secondary_test_goal',
    header: 'Secondary Test Goal',
    cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
    filterFn: 'includesString',
  },
  {
    accessorKey: 'id',
    header: 'Wevo ID',
    cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
    filterFn: 'arrIncludesSome',
  },
];

const Dashboard = () => {
  useTrackPageLoad({ name: TrackEvent.LOADED_DASHBOARD });
  const history = useHistory();
  const { data: wevos, isLoading } = useWevos();
  const { mutate: archiveWevo } = useArchiveWevo();
  const { mutate: restoreWevo } = useRestoreWevo();
  const { mutate: deleteWevo } = useDeleteWevo();
  const { mutate: copyWevo, isLoading: isCopying } = useCopyTest();

  const { track } = useAnalytics();

  const user = useSelector(getUserProfile);
  const userCustomizations = useSelector(getUserCustomizations);

  const isMastercard = Boolean(userCustomizations?.isMastercard);

  const canEditNewIntake = (wevo) => {
    return (
      userCustomizations?.features?.newIntake !== false &&
      wevo.type === WevoType.Journey &&
      wevo.jobToBeDone !== JobToBeDone.Lite
    );
  };

  const [selectedTag, setSelectedTag] = useState(TagsOption.AllTags);

  const theme = useTheme();
  const queryClient = useQueryClient();

  const [selectedTab, setSelectedTab] = useState(wevoActivityState.active);
  const [isFiltersPopperOpen, setIsFiltersPopperOpen] = useState(false);

  const filtersPopoverAnchorRef = useRef();
  const testsSearchInputRef = useRef();

  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = useState(null);
  const [optionsMenuWevo, setOptionsMenuWevo] = useState();
  const [isDeleteWevoDialogOpen, setIsDeleteWevoDialogOpen] = useState(false);

  const [enableTaggingForWevoId, setEnableTaggingForWevoId] = useState();
  const [displayAddTagForWevoID, setDisplayAddTagForWevoId] = useState(null);

  const handleOptionsClick = useCallback((ev) => {
    setOptionsMenuAnchorEl(ev.currentTarget);
  }, []);

  const handleOptionsMenuClose = () => {
    setOptionsMenuAnchorEl(null);
  };

  const handleCopyTestClick = () => {
    copyWevo(
      { id: optionsMenuWevo.id },
      {
        onSuccess: (data) => {
          const wevoId = data.id;
          handleOptionsMenuClose();

          if (canEditNewIntake(data)) {
            const entryPath = isMastercard ? Paths.intake.intakeStart : Paths.intake.intakeAudience;
            history.push({
              //this path will be different for MC users
              pathname: generatePath(entryPath, { wevoId }),
            });
          } else {
            history.push(`/wevos/${wevoId}/edit/start`);
          }
        },
        onError: (err) => {
          handleOptionsMenuClose();
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Wevo duplicating failed.');
        },
      }
    );
  };

  const handleAddTagClick = () => {
    track(TrackEvent.ADD_TAG_CLICKED);
    setEnableTaggingForWevoId(optionsMenuWevo.id);
    handleOptionsMenuClose();
  };

  const handleArchiveClick = () => {
    handleOptionsMenuClose();
    archiveWevo(
      { wevoId: optionsMenuWevo.id },
      {
        onSuccess: (res, { wevoId }) => {
          snackbar.success(`Wevo ${wevoId} was moved to the archive`);
          queryClient.invalidateQueries('wevosTags');
        },
      }
    );
  };

  const handleRestoreClick = () => {
    handleOptionsMenuClose();
    restoreWevo(
      { wevoId: optionsMenuWevo.id },
      {
        onSuccess: (res, { wevoId }) => {
          snackbar.success(`Wevo ${wevoId} was restored from the archive`);
        },
      }
    );
  };

  const handleDeleteClick = () => {
    setIsDeleteWevoDialogOpen(true);
    handleOptionsMenuClose();
  };

  const handleWevoDelete = () => {
    deleteWevo(
      { wevoId: optionsMenuWevo.id },
      {
        onSuccess: (res, { wevoId }) => {
          snackbar.success(`Wevo ${wevoId} was deleted`);
          queryClient.invalidateQueries('wevosTags');
        },
      }
    );
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteWevoDialogOpen(false);
  };

  const tableColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Test Name',
        cell: ({ getValue, row }) => {
          const { original: wevo } = row;
          return (
            <Box sx={{ maxWidth: '280px' }}>
              <Tooltip title={getValue().length > TEST_NAME_MAX_LENGTH ? getValue() : ''} placement="right">
                <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {getValue()}
                </Typography>
              </Tooltip>
              <Box mt={1}>
                <Tags
                  tags={wevo.tags}
                  enableTagging={wevo.enableTagging}
                  setEnableTaggingForWevoId={setEnableTaggingForWevoId}
                  wevoId={wevo.id}
                  setDisplayAddTagForWevoId={setDisplayAddTagForWevoId}
                />
              </Box>
            </Box>
          );
        },
        enableSorting: true,
        enableColumnFilter: true,
        sortDescFirst: false,
        sortingFn: 'basic',
        filterFn: 'includesString',
      },
      ...columns,
      {
        id: 'options',
        header: 'Options',
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const { original: wevo } = row;
          return (
            <WevoOptionsButton
              wevo={{
                name: wevo.name,
                id: wevo.id,
                status: wevo.status,
                isArchived: wevo.is_archived,
                canEdit: Boolean(wevo.acls.canEdit),
              }}
              setOptionsMenuWevo={setOptionsMenuWevo}
              handleOptionsClick={handleOptionsClick}
            />
          );
        },
      },
    ],
    [handleOptionsClick]
  );

  const customizedWevos = useMemo(() => {
    return wevos?.map((wevo) => {
      const productName = wevo?.product?.name || '';
      const creatorName = `${wevo?.createdByUser?.first_name} ${wevo?.createdByUser?.last_name}` || '';
      const secondaryTestGoal = wevo.secondaryTestGoal || '';
      return {
        ...wevo,
        created_by_user_id: creatorName,
        product_name: productName,
        secondary_test_goal: secondaryTestGoal,
        enableTagging: wevo.id === enableTaggingForWevoId,
        displayAddTag: wevo.id === displayAddTagForWevoID,
      };
    });
  }, [wevos, enableTaggingForWevoId, displayAddTagForWevoID]);

  const table = useReactTable({
    data: customizedWevos,
    columns: tableColumns,
    initialState: {
      columnVisibility: {
        is_archived: false,
        ownerEmail: false,
        visitorObjective: false,
        created_by_user_id: false,
        product_name: false,
        productOffer: false,
        description: false,
        secondary_test_goal: false,
        id: false,
      },
      columnFilters: [
        {
          id: 'is_archived',
          value: false,
        },
      ],
      sorting: [
        {
          id: 'date_modified',
          desc: true,
        },
      ],
    },
    enableMultiSort: false,
    enableSortingRemoval: false,
    // since each row represents a Wevo, let's use the Wevo's ID as that row's ID
    getRowId: (originalRow) => originalRow.id,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { setGlobalFilter } = table;

  const handleTabChange = (ev, newValue) => {
    table.getColumn('is_archived').setFilterValue(newValue === wevoActivityState.archived);
    setSelectedTab(newValue);
  };

  const handleTestsSearchInput = (ev) => {
    setGlobalFilter(ev.target.value);
  };

  const handleClose = (ev) => {
    setIsFiltersPopperOpen(false);
  };

  const handleResetFiltersClick = () => {
    // remove all column filters other than the is_archived filter since that one is controlled by the tabs
    table.setColumnFilters((currColumnFilters) =>
      currColumnFilters.filter((columnFilter) => columnFilter.id === 'is_archived')
    );
  };

  const handleClearSearchClick = (ev) => {
    setGlobalFilter();
    testsSearchInputRef.current.value = '';
  };

  return (
    <>
      <Box sx={styles.paper}>
        <Grid container spacing={2} sx={{ flexWrap: 'nowrap' }}>
          <Grid item xs={2} />
          <Grid item xs={8} sx={{ minWidth: '1000px' }}>
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
              <Grid item xs={6}>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  aria-label="view active or archived tests">
                  <Tab label={wevoActivityState.active} value={wevoActivityState.active} />
                  <Tab label={wevoActivityState.archived} value={wevoActivityState.archived} />
                </Tabs>
              </Grid>
              <Grid item xs={6} sx={styles.createTestButton}>
                {canUseAutomatedInsightSessions(user, userCustomizations) && (
                  <Box component="span" sx={{ paddingRight: 1 }}>
                    <CreatePulseButton />
                  </Box>
                )}
                <CreateTestButton />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sx={{ minWidth: '270px' }} />
        </Grid>
        <Grid container spacing={2} sx={{ flexWrap: 'nowrap' }}>
          <Grid item xs={2} />
          <Grid item xs={8} sx={{ minWidth: '1000px' }}>
            <Paper sx={{ marginTop: theme.spacing(2), width: '100%' }}>
              <Grid container alignItems="center" style={{ padding: theme.spacing(2) }}>
                <Grid item xs>
                  <Typography variant="h5">Tests</Typography>
                </Grid>
                <Grid item>
                  <SearchTextField
                    variant="standard"
                    onChange={handleTestsSearchInput}
                    inputRef={testsSearchInputRef}
                    InputProps={{
                      placeholder: 'Search...',
                      startAdornment: <SearchIcon />,
                      endAdornment: (
                        <IconButton
                          size="small"
                          style={{
                            visibility: Boolean(testsSearchInputRef?.current?.value) ? 'visible' : 'hidden',
                          }}
                          onClick={handleClearSearchClick}>
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title="Filter">
                    <IconButton
                      ref={filtersPopoverAnchorRef}
                      onClick={() => {
                        setIsFiltersPopperOpen((isOpen) => !isOpen);
                      }}
                      size="large">
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>

                  <Popover
                    open={isFiltersPopperOpen}
                    onClose={handleClose}
                    anchorEl={filtersPopoverAnchorRef.current}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}>
                    <Paper>
                      <Box p={2}>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item>
                            <Typography variant="h6">Filters</Typography>
                          </Grid>
                          <Grid item>
                            <Button onClick={handleResetFiltersClick} size="small">
                              Reset
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} justifyContent="space-between">
                          <Grid item>
                            <DeviceFilter
                              value={table.getColumn('devices').getFilterValue()}
                              onChange={table.getColumn('devices').setFilterValue}
                            />
                          </Grid>
                          <Grid item>
                            <StatusFilter
                              value={table.getColumn('status').getFilterValue()}
                              onChange={table.getColumn('status').setFilterValue}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Popover>
                </Grid>
              </Grid>
              <DashboardTable
                headerGroups={table.getHeaderGroups()}
                rows={table.getRowModel().rows}
                isLoading={isLoading}
              />
            </Paper>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              minWidth: '270px',
              marginTop: 2,
              padding: theme.spacing(2),
            }}>
            <Box sx={{ position: 'sticky', top: 140 }}>
              <TagsBox table={table} selectedTag={selectedTag} setSelectedTag={setSelectedTag} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <WevoOptionsMenu
        wevo={optionsMenuWevo}
        anchorEl={optionsMenuAnchorEl}
        handleArchiveClick={handleArchiveClick}
        handleRestoreClick={handleRestoreClick}
        handleDeleteClick={handleDeleteClick}
        handleCopyTestClick={handleCopyTestClick}
        handleAddTagClick={handleAddTagClick}
        handleClose={handleOptionsMenuClose}
        isCopying={isCopying}
      />
      <DeleteWevoDialog
        open={isDeleteWevoDialogOpen}
        wevoName={optionsMenuWevo?.name}
        deleteCallback={handleWevoDelete}
        closeCallback={handleDeleteDialogClose}
      />
    </>
  );
};

export default Dashboard;
