import { Box } from '@mui/material';
import { useState } from 'react';
import {
  CustomScreenerTypes,
  OutcomeOptions,
  ScreenerSelectOptions,
} from '../../../../../../modules/intake/constants';
import { DeleteButton } from '../../../../components/Buttons';
import CustomScreenerTypeSelector from './CustomScreenerTypeSelector';
import CustomScreenerDetails from './question-types';

const IntakeCustomScreener = ({
  customScreener,
  onDelete,
  isDeleting,
  isDeletingOption,
  isAddingOption,
  onChange,
  onOptionAdded,
  onOptionDeleted,
  errors,
}) => {
  const [isLoadingNewOptions, setIsLoadingNewOptions] = useState(false);

  const questionType = customScreener?.questionType || 'text';

  const handleQuestionTypeChanged = async (newQuestionType) => {
    const prevQuestionType = customScreener.questionType;
    const prevOptions = customScreener.options;
    let updates = {};

    if (newQuestionType === prevQuestionType) {
      return;
    }
    // optimistically update the question type
    updates.questionType = newQuestionType;

    // when switching from muliselect to another question type, change any MaySelect outcomes to Accept
    // because only multiselect supports MaySelect outcomes
    if (prevQuestionType === CustomScreenerTypes.MultiSelect) {
      const updatedOptions = prevOptions?.map((option) => {
        if (option?.outcome === OutcomeOptions.MaySelect) {
          return { ...option, outcome: OutcomeOptions.Accept };
        }
        return option;
      });
      updates.options = updatedOptions;
    }

    // if the new question type is not multiple choice or multiselect, reset labelsType = 'custom' and isRandomized = false
    if (![CustomScreenerTypes.MultipleChoice, CustomScreenerTypes.MultiSelect].includes(newQuestionType)) {
      updates.details = { labelsType: ScreenerSelectOptions.Custom };
      updates.isRandomized = false;
    }
    setIsLoadingNewOptions(true);
    await onChange({ customScreener, updateFields: updates });
    setIsLoadingNewOptions(false);
  };

  return (
    <Box>
      <CustomScreenerTypeSelector value={questionType} onChange={handleQuestionTypeChanged} />
      <Box mb={4} />
      <CustomScreenerDetails
        customScreener={customScreener}
        onChange={onChange}
        onOptionAdded={onOptionAdded}
        onOptionDeleted={onOptionDeleted}
        onLoadingNewOptions={setIsLoadingNewOptions}
        isAddingOption={isAddingOption}
        isDeletingOption={isDeletingOption}
        isLoadingNewOptions={isLoadingNewOptions}
        errors={errors}
      />
      <Box mb={5} />
      <DeleteButton
        isLoading={isDeleting}
        disabled={isDeleting}
        onClick={() => onDelete && onDelete({ customScreener })}
      />
    </Box>
  );
};

export default IntakeCustomScreener;
