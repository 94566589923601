import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ReactComponent as PreviewIcon } from '../../../../../assets/eye.svg';
import { ReactComponent as IntakeSummaryIcon } from '../../../../../assets/intake-summary-icon.svg';
import PreviewPanel from './PreviewPanel';
import SummaryPanel from './SummaryPanel';

function ariaProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SummaryPreviewTabs({
  wevo,
  customQuestions,
  customScreeners,
  userCustomizations,
  proPricing,
  selectedTabIndex,
  onChange,
  hasPreview,
  previewComponent = <></>,
  controls = null,
}) {
  const value = selectedTabIndex || 0;

  if (!hasPreview && value === 1) {
    onChange(null, 0);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={onChange}
          aria-label="summary-preview-tabs"
          sx={{ background: '#FFFFFF' }}
          TabIndicatorProps={{
            sx: { display: 'none' },
          }}>
          <StyledTab
            label={
              <Box display="flex" alignItems="center" gap={1}>
                <IntakeSummaryIcon />
                <Typography>Summary</Typography>
              </Box>
            }
            {...ariaProps(0)}
            fontColor="#276EB0"
            selectedFontColor="#276EB0"
          />
          <StyledTab
            label={
              <Box display="flex" alignItems="center" gap={1}>
                <PreviewIcon />
                <Typography>Preview</Typography>
              </Box>
            }
            {...ariaProps(1)}
            disabled={!hasPreview}
            fontColor="#212A37"
            selectedFontColor="#212A37"
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SummaryPanel
          wevo={wevo}
          customQuestions={customQuestions}
          customScreeners={customScreeners}
          userCustomizations={userCustomizations}
          proPricing={proPricing}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PreviewPanel content={previewComponent} controls={controls} />
      </TabPanel>
    </Box>
  );
}

function StyledTab({ label, fontColor, selectedFontColor, ...rest }) {
  return (
    <Tab
      label={label}
      sx={{
        width: '50%',
        p: 3,
        '&.MuiTab-root': {
          textTransform: 'none',
          borderTop: '1px solid #C7D6DF',
          borderRight: '1px solid #C7D6DF',
          borderBottom: '1px solid #C7D6DF',
          fontWeight: 400,
          ...(fontColor ? { color: fontColor } : {}),
        },
        '&.Mui-selected': {
          background: '#F3F8FB',
          '& p': {
            fontWeight: 700,
            ...(selectedFontColor ? { color: selectedFontColor } : {}),
          },
        },
        '&.Mui-disabled': {
          opacity: 0.38,
        },
      }}
      {...rest}
    />
  );
}

function TabPanel({ children, index, value }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
