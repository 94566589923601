/**
 * Components preset scale multiple choice questions. Note that this covers:
 *  - Likert 5
 *  - Likert 7
 *  - Yes/No and True/False
 *
 * The custom scale option is kicked off to a regular MultipleChoiceConfiguration
 */

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, CircularProgress, Grid, List, ListItem, Typography } from '@mui/material';
import { CustomScreenerTypes } from '../../../../../../../../modules/intake/constants';
import { CustomSelect } from '../../../../../../components';
import { ValidationErrorNotice } from '../../../../../../components/Notice';
import { SelectOptions } from '../../../../../custom-questions/constants';
import { BinaryScaleOptions, LikertScaleOptions } from '../../../../constants';
import { serializeErrors } from '../../../../helpers/validation';
import OutcomeSelect from '../OutcomeSelect';
import MultipleChoiceConfiguration from './MultipleChoiceConfiguration';

function PresetScaleConfiguration({
  customScreener,
  onCustomScreenerChanged,
  onLoadingNewOptions,
  isLoadingNewOptions,
  errors,
}) {
  const scale = customScreener?.details?.labelsType;
  const type = customScreener?.questionType;
  const scaleOptions = [CustomScreenerTypes.FiveLikertScale, CustomScreenerTypes.SevenLikertScale].includes(
    type
  )
    ? LikertScaleOptions
    : BinaryScaleOptions;

  const handleScaleTypeChanged = async (newScaleType) => {
    if (!newScaleType) {
      return;
    }

    const updates = {
      details: { labelsType: newScaleType },
    };

    onLoadingNewOptions(true);
    await onCustomScreenerChanged({ customScreener, updateFields: updates });
    onLoadingNewOptions(false);
  };

  const renderOptions = () => {
    if (scale === SelectOptions.Custom) {
      return (
        <CustomScaleOptions
          customScreener={customScreener}
          onCustomScreenerChanged={onCustomScreenerChanged}
          errors={errors}
        />
      );
    }
    return (
      <>
        <PresetOptionsScale customScreener={customScreener} onChange={onCustomScreenerChanged} />
        {errors?.labels?.length > 0 && (
          <Box my={1}>
            <ValidationErrorNotice message={serializeErrors(errors.labels)} />
          </Box>
        )}
      </>
    );
  };

  return (
    <Box>
      <Typography mb={1} fontSize={13} fontWeight={500}>
        Scale*
      </Typography>
      <CustomSelect
        size={'medium'}
        value={scale || ''}
        items={[{ value: '', label: 'Select scale' }].concat(scaleOptions)}
        disabled={isLoadingNewOptions}
        onChange={(ev) => handleScaleTypeChanged(ev.target.value)}
      />
      {errors?.labelsType?.length > 0 && (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(errors.labelsType)} />
        </Box>
      )}
      <Box mb={scale === SelectOptions.Custom ? 3 : 1} />
      {isLoadingNewOptions ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress size={24} />
        </Box>
      ) : (
        renderOptions()
      )}
    </Box>
  );
}

function PresetScaleAndOutcomes({ customScreener, onChange }) {
  const options = customScreener?.options;
  const questionType = customScreener?.questionType;

  const handleOutcomeChanged = (optionId, newOutcome, prevOutcome) => {
    if (newOutcome === prevOutcome) {
      return;
    }

    const updateFields = { outcome: newOutcome };

    onChange({ customScreener, optionId, updateFields });
  };

  return (
    <List>
      {options?.map((option, index) => (
        <ListItem id={option?.optionText} key={index} sx={{ paddingRight: 0 }}>
          <Grid container alignItems="center">
            <Grid item sx={{ flexGrow: 1 }}>
              <Box display="flex" alignItems="center">
                <RadioButtonUncheckedIcon
                  sx={{
                    marginRight: 1,
                    fontSize: 'large',
                    color: '#C7D6DF',
                  }}
                />
                <Typography variant="body1">{option?.optionText}</Typography>
              </Box>
            </Grid>
            <Grid item sx={{ width: '30%', minWidth: '135px' }}>
              <OutcomeSelect
                selectedOutcome={option?.outcome}
                questionType={questionType}
                onChange={(value) => handleOutcomeChanged(option?.id, value, option?.outcome)}
              />
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
}

function PresetOptionsScale({ customScreener, onChange }) {
  return <PresetScaleAndOutcomes customScreener={customScreener} onChange={onChange} />;
}

function CustomScaleOptions({ customScreener, onCustomScreenerChanged, errors }) {
  const type = customScreener?.questionType;
  const validTypes = [
    CustomScreenerTypes.FiveLikertScale,
    CustomScreenerTypes.SevenLikertScale,
    CustomScreenerTypes.YesNoTrueFalse,
  ];

  if (!validTypes.includes(type)) {
    return <></>;
  }
  return (
    <MultipleChoiceConfiguration
      customScreener={customScreener}
      showHeading={false}
      onCustomScreenerChanged={onCustomScreenerChanged}
      errors={errors}
    />
  );
}

export { PresetScaleConfiguration };
