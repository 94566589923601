import { Box, Divider, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AlertMessage from './AlertMessage';

const useStyles = makeStyles((theme) => ({
  label: {
    color: '#212A37',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '18px',
  },
  labelBox: {
    minWidth: '116px',
    width: '116px',
    borderBottomColor: '#C7D6DF',
    paddingTop: theme.spacing(1.6),
    display: 'flex',
  },
  icon: {
    marginLeft: theme.spacing(0.8),
  },
}));

export const MultiLineField = ({ sx, title, titleErrorMessage, text, errorMessage }) => {
  return (
    <Box sx={{ ...sx }}>
      <Box>
        <Typography
          sx={{
            color: '#212A37',
            fontSize: '11px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '18px',
            overflow: 'auto',
          }}>
          {title}
        </Typography>
      </Box>
      {titleErrorMessage && (
        <Box mb={1}>
          <AlertMessage message={titleErrorMessage} />
        </Box>
      )}
      <Box>
        {errorMessage ? (
          <AlertMessage message={errorMessage} />
        ) : (
          <Typography
            variant="body2"
            sx={{
              color: '#212A37',
              fontSize: '13px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
              whiteSpace: 'pre-line', // Preserve newlines
            }}>
            {text}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const FieldRow = ({ label, icon, divider, children }) => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" sx={{ marginBottom: '16px', flexWrap: { xs: 'wrap', lg: 'nowrap' } }}>
        <Box className={classes.labelBox} mr={2}>
          <Typography className={classes.label}>{label}</Typography>
          {icon && <Typography className={classes.icon}>{icon}</Typography>}
        </Box>
        <Box sx={{ flexGrow: 1, minWidth: '50px' }}>{children}</Box>
      </Box>
      {divider && <Divider sx={{ my: 2, borderColor: '#C7D6DF', borderWidth: '1px' }} />}
    </>
  );
};

export default FieldRow;
