import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Collapse,
  Drawer,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as AccountOutlineIcon } from '../../../src/assets/account-outline.svg';
import { ReactComponent as AudienceOutlineIcon } from '../../../src/assets/audience-outline.svg';
import { ReactComponent as QuestionMarkIcon } from '../../../src/assets/pulse-faq-question-mark.svg';
import shareFreeTrialWhiteIcon from '../../../src/assets/share-free-trial-white-logo.png';
import { ReactComponent as ShareIcon } from '../../../src/assets/share.svg';
import AccountSettingsIcon from '../../assets/account-settings-icon.png';
import BackToWevoIcon from '../../assets/back-to-wevo-icon.png';
import LogoutIcon from '../../assets/logout-icon.png';
import PasswordIcon from '../../assets/password-icon.png';
import ProfileIcon from '../../assets/profile-icon.png';
import { ReactComponent as WevoPulseLogo } from '../../assets/wevo-pulse-logo.svg';
import { getAppBarColor } from '../../helpers';
import { canOnlyUseAutomatedInsightSessions } from '../../modules/automated-insights/helpers';
import { fetchUserInfo } from '../../modules/user/actions';
import { isAuthenticated, setUser } from '../../modules/user/helpers';
import { getUserCustomizations, getUserProfile } from '../../modules/user/selectors';
import { Paths } from '../../routes';
import { TrackEvent } from '../analytics';
import UpgradeSubscriptionBanner from '../automated-insights/UpgradeSubscriptionBanner';
import CustomButton from '../automated-insights/ui/Button';

const cookies = new Cookies();

const styles = {
  appBar: {
    backgroundColor: getAppBarColor('dark'),
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
  onboardingAppBar: {
    backgroundColor: '#294151',
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
  link: {
    textTransform: 'none',
    textDecoration: 'none',
    minWidth: 'fit-content',
    marginLeft: (theme) => theme.spacing(2),
  },
  font: {
    fontSize: '12px',
    color: (theme) => theme.palette.text.primary,
  },
  upgradeFont: {
    fontSize: '11px',
    color: (theme) => theme.palette.text.primary,
  },
  button: {
    fontSize: '14px',
    fontWeight: 400,
  },
  iconButton: {
    height: '34.5px',
    width: '34.5px', //this number is the same as its height
    padding: 0,
    border: '1px solid rgba(255,255,255,.3)',
    '&:hover': {
      border: '1px solid rgba(255,255,255,.5)',
    },
  },
  listItemButton: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 2,
  },
  listItemText: {
    fontSize: '12px',
    color: (theme) => theme.palette.text.secondary,
  },
};

const MobileNavDrawer = ({ mobileOpen, onClick, children }) => {
  return (
    <Drawer
      anchor={'right'}
      variant="temporary"
      open={mobileOpen}
      onClose={onClick}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', md: 'none' },
        zIndex: 1500,
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100%', sm: 250 },
          marginTop: '65px',
          paddingTop: 2,
          backgroundColor: (theme) => theme.palette.secondary.main,
        },
      }}>
      <Box sx={{ marginLeft: '4%' }}>
        <IconButton aria-label="close menu" color="primary" size="small" onClick={onClick}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ paddingX: { xs: '10%', sm: '5%' } }}>{children}</Box>
    </Drawer>
  );
};

const AppBarContainer = (props) => {
  const { children } = props;

  const scrollTriggerConfig = { disableHysteresis: true, threshold: 0 };

  const trigger = useScrollTrigger(scrollTriggerConfig);

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

AppBarContainer.propTypes = {
  children: PropTypes.element.isRequired,
};

const AppBarAI = ({
  onToggleShareDialog,
  onToggleInviteColleagueDialog,
  showAudienceList,
  onShowAudienceList,
  isAudienceDisabled,
  selfServiceSubscription,
  isFreeTrial,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const { track } = useAnalytics();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(getUserProfile);
  const userCustomizations = useSelector(getUserCustomizations);
  const isMastercard = Boolean(userCustomizations?.isMastercard);
  const onlyAutomatedInsightSessionsEnabled = canOnlyUseAutomatedInsightSessions(user, userCustomizations);

  // Streamlined users have logged in via a code (e.g. for a conference) and should not be able to
  // access the Account page because they don't own the account.
  const isStreamlinedUser = user?.isStreamlined;

  const open = Boolean(anchorEl);

  const isOnboardingPage = window.location.pathname === '/pulse/accept-invite';

  const freeTrialRemainingDays =
    isFreeTrial && !user?.pulseSubscription?.isExpired
      ? user?.pulseSubscription?.timeUntilExpiration
      : '0 days';
  const [showUpgradeBox, setShowUpgradeBox] = useState(true);

  useEffect(() => {
    // If the user does not have an auth token, then don't try to fetch the user because this will
    // redirect them to login.
    if (isAuthenticated()) {
      dispatch(fetchUserInfo());
    } else {
      // Set user as unauthenticated.
      setUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const basePathMatch = useRouteMatch({
    path: Paths.automatedInsights.basePath,
    exact: true,
  });

  const sessionPathMatch = useRouteMatch({
    path: Paths.automatedInsights.session,
    exact: true,
  });

  const faqPathMatch = useRouteMatch({
    path: Paths.automatedInsights.faq,
    exact: true,
  });

  const allFaqPathMatch = useRouteMatch({
    path: Paths.automatedInsights.allFaq,
    exact: true,
  });

  const trialPathMatch = useRouteMatch({
    path: Paths.automatedInsights.trial,
    exact: true,
  });

  const acceptInvitePathMatch = useRouteMatch({
    path: Paths.automatedInsights.acceptInvite,
    exact: true,
  });

  const subscriptionPathMatch = useRouteMatch({
    path: Paths.automatedInsights.subscription,
    exact: true,
  });

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMobileDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMobileSubMenuClick = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const handleLogoutClick = () => {
    track(TrackEvent.USER_LOGGED_OUT);
    cookies.remove('authToken', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
    handleClose();
    window.location.replace(`${process.env.REACT_APP_HELLO_HOST}/login`);
  };

  const handleWevoClick = () => {
    track(TrackEvent.CLICKED_RUN_STUDY_BUTTON_FROM_PULSE, {
      isPulseOnly: onlyAutomatedInsightSessionsEnabled,
    });

    if (onlyAutomatedInsightSessionsEnabled) {
      window.open('https://wevo.ai/wevo-pro/', '_blank', 'noopener,noreferrer');
      return;
    }

    window.location.href = `${process.env.REACT_APP_BASE_URL}/dashboard`;
  };

  const handleBackToWevoClick = () => {
    track(TrackEvent.CLICKED_BACK_TO_WEVO_FROM_PULSE);
    window.location.href = '/';
  };

  const handleRestartClick = () => {
    track(TrackEvent.CLICKED_RUN_PULSE_BUTTON_FROM_PULSE);
    setMobileOpen(false);
    history.push(Paths.automatedInsights.basePath);
  };

  const handleProfileClick = () => {
    setMobileOpen(false);
    setAnchorEl(null);
    history.push(Paths.automatedInsights.profile);
  };

  const handleAccountSettingsClick = () => {
    setMobileOpen(false);
    setAnchorEl(null);
    history.push(Paths.automatedInsights.accountSettings);
  };

  const handlePasswordClick = () => {
    setMobileOpen(false);
    setAnchorEl(null);
    history.push(Paths.automatedInsights.password);
  };

  const handleShareButtonClick = () => {
    setMobileOpen(false);
    onToggleShareDialog(true);
  };

  const handleInviteColleagueButtonClick = () => {
    setMobileOpen(false);
    onToggleInviteColleagueDialog(true);
  };

  const handleFaqClick = () => {
    track(TrackEvent.CLICKED_PULSE_FAQ_BUTTON_FROM_PULSE);
    setMobileOpen(false);
    history.push(Paths.automatedInsights.faq);
  };

  const handleMyAudienceClick = () => {
    setMobileOpen(false);
    onShowAudienceList(true);
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <AppBarContainer>
        <AppBar position="fixed" sx={isOnboardingPage ? styles.onboardingAppBar : styles.appBar}>
          <Toolbar>
            <Box sx={{ display: 'inline-flex', alignContent: 'center' }}>
              <IconButton href={`${process.env.REACT_APP_BASE_URL}/pulse`} edge="start" size="large">
                <WevoPulseLogo />
              </IconButton>
            </Box>
            <Box sx={{ display: 'inline-flex', width: '50%' }}></Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                columnGap: 1,
                justifyContent: 'flex-end',
                flexGrow: 1,
              }}>
              {selfServiceSubscription && isFreeTrial && showUpgradeBox && (
                <Box>
                  <UpgradeSubscriptionBanner
                    setShowUpgradeBox={setShowUpgradeBox}
                    freeTrialRemainingDays={freeTrialRemainingDays}
                  />
                </Box>
              )}
              {!basePathMatch && !faqPathMatch && !allFaqPathMatch && !subscriptionPathMatch && (
                <>
                  {isAuthenticated() && (
                    <>
                      <CustomButton
                        variant="secondaryLight"
                        size="small"
                        startIcon={
                          <img
                            src={shareFreeTrialWhiteIcon}
                            alt="Share Free Trial logo"
                            style={{ height: '14px', marginRight: '-4px' }}
                            sx={{ color: 'white' }}
                          />
                        }
                        sx={{ ...styles.button, width: '180px' }}
                        onClick={handleInviteColleagueButtonClick}>
                        {'Invite a Colleague'}
                      </CustomButton>
                      <CustomButton
                        variant="secondaryLight"
                        size="small"
                        sx={styles.button}
                        onClick={handleWevoClick}>
                        {isMastercard ? 'Run a Study' : 'New Pro'}
                      </CustomButton>
                      <CustomButton
                        variant="gradient"
                        size="small"
                        sx={styles.button}
                        onClick={handleRestartClick}>
                        {isMastercard ? 'Take a Pulse' : 'New Pulse'}
                      </CustomButton>
                    </>
                  )}
                  {sessionPathMatch && (
                    <Tooltip title={'Share'} arrow>
                      <IconButton
                        aria-label="share"
                        color="primary"
                        size="small"
                        sx={styles.iconButton}
                        onClick={handleShareButtonClick}>
                        <ShareIcon fill="#FFFFFF" />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
              {!trialPathMatch && !acceptInvitePathMatch && (
                <Tooltip title={'Help Center'} arrow>
                  <IconButton
                    aria-label="frequently asked questions"
                    onClick={handleFaqClick}
                    color="inherit"
                    size="small"
                    sx={styles.iconButton}>
                    <QuestionMarkIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {isAuthenticated() && (
                <>
                  <Tooltip title={user?.firstName} arrow>
                    <IconButton
                      disabled={!userCustomizations}
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                      size="large"
                      sx={styles.iconButton}>
                      <AccountOutlineIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={open}
                    onClose={handleClose}>
                    {!isStreamlinedUser && (
                      <div>
                        {selfServiceSubscription && isFreeTrial && (
                          <Grid
                            item
                            sx={{
                              backgroundColor: 'rgba(75, 192, 200, 0.15)',
                              height: '30px',
                              alignContent: 'center',
                              marginTop: -1,
                              marginBottom: 1,
                            }}>
                            <Typography sx={{ ...styles.upgradeFont, textAlign: 'center' }}>
                              <b>{freeTrialRemainingDays} </b>left in trial.
                              <a
                                href="/pulse/subscription"
                                style={{ color: '#23B1FF', textDecoration: 'none', marginLeft: 1 }}>
                                {`  Upgrade`}
                              </a>
                            </Typography>
                          </Grid>
                        )}
                        <Link style={styles.link} onClick={handleBackToWevoClick}>
                          <MenuItem>
                            <ListItemIcon sx={{ marginLeft: -0.5 }}>
                              <img
                                src={BackToWevoIcon}
                                alt="selectable"
                                style={{ width: '25px', height: 'auto' }}
                              />
                            </ListItemIcon>
                            <ListItemText sx={{ marginLeft: 0.5 }}>
                              <Typography sx={styles.font}>Back to WEVO</Typography>
                            </ListItemText>
                          </MenuItem>
                        </Link>
                        {selfServiceSubscription && (
                          <Link style={styles.link} onClick={handleProfileClick}>
                            <MenuItem>
                              <ListItemIcon>
                                <img
                                  src={ProfileIcon}
                                  alt="selectable"
                                  style={{ width: '15px', height: 'auto' }}
                                />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography sx={styles.font}>Profile</Typography>
                              </ListItemText>
                            </MenuItem>
                          </Link>
                        )}
                        <Link
                          style={styles.link}
                          onClick={selfServiceSubscription ? handleAccountSettingsClick : handleProfileClick}>
                          <MenuItem>
                            <ListItemIcon>
                              {selfServiceSubscription ? (
                                <img
                                  src={AccountSettingsIcon}
                                  alt="selectable"
                                  style={{ width: '15px', height: 'auto' }}
                                />
                              ) : (
                                <img
                                  src={ProfileIcon}
                                  alt="selectable"
                                  style={{ width: '15px', height: 'auto' }}
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText>
                              <Typography sx={styles.font}>
                                {selfServiceSubscription ? 'Account settings' : 'Account'}
                              </Typography>
                            </ListItemText>
                          </MenuItem>
                        </Link>
                        <Link style={styles.link} onClick={handlePasswordClick}>
                          <MenuItem>
                            <ListItemIcon>
                              <img
                                src={PasswordIcon}
                                alt="selectable"
                                style={{ width: '15px', height: 'auto' }}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              <Typography sx={styles.font}>Password</Typography>
                            </ListItemText>
                          </MenuItem>
                        </Link>
                      </div>
                    )}
                    <MenuItem onClick={handleLogoutClick}>
                      <ListItemIcon>
                        <img src={LogoutIcon} alt="selectable" style={{ width: '15px', height: 'auto' }} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography sx={styles.font}>Log out</Typography>
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: { xs: 'inline-flex', md: 'none' },
                columnGap: 1,
                justifyContent: 'flex-end',
                flexGrow: 1,
              }}>
              <IconButton
                color="primary"
                aria-label="open menu"
                edge="start"
                onClick={handleMobileDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </AppBarContainer>
      <MobileNavDrawer mobileOpen={mobileOpen} onClick={handleMobileDrawerToggle}>
        <List>
          {!basePathMatch && !faqPathMatch && sessionPathMatch && (
            <>
              <ListItem disablePadding>
                <ListItemButton onClick={handleMyAudienceClick}>
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <AudienceOutlineIcon fill="#FFFFFF" />
                  </ListItemIcon>
                  <ListItemText primary="Personas" sx={styles.listItemText} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={handleShareButtonClick}>
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <ShareIcon fill="#FFFFFF" />
                  </ListItemIcon>
                  <ListItemText primary="Share" sx={styles.listItemText} />
                </ListItemButton>
              </ListItem>
            </>
          )}
          {!trialPathMatch && !acceptInvitePathMatch && (
            <ListItem disablePadding>
              <ListItemButton onClick={handleFaqClick}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <QuestionMarkIcon />
                </ListItemIcon>
                <ListItemText primary="Help Center" sx={styles.listItemText} />
              </ListItemButton>
            </ListItem>
          )}
          {isAuthenticated() && (
            <>
              <ListItemButton onClick={handleMobileSubMenuClick}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <AccountOutlineIcon />
                </ListItemIcon>
                <ListItemText
                  primary={user?.firstName || user?.email}
                  primaryTypographyProps={{
                    noWrap: true,
                  }}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginRight: 2,
                    ...styles.listItemText,
                  }}
                />
                {subMenuOpen ? (
                  <ExpandLess color="primary" fontSize="small" />
                ) : (
                  <ExpandMore color="primary" fontSize="small" />
                )}
              </ListItemButton>
              <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {!isStreamlinedUser && (
                    <>
                      <ListItem disablePadding sx={{ pl: 6 }}>
                        <ListItemButton onClick={handleProfileClick}>
                          <ListItemText primary="Profile" sx={styles.listItemText} />
                        </ListItemButton>
                      </ListItem>
                      {selfServiceSubscription && (
                        <ListItem disablePadding sx={{ pl: 6 }}>
                          <ListItemButton onClick={handleAccountSettingsClick}>
                            <ListItemText primary="Account" sx={styles.listItemText} />
                          </ListItemButton>
                        </ListItem>
                      )}
                      <ListItem disablePadding sx={{ pl: 6 }}>
                        <ListItemButton onClick={handlePasswordClick}>
                          <ListItemText primary="Password" sx={styles.listItemText} />
                        </ListItemButton>
                      </ListItem>
                    </>
                  )}
                  <ListItem disablePadding sx={{ pl: 6 }}>
                    <ListItemButton onClick={handleLogoutClick}>
                      <ListItemText primary="Logout" sx={styles.listItemText} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}
          {!basePathMatch && !faqPathMatch && !allFaqPathMatch && isAuthenticated() && (
            <Box mt={6}>
              <ListItem disablePadding sx={styles.listItemButton}>
                <CustomButton
                  variant="gradient"
                  fullWidth
                  sx={{ maxWidth: 300, fontSize: 14 }}
                  onClick={handleRestartClick}>
                  {isMastercard ? 'Take a Pulse' : 'New Pulse'}
                </CustomButton>
              </ListItem>
              <ListItem disablePadding sx={styles.listItemButton}>
                <CustomButton
                  variant="secondaryLight"
                  fullWidth
                  sx={{ maxWidth: 300, fontSize: 14 }}
                  onClick={handleWevoClick}>
                  {isMastercard ? 'Run a Study' : 'New Pro'}
                </CustomButton>
              </ListItem>
              <ListItem disablePadding sx={styles.listItemButton}>
                <CustomButton
                  variant="secondaryLight"
                  fullWidth
                  startIcon={
                    <img
                      src={shareFreeTrialWhiteIcon}
                      alt="Share Free Trial logo"
                      style={{ height: '14px', marginRight: '-4px' }}
                    />
                  }
                  sx={{ maxWidth: 300, fontSize: 14 }}
                  onClick={handleInviteColleagueButtonClick}>
                  {'Invite a Colleague'}
                </CustomButton>
              </ListItem>
            </Box>
          )}
        </List>
      </MobileNavDrawer>
    </div>
  );
};

export default AppBarAI;
