import { Box, Typography } from '@mui/material';
import { hasScopes } from '../../../custom-questions/helpers/scopes';
import { getReportFeatures } from '../../../helpers/wevo';
import AcceptedBlock from '../survey-elements/AcceptedBlock';
import DeepDiveIntroBlock from '../survey-elements/DeepDiveIntroBlock';
import EndOfSurveyBlock from '../survey-elements/EndOfSurveyBlock';
import FraudPreventionBlock from '../survey-elements/FraudPreventionBlock';
import PageBreakIndicator from '../survey-elements/PageBreakIndicator';
import { CustomQuestionsPageScopePreview, CustomQuestionsStepScopePreview } from './CustomQuestionsPreview';
import CustomScreenersPreview from './CustomScreenersPreview';
import DemographicsPreview from './DemographicsPreview';
import DiagnosticsPreview from './DiagnosticsPreview';
import { ExpectationsFollowupPreview, ExpectationsHopesFearsPreview } from './ExpectationsPreview';
import FirstImpressionPreview from './FirstImpressionPreview';
import PrimerPreview from './PrimerPreview';
import { SentimentMapPreview } from './SentimentMapPreview';
import TaskInstructionsPreview from './TaskInstructionsPreview';

function FullSurveyPreview({ wevo, page, customQuestions, customScreeners }) {
  const { includeCustomQuestions, includeDiagnostics, includeExpectations, includeSentimentMaps } =
    getReportFeatures({ wevo });

  return (
    <Box>
      <Typography fontWeight={600} fontSize={16}>
        Survey Preview
      </Typography>
      <Typography variant="caption" fontWeight={400} fontSize={12} color="#878787">
        Please note that this is an automatically generated preview. The finalized survey distributed to
        respondents may contain minor variations from this generated preview.
      </Typography>
      <Box mb={2} />
      <FraudPreventionBlock />
      <PageBreakIndicator spacing={4} />
      <CustomScreenersPreview wevo={wevo} customScreeners={customScreeners} />
      <PageBreakIndicator spacing={4} />
      <AcceptedBlock />
      <PageBreakIndicator spacing={4} />
      {includeExpectations && (
        <>
          <ExpectationsHopesFearsPreview wevo={wevo} showDisabledNotice={false} />
          <PageBreakIndicator spacing={4} />
        </>
      )}
      {wevo?.pages?.[0]?.primerContext && (
        <>
          <PrimerPreview wevo={wevo} />
          <PageBreakIndicator spacing={4} />
        </>
      )}
      {wevo?.details?.taskToComplete && (
        <>
          <TaskInstructionsPreview wevo={wevo} page={page} />
          <PageBreakIndicator spacing={4} />
        </>
      )}
      {includeDiagnostics && (
        <>
          <FirstImpressionPreview />
          <PageBreakIndicator spacing={4} />
        </>
      )}
      {includeCustomQuestions && hasScopes(customQuestions, { wevoPageId: page?.id, stepId: null }) && (
        <>
          <CustomQuestionsPageScopePreview wevo={wevo} page={page} customQuestions={customQuestions} />
          <PageBreakIndicator spacing={4} />
        </>
      )}
      {includeDiagnostics && (
        <>
          <DiagnosticsPreview />
          <PageBreakIndicator spacing={4} />
        </>
      )}
      <DeepDiveIntroBlock />
      <PageBreakIndicator spacing={4} />
      {(page?.steps ?? []).map((step) => {
        return (
          <Box key={step.id}>
            {includeSentimentMaps && (
              <>
                <SentimentMapPreview wevo={wevo} page={page} step={step} />
                <PageBreakIndicator spacing={4} />
              </>
            )}
            {includeCustomQuestions &&
              hasScopes(customQuestions, { wevoPageId: page?.id, stepId: step.id }) && (
                <>
                  <CustomQuestionsStepScopePreview
                    wevo={wevo}
                    page={page}
                    step={step}
                    customQuestions={customQuestions}
                  />
                  <PageBreakIndicator spacing={4} />
                </>
              )}
          </Box>
        );
      })}
      {includeExpectations && (
        <>
          <ExpectationsFollowupPreview wevo={wevo} />
          <PageBreakIndicator spacing={4} />
        </>
      )}
      <DemographicsPreview wevo={wevo} />
      <PageBreakIndicator spacing={4} />
      <EndOfSurveyBlock />
    </Box>
  );
}

export default FullSurveyPreview;
