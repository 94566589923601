import { Box, Typography } from '@mui/material';
import { CustomScreenerTypes, OutcomeOptions } from '../../../../../../modules/intake/constants';
import { QuestionNotice } from '../survey-elements/QuestionLogic';
import {
  ReadOnlyHorizontalMultipleChoiceQuestion,
  ReadOnlyVerticalMultipleChoiceQuestion,
  ReadOnlyVerticalMultipleSelectionQuestion,
} from '../survey-elements/ReadOnlyField';

function CustomScreenersPreview({ wevo, page, customScreeners, showName = false }) {
  const hasCustomAudience = !!wevo?.audienceDescription;

  const customAudienceComponent = hasCustomAudience ? (
    <QuestionNotice
      noticeText={
        <>
          WEVO may add screeners in order to meet your <b>Other screening criteria</b>. Please note that
          including special audience requirements may require more time to collect responses and incur
          additional costs.
        </>
      }
    />
  ) : (
    <></>
  );
  return (
    <Box>
      <Box mb={2} />
      {customAudienceComponent}
      <Box mb={3} />
      {(customScreeners ?? []).map((customScreener) => {
        // we need to default custom question name because it isn't actually persisted unless the user touches the record
        const customScreenerName = customScreener.name || `Custom Screener #${customScreener.sortOrder + 1}`;

        return (
          <Box key={customScreener.id} mb={3}>
            {showName && (
              <Box mb={2}>
                <Typography fontSize={12} fontWeight={600}>
                  {customScreenerName}
                </Typography>
              </Box>
            )}
            <Box>
              <CustomScreenerQuestionPreview customScreener={customScreener} />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

function CustomScreenerQuestionPreview({ customScreener }) {
  switch (customScreener.questionType) {
    case CustomScreenerTypes.FiveLikertScale:
    case CustomScreenerTypes.SevenLikertScale:
      return <LikertCustomScreenerQuestionPreview customScreener={customScreener} />;
    case CustomScreenerTypes.YesNoTrueFalse:
      return <BinaryCustomScreenerPreview customScreener={customScreener} />;
    case CustomScreenerTypes.MultipleChoice:
      return <MulitpleChoiceCustomScreenerQuestionPreview customScreener={customScreener} />;
    case CustomScreenerTypes.MultiSelect:
      return <MulitpleSelectionCustomScreenerQuestionPreview customScreener={customScreener} />;
    default:
      return <Box></Box>;
  }
}

function LikertCustomScreenerQuestionPreview({ customScreener }) {
  const questionText = customScreener.questionText;
  const choices = (customScreener?.options ?? []).map((option) => option.optionText);

  return (
    <Box>
      <ReadOnlyHorizontalMultipleChoiceQuestion
        questionText={questionText}
        noticeText={generateMultipleChoiceAcceptMessage({ customScreener })}
        choices={choices}
      />
    </Box>
  );
}

function MulitpleChoiceCustomScreenerQuestionPreview({ customScreener }) {
  const questionText = customScreener.questionText;
  const choices = (customScreener?.options ?? []).map((option) => option.optionText);

  return (
    <Box>
      <ReadOnlyVerticalMultipleChoiceQuestion
        questionText={questionText}
        noticeText={generateMultipleChoiceAcceptMessage({ customScreener })}
        choices={choices}
      />
    </Box>
  );
}

function MulitpleSelectionCustomScreenerQuestionPreview({ customScreener }) {
  const questionText = customScreener.questionText;
  const choices = (customScreener?.options ?? []).map((option) => option.optionText);

  return (
    <Box>
      <ReadOnlyVerticalMultipleSelectionQuestion
        questionText={questionText}
        noticeText={generateMultipleSelectionAcceptMessage({ customScreener })}
        choices={choices}
      />
    </Box>
  );
}

function BinaryCustomScreenerPreview({ customScreener }) {
  return <MulitpleChoiceCustomScreenerQuestionPreview customScreener={customScreener} />;
}

function generateScreenerAcceptMessage({ customScreener, conjuction }) {
  const acceptOptions = (customScreener?.options ?? []).filter(
    (option) => option.outcome === OutcomeOptions.Accept
  );

  const acceptCriteria = acceptOptions.map((option, index) => {
    return (
      <Box component="span" key={option.id}>
        <Typography component="span" fontSize={13}>
          '{option.optionText}'
        </Typography>
        {index < acceptOptions.length - 1 ? (
          <Typography component="span" fontSize={13} fontWeight={500}>
            &nbsp;{conjuction}&nbsp;
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    );
  });

  const screeningNotice = <>Respondents are accepted if they select: {acceptCriteria}.</>;

  return screeningNotice;
}

function generateMultipleChoiceAcceptMessage({ customScreener }) {
  return generateScreenerAcceptMessage({ customScreener, conjuction: 'or' });
}

function generateMultipleSelectionAcceptMessage({ customScreener }) {
  return generateScreenerAcceptMessage({ customScreener, conjuction: 'and' });
}

export default CustomScreenersPreview;
