import { ButtonGroup, IconButton, Tooltip } from '@mui/material';
import { ReactComponent as DesktopIcon } from '../../../src/assets/desktop.svg';
import { ReactComponent as MobileIcon } from '../../../src/assets/mobile.svg';
import { DeviceNameToId } from '../../modules/automated-insights/constants';

const DeviceSelection = ({ deviceId, onDeviceSelection, background, disableButtonGroup }) => {
  return (
    <Tooltip title={disableButtonGroup ? 'Device selection not applicable for Compares' : ''}>
      <ButtonGroup
        aria-label="device buttons"
        disableElevation
        sx={{
          width: '100%',
          maxWidth: { md: '128px' },
          border: '1px solid rgba(217, 217, 217, 0.2)',
          borderRadius: '10px',
        }}>
        <Tooltip title="Desktop">
          <IconButton
            aria-label="desktop"
            disabled={disableButtonGroup}
            onClick={() => onDeviceSelection(DeviceNameToId.Desktop)}
            sx={{
              width: '50%',
              borderTopLeftRadius: '9px',
              borderBottomLeftRadius: '9px',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: '1px solid rgba(217, 217, 217, 0.2)',
              background: (theme) =>
                deviceId === DeviceNameToId.Desktop
                  ? background || theme.palette.gradient.main
                  : 'transparent',
              '&.Mui-disabled': {
                background: '#172C3A',
              },
            }}>
            <DesktopIcon fill={disableButtonGroup ? 'rgba(107, 105, 105, 0.50)' : '#FFFFFF'} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Mobile">
          <IconButton
            aria-label="mobile"
            disabled={disableButtonGroup}
            onClick={() => onDeviceSelection(DeviceNameToId.Mobile)}
            sx={{
              width: '50%',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: '9px',
              borderBottomRightRadius: '9px',
              background: (theme) =>
                deviceId === DeviceNameToId.Mobile ? background || theme.palette.gradient.main : 'transparent',
              '&.Mui-disabled': {
                background: '#172C3A',
              },
            }}>
            <MobileIcon fill={disableButtonGroup ? 'rgba(107, 105, 105, 0.50)' : '#FFFFFF'} />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    </Tooltip>
  );
};

export default DeviceSelection;
