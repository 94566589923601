import ClearIcon from '@mui/icons-material/Clear';
import { CssBaseline, IconButton, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SnackbarProvider } from 'notistack';
import React, { createRef, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { AnalyticsProvider } from 'use-analytics';
import darkTheme from '../../darkTheme';
import { Environment } from '../../helpers';
import { HOTJAR_NAME, HOTJAR_SCRIPT, useScript } from '../../hooks/useScript';
// import * as UserActions from '../../modules/user/actions';
import { fetchUserInfo } from '../../modules/user/actions';
import { isAuthenticated, setUser } from '../../modules/user/helpers';
import { getUserProfile } from '../../modules/user/selectors';
import { UserType } from '../../modules/wevos/constants';
import { SnackbarUtilsConfigurator } from '../../notifications';
import theme from '../../theme';
import analytics from '../analytics';
import InitializeConstants from '../app/InitializeConstants';
import AppContainer from './AppContainer';

const queryClient = new QueryClient();

const AppScripts = () => {
  const user = useSelector(getUserProfile);

  useScript(HOTJAR_NAME, null, HOTJAR_SCRIPT, {
    crossOrigin: 'anonymous',
    enabled:
      window?.ketchConsent?.analytics &&
      user &&
      user?.userType &&
      process.env.REACT_APP_ENV_FILE === Environment.Production
        ? user?.userType !== UserType.Wevo
        : false,
  });

  return <></>;
};
const App = () => {
  const [constantsPopulated, setConstantsPopulated] = useState(false);

  const dispatch = useDispatch();
  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  // sets 'FAQpage' to be true only when the "FAQ" page is opened

  const isAutomatedInsight = window.location.pathname.startsWith('/pulse');

  const appTheme = isAutomatedInsight ? darkTheme : theme;

  // Disable analytics if user has not consented via Ketch. Only call it if ketch is actually loaded.
  if (window?.ketchConsent) {
    if (!window?.ketchConsent?.analytics) {
      analytics.plugins.disable('segment');
    } else {
      analytics.plugins.enable('segment');
    }
  }

  useEffect(() => {
    const fetchUser = () => dispatch(fetchUserInfo());
    // If the user does not have an auth token, then don't try to fetch the user because this will
    // redirect them to login.
    if (isAuthenticated()) {
      fetchUser();
    } else {
      // Set user as unauthenticated.
      setUser();
    }
  }, [dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      <InitializeConstants setConstantsPopulated={setConstantsPopulated} />
      {constantsPopulated && (
        <AnalyticsProvider instance={analytics}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appTheme}>
              <CssBaseline />
              <SnackbarProvider
                ref={notistackRef}
                maxSnack={2}
                autoHideDuration={3000}
                action={(key) => (
                  <IconButton onClick={onClickDismiss(key)} size="large">
                    <ClearIcon fontSize="small" style={{ color: grey[200] }} />
                  </IconButton>
                )}
                dense>
                <SnackbarUtilsConfigurator />
                <AppScripts />
                <Router>
                  <AppContainer />
                </Router>
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
          <ReactQueryDevtools />
        </AnalyticsProvider>
      )}
    </QueryClientProvider>
  );
};

export default App;
