/**
 * Components preset scale multiple choice questions. Note that this covers:
 *  - Likert 5
 *  - Likert 7
 *  - Yes/No and True/False
 *
 * The custom scale option is kicked off to a regular MultipleChoiceConfiguration
 */

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, List, ListItem, Typography } from '@mui/material';
import { CustomSelect, CustomTextField } from '../../../../../components';
import { ValidationErrorNotice } from '../../../../../components/Notice';
import DebouncedInput from '../../../../../edit/DebouncedInput';
import {
  BinaryScaleOptions,
  CustomQuestionTypeOptionsByType,
  CustomQuestionTypes,
  LikertScaleOptions,
  PresetBinaryScales,
  PresetLikertScales,
  SelectOptions,
} from '../../../constants';
import { serializeErrors } from '../../../helpers';
import { makeEmptyArray } from '../../../helpers/questionTypes';
import MultipleChoiceConfiguration from './MultipleChoiceConfiguration';

function LikertScaleConfiguration({ wevo, customQuestion, onCustomQuestionChanged, errors }) {
  const scale = customQuestion?.question?.labelsType;
  const type = customQuestion?.question?.type;

  const handleScaleTypeChanged = (newScaleType) => {
    if (!newScaleType) {
      return;
    }

    const updates = {
      labelsType: newScaleType,
    };

    if (newScaleType === SelectOptions.Custom) {
      const limits = CustomQuestionTypeOptionsByType[type].choiceLimits;
      updates.labels = makeEmptyArray(limits.default);
    } else {
      updates.labels = PresetLikertScales[newScaleType][type].values;
    }

    onCustomQuestionChanged({ customQuestion, updateFields: updates });
  };

  return (
    <Box>
      <Typography mb={1}>Scale*</Typography>
      <CustomSelect
        size={'medium'}
        value={scale || ''}
        items={[{ value: '', label: 'Select scale' }].concat(LikertScaleOptions)}
        onChange={(ev) => handleScaleTypeChanged(ev.target.value)}
      />
      {errors?.labelsType?.length > 0 && (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(errors.labelsType)} />
        </Box>
      )}
      <Box mb={scale === SelectOptions.Custom ? 3 : 1} />
      {scale === SelectOptions.Custom ? (
        <CustomScaleOptions
          wevo={wevo}
          customQuestion={customQuestion}
          onCustomQuestionChanged={onCustomQuestionChanged}
          errors={errors}
        />
      ) : (
        <>
          <PresetLikertOptionsScale likertType={type} scale={scale} />
          {errors?.labels?.length > 0 && (
            <Box my={1}>
              <ValidationErrorNotice message={serializeErrors(errors.labels)} />
            </Box>
          )}
          <Box mb={4} />
          <Typography mb={1}>Follow-up question</Typography>
          <DebouncedInput
            value={customQuestion.question.followUpQuestionText || ''}
            onChange={(value) => {
              onCustomQuestionChanged({
                customQuestion: customQuestion,
                updateFields: { followUpQuestionText: value },
              });
            }}
            debounceMs={500}
            renderInput={({ value, onChange }) => (
              <CustomTextField
                value={value}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: 14,
                  },
                }}
                onChange={onChange}
              />
            )}
          />
          {errors?.followUpQuestionText?.length > 0 && (
            <Box my={1}>
              <ValidationErrorNotice message={serializeErrors(errors.followUpQuestionText)} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

function YesNoTrueFalseConfiguration({ wevo, customQuestion, onCustomQuestionChanged, errors }) {
  const scale = customQuestion?.question?.labelsType;
  const type = customQuestion?.question?.type;

  const handleScaleTypeChanged = (newScaleType) => {
    if (!newScaleType) {
      return;
    }

    const updates = {
      labelsType: newScaleType,
    };

    if (newScaleType === SelectOptions.Custom) {
      const limits = CustomQuestionTypeOptionsByType[type].choiceLimits;
      updates.labels = makeEmptyArray(limits.default);
    } else {
      updates.labels = PresetBinaryScales[newScaleType].values;
    }

    onCustomQuestionChanged({ customQuestion, updateFields: updates });
  };

  return (
    <Box>
      <Typography mb={1}>Scale*</Typography>
      <CustomSelect
        size={'medium'}
        value={scale || ''}
        items={[{ value: '', label: 'Select scale' }].concat(BinaryScaleOptions)}
        onChange={(ev) => handleScaleTypeChanged(ev.target.value)}
      />
      {errors?.labelsType?.length > 0 && (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(errors.labelsType)} />
        </Box>
      )}
      <Box mb={scale === SelectOptions.Custom ? 3 : 1} />
      {scale === SelectOptions.Custom ? (
        <CustomScaleOptions
          wevo={wevo}
          customQuestion={customQuestion}
          onCustomQuestionChanged={onCustomQuestionChanged}
          errors={errors}
        />
      ) : (
        <>
          <PresetBinaryOptionsScale scale={scale} />
          {errors?.labels?.length > 0 && (
            <Box my={1}>
              <ValidationErrorNotice message={serializeErrors(errors.labels)} />
            </Box>
          )}
          <Box mb={4} />
          <Typography mb={1}>Follow-up question</Typography>
          <DebouncedInput
            value={customQuestion.question.followUpQuestionText || ''}
            onChange={(value) => {
              onCustomQuestionChanged({
                customQuestion: customQuestion,
                updateFields: { followUpQuestionText: value },
              });
            }}
            debounceMs={500}
            renderInput={({ value, onChange }) => (
              <CustomTextField
                value={value}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: 14,
                  },
                }}
                onChange={onChange}
              />
            )}
          />
          {errors?.followUpQuestionText?.length > 0 && (
            <Box my={1}>
              <ValidationErrorNotice message={serializeErrors(errors.followUpQuestionText)} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

function PresetScale({ scaleOptions }) {
  return (
    <List>
      {scaleOptions.map((value, index) => (
        <ListItem id={value} key={index}>
          <RadioButtonUncheckedIcon
            sx={{
              marginRight: 1,
              fontSize: 'large',
              color: '#C7D6DF',
            }}
          />
          <Typography variant="body1" fontWeight={500}>
            {value}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
}
function PresetLikertOptionsScale({ likertType, scale }) {
  const likertScale = PresetLikertScales?.[scale]?.[likertType]?.values || [];
  return <PresetScale scaleOptions={likertScale} />;
}

function PresetBinaryOptionsScale({ scale }) {
  const scaleValues = PresetBinaryScales?.[scale]?.values || [];
  return <PresetScale scaleOptions={scaleValues} />;
}

function CustomScaleOptions({ wevo, customQuestion, onCustomQuestionChanged, errors }) {
  const type = customQuestion?.question?.type;
  const validTypes = [
    CustomQuestionTypes.FiveLikertScale,
    CustomQuestionTypes.SevenLikertScale,
    CustomQuestionTypes.YesNoTrueFalse,
  ];

  if (!validTypes.includes(type)) {
    return <></>;
  }
  return (
    <MultipleChoiceConfiguration
      wevo={wevo}
      customQuestion={customQuestion}
      showHeading={false}
      onCustomQuestionChanged={onCustomQuestionChanged}
      errors={errors}
    />
  );
}

export { LikertScaleConfiguration, YesNoTrueFalseConfiguration };
