import { isNil } from 'lodash';
import { IntakeSectionNames, IntakeSubsectionNames } from '../../../../../modules/intake/constants';
import { hasCustomScreenerErrors } from '../../audience/helpers/validation';
import { hasCustomQuestionErrors } from '../../custom-questions/helpers/validation';
import { ValidationKeys as AssetValidationKeys, hasAssetErrors } from '../../helpers/validation/assets';
import { hasAudienceErrors, ValidationKeys as WevoValidationKeys } from '../../helpers/validation/wevo';

export function hasErrorsForValidationKeys(validationKeys, validationResult) {
  return validationKeys.some((key) => validationResult[key] && validationResult[key].length > 0);
}

export function hasSectionErrors({
  sectionName,
  wevo,
  wevoErrors,
  customQuestionErrors,
  customScreenerErrors,
  assetErrors,
}) {
  let validationKeys = [];

  const pageErrors = assetErrors?.pageErrors?.[String(wevo?.pages?.[0]?.id)] || {};
  const errors = wevoErrors?.wevoErrors?.[String(wevo?.id)] || {};
  const hasPrimerError = hasErrorsForValidationKeys([AssetValidationKeys.PrimerContext], pageErrors);

  switch (sectionName) {
    // main sections
    case IntakeSectionNames.Start:
      validationKeys = [WevoValidationKeys.Tags];
      return hasErrorsForValidationKeys(validationKeys, errors);
    case 'Study Info':
      validationKeys = [
        WevoValidationKeys.OwnerEmail,
        WevoValidationKeys.OwnerName,
        WevoValidationKeys.StudyName,
        WevoValidationKeys.Tags,
      ];
      return hasErrorsForValidationKeys(validationKeys, errors);
    case IntakeSectionNames.Audience:
      return hasCustomScreenerErrors(customScreenerErrors) || hasAudienceErrors(wevoErrors);
    case IntakeSectionNames.Build:
      return hasAssetErrors(assetErrors, { validatePrimer: false });
    case IntakeSectionNames.Details:
      validationKeys = [
        WevoValidationKeys.Goal,
        WevoValidationKeys.TaskToComplete,
        WevoValidationKeys.VisitorObjective,
      ];

      return (
        hasErrorsForValidationKeys(validationKeys, errors) ||
        hasCustomQuestionErrors(customQuestionErrors) ||
        hasPrimerError
      );
    // subsections
    case IntakeSubsectionNames.Asset:
      return hasAssetErrors(assetErrors, { validatePrimer: false });
    case IntakeSubsectionNames.Goal:
      validationKeys = [WevoValidationKeys.Goal];
      return hasErrorsForValidationKeys(validationKeys, errors);
    case IntakeSubsectionNames.PrimerAndTask:
      validationKeys = [WevoValidationKeys.TaskToComplete];
      const taskError = hasErrorsForValidationKeys(validationKeys, errors);
      return taskError || hasPrimerError;
    case IntakeSubsectionNames.Expectations:
      validationKeys = [WevoValidationKeys.VisitorObjective];
      return hasErrorsForValidationKeys(validationKeys, errors);
    case IntakeSubsectionNames.CustomQuestions:
      return hasCustomQuestionErrors(customQuestionErrors);

    default:
      return false;
  }
}

export function getSubsectionsWithErrors({
  subsectionNames,
  errors,
  customQuestionErrors,
  customScreenerErrors,
  assetErrors,
}) {
  if (isNil(subsectionNames)) {
    return [];
  }

  return subsectionNames.filter((name) =>
    hasSectionErrors({
      sectionName: name,
      errors,
      customQuestionErrors,
      customScreenerErrors,
      assetErrors,
    })
  );
}
