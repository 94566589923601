import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip, TextField, Tooltip, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import * as EmailValidator from 'email-validator';
import React, { useState } from 'react';
import { snackbar } from '../../../notifications';
import { CancelButton, ConfirmButton } from '../components/Buttons';
import useCreatePreviewSurveyLinkRequest from '../hooks/useCreatePreviewSurveyLinkRequest';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  inputField: {
    marginTop: (theme) => theme.spacing(1),
    marginBottom: (theme) => theme.spacing(1),
  },
  nextBtn: {
    padding: '8px 24px',
  },
  deleteIcon: {
    marginLeft: (theme) => theme.spacing(0.5),
    marginTop: (theme) => theme.spacing(0.2),
    height: '10px',
    color: (theme) => theme.palette.primary.main,
    stroke: (theme) => theme.palette.primary.main,
    strokeWidth: '0.5',
  },
  emailChip: {
    fontSize: '12px',
    marginRight: (theme) => theme.spacing(1),
    marginBottom: (theme) => theme.spacing(1),
    maxWidth: 390,
  },
  chipsArea: {
    maxHeight: 110,
    overflowY: 'auto',
    margin: (theme) => theme.spacing(1, 0),
  },
  container: {
    padding: 0,
  },
};

export const PreviewSurveyLinkRequestModal = ({
  wevoId,
  initialRecipientEmails,
  open,
  onConfirm,
  onClose,
}) => {
  const RECIPIENT_LIMIT = 12;

  const [isSuccess, setIsSuccess] = useState(false);
  const [recipientEmails, setRecipientEmails] = useState(initialRecipientEmails || []);
  const [recipientInputText, setRecipientEmailText] = useState('');
  const [error, setError] = useState('');

  const { mutate: createPreviewSurveyLinkRequest } = useCreatePreviewSurveyLinkRequest();

  const handleClose = () => {
    setRecipientEmails(initialRecipientEmails || []);
    setRecipientEmailText('');
    setError('');
    setIsSuccess(false);
    !!onClose && onClose();
  };

  const handleConfirm = () => {
    createPreviewSurveyLinkRequest(
      { wevoId, recipientEmails },
      {
        onSuccess: () => {
          setIsSuccess(true);
          !!onConfirm && onConfirm(recipientEmails);
        },
        onError: () => {
          snackbar.error('Something went wrong processing your request. Please contact us.');
        },
      }
    );
  };

  const handleRecipientInputTextChanged = (value) => {
    setRecipientEmailText(value);
    setError('');
    return;
  };

  const handleKeyPress = (ev) => {
    if (ev.key !== 'Enter') {
      return;
    }

    if (!isEmail(recipientInputText)) {
      setError('Please enter a valid email address.');
      return;
    }

    if (isInList(recipientInputText)) {
      setError('Email adddress is already in the recipient list.');
      return;
    }

    setRecipientEmails([...recipientEmails, recipientInputText]);
    setRecipientEmailText('');
    setError('');
  };

  const handleRemoveRecipient = (_item, indexToRemove) => {
    setRecipientEmails(recipientEmails.filter((_, idx) => idx !== indexToRemove));
  };

  const isEmail = (value) => {
    return EmailValidator.validate(value);
  };

  const isInList = (value) => {
    return recipientEmails.map((email) => email.toLowerCase()).includes(value.toLowerCase());
  };

  const dialogTitle = () => {
    return isSuccess ? 'Preview Link on Its Way!' : 'Get a Preview of Your Study';
  };

  const dialogDescription = () => {
    return isSuccess
      ? ''
      : 'Ready to see your study in action? Enter your email(s) below. Then press the return key.';
  };

  const dialogContent = () => {
    return (
      <>
        <Typography fontSize={18} fontWeight={600} mb={1}>
          {dialogTitle()}
        </Typography>
        <Typography fontSize={14}>{dialogDescription()}</Typography>
        <Box mb={3}></Box>
        {dialogMainContent()}
      </>
    );
  };

  const dialogMainContent = () => {
    const getEmailSeparator = (recipientEmails, index) => {
      if (recipientEmails.length === 1) {
        return '';
      }

      if (index === recipientEmails.length - 2) {
        return recipientEmails.length > 2 ? <>,&nbsp;and&nbsp;</> : <>&nbsp;and&nbsp;</>;
      }

      if (index < recipientEmails.length - 1) {
        return <>,&nbsp;</>;
      }

      return '';
    };

    if (isSuccess) {
      const recipients = recipientEmails.map((email, index) => (
        <Box component="span" sx={{ display: 'inline-block' }} key={email}>
          <a href={`mailto:${email}`}>{email}</a>
          {getEmailSeparator(recipientEmails, index)}
        </Box>
      ));

      return (
        <>
          <Typography mb={2}>
            A test link will be sent to {recipients}. Please allow up to one business day for it to arrive in
            your inbox.
          </Typography>

          <Typography variant="caption">
            If you would like to make any changes between now and when you recieve your preview survey link,
            please reach out to your Customer Success Manager (CSM) first. We'll need to update your study on
            our end before launch to ensure everything runs smoothly.
          </Typography>
        </>
      );
    }

    return (
      <>
        <TextField
          type="email"
          onChange={(ev) => handleRecipientInputTextChanged(ev.target.value)}
          onKeyPress={(ev) => handleKeyPress(ev)}
          value={recipientInputText}
          variant="outlined"
          fullWidth
          label="Recipient Email(s)"
          placeholder="Enter Email(s)"
          autoComplete="email"
          error={!!error}
          helperText={error || ''}
          disabled={recipientEmails?.length >= RECIPIENT_LIMIT}
          sx={{
            borderRadius: 4,
            '& .MuiInputBase-root': {
              fontSize: 14,
            },
          }}
        />
        <Box mb={2} />
        {Boolean(recipientEmails?.length) && (
          <div sx={styles.chipsArea}>
            {recipientEmails?.map((item, index) => (
              <Tooltip title={item.length > 50 ? item : ''} placement="right" key={item}>
                <Chip
                  sx={styles.emailChip}
                  label={item}
                  onDelete={() => handleRemoveRecipient(item, index)}
                  size="small"
                  deleteIcon={<CloseIcon sx={styles.deleteIcon} />}
                />
              </Tooltip>
            ))}
          </div>
        )}
      </>
    );
  };

  const dialogActions = () => {
    if (isSuccess) {
      return <ConfirmButton label={'Done'} onClick={handleClose} disabled={recipientEmails?.length === 0} />;
    }

    return (
      <>
        <CancelButton label={'Cancel'} onClick={handleClose} />
        <ConfirmButton label={'Done'} onClick={handleConfirm} disabled={recipientEmails?.length === 0} />
      </>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="preview survey link request dialog"
        PaperProps={{ sx: { width: '65%', py: 2, px: 1, borderRadius: 2 } }}>
        <DialogContent>{dialogContent()}</DialogContent>
        <Box mb={2} />
        <DialogActions>{dialogActions()}</DialogActions>
      </Dialog>
    </div>
  );
};
