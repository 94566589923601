import { Typography } from '@mui/material';

export const uuidInfo = () => {
  return (
    <Typography fontSize={12}>
      Your product's UUID, found in the{' '}
      <a
        style={{ color: '#276EB0' }}
        href="https://mastercard.sharepoint.com/sites/info_macatalog"
        target="_blank"
        rel="noreferrer">
        Mastercard Catalog
      </a>
      , is required to launch a CDS or a DQS study. All CDS and DQS studies are displayed within the applicable
      Studio Dashboards.
    </Typography>
  );
};
