import { Box } from '@mui/material';
import { isNil } from 'lodash';
import { DeleteButton } from '../../../components/Buttons';
import { ValidationErrorNotice } from '../../../components/Notice';
import { isCustomSurveyType } from '../../helpers/wevo';
import { CustomQuestionScopeType, CustomQuestionTypes, ValidationKeys } from '../constants';
import {
  areScopesEqual,
  defaultScopes,
  getDefaultsForQuestionType,
  getScopeType,
  isInvalidScope,
  serializeErrors,
} from '../helpers';
import CustomQuestionTypeSelector from './CustomQuestionTypeSelector';
import ScopeAssetSelector from './question-scopes/ScopeAssetSelector';
import ScopeTypeSelector from './question-scopes/ScopeTypeSelector';
import CustomQuestionDetails from './question-types';

function IntakeCustomQuestion({ wevo, customQuestion, onDelete, isDeleting, onChange, errors }) {
  const isCustomSurvey = isCustomSurveyType(wevo);
  const questionType = customQuestion?.question?.type || 'text';

  const scopeType = getScopeType(customQuestion);

  const handleQuestionTypeChanged = (newQuestionType) => {
    const updates = { randomization: null, ...getDefaultsForQuestionType(newQuestionType) };

    // when changing to heatmap custom question, if the current scope is page level, force switch to default step scopes
    // the reason for this is that heatmap does not support page scope
    if (newQuestionType === CustomQuestionTypes.Heatmap && scopeType === CustomQuestionScopeType.Page) {
      updates.scopes = defaultScopes({ wevo, scopeType: CustomQuestionScopeType.Step });
    }

    if (
      isCustomSurvey &&
      customQuestion?.scopes?.length <= 1 &&
      !customQuestion?.scopes?.find((scope) => String(scope.wevoPageId) === String(wevo?.pages?.[0]?.id))
    ) {
      updates.scopes = (customQuestion?.scopes ?? [])
        .filter((scope) => !isInvalidScope(scope))
        .concat([{ wevoPageId: String(wevo?.pages?.[0]?.id), stepId: null }]);
    }

    onChange({ customQuestion, updateFields: updates });
  };

  const handleScopeTypeChanged = ({ customQuestion, newScopeType }) => {
    const newScopes = defaultScopes({ wevo, scopeType: newScopeType });

    if (!isNil(newScopes)) {
      onChange({ customQuestion, updateFields: { scopes: newScopes } });
    }
  };

  const handleScopeAssetsChanged = ({ customQuestion, scope, isSelected }) => {
    const currentScopes = customQuestion.scopes;
    let newScopes;

    if (isSelected) {
      newScopes = currentScopes.concat([scope.scope]);
    } else {
      newScopes = currentScopes.filter((existingScope) => !areScopesEqual(existingScope, scope.scope));
    }

    // safety precaution in case we try to send bad data to the backend
    newScopes = newScopes.filter((scope) => !isInvalidScope(scope));

    onChange({ customQuestion, updateFields: { scopes: newScopes } });
  };

  const isPageScopeDisabled = [CustomQuestionTypes.Heatmap].includes(questionType);
  const isStepScopeDisabled = false; // currently no restrictions around step scope

  return (
    <Box>
      {errors?.[ValidationKeys.UnsupportedQuestionType]?.length > 0 && (
        <Box mb={3}>
          <ValidationErrorNotice message={serializeErrors(errors[ValidationKeys.UnsupportedQuestionType])} />
        </Box>
      )}
      <CustomQuestionTypeSelector
        value={questionType}
        onChange={handleQuestionTypeChanged}
        scopeType={scopeType}
      />
      <Box mb={4} />
      <CustomQuestionDetails wevo={wevo} customQuestion={customQuestion} onChange={onChange} errors={errors} />
      <Box mb={4} />
      {/* Custom surveys are always page scoped for now */}
      {!isCustomSurvey && (
        <>
          <ScopeTypeSelector
            wevo={wevo}
            customQuestion={customQuestion}
            value={scopeType}
            onChange={handleScopeTypeChanged}
            isPageScopeDisabled={isPageScopeDisabled}
            isStepScopeDisabled={isStepScopeDisabled}
          />
          <Box mb={4} />
          {errors?.scopes?.length > 0 && (
            <Box my={1}>
              <ValidationErrorNotice message={serializeErrors(errors.scopes)} />
            </Box>
          )}
          <ScopeAssetSelector
            wevo={wevo}
            customQuestion={customQuestion}
            scopeType={scopeType}
            onChange={handleScopeAssetsChanged}
            errors={errors}
          />
          <Box mb={5} />
        </>
      )}
      <DeleteButton
        isLoading={isDeleting}
        disabled={isDeleting}
        onClick={() => onDelete && onDelete({ customQuestion })}
      />
    </Box>
  );
}

export default IntakeCustomQuestion;
