import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { GridLoader } from 'react-spinners';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as SendIcon } from '../../../src/assets/send.svg';
import assistantAvatarIcon from '../../assets/wevo-gray-small-logo.png';
import { customRenderers } from '../../helpers';
import {
  CannotChatReason,
  ChatDelayThresholdSeconds,
  TaskStatus,
} from '../../modules/automated-insights/constants';
import { isAuthenticated } from '../../modules/user/helpers';
import { snackbar } from '../../notifications';
import { TrackEvent } from '../analytics';
import ChatInfo from './ChatInfo';
import useFetchChatMessageStatus from './hooks/useFetchChatMessageStatus';
import useFetchChatMessages from './hooks/useFetchChatMessages';
import useSendChatMessage from './hooks/useSendChatMessage';

// Hardcoded value from V2 API to indicate whether the message was sent by the user or the assistant.
const ASSISTANT_USER = 'Assistant';

const PENDING_MESSAGE_ID = 'pending-message-id';

// Extract initials from the name
const getInitials = (name) => {
  const names = name.split(' ');
  const initials = names.map((n) => n.charAt(0)).join('');
  return initials.slice(0, 2).toUpperCase();
};

const Avatar = ({ children, size = 28, backgroundColor = '#efefef' }) => {
  const avatarStyle = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor,
    color: 'black',
    fontSize: `${size * 0.4}px`,
  };

  return <div style={avatarStyle}>{children}</div>;
};

const UserAvatar = ({ name }) => {
  return <Avatar>{getInitials(name)}</Avatar>;
};

const AssistantAvatar = () => {
  return (
    <Avatar backgroundColor="#ffffff">
      <img src={assistantAvatarIcon} alt="WEVO assistant logo" style={{ maxHeight: '12px' }} />
    </Avatar>
  );
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
    resize: none;
    width: 100%;
    font-family: ${theme.typography.fontFamily};
    font-size: 0.875rem;
    font-weight: 400;
    font-style: italic;
    line-height: 1.5;
    padding: 12px;
    border-radius: 16px;
    border: 1px solid #9e9e9e;
    &:hover {
      border-color: ${grey[600]};
    }

    &:focus {
      border-color: ${grey[500]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

/**
 * Determines whether message was sent by the user or the assistant.
 */
function isAssistantMessage(message) {
  return message?.user === ASSISTANT_USER && !message?.userId;
}

const styles = {
  userMessageContainer: {
    paddingTop: 1.5,
    paddingBottom: 2,
    paddingLeft: 3,
    paddingRight: 3,
    backgroundColor: '#fff',
    fontSize: '14px',
  },
  assistantMessageContainer: {
    paddingTop: 1.5,
    paddingBottom: 2,
    paddingLeft: 3,
    paddingRight: 3,
    backgroundColor: '#f7f7f7',
    fontSize: '14px',
  },
};

const QuestionsChat = ({
  sessionId,
  selectedSegment,
  ownerUserId,
  chatUserId,
  sessionType,
  showCarousel = false,
  selectedQuantTheme = null,
  setChatMessages,
  setShowExpandCollapse,
  openChatMessages,
  handleOpenMessageAccordion,
}) => {
  const { track } = useAnalytics();

  const [messages, setMessages] = useState([]);
  const [chatStatus, setChatStatus] = useState({
    canChat: false,
    shouldPoll: false,
    pendingChat: null,
    lastMessageStatus: null,
    reason: null,
  });

  useEffect(() => {
    setChatMessages(messages);
  }, [messages, setChatMessages]);

  const [isChatDelayed, setIsChatDelayed] = useState(false);

  const {
    data: chatData,
    isLoading,
    refetch: fetchChatMessages,
  } = useFetchChatMessages({
    sessionId: sessionId,
    segmentId: selectedSegment?.id,
  });

  const { mutate: sendChatMessage, isLoading: isSendingMessage } = useSendChatMessage();

  const messagesEndRef = useRef(null);
  const [inputValue, setInputValue] = useState('');

  const limitExceeded = chatStatus?.reason === CannotChatReason.LimitExceeded;
  const canSend = chatStatus?.canChat && !chatStatus?.pendingChat && !isSendingMessage && !limitExceeded;
  const noMessages = messages?.length < 1;
  const showChatTextbox = !!chatUserId;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  // Ensure that the chat window is scrolled to the bottom when new messages are added.
  useEffect(scrollToBottom, [messages, isChatDelayed]);

  useEffect(() => {
    if (chatData?.status && chatData?.chat) {
      setChatStatus(chatData?.status);
      setMessages(chatData?.chat ?? []);
      track(TrackEvent.PULSE_CHAT_OPEN, { sessionId, segmentId: selectedSegment?.id });
    }
  }, [chatData, selectedSegment?.id, sessionId, track]);

  const { refetch: fetchChatMessageStatus } = useFetchChatMessageStatus(
    { sessionId, segmentId: selectedSegment?.id },
    {
      onSuccess: (data) => {
        if (!data) {
          return;
        }

        setChatStatus(data?.status);

        // Once server tells us to stop polling, we can fetch the updated messages.
        if (!data?.status?.shouldPoll) {
          fetchChatMessages({ sessionId, segmentId: selectedSegment?.id });
          track(TrackEvent.PULSE_CHAT_RECEIVED, { sessionId, segmentId: selectedSegment?.id });
        }

        // Handle delay status of chat
        const timeElapsed = timeElapsedSinceChatSubmitted(data?.status);
        const chatDelayStatus = _.isNil(timeElapsed) ? false : timeElapsed > ChatDelayThresholdSeconds;

        if (chatDelayStatus !== isChatDelayed) {
          setIsChatDelayed(chatDelayStatus);
        }
      },
      onError: () => {
        setChatStatus({ ...chatStatus, shouldPoll: false });
      },
      refetchInterval: chatStatus?.shouldPoll ? 5000 : null,
      refetchIntervalInBackground: true,
    }
  );

  const timeElapsedSinceChatSubmitted = (chatStatus) => {
    const pendingUserChat = chatStatus?.pendingChat?.[0];

    if (!pendingUserChat || !pendingUserChat?.time) {
      return;
    }

    return (new Date() - new Date(pendingUserChat.time)) / 1000;
  };

  const submitChatQuestion = (question) => {
    if (!canSend) {
      if (limitExceeded) {
        track(TrackEvent.PULSE_CHAT_ATTEMPTS_LIMIT_EXCEEDED, { sessionId, segmentId: selectedSegment?.id });
        snackbar.warning('You have exceeded the limit of messages in the last hour. Please try again later.');
      } else {
        track(TrackEvent.PULSE_CHAT_ATTEMPTS_MULTIPLE_MESSAGES, { sessionId, segmentId: selectedSegment?.id });
        snackbar.warning('Only one message can be sent at a time');
      }
      return;
    }

    const newMessage = question.trim();
    if (!newMessage) {
      return;
    }

    setMessages([...messages, { id: PENDING_MESSAGE_ID, message: newMessage }]);

    track(TrackEvent.PULSE_CHAT_SENT, { sessionId, segmentId: selectedSegment?.id });
    sendChatMessage(
      { sessionId, segmentId: selectedSegment?.id, message: newMessage },
      {
        onSuccess: (data) => {
          setChatStatus(data?.status);

          // Add the pending chats to the list of messages.
          const newMessages = data?.status?.pendingChat ?? [];

          if (newMessage?.[1]) {
            setMessages(messages.filter((message) => message.id !== PENDING_MESSAGE_ID).concat(newMessages));
          }

          // Start polling for message status until server tells us to stop polling (when shouldPoll === false)
          fetchChatMessageStatus();
        },
        onError: (err) => {
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error sending message');
        },
      }
    );

    setInputValue('');
  };

  const handleInputChange = (input) => {
    setInputValue(input);
  };

  const handleSend = () => {
    submitChatQuestion(inputValue);
  };

  if (isLoading) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  } else {
    setShowExpandCollapse(Boolean(messages?.length));
  }

  if (
    !isLoading &&
    !chatStatus?.canChat &&
    !chatStatus?.pendingChat &&
    ![CannotChatReason.LimitExceeded, CannotChatReason.NotOwner].includes(chatStatus?.reason)
  ) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingX: '5%',
        }}>
        <Typography color="text.primary" textAlign="center">
          Chat will be available soon after insights have been generated.
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container direction="column" style={{ height: '100%' }}>
      <Grid
        item
        xs
        className="lightContainer"
        sx={{
          display: noMessages ? 'flex' : 'block',
          overflowY: 'auto',
          scrollbarWidth: 'none',
        }}>
        {messages?.map((message, index) => (
          <div key={`automated-insight-session-chat-message-${message?.id}`}>
            {isAssistantMessage(message) && (
              <Box sx={styles.assistantMessageContainer}>
                <Accordion
                  disableGutters
                  expanded={openChatMessages?.[index] || false}
                  sx={{
                    backgroundColor: 'transparent',
                    maxHeight: 47,
                    overflow: 'hidden',
                    '&.Mui-expanded': {
                      maxHeight: '',
                    },
                  }}
                  elevation={0}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          marginX: -1.25,
                          marginY: 3,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleOpenMessageAccordion(index, !openChatMessages?.[index]);
                        }}
                      />
                    }
                    sx={{
                      alignItems: 'flex-start',
                      marginTop: '-12px',
                      '&.MuiAccordionSummary-root': {
                        userSelect: 'auto',
                      },
                      '& .MuiAccordionSummary-content': {
                        cursor: 'default',
                      },
                    }}>
                    <Grid container sx={{ flexWrap: 'nowrap' }}>
                      <Grid item xs={1} sx={{ minWidth: '40px' }}>
                        <Box sx={{ paddingTop: '12px' }}>
                          <AssistantAvatar />
                        </Box>
                      </Grid>
                      <Grid item xs={11} sx={{ marginRight: 0.5 }}>
                        {message?.status !== TaskStatus.Failed && message?.message && (
                          <ReactMarkdown customRenderers={customRenderers}>{message?.message}</ReactMarkdown>
                        )}
                        {message?.status !== TaskStatus.Failed && !message?.message && (
                          <Grid container sx={{ paddingTop: 1, gap: 2, alignItems: 'center' }}>
                            <Grid item xs={1}>
                              <GridLoader size={8} color="#aaa" />
                            </Grid>
                            <Grid item xs={10}>
                              {isChatDelayed && (
                                <Alert severity="warning">
                                  <Typography>
                                    We're currently experiencing elevated demand. Response times may be longer
                                    than usual. Please expect delays or try again later.
                                  </Typography>
                                </Alert>
                              )}
                            </Grid>
                          </Grid>
                        )}
                        {message?.status === TaskStatus.Failed && (
                          <Alert severity="warning">Error sending message, please try again</Alert>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                </Accordion>
              </Box>
            )}
            {!isAssistantMessage(message) && (
              <Box sx={styles.userMessageContainer}>
                <Accordion
                  disableGutters
                  expanded={openChatMessages?.[index] || false}
                  sx={{
                    backgroundColor: 'transparent',
                    maxHeight: 47,
                    overflow: 'hidden',
                    '&.Mui-expanded': {
                      maxHeight: '',
                    },
                  }}
                  elevation={0}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          marginX: -1.25,
                          marginY: 3,
                        }}
                        onClick={() => {
                          handleOpenMessageAccordion(index, !openChatMessages?.[index]);
                        }}
                      />
                    }
                    sx={{
                      alignItems: 'flex-start',
                      marginTop: '-12px',
                      '&.MuiAccordionSummary-root': {
                        userSelect: 'auto',
                      },
                      '& .MuiAccordionSummary-content': {
                        cursor: 'default',
                      },
                    }}>
                    <Grid container sx={{ flexWrap: 'nowrap' }}>
                      <Grid item xs={1} sx={{ minWidth: '40px' }}>
                        <Tooltip title={isAuthenticated() ? message?.user ?? '' : ''}>
                          <Box sx={{ paddingTop: '12px' }}>
                            {message?.user === 'Anonymous' ? (
                              <Tooltip title="Anonymous">
                                <Box>
                                  <UserAvatar name={message?.user || 'User'} />
                                </Box>
                              </Tooltip>
                            ) : (
                              <UserAvatar name={message?.user || 'User'} />
                            )}
                          </Box>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={11} sx={{ marginRight: 0.5 }}>
                        <ReactMarkdown customRenderers={customRenderers}>{message?.message}</ReactMarkdown>
                        {message?.time && (
                          <Typography fontSize={10} color={'#9C9C9C'}>
                            {`${new Date(message?.time).toLocaleTimeString(/*locale=*/ undefined, {
                              hour: '2-digit',
                              minute: '2-digit',
                            })}
                      on
                      ${new Date(message?.time).toLocaleDateString(/*locale=*/ undefined, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                      `}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                </Accordion>
              </Box>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </Grid>
      <Grid item>
        <Box sx={{ marginTop: 'auto' }}>
          <ChatInfo
            sessionId={sessionId}
            ownerUserId={ownerUserId}
            chatUserId={chatUserId}
            currentInput={inputValue}
            onQuestionSelection={handleInputChange}
            showCarousel={showCarousel}
            quantCategory={selectedQuantTheme}
            sessionType={sessionType}
            prompts={chatData?.prompts}
            showChatTextbox={showChatTextbox}
            canSend={canSend}
            submitChat={submitChatQuestion}
          />
        </Box>
      </Grid>
      {showChatTextbox && (
        <Grid
          item
          container
          sx={{
            paddingLeft: 1,
            alignItems: 'center',
            boxShadow: '0px -1px 5px 0px rgba(0, 0, 0, 0.20)',
          }}>
          <Grid item sx={{ flexGrow: 1 }}>
            <Box sx={{ width: '100%', mt: 1 }}>
              <StyledTextarea
                sx={{
                  fontStyle: inputValue.length > 0 && 'initial',
                }}
                aria-label="Ask questions about your data"
                disabled={!chatUserId}
                value={inputValue}
                minRows={1}
                onChange={(e) => handleInputChange(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault(); // prevent the default behavior (newline)
                    handleSend();
                  }
                }}
                maxLength={2000}
                placeholder="Ask questions about your data"
              />
            </Box>
          </Grid>
          <Grid item>
            <IconButton onClick={handleSend} disabled={!canSend}>
              {isSendingMessage && <CircularProgress size={24} style={{ color: '#ccc' }} />}
              {!isSendingMessage && <SendIcon height={20} width={20} />}
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default QuestionsChat;
