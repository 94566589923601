import { useIsMutating, useQuery } from 'react-query';
import axios from '../../../api.js';
import { CustomScreenerMutationKeys } from '../../../modules/intake/constants.js';

export const getCustomScreenerQuestions = async ({ wevoId }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/filters`,
    method: 'GET',
  });
  return data?.filters;
};

const fetchCustomScreenerQuestions = async ({ queryKey: [key, { wevoId }] }) => {
  if (!wevoId) {
    return [];
  }

  return getCustomScreenerQuestions({ wevoId });
};

export default function useFetchCustomScreenerQuestions({ wevoId }, options) {
  const numOngoingMutations = useIsMutating({
    predicate: (mutation) =>
      Object.values(CustomScreenerMutationKeys).includes(mutation.options.mutationKey) &&
      mutation.options.variables.wevoId === wevoId,
  });
  return useQuery(['customScreeners', { wevoId }], fetchCustomScreenerQuestions, {
    notifyOnChangeProps: 'tracked',
    enabled: numOngoingMutations === 0, // don't refetch this query while relevant mutations are ongoing,
    ...(options || {}),
  });
}
