import { Box } from '@mui/material';
import { isNil } from 'lodash';
import { default as React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as InfoIconFilled } from '../../../../../assets/info-icon-filled.svg';
import { MetricTypes } from '../../../../../modules/intake/constants';
import { getUserCustomizations } from '../../../../../modules/user/selectors';
import { TrackEvent } from '../../../../analytics';
import { CustomTextField, IntakeTooltip } from '../../../components';
import { ValidationErrorNotice } from '../../../components/Notice';
import DebouncedInput from '../../../edit/DebouncedInput';
import { uuidInfo } from '../../customers/mastercard';
import { isMastercardUUID, isValidMastercardUUIDInput } from '../../helpers/tags';
import { serializeErrors } from '../../helpers/validation/shared';
import { ValidationKeys } from '../../helpers/validation/wevo';
import FieldRow from '../shared/FieldRow';
import Tags from './Tags';
import TeamEmails from './TeamEmails';

const StudyInfoSection = ({
  wevo,
  errors,
  onUUIDChanged,
  onStudyNameChanged,
  onOwnerNameChanged,
  onOwnerEmailChanged,
  onEmailsToNotifyChanged,
}) => {
  const userCustomizations = useSelector(getUserCustomizations);
  const { track } = useAnalytics();

  const isMastercard = Boolean(userCustomizations?.isMastercard);
  const metricType = wevo?.metricType;
  const isMastercardMetric = [MetricTypes.MastercardCds, MetricTypes.MastercardDqs].includes(metricType);
  const uuidTag = wevo?.tags?.find((tag) => isMastercardUUID(tag?.name))?.name || '';

  // hack to provide user feedback because we don't actually change the UUID tag unless it is valid or empty
  const [isValidUUID, setIsValidUUID] = useState(isValidMastercardUUIDInput(uuidTag));
  const [studyName, setStudyName] = useState(wevo?.name);

  // this updates the study name because there could potentially be
  // two components mounted at the same time that could change the name
  useEffect(() => {
    setStudyName(wevo?.name);
  }, [wevo?.name]);

  const handleUUIDChanged = (value) => {
    onUUIDChanged && onUUIDChanged({ newUUID: value });
    setIsValidUUID(isValidMastercardUUIDInput(value));
  };

  // save new name when clicking outside of the text field
  const handleNameBlur = async () => {
    if (studyName === wevo?.name) {
      return;
    }

    let newName = studyName;
    if (!studyName?.length) {
      newName = wevo?.name;
      setStudyName(newName);
    }

    await onStudyNameChanged({ name: newName });
  };

  const handleNameClick = () => {
    track(TrackEvent.CLICKED_STUDY_NAME_FROM_REVIEW_PAGE, {
      wevoId: wevo?.id,
    });
  };

  const renderMastercardUUIDError = () => {
    if (!isMastercardMetric) {
      return <></>;
    }

    if (!isValidUUID) {
      return (
        <Box my={1}>
          <ValidationErrorNotice message="Please enter a valid uuid." />
        </Box>
      );
    } else if (errors?.[ValidationKeys.Tags]?.length > 0) {
      return (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(errors[ValidationKeys.Tags])} />
        </Box>
      );
    }

    return <></>;
  };

  return (
    <>
      <FieldRow label={'Study name*'}>
        <CustomTextField
          required
          disabled={isNil(wevo?.name)}
          value={studyName || ''}
          onFocus={handleNameClick}
          onChange={(ev) => setStudyName(ev.target.value)}
          onBlur={handleNameBlur}
          name="StudyName"
          error={Boolean(errors?.studyName?.message?.length)}
          helperText={Boolean(errors?.studyName?.message?.length) ? errors?.studyName?.message : ''}
        />
        {errors?.[ValidationKeys.StudyName]?.length > 0 && (
          <Box my={1}>
            <ValidationErrorNotice message={serializeErrors(errors[ValidationKeys.StudyName])} />
          </Box>
        )}
      </FieldRow>
      {isMastercard && (
        <FieldRow label={isMastercardMetric ? 'UUID*' : 'UUID'} divider={true}>
          <IntakeTooltip title={uuidInfo()} arrow={true} placement={'right'}>
            <DebouncedInput
              value={uuidTag}
              onChange={handleUUIDChanged}
              debounceMs={500}
              renderInput={({ value, onChange }) => (
                <CustomTextField
                  value={value}
                  sx={{
                    background: 'white',
                    borderRadius: 4,
                    '& .MuiInputBase-root': {
                      fontSize: 13,
                    },
                  }}
                  onChange={onChange}
                />
              )}
            />
          </IntakeTooltip>
          {renderMastercardUUIDError()}
        </FieldRow>
      )}
      <FieldRow
        label={'Tags'}
        icon={
          <IntakeTooltip
            title={
              "Keywords to categorize and group related studies (e.g., 'Mobile App', 'Onboarding', 'Q1 2024')."
            }
            arrow={true}
            placement={'right'}>
            <InfoIconFilled height="16px" width="16px" />
          </IntakeTooltip>
        }
        divider={true}>
        <Tags wevo={wevo} userCustomizations={userCustomizations} />
      </FieldRow>
      <FieldRow
        label={'Owner*'}
        icon={
          <IntakeTooltip title={'Person responsible for the study.'} arrow={true} placement={'right'}>
            <InfoIconFilled height="16px" width="16px" />
          </IntakeTooltip>
        }
        divider={true}>
        <DebouncedInput
          value={wevo?.ownerName || ''}
          onChange={(value) => onOwnerNameChanged({ ownerName: value })}
          debounceMs={500}
          renderInput={({ value, onChange }) => (
            <CustomTextField
              required
              disabled={isNil(wevo?.ownerName)}
              value={value}
              onChange={onChange}
              name="OwnerName"
              error={Boolean(errors?.ownerName?.message?.length)}
              helperText={Boolean(errors?.ownerName?.message?.length) ? errors?.ownerName?.message : ''}
            />
          )}
        />
        {errors?.[ValidationKeys.OwnerName]?.length > 0 && (
          <Box my={1}>
            <ValidationErrorNotice message={serializeErrors(errors[ValidationKeys.OwnerName])} />
          </Box>
        )}
      </FieldRow>
      <FieldRow
        label={'Owner email*'}
        icon={
          <IntakeTooltip
            title={'Primary contact email for study-related communications and updates.'}
            arrow={true}
            placement={'right'}>
            <InfoIconFilled height="16px" width="16px" />
          </IntakeTooltip>
        }
        divider={true}>
        <DebouncedInput
          value={wevo?.ownerEmail || ''}
          onChange={(value) => onOwnerEmailChanged({ ownerEmail: value })}
          debounceMs={500}
          renderInput={({ value, onChange }) => (
            <CustomTextField
              required
              disabled={isNil(wevo?.ownerEmail)}
              value={value}
              onChange={onChange}
              name="OwnerEmail"
              error={Boolean(errors?.ownerEmail?.message?.length)}
              helperText={Boolean(errors?.ownerEmail?.message?.length) ? errors?.ownerEmail?.message : ''}
            />
          )}
        />
        {errors?.[ValidationKeys.OwnerEmail]?.length > 0 && (
          <Box my={1}>
            <ValidationErrorNotice message={serializeErrors(errors[ValidationKeys.OwnerEmail])} />
          </Box>
        )}
      </FieldRow>
      <FieldRow
        label={'Team email'}
        icon={
          <IntakeTooltip
            title={'Additional team members who will receive study results.'}
            arrow={true}
            placement={'right'}>
            <InfoIconFilled height="16px" width="16px" />
          </IntakeTooltip>
        }>
        <TeamEmails wevo={wevo} onEmailsToNotifyChanged={onEmailsToNotifyChanged} />
      </FieldRow>
    </>
  );
};

export default StudyInfoSection;
