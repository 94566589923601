import { useQuery } from 'react-query';
import axios from '../../../api.js';

const getProPricing = async () => {
  const response = await axios({
    url: `/api/v3/pricing`,
    method: 'GET',
  });
  return response.data.pricing;
};

const fetchProPricing = async ({ queryKey: [key] }) => {
  return getProPricing();
};

export default function useFetchProPricing(options) {
  return useQuery(['userPricingData'], fetchProPricing, {
    notifyOnChangeProps: 'tracked',
    ...(options ?? {}),
  });
}
