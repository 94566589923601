import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as ComparePagesIcon } from '../../../../../assets/compare-pages-dynamic.svg';
import { ReactComponent as JourneyIcon } from '../../../../../assets/journey-dynamic.svg';
import { ReactComponent as SinglePageIcon } from '../../../../../assets/single-page-dynamic.svg';
import { ReactComponent as UsabilityIcon } from '../../../../../assets/usability-dynamic.svg';
import { ReactComponent as CompareJourneysIcon } from '../../../../../assets/journey-compare-icon.svg';

import { TestTypes } from '../../../../../modules/intake/constants';
import SelectableCard from '../../SelectableCard';
import DiscardTestTypeDialog from './DiscardTestTypeDialog';
import { getUserCustomizations } from '../../../../../modules/user/selectors';

const useStyles = makeStyles((theme) => ({
  journeyIcon: {
    width: '43px',
    height: '43px',
    display: 'initial', // fixes a weird safari styling issue related to display: inline-flex
  },
  singlePageIcon: {
    width: '43px',
    height: '43px',
    display: 'initial', // fixes a weird safari styling issue related to display: inline-flex
  },
  compareIcon: {
    width: '43px',
    height: '43px',
    display: 'initial', // fixes a weird safari styling issue related to display: inline-flex
  },
}));

function TypeSelection(props) {
  const { type, handleTypeSelect, canRunLite, compareJourneysEnabled } = props;

  const classes = useStyles({ type });

  const userCustomizations = useSelector(getUserCustomizations);
  const isMastercard = Boolean(userCustomizations?.isMastercard);
  const liteDescription = `Rapid insights in 1-5 days (depending on your audience)${
    !isMastercard ? ', just 0.25 credits.' : '.'
  }`;
  return (
    <Grid container spacing={1} justifyContent="center">
      {canRunLite && (
        <Grid item xs>
          <SelectableCard
            title="Lite"
            description={liteDescription}
            icon={<UsabilityIcon className={classes.journeyIcon} />}
            onClick={() => handleTypeSelect(TestTypes.Lite)}
            isSelected={type === TestTypes.Lite}
            disabled={false}
          />
        </Grid>
      )}
      <Grid item xs>
        <SelectableCard
          title="Journey"
          description="A sequence of multiple pages. Live or a prototype."
          icon={<JourneyIcon className={classes.journeyIcon} />}
          onClick={() => handleTypeSelect(TestTypes.Journey)}
          isSelected={type === TestTypes.Journey}
          disabled={false}
        />
      </Grid>
      <Grid item xs>
        <SelectableCard
          title="Page"
          description="A single page. Live or a design."
          icon={<SinglePageIcon className={classes.singlePageIcon} />}
          onClick={() => handleTypeSelect(TestTypes.SinglePage)}
          isSelected={type === TestTypes.SinglePage}
          disabled={false}
        />
      </Grid>
      <Grid item xs>
        <SelectableCard
          title="Compare Pages"
          description="Analyze and compare multiple pages (up to 6)"
          icon={<ComparePagesIcon className={classes.compareIcon} />}
          onClick={() => handleTypeSelect(TestTypes.ComparePages)}
          isSelected={type === TestTypes.ComparePages}
          disabled={false}
        />
      </Grid>
      {compareJourneysEnabled && (
        <Grid item xs>
          <SelectableCard
            title="Compare Journeys"
            description="Analyze and compare multiple journeys (up to 3)"
            icon={<CompareJourneysIcon className={classes.compareIcon} />}
            onClick={() => handleTypeSelect(TestTypes.CompareJourneys)}
            isSelected={type === TestTypes.CompareJourneys}
            disabled={false}
          />
        </Grid>
      )}
    </Grid>
  );
}

TypeSelection.propTypes = {
  type: PropTypes.oneOf(Object.values(TestTypes)).isRequired,
  handleTypeSelect: PropTypes.func.isRequired,
  canRunUsability: PropTypes.bool,
  canRunLite: PropTypes.bool,
  compareJourneysEnabled: PropTypes.bool,
};

function TestTypeSelectionSection({
  isDQS,
  isCDS,
  canRunUsability,
  canRunLite,
  compareJourneysEnabled,
  testType,
  testTypeToSetOnConfirm,
  handleTestTypeSelect,
  showDiscardTestTypeDialog,
  discardTestType,
  toggleShowTestTypeDialog,
}) {
  const discardTestTypeMessage = useMemo(() => {
    const journeyToCompareJourneySwitch =
      [TestTypes.Journey, TestTypes.Usability, TestTypes.Lite].includes(testType) &&
      [TestTypes.CompareJourneys].includes(testTypeToSetOnConfirm);
    if (journeyToCompareJourneySwitch) {
      let currentTestType;
      if (testType === TestTypes.Lite) {
        currentTestType = 'Lite';
      } else if (testType === TestTypes.Usability) {
        currentTestType = 'Usability';
      } else {
        currentTestType = 'Journey';
      }
      return `Switching between ${currentTestType} and Compare Journey will clear any test type specific selections. Is that okay?`;
    }
    return 'Switching between Journey and Page test types will clear any information you’ve already selected. Is that okay?';
  }, [testType, testTypeToSetOnConfirm]);
  return (
    <>
      {/* Test Type Section */}
      {isDQS || isCDS ? (
        <Typography variant="h3" gutterBottom align="center" sx={{ mb: 4, width: '100%' }}>
          Describe Your Task
        </Typography>
      ) : (
        <>
          <Typography variant="h3" gutterBottom align="center" sx={{ mb: 4, width: '100%' }}>
            Select Your Test Type
          </Typography>

          <Grid item xs={12}>
            <TypeSelection
              type={testType}
              handleTypeSelect={handleTestTypeSelect}
              canRunUsability={canRunUsability}
              canRunLite={canRunLite}
              compareJourneysEnabled={compareJourneysEnabled}
            />
          </Grid>
          <DiscardTestTypeDialog
            open={showDiscardTestTypeDialog}
            discardTestType={discardTestType}
            closeCallback={toggleShowTestTypeDialog}
            message={discardTestTypeMessage}
          />
        </>
      )}
    </>
  );
}

TestTypeSelectionSection.propTypes = {
  isDQS: PropTypes.bool.isRequired,
  isCDS: PropTypes.bool.isRequired,
  canRunUsability: PropTypes.bool.isRequired,
  testType: PropTypes.string.isRequired,
  handleTestTypeSelect: PropTypes.func,
  showDiscardTestTypeDialog: PropTypes.bool,
  discardTestType: PropTypes.func,
  toggleShowTestTypeDialog: PropTypes.func,
  compareJourneysEnabled: PropTypes.bool,
};

export { TypeSelection, TestTypeSelectionSection };
