import { Checkbox } from '@mui/material';
import { ReactComponent as IntakeCheckboxBlueIcon } from '../../../assets/intake-checkbox-icon-checked-blue.svg';
import { ReactComponent as IntakeCheckboxUncheckedBlueIcon } from '../../../assets/intake-checkbox-icon-unchecked-blue.svg';

function CustomCheckbox(props) {
  return (
    <Checkbox icon={<IntakeCheckboxUncheckedBlueIcon />} checkedIcon={<IntakeCheckboxBlueIcon />} {...props} />
  );
}

export default CustomCheckbox;
