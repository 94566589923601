import { CustomQuestionTypes } from '../../constants';
import CustomClickMapQuestion from './CustomClickMapQuestion';
import CustomQualitativeQuestion from './CustomQualitativeQuestion';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import MultipleSelectionQuestion from './MultipleSelectionQuestion';

function CustomQuestionDetails({ wevo, customQuestion, errors, onChange }) {
  const questionType = customQuestion?.question?.type;

  switch (questionType) {
    case CustomQuestionTypes.CustomQualitative:
      return (
        <CustomQualitativeQuestion
          wevo={wevo}
          customQuestion={customQuestion}
          onCustomQuestionChanged={onChange}
          errors={errors}
        />
      );
    case CustomQuestionTypes.MultipleChoice:
    case CustomQuestionTypes.FiveLikertScale:
    case CustomQuestionTypes.SevenLikertScale:
    case CustomQuestionTypes.YesNoTrueFalse:
    case CustomQuestionTypes.RankOrder:
      return (
        <MultipleChoiceQuestion
          wevo={wevo}
          customQuestion={customQuestion}
          onCustomQuestionChanged={onChange}
          errors={errors}
        />
      );
    case CustomQuestionTypes.MultiSelect:
      return (
        <MultipleSelectionQuestion
          wevo={wevo}
          customQuestion={customQuestion}
          onCustomQuestionChanged={onChange}
          errors={errors}
        />
      );
    case CustomQuestionTypes.Heatmap:
      return (
        <CustomClickMapQuestion
          wevo={wevo}
          customQuestion={customQuestion}
          onCustomQuestionChanged={onChange}
          errors={errors}
        />
      );
    default:
      return <></>;
  }
}
export {
  CustomClickMapQuestion,
  CustomQualitativeQuestion,
  MultipleChoiceQuestion,
  MultipleSelectionQuestion,
};

export default CustomQuestionDetails;
