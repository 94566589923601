import { isNil } from 'lodash';
import AssetUploadCard from '../../components/AssetUploadCard';
import ImageCard from '../../components/ImageCard';

function PrimerImage({ primer, onFileUploaded, isUploading, isDeletingPrimer }) {
  const handleFileInput = (files, fileRejections) => {
    onFileUploaded && onFileUploaded({ files, fileRejections });
  };

  if (!isNil(primer)) {
    return <PrimerImageCard primer={primer} />;
  }

  return (
    <AssetUploadCard
      handleFileInput={handleFileInput}
      multiple={false}
      isUploading={isUploading}
      maxFiles={1}
      disabled={!isNil(primer) || isDeletingPrimer || isUploading}
    />
  );
}

function PrimerImageCard({ primer, onDelete }) {
  return <ImageCard images={primer.images} />;
}

export default PrimerImage;

export { PrimerImageCard };
