import EditIcon from '@mui/icons-material/Edit';
import {
  Card,
  CardContent,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useFetchExperiences } from '../../../hooks/useExperience';
import useWevo from '../../../hooks/useWevo';
import {
  IntakeStepIds,
  JobToBeDone,
  KeyFindingValues,
  MetricTypes,
  QualitativeExpectationsValues,
} from '../../../modules/intake/constants';
import { getSelectedMetricName } from '../../../modules/intake/helpers';
import { getUserCustomizations } from '../../../modules/user/selectors';
import {
  AuthenticExperienceOption,
  DEVICE_ID_TO_NAME,
  getAudienceCategoryNameById,
  KeyFindingOption,
  WevoType,
} from '../../../modules/wevos/constants';
import ExpandableText from '../../../ui/ExpandableText';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import useFetchCustomQuestions from '../hooks/useFetchCustomQuestions';
import useFetchCustomScreenerQuestions from '../hooks/useFetchCustomScreenerQuestions';
import AudienceReviewCard from './AudienceReviewCard';
import CustomAudienceReviewCard from './CustomAudienceReviewCard';
import CustomScreenerReviewCard from './CustomScreenerReviewCard';
import GroupReviewCard from './GroupReviewCard';
import PageReviewCard, { PrimerReviewCard } from './PageReviewCard';

const USABILITY_DEFAULT_NUM_RESPONDENTS = 50;
const LITE_DEFAULT_NUM_RESPONDENTS = 50;

const ErrorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.error.main,
  '&:hover': {
    backgroundColor: 'rgba(255,85,85,0.04)',
  },
}));

const styles = {
  title: {
    paddingTop: (theme) => theme.spacing(4),
    paddingBottom: (theme) => theme.spacing(4),
  },
  sectionHeader: {
    paddingBottom: (theme) => theme.spacing(0),
  },
  infoContainer: {
    paddingLeft: (theme) => theme.spacing(10),
    paddingRight: (theme) => theme.spacing(10),
  },
  audienceButton: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    marginTop: (theme) => theme.spacing(4),
    marginLeft: (theme) => theme.spacing(-1),
    marginBottom: (theme) => theme.spacing(3),
  },
  divider: {
    width: '100%',
    marginTop: (theme) => theme.spacing(6),
    marginBottom: (theme) => theme.spacing(6),
  },
  dividerAudience: {
    opacity: 0.4,
    width: '80%',
    marginTop: (theme) => theme.spacing(6),
  },
  cardContent: {
    paddingTop: 0,
    paddingLeft: (theme) => theme.spacing(1),
    paddingBottom: (theme) => theme.spacing(1),
    paddingRight: (theme) => theme.spacing(1),
  },
  segmentName: {
    textTransform: 'capitalize',
  },
  honorNewLines: {
    whiteSpace: 'pre-line',
  },
  breakText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    overflowWrap: 'anywhere',
  },
  breakEmailText: {
    maxWidth: 400,
    fontSize: '13px',
    whiteSpace: 'wrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: (theme) => theme.spacing(1),
  },
  emailsSection: {
    marginTop: (theme) => theme.spacing(-3),
    marginBottom: (theme) => theme.spacing(-0.75),
  },
  goalContent: {
    marginTop: (theme) => theme.spacing(-3),
  },
};

const UsabilityCard = ({ draft, handleEditTestTypeFieldClick }) => {
  return draft.isUsabilityTestType ? (
    <Grid item>
      <Card>
        <CardActionArea onClick={handleEditTestTypeFieldClick}>
          <Table aria-label="Usability">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h5">Usability Test</Typography>
                </TableCell>
                <TableCell align="right" style={{ textTransform: 'capitalize' }}>
                  Yes
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardActionArea>
      </Card>
    </Grid>
  ) : null;
};

const NumRespondentsCard = ({ draft, handleEditTestTypeFieldClick }) => {
  return draft.isUsabilityTestType || draft.isLiteTestType ? (
    <Grid item>
      <Card>
        <CardActionArea onClick={handleEditTestTypeFieldClick}>
          <Table aria-label="NumRespondents">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h5">Num Respondents</Typography>
                </TableCell>
                <TableCell align="right" style={{ textTransform: 'capitalize' }}>
                  {draft.isUsabilityTestType
                    ? USABILITY_DEFAULT_NUM_RESPONDENTS
                    : LITE_DEFAULT_NUM_RESPONDENTS}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardActionArea>
      </Card>
    </Grid>
  ) : null;
};

const Review = (props) => {
  const { isDefinitionValid, isTestGoalsValid, isAudienceValid, isPagesValid, isDetailsValid, setStep } =
    props;

  const { wevoId } = useParams();
  const userCustomizations = useSelector(getUserCustomizations);
  const { data: draft, isLoading: isDraftLoading } = useWevo(wevoId);
  const { data: groups } = useFetchCustomQuestions(wevoId);
  const { data: screenerQuestions } = useFetchCustomScreenerQuestions({ wevoId });

  const hasAuthenticExperience = userCustomizations?.authenticExperience === AuthenticExperienceOption.Enabled;

  const experiencesRequests = useFetchExperiences(
    (draft?.pages || []).map((page) => {
      return { wevoId, pageId: page.id, includeConfig: true };
    }),
    {
      enabled:
        !isDraftLoading &&
        draft?.type === WevoType.Journey &&
        draft?.metricType === MetricTypes.Standard &&
        hasAuthenticExperience,
    }
  );

  const experiences = useMemo(() => {
    const loading = experiencesRequests.some((request) => request.isLoading);

    if (loading) {
      return [];
    }

    return experiencesRequests.map((request) => request?.data).filter((experience) => !!experience);
  }, [experiencesRequests]);

  const hasExpectations = useMemo(() => {
    return (
      (draft?.metricType === MetricTypes.Standard || draft?.metricType === MetricTypes.MastercardCds) &&
      !draft?.isUsabilityTestType
    );
  }, [draft]);

  useTrackPageLoad({ name: TrackEvent.VIEWED_REVIEW_TEST, properties: { wevoId: draft?.analyticsId } });

  const selectedMetricName = getSelectedMetricName(draft?.metricType);

  const isKeyFindingsOptional =
    userCustomizations?.features?.keyFindings === KeyFindingOption.Optional &&
    draft?.metricType !== MetricTypes.MastercardCds;

  const emailsToNotify = draft?.emailsToNotify || [];

  const handleEditInfoClick = () => {
    setStep(IntakeStepIds.Start);
  };

  const handleEditAudienceClick = (ev) => {
    ev.preventDefault();
    setStep(IntakeStepIds.Audience);
  };

  const handleEditGoalClick = () => {
    setStep(IntakeStepIds.TestGoal);
  };

  const handleGroupTypeClick = (ev) => {
    ev.preventDefault();
    setStep(IntakeStepIds.Audience);
  };

  const handleEditTestTypeFieldClick = (ev) => {
    ev.preventDefault();
    setStep(IntakeStepIds.TestType);
  };

  const handleTestInfoClick = (ev) => {
    ev.preventDefault();
    setStep(IntakeStepIds.Start);
  };

  const handleGoalClick = (ev) => {
    ev.preventDefault();
    setStep(IntakeStepIds.TestGoal);
  };

  const handleMetricClick = (ev) => {
    ev.preventDefault();
    setStep(IntakeStepIds.Start);
  };

  const handleEditDetailsClick = (ev) => {
    ev.preventDefault();
    setStep(IntakeStepIds.Details);
  };

  return (
    <Fragment>
      <Typography sx={styles.title} variant="h3" align="center">
        Review Your Test
      </Typography>
      <Grid container direction="row" spacing={4}>
        <Grid container item direction="column" spacing={4} xs>
          {/* Left Column */}
          <Grid container item direction="column" spacing={2}>
            {/* Info Section */}
            <Grid container item sx={styles.sectionHeader} alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h4">Info</Typography>
              </Grid>
              {!isDefinitionValid && (
                <Grid item>
                  <ErrorButton onClick={handleEditInfoClick} startIcon={<EditIcon />}>
                    Fix Errors
                  </ErrorButton>
                </Grid>
              )}
              {!isTestGoalsValid && (
                <Grid item>
                  <ErrorButton onClick={handleEditGoalClick} startIcon={<EditIcon />}>
                    Fix Errors
                  </ErrorButton>
                </Grid>
              )}
            </Grid>
            <Grid item>
              {(draft.name || draft.ownerName) && (
                <Card>
                  <CardActionArea onClick={handleTestInfoClick}>
                    <TableContainer>
                      <Table aria-label="Info">
                        <TableBody>
                          {draft.name && (
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5">Test Name</Typography>
                              </TableCell>
                              <TableCell align="right">{draft.name}</TableCell>
                            </TableRow>
                          )}
                          {draft.ownerName && (
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5">Test Owner</Typography>
                              </TableCell>
                              <TableCell align="right">{draft.ownerName}</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardActionArea>
                </Card>
              )}
            </Grid>
            {Boolean(draft?.emailsToNotify?.length) && (
              <Grid item>
                <Card>
                  <CardActionArea onClick={handleTestInfoClick}>
                    <CardHeader title="Team emails" />
                    <CardContent sx={styles.emailsSection}>
                      {emailsToNotify?.map((teamEmail, index) => {
                        return (
                          <Typography variant="body2" sx={styles.breakEmailText} key={index}>
                            {teamEmail}
                          </Typography>
                        );
                      })}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
            {draft?.jobToBeDone && draft?.description && (
              <Grid item>
                <Card>
                  <CardActionArea onClick={handleGoalClick}>
                    <CardHeader title="Primary Goal" />
                    <CardContent sx={styles.goalContent}>
                      <Typography variant="caption" color="textSecondary">
                        {`"${draft?.description}"`}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
            {draft?.secondaryTestGoal && (
              <Grid item>
                <Card>
                  <CardActionArea onClick={handleGoalClick}>
                    <CardHeader title="Secondary Goal" />
                    <CardContent sx={styles.goalContent}>
                      <Typography variant="caption" color="textSecondary">
                        {draft.secondaryTestGoal}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
            {draft?.metricType && (
              <Grid item>
                <Card>
                  <CardActionArea onClick={handleMetricClick}>
                    <Table aria-label="Type">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h5">Metric selected</Typography>
                          </TableCell>
                          <TableCell align="right">{selectedMetricName}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
          </Grid>
          <Grid container item direction="column" spacing={2}>
            {/* Audience Section */}
            <Grid container item sx={styles.sectionHeader} alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Target Audience Criteria</Typography>
              </Grid>
              {!isAudienceValid && (
                <Grid item>
                  <ErrorButton onClick={handleEditAudienceClick} startIcon={<EditIcon />}>
                    Fix Errors
                  </ErrorButton>
                </Grid>
              )}
            </Grid>
            {draft.audienceCategory && (
              <Grid item>
                <Card>
                  <CardActionArea onClick={handleGroupTypeClick}>
                    <Table aria-label="Group Type">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h5">Group Type</Typography>
                          </TableCell>
                          <TableCell align="right">
                            {getAudienceCategoryNameById(draft.audienceCategory && draft.audienceCategory.id)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
            {draft?.audience && (
              <Fragment>
                {/* List of audiences */}
                {(draft?.audience?.attributes || []).map((audience) =>
                  audience.isSelected ? (
                    <Grid key={audience.id} item>
                      <AudienceReviewCard audience={audience} onClick={handleEditAudienceClick} />
                    </Grid>
                  ) : null
                )}

                {/* Custom audience */}
                {draft.audience.description && (
                  <Grid item>
                    <CustomAudienceReviewCard
                      customAudience={draft.audience.description}
                      onClick={handleEditAudienceClick}
                    />
                  </Grid>
                )}
              </Fragment>
            )}
            {/* custom screener questions */}
            {screenerQuestions?.map((question) => (
              <Grid item key={question?.id} xs>
                <CustomScreenerReviewCard
                  questionName={question?.name}
                  questionText={question?.questionText}
                  questionType={question?.questionType}
                  labelsType={question?.details?.labelsType}
                  onClick={handleEditAudienceClick}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container item direction="column" spacing={4} xs>
          {/* 2nd Column */}
          <Grid container item direction="column" spacing={2}>
            {/* Top Section */}
            <Grid container item alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h4">Test Type</Typography>
              </Grid>
              {!isPagesValid && (
                <Grid item>
                  <ErrorButton onClick={handleEditTestTypeFieldClick} startIcon={<EditIcon />}>
                    Fix Errors
                  </ErrorButton>
                </Grid>
              )}
            </Grid>
            {draft.type && (
              <Grid item>
                <Card>
                  <CardActionArea onClick={handleEditTestTypeFieldClick}>
                    <Table aria-label="Type">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h5">Type</Typography>
                          </TableCell>
                          <TableCell align="right" style={{ textTransform: 'capitalize' }}>
                            {draft?.jobToBeDone === JobToBeDone.Lite ? ' Lite' : draft.type}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
            <UsabilityCard draft={draft} handleEditTestTypeFieldClick={handleEditTestTypeFieldClick} />
            <NumRespondentsCard draft={draft} handleEditTestTypeFieldClick={handleEditTestTypeFieldClick} />
            {Array.isArray(draft.devices) && (
              <Grid item>
                <Card>
                  <CardActionArea onClick={handleEditTestTypeFieldClick}>
                    <Table aria-label="Device">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h5">Device</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                              {DEVICE_ID_TO_NAME[draft.devices[0]]}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
            {draft?.details?.journeyStartUrl && draft.type === WevoType.Journey && (
              <Grid item style={{ width: '100%' }}>
                <CardActionArea onClick={handleEditTestTypeFieldClick}>
                  <Card sx={{ padding: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Journey Start
                    </Typography>
                    <Typography variant="caption" sx={styles.breakText} color="textSecondary">
                      {draft.details.journeyStartUrl}
                    </Typography>
                  </Card>
                </CardActionArea>
              </Grid>
            )}
            {draft?.details?.taskToComplete && draft.type === WevoType.Journey && (
              <Grid item style={{ width: '100%' }}>
                <CardActionArea onClick={handleEditTestTypeFieldClick}>
                  <Card sx={{ padding: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Task To Complete
                    </Typography>
                    <Typography variant="caption" color="textSecondary" sx={styles.breakText}>
                      {draft.details.taskToComplete}
                    </Typography>
                  </Card>
                </CardActionArea>
              </Grid>
            )}
            {hasExpectations && draft?.visitorObjective && (
              <Grid item style={{ width: '100%' }}>
                <CardActionArea onClick={handleEditTestTypeFieldClick}>
                  <Card sx={{ p: 2 }}>
                    <ExpandableText
                      header="Expectations Gap"
                      headerVariant="h5"
                      content={draft.visitorObjective}
                      contentVariant="caption"
                      contentColor="textSecondary"
                      buttonFontSize="12px"
                    />
                  </Card>
                </CardActionArea>
              </Grid>
            )}
            {isKeyFindingsOptional && (
              <Grid item style={{ width: '100%' }}>
                <Card>
                  <CardActionArea onClick={handleEditDetailsClick}>
                    <Table size="small" aria-label="Type">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h5">Key Findings</Typography>
                          </TableCell>
                          <TableCell align="right" style={{ textTransform: 'capitalize' }}>
                            {typeof draft?.details?.includeKeyFindings !== 'boolean' ||
                            draft?.details?.includeKeyFindings
                              ? ` ${KeyFindingValues.Yes}`
                              : ` ${KeyFindingValues.No}`}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
            {hasExpectations && (
              <Grid item style={{ width: '100%' }}>
                <Card>
                  <CardActionArea onClick={handleEditTestTypeFieldClick}>
                    <Table size="small" aria-label="Type">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h5">Qualitative Expectations</Typography>
                          </TableCell>
                          <TableCell align="right" style={{ textTransform: 'capitalize' }}>
                            {typeof draft?.details?.includeQualitativeExpectations !== 'boolean' ||
                            draft?.details?.includeQualitativeExpectations
                              ? ` ${QualitativeExpectationsValues.Yes}`
                              : ` ${QualitativeExpectationsValues.No}`}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
            {(draft?.pages?.[0]?.primerId || draft?.pages?.[0]?.primerContext) && (
              <Grid item style={{ width: '100%' }}>
                <PrimerReviewCard draft={draft} onClick={handleEditTestTypeFieldClick} />
              </Grid>
            )}
          </Grid>
          {(draft?.pages ?? []).length > 0 && (
            <Grid container item direction="row" spacing={2}>
              {/* Asset Section */}
              <Grid item sx={styles.sectionHeader}>
                <Typography variant="h5">Assets</Typography>
              </Grid>
              {(draft?.pages ?? []).map((page) => (
                <Grid item xs={12} key={page.id}>
                  <PageReviewCard
                    draft={draft}
                    experience={experiences.find(
                      (experience) => String(experience?.wevoPageId) === String(page.id)
                    )}
                    page={page}
                    onClick={handleEditTestTypeFieldClick}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        {groups?.length > 0 && (
          <Grid container item direction="column" spacing={4} xs>
            {/* 3rd Column */}
            <Grid container item direction="column" spacing={2}>
              {/* Top Section */}
              <Grid container item alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h4">Custom Questions</Typography>
                </Grid>
                {!isDetailsValid && (
                  <Grid item>
                    <ErrorButton onClick={handleEditDetailsClick} startIcon={<EditIcon />}>
                      Fix Errors
                    </ErrorButton>
                  </Grid>
                )}
              </Grid>
              {groups?.map((group) => (
                <Grid item key={group.groupId} xs>
                  <GroupReviewCard
                    group={group}
                    type={draft.type}
                    pages={draft?.pages ?? []}
                    handleEditDetailsClick={handleEditDetailsClick}
                    allowEditing={true}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default Review;
