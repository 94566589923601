import { Box, Typography } from '@mui/material';
import { PrimerImageCard } from '../../../primer-task/PrimerImage';

function PrimerPreview({ wevo }) {
  // same for every page, so just pick the first one
  const primerPage = wevo?.pages?.[0];
  const primerContext = primerPage?.primerContext;
  const primer = primerPage?.primer;

  if (!primerContext) {
    return <></>;
  }

  return (
    <Box my={1}>
      <Box mb={1}>
        <Typography>{primerContext}</Typography>
      </Box>
      {primer?.images && (
        <Box>
          <PrimerImageCard primer={primer} />
        </Box>
      )}
    </Box>
  );
}

export default PrimerPreview;
