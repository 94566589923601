import customerIcon from '../../assets/customerIcon.png';
import emptyIcon from '../../assets/emptyIcon.png';
import outlinedAndColoredIcon from '../../assets/outlinedAndColoredIcon.png';
import outlinedStarIcon from '../../assets/outlinedStarIcon.png';
import { MetricTypes } from '../intake/constants';
import { WevoType } from '../wevos/constants';
import { CONCEPT_SCORE_THEME_NAMES, EXPERIENCE_SCORE_THEME_NAMES } from './constants';

/**
 * Chunks a string by word and ensures that each line does not exceed the max width.
 *
 * @param {String} str - String to split into lines.
 * @param {Number} maxWidth - Max width for each line.
 * @returns list of chunks
 */
export function chunkString(str, maxWidth) {
  const chunks = [];
  const words = str.split(/\s+/);

  let line = '';
  words.forEach((word) => {
    const newLine = line.length > 0 ? `${line} ${word}` : word;

    if (newLine.length > maxWidth) {
      // If new line exceeds max width, then copy the current line to the list of chunks.
      chunks.push(line);
      line = word;
    } else {
      // New line doesn't exceed max width yet.
      line = newLine;
    }
  });

  // If there are any remaining words in the line, add it to the chunks.
  if (line.length) {
    chunks.push(line);
  }

  return chunks;
}

export const displayLabels = (context) => {
  // find the running total value up to the current value and after adding the current value
  // these values define the x-axis pixels (i.e. width) of the bar segments (there is no chart.js api for this)
  const runningTotalValueBefore = context.chart.data.datasets
    .slice(0, context.datasetIndex)
    .reduce((acc, cur) => {
      return acc + cur.data[context.dataIndex];
    }, 0);

  const runningTotalValueAfter =
    runningTotalValueBefore + context.chart.data.datasets[context.datasetIndex].data[context.dataIndex];

  const xPixelBefore = context.chart.scales.x.getPixelForValue(runningTotalValueBefore);
  const xPixelAfter = context.chart.scales.x.getPixelForValue(runningTotalValueAfter);
  const barSegmentWidth = xPixelAfter - xPixelBefore;

  return barSegmentWidth >= 40;
};

export const convertScoresToPercentages = (scores) => {
  const sumOfScores = scores.reduce((a, b) => a + b, 0);
  const percentages = scores.map((score) => (sumOfScores > 0 ? (score * 100) / sumOfScores : 0));
  return percentages;
};

export const roundPercentages = (percentages, fixedNumber) => {
  return percentages?.map((percentage) => percentage.toFixed(fixedNumber));
};

export const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/(png|jpeg|jpg)/g, '')
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-');

export const getOrderedThemeNames = (metricType) => {
  if (metricType === MetricTypes.MastercardDqs) {
    return EXPERIENCE_SCORE_THEME_NAMES;
  } else if (metricType === MetricTypes.MastercardCds) {
    return CONCEPT_SCORE_THEME_NAMES;
  } else return null;
};

/**
 * Orders list of composite metrics according to a specified ordering scheme
 * (e.g. EXPERIENCE_SCORE_THEME_NAMES or CONCEPT_SCORE_THEME_NAMES)
 *
 * @param {String} metricType - Wevo metric type used.
 * @param {Array} selectedompositeMetrics - List of composite metric names to reorder.
 * @returns {Array} ordered list of composite metric names.
 */
export const orderCompositeMetrics = (metricType, compositeMetrics) => {
  const orderedThemes = getOrderedThemeNames(metricType);

  // Copy list of composite metrics to protect from in-place sorting.
  const compositeMetricsToOrder = [...compositeMetrics];

  compositeMetricsToOrder.sort(function (a, b) {
    let indexA = orderedThemes.indexOf(a);
    let indexB = orderedThemes.indexOf(b);
    return indexA - indexB;
  });
  return compositeMetricsToOrder;
};

/**
 * Orders list of chip groups according to a specified ordering scheme
 * (e.g. EXPERIENCE_SCORE_THEME_NAMES or CONCEPT_SCORE_THEME_NAMES)
 *
 * E.g. for DQS, the chip group for Emotion will be ordered first.
 *
 * @param {String} metricType - Wevo metric type used.
 * @param {Array<array>} selectedChipGroups - List of chip groups to reorder.
 * @param {Array<string>} selectedCompositeMetrics - List of selected composite metric names.
 * @returns {Array<array>} Ordered list of chip groups.
 */
export function orderChipGroups(metricType, selectedChipGroups, selectedCompositeMetrics) {
  const orderedThemes = getOrderedThemeNames(metricType);

  // Copy list of chip groups to protect from in-place sorting.
  const chipGroupsToOrder = [...selectedChipGroups];

  chipGroupsToOrder.sort(function (a, b) {
    let groupIndexA = chipGroupsToOrder.indexOf(a);
    let indexA = orderedThemes.indexOf(selectedCompositeMetrics[groupIndexA]);
    if (indexA < 0) {
      indexA = Number.MAX_SAFE_INTEGER; // If not in ordered list, sort to the end
    }

    let groupIndexB = chipGroupsToOrder.indexOf(b);
    let indexB = orderedThemes.indexOf(selectedCompositeMetrics[groupIndexB]);
    if (indexB < 0) {
      indexB = Number.MAX_SAFE_INTEGER; // If not in ordered list, sort to the end
    }

    return indexA - indexB;
  });

  return chipGroupsToOrder;
}

export const generateLimitedReportPageUrl = (shareUrl, pageName) => {
  const url = shareUrl?.replace('report', pageName);
  return url;
};

/**
 * Converts time in seconds to minutes and seconds
 *
 * @param {Number} seconds - Number of seconds
 * @returns {String} e.g '5m40s'
 */
export function secondsToMinutes(seconds) {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60 ? `${Math.round(seconds % 60)}s` : '';
  return `${mins}m${secs}`;
}

/**
 * Parses the display label based on the audience attribute.
 *
 * @param {Object} attribute
 * @returns {String}
 */
export function attributeToDisplayLabel(attribute) {
  if (attribute.segmentLabelText) {
    return `${attribute.segmentLabel} (${attribute.segmentLabelText})`;
  } else {
    return attribute.segmentLabel;
  }
}

export const determineStarredQuoteIcon = (starredQuote) => {
  const isStarredByCompany = starredQuote.starredByCompany;
  const isStarredByWevo = starredQuote.starredByWevo;

  let starredQuoteIcon;
  if (isStarredByCompany && isStarredByWevo) {
    starredQuoteIcon = outlinedAndColoredIcon;
  } else if (isStarredByWevo) {
    starredQuoteIcon = outlinedStarIcon;
  } else if (isStarredByCompany) {
    starredQuoteIcon = customerIcon;
  } else {
    starredQuoteIcon = emptyIcon;
  }

  return starredQuoteIcon;
};

/**
 * Checks if the point is within the selected region
 *
 * @param {Object} point A data point {x,y}
 * @param {Object} region Selected region {x, y, width, height}
 * @param {Object} pageImage Info about the image where the points are being rendered on
 * @returns {Boolean}
 */
export const pointInRegion = (point, region, pageImage) => {
  const { x, y } = point;
  const scaleX = (x) => (x * pageImage.width) / 100;
  const scaleY = (y) => (y * pageImage.height) / 100;
  const minX = scaleX(region.x);
  const minY = scaleY(region.y);
  const maxX = minX + scaleX(region.width);
  const maxY = minY + scaleY(region.height);
  return x < maxX && x > minX && y > minY && y < maxY;
};

/**
 * Returns the element ID for the ExperienceSummary component.
 *
 * @param {String} pageId
 * @returns {String}
 */
export function getExperienceSummaryElementId(pageId) {
  return `experienceSummary-${pageId}`;
}

/**
 * Converts a number to an ordinal number (e.g. 2nd)
 *
 * @param {Number} n
 * @returns {String}
 */
export function numberToOrdinal(n) {
  const lastDigit = n % 10;
  const lastTwoDigits = n % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return n + 'th';
  }

  switch (lastDigit) {
    case 1:
      return n + 'st';
    case 2:
      return n + 'nd';
    case 3:
      return n + 'rd';
    default:
      return n + 'th';
  }
}

/**
 * Returns true if every scope in the custom question is page - level and the wevo is a journey.
 * This implementation supports Journeys with multiple pages
 *
 * @param {object} wevo
 * @param {Array<object>} scopes
 * @returns {Boolean}
 */
export function isJourneyWideCustomQuestion(wevo, scopes) {
  return (
    wevo?.type === WevoType.Journey &&
    scopes.length === wevo?.pages?.length &&
    scopes.every((scope) => !scope.stepId)
  );
}

export function isCustomSurveyType(wevo) {
  return wevo?.details?.isCustomSurvey === true;
}
