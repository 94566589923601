import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isNil } from 'lodash';
import { WevoType } from '../../../../../modules/wevos/constants';
import { getFormattedSelectionRequirements } from '../../audience/helpers';
import {
  CustomQuestionTypes,
  LabelsType,
  QuestionType,
  ValidationKeys,
} from '../../custom-questions/constants';
import { serializeErrors } from '../../custom-questions/helpers';
import AlertMessage from '../shared/AlertMessage';
import AssetPreview from '../shared/AssetPreview';
import { MultiLineField } from '../shared/FieldRow';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    marginLeft: theme.spacing(1.8),
    marginTop: theme.spacing(1.5),
  },
  fieldBox: { marginTop: theme.spacing(1.5) },
  header: {
    color: '#212A37',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '18px',
    overflow: 'auto',
  },
  text: {
    marginRight: theme.spacing(-3),
    overflow: 'auto',
    color: '#212A37',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
  },
}));

const SelectedAssetsGrid = ({ assets }) => {
  return assets.map((asset) => (
    <Grid item xs={12} lg={6} id={asset.id} key={asset.id}>
      <AssetPreview asset={asset} maxHeight={82} maxWidth={130} />
    </Grid>
  ));
};

/*
 * Shows a group and its info.
 */
const GroupReview = ({ isCustomSurvey = false, group, type, pages, index, errors }) => {
  const classes = useStyles();

  const scopes = group?.scopes;
  const name = group?.name;
  const question = group?.question;
  const questionType = question?.type;
  const questionText = question?.questionText;
  const followUpQuestionText = question?.followUpQuestionText;
  const labelsType = question?.labelsType;
  const labels = question?.labels ?? [];
  const rangeType = question?.rangeType;
  const number = question?.number;

  const showQuestionScale = [
    CustomQuestionTypes.YesNoTrueFalse,
    CustomQuestionTypes.FiveLikertScale,
    CustomQuestionTypes.SevenLikertScale,
    CustomQuestionTypes.MultipleChoice,
    CustomQuestionTypes.MultiSelect,
    CustomQuestionTypes.RankOrder,
  ]?.includes(questionType);

  const showQuestionLabels = [
    CustomQuestionTypes.YesNoTrueFalse,
    CustomQuestionTypes.FiveLikertScale,
    CustomQuestionTypes.SevenLikertScale,
    CustomQuestionTypes.MultipleChoice,
    CustomQuestionTypes.MultiSelect,
    CustomQuestionTypes.RankOrder,
  ]?.includes(questionType);

  const showChoiceRequirements = [CustomQuestionTypes.MultiSelect]?.includes(questionType);

  const showFollowUpQuestion = ![CustomQuestionTypes.CustomQualitative].includes(questionType);

  const pageExistsInScopes = (page) => {
    const id = String(page.id);
    return scopes.find((scope) => scope.wevoPageId === id && !scope.stepId);
  };

  const stepExistsInScopes = (step) => {
    const id = String(step.id);
    return scopes.find((scope) => scope.stepId === id);
  };

  const filteredPages = pages
    .filter((page) => pageExistsInScopes(page))
    .filter((page) => !isNil(page?.images));
  const filteredSteps = pages
    .flatMap((page) => page?.steps ?? [])
    .filter((step) => stepExistsInScopes(step))
    .filter((step) => !isNil(step?.images));

  const selectionLevel = () => {
    if (type === WevoType.Classic) {
      return 'Specific asset';
    } else {
      if (filteredSteps.length) {
        return 'Specific asset';
      } else {
        return 'All assets';
      }
    }
  };

  return (
    <Box className={classes.mainBox}>
      <MultiLineField
        title={name ? name : `CQ ${index + 1}`}
        titleErrorMessage={errors?.name?.length > 0 ? serializeErrors(errors.name) : ''}
        text={questionText}
        errorMessage={errors?.questionText?.length > 0 ? 'Question is required.' : ''}
      />
      <MultiLineField
        sx={{ marginTop: '12px' }}
        title={'Question Type'}
        text={QuestionType[questionType]}
        errorMessage={errors?.questionType?.length > 0 ? serializeErrors(errors.questionType) : ''}
      />
      {showQuestionScale && (
        <MultiLineField
          sx={{ marginTop: '12px' }}
          title={'Question Scale'}
          text={LabelsType[labelsType]}
          errorMessage={errors?.labelsType?.length > 0 ? serializeErrors(errors.labelsType) : ''}
        />
      )}
      {showChoiceRequirements && (
        <MultiLineField
          sx={{ marginTop: '12px' }}
          title={'Respondents must choose'}
          text={getFormattedSelectionRequirements(rangeType, number)}
          errorMessage={
            errors?.choiceRequirements?.length > 0 ? serializeErrors(errors.choiceRequirements) : ''
          }
        />
      )}
      {showQuestionLabels && (
        <MultiLineField
          sx={{ marginTop: '12px' }}
          title={'Question Labels'}
          text={labels.join(', ')}
          errorMessage={errors?.labels?.length > 0 ? serializeErrors(errors.labels) : ''}
        />
      )}
      {showFollowUpQuestion && (
        <MultiLineField
          sx={{ marginTop: '12px' }}
          title={'Follow-up question'}
          text={followUpQuestionText}
          errorMessage={
            errors?.followUpQuestionText?.length > 0 ? serializeErrors(errors.followUpQuestionText) : ''
          }
        />
      )}
      {/* Note: Custom Surveys do not apply to "assets" in the way end users understand them, so hide this section */}
      {!isCustomSurvey && (
        <>
          <MultiLineField sx={{ marginTop: '12px' }} title={'Applies to'} text={selectionLevel()} />
          {(filteredPages?.length > 0 || filteredSteps?.length > 0) && (
            <Box className={classes.fieldBox}>
              <Box>
                <Grid container spacing={2} justifyContent="flex-start">
                  <SelectedAssetsGrid assets={filteredPages} />
                  <SelectedAssetsGrid assets={filteredSteps} />
                </Grid>
              </Box>
            </Box>
          )}
        </>
      )}
      {errors?.[ValidationKeys.UnsupportedQuestionType]?.length > 0 && (
        <Box mb={1}>
          <AlertMessage message={serializeErrors(errors[ValidationKeys.UnsupportedQuestionType])} />
        </Box>
      )}
    </Box>
  );
};

export default GroupReview;
