// These are the "absolute limits" of the system placed on customers
export const CUSTOM_QUESTION_SYSTEM_MIN = 0;
export const CUSTOM_QUESTION_SYSTEM_MAX = 32;

export const MIN_DEEP_DIVE_STEPS = 1;
export const MAX_DEEP_DIVE_STEPS = 4;

export const MIN_PAGES_COMPARE = 2;
export const MAX_PAGES_COMPARE = 4;

export const MAX_FILE_SIZE = 20000000;
export const ACCEPTED_FILE_TYPES = {
  'image/jpeg': [],
  'image/png': [],
};

export const FILE_ERROR_CODES = {
  FILE_TOO_LARGE: 'file-too-large',
  TOO_MANY_FILES: 'too-many-files',
};

export const WevoPageAssetType = {
  URL: 'url',
  Image: 'image',
};

export const GOAL_MAX_LENGTH = 1000;
