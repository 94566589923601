import { Box, List } from '@mui/material';
import { cloneDeep, debounce } from 'lodash';
import { useContext, useRef, useState } from 'react';
import { AudienceCategory, AudienceDisplay } from '../../../../modules/wevos/constants';
import { snackbar } from '../../../../notifications';
import { Header, IntakeTooltip, StyledListItemButton } from '../../components';
import useAddSavedAudience from '../../hooks/useAddSavedAudience';
import useDeleteSavedAudience from '../../hooks/useDeleteSavedAudience';
import useSaveWevo from '../../hooks/useSaveWevo';
import { IntakeWevoContext } from '../context/IntakeWevoContext';
import NewAudience from './components/new-audience';
import SavedAudiences from './components/saved-audiences';
import { mergeAttributeChanges } from './helpers';

const AudienceIntakeSection = () => {
  const {
    wevo,
    setWevo,
    setIsWevoSyncing,
    savedAudiences,
    setSavedAudiences,
    reloadWevoFromRemote,
    wevoErrors,
  } = useContext(IntakeWevoContext);

  const [audienceDisplay, setAudienceDisplay] = useState(
    wevo?.savedAudienceGroup && !wevo?.savedAudienceGroup?.isDraft
      ? AudienceDisplay.SavedAudience
      : AudienceDisplay.SelectAudience
  );

  const { mutateAsync: addSavedAudienceAsync } = useAddSavedAudience();
  const { mutateAsync: deleteSavedAudienceAsync } = useDeleteSavedAudience();
  const { mutateAsync: saveWevoAsync } = useSaveWevo();

  const nonDraftAudiences = savedAudiences?.filter((audience) => !audience?.isDraft);
  const hasPreviouslySavedAudiences = nonDraftAudiences?.length > 0;
  const isBYOPSupported = false;

  const handleWevoChanged = async ({ changedFields = {} }) => {
    try {
      setIsWevoSyncing(true);
      const updatedWevo = await saveWevoAsync({ id: wevo.id, ...changedFields });
      setWevo(updatedWevo);
    } catch (err) {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating Wevo');
      throw err;
    } finally {
      setIsWevoSyncing(false);
    }
  };

  // the debounce function needs to be retained across renders so use useRef
  const debouncedSaveWevo = useRef(
    debounce(async (newAudience, prevWevo) => {
      try {
        await saveWevoAsync({ id: wevo.id, audience: newAudience });
      } catch (err) {
        // rollback to the previous state on error
        setWevo(prevWevo);
        snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating audience');
        throw err;
      }
    }, 1000)
  );

  const handleAudienceChanged = ({ attributeId, changes }) => {
    const { attributes, segments, description } = changes;
    const prevWevo = cloneDeep(wevo);

    let newAudience = { ...wevo?.audience };

    if (attributes || segments) {
      newAudience = mergeAttributeChanges({ audience: { ...wevo?.audience }, attributeId, changes });
    }
    if (typeof description === 'string') {
      newAudience.description = description;
    }

    // optimistically update the wevo state with the new audience and description
    setWevo((prevWevo) => ({
      ...prevWevo,
      audience: newAudience,
      audienceDescription: newAudience?.description,
    }));

    // debounce saveWevoAsync
    debouncedSaveWevo.current(newAudience, prevWevo);
  };

  const handleAddSavedAudience = async (name) => {
    const prevWevo = cloneDeep(wevo);
    const newWevo = cloneDeep(wevo);

    let newSavedAudienceGroup = {};
    let newWevoState = {};

    // If there is a savedAudienceGroupId and the name is an empty string, it is likely that the name is being changed.
    // We will only save it to the local state and not the db because we currently do not allow saved audiences with empty names.
    // Refreshing the page will show the previous name, not the empty string
    if (newWevo?.savedAudienceGroup?.id && !name?.length) {
      newSavedAudienceGroup = { ...newWevo.savedAudienceGroup, name: '' };
      newWevoState = {
        ...newWevo,
        savedAudienceGroup: newSavedAudienceGroup,
      };
      setWevo(newWevoState);
      return;
    }

    try {
      setIsWevoSyncing(true);
      // create or update an existing saved audience draft
      const savedAudienceGroup = await addSavedAudienceAsync({ id: wevo?.id, name, draft: true });

      // update savedAudienceGroupId and savedAudienceGroup
      newSavedAudienceGroup = { ...newWevo.savedAudienceGroup, ...savedAudienceGroup };
      newWevoState = {
        ...newWevo,
        savedAudienceGroupId: savedAudienceGroup.id,
        savedAudienceGroup: newSavedAudienceGroup,
      };
      setWevo(newWevoState);
    } catch (err) {
      setWevo(prevWevo);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating saved audience');
      throw err;
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleDeleteSavedAudienceDraft = async (groupId) => {
    const prevWevo = cloneDeep(wevo);

    if (!groupId) {
      return;
    }

    setWevo({
      ...prevWevo,
      savedAudienceGroupId: null,
      savedAudienceGroup: null,
    });

    try {
      setIsWevoSyncing(true);
      // delete saved audience draft
      await deleteSavedAudienceAsync({ groupId });
      // disassociate saved audience group id from wevo
      await saveWevoAsync({ id: wevo.id, savedAudienceGroupId: null });
    } catch (err) {
      setWevo(prevWevo);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting saved audience');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleSelectSavedAudience = async ({ audienceCategoryId, groupId }) => {
    const prevWevo = cloneDeep(wevo);
    const prevSavedAudiences = cloneDeep(savedAudiences);
    const hasSavedAudienceDraft = prevWevo?.savedAudienceGroup?.isDraft;

    if (!groupId) {
      return;
    }

    const audienceCategoryLabel = Object.entries(AudienceCategory).find(
      ([key, value]) => value.id === audienceCategoryId
    )?.[0];

    let newWevo = cloneDeep(wevo);
    newWevo.audienceCategory = { id: audienceCategoryId, label: audienceCategoryLabel };
    newWevo.savedAudienceGroupId = groupId;

    setWevo(newWevo);

    if (hasSavedAudienceDraft) {
      let newSavedAudiences = cloneDeep(savedAudiences);
      const index = newSavedAudiences.findIndex((audience) => audience.id === prevWevo?.savedAudienceGroupId);
      if (index !== -1) {
        newSavedAudiences.splice(index, 1);
        setSavedAudiences(newSavedAudiences);
      }
    }

    try {
      // update audienceCategoryId and savedAudienceGroupId fields
      await saveWevoAsync({ id: wevo.id, audienceCategoryId, savedAudienceGroupId: groupId });
      // if the previously associated saved audience group is a draft, delete the draft because it is no longer needed
      if (hasSavedAudienceDraft) {
        await deleteSavedAudienceAsync({ groupId: prevWevo?.savedAudienceGroupId });
      }
      // reload wevo state so that the savedAudienceGroup is updated
      await reloadWevoFromRemote();
    } catch (err) {
      setWevo(prevWevo);
      setSavedAudiences(prevSavedAudiences);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error selecting saved audience');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleDeleteSavedAudience = async (groupId) => {
    const prevWevo = cloneDeep(wevo);
    const prevSavedAudiences = cloneDeep(savedAudiences);

    if (!groupId) {
      return;
    }

    let newSavedAudiences = cloneDeep(savedAudiences);
    const index = newSavedAudiences.findIndex((audience) => audience.id === groupId);
    if (index !== -1) {
      newSavedAudiences.splice(index, 1);
      setSavedAudiences(newSavedAudiences);
    } else {
      return;
    }

    // if the audience being deleted is currently selected and associated with a wevo, disassociate it
    if (prevWevo?.savedAudienceGroupId === groupId) {
      setWevo({
        ...prevWevo,
        savedAudienceGroupId: null,
      });
    }

    try {
      setIsWevoSyncing(true);
      // delete saved audience
      await deleteSavedAudienceAsync({ groupId });
      // disassociate saved audience group id from wevo
      if (prevWevo?.savedAudienceGroupId === groupId) {
        await saveWevoAsync({ id: wevo.id, savedAudienceGroupId: null });
      }
    } catch (err) {
      setWevo(prevWevo);
      setSavedAudiences(prevSavedAudiences);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting saved audience');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleAudienceDisplayClick = async (option) => {
    const prevAudienceDisplay = audienceDisplay;
    const prevWevo = cloneDeep(wevo);

    if (option === audienceDisplay) {
      return;
    }

    setAudienceDisplay(option);
    // disassociate any saved audience group that is not a draft from the wevo when switching to the New Audience option
    if (option === AudienceDisplay.SelectAudience && !wevo?.savedAudienceGroup?.isDraft) {
      let newWevo = cloneDeep(wevo);
      newWevo.savedAudienceGroup = null;
      newWevo.savedAudienceGroupId = null;
      setWevo(newWevo);

      try {
        setIsWevoSyncing(true);
        await saveWevoAsync({ id: wevo.id, savedAudienceGroupId: null });
      } catch (err) {
        setAudienceDisplay(prevAudienceDisplay);
        setWevo(prevWevo);
        snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating audience selection');
      } finally {
        setIsWevoSyncing(false);
      }
    }
  };

  return (
    <Box>
      <Box>
        <Header name={'Audience'} description={'Which audience are you using?'} isRequired={true} />
        <List>
          <IntakeTooltip
            title={hasPreviouslySavedAudiences ? '' : "There aren't any saved audiences yet"}
            arrow>
            <span>
              <StyledListItemButton
                primaryListItemText={'Saved audience'}
                endText={'Recommended'}
                isSelected={audienceDisplay === AudienceDisplay.SavedAudience}
                isDisabled={!hasPreviouslySavedAudiences}
                onClick={() => handleAudienceDisplayClick(AudienceDisplay.SavedAudience)}
              />
            </span>
          </IntakeTooltip>
          <StyledListItemButton
            primaryListItemText={'New audience'}
            isSelected={audienceDisplay === AudienceDisplay.SelectAudience}
            onClick={() => handleAudienceDisplayClick(AudienceDisplay.SelectAudience)}
          />
          {isBYOPSupported && <StyledListItemButton primaryListItemText={'Your own audience'} />}
        </List>
      </Box>
      {audienceDisplay === AudienceDisplay.SavedAudience && (
        <Box mt={5}>
          <SavedAudiences
            audiences={nonDraftAudiences}
            selectedAudienceId={wevo?.savedAudienceGroupId}
            onSelectSavedAudience={handleSelectSavedAudience}
            onDeleteSavedAudience={handleDeleteSavedAudience}
          />
        </Box>
      )}
      {audienceDisplay === AudienceDisplay.SelectAudience && (
        <Box mt={5}>
          <NewAudience
            audience={wevo?.audience}
            savedAudienceGroup={wevo?.savedAudienceGroup}
            categoryId={wevo?.audienceCategory?.id}
            onWevoChanged={handleWevoChanged}
            onAudienceChanged={handleAudienceChanged}
            onAudienceNameChanged={handleAddSavedAudience}
            onDeleteSavedAudience={handleDeleteSavedAudienceDraft}
            errors={wevoErrors?.audienceErrors?.[String(wevo.id)] || {}}
          />
        </Box>
      )}
    </Box>
  );
};

export default AudienceIntakeSection;
