import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFetchExperiences } from '../../../hooks/useExperience';
import {
  KeyFindingValues,
  MetricTypes,
  QualitativeExpectationsValues,
} from '../../../modules/intake/constants';
import { getSelectedMetricName } from '../../../modules/intake/helpers';
import { getUserProfile } from '../../../modules/user/selectors';
import { fetchWevo } from '../../../modules/wevos/actions';
import {
  DEVICE_ID_TO_NAME,
  getAudienceCategoryNameById,
  WevoTestType,
  WevoType,
} from '../../../modules/wevos/constants';
import { getCurrentWevo } from '../../../modules/wevos/selectors';
import ExpandableText from '../../../ui/ExpandableText';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import { ExperienceGoalSelection } from '../../intake/edit/ExperienceScreenSelection';
import useFetchCustomQuestions from '../hooks/useFetchCustomQuestions';
import useFetchCustomScreenerQuestions from '../hooks/useFetchCustomScreenerQuestions';
import AudienceReviewCard from './AudienceReviewCard';
import CustomAudienceReviewCard from './CustomAudienceReviewCard';
import CustomScreenerReviewCard from './CustomScreenerReviewCard';
import GroupReviewCard from './GroupReviewCard';
import PageReviewCard, { PrimerReviewCard } from './PageReviewCard';
import { PreviewSurveyLinkRequestModal } from './PreviewSurveyLinkRequestModal';

const StyledCard = styled(Card)(() => ({
  borderRadius: '15px',
}));

const styles = {
  title: {
    paddingTop: (theme) => theme.spacing(4),
    paddingBottom: (theme) => theme.spacing(4),
  },
  honorNewLines: {
    whiteSpace: 'pre-line',
  },
  tableRow: {
    '&:last-child th, &:last-child td': {
      borderBottom: 0,
    },
  },
  breakEmailText: {
    maxWidth: 400,
    fontSize: '13px',
    whiteSpace: 'wrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: (theme) => theme.spacing(1),
  },
  emailsSection: {
    marginTop: (theme) => theme.spacing(-3),
    marginBottom: (theme) => theme.spacing(-2),
  },
  goalContent: {
    marginTop: (theme) => theme.spacing(-3),
    whiteSpace: 'pre-line',
  },
};

const UsabilityCard = ({ wevo }) => {
  return wevo.isUsabilityTestType ? (
    <Grid item>
      <StyledCard elevation={4}>
        <Table aria-label="Usability">
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="h5">Usability Test</Typography>
              </TableCell>
              <TableCell align="right" style={{ textTransform: 'capitalize' }}>
                Yes
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledCard>
    </Grid>
  ) : null;
};

const NumRespondentsCard = ({ wevo }) => {
  return (
    <Grid item>
      <StyledCard elevation={4}>
        <Table aria-label="NumRespondents">
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="h5">Num Respondents</Typography>
              </TableCell>
              <TableCell align="right" style={{ textTransform: 'capitalize' }}>
                {wevo?.numTargetedRespondentsPerPage}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledCard>
    </Grid>
  );
};

/**
 * Shows test details (similar to Review) after test has been submitted.
 */
const ViewTest = (props) => {
  const { wevoId } = useParams();
  const dispatch = useDispatch();
  const wevo = useSelector(getCurrentWevo);
  const user = useSelector(getUserProfile);

  const selectedMetricName = getSelectedMetricName(wevo?.metricType);

  const [isPreviewSurveyLinkRequestModalOpen, setIsPreviewSurveyLinkRequestModalOpen] = useState(false);

  const experiencesRequests = useFetchExperiences(
    (wevo?.pages || []).map((page) => {
      return { wevoId: wevo.id, pageId: page.id, includeConfig: true };
    }),
    {
      enabled:
        wevo?.type === WevoType.Journey && wevo?.metricType === MetricTypes.Standard && wevo?.hasExperience,
    }
  );

  const experiences = useMemo(() => {
    const loading = experiencesRequests.some((request) => request.isLoading);

    if (loading) {
      return [];
    }

    return experiencesRequests.map((request) => request?.data).filter((experience) => !!experience);
  }, [experiencesRequests]);

  const { data: groups } = useFetchCustomQuestions(wevo.id);
  const { data: screenerQuestions } = useFetchCustomScreenerQuestions({ wevoId: wevo?.id });

  const emailsToNotify = wevo?.emailsToNotify || [];

  // hiding the request preview button for now
  // const handlePreviewSurveyLinkRequestClicked = () => {
  //   setIsPreviewSurveyLinkRequestModalOpen(true);
  // };

  const handleClosePreviewSurveyLinkRequest = () => {
    setIsPreviewSurveyLinkRequestModalOpen(false);
  };

  useEffect(() => {
    dispatch(fetchWevo(wevoId));
  }, [dispatch, wevoId]);

  useTrackPageLoad({ name: TrackEvent.VIEWED_TEST_DETAILS, properties: { wevoId: wevo?.analyticsId } });

  return (
    <Container maxWidth="md" sx={{ pb: 12 }}>
      <Fragment>
        <Typography sx={styles.title} variant="h3" align="center">
          View Test Details
        </Typography>

        <Grid container direction="row" spacing={4} justifyContent="center">
          <Grid container item direction="column" spacing={4} xs>
            <Grid container item direction="column" spacing={2}>
              <Grid container item alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h4">Info</Typography>
                </Grid>
              </Grid>
              <Grid item>
                {(wevo.name || wevo.ownerName) && (
                  <StyledCard elevation={4}>
                    <TableContainer>
                      <Table aria-label="Info">
                        {wevo.name && (
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5">Test Name</Typography>
                              </TableCell>
                              <TableCell align="right">{wevo.name}</TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                        {wevo.ownerName && (
                          <TableBody>
                            <TableRow sx={styles.tableRow}>
                              <TableCell>
                                <Typography variant="h5">Test Owner</Typography>
                              </TableCell>
                              <TableCell align="right">{wevo.ownerName}</TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </StyledCard>
                )}
              </Grid>
              {Boolean(wevo?.emailsToNotify?.length) && (
                <Grid item>
                  <StyledCard elevation={4}>
                    <CardHeader title="Team emails" />
                    <CardContent sx={styles.emailsSection}>
                      {emailsToNotify?.map((teamEmail, index) => {
                        return (
                          <Typography variant="body2" sx={styles.breakEmailText} key={index}>
                            {teamEmail}
                          </Typography>
                        );
                      })}
                    </CardContent>
                  </StyledCard>
                </Grid>
              )}
              {wevo?.description && (
                <Grid item>
                  <StyledCard elevation={4}>
                    <CardHeader title="Primary Goal" />
                    <CardContent sx={styles.goalContent}>
                      <Typography variant="body2" color="textSecondary">
                        {`"${wevo?.description}"`}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              )}
              {wevo?.secondaryTestGoal && (
                <Grid item>
                  <StyledCard elevation={4}>
                    <CardHeader title="Secondary Goal" />
                    <CardContent sx={styles.goalContent}>
                      <Typography variant="body2" color="textSecondary">
                        {wevo.secondaryTestGoal}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              )}
              {wevo?.metricType && (
                <Grid item>
                  <StyledCard elevation={4}>
                    <Table size="small" aria-label="Type">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <CardHeader
                              style={{ marginLeft: '-16px' }}
                              sx={styles.cardHeader}
                              title="Metric selected"
                            />
                          </TableCell>
                          <TableCell align="right">{selectedMetricName}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </StyledCard>
                </Grid>
              )}
            </Grid>
            <Grid container item direction="column" spacing={2}>
              <Grid container item alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5">Target Audience Criteria</Typography>
                </Grid>
              </Grid>
              {wevo.audienceCategory && (
                <Grid item>
                  <StyledCard elevation={4}>
                    <Table size="small" aria-label="Group Type">
                      <TableBody>
                        <TableRow sx={styles.tableRow}>
                          <TableCell>
                            <CardHeader
                              style={{ marginLeft: '-15px' }}
                              sx={styles.cardHeader}
                              title="Group Type"
                            />
                          </TableCell>
                          <TableCell align="right">
                            {getAudienceCategoryNameById(wevo.audienceCategory && wevo.audienceCategory.id)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </StyledCard>
                </Grid>
              )}
              {wevo?.audience && (
                <Fragment>
                  {wevo?.audience?.attributes?.map((audience) =>
                    audience.isSelected ? (
                      <Grid key={audience.id} item>
                        <AudienceReviewCard audience={audience} />
                      </Grid>
                    ) : null
                  )}

                  {wevo.audience.description && (
                    <Grid item>
                      <CustomAudienceReviewCard customAudience={wevo.audience.description} />
                    </Grid>
                  )}
                </Fragment>
              )}
              {/* custom screener questions */}
              {screenerQuestions?.map((question) => (
                <Grid item key={question?.id} xs>
                  <CustomScreenerReviewCard
                    questionName={question?.name}
                    questionText={question?.questionText}
                    questionType={question?.questionType}
                    labelsType={question?.details?.labelsType}
                    isStyled
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid container item direction="column" spacing={4} xs>
            <Grid container item direction="column" spacing={2}>
              <Grid container item alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h4">Test Type</Typography>
                </Grid>
              </Grid>
              {wevo.type && (
                <Grid item>
                  <StyledCard elevation={4}>
                    <Table size="small" aria-label="Type">
                      <TableBody>
                        <TableRow sx={styles.tableRow}>
                          <TableCell>
                            <CardHeader style={{ marginLeft: '-15px' }} sx={styles.cardHeader} title="Type" />
                          </TableCell>
                          <TableCell align="right" style={{ textTransform: 'capitalize' }}>
                            {wevo.type}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </StyledCard>
                </Grid>
              )}
              <UsabilityCard wevo={wevo} />
              <NumRespondentsCard wevo={wevo} />
              {Array.isArray(wevo.devices) && (
                <Grid item>
                  <StyledCard elevation={4}>
                    <Table size="small" aria-label="Device">
                      <TableBody>
                        <TableRow sx={styles.tableRow}>
                          <TableCell>
                            <CardHeader
                              style={{ marginLeft: '-15px' }}
                              sx={styles.cardHeader}
                              title="Device"
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                              {DEVICE_ID_TO_NAME[wevo.devices[0]]}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </StyledCard>
                </Grid>
              )}
              {wevo.type === WevoType.Journey &&
                wevo?.testType === WevoTestType.Page &&
                (wevo?.details?.journeyStartUrl || wevo?.pages?.[0]?.journeyStartUrl) && (
                  <Grid item>
                    <StyledCard elevation={4} sx={{ padding: 2 }}>
                      <Typography variant="h5" gutterBottom>
                        Journey Start
                      </Typography>
                      <Typography style={{ fontSize: '12px' }} variant="caption" color="textSecondary">
                        {wevo?.pages?.[0]?.journeyStartUrl ?? wevo.details.journeyStartUrl}
                      </Typography>
                    </StyledCard>
                  </Grid>
                )}
              {experiences?.length > 0 &&
                wevo.metricType === MetricTypes.Standard &&
                wevo.type === WevoType.Journey &&
                experiences.map((experience) => {
                  return (
                    <Grid item key={experience.id}>
                      <StyledCard elevation={4} sx={{ padding: 2 }}>
                        <Typography variant="h5" gutterBottom>
                          Goal Page
                        </Typography>
                        <ExperienceGoalSelection
                          wevo={wevo}
                          experience={experience}
                          orientation={'horizontal'}
                          isEditable={false}
                          maxImageHeight={250}
                        />
                      </StyledCard>
                    </Grid>
                  );
                })}
              {wevo?.details?.taskToComplete && wevo.type === WevoType.Journey && (
                <Grid item>
                  <StyledCard elevation={4} sx={{ padding: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Task To Complete
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={styles.honorNewLines}>
                      {wevo.details.taskToComplete}
                    </Typography>
                  </StyledCard>
                </Grid>
              )}
              {wevo?.visitorObjective && (
                <Grid item>
                  <StyledCard elevation={4} sx={{ padding: 2 }}>
                    <ExpandableText
                      header="Expectations Gap"
                      headerVariant="h5"
                      content={wevo.visitorObjective}
                      contentVariant="caption"
                      contentColor="textSecondary"
                      buttonFontSize="12px"
                    />
                  </StyledCard>
                </Grid>
              )}
              <Grid item>
                <StyledCard elevation={4}>
                  <Table size="small" aria-label="Type">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <CardHeader
                            style={{ marginLeft: '-15px' }}
                            sx={styles.cardHeader}
                            title="Key Findings"
                          />
                        </TableCell>
                        <TableCell align="right" style={{ textTransform: 'capitalize' }}>
                          {typeof wevo?.details?.includeKeyFindings !== 'boolean' ||
                          wevo?.details?.includeKeyFindings
                            ? ` ${KeyFindingValues.Yes}`
                            : ` ${KeyFindingValues.No}`}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </StyledCard>
              </Grid>
              <Grid item>
                <StyledCard elevation={4}>
                  <Table size="small" aria-label="Type">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <CardHeader
                            style={{ marginLeft: '-15px' }}
                            sx={styles.cardHeader}
                            title="Diagnostics"
                          />
                        </TableCell>
                        <TableCell align="right" style={{ textTransform: 'capitalize' }}>
                          {typeof wevo?.details?.includeKeyFindings !== 'boolean' ||
                          wevo?.details?.includeDiagnostics
                            ? ` Yes`
                            : ` No`}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </StyledCard>
              </Grid>
              <Grid item>
                <StyledCard elevation={4}>
                  <Table size="small" aria-label="Type">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <CardHeader
                            style={{ marginLeft: '-15px' }}
                            sx={styles.cardHeader}
                            title="Expectations"
                          />
                        </TableCell>
                        <TableCell align="right" style={{ textTransform: 'capitalize' }}>
                          {typeof wevo?.details?.includeExpectations !== 'boolean' ||
                          wevo?.details?.includeExpectations
                            ? ` Yes`
                            : ` No`}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </StyledCard>
              </Grid>
              <Grid item>
                <StyledCard elevation={4}>
                  <Table size="small" aria-label="Type">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <CardHeader
                            style={{ marginLeft: '-15px' }}
                            sx={styles.cardHeader}
                            title="Qualitative Expectations"
                          />
                        </TableCell>
                        <TableCell align="right" style={{ textTransform: 'capitalize' }}>
                          {typeof wevo?.details?.includeQualitativeExpectations !== 'boolean' ||
                          wevo?.details?.includeQualitativeExpectations
                            ? ` ${QualitativeExpectationsValues.Yes}`
                            : ` ${QualitativeExpectationsValues.No}`}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </StyledCard>
              </Grid>
              {(wevo?.pages?.[0]?.primerId || wevo?.pages?.[0]?.primerContext) && (
                <Grid item>
                  <PrimerReviewCard draft={wevo} isStyled />
                </Grid>
              )}
            </Grid>
            {(wevo?.pages ?? []).length > 0 && (
              <Grid container item direction="row" spacing={2}>
                <Grid item>
                  <Typography variant="h5">Assets</Typography>
                </Grid>
                {(wevo?.pages ?? []).map((page) => (
                  <Grid item xs={12} key={page.id}>
                    <PageReviewCard
                      draft={wevo}
                      page={page}
                      experience={experiences.find(
                        (experience) => String(experience?.wevoPageId) === String(page.id)
                      )}
                      isStyled
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
          {groups?.length > 0 && wevo?.metricType !== MetricTypes.MastercardCds && (
            <Grid container item direction="column" spacing={4} xs>
              {/* 3rd Column */}
              <Grid container item direction="column" spacing={2}>
                {/* Top Section */}
                <Grid container item alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h4">Custom Questions</Typography>
                  </Grid>
                </Grid>
                {groups?.map((group) => (
                  <Grid item key={group.groupId} xs>
                    <GroupReviewCard group={group} type={wevo.type} pages={wevo?.pages ?? []} isStyled />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Fragment>
      {/* hiding the request preview button for now */}
      {/* <Box my={2} display="flex" justifyContent="right">
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handlePreviewSurveyLinkRequestClicked}>
          Request Preview
        </Button>
      </Box> */}
      {/* only mount this modal when the user data is valid, otherwise the initial state is wrong */}
      {user && user.id && (
        <PreviewSurveyLinkRequestModal
          wevoId={wevo.id}
          initialRecipientEmails={[user.email]}
          open={isPreviewSurveyLinkRequestModalOpen}
          onClose={handleClosePreviewSurveyLinkRequest}
        />
      )}
    </Container>
  );
};

export default ViewTest;
