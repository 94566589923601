import { Box } from '@mui/material';
import { ReactComponent as InfoIconFilled } from '../../../assets/info-icon-filled.svg';
import DebouncedInput from '../edit/DebouncedInput';
import { CustomFormControlCheckbox } from './CustomFormControlLabel';
import CustomTextField from './CustomTextField';
import IntakeTooltip from './IntakeTooltip';

const CheckboxWithTextField = ({
  label,
  isChecked,
  inputLabel,
  inputValue,
  tooltipProps,
  onCheckboxChange,
  onInputChange,
}) => {
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <CustomFormControlCheckbox
          checked={isChecked}
          label={label}
          onChange={(ev) => onCheckboxChange(ev.target.checked)}
        />
        {tooltipProps && (
          <IntakeTooltip {...tooltipProps}>
            <InfoIconFilled />
          </IntakeTooltip>
        )}
      </Box>
      {isChecked && (
        <Box sx={{ marginBottom: 1, paddingLeft: '26px' }}>
          <DebouncedInput
            value={inputValue}
            onChange={(value) => onInputChange(value)}
            debounceMs={500}
            renderInput={({ value, onChange }) => (
              <CustomTextField
                value={value}
                label={inputLabel}
                InputLabelProps={{ shrink: true }}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: 14,
                  },
                }}
                onChange={onChange}
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
};

export default CheckboxWithTextField;
