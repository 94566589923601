import { Box, ButtonBase, CircularProgress, Typography } from '@mui/material';
import { ReactComponent as IntakeAddIcon } from '../../../assets/intake-add-icon.svg';

const SelectableTile = ({
  label,
  description,
  iconComponent,
  size = 'medium',
  isSelected,
  onSelect,
  isToggleable = true,
  layout = 'vertical',
  fontSize = '13px',
  fontWeight,
  descriptionFontSize = '12px',
  descriptionFontWeight = 400,
  width,
  minHeight,
  isDisabled = false,
  ...rest
}) => {
  const sizeStyleOverrides = () => {
    if (size === 'small') {
      return {
        paddingY: 1.5,
      };
    }

    if (size === 'medium') {
      return {
        paddingY: 2.5,
      };
    }
    if (size === 'large') {
      return {
        paddingY: 4,
      };
    }

    return {};
  };
  const buttonTextFontWeight = () => {
    if (fontWeight) {
      return fontWeight;
    }
    return isSelected && isToggleable ? 500 : 400;
  };
  return (
    <ButtonBase
      onClick={onSelect}
      disabled={isDisabled}
      sx={{
        width,
        minHeight,
        display: 'flex',
        flexDirection: 'column',
        paddingY: iconComponent ? 4 : 2.5,
        textAlign: 'center',
        cursor: 'pointer',
        border: isSelected && isToggleable ? '1.5px solid #3B6CAB' : '1.5px solid #C7D6DF',
        borderRadius: '10px',
        backgroundColor: isSelected && isToggleable ? '#F3F8FB' : '#FFFFFF',
        '&:hover': {
          backgroundColor: '#F3F8FB',
        },
        '&:focus-visible': {
          backgroundColor: '#F3F8FB',
        },
        ...sizeStyleOverrides(),
      }}
      {...rest}>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          flexDirection: layout === 'vertical' ? 'column' : 'row',
          alignItems: 'center',
        }}>
        {iconComponent && (
          <Box display="flex" alignItems="center">
            {iconComponent}
          </Box>
        )}
        <Typography fontSize={fontSize} fontWeight={buttonTextFontWeight()}>
          {label}
        </Typography>
        {description && (
          <Typography
            fontSize={descriptionFontSize}
            fontWeight={descriptionFontWeight}
            sx={{ color: '#878787' }}>
            {description}
          </Typography>
        )}
      </Box>
    </ButtonBase>
  );
};

export default SelectableTile;

export function AddListItemButton({ onClick, isLoading = false, isDisabled = false, ...rest }) {
  const icon = isLoading ? <CircularProgress size="1rem" /> : <IntakeAddIcon />;

  return (
    <SelectableTile
      label={'Add'}
      isDisabled={isDisabled}
      isToggleable={false}
      fontWeight={500}
      iconComponent={icon}
      onSelect={onClick}
      layout="horizontal"
      {...rest}
    />
  );
}
