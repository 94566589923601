import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, Collapse, Drawer, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { cloneDeep, isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { generatePath, Link, useLocation } from 'react-router-dom';
import { ReactComponent as IntakeAudienceIcon } from '../../../../src/assets/intake-audience.svg';
import { ReactComponent as IntakeBuildIcon } from '../../../../src/assets/intake-build.svg';
import { ReactComponent as IntakeDetailsIcon } from '../../../../src/assets/intake-details.svg';
import { ReactComponent as IntakeReviewIcon } from '../../../../src/assets/intake-review.svg';
import { ReactComponent as IntakeStartIcon } from '../../../../src/assets/intake-start-icon.svg';
import { ReactComponent as VerticalLineIcon } from '../../../../src/assets/vertical-line.svg';
import { IntakeSectionNames, IntakeSubsectionNames } from '../../../modules/intake/constants';
import { Paths } from '../../../routes';
import IntakeTooltip from '../components/IntakeTooltip';
import { isCustomSurveyType } from './helpers/wevo';

const drawerWidth = 230;

const IntakeStartSection = {
  name: IntakeSectionNames.Start,
  icon: IntakeStartIcon,
  path: Paths.intake.intakeStart,
  isDisabled: false,
};

const IntakeStepDetails = [
  {
    name: IntakeSectionNames.Audience,
    icon: IntakeAudienceIcon,
    path: Paths.intake.intakeAudience,
    isDisabled: false,
  },
  {
    name: IntakeSectionNames.Build,
    icon: IntakeBuildIcon,
    isDisabled: false,
    subsections: [
      { name: IntakeSubsectionNames.Setup, path: Paths.intake.intakeSetup, isDisabled: false },
      { name: IntakeSubsectionNames.Asset, path: Paths.intake.intakeAsset, isDisabled: false },
    ],
  },
  {
    name: IntakeSectionNames.Details,
    icon: IntakeDetailsIcon,
    isDisabled: false,
    subsections: [
      { name: IntakeSubsectionNames.Goal, path: Paths.intake.intakeGoal, isDisabled: false },
      { name: IntakeSubsectionNames.PrimerAndTask, path: Paths.intake.intakePrimerAndTask, isDisabled: false },
      { name: IntakeSubsectionNames.Expectations, path: Paths.intake.intakeExpectations, isDisabled: false },
      {
        name: IntakeSubsectionNames.CustomQuestions,
        path: Paths.intake.intakeCustomQuestions,
        isDisabled: false,
      },
    ],
  },
  {
    name: IntakeSectionNames.Review,
    icon: IntakeReviewIcon,
    path: Paths.intake.intakeReview,
    isDisabled: false,
  },
];

const NavSubItem = ({ name, path, isSelected, isDisabled, disabledText = 'Not included in your report' }) => {
  return (
    <IntakeTooltip title={isDisabled ? disabledText : ''} arrow placement={'right'}>
      <Box>
        <ListItemButton
          component={isDisabled ? 'div' : Link}
          to={isDisabled ? undefined : path}
          disabled={isDisabled}
          sx={{
            paddingLeft: 4.5,
            '&:hover': {
              borderRadius: '12px',
            },
          }}>
          <ListItemIcon sx={{ minWidth: 15 }}>{isSelected && <VerticalLineIcon />}</ListItemIcon>
          <ListItemText
            primary={name}
            primaryTypographyProps={{
              fontSize: 12,
              fontWeight: isSelected ? 700 : 500,
            }}
          />
        </ListItemButton>
      </Box>
    </IntakeTooltip>
  );
};

const NavItem = ({
  wevoId,
  name,
  icon,
  path,
  subItems,
  selectedSubItem,
  isSelected,
  isDisabled,
  disabledText = 'Not included in your report',
}) => {
  const IconComponent = icon;
  const hasSubItems = subItems?.length > 0;

  return (
    <IntakeTooltip title={isDisabled ? disabledText : ''} arrow placement={'right'}>
      <Box>
        <ListItemButton
          component={isDisabled ? 'div' : Link}
          to={isDisabled ? undefined : path}
          disabled={isDisabled}
          sx={{
            position: 'relative',
            paddingRight: 1,
            '&:hover': {
              borderRadius: '12px',
            },
          }}>
          {IconComponent && (
            <ListItemIcon
              sx={{
                minWidth: 35,
                fill: (theme) => (isSelected ? theme.palette.primary.main : theme.palette.text.primary),
              }}>
              <IconComponent name={name} width="20px" height="20px" />
            </ListItemIcon>
          )}
          <ListItemText
            primary={name}
            primaryTypographyProps={{
              fontSize: 12,
              fontWeight: isSelected ? 780 : 500,
            }}
          />
          {hasSubItems && !isSelected && <ExpandMoreRoundedIcon />}
        </ListItemButton>

        {hasSubItems && (
          <Collapse in={isSelected} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {subItems?.map((subItem) => (
                <NavSubItem
                  key={subItem.name}
                  name={subItem.name}
                  path={generatePath(subItem.path, { wevoId })}
                  isSelected={selectedSubItem === subItem.name}
                  isDisabled={subItem.isDisabled}
                  disabledText={subItem?.disabledText}
                />
              ))}
            </List>
          </Collapse>
        )}
      </Box>
    </IntakeTooltip>
  );
};

const NavMenu = ({ wevo, userCustomizations }) => {
  const location = useLocation();

  const wevoId = wevo?.id;

  //top level item in menu that is selected
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedSubItem, setSelectedSubItem] = useState(null);

  const intakeSteps = useMemo(() => {
    let steps = [];
    const copyOfIntakeStepDetails = cloneDeep(IntakeStepDetails);
    const wevoDetails = wevo?.details;
    const isMastercard = Boolean(userCustomizations?.isMastercard);

    if (isMastercard) {
      steps = [{ ...IntakeStartSection }].concat(copyOfIntakeStepDetails);
    } else {
      steps = copyOfIntakeStepDetails;
    }

    let disabledItems = {};

    // menu items to exclude/disable
    if (!wevoDetails?.includeExpectations) {
      disabledItems[IntakeSubsectionNames.Expectations] = { isDisabled: true };
    }

    // custom surveys that have fewer than two pages are don't need the Asset section
    // The asset section should only be shown if there are other pages to give the user
    // the opportunity to remove the extra pages.
    if (isCustomSurveyType(wevo) && wevo?.pages?.length < 2) {
      disabledItems[IntakeSubsectionNames.Asset] = {
        isDisabled: true,
        disabledText: 'Not included in your report.',
      };
    }

    const updateDisabledItems = (steps, disabledItems) => {
      if (isEmpty(disabledItems)) {
        return;
      }

      steps.forEach((step) => {
        // Update the main step if its name exists in the updates object
        if (disabledItems.hasOwnProperty(step.name)) {
          step.isDisabled = disabledItems[step.name].isDisabled;
          step.disabledText = disabledItems[step.name]?.disabledText;
        }
        // Update subsections if they exist
        if (step.subsections) {
          step.subsections.forEach((subsection) => {
            if (disabledItems.hasOwnProperty(subsection.name)) {
              subsection.isDisabled = disabledItems[subsection.name].isDisabled;
              subsection.disabledText = disabledItems[subsection.name]?.disabledText;
            }
          });
        }
      });
    };

    updateDisabledItems(steps, disabledItems);

    return steps;
  }, [userCustomizations, wevo]);

  const getFirstActiveSubStep = (step) => {
    for (const subsection of step?.subsections ?? []) {
      if (subsection.isDisabled) continue;
      return subsection;
    }
  };

  useEffect(() => {
    const path = location.pathname;

    // Loop through sections and subsections to find matching path
    intakeSteps.forEach((section) => {
      if (generatePath(section.path, { wevoId }) === path) {
        setSelectedItem(section.name);
        setSelectedSubItem(null);
      } else if (section?.subsections) {
        const matchingSubItem = section.subsections.find(
          (item) => generatePath(item.path, { wevoId }) === path
        );
        if (matchingSubItem) {
          setSelectedItem(section.name);
          setSelectedSubItem(matchingSubItem.name);
        }
      }
    });
  }, [intakeSteps, location.pathname, wevoId]);

  const renderMenuItems = (menuItems) => {
    return menuItems.map((step) => {
      return (
        <Box
          key={step.name}
          sx={{
            marginBottom: 1,
            backgroundColor: selectedItem === step.name ? '#E0E5EE' : 'transparent',
            borderRadius: '12px',
            color: (theme) =>
              selectedItem === step.name ? theme.palette.primary.main : theme.palette.text.primary,
          }}>
          <NavItem
            wevoId={wevoId}
            name={step.name}
            icon={step.icon}
            path={generatePath(step.path || getFirstActiveSubStep(step)?.path, { wevoId })}
            subItems={step?.subsections}
            selectedSubItem={selectedSubItem}
            isSelected={selectedItem === step.name}
            isDisabled={step?.isDisabled}
            disabledText={step?.disabledText}
          />
        </Box>
      );
    });
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: '#F4F4F6' },
      }}>
      <Box sx={{ marginTop: 10, overflow: 'auto' }}>
        <List component="nav" sx={{ paddingX: 2 }}>
          {renderMenuItems(intakeSteps)}
        </List>
      </Box>
    </Drawer>
  );
};

export default NavMenu;
