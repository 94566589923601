import { Box, Typography } from '@mui/material';
import { ReactComponent as AlertIcon } from '../../../assets/alert-icon.svg';
import { ReactComponent as InfoIconFilled } from '../../../assets/info-icon-filled.svg';

function Notice({ icon, message, textColor, fontWeight = 400, fontSize = 14, iconSize = 14, ...rest }) {
  return (
    <Box display="flex" gap={1} {...rest}>
      <Box sx={{ height: iconSize, minWidth: 20 }}>{icon}</Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ color: textColor }} fontWeight={fontWeight} fontSize={fontSize}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
}

export const InfoNotice = ({ message }) => {
  return <Notice icon={<InfoIconFilled width="100%" height="100%" />} message={message} variant="info" />;
};

export const ValidationErrorNotice = ({ message }) => {
  return (
    <Notice
      icon={<AlertIcon width="100%" height="100%" />}
      textColor="#EE404C"
      message={message}
      variant="error"
    />
  );
};

export default Notice;
