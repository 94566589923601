import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
} from '@mui/material';
import _ from 'lodash';
import { Fragment, useMemo, useState } from 'react';
import { Link as RouteLink, generatePath, useRouteMatch } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { Paths } from '../../routes';
import { TrackEvent } from '../analytics';
import SessionDetailsDialog from './SessionDetailsDialog';
import SessionMenu from './SessionMenu';
import ShareDialog from './ShareDialog';

const openedMixin = (theme, width) => ({
  width: width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  width: 0,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

const paperMixin = (theme) => ({
  backgroundColor: theme.palette.secondary.main,
  borderInlineEnd: 'none',
  maxHeight: '100vh',
  paddingBottom: theme.spacing(3),
  overflowX: 'hidden',
  msOverflowStyle: 'none', // IE and edge
  scrollbarWidth: 'none', // firefox
  '&::-webkit-scrollbar': {
    display: 'none', // chrome and safari
  },
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'width',
})(({ theme, open, width }) => ({
  width: width,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, width),
    '& .MuiDrawer-paper': { ...paperMixin(theme), ...openedMixin(theme, width) },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': { ...paperMixin(theme), ...closedMixin(theme) },
  }),
}));

const styles = {
  contentContainer: {
    paddingX: (theme) => theme.spacing(1),
    msOverflowStyle: 'none', // IE and edge
    scrollbarWidth: 'none', // firefox
    '&::-webkit-scrollbar': {
      display: 'none', // chrome and safari
    },
  },
  subheader: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontWeight: 600,
    backgroundColor: (theme) => theme.palette.secondary.main,
  },
  name: {
    color: (theme) => theme.palette.text.secondary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuIcon: { color: 'white', fontSize: 22 },
  font: { fontSize: 14 },
  menuOptionIcon: { fontSize: 18, marginRight: 2 },
  listItem: { '&:hover .menu-icon': { display: 'initial' } },
  listItemIcon: {
    display: 'none',
    minWidth: '24px',
    paddingLeft: 3,
  },
};

const SessionListItem = ({
  menuAnchorEl,
  sessionId,
  sessionName,
  isOpenMenu,
  isSelected,
  onItemClick,
  onItemHover,
  onMenuIconClick,
  onMenuClose,
  onDetailsClick,
  onShareClick,
}) => {
  return (
    <ListItem
      sx={styles.listItem}
      key={sessionId}
      disablePadding
      onMouseEnter={(ev) => {
        onItemHover(ev);
      }}>
      <ListItemButton
        component={RouteLink}
        selected={isSelected}
        sx={{ borderRadius: '10px' }}
        to={generatePath(Paths.automatedInsights.session, { sessionId })}
        onClick={onItemClick}>
        <ListItemText
          primary={sessionName}
          primaryTypographyProps={{
            fontSize: 14,
            fontWeight: 'normal',
            letterSpacing: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: (theme) => theme.palette.text.secondary,
          }}
        />
        <ListItemIcon
          sx={styles.listItemIcon}
          size="small"
          className="menu-icon"
          onClick={(ev) => onMenuIconClick(ev)}>
          <MoreVertIcon sx={styles.menuIcon} />
        </ListItemIcon>
      </ListItemButton>
      <SessionMenu
        id={'menu-session-details-share'}
        anchorEl={menuAnchorEl}
        open={isOpenMenu}
        onClose={() => onMenuClose()}
        onDetailsClick={() => onDetailsClick()}
        onShareClick={() => onShareClick()}
      />
    </ListItem>
  );
};

const SessionListSubItem = ({ sessionId, sessionName }) => {
  return (
    <ListItem sx={{ ...styles.listItem, paddingLeft: 4 }} key={sessionId} disablePadding>
      <ListItemButton
        component={RouteLink}
        sx={{ borderRadius: '10px' }}
        to={generatePath(Paths.automatedInsights.session, { sessionId })}
        aria-label="open link"
        target="_blank"
        rel="noopener noreferrer">
        <ListItemText
          primary={sessionName}
          primaryTypographyProps={{
            fontSize: 14,
            fontWeight: 'normal',
            letterSpacing: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: (theme) => theme.palette.text.secondary,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const SidebarSessionList = ({
  title,
  ariaLabel,
  headingId,
  selectedSessionId,
  sessions,
  onClick,
  sx = {},
  handleMenuIconClick,
  handleDetailsButtonClick,
  handleShareButtonClick,
  toggleShareDialog,
  toggleDetailsDialog,
  handleCloseMenu,
  setAnchorEl,
  clickedMenuSession,
  showMenuSession,
  showShareDialog,
  showDetailsDialog,
  selectedSessionShareUrl,
  selectedSessionUrls,
  selectedSessionGoals,
  anchorEl,
}) => {
  return (
    <List
      sx={{ ...styles.contentContainer, ...sx }}
      component="nav"
      aria-labelledby={ariaLabel}
      subheader={
        <ListSubheader component="div" id={headingId} sx={styles.subheader}>
          {title}
        </ListSubheader>
      }>
      {sessions?.map(
        (session) =>
          session?.deletedAt === null && (
            <Fragment key={session?.id}>
              <SessionListItem
                menuAnchorEl={anchorEl}
                sessionId={session?.id}
                sessionName={session?.name}
                isSelected={session?.id === selectedSessionId}
                isOpenMenu={session?.id === showMenuSession?.id}
                onItemClick={onClick}
                onItemHover={(ev) => setAnchorEl(ev.currentTarget)}
                onMenuIconClick={(ev) => handleMenuIconClick(ev, session)}
                onMenuClose={handleCloseMenu}
                onDetailsClick={handleDetailsButtonClick}
                onShareClick={handleShareButtonClick}
              />
              {session?.fromSessions?.length > 0 && (
                <Collapse in={session?.id === selectedSessionId} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {session?.fromSessions?.map((linkedSession) => (
                      <SessionListSubItem
                        key={linkedSession?.id}
                        sessionId={linkedSession?.id}
                        sessionName={linkedSession?.name}
                      />
                    ))}
                  </List>
                </Collapse>
              )}
            </Fragment>
          )
      )}
      <ShareDialog
        sessionId={clickedMenuSession?.id || ''}
        url={selectedSessionShareUrl || ''}
        isOpen={showShareDialog || false}
        onToggleShareDialog={() => toggleShareDialog()}
      />
      <SessionDetailsDialog
        session={clickedMenuSession}
        urls={selectedSessionUrls}
        goals={selectedSessionGoals}
        isOpen={showDetailsDialog || false}
        onToggleDetailsDialog={() => toggleDetailsDialog()}
      />
    </List>
  );
};

const Sidebar = ({
  sessionId,
  open,
  onToggleSidebar,
  previousSessions,
  setShowShareDialogFromLeftMenu,
  setShowDetailsDialog,
  showShareDialogFromLeftMenu,
  showDetailsDialog,
}) => {
  const basePathMatch = useRouteMatch({
    path: [Paths.automatedInsights.basePath],
    exact: true,
  });

  const { track } = useAnalytics();

  const [clickedMenuSession, setClickedMenuSession] = useState(null);
  const [showMenuSession, setShowMenuSession] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const selectedSessionShareUrl = useMemo(() => {
    if (!_.isEmpty(previousSessions) && clickedMenuSession) {
      return clickedMenuSession.shareUrl;
    }
  }, [previousSessions, clickedMenuSession]);

  const selectedSessionUrls = useMemo(() => {
    if (!_.isEmpty(previousSessions) && clickedMenuSession) {
      return clickedMenuSession.sessionUrls;
    }
  }, [previousSessions, clickedMenuSession]);

  const selectedSessionGoals = useMemo(() => {
    if (!_.isEmpty(previousSessions) && clickedMenuSession) {
      return clickedMenuSession.sessionGoals;
    }
  }, [previousSessions, clickedMenuSession]);

  const updateClickedMenuSession = (session) => {
    setClickedMenuSession(session);
    setShowMenuSession(session);
  };

  const handleDetailsButtonClick = () => {
    toggleDetailsDialog(true);
  };

  const toggleDetailsDialog = (open) => {
    if (open) {
      track(TrackEvent.CLICKED_PULSE_DETAILS_BUTTON, { sessionId: clickedMenuSession.id });
      handleCloseMenu();
    }
    if (!open) {
      setClickedMenuSession(null);
    }
    setShowDetailsDialog(open);
  };

  const handleShareButtonClick = () => {
    toggleShareDialogFromLeftNav(true);
  };

  const toggleShareDialogFromLeftNav = (open) => {
    if (open) {
      track(TrackEvent.CLICKED_PULSE_SHARE_BUTTON, { sessionId: clickedMenuSession.id });
      handleCloseMenu();
    }
    if (!open) {
      setClickedMenuSession(null);
    }
    setShowShareDialogFromLeftMenu(open);
  };

  const handleCloseMenu = () => {
    setShowMenuSession(null);
    setAnchorEl(null);
  };

  const handleLinkClick = () => {
    // if we are clicking a link to a session from the base path,
    // we will want to close the sidebar when navigating to the link path
    if (basePathMatch) {
      onToggleSidebar(false);
    } else return;
  };

  const handleMenuIconClick = (ev, session) => {
    ev.preventDefault();
    ev.stopPropagation();
    updateClickedMenuSession(session);
  };

  const previousSessionsByTimeframe = useMemo(() => {
    const now = new Date();

    return (previousSessions ?? []).reduce(
      (acc, cur) => {
        if (!cur.createdAt) {
          acc.earlier.push(cur);
          return acc;
        }

        const daysDelta = Math.floor((now - new Date(cur.createdAt)) / 1000 / 60 / 60 / 24);

        if (daysDelta < 1) {
          acc.recent.push(cur);
        } else if (daysDelta <= 7) {
          acc.week.push(cur);
        } else if (daysDelta <= 30) {
          acc.month.push(cur);
        } else {
          acc.earlier.push(cur);
        }

        return acc;
      },
      {
        recent: [],
        week: [],
        month: [],
        earlier: [],
      }
    );
  }, [previousSessions]);

  return (
    <StyledDrawer variant="permanent" anchor="left" open={open} width={300}>
      <Box sx={{ marginTop: '90px' }} />
      {previousSessionsByTimeframe.recent.length > 0 && (
        <SidebarSessionList
          title="Recent"
          ariaLabel="recent sessions"
          headingId="recent"
          onClick={handleLinkClick}
          sessions={previousSessionsByTimeframe.recent}
          selectedSessionId={sessionId}
          handleMenuIconClick={handleMenuIconClick}
          handleDetailsButtonClick={() => handleDetailsButtonClick()}
          handleShareButtonClick={() => handleShareButtonClick()}
          toggleShareDialog={() => toggleShareDialogFromLeftNav()}
          toggleDetailsDialog={() => toggleDetailsDialog()}
          handleCloseMenu={() => handleCloseMenu()}
          setAnchorEl={setAnchorEl}
          clickedMenuSession={clickedMenuSession}
          showMenuSession={showMenuSession}
          showShareDialog={showShareDialogFromLeftMenu}
          showDetailsDialog={showDetailsDialog}
          selectedSessionShareUrl={selectedSessionShareUrl}
          selectedSessionUrls={selectedSessionUrls}
          selectedSessionGoals={selectedSessionGoals}
          anchorEl={anchorEl}
        />
      )}
      {previousSessionsByTimeframe.week.length > 0 && (
        <SidebarSessionList
          title="Previous 7 days"
          ariaLabel="previous 7 days"
          headingId="week"
          onClick={handleLinkClick}
          sessions={previousSessionsByTimeframe.week}
          selectedSessionId={sessionId}
          handleMenuIconClick={handleMenuIconClick}
          handleDetailsButtonClick={() => handleDetailsButtonClick()}
          handleShareButtonClick={() => handleShareButtonClick()}
          toggleShareDialog={() => toggleShareDialogFromLeftNav()}
          toggleDetailsDialog={() => toggleDetailsDialog()}
          handleCloseMenu={() => handleCloseMenu()}
          setAnchorEl={setAnchorEl}
          clickedMenuSession={clickedMenuSession}
          showMenuSession={showMenuSession}
          showShareDialog={showShareDialogFromLeftMenu}
          showDetailsDialog={showDetailsDialog}
          selectedSessionShareUrl={selectedSessionShareUrl}
          selectedSessionUrls={selectedSessionUrls}
          selectedSessionGoals={selectedSessionGoals}
          anchorEl={anchorEl}
        />
      )}
      {previousSessionsByTimeframe.month.length > 0 && (
        <SidebarSessionList
          title="Previous 30 days"
          ariaLabel="previous 30 days"
          headingId="month"
          onClick={handleLinkClick}
          sessions={previousSessionsByTimeframe.month}
          selectedSessionId={sessionId}
          handleMenuIconClick={handleMenuIconClick}
          handleDetailsButtonClick={() => handleDetailsButtonClick()}
          handleShareButtonClick={() => handleShareButtonClick()}
          toggleShareDialog={() => toggleShareDialogFromLeftNav()}
          toggleDetailsDialog={() => toggleDetailsDialog()}
          handleCloseMenu={() => handleCloseMenu()}
          setAnchorEl={setAnchorEl}
          clickedMenuSession={clickedMenuSession}
          showMenuSession={showMenuSession}
          showShareDialog={showShareDialogFromLeftMenu}
          showDetailsDialog={showDetailsDialog}
          selectedSessionShareUrl={selectedSessionShareUrl}
          selectedSessionUrls={selectedSessionUrls}
          selectedSessionGoals={selectedSessionGoals}
          anchorEl={anchorEl}
        />
      )}
      {previousSessionsByTimeframe.earlier.length > 0 && (
        <SidebarSessionList
          title="Earlier"
          ariaLabel="earlier"
          headingId="earlier"
          onClick={handleLinkClick}
          sessions={previousSessionsByTimeframe.earlier}
          selectedSessionId={sessionId}
          handleMenuIconClick={handleMenuIconClick}
          handleDetailsButtonClick={() => handleDetailsButtonClick()}
          handleShareButtonClick={() => handleShareButtonClick()}
          toggleShareDialog={() => toggleShareDialogFromLeftNav()}
          toggleDetailsDialog={() => toggleDetailsDialog()}
          handleCloseMenu={() => handleCloseMenu()}
          setAnchorEl={setAnchorEl}
          clickedMenuSession={clickedMenuSession}
          showMenuSession={showMenuSession}
          showShareDialog={showShareDialogFromLeftMenu}
          showDetailsDialog={showDetailsDialog}
          selectedSessionShareUrl={selectedSessionShareUrl}
          selectedSessionUrls={selectedSessionUrls}
          selectedSessionGoals={selectedSessionGoals}
          anchorEl={anchorEl}
        />
      )}
    </StyledDrawer>
  );
};

export default Sidebar;
