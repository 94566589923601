import { styled, Switch } from '@mui/material';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  width: 52,
  '& .MuiSwitch-switchBase': {
    '& +.MuiSwitch-track': {
      backgroundColor: '#DCDCDC',
      borderRadius: 20,
      opacity: 1,
    },
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      '& +.MuiSwitch-track': {
        backgroundColor: '#3B6CAB',
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    height: 18,
    width: 18,
    margin: 1,
    color: '#FFFFFF',
    boxShadow: 'none',
  },
}));

const ToggleSwitch = ({ ...props }) => {
  return <StyledSwitch {...props} />;
};

export default ToggleSwitch;
