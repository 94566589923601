import produce from 'immer';
import { get as _get, omit as _omit, isNil } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { MutationKeys } from '../../../modules/intake/constants';

const deletePrimer = ({ wevoId, pageId, deletePrimerContext }) => {
  let url = `/api/v2/wevos/${wevoId}/pages/${pageId}/primer`;

  if (!isNil(deletePrimerContext)) {
    url = `${url}?deletePrimerContext=${deletePrimerContext}`;
  }

  return axios({
    url,
    method: 'DELETE',
  });
};

export default function useDeletePrimer() {
  const queryClient = useQueryClient();
  return useMutation(deletePrimer, {
    mutationKey: MutationKeys.deletePrimer,
    onMutate: async ({ wevoId, pageId }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['wevoData', { wevoId }]);

      // Snapshot the previous value
      const previousWevoState = await queryClient.getQueryData(['wevoData', { wevoId }]);

      await queryClient.setQueryData(['wevoData', { wevoId }], (oldData) => {
        return produce(oldData, (draft) => {
          const pages = _get(draft, 'pages', []);

          // Remove the page.
          const index = pages.findIndex((p) => String(p.id) === String(pageId));
          if (index >= 0) {
            pages[index] = _omit(pages[index], ['primerId', 'primer']);
          }
        });
      });

      // Return a context object with the snapshotted value
      return { previousWevoState };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, { wevoId, stepId }, { previousWevoState }) => {
      queryClient.setQueryData(['wevoData', { wevoId }], previousWevoState);
    },
  });
}
