import { Box } from '@mui/material';
import { Header } from '../../components';
import AssetUploadCard from '../../components/AssetUploadCard';
import { ValidationErrorNotice } from '../../components/Notice';
import { ReorderableList } from '../../components/ReorderableList';
import { MAX_DEEP_DIVE_STEPS } from '../constants';
import { serializeErrors, ValidationKeys } from '../helpers/validation/assets';
import { DraggableStepCard } from './StepCard';

function SentimentMapsConfiguration({
  wevo,
  page,
  onFilesUploaded,
  onReorderStep,
  onDeleteStep,
  onStepNameChanged,
  isUploadingSteps,
  isDeletingStep,
  errors,
}) {
  const handleFileInput = (files, fileRejections) => {
    onFilesUploaded && onFilesUploaded({ files, fileRejections, page });
  };

  const pageErrors = errors?.pageErrors?.[String(page.id)] ?? {};

  const hasStepErrors =
    pageErrors?.[ValidationKeys.StepLimitExceeded]?.length > 0 ||
    pageErrors?.[ValidationKeys.InsufficientSteps]?.length > 0;

  const stepErrors = () => {
    if (pageErrors?.[ValidationKeys.StepLimitExceeded]?.length > 0) {
      return serializeErrors(pageErrors?.[ValidationKeys.StepLimitExceeded]);
    }

    if (pageErrors?.[ValidationKeys.InsufficientSteps]?.length > 0) {
      return serializeErrors(pageErrors?.[ValidationKeys.InsufficientSteps]);
    }

    return '';
  };

  return (
    <Box>
      <Header
        name="Sentiment Map(s)"
        description="Upload the pages you want to see Sentiment Map(s) with respondents' likes and dislikes (min 1/max 4)."
        isRequired={true}
        hasPreview={true}
      />
      {hasStepErrors && (
        <Box my={1}>
          <ValidationErrorNotice message={stepErrors()} />
        </Box>
      )}
      <Box mb={2} />
      <AssetUploadCard
        handleFileInput={handleFileInput}
        multiple={MAX_DEEP_DIVE_STEPS > 1}
        isUploading={isUploadingSteps}
        maxFiles={MAX_DEEP_DIVE_STEPS}
        disabled={false}
      />
      <Box mb={2} />
      <Box>
        <ReorderableList
          onDragEnd={(props) => {
            if (!onReorderStep) return;

            const source = props.source;
            const destination = props.destination;

            if (!destination) {
              return;
            }

            if (source.index === destination.index) {
              return;
            }

            const step = page?.steps?.[source.index];

            onReorderStep({
              step,
              sourceIndex: source.index,
              destinationIndex: destination.index,
            });
          }}
          items={page?.steps ?? []}
          renderDraggable={(props) => {
            return (
              <Box mb={2}>
                <DraggableStepCard
                  wevo={wevo}
                  page={page}
                  step={props.item}
                  dragHandleProps={props.dragHandleProps}
                  onDeleteStep={onDeleteStep}
                  onStepNameChanged={onStepNameChanged}
                  isDeletingStep={isDeletingStep}
                  errors={errors?.stepErrors?.[String(props.item.id)] || {}}
                />
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
}

export default SentimentMapsConfiguration;
