import { Box, Typography } from '@mui/material';
import { CustomTextField } from '../../../../components';
import { ValidationErrorNotice } from '../../../../components/Notice';
import DebouncedInput from '../../../../edit/DebouncedInput';
import { serializeErrors } from '../../helpers';

function CustomQualitativeQuestion({ wevo, customQuestion, onCustomQuestionChanged, errors }) {
  return (
    <Box>
      <Typography mb={1}>Enter your question*</Typography>
      <DebouncedInput
        value={customQuestion.question.questionText || ''}
        onChange={(value) => {
          onCustomQuestionChanged({
            customQuestion: customQuestion,
            updateFields: { questionText: value },
          });
        }}
        debounceMs={500}
        renderInput={({ value, onChange }) => (
          <CustomTextField
            value={value}
            sx={{
              '& .MuiInputBase-root': {
                fontSize: 14,
              },
            }}
            onChange={onChange}
          />
        )}
      />
      {errors?.questionText?.length > 0 && (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(errors.questionText)} />
        </Box>
      )}
    </Box>
  );
}

export default CustomQualitativeQuestion;
