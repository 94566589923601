import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Grid, Typography } from '@mui/material';
import { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CustomTextField } from '../../../../components';
import ImageCard, { PlaceholderImagePreviewCard } from '../../../../components/ImageCard';
import { QuestionNotice } from './QuestionLogic';

function QuestionText({ questionText, noticeText }) {
  return (
    <Box>
      <Typography>{questionText}</Typography>
      {noticeText && <QuestionNotice noticeText={noticeText} />}
    </Box>
  );
}

function ReadOnlyHorizontalTextField({ label }) {
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box sx={{ width: 120 }}>
          <Typography fontSize={12}>{label}</Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <CustomTextField InputProps={{ readOnly: true }} />
        </Box>
      </Box>
    </Box>
  );
}

function ReadOnlyVerticalTextField({ label }) {
  return (
    <Box>
      <Box display="flex" flexDirection="column">
        <Box>
          <Typography fontSize={12}>{label}</Typography>
        </Box>
        <Box>
          <CustomTextField InputProps={{ readOnly: true }} />
        </Box>
      </Box>
    </Box>
  );
}

function ReadOnlyMatrixSingleChoiceQuestion({ columnValues, rowValues }) {
  const rowsData = rowValues.map((rowValue) => {
    return [<Typography fontSize={12}>{rowValue}</Typography>].concat(
      columnValues.map(() => (
        <Box textAlign="center">
          <RadioButtonUncheckedIcon />
        </Box>
      ))
    );
  });

  const headerRowData = [''].concat(columnValues).map((headerData) => {
    return (
      <Typography fontSize={12} textAlign="center">
        {headerData}
      </Typography>
    );
  });

  const matrixData = [headerRowData].concat(rowsData);

  return (
    <Box>
      {(matrixData ?? []).map((row, rowIndex) => {
        return (
          <Grid
            container
            key={`row_${rowIndex}`}
            mb={1}
            sx={{
              borderBottom: rowIndex === 0 ? '1px solid #C7D6DF' : '',
            }}>
            {row.map((col, colIndex) => {
              return (
                <Fragment key={`col_${colIndex}`}>
                  <Grid
                    item
                    xs={colIndex === 0 ? 3 : 9 / (row.length - 1)}
                    alignSelf="center"
                    sx={{
                      borderRight: colIndex === 0 ? '1px solid #C7D6DF' : '',
                    }}>
                    <Box height="100%">{col}</Box>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        );
      })}
    </Box>
  );
}

function ReadOnlyVerticalMultipleChoiceQuestion({ questionText, noticeText = null, choices }) {
  return (
    <Box>
      <QuestionText questionText={questionText} noticeText={noticeText} />
      <Box mb={1} />
      {choices.map((choice) => {
        return (
          <Box key={uuidv4()} display="flex" alignItems="center" mb={1}>
            <Box sx={{ width: 40 }} display="flex" alignItems="center">
              <RadioButtonUncheckedIcon sx={{ height: 20, width: 20 }} />
            </Box>
            <Box sx={{ flexGrow: 1 }}>{choice}</Box>
          </Box>
        );
      })}
    </Box>
  );
}

function ReadOnlyHorizontalMultipleChoiceQuestion({ questionText, noticeText = null, choices }) {
  return (
    <Box>
      <QuestionText questionText={questionText} noticeText={noticeText} />
      <Box mb={1} />
      <Grid container>
        {choices.map((choice, index) => {
          return (
            <Grid item key={uuidv4()} sx={{ width: `${100 / choices.length}%` }}>
              {index === 0 || index === choices.length - 1 ? (
                <Typography textAlign="center" fontSize={12}>
                  {choice}
                </Typography>
              ) : (
                <></>
              )}
            </Grid>
          );
        })}
      </Grid>
      <Box mb={1} />
      <Grid container>
        {choices.map((choice) => {
          return (
            <Grid item key={uuidv4()} sx={{ width: `${100 / choices.length}%` }}>
              <Box textAlign="center">
                <RadioButtonUncheckedIcon />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

function ReadOnlyVerticalMultipleSelectionQuestion({ questionText, noticeText = null, choices }) {
  return (
    <Box>
      <QuestionText questionText={questionText} noticeText={noticeText} />
      <Box mb={1} />
      {choices.map((choice) => {
        return (
          <Box key={uuidv4()} display="flex" alignItems="center" mb={1}>
            <Box sx={{ width: 40 }} display="flex" alignItems="center">
              <CheckBoxOutlineBlankIcon sx={{ height: 20, width: 20 }} />
            </Box>
            <Box sx={{ flexGrow: 1 }}>{choice}</Box>
          </Box>
        );
      })}
    </Box>
  );
}

function ReadOnlyHeatmapQuestion({ questionText, noticeText = null, images }) {
  return (
    <Box>
      <QuestionText questionText={questionText} noticeText={noticeText} />
      <Box mb={3} />
      {images ? <ImageCard images={images} /> : <PlaceholderImagePreviewCard />}
    </Box>
  );
}

export {
  QuestionText,
  ReadOnlyHeatmapQuestion,
  ReadOnlyHorizontalMultipleChoiceQuestion,
  ReadOnlyHorizontalTextField,
  ReadOnlyMatrixSingleChoiceQuestion,
  ReadOnlyVerticalMultipleChoiceQuestion,
  ReadOnlyVerticalMultipleSelectionQuestion,
  ReadOnlyVerticalTextField,
};
