import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, Chip, Container, TextField, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import * as EmailValidator from 'email-validator';
import { get as _get, isEmpty as _isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Metrics } from '../../../modules/intake/constants';
import { getSelectedMetricName } from '../../../modules/intake/helpers';
import * as WevoActions from '../../../modules/wevos/actions';
import { getActiveWevos } from '../../../modules/wevos/selectors';
import { WevoTagsComboBox } from '../../dashboard/Tags';
import DiscardMetricDialog from '../edit/DiscardMetricDialog';
import MetricSelection from './MetricSelection';

const EMAILS_TO_NOTIFY_LIMIT = 10;

const useCreateTestStyles = makeStyles((theme) => ({
  inputField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  nextBtn: {
    padding: '8px 24px',
  },
  deleteIcon: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.2),
    height: '10px',
    color: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
    strokeWidth: '0.5',
  },
  dropDownCard: {
    height: '40px',
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
  },
  dropDownChip: {
    '&:hover': {
      backgroundColor: grey[100],
    },
    backgroundColor: 'white',
    color: 'grey',
    fontSize: '12px',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.75),
  },
  emailChip: {
    backgroundColor: 'white',
    color: 'grey',
    fontSize: '12px',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: 390,
  },
  chipsArea: {
    maxHeight: 110,
    overflowY: 'auto',
    margin: theme.spacing(1, 0),
  },
  container: {
    padding: 0,
  },
}));

const buildSchema = (wevos, draft, emails, isCDS, isDQS, tagsList) =>
  yup.object({
    testName: yup
      .string()
      .trim()
      .required('You must name the test')
      .test({
        name: 'name-not-used',
        params: { wevos, draft },
        test: (value) =>
          !wevos.filter((wevo) => wevo.id !== draft?.id).some((wevo) => wevo.name.trim() === value.trim()),
        // eslint-disable-next-line no-template-curly-in-string
        message: '"${value}" already exists. Please choose a unique name.',
      }),
    testTags: yup
      .string()
      .test({
        name: 'tag-list-not-empty',
        params: { draft, isCDS, isDQS, tagsList },
        test: () =>
          (!isCDS && !isDQS) || ((isCDS || isDQS) && (tagsList?.length > 0 || draft?.tags?.length > 0)),
        // eslint-disable-next-line no-template-curly-in-string
        message: 'Please add at least one tag.',
      })
      .test({
        name: 'tag-list-contains-uuid',
        params: { isCDS, isDQS, tagsList },
        test: () =>
          // mc could not confirm that tags are always of a particular uuid format
          // but did confirm that the all have the same length
          (!isCDS && !isDQS) || ((isCDS || isDQS) && tagsList?.find((tag) => tag.name.length === 36)),
        // eslint-disable-next-line no-template-curly-in-string
        message: 'Please add the UUID for this study.',
      }),
    ownerName: yup.string().trim().required('You must provide a name for the test owner'),
    ownerEmail: yup.string().trim().email('Invalid email address').required('You must provide an email'),
    emailsToNotify: yup
      .string()
      .trim()
      .test({
        name: 'valid-email',
        test: (value) => typeof value === 'undefined' || value.length === 0 || EmailValidator.validate(value),
        // eslint-disable-next-line no-template-curly-in-string
        message: '"${value}" is not a valid email address.',
      })
      .test({
        name: 'valid-count-additional-emails',
        params: { emails },
        test: () => emails.length <= EMAILS_TO_NOTIFY_LIMIT,
        // eslint-disable-next-line no-template-curly-in-string
        message: 'You have reached the limit on additional users for this test',
      }),
  });

const CreateTestForm = ({
  draft,
  wevos,
  user,
  fetchWevos,
  fetchUser,
  onSubmit,
  updateDefinitionValidity,
  selectedMetric,
  showMetricSelection,
  handleMetricChange,
  enabledDQS,
  enabledCDS,
  showDiscardMetricDialog,
  newMetricName,
  switchMetric,
  toggleShowMetricDialog,
  tagsList,
  onTagsChanged,
  emailsToNotify,
  onEmailsToNotifyChanged,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [email, setEmail] = useState('');
  const [dropDownItems, setDropDownItems] = useState([]);
  const [scrollToBottom, setScrollToBottom] = useState(false);

  const emailsRef = useRef();

  const classes = useCreateTestStyles({ isSmallScreen, emailsToNotify });

  const isCDS = useMemo(() => {
    return Boolean(selectedMetric === Metrics.CDS);
  }, [selectedMetric]);

  const isDQS = useMemo(() => {
    return Boolean(selectedMetric === Metrics.DQS);
  }, [selectedMetric]);

  useEffect(() => {
    fetchWevos();
  }, [fetchWevos]);

  useEffect(() => {
    if (!wevos.length > 0) {
      fetchWevos(); // get current user's wevos if not already fetched
    }
  }, [fetchUser, fetchWevos, wevos.length]);

  useEffect(() => {
    if (scrollToBottom && emailsToNotify.length > 3) {
      // shows the newly added email in the list of team emails when the section is scrollable
      emailsRef.current.scrollTop = emailsRef.current.scrollHeight;
    }
  }, [scrollToBottom, emailsToNotify]);

  const formDefaults = () => {
    if (!_isEmpty(draft)) {
      return {
        testName: draft.name ?? '',
        ownerName: draft.ownerName ?? '',
        ownerEmail: draft.ownerEmail ?? '',
        emailsToNotify: '',
        testGoals: draft.description ?? '',
      };
    } else {
      let defaultFormValues = {
        testName: '',
        ownerName: '',
        ownerEmail: '',
        emailsToNotify: '',
        testGoals: '',
      };
      if (_get(user, 'firstName') && _get(user, 'lastName')) {
        defaultFormValues.ownerName = `${_get(user, 'firstName', '')} ${_get(user, 'lastName', '')}`;
      }
      defaultFormValues.ownerEmail = _get(user, 'email', '');
      return defaultFormValues;
    }
  };

  const [defaultValues] = useState(formDefaults());

  const {
    handleSubmit,
    reset,
    trigger,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
    resolver: yupResolver(buildSchema(wevos, draft, emailsToNotify, isCDS, isDQS, tagsList)),
  });

  useEffect(() => {
    trigger(['testTags']);
  }, [isCDS, isDQS, tagsList, draft?.tags, trigger]);

  useEffect(() => {
    if (updateDefinitionValidity) {
      updateDefinitionValidity(isValid);
    }
  }, [isValid, updateDefinitionValidity]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    trigger(['testName', 'testGoals']);
  }, [draft?.name, draft?.description, wevos, trigger]);

  const handleTeamEmailChange = async (ev) => {
    const value = ev.target.value.trim();
    setEmail(value);

    if (value && isValidValue(value)) {
      setDropDownItems([email]);
    } else {
      setDropDownItems([]);
    }
  };

  const handleKeyPress = (ev) => {
    if (ev.key === 'Enter' && isValidValue(email)) {
      ev.preventDefault();
      handleAddEmail();
    }
  };

  const isValidValue = (value) => {
    if (!isEmail(value) || isInList(value)) {
      return false;
    }

    return true;
  };

  const isEmail = (value) => {
    return EmailValidator.validate(value);
  };

  const isInList = (value) => {
    return emailsToNotify.includes(value);
  };

  const handleAddEmail = () => {
    const updatedEmails = [...emailsToNotify, email];
    onEmailsToNotifyChanged(updatedEmails);
    setEmail('');
    setDropDownItems([]);
    setScrollToBottom(true);
  };

  const handleDeleteEmail = (item) => {
    const filteredEmails = emailsToNotify.filter((i) => i !== item);
    onEmailsToNotifyChanged(filteredEmails);
    setScrollToBottom(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="create-test-form">
      <Controller
        control={control}
        name="testName"
        render={({ field: { onChange, onBlur, ref, value }, fieldState: { error, invalid } }) => (
          <TextField
            className={classes.inputField}
            type="text"
            onBlur={onBlur}
            onChange={onChange}
            inputRef={ref}
            variant="outlined"
            fullWidth
            label="Test Name"
            value={value}
            required
            autoFocus
            error={invalid}
            helperText={_get(error, 'message', '')}
          />
        )}
      />
      <Controller
        control={control}
        name="ownerName"
        render={({ field: { onChange, onBlur, ref, value }, fieldState: { error, invalid } }) => (
          <TextField
            className={classes.inputField}
            type="text"
            onBlur={onBlur}
            onChange={onChange}
            inputRef={ref}
            variant="outlined"
            fullWidth
            label="Test Owner Name"
            value={value}
            autoComplete="name"
            required
            error={invalid}
            helperText={_get(error, 'message', '')}
          />
        )}
      />
      <Controller
        control={control}
        name="ownerEmail"
        render={({ field: { onChange, onBlur, ref, value }, fieldState: { error, invalid } }) => (
          <TextField
            className={classes.inputField}
            inputRef={ref}
            type="email"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            required
            variant="outlined"
            fullWidth
            label="Test Owner Email"
            autoComplete="email"
            error={invalid}
            helperText={_get(error, 'message', '')}
          />
        )}
      />
      <Controller
        control={control}
        name="emailsToNotify"
        render={({ field: { onBlur, onChange, ref }, fieldState: { error, invalid } }) => (
          <Tooltip
            title={
              emailsToNotify.length >= EMAILS_TO_NOTIFY_LIMIT
                ? 'You have reached the limit on additional users for this test'
                : ''
            }
            placement="right">
            <TextField
              className={classes.inputField}
              inputRef={ref}
              type="email"
              onChange={(ev) => {
                onChange(ev);
                handleTeamEmailChange(ev);
              }}
              onBlur={onBlur}
              onKeyPress={(ev) => handleKeyPress(ev)}
              value={email}
              variant="outlined"
              fullWidth
              label="Team Email(s)"
              autoComplete="email"
              error={invalid}
              helperText={error?.message ?? ''}
              disabled={emailsToNotify.length >= EMAILS_TO_NOTIFY_LIMIT}
            />
          </Tooltip>
        )}
      />
      {Boolean(dropDownItems.length) && (
        <Card className={classes.dropDownCard}>
          <Chip label={email} size="small" onClick={handleAddEmail} className={classes.dropDownChip} />
        </Card>
      )}
      {Boolean(emailsToNotify?.length) && (
        <div className={classes.chipsArea} ref={emailsRef}>
          {emailsToNotify?.map((item) => (
            <Tooltip title={item.length > 50 ? item : ''} placement="right" key={item}>
              <Chip
                label={item}
                onDelete={() => handleDeleteEmail(item)}
                size="small"
                deleteIcon={<CloseIcon className={classes.deleteIcon} />}
                className={classes.emailChip}
              />
            </Tooltip>
          ))}
        </div>
      )}
      <Box pt={showMetricSelection ? 4 : 0} />
      {showMetricSelection && (
        <Container maxWidth="sm" className={classes.container}>
          <Typography variant="h5">Select your study type:</Typography>
          <MetricSelection
            selectedMetric={selectedMetric}
            handleMetricChange={handleMetricChange}
            enabledDQS={enabledDQS}
            enabledCDS={enabledCDS}
          />
          <DiscardMetricDialog
            open={showDiscardMetricDialog}
            currentMetricName={getSelectedMetricName(selectedMetric)}
            newMetricName={newMetricName}
            switchMetric={switchMetric}
            closeCallback={toggleShowMetricDialog}
          />
        </Container>
      )}
      <Box pt={showMetricSelection ? 2 : 0} />
      <Controller
        control={control}
        name="testTags"
        render={({ field: { onBlur, onChange, ref }, fieldState: { error, invalid } }) => (
          <WevoTagsComboBox
            draft={draft}
            tags={tagsList}
            inputRef={ref}
            onBlur={onBlur}
            invalid={invalid}
            error={error}
            required={(isCDS || isDQS) && !tagsList?.length}
            helperText={_get(error, 'message', '')}
            isDQS={isDQS}
            isCDS={isCDS}
            onTagsChanged={onTagsChanged}
          />
        )}
      />
    </form>
  );
};

CreateTestForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  updateDefinitionValidity: PropTypes.func,
  onEmailsToNotifyChanged: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    wevos: getActiveWevos(state),
  };
};

const actionCreators = { fetchWevos: WevoActions.fetchWevos };

export default connect(mapStateToProps, actionCreators, null, { forwardRef: true })(CreateTestForm);
