import { Box, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUserCustomizations, getUserProfile, getUserTeamId } from '../../../modules/user/selectors';
import ProductSelection from '../edit/ProductSelection';

const LoginInterstitial = (props) => {
  const user = useSelector(getUserProfile);
  const userTeamId = useSelector(getUserTeamId);
  const userCustomizations = useSelector(getUserCustomizations);

  return (
    <Box>
      <Grid container spacing={2} justifyContent="left">
        <Grid item>
          {!userCustomizations ? (
            <CircularProgress size={40} />
          ) : (
            <ProductSelection userCustomizations={userCustomizations} user={user} userTeamId={userTeamId} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginInterstitial;
