import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  generatePath,
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as ConsumersIcon } from '../../../../src/assets/consumers.svg';
import { ReactComponent as FasterIcon } from '../../../../src/assets/faster.svg';
import useWevo, { getWevo } from '../../../hooks/useWevo';
import { IntakeSectionNames, IntakeSubsectionNames, JobToBeDone } from '../../../modules/intake/constants';
import { WevoStatus } from '../../../modules/report/constants';
import { getUserCustomizations, getUserProfile } from '../../../modules/user/selectors';
import { UserType, WevoType } from '../../../modules/wevos/constants';
import { snackbar } from '../../../notifications';
import { Paths } from '../../../routes';
import AppBar from '../../appBar/AppBar';
import { CustomTextField, Header, SelectableTile, StyledListItemButton, ToggleSwitch } from '../components';
import { DeleteButton } from '../components/Buttons';
import CustomSelect from '../components/CustomSelect';
import { ReorderableList } from '../components/ReorderableList';
import { AddListItemButton } from '../components/SelectableTile';
import ToggleThumbnail, { ThumbnailGrid } from '../components/ToggleThumbnail';
import { PreviewSurveyLinkRequestModal } from '../edit/PreviewSurveyLinkRequestModal';
import useFetchCustomQuestions, { getCustomQuestions } from '../hooks/useFetchCustomQuestions';
import useFetchCustomScreenerQuestions, {
  getCustomScreenerQuestions,
} from '../hooks/useFetchCustomScreenerQuestions';
import useFetchSavedAudience, { getSavedAudiences } from '../hooks/useFetchSavedAudience';
import useLaunchWevo from '../hooks/useLaunchWevo';
import useFetchProPricing from '../hooks/usePricing';
import useSaveWevo from '../hooks/useSaveWevo';
import ActionBar from './ActionBar';
import NavMenu from './NavMenu';
import AudienceIntakeSection from './audience';
import { CustomScreenerQuestionLimit } from './audience/constants';
import { hasCustomScreenerErrors, validateCustomScreeners } from './audience/helpers/validation';
import { MAX_DEEP_DIVE_STEPS, MAX_PAGES_COMPARE, MIN_DEEP_DIVE_STEPS, MIN_PAGES_COMPARE } from './constants';
import { IntakeWevoContext } from './context/IntakeWevoContext';
import { CustomQuestionsIntakeSection } from './custom-questions';
import { JourneyQuestionLimit } from './custom-questions/constants';
import { hasCustomQuestionErrors, validateCustomQuestions } from './custom-questions/helpers/validation';
import ExpectationsIntakeSection from './expectations';
import { hasAssetErrors, validateAssets } from './helpers/validation/assets';
import { hasAudienceErrors, hasValidUUIDTag, hasWevoErrors, validateWevo } from './helpers/validation/wevo';
import { isCustomSurveyType } from './helpers/wevo';
import PrimerTaskIntakeSection from './primer-task';
import ReviewIntakeSection from './review-tab';
import StartIntakeSection from './start';
import StudyAssetsIntakeSection from './study-assets';
import StudyGoalsIntakeSection from './study-goals';
import StudySetupIntakeSection from './study-setup';
import IntakeSummaryPreviewPanel from './summary-preview';

const IntakeForm = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const params = useParams();
  const { track } = useAnalytics();
  const user = useSelector(getUserProfile);
  const userCustomizations = useSelector(getUserCustomizations);
  const isMastercard = Boolean(userCustomizations?.isMastercard);

  const wevoId = params?.wevoId;

  // Root Entity State
  const [wevo, setWevo] = useState(null);
  const [wevoErrors, setWevoErrors] = useState({});
  const [assetErrors, setAssetErrors] = useState({});
  const [customQuestions, setCustomQuestions] = useState(null);
  const [customQuestionErrors, setCustomQuestionErrors] = useState({});
  const [customScreeners, setCustomScreeners] = useState(null);
  const [customScreenerErrors, setCustomScreenerErrors] = useState({});
  const [savedAudiences, setSavedAudiences] = useState(null);
  const [proPricing, setProPricing] = useState(null);
  const [isWevoSyncing, setIsWevoSyncing] = useState(false);
  const [showErrorMessage, setShowErrowMessage] = useState(false);
  // End Root entity State

  // Other UI States
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const [selectedSidebarTabIndex, setSelectedSidebarTabIndex] = useState(0);
  const [isPreviewSurveyLinkRequestModalOpen, setIsPreviewSurveyLinkRequestModalOpen] = useState(false);

  const handleSelectedSidebarTabIndexChanged = (event, newValue) => {
    setSelectedSidebarTabIndex(newValue);
  };

  const hasSidebarPreview = useRouteMatch({
    path: [
      Paths.intake.intakeAudience,
      Paths.intake.intakeAsset,
      Paths.intake.intakeExpectations,
      Paths.intake.intakePrimerAndTask,
      Paths.intake.intakeCustomQuestions,
      Paths.intake.intakeReview,
    ],
    exact: true,
  });

  const section = useMemo(() => {
    if (matchPath(pathname, { path: Paths.intake.intakeStart, exact: true })) {
      return { sectionName: IntakeSectionNames.Start, subSectionName: null };
    }

    if (matchPath(pathname, { path: Paths.intake.intakeAudience, exact: true })) {
      return { sectionName: IntakeSectionNames.Audience, subSectionName: null };
    }

    if (matchPath(pathname, { path: Paths.intake.intakeSetup, exact: true })) {
      return { sectionName: IntakeSectionNames.Build, subSectionName: IntakeSubsectionNames.Setup };
    }

    if (matchPath(pathname, { path: Paths.intake.intakeAsset, exact: true })) {
      return { sectionName: IntakeSectionNames.Build, subSectionName: IntakeSubsectionNames.Asset };
    }

    if (matchPath(pathname, { path: Paths.intake.intakeGoal, exact: true })) {
      return { sectionName: IntakeSectionNames.Details, subSectionName: IntakeSubsectionNames.Goal };
    }

    if (matchPath(pathname, { path: Paths.intake.intakePrimerAndTask, exact: true })) {
      return { sectionName: IntakeSectionNames.Details, subSectionName: IntakeSubsectionNames.PrimerAndTask };
    }

    if (matchPath(pathname, { path: Paths.intake.intakeExpectations, exact: true })) {
      return { sectionName: IntakeSectionNames.Details, subSectionName: IntakeSubsectionNames.Expectations };
    }

    if (matchPath(pathname, { path: Paths.intake.intakeCustomQuestions, exact: true })) {
      return {
        sectionName: IntakeSectionNames.Details,
        subSectionName: IntakeSubsectionNames.CustomQuestions,
      };
    }

    if (matchPath(pathname, { path: Paths.intake.intakeReview, exact: true })) {
      return {
        sectionName: IntakeSectionNames.Review,
        subSectionName: null,
      };
    }
  }, [pathname]);

  const hasBackAction = useMemo(() => {
    if (isMastercard) {
      return isNil(matchPath(pathname, { path: Paths.intake.intakeStart, exact: true }));
    }

    return isNil(matchPath(pathname, { path: Paths.intake.intakeAudience, exact: true }));
  }, [isMastercard, pathname]);

  // hiding review preview button for now
  const hasRequestPreviewAction = false;
  // const hasRequestPreviewAction = useMemo(
  //   () => !isNil(matchPath(pathname, { path: Paths.intake.intakeReview, exact: true })),
  //   [pathname]
  // );

  const hasSubmitAction = useMemo(
    () => !isNil(matchPath(pathname, { path: Paths.intake.intakeReview, exact: true })),
    [pathname]
  );

  const isSubmitDisabled = useMemo(() => {
    if (!hasSubmitAction) return true;

    const wevoErrorsDetected = hasWevoErrors(wevoErrors);
    const assetErrorsDetected = hasAssetErrors(assetErrors);
    const audienceErrorsDetected = hasAudienceErrors(wevoErrors);
    const customQuestionErrorsDetected = hasCustomQuestionErrors(customQuestionErrors);
    const customScreenerErrorsDetected = hasCustomScreenerErrors(customScreenerErrors);

    return (
      wevoErrorsDetected ||
      assetErrorsDetected ||
      audienceErrorsDetected ||
      customQuestionErrorsDetected ||
      customScreenerErrorsDetected
    );
  }, [assetErrors, customQuestionErrors, customScreenerErrors, hasSubmitAction, wevoErrors]);

  const errorMessage = useMemo(() => {
    if (!showErrorMessage) {
      return '';
    }
    return isSubmitDisabled ? 'Please fill in all required fields.' : '';
  }, [isSubmitDisabled, showErrorMessage]);

  // End Other UI States

  // Remote State Integration
  // State Seed Hooks - the expectation is that this should only set
  // the initial values for the root entities; afterwards, any mutation should operate
  // on the state value optimistically, sync to the backend in the background,
  // and rollback the state only in cases where errors are encountered
  // the remote value is continuosly tracked for easy rollbacks in case of failure
  const { data: remoteWevoState, isLoading: isLoadingWevo } = useWevo(wevoId, {
    onSuccess: (data) => {
      if (isNil(wevo)) {
        setWevo(data);
      }
    },
  });

  const { data: remoteCustomQuestionsState, isLoading: isLoadingCustomQuestions } = useFetchCustomQuestions(
    wevoId,
    {
      onSuccess: (data) => {
        if (isNil(customQuestions)) {
          setCustomQuestions(data);
        }
      },
    }
  );
  const { data: remoteCustomScreenersState, isLoading: isLoadingCustomScreeners } =
    useFetchCustomScreenerQuestions(
      { wevoId },
      {
        onSuccess: (data) => {
          if (isNil(customScreeners)) {
            setCustomScreeners(data);
          }
        },
      }
    );

  const { data: remoteSavedAudiencesState, isLoading: isLoadingSavedAudiences } = useFetchSavedAudience({
    onSuccess: (data) => {
      if (isNil(savedAudiences)) {
        setSavedAudiences(data);
      }
    },
  });

  // we're just using this to set pro pricing data once on app load
  useFetchProPricing({
    onSuccess: (data) => {
      if (isNil(proPricing)) {
        setProPricing(data);
      }
    },
    enabled: isNil(proPricing),
  });

  const reloadSavedAudiencesFromRemote = async () => {
    setIsWevoSyncing(true);
    const savedAudiences = await getSavedAudiences();
    setSavedAudiences(savedAudiences);
  };

  const reloadCustomQuestionsFromRemote = async () => {
    setIsWevoSyncing(true);
    const customQuestions = await getCustomQuestions({ wevoId: wevo.id });
    setCustomQuestions(customQuestions);
    setIsWevoSyncing(false);
  };

  const reloadCustomScreenersFromRemote = async () => {
    setIsWevoSyncing(true);
    const customScreeners = await getCustomScreenerQuestions({ wevoId: wevo.id });
    setCustomScreeners(customScreeners);
    setIsWevoSyncing(false);
  };

  const reloadWevoFromRemote = async () => {
    setIsWevoSyncing(true);
    const remoteWevo = await getWevo({ wevoId: wevo.id });
    setWevo(remoteWevo);
    setIsWevoSyncing(false);
  };

  const { mutateAsync: launchWevoAsync, isLoading: isLaunching } = useLaunchWevo();

  const launchStudy = async () => {
    track(TrackEvent.LAUNCHED_TEST, {
      wevoId: wevo?.analyticsId,
      draftId: wevo?.id,
      testType: wevo?.type,
    });

    try {
      setIsWevoSyncing(true);
      await launchWevoAsync({ id: wevo.id });
    } catch (err) {
      setIsWevoSyncing(false);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Something went wrong, please try again.');
    }
  };
  // End Remote State Integration

  // Action Bar Handlers
  const handleBackActionClicked = () => {
    // only mastercard has a 'start' page
    if (isMastercard && section?.sectionName === IntakeSectionNames.Audience) {
      history.push(generatePath(Paths.intake.intakeStart, { wevoId }));
      return;
    }

    if (section?.subSectionName === IntakeSubsectionNames.Setup) {
      history.push(generatePath(Paths.intake.intakeAudience, { wevoId }));
      return;
    }

    if (section?.subSectionName === IntakeSubsectionNames.Asset) {
      history.push(generatePath(Paths.intake.intakeSetup, { wevoId }));
      return;
    }

    if (section?.subSectionName === IntakeSubsectionNames.Goal) {
      if (isCustomSurveyType(wevo) && wevo?.pages?.length < 2) {
        // a well formed custom survey doesn't need asset setup at this time
        history.push(generatePath(Paths.intake.intakeSetup, { wevoId }));
        return;
      }

      history.push(generatePath(Paths.intake.intakeAsset, { wevoId }));
      return;
    }

    if (section?.subSectionName === IntakeSubsectionNames.PrimerAndTask) {
      history.push(generatePath(Paths.intake.intakeGoal, { wevoId }));
      return;
    }

    if (section?.subSectionName === IntakeSubsectionNames.Expectations) {
      history.push(generatePath(Paths.intake.intakePrimerAndTask, { wevoId }));
      return;
    }

    if (section?.subSectionName === IntakeSubsectionNames.CustomQuestions) {
      if (wevo?.details?.includeExpectations) {
        history.push(generatePath(Paths.intake.intakeExpectations, { wevoId }));
      } else {
        history.push(generatePath(Paths.intake.intakePrimerAndTask, { wevoId }));
      }
      return;
    }

    if (section?.sectionName === IntakeSectionNames.Review) {
      history.push(generatePath(Paths.intake.intakeCustomQuestions, { wevoId }));
      return;
    }
  };

  const handleNextActionClicked = () => {
    if (isMastercard && section?.sectionName === IntakeSectionNames.Start) {
      history.push(generatePath(Paths.intake.intakeAudience, { wevoId }));
      return;
    }

    if (section?.sectionName === IntakeSectionNames.Audience) {
      history.push(generatePath(Paths.intake.intakeSetup, { wevoId }));
      return;
    }

    if (section?.subSectionName === IntakeSubsectionNames.Setup) {
      if (isCustomSurveyType(wevo) && wevo?.pages?.length < 2) {
        // a well formed custom survey doesn't need asset setup at this time
        history.push(generatePath(Paths.intake.intakeGoal, { wevoId }));
        return;
      }

      history.push(generatePath(Paths.intake.intakeAsset, { wevoId }));
      return;
    }

    if (section?.subSectionName === IntakeSubsectionNames.Asset) {
      history.push(generatePath(Paths.intake.intakeGoal, { wevoId }));
      return;
    }

    if (section?.subSectionName === IntakeSubsectionNames.Goal) {
      history.push(generatePath(Paths.intake.intakePrimerAndTask, { wevoId }));
      return;
    }

    if (section?.subSectionName === IntakeSubsectionNames.PrimerAndTask) {
      if (wevo?.details?.includeExpectations) {
        history.push(generatePath(Paths.intake.intakeExpectations, { wevoId }));
      } else {
        history.push(generatePath(Paths.intake.intakeCustomQuestions, { wevoId }));
      }
      return;
    }

    if (section?.subSectionName === IntakeSubsectionNames.Expectations) {
      history.push(generatePath(Paths.intake.intakeCustomQuestions, { wevoId }));
      return;
    }

    if (section?.subSectionName === IntakeSubsectionNames.CustomQuestions) {
      history.push(generatePath(Paths.intake.intakeReview, { wevoId }));
      return;
    }
  };

  const handleRequestPreviewActionClicked = () => {
    setIsPreviewSurveyLinkRequestModalOpen(true);
  };

  const handleClosePreviewSurveyLinkRequest = () => {
    setIsPreviewSurveyLinkRequestModalOpen(false);
  };

  const handleSubmitActionClicked = async () => {
    if (isSubmitDisabled) {
      // if there is an error and the submit button is disabled,
      // the error message is shown after the button is clicked at least once
      if (!showErrorMessage) {
        setShowErrowMessage(true);
      }
      return;
    }
    await launchStudy();
  };
  // End Action Bar Handlers

  const { mutateAsync: saveWevo } = useSaveWevo();

  const handleUpdateWevo = async ({ updateFields }) => {
    const previousWevo = cloneDeep(wevo);

    try {
      setWevo({ ...wevo, ...updateFields });
      await saveWevo({ id: wevo.id, ...updateFields });
    } catch (err) {
      setWevo(previousWevo);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
    }
  };

  const useQuery = () => new URLSearchParams(useLocation()?.search);
  const query = useQuery();

  const forceNoLimit = query.get('noLimit') === 'true';

  // Validation
  useEffect(() => {
    setWevoErrors(validateWevo(wevo, { additionalValidators: [hasValidUUIDTag] }));
    setAssetErrors(
      validateAssets(wevo, {
        global: {
          forceNoLimit,
        },
        pages: {
          minPages: MIN_PAGES_COMPARE,
          maxPages: MAX_PAGES_COMPARE,
        },
        steps: {
          minSteps: isCustomSurveyType(wevo) ? 0 : MIN_DEEP_DIVE_STEPS,
          maxSteps: MAX_DEEP_DIVE_STEPS,
        },
      })
    );
    // handle form validation whenever custom questions change
    setCustomQuestionErrors(
      validateCustomQuestions(customQuestions, {
        global: {
          forceNoLimit,
          questionLimit: wevo?.details?.customQuestionLimit || JourneyQuestionLimit,
          isCustomSurvey: isCustomSurveyType(wevo),
        },
      })
    );
    setCustomScreenerErrors(
      validateCustomScreeners(customScreeners, {
        global: { forceNoLimit, questionLimit: CustomScreenerQuestionLimit },
      })
    );
  }, [
    customQuestions,
    customScreeners,
    wevo,
    setWevoErrors,
    setAssetErrors,
    setCustomQuestionErrors,
    setCustomScreenerErrors,
    forceNoLimit,
  ]);

  if (
    !user ||
    isEmpty(user) ||
    !user?.id ||
    !userCustomizations ||
    isLoadingWevo ||
    isLoadingCustomQuestions ||
    isLoadingCustomScreeners ||
    isLoadingSavedAudiences ||
    isNil(wevo) ||
    isNil(customQuestions)
  ) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: 'calc(100vh - 64px)', width: '100%', overflowY: 'auto' }}>
        <CircularProgress />
      </Box>
    );
  } else if (userCustomizations?.features?.newIntake === false) {
    history.replace('/dashboard');
  }

  // if the wevo is a lite or classic wevo, redirect to the old review page
  const isLite = wevo?.jobToBeDone === JobToBeDone.Lite;
  const isClassic = wevo?.type === WevoType.Classic;
  if (isLite || isClassic) {
    history.replace(`/wevos/${wevoId}/edit/review`);
  }

  // if the wevo is no longer in draft and not a wevo user, then redirect
  // note: this is pretty hacky, but solves a major problem for our internal users
  if (wevo?.status !== WevoStatus.Draft && user?.userType !== UserType.Wevo) {
    history.replace('/dashboard');
  }

  const showPallet = false;

  return (
    <IntakeWevoContext.Provider
      value={{
        wevo,
        setWevo,
        isWevoSyncing,
        setIsWevoSyncing,
        customQuestions,
        setCustomQuestions,
        reloadCustomQuestionsFromRemote,
        customQuestionErrors,
        setCustomQuestionErrors,
        customScreeners,
        setCustomScreeners,
        reloadCustomScreenersFromRemote,
        customScreenerErrors,
        setCustomScreenerErrors,
        savedAudiences,
        setSavedAudiences,
        reloadSavedAudiencesFromRemote,
        remoteWevoState,
        remoteCustomQuestionsState,
        remoteCustomScreenersState,
        remoteSavedAudiencesState,
        reloadWevoFromRemote,
        wevoErrors,
        setWevoErrors,
        assetErrors,
        setAssetErrors,
        selectedPageIndex,
        setSelectedPageIndex,
        userCustomizations,
        proPricing,
      }}>
      <AppBar name={wevo?.name} onNameChanged={handleUpdateWevo} />
      <Box display="flex" sx={{ height: 'calc(100vh - 64px)', width: '100%', overflowY: 'hidden' }}>
        <NavMenu wevo={wevo} userCustomizations={userCustomizations} />
        <Container
          component="main"
          maxWidth={false}
          disableGutters
          sx={{ height: 'inherit', backgroundColor: '#FFFFFF' }}>
          <Grid container sx={{ height: 'inherit' }}>
            <Grid
              item
              sx={{
                height: 'inherit',
                flexGrow: 1,

                width: 'calc(100% - 36vw)',
              }}>
              <Box sx={{ height: '90%', overflowY: 'auto', paddingX: 11, paddingY: 4 }}>
                <Switch>
                  {isMastercard && (
                    <Route exact path={Paths.intake.intakeStart}>
                      <StartIntakeSection />
                    </Route>
                  )}
                  <Route exact path={Paths.intake.intakeAudience}>
                    <AudienceIntakeSection />
                  </Route>
                  <Route exact path={Paths.intake.intakeSetup}>
                    <StudySetupIntakeSection />
                  </Route>
                  <Route exact path={Paths.intake.intakeAsset}>
                    <StudyAssetsIntakeSection />
                  </Route>
                  <Route exact path={Paths.intake.intakeGoal}>
                    <StudyGoalsIntakeSection />
                  </Route>
                  <Route exact path={Paths.intake.intakePrimerAndTask}>
                    <PrimerTaskIntakeSection />
                  </Route>
                  <Route exact path={Paths.intake.intakeExpectations}>
                    <ExpectationsIntakeSection />
                  </Route>
                  <Route exact path={Paths.intake.intakeCustomQuestions}>
                    <CustomQuestionsIntakeSection />
                  </Route>
                  <Route exact path={Paths.intake.intakeReview}>
                    <ReviewIntakeSection />
                  </Route>
                </Switch>
                {showPallet && (
                  <>
                    <CustomSelect
                      size={'medium'}
                      value={1}
                      items={[
                        { label: 'Please Select a Value', description: 'This is a description', value: '' },
                        { label: 'One', description: 'One is the loneliest number', value: 1 },
                        { label: 'Two', description: 'Two can be as bad as one', value: 2 },
                        { label: 'three', description: 'Three is good. No notes.', value: 3 },
                      ]}
                    />
                    <AddListItemButton onClick={() => console.log('add list item')} />
                    <DeleteButton onClick={() => console.log('Deleted!')} />
                    <ReorderableList
                      onDragEnd={(props) => console.log(props)}
                      items={[
                        { label: 'Cool Stuff', id: '1', description: 'Desc 1' },
                        { label: 'Nice Stuff', id: '2', description: 'Desc 2' },
                        { label: 'Neat Stuff', id: '3', description: 'Desc 3' },
                        { label: 'Pending Item', id: null, description: 'Desc 4' },
                      ]}
                      renderTitle={(item, index) => <Typography variant="h5">{item.label}</Typography>}
                      renderItem={(item, index) => (
                        <Box>
                          <Typography>{item.description}</Typography>
                        </Box>
                      )}
                    />
                    <ReorderableList
                      onDragEnd={(props) => console.log(props)}
                      items={[
                        { label: 'Cool Stuff', id: '1', description: 'Desc 1' },
                        { label: 'Nice Stuff', id: '2', description: 'Desc 2' },
                        { label: 'Neat Stuff', id: '3', description: 'Desc 3' },
                        { label: 'Pending Item', id: null, description: 'Desc 4' },
                      ]}
                      renderTitle={(item, index) => (
                        <CustomTextField
                          value={item.label}
                          sx={{
                            width: '90%',
                            background: 'white',
                            borderRadius: 4,
                            '& .MuiInputBase-root': {
                              fontWeight: 600,
                              fontSize: 14,
                            },
                          }}
                          onChange={(ev) =>
                            console.log('Tried to change item: ', item, ' at index ', index, ' with event', ev)
                          }
                        />
                      )}
                      renderItem={(item, index) => (
                        <Box>
                          <Typography>{item.description}</Typography>
                        </Box>
                      )}
                    />
                    <Header
                      name={'Additional screeners'}
                      description={'Target amore specific audience by adding custom screener questions.'}
                      tooltipProps={{ title: 'Tooltip content', arrow: true, placement: 'right' }}
                      hasPreview={true}
                      isRequired={true}
                      iconComponent={<FasterIcon />}
                      toggleSwitchProps={{ onChange: (ev) => console.log(ev.target.checked) }}
                    />
                    <ToggleSwitch />
                    <CustomTextField aria-label="single" placeholder="shopping for shoes" />
                    <CustomTextField
                      multiline
                      minRows={4}
                      maxRows={10}
                      aria-label="multiline"
                      placeholder="Example: Imagine you are looking for a pair of shoes."
                      onChange={(ev) => console.log(ev.target.value)}
                    />
                    <StyledListItemButton
                      primaryListItemText={'Saved Audience'}
                      endText={'Recommended'}
                      tooltipProps={{ title: 'disabled item', arrow: true }}
                      isDisabled={true}
                    />
                    <StyledListItemButton
                      primaryListItemText={'General Population'}
                      secondaryListItemText={'Non-specific criteria for: Gender, age, income, and education'}
                      listItemIcon={<FasterIcon />}
                    />
                    <StyledListItemButton
                      primaryListItemText={'Saved Audience'}
                      endText={'Recommended'}
                      isSelected={true}
                    />
                    <SelectableTile label={'Consumers'} iconComponent={<ConsumersIcon />} isSelected={true} />
                    <SelectableTile label={'Desktop'} />
                    <ThumbnailGrid>
                      <ToggleThumbnail
                        label={
                          'ew_corp_doc_01.png ew_corp_doc_01.png ew_corp_doc_01.png ew_corp_doc_01.png ew_corp_doc_01.png'
                        }
                        images={{
                          original: {
                            id: 65492,
                            image_id: 52485,
                            content_type: 'image/png',
                            image_url: null,
                            size_class: 'original',
                            height: 600,
                            width: 800,
                            file_size: 180123,
                            s3_key: '35c159117c9827238e9344a459d5843e7b13bce6',
                            s3_bucket: 'wevo-image-uploads',
                            type: null,
                            pipe_id: null,
                            deleted_at: null,
                            is_placeholder: false,
                            url: 'https://media.wevo.ai/35c159117c9827238e9344a459d5843e7b13bce6',
                          },
                        }}
                      />
                      <ToggleThumbnail
                        label={'ew_corp_doc_01.png'}
                        images={{
                          original: {
                            id: 65492,
                            image_id: 52485,
                            content_type: 'image/png',
                            image_url: null,
                            size_class: 'original',
                            height: 600,
                            width: 800,
                            file_size: 180123,
                            s3_key: '35c159117c9827238e9344a459d5843e7b13bce6',
                            s3_bucket: 'wevo-image-uploads',
                            type: null,
                            pipe_id: null,
                            deleted_at: null,
                            is_placeholder: false,
                            url: 'https://media.wevo.ai/35c159117c9827238e9344a459d5843e7b13bce6',
                          },
                        }}
                        onSelect={(a, b, c) => {
                          console.log(a, b, c);
                        }}
                        isSelected={true}
                      />
                      <ToggleThumbnail
                        label={'ew_corp_doc_01.png'}
                        images={{
                          original: {
                            id: 65492,
                            image_id: 52485,
                            content_type: 'image/png',
                            image_url: null,
                            size_class: 'original',
                            height: 600,
                            width: 800,
                            file_size: 180123,
                            s3_key: '35c159117c9827238e9344a459d5843e7b13bce6',
                            s3_bucket: 'wevo-image-uploads',
                            type: null,
                            pipe_id: null,
                            deleted_at: null,
                            is_placeholder: false,
                            url: 'https://media.wevo.ai/35c159117c9827238e9344a459d5843e7b13bce6',
                          },
                        }}
                      />
                      <ToggleThumbnail
                        label={'ew_corp_doc_01.png'}
                        images={{
                          original: {
                            id: 65492,
                            image_id: 52485,
                            content_type: 'image/png',
                            image_url: null,
                            size_class: 'original',
                            height: 600,
                            width: 800,
                            file_size: 180123,
                            s3_key: '35c159117c9827238e9344a459d5843e7b13bce6',
                            s3_bucket: 'wevo-image-uploads',
                            type: null,
                            pipe_id: null,
                            deleted_at: null,
                            is_placeholder: false,
                            url: 'https://media.wevo.ai/35c159117c9827238e9344a459d5843e7b13bce6',
                          },
                        }}
                      />
                    </ThumbnailGrid>
                  </>
                )}
              </Box>
              <Box sx={{ height: '10%', borderTop: '1px solid #C7D6DF' }} px={12}>
                <ActionBar
                  wevo={wevo}
                  onBack={handleBackActionClicked}
                  hasBackAction={hasBackAction}
                  onNext={handleNextActionClicked}
                  hasRequestPreviewAction={hasRequestPreviewAction}
                  onRequestPreview={handleRequestPreviewActionClicked}
                  onSubmit={handleSubmitActionClicked}
                  hasSubmitAction={hasSubmitAction}
                  isSubmitDisabled={isSubmitDisabled}
                  isSubmitting={isLaunching}
                  errorMessage={errorMessage}
                />
              </Box>
            </Grid>
            <Grid
              item
              sx={{
                borderLeft: '1px solid #C7D6DF',
                width: '36vw',
                backgroundColor: '#F4F4F6',
                flexWrap: 'wrap',
              }}>
              <IntakeSummaryPreviewPanel
                selectedTabIndex={selectedSidebarTabIndex}
                onTabChanged={handleSelectedSidebarTabIndexChanged}
                hasPreview={Boolean(hasSidebarPreview)}
                section={section}
              />
            </Grid>
          </Grid>
        </Container>
        {/* only mount this modal when the user data is valid, otherwise the initial state is wrong */}
        {user && user.id && (
          <PreviewSurveyLinkRequestModal
            wevoId={wevo.id}
            initialRecipientEmails={[user.email]}
            open={isPreviewSurveyLinkRequestModalOpen}
            onClose={handleClosePreviewSurveyLinkRequest}
          />
        )}
      </Box>
    </IntakeWevoContext.Provider>
  );
};

export default IntakeForm;
