import { Box, styled, TextField, Typography } from '@mui/material';

const StyledInputField = styled((props) => <TextField {...props} />)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '22px',
    width: '50px',
    borderRadius: '3px',
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
    fontWeight: 700,
    border: '1px solid #C7D6DF',
    '&:hover': {
      border: '1px solid #3B6CAB',
    },
    '&.Mui-focused': {
      border: '1px solid #3B6CAB',
    },
  },
  '& .MuiInputBase-input': {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
  },
  '& fieldset': {
    border: 'none',
  },
}));

const QuotaPercentageInput = ({ percentage, isDisabled, isReadOnly, onChange }) => {
  return (
    <Box display="flex" alignItems="center">
      <StyledInputField
        disabled={isDisabled}
        type="number"
        variant="outlined"
        value={percentage}
        onChange={(ev) => onChange({ value: Number(ev.target.value) })}
        autoComplete="off"
        inputProps={{
          max: 100,
          min: 0,
          readOnly: isReadOnly,
        }}
      />
      <Typography component="span" ml={1} fontSize={13}>
        %
      </Typography>
    </Box>
  );
};

export default QuotaPercentageInput;
