import { Box, CircularProgress, Dialog, DialogContent } from '@mui/material';
import Grid from '@mui/material/Grid';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import cuid from 'cuid';
import _, { isEmpty as _isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import useAuthorizeFigma from '../../../hooks/useAuthorizeFigma';
import useDeleteFigmaPrototype from '../../../hooks/useDeleteFigmaPrototype';
import useImportFigmaPrototype from '../../../hooks/useImportFigmaPrototype';
import usePrototypeGenerateComponents from '../../../hooks/usePrototypeGenerateComponents';
import usePrototypeGenerateStaticImages from '../../../hooks/usePrototypeGenerateStaticImages';
import useUpdateExperienceStep from '../../../hooks/useUpdateExperienceStep';
import useWevo from '../../../hooks/useWevo';
import {
  AssetScope,
  ExperienceEndScreenType,
  IntakePrototypeImportStages,
  MAX_PRIMER_COUNT,
  MutationKeys,
  PrimerValues,
  TestTypes,
} from '../../../modules/intake/constants';
import { numMaxAssets } from '../../../modules/intake/helpers';
import { getUserCustomizations } from '../../../modules/user/selectors';
import { CompareJourneysOption, LiteOption, UsabilityOption } from '../../../modules/wevos/constants';
import { getAssetsForAssetScope } from '../../../modules/wevos/helpers';
import { snackbar } from '../../../notifications';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import ExplainerPopup from '../ExplainerPopup';
import useAddPage from '../hooks/useAddPage';
import { useBulkUpdateAssetsV2 as useBulkUpdateAssets } from '../hooks/useBulkUpdateAssets';
import useDeletePage from '../hooks/useDeletePage';
import useDeletePrimer from '../hooks/useDeletePrimer';
import useDeleteStep from '../hooks/useDeleteStep';
import useFetchCustomQuestions from '../hooks/useFetchCustomQuestions';
import useSaveWevo from '../hooks/useSaveWevo';
import useUpdateCustomQuestion from '../hooks/useUpdateCustomQuestion';
import useUpdatePage from '../hooks/useUpdatePage';
import useUpdatePageSortOrder from '../hooks/useUpdatePageSortOrder';
import useUpdateStep from '../hooks/useUpdateStep';
import useUpdateStepSortOrder from '../hooks/useUpdateStepSortOrder';
import useUploadPage from '../hooks/useUploadPage';
import useUploadPrimer from '../hooks/useUploadPrimer';
import useUploadStep from '../hooks/useUploadStep';
import { serializeFileRejections } from './test-type/components/DropzoneCard';
import { ExperienceLoadingSection } from './test-type/components/JourneyExperienceComponents';
import LiteTestInfoBanner from './test-type/components/LiteTestInfoBanner';
import {
  DeviceSelectionSection,
  ExpectationsGap,
  FileRequirements,
  PageConfigurationList,
  PrimerConfiguration,
  PrototypeRequirements,
  QualitativeExpectationsSelect,
  TaskToComplete,
  TestTypeSelectionSection,
  UsabilityTestInfoBanner,
} from './test-type/components/index';

/**
 * Determines whether the test type has changed from journey to single-page/compare-pages/compare-journeys or vice versa.
 */
const isTestTypeChanged = (testType, selectedTestType) => {
  const journeyToCompareJourneySwitch =
    [TestTypes.Journey, TestTypes.Usability, TestTypes.Lite].includes(testType) &&
    [TestTypes.CompareJourneys].includes(selectedTestType);

  const journeyToClassicSwitch =
    [TestTypes.Journey, TestTypes.CompareJourneys, TestTypes.Usability, TestTypes.Lite].includes(testType) &&
    [TestTypes.SinglePage, TestTypes.ComparePages].includes(selectedTestType);

  const classicToJourneySwitch =
    [TestTypes.Journey, TestTypes.CompareJourneys, TestTypes.Usability, TestTypes.Lite].includes(
      selectedTestType
    ) && [TestTypes.SinglePage, TestTypes.ComparePages].includes(testType);

  return journeyToCompareJourneySwitch || journeyToClassicSwitch || classicToJourneySwitch;
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
  fileRequirementsCard: {
    padding: theme.spacing(2),
  },
  uploadCard: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
  },
  divider: {
    width: '90%',
  },
  infoIcon: {
    verticalAlign: 'bottom',
    color: grey[600],
  },
  question: {
    marginTop: theme.spacing(2),
  },
  infoText: { fontSize: '13px' },
  infoIncluded: {
    fontSize: '16px',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
    color: theme.palette.primary.main,
  },
  infoOmitted: {
    fontSize: '16px',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
    color: 'red',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  linkText: {
    fontSize: '14px',
  },
}));

const TestTypePage = (props) => {
  const { testType, setTestType, showPrimer, isDQS, isCDS, userCustomizations } = props;

  const { search } = useLocation();
  const queryParams = useMemo(() => queryString.parse(search), [search]);
  let history = useHistory();

  const { wevoId } = useParams();
  const classes = useStyles();
  const { data: draft } = useWevo(wevoId);

  useTrackPageLoad({ name: TrackEvent.VIEWED_EDIT_TEST_TYPE, properties: { wevoId: draft?.analyticsId } });

  const canRunUsability = userCustomizations?.usabilityTestType !== UsabilityOption.Disabled;
  const canRunLite = userCustomizations?.liteTestType !== LiteOption.Disabled;

  const compareJourneysEnabled = userCustomizations?.compareJourneys !== CompareJourneysOption.Disabled;

  const useWideLayout = canRunUsability || compareJourneysEnabled;

  const { data: customQuestions } = useFetchCustomQuestions(wevoId);

  const { mutate: updateStepSortOrder } = useUpdateStepSortOrder();
  const { mutate: updatePageSortOrder } = useUpdatePageSortOrder();
  const { mutate: importFigmaPrototype } = useImportFigmaPrototype();
  const { mutate: deleteFigmaPrototype } = useDeleteFigmaPrototype();
  const { mutate: authorizeFigma } = useAuthorizeFigma();
  const { mutateAsync: generateStaticImagesAsync } = usePrototypeGenerateStaticImages();
  const { mutateAsync: generateComponentsAsync } = usePrototypeGenerateComponents();
  const { mutateAsync: updateExperienceStepAsync } = useUpdateExperienceStep();
  const { mutate: addPage } = useAddPage();
  const { mutateAsync: uploadPageImage } = useUploadPage();
  const { mutateAsync: uploadStepImage } = useUploadStep();
  const { mutate: deletePage } = useDeletePage();
  const { mutate: deleteStep } = useDeleteStep();
  const { mutate: updatePage } = useUpdatePage();
  const { mutate: updateStep } = useUpdateStep();
  const { mutate: saveWevo } = useSaveWevo();
  const { mutate: bulkUpdateAssets } = useBulkUpdateAssets();
  const { mutate: uploadPrimer } = useUploadPrimer();
  const { mutate: deletePrimer } = useDeletePrimer();
  const { mutateAsync: updateCustomQuestionAsync } = useUpdateCustomQuestion();

  const [showDiscardTestTypeDialog, setShowDiscardTestTypeDialog] = useState(false);
  const [testTypeToSetOnConfirm, setTestTypeToSetOnConfirm] = useState();
  const [prevTestType, setPrevTestType] = useState(testType);

  const queryClient = useQueryClient();

  const mutationCache = useMemo(() => queryClient.getMutationCache(), [queryClient]);
  const ongoingUploads = mutationCache
    .getAll()
    .filter(
      (mutation) =>
        mutation.options.variables.wevoId === draft.id &&
        (mutation.options.mutationKey === MutationKeys.uploadPage ||
          mutation.options.mutationKey === MutationKeys.uploadStep) &&
        mutation.state.status === 'loading'
    );

  const ongoingPrimerUploads = mutationCache
    .getAll()
    .filter(
      (mutation) =>
        mutation.options.variables.wevoId === draft.id &&
        mutation.options.mutationKey === MutationKeys.uploadPrimer &&
        mutation.state.status === 'loading'
    );

  const errors = useMemo(() => {
    let errors = {};

    const taskToComplete = draft?.details?.taskToComplete;

    if (
      [TestTypes.Journey, TestTypes.Usability, TestTypes.CompareJourneys, TestTypes.Lite].includes(testType)
    ) {
      if (!taskToComplete) {
        errors = {
          ...errors,
          taskToComplete: { message: 'Please provide a task to complete' },
        };
      } else {
        errors = {
          ...errors,
          taskToComplete: false,
        };
      }
    }

    const primerContext = draft?.pages?.[0]?.primerContext;

    if (!primerContext) {
      errors = {
        ...errors,
        primerContext: { message: 'Please provide a primer context' },
      };
    } else {
      errors = {
        ...errors,
        primerContext: false,
      };
    }

    const visitorObjective = draft?.visitorObjective ?? '';

    if (!isDQS && !draft.isUsabilityTestType && !draft.isLiteTestType && !visitorObjective.trim()) {
      errors = {
        ...errors,
        expectationsGap: { message: 'Please describe your expectations gap' },
      };
    } else {
      errors = {
        ...errors,
        expectationsGap: false,
      };
    }
    return errors;
  }, [draft, testType, isDQS]);

  const showBaselineCompetitor = useMemo(() => testType === TestTypes.ComparePages, [testType]);

  const primers = useMemo(() => {
    if (!_isEmpty(draft.pages)) {
      return draft.pages.filter((page) => Boolean(page.primerId)).map((page) => page.primer);
    }
    return [];
  }, [draft.pages]);

  const handleShowPrimerSelect = useCallback(
    (ev, callbacks) => {
      if (ev.target.value === PrimerValues.No) {
        draft.pages.forEach((page) => {
          if (page?.primerId || page?.primerContext) {
            deletePrimer(
              { wevoId, pageId: page.id },
              {
                onSuccess: () => {
                  callbacks?.onSuccess && callbacks.onSuccess();
                },
                onError: (err) => {
                  callbacks?.onError && callbacks.onError(err);
                  snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting primer');
                },
              }
            );
          }
        });
      }
    },
    [deletePrimer, draft.pages, wevoId]
  );

  const handleFileInput = useCallback(
    async (inputs) => {
      let { files, fileRejections, assetScope, pageId } = inputs ?? {};

      const assets = getAssetsForAssetScope(draft, { pageId });

      files = Array.isArray(files) ? files : [files];
      const maxAssets = numMaxAssets(testType, assetScope);

      if ((!files || files?.length < 1) && fileRejections?.length > 0) {
        snackbar.error(`Error uploading assets. ${serializeFileRejections(fileRejections)}.`);
      } else if (files.length > maxAssets || files.length + assets?.length > maxAssets) {
        snackbar.error('Page limit would be exceeded');
      } else {
        // at this point, some files are valid, but some may have failed, so we should also warn
        if (fileRejections?.length > 0) {
          snackbar.error(
            `An error occurred for one or more assets. ${serializeFileRejections(fileRejections)}.`
          );
        }
        const uploadParams = {
          uploadId: cuid(),
          wevoId: draft.id,
          pageId,
          files,
          deviceId: draft.devices[0],
          primerId: draft?.pages?.[0]?.primerId,
          primerContext: draft?.pages?.[0]?.primerContext,
        };

        if (assetScope === AssetScope.Page) {
          await uploadPageImage(uploadParams, {
            onError: (err) => {
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error uploading asset');
            },
          });
        } else {
          await uploadStepImage(uploadParams, {
            onError: (err) => {
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error uploading asset');
            },
          });
        }
      }
    },
    [draft, testType, uploadPageImage, uploadStepImage]
  );

  const handlePrimerInput = useCallback(
    (files, fileRejections) => {
      if (fileRejections && fileRejections.length > 0) {
        snackbar.error(`An error occurred uploading primer. ${serializeFileRejections(fileRejections)}.`);
        return;
      }

      files = Array.isArray(files) ? files : [files];
      files.forEach((file) => {
        uploadPrimer(
          {
            uploadId: cuid(),
            image: file,
            wevoId: draft.id,
          },
          {
            onError: (err) => {
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating primer');
            },
          }
        );
      });
    },
    [draft.id, uploadPrimer]
  );

  const handleAssetDelete = useCallback(
    (deleteCriteria) => {
      const { assetId, assetScope, pageId } = deleteCriteria ?? {};
      const assets = getAssetsForAssetScope(draft, { pageId });

      // note if the asset being deleted is the baseline
      const isBaseline = assets?.find((asset) => asset.id === assetId).isBaseline;
      if (assetScope === AssetScope.Page) {
        deletePage(
          { wevoId: draft.id, pageId: assetId },
          {
            onSuccess: () => {
              // if deleted asset was baseline, make sure every other asset is not a competitor
              if (isBaseline) {
                let changes = [];
                for (const asset of assets) {
                  if (asset.id !== assetId && asset.isCompetitor) {
                    changes.push({ pageId: asset.id, isCompetitor: false });
                  }
                }
                if (changes.length > 0) {
                  bulkUpdateAssets({ wevoId: draft.id, changes });
                }
              }
              // TODO: make group unvalid
              // invalidateQueries marks the query as stale, and the query will be refetched in the background
              queryClient.invalidateQueries(['customQuestionsData', { wevoId: draft.id }]);
            },
            onError: (err) => {
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting asset');
            },
          }
        );
      } else {
        deleteStep(
          { wevoId: draft.id, stepId: assetId },
          {
            onSuccess: () => {
              // invalidateQueries marks the query as stale, and the query will be refetched in the background
              queryClient.invalidateQueries(['customQuestionsData', { wevoId: draft.id }]);
            },
            onError: (err) => {
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting asset');
            },
          }
        );
      }
    },

    [bulkUpdateAssets, deletePage, deleteStep, draft, queryClient]
  );

  const updateAssetFields = useCallback(
    (updateCriteria) => {
      const { assetId, assetScope, fields } = updateCriteria ?? {};

      if (assetScope === AssetScope.Page) {
        updatePage(
          { wevoId: draft.id, pageId: assetId, ...fields },
          {
            onError: (err) => {
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating asset');
            },
          }
        );
      } else {
        updateStep(
          { wevoId: draft.id, stepId: assetId, ...fields },
          {
            onError: (err) => {
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating asset');
            },
          }
        );
      }
    },
    [draft.id, updatePage, updateStep]
  );

  const handleReorderAssets = useCallback(
    (reorderCriteria) => {
      const { result, assetScope, pageId } = reorderCriteria ?? {};

      // make sure we drag items only on dragAndDrop area
      if (!result.destination) return;

      const assets = getAssetsForAssetScope(draft, { pageId });

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;
      const assetToReorder = assets[sourceIndex];
      const assetId = assetToReorder.id;

      // copy original assests - as long as we don't modify the asset objects, only the order, this shallow copy is OK
      const originalAssets = [...assets];

      // generate reordered assets - as long as we don't modify the asset objects, only the order, this shallow copy is OK
      const reorderedAssets = [...assets];
      reorderedAssets.splice(sourceIndex, 1);
      reorderedAssets.splice(destinationIndex, 0, assetToReorder);

      if (assetScope === AssetScope.Step) {
        // Optimistically reorder the client-side mirror of the server state to make reordering appear smooth
        // When the backend responds with a success response, the client state will be wiped anyway
        queryClient.setQueryData(['wevoData', { wevoId: draft.id }], {
          ...draft,
          pages: (draft?.pages ?? []).map((page) => {
            if (String(page.id) === String(pageId)) {
              return { ...page, steps: reorderedAssets };
            }
            return page;
          }),
        });
        updateStepSortOrder(
          { id: draft.id, stepId: assetId, sortOrder: destinationIndex },
          {
            onError: (err) => {
              // On error, put the ordering back to what it was to undo optimistic change
              queryClient.setQueryData(['wevoData', { wevoId: draft.id }], {
                ...draft,
                pages: (draft?.pages ?? []).map((page) => {
                  if (String(page.id) === String(pageId)) {
                    return { ...page, steps: originalAssets };
                  }
                  return page;
                }),
              });
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error reordering steps');
            },
          }
        );
      } else {
        // Optimistically reorder the client-side mirror of the server state to make reordering appear smooth
        // When the backend responds with a success response, the client state will be wiped anyway
        queryClient.setQueryData(['wevoData', { wevoId: draft.id }], { ...draft, pages: reorderedAssets });
        updatePageSortOrder(
          { id: draft.id, pageId: assetId, sortOrder: destinationIndex },
          {
            onError: (err) => {
              // On error, put the ordering back to what it was to undo optimistic change
              queryClient.setQueryData(['wevoData', { wevoId: draft.id }], {
                ...draft,
                pages: originalAssets,
              });
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error reordering pages');
            },
          }
        );
      }
    },
    [draft, queryClient, updateStepSortOrder, updatePageSortOrder]
  );

  const togglePageBaseline = useCallback(
    (pageId) => {
      const assets = getAssetsForAssetScope(draft);
      const selectedPage = assets?.find((page) => page.id === pageId);

      // If we are setting the page to be the new baseline, we must ensure that all of the other pages are not marked as baseline.
      // Also, need to ensure that the page is not a competitor.
      if (!selectedPage.isBaseline) {
        const changes = [{ pageId: selectedPage.id, isBaseline: true, isCompetitor: false }];
        for (const page of assets) {
          if (page.id !== selectedPage.id) {
            changes.push({ pageId: page.id, isBaseline: false, isCompetitor: true });
          }
        }
        bulkUpdateAssets({ wevoId: draft.id, changes });
      } else {
        // we are unmarking this page as the baseline and set isCompetitor=false for all assets
        const changes = [];
        for (const page of assets) {
          changes.push({ pageId: page.id, isBaseline: false, isCompetitor: false });
        }
        bulkUpdateAssets({ wevoId: draft.id, changes });
      }
    },
    [bulkUpdateAssets, draft]
  );

  const togglePageCompetitor = useCallback(
    (pageId) => {
      const assets = getAssetsForAssetScope(draft);
      const page = assets?.find((page) => page.id === pageId);
      const newCompetitorValue = !page.isCompetitor;

      let updateParams = { wevoId: draft.id, pageId, isCompetitor: newCompetitorValue };

      // If we are setting the page to be a competitor, we need to unselect if it is a baseline.
      if (newCompetitorValue && page.isBaseline) {
        updateParams.isBaseline = false;
      }

      updatePage(updateParams, {
        onError: (err) => {
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating asset');
        },
      });
    },
    [draft, updatePage]
  );

  const handlePrimerContextInput = useCallback(
    (value) => {
      if (draft.pages.length > 0) {
        draft?.pages?.forEach((page) =>
          updatePage({ wevoId: draft.id, pageId: page.id, primerContext: value })
        );
      }
    },
    [draft.id, draft.pages, updatePage]
  );

  const handleTaskToCompleteInput = useCallback(
    (value) => {
      saveWevo(
        { id: wevoId, taskToComplete: value },
        {
          onError: (err, variables, { previousWevoState }) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    },
    [saveWevo, wevoId]
  );

  const handleVisitorObjectiveInput = useCallback(
    (value) => {
      saveWevo(
        { id: wevoId, visitorObjective: value.trim() },
        {
          onError: (err, variables, { previousWevoState }) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    },
    [saveWevo, wevoId]
  );

  const handleTestTypeSelect = useCallback(
    (selectedType) => {
      if (testType !== selectedType) {
        const pages = draft?.pages ?? [];
        const hasPages = pages.length > 0;

        const isJourneyTestType = [
          TestTypes.Journey,
          TestTypes.Usability,
          TestTypes.CompareJourneys,
          TestTypes.Lite,
        ].includes(testType);
        const hasJourneyStartUrls =
          pages.some((page) => !_.isEmpty(page?.journeyStartUrl)) ||
          !_.isEmpty(draft?.details?.journeyStartUrl);
        const hasTaskToComplete = !_isEmpty(draft?.details?.taskToComplete);
        const hasPrimer = !_isEmpty(draft?.pages?.[0]?.primer?.context);
        const journeyFieldsFilled = isJourneyTestType && hasJourneyStartUrls && hasTaskToComplete && hasPrimer;

        const isFormFilled = hasPages || journeyFieldsFilled;

        if (isTestTypeChanged(testType, selectedType) && isFormFilled) {
          setShowDiscardTestTypeDialog(true);
          setTestTypeToSetOnConfirm(selectedType);
          setPrevTestType(testType);
        } else {
          setTestType(selectedType);
        }
      }
    },
    [setTestType, testType, draft.pages, draft?.details?.taskToComplete, draft?.details?.journeyStartUrl]
  );

  const toggleShowTestTypeDialog = useCallback(() => {
    setShowDiscardTestTypeDialog(!showDiscardTestTypeDialog);
    setTestTypeToSetOnConfirm(null);
  }, [setShowDiscardTestTypeDialog, setTestTypeToSetOnConfirm, showDiscardTestTypeDialog]);

  const handleDeviceSelect = useCallback(
    (ev) => {
      saveWevo(
        { id: draft.id, devices: [Number(ev.target.value)] },
        {
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );

      const pages = draft?.pages ?? [];

      if (pages?.length) {
        const changes = pages.map((page) => ({
          pageId: page.id,
          deviceId: Number(ev.target.value),
        }));

        bulkUpdateAssets(
          {
            wevoId: draft.id,
            changes,
          },
          {
            onError: (err) => {
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating asset');
            },
          }
        );
      }
    },
    [bulkUpdateAssets, draft, saveWevo]
  );

  // ensures that visitorObjective doesn't have data for DQS or CDS
  useEffect(() => {
    if (isDQS || isCDS) {
      handleVisitorObjectiveInput('');
    }
  }, [handleVisitorObjectiveInput, isDQS, isCDS]);

  const discardTestType = useCallback(async () => {
    const previousTestType = prevTestType;

    setTestType(testTypeToSetOnConfirm);

    if (isTestTypeChanged(prevTestType, testTypeToSetOnConfirm)) {
      setPrevTestType(testTypeToSetOnConfirm);
    }

    if (
      [TestTypes.Journey, TestTypes.Usability, TestTypes.Lite].includes(testType) &&
      [TestTypes.CompareJourneys].includes(testTypeToSetOnConfirm) &&
      customQuestions?.length > 0
    ) {
      try {
        //  set scopes of all custom questions to be be journey level
        for (const question of customQuestions) {
          const newScopes = (draft?.pages ?? []).map((page) => ({
            wevoPageId: page.id,
            stepId: null,
          }));
          await updateCustomQuestionAsync({
            id: wevoId,
            groupId: question.groupId,
            scopes: newScopes,
          });
        }
      } catch (error) {
        setTestType(previousTestType);
        setPrevTestType(previousTestType);
        snackbar.error(error?.response?.data?.humanReadableMessage ?? 'Error updating test type');
      }
    }
  }, [
    testType,
    setTestType,
    setPrevTestType,
    prevTestType,
    testTypeToSetOnConfirm,
    customQuestions,
    updateCustomQuestionAsync,
    draft?.pages,
    wevoId,
  ]);

  const explainerSections = useMemo(() => {
    return [
      ...(testType === TestTypes.Journey || testType === TestTypes.Usability
        ? [
            {
              id: 'prototype-requirements',
              title: 'Prototype Requirements',
              content: <PrototypeRequirements />,
            },
          ]
        : []),
      {
        id: 'asset-requirements',
        title: 'File Requirements',
        content: (
          <FileRequirements
            maxPages={numMaxAssets(testType, AssetScope.Page)}
            maxSteps={numMaxAssets(testType, AssetScope.Step)}
          />
        ),
      },
      ...(showPrimer === PrimerValues.Yes
        ? [
            {
              id: 'primer-requirements',
              title: 'Primer Requirements',
              content: <FileRequirements maxPages={MAX_PRIMER_COUNT} />,
            },
          ]
        : []),
    ];
  }, [showPrimer, testType]);

  const handleAuthorizePrototypeApp = useCallback(
    ({ draft, page, callbacks }) => {
      authorizeFigma(
        {
          nextUri: `${process.env.REACT_APP_BASE_URL}/wevos/${draft.id}/edit/test-type?autoImport=1&pageId=${page.id}`,
        },
        {
          onSuccess: callbacks?.onSuccess ?? (() => {}),
          onError: (err) => {
            callbacks?.onError({ err, notify: snackbar });
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error authorizing Figma');
          },
          onSettled: callbacks?.onSettled ?? (() => {}),
        }
      );
    },
    [authorizeFigma]
  );

  const handleSetUpPrototype = useCallback(
    async ({ draft, page, prototype, callbacks }) => {
      const wevoId = draft.id;

      callbacks?.onBeforeRequest && callbacks?.onBeforeRequest();

      // Okay, so this block looks weird, but by making "preflight" requests, we're getting
      // figma to do the heavy lifting of generating and caching figma assets before we attempt to actually
      // make changes to the test. Even though the total time is increased, by using this trick we can reduce the chances
      // that we show the user an ugly error.
      try {
        await generateStaticImagesAsync({
          wevoId,
          pageId: prototype.wevoPageId,
          assetId: prototype.id,
          preflight: true,
        });

        await generateComponentsAsync({
          wevoId,
          pageId: prototype.wevoPageId,
          assetId: prototype.id,
          preflight: true,
        });
      } catch (err) {
        snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error exporting resources from figma.');
        callbacks?.onError && callbacks.onError();
        return;
      }
      try {
        await generateStaticImagesAsync({
          wevoId,
          pageId: prototype.wevoPageId,
          assetId: prototype.id,
        });
      } catch (err) {
        snackbar.error(
          err?.response?.data?.humanReadableMessage ?? 'Error generating static images for prototype.'
        );
        callbacks?.onError && callbacks.onError();
        return;
      }

      callbacks?.onStaticImagesGenerated && callbacks.onStaticImagesGenerated();

      try {
        await generateComponentsAsync({
          wevoId,
          pageId: prototype.wevoPageId,
          assetId: prototype.id,
        });
      } catch (err) {
        snackbar.error(
          err?.response?.data?.humanReadableMessage ?? 'Error generating static assets for prototype.'
        );
        callbacks?.onError && callbacks.onError();
        return;
      }

      callbacks?.onComponentsGenerated && callbacks.onComponentsGenerated();

      queryClient.invalidateQueries([
        'experience',
        { wevoId, pageId: Number(prototype.wevoPageId), includeConfig: true },
      ]);

      callbacks?.onSuccess && callbacks.onSuccess();
    },
    [generateStaticImagesAsync, generateComponentsAsync, queryClient]
  );

  const handleImportPrototype = useCallback(
    (importPrototypeCriteria) => {
      const { draft, page, callbacks, authorizeCallbacks } = importPrototypeCriteria ?? {};

      callbacks?.onBeforeRequest && callbacks.onBeforeRequest();

      importFigmaPrototype(
        {
          id: draft.id,
          journeyStartUrl: page?.journeyStartUrl ?? draft?.details?.journeyStartUrl,
          deviceId: draft.devices[0],
          pageId: page?.id,
          primerId: draft?.pages?.[0]?.primerId,
          primerContext: draft?.pages?.[0]?.primerContext,
        },
        {
          onSuccess: async (data) => {
            queryClient.invalidateQueries(['wevoData', { wevoId: draft.id }]);
            queryClient.invalidateQueries([
              'experience',
              { wevoId: draft.id, pageId: Number(page.id), includeConfig: true },
            ]);
            queryClient.setQueryData(
              [`figmaPrototypeByPage/wevo/${draft.id}/page/${page.id}`, { wevoId: draft.id, pageId: page.id }],
              data.prototype
            );
            callbacks?.onSuccess && callbacks.onSuccess(data);
            if (data?.showSizeWarning) {
              snackbar.warning(
                'Your prototype exceeds 25MB which may impact the performance of your test. Please see our guidelines for optimization by clicking the question mark icon.'
              );
            }
          },
          onError: async (err) => {
            if (err?.response?.status === 403) {
              // If import failed because err.status === 403, send user to the Oauth flow.
              handleAuthorizePrototypeApp({ draft, page, callbacks: authorizeCallbacks });
            } else {
              callbacks?.onError && callbacks.onError();
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error importing Figma prototype');
            }
          },
          onSettled: callbacks?.onSettled ?? (() => {}),
        }
      );
    },
    [handleAuthorizePrototypeApp, importFigmaPrototype, queryClient]
  );

  const handleDeletePrototype = useCallback(
    ({ draft, page, prototype, callbacks }) => {
      deleteFigmaPrototype(
        { wevoId: draft.id, pageId: page.id, prototypeId: prototype.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              'experience',
              { wevoId: draft.id, pageId: Number(page.id), includeConfig: true },
            ]);

            queryClient.invalidateQueries([
              `figmaPrototypeByPage/wevo/${draft.id}/page/${page.id}`,
              { wevoId: draft.id, pageId: page.id },
            ]);

            callbacks?.onSuccess && callbacks.onSuccess();
          },
          onError: (err) => {
            callbacks?.onError && callbacks.onError({ err, notify: snackbar });
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting Figma prototype');
          },
          onSettled: callbacks?.onSettled ?? (() => {}),
        }
      );
    },
    [deleteFigmaPrototype, queryClient]
  );

  const handleSetExperienceGoalStep = useCallback(
    async ({ draft, experienceStep, experience, callbacks }) => {
      const requestBase = {
        wevoId: draft.id,
        pageId: experience.wevoPageId,
        experienceId: experienceStep.experienceId,
      };

      const previousGoalSteps = experience?.steps?.filter(
        (other) => other.id !== experienceStep.id && other.endScreenType === ExperienceEndScreenType.Success
      );

      try {
        if (previousGoalSteps) {
          await Promise.all(
            previousGoalSteps.map((previousGoalStep) =>
              updateExperienceStepAsync({
                ...requestBase,
                experienceStepId: previousGoalStep.id,
                endScreenType: null,
              })
            )
          );
        }

        const data = await updateExperienceStepAsync({
          ...requestBase,
          experienceStepId: experienceStep.id,
          endScreenType: ExperienceEndScreenType.Success,
        });

        callbacks?.onSuccess && callbacks.onSuccess(data);

        queryClient.invalidateQueries([
          'experience',
          { wevoId: draft.id, pageId: Number(experience.wevoPageId), includeConfig: true },
        ]);
      } catch (err) {
        callbacks?.onError && callbacks.onError({ err, notify: snackbar });
        snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error setting goal page');
      }

      callbacks?.onSettled && callbacks.onSettled();
    },
    [queryClient, updateExperienceStepAsync]
  );

  const journeyConfigHandlers = useMemo(
    () => ({
      page: {
        handleNameChanged: ({ page, value }) => {
          updatePage(
            { wevoId: draft.id, pageId: page.id, name: value },
            {
              onError: (err) => {
                snackbar.error(
                  err?.response?.data?.humanReadableMessage ?? 'Error updating journey start url'
                );
              },
            }
          );
        },
        handleJourneyStartUrlChanged: ({ page, value }) => {
          updatePage(
            { wevoId: draft.id, pageId: page.id, journeyStartUrl: value },
            {
              onError: (err) => {
                snackbar.error(
                  err?.response?.data?.humanReadableMessage ?? 'Error updating journey start url'
                );
              },
            }
          );
        },
        handleCreatePage: ({ draft, name, callbacks }) =>
          addPage(
            {
              wevoId: draft.id,
              name,
              deviceId: draft.devices[0],
              primerId: draft?.pages?.[0]?.primerId,
              primerContext: draft?.pages?.[0]?.primerContext,
            },
            {
              onSuccess: (data) => {
                callbacks?.onSuccess && callbacks.onSuccess(data);
                queryClient.invalidateQueries(['wevoData', { wevoId: draft.id }]);
              },
              onError: (err) => {
                callbacks?.onError && callbacks.onError(err);
                queryClient.invalidateQueries(['wevoData', { wevoId: draft.id }]);
              },
              onSettled: () => {
                callbacks?.onSettled && callbacks.onSettled();
              },
            }
          ),
        handleDeletePage: ({ page, callbacks }) =>
          handleAssetDelete(
            { assetId: page.id, assetScope: AssetScope.Page },
            {
              onSuccess: (data) => {
                callbacks?.onSuccess && callbacks.onSuccess(data);
                queryClient.invalidateQueries(['wevoData', { wevoId: draft.id }]);
              },
              onError: (err) => {
                callbacks?.onError && callbacks.onError(err);
              },
              onSettled: () => {
                callbacks?.onSettled && callbacks.onSettled();
              },
            }
          ),
        handleReorderPages: ({ result }) => handleReorderAssets({ result, assetScope: AssetScope.Page }),
      },
      prototype: {
        handleAuthorizeApp: handleAuthorizePrototypeApp,
        handleDeletePrototype: handleDeletePrototype,
        handleImportPrototype: handleImportPrototype,
        handleSetUpPrototype: handleSetUpPrototype,
      },
      experience: {
        setExperienceGoalStep: handleSetExperienceGoalStep,
      },
    }),
    [
      draft,
      addPage,
      handleAssetDelete,
      handleAuthorizePrototypeApp,
      handleDeletePrototype,
      handleImportPrototype,
      handleReorderAssets,
      handleSetExperienceGoalStep,
      handleSetUpPrototype,
      updatePage,
      queryClient,
    ]
  );

  // Auto-Import Functionality
  const [globalPrototypeImportPage, setGlobalPrototypeImportPage] = useState(null);
  const [globalPrototypeImportStage, setGlobalPrototypeImportStage] = useState(
    IntakePrototypeImportStages.Ready
  );

  useEffect(() => {
    const shouldAutoImport = queryParams?.autoImport === '1';
    const pageId = queryParams?.pageId;
    const page = pageId ? (draft?.pages ?? []).find((page) => String(page.id) === String(pageId)) : null;

    if (shouldAutoImport && (page?.journeyStartUrl || draft?.details?.journeyStartUrl)) {
      setGlobalPrototypeImportPage(page);
      setGlobalPrototypeImportStage(IntakePrototypeImportStages.GeneratingImages);

      handleImportPrototype({
        draft,
        page,
        callbacks: {
          onSuccess: (data) => {
            handleSetUpPrototype({
              draft,
              page,
              prototype: data.prototype,
              callbacks: {
                onStaticImagesGenerated: () =>
                  setGlobalPrototypeImportStage(IntakePrototypeImportStages.GeneratingComponents),
                onComponentsGenerated: () => setGlobalPrototypeImportStage(IntakePrototypeImportStages.Ready),
                onSuccess: () => {
                  setGlobalPrototypeImportStage(IntakePrototypeImportStages.Ready);
                  setGlobalPrototypeImportPage(null);
                },
                onError: () => {
                  setGlobalPrototypeImportStage(IntakePrototypeImportStages.Ready);
                  setGlobalPrototypeImportPage(null);
                },
              },
            });
          },
          onError: () => {
            setGlobalPrototypeImportStage(IntakePrototypeImportStages.Ready);
            setGlobalPrototypeImportPage(null);
          },
        },
      });

      history.push({ pathname: `/wevos/${wevoId}/edit/test-type` });
    }
  }, [
    draft,
    queryParams,
    handleImportPrototype,
    handleSetUpPrototype,
    history,
    setGlobalPrototypeImportStage,
    wevoId,
  ]);

  if (_isEmpty(draft)) {
    return (
      <Box textAlign="center" pt={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ position: 'fixed', right: 20, top: 80 }}>
        <ExplainerPopup sections={explainerSections} />
      </Box>
      <Grid container spacing={2} justifyContent="center" className={classes.root}>
        <Grid item xs={3} />
        <Grid
          item
          xs={6}
          style={{
            minWidth: useWideLayout ? '900px' : '',
          }}>
          <TestTypeSelectionSection
            isDQS={isDQS}
            isCDS={isCDS}
            canRunUsability={canRunUsability}
            canRunLite={canRunLite}
            testType={testType}
            testTypeToSetOnConfirm={testTypeToSetOnConfirm}
            handleTestTypeSelect={handleTestTypeSelect}
            showDiscardTestTypeDialog={showDiscardTestTypeDialog}
            discardTestType={discardTestType}
            toggleShowTestTypeDialog={toggleShowTestTypeDialog}
            compareJourneysEnabled={compareJourneysEnabled}
          />
          {canRunUsability && testType === TestTypes.Usability && (
            <Box
              mb={4}
              style={{
                maxWidth: '900px',
              }}>
              <UsabilityTestInfoBanner classes={classes} />
            </Box>
          )}
          {canRunLite && testType === TestTypes.Lite && (
            <Box
              mb={4}
              style={{
                maxWidth: '900px',
              }}>
              <LiteTestInfoBanner classes={classes} />
            </Box>
          )}
        </Grid>
        <Grid item xs={3} />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={useWideLayout ? 2 : 3}></Grid>
        <Grid item xs={useWideLayout ? 8 : 6}>
          {/* Device Selection */}
          {testType !== TestTypes.Usability && (
            <Box mb={4}>
              <DeviceSelectionSection
                devices={draft.devices}
                onDeviceSelected={handleDeviceSelect}
                classes={classes}
              />
            </Box>
          )}
          {!_.isNil(globalPrototypeImportPage) && (
            <Box mb={4}>
              <Dialog open={true}>
                <DialogContent>
                  <ExperienceLoadingSection
                    page={globalPrototypeImportPage}
                    prototypeImportStage={globalPrototypeImportStage}
                  />
                </DialogContent>
              </Dialog>
            </Box>
          )}
          <Box mb={4}>
            <PageConfigurationList
              draft={draft}
              testType={testType}
              customQuestions={customQuestions}
              handleAssetDelete={handleAssetDelete}
              togglePageBaseline={togglePageBaseline}
              togglePageCompetitor={togglePageCompetitor}
              updateAssetFields={updateAssetFields}
              showBaselineCompetitor={showBaselineCompetitor}
              ongoingUploads={ongoingUploads}
              handleFileInput={handleFileInput}
              handleReorderAssets={handleReorderAssets}
              journeyConfigHandlers={journeyConfigHandlers}
            />
          </Box>
          {[TestTypes.Journey, TestTypes.CompareJourneys, TestTypes.Usability, TestTypes.Lite].includes(
            testType
          ) && (
            <Box mb={4}>
              <TaskToComplete
                errors={errors}
                taskToComplete={draft?.details?.taskToComplete ?? ''}
                handleTaskToCompleteInput={handleTaskToCompleteInput}
                hasExperienceConfiguration={
                  (draft?.pages ?? []).filter((page) => !_.isEmpty(page?.experience)).length > 0
                }
                disabled={(draft?.pages?.[0]?.steps ?? []).length === 0}
                testType={testType}
                canRunUsability={canRunUsability}
              />
            </Box>
          )}
          <Box mb={4}>
            <PrimerConfiguration
              draft={draft}
              primers={primers}
              handleShowPrimerSelect={handleShowPrimerSelect}
              handlePrimerInput={handlePrimerInput}
              ongoingPrimerUploads={ongoingPrimerUploads}
              primerContext={draft?.pages?.[0]?.primerContext}
              handlePrimerContextInput={handlePrimerContextInput}
              errors={errors}
              classes={classes}
            />
          </Box>
          {!isDQS &&
            testType !== TestTypes.Usability &&
            !draft.isUsabilityTestType &&
            testType !== TestTypes.Lite &&
            !draft.isLiteTestType && (
              <>
                <Box mb={4}>
                  <ExpectationsGap
                    expectationsGap={draft?.visitorObjective ?? ''}
                    onExpectationsGapChanged={handleVisitorObjectiveInput}
                    errors={errors}
                  />
                </Box>
                <Box mb={4}>
                  <QualitativeExpectationsSelect draft={draft} />
                </Box>
              </>
            )}
        </Grid>
        <Grid item xs={useWideLayout ? 2 : 3}></Grid>
      </Grid>
    </>
  );
};

TestTypePage.propTypes = {
  testType: PropTypes.oneOf(Object.values(TestTypes)).isRequired,
  setTestType: PropTypes.func.isRequired,
  validationMessage: PropTypes.string,
  isDQS: PropTypes.bool,
  isCDS: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    userCustomizations: getUserCustomizations(state),
  };
};
export default connect(mapStateToProps)(TestTypePage);
