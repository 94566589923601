import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip, Grid, Popper, TextField, Tooltip, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useAddTags, useDeleteTag, useWevosTags } from '../../../../../hooks/useTags';
import { MetricTypes } from '../../../../../modules/intake/constants';
import { snackbar } from '../../../../../notifications';
import theme from '../../../../../theme';
import { isMastercardUUID } from '../../helpers/tags';

const tagsCompareFunction = (a, b) => {
  const aIsUUID = isMastercardUUID(a.name);
  const bIsUUID = isMastercardUUID(b.name);

  if (aIsUUID && !bIsUUID) {
    return 1;
  } else if (!aIsUUID && bIsUUID) {
    return -1;
  } else {
    return 0;
  }
};

export const Tag = (props) => {
  const { tooltipTitle, tooltipPlacement, label, countIcon, tagId, onDelete, onClick, style } = props;

  return (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement || 'top'}>
      <Chip
        label={label}
        deleteIcon={countIcon || <CloseIcon style={{ color: 'black', fontSize: '16px' }} />}
        onDelete={!!onDelete ? () => onDelete(tagId) : null}
        onClick={!!onClick ? () => onClick() : null}
        sx={style}
        size="small"
      />
    </Tooltip>
  );
};

const MastercardInfoTooltip = () => {
  return (
    <Box sx={{ marginTop: -1, marginBottom: -1 }}>
      <Box mb={2} sx={{ marginLeft: 1, marginTop: 2 }}>
        <Typography variant="body1" sx={{ fontSize: '13px' }}>
          Your product's UUID, found in{' '}
          <b>
            <a
              href="https://mastercard.sharepoint.com/sites/info_macatalog"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'white' }}>
              Mastercard Catalog
            </a>
          </b>
          , is required to launch a CDS or DQS study.
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '13px' }}>
          All CDS and DQS studies are displayed within the applicable Studio Dashboards.
        </Typography>
      </Box>
    </Box>
  );
};

export const TagTextField = (props) => {
  const { tagsRef, onClick, onChange, onFocus, tag, isDQS, isCDS } = props;
  return (
    <Tooltip placement="bottom" title={''}>
      <Tooltip placement="bottom" title={isDQS || isCDS ? <MastercardInfoTooltip /> : ''}>
        <TextField
          ref={tagsRef}
          type="text"
          onClick={onClick}
          onFocus={onFocus}
          onChange={onChange}
          variant="outlined"
          fullWidth
          multiline={false}
          value={tag}
          required
          sx={{
            height: '44px',
            borderRadius: '10px',
            border: '1.5px solid #C7D6DF',
            '&:hover': {
              border: '1.5px solid #3B6CAB',
            },
            '& .MuiInputBase-input': {
              paddingTop: theme.spacing(1.5),
              paddingBottom: theme.spacing(1.5),
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            },
            '& fieldset': {
              border: 'none',
            },
          }}
          InputProps={{
            style: {
              fontSize: '13px',
            },
          }}
        />
      </Tooltip>
    </Tooltip>
  );
};

export const DraftTags = ({
  draft,
  tags,
  tagsRef,
  onChange,
  onFocus,
  setOpenTagsMenu,
  openTagsMenu,
  required,
  isDQS,
  isCDS,
  isMastercard,
  onTagsChanged,
}) => {
  const { data: wevosTagsGroups } = useWevosTags();
  const { mutate: addTags } = useAddTags();
  const { mutate: deleteTag } = useDeleteTag();

  const [tagInputValue, setTagInputValue] = useState('');

  // This is the mastercard uuid tag - in general it's the "first" one we can locate
  // in the tag array. It's super clunky.
  const uuidTag = useMemo(() => tags.find((tag) => isMastercardUUID(tag.name)), [tags]);

  const allTags = useMemo(() => {
    const tagsArray = [...wevosTagsGroups];
    for (const tag of tags) {
      if (!tagsArray?.map((tag) => tag.name).includes(tag.name)) {
        tagsArray.push(tag);
      }
    }

    let filteredAllTags = tagsArray;

    if (isMastercard && uuidTag) {
      filteredAllTags = filteredAllTags.filter((tag) => tag.name !== uuidTag?.name);
    }

    // sort to put uuid at the end
    return filteredAllTags.sort(tagsCompareFunction) || [];
  }, [isMastercard, wevosTagsGroups, tags, uuidTag]);

  const allTagsNames = useMemo(() => {
    return allTags?.map((tag) => tag?.name?.toLowerCase());
  }, [allTags]);

  const currentWevoTags = useMemo(() => {
    if (!isMastercard || !uuidTag) {
      return tags;
    }

    return tags.filter((tag) => tag.name !== uuidTag?.name);
  }, [isMastercard, tags, uuidTag]);

  const currentWevoTagsNames = useMemo(() => {
    return currentWevoTags?.map((tag) => tag?.name?.toLowerCase());
  }, [currentWevoTags]);

  const trimmedTag = useMemo(() => {
    return tagInputValue?.trim().toLowerCase();
  }, [tagInputValue]);

  const isNewTag = () => {
    return Boolean(!currentWevoTagsNames?.includes(trimmedTag) && !allTagsNames?.includes(trimmedTag));
  };

  const isAlreadyTagged = (tag) => {
    return Boolean(currentWevoTagsNames?.includes(tag?.toLowerCase()));
  };

  const createTag = useCallback(
    (newTag) =>
      addTags(
        { id: draft?.id, tags: [newTag.trim()] },
        {
          onSuccess: (data) => {
            setOpenTagsMenu(false);

            let tagToAdd;

            // v2 api implementation is fucked and not an easy fix, so hacking it for now
            if (Array.isArray(data)) {
              tagToAdd = { name: data?.[0]?.name, tagId: data?.[0]?.id };
            } else {
              tagToAdd = { name: data?.name, tagId: data?.id };
            }

            const newTags = [...tags, tagToAdd];
            onTagsChanged(newTags);
            setTagInputValue('');
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error adding tag');
          },
        }
      ),
    [draft?.id, addTags, setTagInputValue, setOpenTagsMenu, onTagsChanged, tags]
  );

  const deleteTagFromList = useCallback(
    (tagId, newTags) =>
      deleteTag(
        { id: draft?.id, tagId },
        {
          onSuccess: () => {
            onTagsChanged(newTags);
            setOpenTagsMenu(false);
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error removing tag');
          },
        }
      ),
    [draft?.id, setOpenTagsMenu, deleteTag, onTagsChanged]
  );

  const handleTagTextFieldFocus = () => {
    setOpenTagsMenu(true);
    onFocus && onFocus();
  };

  const handleTagChange = (ev) => {
    setTagInputValue(ev.target.value);
    setOpenTagsMenu(Boolean(ev.target.value.length));
    onChange && onChange(ev.target.value, ev);
  };

  const handleTagSelect = (tagSelected) => {
    createTag(tagSelected);
  };

  const handleDeleteChipClick = (tagId) => {
    const tagToDeleteIndex = tags.findIndex((tag) => tag.tagId === tagId);
    const newTags = [...tags];
    newTags.splice(tagToDeleteIndex, 1);

    deleteTagFromList(tagId, newTags);
  };

  const handleClose = () => {
    setTagInputValue('');
    setOpenTagsMenu(false);
  };

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <TagTextField
            tagsRef={tagsRef}
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
            }}
            onChange={handleTagChange}
            onFocus={handleTagTextFieldFocus}
            tag={tagInputValue}
            style={{ marginTop: 1, marginBottom: 1 }}
            required={required}
            isDQS={isDQS}
            isCDS={isCDS}
          />
        </Grid>
        <Grid
          container
          spacing={1}
          justifyContent={'flex-start'}
          sx={{
            maxHeight: 110,
            overflowY: 'auto',
            marginLeft: 1,
            marginTop: 0,
          }}>
          {currentWevoTags.map((tag, index) => {
            return (
              <Grid item key={`${tag.name}-${index}`} sx={{ maxWidth: '140px' }}>
                <Tag
                  tooltipTitle={tag.name}
                  label={tag.name}
                  tagId={tag.tagId || ''}
                  onDelete={() => handleDeleteChipClick(tag.tagId)}
                  style={{
                    backgroundColor: '#C7D6DF',
                    color: 'black',
                    fontSize: '12px',
                    height: '23px',
                    borderRadius: 10,
                    '& .MuiChip-deleteIcon': {
                      display: 'block',
                      height: '10px',
                      color: 'black',
                      stroke: theme.palette.primary.main,
                      strokeWidth: '0.5',
                    },
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {tagsRef.current && (
        <ClickAwayListener onClickAway={handleClose}>
          <Popper
            open={openTagsMenu}
            anchorEl={tagsRef.current}
            placement="bottom-start"
            transition
            disablePortal
            sx={{ zIndex: 1000, position: 'relative' }}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}>
                <Paper>
                  <MenuList id="tags-menu" aria-labelledby="tags-text-Box">
                    {isNewTag() ? (
                      <span>
                        <MenuItem
                          disabled={!tagInputValue.trim().length}
                          onClick={() => handleTagSelect(tagInputValue)}
                          sx={{ marginTop: '-8px', marginBottom: '-8px' }}>
                          <AddIcon
                            sx={{
                              color: grey[500],
                              fontSize: '13px',
                              marginLeft: -1,
                              marginRight: 0.25,
                            }}
                          />
                          <Typography variant="caption">Create tag:</Typography>
                          &nbsp;
                          {tagInputValue?.length ? (
                            <Tag label={tagInputValue} style={{ height: '25px' }} />
                          ) : (
                            <Typography variant="caption" sx={{ color: grey[600] }}>
                              ""
                            </Typography>
                          )}
                        </MenuItem>
                        {allTags?.filter(({ name }) => !trimmedTag || name?.toLowerCase().includes(trimmedTag))
                          .length ? (
                          <hr
                            style={{
                              background: grey[300],
                              height: '0.5px',
                              border: 'none',
                            }}
                          />
                        ) : null}
                      </span>
                    ) : null}
                    {allTags
                      ?.filter(
                        ({ name }) =>
                          !trimmedTag ||
                          (name.toLowerCase().includes(trimmedTag) && tagInputValue.trim().length)
                      )
                      ?.map((filteredTag, index) => {
                        return (
                          <MenuItem
                            disabled={isAlreadyTagged(filteredTag.name)}
                            key={index}
                            onClick={
                              !isAlreadyTagged(filteredTag.name)
                                ? () => handleTagSelect(filteredTag.name)
                                : () => {}
                            }>
                            <Tag
                              label={filteredTag.name}
                              style={{ height: '25px', backgroundColor: '#C7D6DF' }}
                            />
                          </MenuItem>
                        );
                      })}
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export const WevoTagsComboBox = ({ draft, tags, required, isDQS, isCDS, isMastercard, onTagsChanged }) => {
  const tagsRef = useRef(null);

  const [openTagsMenu, setOpenTagsMenu] = useState(false);

  return (
    <DraftTags
      draft={draft}
      tags={tags}
      onTagsChanged={onTagsChanged}
      tagsRef={tagsRef}
      openTagsMenu={openTagsMenu}
      setOpenTagsMenu={setOpenTagsMenu}
      required={required}
      isDQS={isDQS}
      isCDS={isCDS}
      isMastercard={isMastercard}
    />
  );
};

const Tags = ({ wevo, userCustomizations }) => {
  const [tagsList, setTagsList] = useState(wevo?.tags || []);
  const isDQS = wevo?.metricType === MetricTypes.MastercardDqs;
  const isCDS = wevo?.metricType === MetricTypes.MastercardCds;
  const isMastercard = Boolean(userCustomizations?.isMastercard);

  return (
    <WevoTagsComboBox
      draft={wevo}
      tags={tagsList}
      required={(isCDS || isDQS) && !tagsList?.length}
      isDQS={isDQS}
      isCDS={isCDS}
      onTagsChanged={setTagsList}
      isMastercard={isMastercard}
      // helperText={_get(error, 'message', '')}
    />
  );
};

export default Tags;
