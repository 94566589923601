import { Box, Typography } from '@mui/material';
import { WevoTestType } from '../../../../../../modules/wevos/constants';
import ToggleThumbnail, { ThumbnailGrid } from '../../../../components/ToggleThumbnail';
import { CustomQuestionScopeType } from '../../constants';
import { isScopeSelected, makeScopeFromPage, makeScopeFromStep } from '../../helpers';

function PageScopeSelector({ customQuestion, wevo, heading, onChange }) {
  const scopes = (wevo?.pages ?? []).map((page) => makeScopeFromPage({ page }));
  return (
    <ScopeAssetList customQuestion={customQuestion} scopes={scopes} onChange={onChange} heading={heading} />
  );
}

function StepScopeSelector({ customQuestion, page, heading, onChange }) {
  const scopes = (page?.steps ?? []).map((step) => makeScopeFromStep({ step }));
  return (
    <ScopeAssetList customQuestion={customQuestion} scopes={scopes} onChange={onChange} heading={heading} />
  );
}

function ScopeAssetList({ customQuestion, scopes, onChange, heading }) {
  const hasScopes = scopes && scopes?.length > 0;

  // n.b. the data structure for scopes is generated via makeScopeFromPage and makeScopeFromStep
  return (
    <Box>
      {heading && (
        <Typography mb={1} fontWeight={500}>
          {heading}
        </Typography>
      )}
      {hasScopes ? (
        <ThumbnailGrid>
          {scopes.map((scope, index) => {
            const isSelected = isScopeSelected({ customQuestion, scope });
            return (
              <ToggleThumbnail
                key={index}
                label={scope.label}
                images={scope.images}
                onSelect={() => onChange && onChange({ customQuestion, scope, isSelected: !isSelected })}
                isSelected={isSelected}
              />
            );
          })}
        </ThumbnailGrid>
      ) : (
        <Typography mb={1} fontSize={13} fontWeight={400} color={'#878787'}>
          Please add one or more pages to this experience
        </Typography>
      )}
    </Box>
  );
}

function ScopeAssetSelector({ wevo, customQuestion, scopeType, onChange }) {
  const isCompare = wevo?.testType === WevoTestType.Compare;

  if (isCompare) {
    return (
      <CompareStudyScopeSelector
        wevo={wevo}
        customQuestion={customQuestion}
        scopeType={scopeType}
        onChange={onChange}
      />
    );
  }

  return (
    <PageStudyScopeSelector
      wevo={wevo}
      customQuestion={customQuestion}
      scopeType={scopeType}
      onChange={onChange}
    />
  );
}

function PageStudyScopeSelector({ wevo, customQuestion, scopeType, onChange }) {
  if (scopeType === CustomQuestionScopeType.Page) {
    return <></>;
  }

  if (scopeType === CustomQuestionScopeType.Step) {
    const page = wevo?.pages?.[0];
    return <StepScopeSelector page={page} customQuestion={customQuestion} onChange={onChange} />;
  }
}

function CompareStudyScopeSelector({ wevo, customQuestion, scopeType, onChange }) {
  if (scopeType === CustomQuestionScopeType.Page) {
    return <PageScopeSelector wevo={wevo} customQuestion={customQuestion} onChange={onChange} />;
  }

  if (scopeType === CustomQuestionScopeType.Step) {
    const pages = wevo?.pages ?? [];

    return (
      <Box>
        {pages.map((page) => (
          <Box key={page.id} mb={4}>
            <StepScopeSelector
              key={page.id}
              page={page}
              customQuestion={customQuestion}
              onChange={onChange}
              heading={`Journey ${page.label}`}
            />
          </Box>
        ))}
      </Box>
    );
  }
}

export default ScopeAssetSelector;

export { PageScopeSelector, StepScopeSelector };
