import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: 48,
      fontWeight: 700,
    },
    h2: {
      fontSize: 36,
      fontWeight: 700,
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
    },
    h4: {
      fontSize: 20,
      fontWeight: 700,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 24,
      fontWeight: 500,
      letterSpacing: 0.25,
    },
    subtitle2: {
      fontSize: 20,
      fontWeight: 700,
      letterSpacing: 1.25,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.25,
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#276EB0',
    },
    secondary: {
      main: '#4CAF50',
    },
    gradient: {
      main: 'rgba(39, 110, 176, 0.1)',
      alt: 'rgba(76, 175, 80, 0.1)',
    },
    error: {
      main: '#FF5252',
      cds: '#CC4A44',
    },
    background: {
      default: '#F3F8Fb', // color picked from Kate's designs
    },
    disabled: {
      default: '#EFEFEF',
    },
    avatar: {
      main: '#F67270', // color picked from Kate's designs
    },
    text: {
      primary: '#212A37', // default font color
    },
  },
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          marginLeft: 0,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        marked: {
          padding: '8px 0px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
          fontWeight: 400,
          backgroundColor: 'rgba(97, 97, 97, .95)',
          padding: '8px 16px',
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
