import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItem } from '@mui/material';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function AccordionListItem({ title, children, initialOpen = true, icon, error, generatePath }) {
  const [isOpen, setIsOpen] = useState(initialOpen);

  return (
    <Box>
      <Accordion
        variant="outlined"
        expanded={isOpen}
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&.MuiAccordion-root': {
            borderRadius: 4,
            borderColor: '#C7D6DF',
          },
          '.MuiAccordionSummary-root': {
            backgroundColor: '#F4F4F6',
            borderRadius: 4,
            '&.Mui-expanded': {
              minHeight: 15,
              backgroundColor: '#F4F4F6',
              borderRadius: '16px 16px 0px 0px', // corresponds to theme units 4 x 4 = 16px
            },
            '&.Mui-expanded > .MuiAccordionSummary-content': {},
          },
          '.MuiAccordionDetails-root': {
            borderRadius: '0 0 16px 16px', // corresponds to theme units 4 x 4 = 16px
            borderTop: '1px solid #C7D6DF',
          },
        }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreRoundedIcon
              sx={{
                color: '#212A37 !important',
                fontSize: 28,
                cursor: 'pointer',
              }}
              onClick={() => setIsOpen(!isOpen)}
            />
          }
          sx={{
            '&.MuiAccordionSummary-root': {
              userSelect: 'auto',
            },
            '& .MuiAccordionSummary-content': {
              cursor: 'default',
            },
          }}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', width: '100%' }}>
            {icon}
            <Link to={generatePath} style={{ textDecoration: 'none', color: 'inherit' }}>
              {title}
            </Link>
            {error && (
              <Box display="flex" alignItems="center">
                {error}
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box>{children}</Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

function AccordionListItemWrapper({
  index,
  item,
  initialOpen,
  BoxProps,
  renderTitle,
  renderItem,
  renderIcon,
  renderError,
  generatePath,
}) {
  return (
    <Box {...BoxProps}>
      <AccordionListItem
        index={index}
        initialOpen={initialOpen}
        title={renderTitle(item, index)}
        icon={renderIcon(item, index)}
        error={renderError(item, index)}
        generatePath={generatePath(item, index)}>
        {renderItem(item, index)}
      </AccordionListItem>
    </Box>
  );
}

function AccordionList({ items, renderTitle, renderItem, renderIcon, renderError, generatePath }) {
  return (
    <List sx={{ width: '100%' }}>
      {!isEmpty(items) &&
        items?.map((item, index) => (
          <ListItem sx={{ p: 0 }} key={index}>
            <AccordionListItemWrapper
              index={index}
              item={item}
              title={item.title}
              initialOpen={true}
              BoxProps={{ sx: { mb: 4, ':last-child': { mb: 2 }, width: 'inherit' } }}
              renderTitle={renderTitle}
              renderItem={renderItem}
              renderIcon={renderIcon}
              renderError={renderError}
              generatePath={generatePath}
            />
          </ListItem>
        ))}
    </List>
  );
}

export { AccordionList, AccordionListItem };
