import InfoIcon from '@mui/icons-material/Info';
import { Grid, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import ProIconColor from '../../../assets/ProIconColor.png';
import ProIconGray from '../../../assets/ProIconGray.png';
import PulseIconColor from '../../../assets/PulseIconColor.png';
import PulseIconGray from '../../../assets/PulseIconGray.png';
import VideoIconColor from '../../../assets/VideoIconColor.png';
import VideoIconGray from '../../../assets/VideoIconGray.png';
import { canUseAutomatedInsightSessions, canUseCore } from '../../../modules/automated-insights/helpers';
import { Products } from '../../../modules/wevos/constants';
import { getInitialWevoProduct } from '../../../modules/wevos/helpers';
import { snackbar } from '../../../notifications';
import { Paths } from '../../../routes';
import theme from '../../../theme';
import { TrackEvent } from '../../analytics';
import useCreateWevo from '../../dashboard/hooks/useCreateWevo';
import SelectableProductCard from '../edit/SelectableProductCard';

const styles = {
  fieldSubTitleGrid: { height: '40px' },
  fieldSubTitleLightGrid: { height: '32px', backgroundColor: '#E6F1F7' },
  fieldTextTitle: { marginTop: '-12px', color: theme.palette.primary.main, fontSize: '16px' },
  fieldText: {
    marginTop: '-20px',
    fontSize: '14px',
    fontWeight: 600,
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  fieldTextLight: {
    marginTop: '-28px',
    fontSize: '14px',
    fontWeight: 600,
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  fieldTextIndent: {
    marginTop: '-6px',
    paddingLeft: 2.5,
    fontSize: '14px',
    display: 'inherit',
    verticalAlign: 'middle',
  },
  fieldTextLightIndent: {
    marginTop: '-10px',
    paddingLeft: 2.5,
    fontSize: '14px',
    display: 'inherit',
    verticalAlign: 'middle',
  },
  infoIcon: {
    marginLeft: (theme) => theme.spacing(1),
    color: grey[600],
    fontSize: '20px',
  },
};

const ProductSelection = ({ userCustomizations, user, userTeamId }) => {
  const [productSelected, setProductSelected] = useState(getInitialWevoProduct(user, userCustomizations));
  const history = useHistory();

  const { mutateAsync: createWevoAsync, isLoading: isCreatingNewWevo } = useCreateWevo();
  const { track } = useAnalytics();

  const proEnabled = canUseCore(userCustomizations);
  const pulseEnabled = canUseAutomatedInsightSessions(user, userCustomizations);
  const isUserlytics = Boolean(userCustomizations?.integrations?.userlytics);

  const handleProductSelected = (selectedProduct) => {
    setProductSelected(selectedProduct);
  };

  const handleStartClicked = async (selectedProduct) => {
    const reactAppBaseUrl = `${process.env.REACT_APP_BASE_URL}`;
    const pulseBaseUrl = `${reactAppBaseUrl}/pulse`;
    const hasNewIntake = userCustomizations?.features?.newIntake !== false;

    if (selectedProduct === Products.Pro && hasNewIntake) {
      await handleNewIntakeClick();
      return;
    }

    const userlyticsDomain = userCustomizations?.integrations?.userlyticsDomain || '';
    const userlyticsTeamId = userCustomizations?.integrations?.userlyticsTeamId || '';
    const userlyticsProjectId = userCustomizations?.integrations?.userlyticsProjectId || '';
    const userlyticsBaseUrl = `${process.env.REACT_APP_USERLYTICS_HOST}${process.env.REACT_APP_USERLYTICS_SSO_PATH}/${userlyticsDomain}`;
    const userlyticsStudyPath = `/client/teams/${userlyticsTeamId}/projects/${userlyticsProjectId}/study`;

    if (selectedProduct === Products.Pro) {
      history.push('/wevos/create');
      return;
    } else if (selectedProduct === Products.Pulse) {
      // hacky, but we need to force the theme for pulse to reload
      window.location.href = pulseBaseUrl;
      return;
    }
    // Redirects to Userlytics
    else if (selectedProduct === Products.Video) {
      window.location.href = `${userlyticsBaseUrl}?redirect_uri=${userlyticsStudyPath}`;
    }
  };

  const handleNewIntakeClick = async () => {
    const firstName = user?.firstName;
    const lastName = user?.lastName;
    const ownerName = `${firstName} ${lastName}`;
    const ownerEmail = user?.email;

    try {
      const wevo = await createWevoAsync({ ownerName, ownerEmail });
      const wevoId = wevo.id;
      track(TrackEvent.CREATED_TEST, {
        wevoId: wevoId,
        ownerName,
        ownerEmail,
        teamId: userTeamId,
      });

      history.push(generatePath(Paths.intake.intakeAudience, { wevoId }));
    } catch (err) {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error creating new wevo');
    }
  };

  return (
    <Grid
      container
      spacing={5}
      justifyContent="center"
      alignItems="flex-end"
      sx={{ marginTop: 6, paddingLeft: '20px', flexWrap: 'nowrap' }}>
      <Grid
        item
        sx={{
          minWidth: '560px',
          marginRight: -3,
          marginBottom: '69px',
        }}
        xs={2}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
            <Typography variant="body2" sx={styles.fieldText}>
              Participants (N)
            </Typography>
          </Grid>
          <Grid item sx={styles.fieldSubTitleLightGrid} xs={12}>
            <Typography variant="body2" sx={styles.fieldTextLight}>
              Duration
            </Typography>
          </Grid>
          <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
            <Typography variant="body2" sx={styles.fieldText}>
              Price
              <Tooltip
                title={`Please note that this price is for gen pop B2C audiences.
                For pricing on more complex audiences/B2B, please speak with your CSM.`}
                placement="right">
                <InfoIcon sx={styles.infoIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item sx={styles.fieldSubTitleLightGrid} xs={12}>
            <Typography variant="h6" sx={styles.fieldTextTitle}>
              <b>When should you use it</b>
            </Typography>
          </Grid>
          <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
            <Typography variant="body2" sx={styles.fieldTextIndent}>
              <b>Live </b> - Evaluate live digital experiences to uncover user friction
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            sx={styles.fieldSubTitleLightGrid}
            xs={12}>
            <Grid item>
              <Typography
                variant="body2"
                sx={{
                  ...styles.fieldTextLightIndent,
                  marginTop: '-16px',
                }}>
                <b>Pre-live</b> - Evaluate concepts, prototypes, and messaging
              </Typography>
            </Grid>
            <Grid item sx={{ marginTop: '-10px' }}>
              <Tooltip title={`From concept to design, design to dev, dev to launch`} placement="right">
                <InfoIcon sx={styles.infoIcon} />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            sx={styles.fieldSubTitleGrid}
            xs={12}>
            <Grid item>
              <Typography
                variant="body2"
                sx={{
                  ...styles.fieldTextIndent,
                  marginTop: '-16px',
                }}>
                <b>Compare</b> - Choose between multiple options, with confidence
              </Typography>
            </Grid>
            <Grid item sx={{ marginTop: '-10px' }}>
              <Tooltip title={`Multiple concepts, prototypes, designs, or messages`} placement="right">
                <InfoIcon sx={styles.infoIcon} />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            sx={styles.fieldSubTitleLightGrid}>
            <Grid item>
              <Typography
                variant="body2"
                sx={{
                  ...styles.fieldTextLightIndent,
                  marginTop: '-16px',
                }}>
                <b>Competitors</b> - Learn from competitors and benchmark
              </Typography>
            </Grid>
            <Grid item sx={{ marginTop: '-10px' }}>
              <Tooltip
                title={`Gain insights from your competitors to uncover areas for improvement `}
                placement="right">
                <InfoIcon sx={styles.infoIcon} />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
            <Typography variant="body2" sx={styles.fieldTextIndent}>
              <b>Localized</b> - Test in local languages, globally
            </Typography>
          </Grid>
          <Grid item sx={styles.fieldSubTitleLightGrid} xs={12}>
            <Typography variant="h6" sx={styles.fieldTextTitle}>
              <b>What do you get</b>
            </Typography>
          </Grid>
          <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
            <Typography variant="body2" sx={styles.fieldTextIndent}>
              <b>Key Findings</b>
            </Typography>
          </Grid>
          <Grid item sx={styles.fieldSubTitleLightGrid} xs={12}>
            <Typography variant="body2" sx={styles.fieldTextLightIndent}>
              <b>Sentiment map</b> with likes and dislikes
            </Typography>
          </Grid>
          <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
            <Typography variant="body2" sx={styles.fieldTextIndent}>
              <b>Experience score</b>, <b>diagnostics</b> and <b>benchmarks</b>
            </Typography>
          </Grid>
          {isUserlytics && (
            <>
              <Grid item sx={styles.fieldSubTitleLightGrid} xs={12}>
                <Typography variant="body2" sx={styles.fieldTextLightIndent}>
                  <b>Usability metrics</b> and <b>click tracking</b>
                </Typography>
              </Grid>
              <Grid item sx={styles.fieldSubTitleGrid} xs={12}>
                <Typography variant="body2" sx={styles.fieldTextIndent}>
                  <b>Video</b> of user journey and transcript
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <SelectableProductCard
        image={proEnabled ? ProIconColor : ProIconGray}
        title={'Deep Insights'}
        participants={'50-120'}
        duration={'1-7 days'}
        price={'0.25 - 1 credit'}
        includesLive={true}
        includesPreLive={true}
        includesCompare={true}
        includesCompetitors={true}
        includesLocalized={false}
        includesKeyFindings={true}
        includesSentimentMap={true}
        includesExperience={true}
        includesUsabilityMetrics={false}
        includesVideo={false}
        isVideoSupported={isUserlytics}
        product={Products.Pro}
        isSelected={productSelected === Products.Pro}
        setProductSelected={() => handleProductSelected(Products.Pro)}
        onStartClick={() => handleStartClicked(Products.Pro)}
        isLoading={isCreatingNewWevo}
        enabled={proEnabled}
      />
      <SelectableProductCard
        image={pulseEnabled ? PulseIconColor : PulseIconGray}
        title={'Instant Insights'}
        participants={'AI'}
        duration={'Instant'}
        price={'Monthly Plan'}
        includesLive={true}
        includesPreLive={true}
        includesCompare={false}
        includesCompetitors={false}
        includesLocalized={true}
        includesKeyFindings={true}
        includesSentimentMap={true}
        includesExperience={false}
        includesUsabilityMetrics={false}
        includesVideo={false}
        isVideoSupported={isUserlytics}
        product={Products.Pulse}
        isSelected={productSelected === Products.Pulse}
        setProductSelected={() => handleProductSelected(Products.Pulse)}
        onStartClick={() => handleStartClicked(Products.Pulse)}
        enabled={pulseEnabled}
      />
      {isUserlytics && (
        <SelectableProductCard
          image={isUserlytics ? VideoIconColor : VideoIconGray}
          title={'Video Capture'}
          participants={'12'}
          duration={'1-7 days'}
          price={'0.5 credit'}
          includesLive={true}
          includesPreLive={true}
          includesCompare={false}
          includesCompetitors={false}
          includesLocalized={true}
          includesKeyFindings={true}
          includesSentimentMap={false}
          includesExperience={false}
          includesUsabilityMetrics={true}
          includesVideo={true}
          isVideoSupported={isUserlytics}
          product={Products.Video}
          isSelected={productSelected === Products.Video}
          setProductSelected={() => handleProductSelected(Products.Video)}
          onStartClick={() => handleStartClicked(Products.Video)}
          enabled={isUserlytics}
        />
      )}
    </Grid>
  );
};

export default ProductSelection;
