import { Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: 75,
    zIndex: 1,
  },
  bottomBar: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
    borderTopRightRadius: 40,
    borderTopLeftRadius: 40,
    backgroundColor: '#fbfdfe',
    color: '#2d4868',
  },
  saveButton: {
    backgroundColor: 'white',
    borderRadius: 10,
    height: 35,
    fontSize: '14px',
    width: '155px',
    color: 'black',
  },
  backButton: {
    backgroundColor: 'white',
    borderRadius: 10,
    height: 35,
    fontSize: '14px',
    width: '85px',
    color: 'black',
  },
  requestPreviewLinkButton: {
    backgroundColor: 'white',
    borderRadius: 10,
    height: 35,
    fontSize: '14px',
    width: '185px',
    color: 'black',
  },
  nextButton: {
    borderRadius: 10,
    height: 35,
    fontSize: '14px',
    width: ({ nextLabel }) => (nextLabel === 'Next' ? '85px' : '140px'),
  },
}));

/**
 * Defines the bottom bar of the Test Creation screen, which allows the user to step back/next
 * and submit the test.
 */
export default function BottomBar(props) {
  const {
    handleBackClick,
    handleNextClick,
    handleSaveAndExit,
    handlePreviewSurveyLinkRequestClicked,
    nextLabel,
    isNextDisabled,
    nextButtonType,
    nextButtonForm,
  } = props;
  const classes = useStyles({ nextLabel });

  return (
    <Grid container className={classes.root} justifyContent="center">
      <Grid item container xs={11} spacing={1} className={classes.bottomBar}>
        <Grid item>
          {handleSaveAndExit && (
            <Button
              variant="contained"
              size="large"
              color="inherit"
              onClick={handleSaveAndExit}
              className={classes.saveButton}>
              Save and Exit
            </Button>
          )}
        </Grid>
        <Grid item>
          {handleBackClick && (
            <Button
              variant="contained"
              size="large"
              color="inherit"
              onClick={handleBackClick}
              className={classes.backButton}>
              Back
            </Button>
          )}
        </Grid>
        {/* handlePreviewSurveyLinkRequestClicked is currently undefined because we're hiding the request preview button for now */}
        {handlePreviewSurveyLinkRequestClicked && (
          <Grid item>
            <Button
              variant="contained"
              size="large"
              color="inherit"
              onClick={handlePreviewSurveyLinkRequestClicked}
              className={classes.requestPreviewLinkButton}>
              Request Preview
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type={nextButtonType}
            form={nextButtonForm}
            onClick={handleNextClick}
            disabled={isNextDisabled}
            className={classes.nextButton}>
            {nextLabel}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

BottomBar.propTypes = {
  handleBackClick: PropTypes.func,
  handleNextClick: PropTypes.func,
  handleSaveAndExit: PropTypes.func,
  nextLabel: PropTypes.string,
  isNextDisabled: PropTypes.bool,
  nextButtonType: PropTypes.string,
  nextButtonForm: PropTypes.string,
};

BottomBar.defaultProps = {
  nextLabel: 'Next',
  nextButtonType: 'button',
  nextButtonForm: '',
};
