import { Typography } from '@mui/material';
import SurveySectionPlaceholder from '../survey-elements/SurveySectionPlaceholder';

function DiagnosticsPreview() {
  return (
    <SurveySectionPlaceholder title="Diagnostics Benchmarking Block">
      <Typography>
        Respondents are asked 5-13 benchmark questions to gauge their emotional responses to the experience.
      </Typography>
    </SurveySectionPlaceholder>
  );
}

export default DiagnosticsPreview;
