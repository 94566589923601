import { Box } from '@mui/material';

function Panel({
  children,
  controls = null,
  BoxStyles = {},
  BoxProps = {},
  ContentStyles = {},
  ContentProps = {},
  scrolls = false,
}) {
  return (
    <Box sx={{ padding: 1, height: '80vh', ...BoxStyles }} {...BoxProps}>
      <Box
        sx={{
          height: '100%',
          background: '#FFFFFF',
          borderRadius: 3,
          boxShadow: '0px 0px 5.6px 2px rgba(199, 214, 223, 0.25)',
          overflow: 'hidden',
        }}>
        {controls && <Box>{controls}</Box>}
        <Box
          sx={{
            height: '100%',
            overflowY: scrolls ? 'auto' : 'hidden',
            px: 4,
            py: 3,
            ...ContentStyles,
          }}
          {...ContentProps}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default Panel;
