import { Box, CircularProgress, Grid, Tooltip, Typography, styled } from '@mui/material';
import Lottie from 'lottie-react';
import { useEffect, useMemo, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import ReactMarkdown from 'react-markdown';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as TakeawaysIcon } from '../../../src/assets/Takeaways.svg';
import { ReactComponent as CopiedCheckmarkIcon } from '../../../src/assets/copied-checkmark.svg';
import { ReactComponent as CopyIcon } from '../../../src/assets/copy-icon.svg';
import checkmark from '../../animations/checkmark.json';
import { customRenderers } from '../../helpers';
import { ProgressKeys, QuantDiagnostics, TaskStatus } from '../../modules/automated-insights/constants';
import { formatKeyFindingsText } from '../../modules/automated-insights/helpers';
import TrackEvent from '../analytics/TrackEvent';
import { InfoBox, WaitingMessage } from './InfoBox';
import SplashLoadingIndicator, { TaskLoadingScreen } from './SplashLoadingIndicator';
import ThemeIcon from './ThemeIcon';
import AccordionGroup from './ui/AccordionGroup';

const STATUS_MESSAGES = {
  pending: 'Request to generate insights is pending',
  enqueued: 'Getting ready to generate insights',
  running: 'Generating key findings',
  failed: 'Failed - please try again',
};

function AutomatedKeyFindingsSplashScreen() {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingX: '5%',
        flexDirection: 'column',
      }}>
      <ConfettiExplosion colors={['#125454', '#133751', '#F18F01', '#F374AE', '#ADF5FF']} />

      <Typography component="div" color="text.primary" textAlign="center" width="20%">
        <Lottie animationData={checkmark} loop={false} />
      </Typography>

      <Typography color="text.primary" textAlign="center" mb={6}>
        Your insights are ready!
      </Typography>
    </Box>
  );
}

const CopyIconStyled = styled(CopyIcon)(({ theme }) => ({
  fill: '#ACACAC',
  '&:hover': {
    fill: '#000F35',
  },
  height: '15px',
  width: '15px',
}));

const TaskAndContext = ({ header, titlesAndContent }) => {
  return <AccordionGroup header={header} titlesAndContent={titlesAndContent} />;
};

const AutomatedKeyFindings = ({
  keyFindings,
  themedFindings,
  taskStatus,
  taskProgress,
  isTaskDelayed,
  isTaskWaiting,
  showTheme,
  showSplashScreen,
  setShowSplashScreen,
  selectedQuantTheme,
  setSelectedTab,
  isQuantReady,
  hideReportContent,
  isSessionOwner,
  sessionDirective,
  sessionGoal,
  isCompare,
}) => {
  const SUCCESS_SPLASH_SCREEN_DURATION = 2500;
  const { track } = useAnalytics();

  const [isCopyClicked, setIsCopyClicked] = useState(false);

  const showLoadingIndicatorForShareLink = !isSessionOwner && hideReportContent;

  useEffect(() => {
    // side effect for animation - this only runs when insights are generated for the proposed audience in the session
    if (showSplashScreen) {
      setTimeout(() => setShowSplashScreen(false), SUCCESS_SPLASH_SCREEN_DURATION);
    }
  }, [showSplashScreen, setShowSplashScreen]);

  // no need to show the status bar when the status === 'completed' because we will display the key findings if they're available
  const showTaskStatus = useMemo(
    () => TaskStatus.Running && ProgressKeys.Insights === taskProgress?.progressKey && !keyFindings,
    [taskProgress, keyFindings]
  );

  const allDependentTasksResolved = useMemo(() => {
    if ((taskProgress?.dependencyTasks ?? []).length === 0) {
      return true;
    }

    return taskProgress.dependencyTasks.every((depTask) =>
      [TaskStatus.Completed, TaskStatus.Failed].includes(depTask.status)
    );
  }, [taskProgress]);

  // aside from task completion, we will also check if the progress key === ProgressKeys.HeatmapPoints
  // because the key findings should be done generating and fetched the by the time the heatmap points are generating
  const noKeyFindingsGenerated = useMemo(
    () => taskStatus === TaskStatus.Completed && keyFindings === '',
    [taskStatus, keyFindings]
  );

  const goalAndDirective = useMemo(() => {
    let details = [];
    if (sessionGoal) {
      const goal = { title: 'Task to complete', description: sessionGoal?.description };
      details.push(goal);
    }
    if (sessionDirective) {
      const directive = { title: 'Context', description: sessionDirective };
      details.push(directive);
    }
    return details;
  }, [sessionDirective, sessionGoal]);

  const onInsightsCopy = () => {
    track(TrackEvent.COPY_PULSE_KEY_FINDINGS);
    const plainText = formatKeyFindingsText(keyFindings);
    navigator.clipboard.writeText(plainText);
    setIsCopyClicked(true);
    setTimeout(() => {
      setIsCopyClicked(false);
    }, 1000);
  };

  if (noKeyFindingsGenerated) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingX: '5%',
        }}>
        <Typography color="text.primary" textAlign="center">
          Oops! Seems like there isn't enough text on this image for us to analyze. Upload another that has
          more text.
        </Typography>
      </Box>
    );
  } else if (showTaskStatus && allDependentTasksResolved) {
    return (
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingX: '5%',
        }}>
        <SplashLoadingIndicator
          message={STATUS_MESSAGES?.[taskStatus] || STATUS_MESSAGES.pending}
          isDelayed={isTaskDelayed}
          isFailed={taskStatus === TaskStatus.Failed}
        />
        {isTaskWaiting && (
          <Box
            sx={{
              width: 'inherit',
              height: 'fit-content',
              position: 'absolute',
              bottom: 0,
              paddingY: 0,
              display: [TaskStatus.Pending, TaskStatus.Enqueued].includes(taskStatus) ? 'initial' : 'none',
            }}
            p={2}>
            <InfoBox sx={{ height: '100%' }} isWarning={true}>
              <WaitingMessage />
            </InfoBox>
          </Box>
        )}
      </Box>
    );
  } else if (!allDependentTasksResolved) {
    return (
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingX: '5%',
        }}>
        <TaskLoadingScreen task={taskProgress} />
      </Box>
    );
  } else if (showSplashScreen) {
    return <AutomatedKeyFindingsSplashScreen />;
  } else {
    return (
      <>
        {!keyFindings || showLoadingIndicatorForShareLink ? (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Box
            className="lightContainer"
            sx={{
              height: '100%',
              paddingX: { xs: 2, sm: 3 },
              paddingBottom: 6,
              fontSize: '14px',
              overflow: hideReportContent ? 'hidden' : 'auto',
            }}>
            <Box alignItems={'center'} sx={{ marginBottom: 1, marginLeft: -0.7 }}>
              <Grid container spacing={1}>
                <Grid item flexWrap={'nowrap'} mt={0.25}>
                  <TakeawaysIcon fontSize="small" height={'22px'} />
                </Grid>
                <Grid item mt={0.5} ml={0.25}>
                  {isCompare ? (
                    <Typography fontSize={12} fontStyle="italic">
                      Takeaways - Discover how these experiences compare, their highlights and how to improve
                      them further.
                    </Typography>
                  ) : (
                    <Typography fontSize={13} fontStyle="italic">
                      Takeaways - Discover the highlights and areas for improvement on your experience.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
            {goalAndDirective?.length > 0 && (
              <Box mb={2}>
                <TaskAndContext header={'Details'} titlesAndContent={goalAndDirective} />
              </Box>
            )}
            {themedFindings.length === 0 ? (
              <ReactMarkdown components={customRenderers}>{keyFindings}</ReactMarkdown>
            ) : (
              themedFindings.map((finding, index) => (
                <Box sx={{ paddingBottom: 2 }} key={index}>
                  <Box sx={{ paddingBottom: 1 }}>
                    <Typography variant="h6">{finding?.title}</Typography>
                  </Box>
                  <Box sx={{ paddingBottom: 1 }}>
                    <Typography variant="body2">{finding?.findings}</Typography>
                  </Box>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    {showTheme &&
                      finding?.themes?.map((theme, innerIndex) => {
                        return (
                          QuantDiagnostics.includes(theme) && (
                            <ThemeIcon
                              theme={theme}
                              selectedTheme={selectedQuantTheme}
                              key={innerIndex}
                              setSelectedTab={setSelectedTab}
                              isClickable={isQuantReady}
                            />
                          )
                        );
                      })}
                  </div>
                </Box>
              ))
            )}
            <Tooltip title="Copy">
              <span>
                {!isCopyClicked ? (
                  <CopyIconStyled onClick={onInsightsCopy} />
                ) : (
                  <CopiedCheckmarkIcon style={{ height: '15px', width: '15px' }} />
                )}
              </span>
            </Tooltip>
          </Box>
        )}
      </>
    );
  }
};

export default AutomatedKeyFindings;
