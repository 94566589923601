import { Box, Slider, styled } from '@mui/material';
import { useEffect, useState } from 'react';

const StyledSlider = styled(Slider, {
  shouldForwardProp: (prop) => prop !== 'numMarks',
})(({ theme, numMarks }) => ({
  height: 11,
  color: '#3B6CAB',
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 18,
    width: 18,
    backgroundColor: '#FFFFFF',
    border: '.5px solid #3B6CAB',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-mark': {
    height: 11,
    width: 1,
    borderRadius: 0, // Removes rounded edges of marker
  },
  [`& .MuiSlider-mark[data-index="0"]`]: {
    display: 'none', // Hide the first tick
  },
  [`& .MuiSlider-mark[data-index="${numMarks - 1}"]`]: {
    display: 'none', // Hide the last tick
  },
  '& .MuiSlider-markLabel': {
    fontSize: 13,
    lineHeight: 'normal',
  },
}));

const RangeSlider = ({ label, segments, marks, onChange, ...props }) => {
  const [range, setRange] = useState([1, marks?.length]);

  useEffect(() => {
    if (segments) {
      // update range whenever the value changes to ensure slider is always in sync with the current attribute state
      let newRange = [Infinity, -Infinity];
      segments.forEach((segment, index) => {
        if (segment.isSelected) {
          if (index + 1 < newRange[0]) {
            newRange[0] = index + 1;
          }
          if (index + 2 > newRange[1]) {
            newRange[1] = index + 2;
          }
        }
      });
      setRange(newRange);
    } else {
      setRange([1, marks?.length]);
    }
  }, [segments, marks, setRange]);

  return (
    <Box padding={1}>
      <StyledSlider
        getAriaLabel={() => `${label} range`}
        value={range}
        valueLabelDisplay="off"
        numMarks={marks?.length}
        marks={marks || []}
        min={1}
        max={marks?.length}
        step={null}
        onChange={(_, newRange) => onChange({ newRange, marks })}
        size="small"
        {...props}
      />
    </Box>
  );
};

export default RangeSlider;
