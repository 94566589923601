import { Box, Card, Checkbox, FormControlLabel, Grid, Tooltip, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import PlaceholderImage from '../../../assets/placeholder-image.jpg';
import ImagePreview from '../../../ui/ImagePreview';

/**
 *
 * Note that images is an object containing all of the variant types, e.g. {original: {...}, mini: {...}}
 * and the `ImagePreview` component picks the appropriate one.
 */
function ToggleThumbnail({
  label,
  images,
  isSelected = false,
  onSelect,
  isDisabled = false,
  disabledMessage,
  context = {},
}) {
  return (
    <Card
      elevation={1}
      sx={{
        border: isSelected ? '1.5px solid #3B6CAB' : '1.5px solid #C7D6DF',
        borderRadius: '16px',
        backgroundColor: isSelected ? '#F3F8FB' : '#FFFFFF',
        '&:hover': {
          backgroundColor: '#F3F8FB',
        },
        '&:focus-visible': {
          backgroundColor: '#F3F8FB',
        },
      }}>
      <Box px={1} pt={1}>
        {isEmpty(images) ? (
          <PlaceholderImagePreview />
        ) : (
          <ImagePreview
            image={images}
            sx={{ border: '0.535px solid #3B6CAB', borderRadius: 16, height: '140px' }}
          />
        )}
      </Box>
      <Box px={2}>
        <Grid container spacing={1} alignItems="center" justifyContent="space-around" sx={{ pt: 0 }}>
          <Grid item xs={12}>
            <Tooltip title={isDisabled ? disabledMessage : ''} placement="right">
              <FormControlLabel
                label={
                  <Typography
                    fontSize={13}
                    fontWeight={isSelected ? 500 : 400}
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', textWrap: 'nowrap' }}>
                    {label}
                  </Typography>
                }
                control={
                  <Checkbox
                    checked={isSelected}
                    onChange={(ev) => onSelect && onSelect(ev, images, context)}
                    disabled={isDisabled}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

function PlaceholderImagePreview({ maxHeight, maxWidth }) {
  return (
    <img
      alt="placeholder"
      src={PlaceholderImage}
      style={{
        border: '0.535px solid #3B6CAB',
        borderRadius: 16,
        height: '140px',
        width: '100%',
        maxHeight,
        maxWidth,
        objectFit: 'cover',
        objectPosition: '0% 0',
        cursor: 'pointer',
      }}
    />
  );
}

export default ToggleThumbnail;

export function ThumbnailGrid({
  children,
  width = { xs: '100%', sm: '100%', md: '32%' },
  gap = { xs: 0, sm: 0, md: 1.5 },
}) {
  let childrenNodes = children;

  // if there is only one item, it doesn't come in as an array,
  // so we need to make it one
  if (children && !Array.isArray(children)) {
    childrenNodes = [children];
  }

  return (
    <Grid container sx={{ gap }}>
      {(childrenNodes ?? []).map((child, index) => {
        return (
          <Grid key={index} item sx={{ width, pt: 0 }}>
            {child}
          </Grid>
        );
      })}
    </Grid>
  );
}
