import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { BAR_CHART_LABEL, CustomQuestionTypes } from '../../../modules/report/constants';
import { roundPercentages } from '../../../modules/report/helpers.js';
import ChartAccessibility from './ChartAccessibility.js';
import LikertChart from './LikertChart';
import MultiSelectChart from './MultiSelectChart';
import MultiplechoiceChart from './MultiplechoiceChart';
import RankOrderChart from './RankOrderChart.js';
import YesNoChart from './YesNoChart';

const CustomQuestionGraph = ({
  questionType,
  labels,
  scores,
  numsOfRespondents,
  isDashboard,
  isPptComponent,
  width,
  height,
  componentId,
  id,
}) => {
  const customHeight = () => {
    const maxCharsInLine = isDashboard
      ? BAR_CHART_LABEL.dashboardMaxCharsInLine
      : BAR_CHART_LABEL.maxCharsInLine;

    const longestLabelLength = Math.max(...labels.map((label) => label.length));

    const barHeight = (lines) => {
      const additionalHeight = isDashboard ? 15 : 10;
      // for each line >2 lines, we will add additionalHeight
      return 65 + Math.ceil(lines - 2) * additionalHeight;
    };

    // for labels that are >2 lines long
    if (longestLabelLength > 2 * maxCharsInLine) {
      const lines = longestLabelLength / maxCharsInLine;
      return isDashboard ? labels.length * 70 : labels.length * barHeight(lines);
    }

    // for labels that are <=2 lines long
    if (labels.length <= 3) {
      return labels.length * 85;
    } else {
      return isDashboard || questionType === CustomQuestionTypes.RankOrder
        ? labels.length * 65
        : labels.length * 55;
    }
  };

  switch (questionType) {
    case CustomQuestionTypes.YesNoTrueFalse:
      return (
        <div style={{ maxWidth: isPptComponent ? '' : '500px', height: isDashboard ? '220px' : '' }}>
          {
            // Render chart only when there are scores to show
            scores.length > 0 && (
              <>
                <YesNoChart
                  labels={labels}
                  scores={scores}
                  isPptComponent={isPptComponent}
                  width={width}
                  height={height}
                  componentId={componentId}
                />
                <ChartAccessibility labels={labels} scores={scores} />
              </>
            )
          }
        </div>
      );
    case CustomQuestionTypes.FiveLikertScale:
      return (
        <div style={{ maxWidth: isPptComponent ? '' : '530px', height: isDashboard ? '220px' : '' }}>
          {
            // Render chart only when there are scores to show
            scores.length > 0 && (
              <>
                <LikertChart
                  labels={labels}
                  scores={scores}
                  narrowBarsWidth={65}
                  barsWidth={80}
                  isDashboard={isDashboard}
                  isPptComponent={isPptComponent}
                  width={width}
                  height={height}
                  componentId={componentId}
                />
                <ChartAccessibility labels={labels} scores={scores} />
              </>
            )
          }
        </div>
      );
    case CustomQuestionTypes.SevenLikertScale:
      return (
        <div style={{ maxWidth: isPptComponent ? '' : '640px', height: isDashboard ? '220px' : '' }}>
          {
            // Render chart only when there are scores to show
            scores.length > 0 && (
              <>
                <LikertChart
                  labels={labels}
                  scores={scores}
                  narrowBarsWidth={50}
                  barsWidth={75}
                  isDashboard={isDashboard}
                  isPptComponent={isPptComponent}
                  width={width}
                  height={height}
                  componentId={componentId}
                />
                <ChartAccessibility labels={labels} scores={scores} />
              </>
            )
          }
        </div>
      );
    case CustomQuestionTypes.MultipleChoice:
      return (
        <div
          style={{
            height: customHeight(),
            maxWidth: isPptComponent ? '' : '750px',
          }}>
          {
            // Render chart only when there are scores to show
            scores.length !== 0 && (
              <>
                <MultiplechoiceChart
                  labels={labels}
                  scores={scores}
                  isDashboard={isDashboard}
                  isPptComponent={isPptComponent}
                  width={width}
                  height={height}
                  componentId={componentId}
                />
                <ChartAccessibility labels={labels} scores={scores} />
              </>
            )
          }
        </div>
      );
    case CustomQuestionTypes.MultiSelect:
      return (
        <div style={{ height: customHeight(), maxWidth: isPptComponent ? '' : '750px' }}>
          {
            // Render chart only when there are scores to show
            scores.length > 0 && numsOfRespondents.length > 0 && (
              <>
                <MultiSelectChart
                  labels={labels}
                  scores={scores}
                  numsOfRespondents={numsOfRespondents}
                  isDashboard={isDashboard}
                  isPptComponent={isPptComponent}
                  width={width}
                  height={height}
                  componentId={componentId}
                />
                <ChartAccessibility
                  labels={labels}
                  scores={scores}
                  percentages={roundPercentages(
                    scores?.map((score, index) => (score / numsOfRespondents[index]) * 100 || 0),
                    1
                  )}
                />
              </>
            )
          }
        </div>
      );
    case CustomQuestionTypes.RankOrder:
      return (
        <div style={{ maxWidth: isPptComponent ? '' : '750px' }}>
          {
            // Render chart only when there are scores to show
            scores.length !== 0 && (
              <>
                <RankOrderChart
                  labels={labels}
                  data={scores}
                  numsOfRespondents={numsOfRespondents}
                  isDashboard={isDashboard}
                  isPptComponent={isPptComponent}
                  width={width}
                  height={height || customHeight() + 'px'}
                  componentId={componentId}
                />
                <ChartAccessibility labels={labels} data={scores} numsOfRespondents={numsOfRespondents} />
              </>
            )
          }
        </div>
      );
    case CustomQuestionTypes.CustomQualitative:
      return null;
    default:
      // Default case when question type is not supported
      return <Typography variant="body1">Chart is not supported</Typography>;
  }
};

CustomQuestionGraph.propTypes = {
  questionType: PropTypes.string,
  labels: PropTypes.array.isRequired,
  scores: PropTypes.array.isRequired,
  numsOfRespondents: PropTypes.array,
  isDashboard: PropTypes.bool,
  isPptComponent: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  componentId: PropTypes.string,
};

export default CustomQuestionGraph;
