import { Box } from '@mui/material';
import PlaceholderImage from '../../../assets/placeholder-image.jpg';
import ImagePreview from '../../../ui/ImagePreview';

function ImageCard({ images }) {
  return (
    <Box
      sx={{
        p: '2px',
        border: '1px solid #C7D6DF',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
      }}>
      <Box px={1} pt={1} sx={{ borderRadius: 12 }}>
        <ImagePreview
          image={images}
          sx={{ border: '0.535px solid #3B6CAB', borderRadius: 12, width: '100%' }}
        />
      </Box>
    </Box>
  );
}

export function PlaceholderImagePreviewCard({ maxHeight, maxWidth }) {
  return (
    <Box
      sx={{
        p: '2px',
        border: '1px solid #C7D6DF',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
      }}>
      <Box px={1} pt={1} sx={{ borderRadius: 12 }}>
        <img
          alt="placeholder"
          src={PlaceholderImage}
          style={{
            border: '0.535px solid #3B6CAB',
            borderRadius: 12,
            width: '100%',
            maxHeight,
            maxWidth,
            objectFit: 'cover',
            objectPosition: '0% 0',
            cursor: 'pointer',
          }}
        />
      </Box>
    </Box>
  );
}

export default ImageCard;
