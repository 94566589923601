import { Box, Typography } from '@mui/material';
import React from 'react';
import IntakeTooltip from './IntakeTooltip';

const Tag = React.forwardRef(({ label, hasTooltip, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      tabIndex="0" // Makes it focusable for keyboard users
      aria-label={!hasTooltip ? label : undefined} // If no tooltip, set aria-label
      sx={{
        display: 'inline-block',
        paddingX: '4px',
        borderRadius: '4px',
        border: '1px solid #3B6CAB',
        cursor: hasTooltip ? 'pointer' : 'default',
      }}
      {...props}>
      <Typography
        sx={{
          fontSize: '11px',
          fontWeight: 500,
          textTransform: 'uppercase',
          lineHeight: 1.2,
          color: '#3B6CAB',
        }}>
        {label}
      </Typography>
    </Box>
  );
});

const TagWithTooltip = ({ label, tooltipProps }) => {
  return (
    <IntakeTooltip {...tooltipProps}>
      <Tag label={label} hasTooltip />
    </IntakeTooltip>
  );
};

const BetaTag = ({ tooltipProps }) => {
  return <TagWithTooltip label={'beta'} tooltipProps={tooltipProps} />;
};

export { Tag, TagWithTooltip, BetaTag };
