import { MAX_DEEP_DIVE_STEPS } from '../constants';

/**
 * Filters rejected Dropzone files by Dropzone error code
 */
export const getFilesWithError = (fileRejections, errorCode) => {
  return fileRejections.filter((file) => file?.errors?.some((error) => error?.code === errorCode));
};

export const withinFileLimit = (numFiles, sessionType) => {
  return numFiles <= MAX_DEEP_DIVE_STEPS;
};

export const exceedFileAmount = (numFiles, sessionType) => {
  return numFiles - MAX_DEEP_DIVE_STEPS;
};

export function serializeFileRejections(fileRejections) {
  const flattenedErrors = fileRejections.flatMap((rejection) => rejection?.errors ?? []);
  const rejections = [];

  if (flattenedErrors.find((error) => error.code === 'file-too-large')) {
    rejections.push('Please ensure files are less than 20mb');
  }

  if (flattenedErrors.find((error) => error.code === 'file-invalid-type')) {
    rejections.push('Please ensure files are png or jpeg');
  }

  return rejections.join('. ');
}
