import produce from 'immer';
import { isNil as _isNil, set as _set } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { CustomScreenerMutationKeys } from '../../../modules/intake/constants';

const OPTIMISTIC_UPDATE_FIELDS = ['name', 'questionText', 'questionType', 'options', 'details'];

const updateCustomScreener = async ({ wevoId, filterId, changes }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/filters/${filterId}`,
    method: 'PUT',
    data: { filter: { id: filterId, ...changes } },
  });
  return data?.filter;
};

/**
 * Custom hook for updating a customScreener.
 * Upon a successful response from the api, this hook will automatically update the queryCache with the updated data
 */
export default function useUpdateCustomScreener() {
  const queryClient = useQueryClient();

  return useMutation(updateCustomScreener, {
    mutationKey: CustomScreenerMutationKeys.updateCustomScreener,
    onMutate: async ({ wevoId, filterId, changes }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['customScreeners', { wevoId }]);

      // Snapshot the previous customScreeners value
      const previousCustomScreenersState = await queryClient.getQueryData(['customScreeners', { wevoId }]);

      if (Object.keys(changes).some((field) => OPTIMISTIC_UPDATE_FIELDS.includes(field))) {
        const updatedCustomScreenersState = produce(previousCustomScreenersState, (draftState) => {
          const index = draftState?.findIndex((q) => String(q?.id) === String(filterId));

          if (index >= 0) {
            if (!_isNil(changes?.name)) {
              _set(draftState[index], 'name', changes.name);
            }
            if (!_isNil(changes?.questionText)) {
              _set(draftState[index], 'questionText', changes.questionText);
            }
            if (!_isNil(changes?.questionType)) {
              _set(draftState[index], 'questionType', changes.questionType);
            }
            if (!_isNil(changes?.details?.labelsType)) {
              _set(draftState[index], 'details.labelsType', changes.details.labelsType);
            }

            if (changes?.options?.length > 0) {
              const updatedOptions = draftState[index]?.options?.map((option) => {
                const optionWithChanges = changes?.options?.find(
                  (changedOption) => changedOption?.id === option?.id
                );
                if (!_isNil(optionWithChanges?.optionText)) {
                  option.optionText = optionWithChanges.optionText;
                }
                if (!_isNil(optionWithChanges?.outcome)) {
                  option.outcome = optionWithChanges.outcome;
                }
                return option;
              });
              _set(draftState[index], 'options', updatedOptions);
            }
          }
        });

        // Update query data
        queryClient.setQueryData(['customScreeners', { wevoId }], updatedCustomScreenersState);
      }

      // Return a context object with the snapshotted value
      return { previousCustomScreenersState };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, { wevoId }, { previousCustomScreenersState }) => {
      queryClient.setQueryData(['customScreeners', { wevoId }], previousCustomScreenersState);
    },
  });
}
