import styled from '@emotion/styled';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CustomTextField } from '../../../../../components';
import DebouncedInput from '../../../../../edit/DebouncedInput';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: grey[600],
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

function CustomChoice({
  customChoice,
  handleCustomChoiceChange,
  handleCustomChoiceDelete,
  isDeletable = false,
  canDeleteChoice = false,
  icon,
}) {
  return (
    <Grid container direction="row" alignItems="center" sx={{ columnGap: 2 }}>
      <Grid item display="flex" alignItems="center">
        {icon}
      </Grid>
      <Grid item xs>
        <DebouncedInput
          value={customChoice}
          onChange={(value) => handleCustomChoiceChange(value)}
          debounceMs={500}
          renderInput={({ value, onChange }) => {
            return (
              <CustomTextField
                fullWidth
                type="text"
                variant="outlined"
                value={value}
                placeholder="Please enter a custom choice..."
                onChange={onChange}
                name="Question"
                autoComplete="off"
                InputProps={{
                  endAdornment: isDeletable ? (
                    <InputAdornment position="end" sx={{ marginRight: '-10px' }}>
                      <StyledIconButton
                        aria-label="delete"
                        size="large"
                        onClick={() => handleCustomChoiceDelete(value)}
                        disabled={!canDeleteChoice}>
                        <IndeterminateCheckBoxIcon />
                      </StyledIconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
}

function MultipleChoiceCustomChoice(props) {
  return (
    <CustomChoice
      {...props}
      icon={<RadioButtonUncheckedIcon sx={{ fontSize: 'large', color: '#C7D6DF' }} />}
    />
  );
}

function MultipleSelectionCustomChoice(props) {
  return (
    <CustomChoice
      {...props}
      icon={<RadioButtonUncheckedIcon sx={{ fontSize: 'large', color: '#C7D6DF' }} />}
    />
  );
}

export { MultipleChoiceCustomChoice, MultipleSelectionCustomChoice };
