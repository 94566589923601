import { cloneDeep } from 'lodash';

export const optimisticMergeUpdates = ({ wevo, updateFields }) => {
  const newState = cloneDeep(wevo);

  for (const [key, value] of Object.entries(updateFields)) {
    newState[key] = value;
  }

  return newState;
};

export const getImageOnlyPreviewRespondentLink = ({ page }) => {
  // survey-app has routes for serving up images to respondents for image-only journeys
  // the preview link is the authenticated version that the customer sees so that they
  // have an idea of what a respondent would see.
  const surveyAppBaseUrl = process.env.REACT_APP_SURVEY_APP_HOST;
  const pageId = page.id;
  return `${surveyAppBaseUrl}/pages/${pageId}/images/preview`;
};
