import { Box, Typography } from '@mui/material';
import { ReactComponent as IntakeFasterOptionIcon } from '../../../../../assets/intake-faster-icon-contained.svg';
import { ReactComponent as IntakeFastestOptionIcon } from '../../../../../assets/intake-fastest-icon-contained.svg';
import { ReactComponent as IntakeInsightsOptionIcon } from '../../../../../assets/intake-insights-icon-contained.svg';
import { ReactComponent as IntakeMoreInsightsOptionIcon } from '../../../../../assets/intake-more-insights-icon-contained.svg';
import { ReactComponent as IntakeMostInsightsOptionIcon } from '../../../../../assets/intake-most-insights-icon-contained.svg';

function CustomSliderMark({ label, icon, BoxProps }) {
  return (
    <Box mt={2} {...(BoxProps || {})}>
      <Box textAlign={'center'}>
        <Typography mb={0.25} fontSize={'13px'} fontWeight={700} sx={{ color: '#878787' }}>
          {label}
        </Typography>
        <Box>{icon}</Box>
      </Box>
    </Box>
  );
}

export const SampleSizes = [
  {
    label: (
      <CustomSliderMark
        label={10}
        icon={<IntakeFastestOptionIcon />}
        BoxProps={{ sx: { marginLeft: '15px' } }}
      />
    ),
    value: 10,
    isEnabled: true,
  },
  { label: <CustomSliderMark label={50} icon={<IntakeFasterOptionIcon />} />, value: 50, isEnabled: true },
  { label: <CustomSliderMark label={120} icon={<IntakeInsightsOptionIcon />} />, value: 120, isEnabled: true },
  {
    label: (
      <CustomSliderMark
        label={200}
        icon={<IntakeMoreInsightsOptionIcon />}
        BoxProps={{ sx: { marginRight: '15px' } }}
      />
    ),
    value: 200,
    isEnabled: true,
  },
];

const getSliderMarkIcon = ({ sampleSize, sampleSizes }) => {
  const sampleSizeRange = (sampleSizes ?? []).map((sampleSize) => sampleSize.n);
  sampleSizeRange.sort();

  if (sampleSize.n === 10) {
    return <IntakeFastestOptionIcon />;
  }

  if (sampleSize.n === 50) {
    return <IntakeFasterOptionIcon />;
  }

  if ([60, 75].includes(sampleSize.n)) {
    return <IntakeInsightsOptionIcon />;
  }

  const hasNonStandardN = sampleSizeRange.filter((n) => [60, 75].includes(n)).length > 0;

  if (sampleSize.n === 120) {
    return hasNonStandardN ? <IntakeMoreInsightsOptionIcon /> : <IntakeInsightsOptionIcon />;
  }

  if (sampleSize.n === 200) {
    const has120 = !!sampleSizeRange.find((n) => n === 120);

    if (hasNonStandardN && has120) {
      return <IntakeMostInsightsOptionIcon />;
    }

    return <IntakeMoreInsightsOptionIcon />;
  }
};

// note: assumes sample sizes are sorted
const getSliderMarks = (sampleSizes) => {
  return sampleSizes.map((sampleSize) => {
    return {
      label: <CustomSliderMark label={sampleSize.n} icon={getSliderMarkIcon({ sampleSize, sampleSizes })} />,
      value: sampleSize.n,
      isEnabled: true,
    };
  });
};

export { CustomSliderMark, getSliderMarks };
