import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { SEGMENT_ID_TO_SEGMENT_LABEL } from '../../../../../../modules/wevos/constants';

const AttributeTable = ({ label, attribute, segments, maxWidth = { md: 193 } }) => {
  return (
    <Box sx={{ maxWidth: maxWidth }}>
      {label && <Typography sx={{ marginBottom: 0.5, fontSize: 11, fontWeight: 700 }}>{label}</Typography>}
      <Box sx={{ paddingY: 1, border: '1px solid #C7D6DF', borderRadius: '10px' }}>
        <Grid container flexDirection="column">
          {segments?.map((segment, index) => (
            <Grid item key={segment?.id}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    columnGap: 2,
                    paddingX: 1,
                  }}>
                  <Box display="flex" alignItems="center">
                    {attribute?.isSelected && (segment?.is_selected || segment?.isSelected) && (
                      <CheckBoxRoundedIcon fontSize="small" sx={{ marginRight: 1, color: '#212A37' }} />
                    )}
                    <Typography
                      style={{
                        fontSize: 10,
                        color: !attribute?.isSelected ? 'rgba(0, 0, 0, 0.3)' : 'inherit',
                      }}>
                      {SEGMENT_ID_TO_SEGMENT_LABEL[Number(segment?.audienceSegmentId || segment?.id)]}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: 10,
                      fontWeight: 700,
                      color: !attribute?.isSelected ? 'rgba(0, 0, 0, 0.3)' : 'inherit',
                    }}>
                    {attribute?.isCustomizedQuota ? `${segment?.percentage}%` : 'N/A'}
                  </Typography>
                </Box>
                {index !== segments?.length - 1 && <Divider sx={{ marginY: 1, borderColor: '#C7D6DF' }} />}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AttributeTable;
