import { cloneDeep, isNil } from 'lodash';
import { WevoTestType } from '../../../../modules/wevos/constants';

export const optimisticMergeUpdates = ({ wevo, updateFields }) => {
  const newState = cloneDeep(wevo);

  for (const [key, value] of Object.entries(updateFields)) {
    // these fields are saved as top-level fields in the api
    // however, they are saved in the details json of the wevo, so the optimistic
    // merge needs to stuff them into the details block to match what we get back from the api
    if (
      [
        'includeSentimentMaps',
        'includeExpectations',
        'includeKeyFindings',
        'includeCustomQuestions',
        'includeDiagnostics',
        'customQuestionLimit',
        'includeQualitativeExpectations',
        'taskToComplete',
        'isCustomSurvey',
      ].includes(key)
    ) {
      newState.details = { ...newState.details, [key]: value };

      // custom surveys are page-only and the backend will force this change
      if (key === 'isCustomSurvey' && value === true) {
        newState.testType = WevoTestType.Page;
      }
      continue;
    }

    if (key === 'numRespondents') {
      newState.numTargetedRespondentsPerPage = value;
    }

    newState[key] = value;
  }

  return newState;
};

export const optimisticMergePageUpdates = ({ page, updateFields }) => {
  const newState = cloneDeep(page);

  for (const [key, value] of Object.entries(updateFields)) {
    newState[key] = value;
  }

  return newState;
};

export const optimisticMergeStepUpdates = ({ step, updateFields }) => {
  const newState = cloneDeep(step);

  for (const [key, value] of Object.entries(updateFields)) {
    newState[key] = value;
  }

  return newState;
};

export const getIndexOfPageById = (wevo, pageId) => {
  const pageIds = (wevo?.pages ?? []).map((page) => String(page.id));
  return pageIds.indexOf(String(pageId));
};

export const getIndexOfPage = (wevo, page) => {
  return getIndexOfPageById(wevo, page.id);
};

export const getIndexOfStepForPageById = (page, stepId) => {
  const stepIds = (page?.steps ?? []).map((step) => String(step.id));
  return stepIds.indexOf(String(stepId));
};

export const getIndexOfStepForPage = (page, step) => {
  return getIndexOfStepForPageById(page, String(step.id));
};

export const getIndexOfStepById = (wevo, stepId) => {
  for (const page of wevo?.pages ?? []) {
    for (const [stepIndex, step] of (page?.steps ?? []).entries()) {
      if (String(step.id) === String(stepId)) {
        return stepIndex;
      }
    }
  }

  return -1;
};

export const getIndexOfStep = (wevo, step) => {
  const page = wevo?.pages?.find((page) => String(page.id) === String(step.pageId));
  if (!page) return -1;
  return getIndexOfStepForPage(page, step);
};

export const isReportFeatureFlag = (value) => {
  return [
    'includeKeyFindings',
    'includeDiagnostics',
    'includeExpectations',
    'includeSentimentMaps',
    'includeCustomQuestions',
  ].includes(value.trim());
};

export const getReportFeatures = ({ wevo }) => {
  const features = {
    includeKeyFindings: isNil(wevo?.details?.includeKeyFindings) ? true : wevo.details.includeKeyFindings,
    includeExpectations: isNil(wevo?.details?.includeExpectations) ? true : wevo.details.includeExpectations,
    includeQualitativeExpectations: isNil(wevo?.details?.includeQualitativeExpectations)
      ? true
      : wevo.details.includeQualitativeExpectations,
    includeSentimentMaps: isNil(wevo?.details?.includeSentimentMaps)
      ? true
      : wevo.details.includeSentimentMaps,
    includeDiagnostics: isNil(wevo?.details?.includeDiagnostics) ? true : wevo.details.includeDiagnostics,
    includeCustomQuestions: isNil(wevo?.details?.includeCustomQuestions)
      ? true
      : wevo.details.includeCustomQuestions,
    customQuestionLimit: isNil(wevo?.details?.customQuestionLimit) ? 5 : wevo.details.customQuestionLimit,
  };
  return features;
};

export const calculateCustomQuestionLimit = ({ reportFeatures }) => {
  // every study starts at 5 by default
  let customQuestionLimit = 5;

  // for each report feature excluded, 5 questions are added to the limit
  if (reportFeatures?.includeDiagnostics === false) {
    customQuestionLimit += 5;
  }

  if (reportFeatures?.includeSentimentMaps === false) {
    customQuestionLimit += 5;
  }

  if (reportFeatures?.includeExpectations === false) {
    customQuestionLimit += 5;
  }

  if (reportFeatures?.includeKeyFindings === false) {
    customQuestionLimit += 5;
  }

  return customQuestionLimit;
};

export const isCustomSurveyType = (wevo) => wevo?.details?.isCustomSurvey === true;
