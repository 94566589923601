import { Box, Typography } from '@mui/material';

function PageBreakIndicator({ spacing = 1 }) {
  return (
    <Box my={spacing} sx={{ width: '100%' }} display="flex" justifyContent="space-between" alignItems="center">
      <Box sx={{ height: '0px', border: '1px solid #C7D6DF', width: '40%', mx: 1 }} />
      <Box alignSelf="center">
        <Typography fontSize={10} fontWeight={400} textAlign="center" color="#878787">
          Page Break
        </Typography>
      </Box>
      <Box sx={{ height: '0px', border: '1px solid #C7D6DF', width: '40%', mx: 1 }} />
    </Box>
  );
}

export default PageBreakIndicator;
