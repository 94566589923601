import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Accordion, AccordionDetails, AccordionSummary, Box, InputAdornment, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { ReactComponent as MagnifyingGlassIcon } from '../../../../assets/intake-magnifying-glass-icon.svg';
import { CustomTextField } from '../../components';
import DebouncedInput from '../../edit/DebouncedInput';

// n.b. these are placeholders
const PRESET_GOALS = [
  'Identify friction points in navigation and barriers to conversion.',
  'Measure how users perceive my design vs. competitors.',
  'Evaluate clarity and resonance of messaging.',
  'Validate alignment of value proposition with user needs.',
  'Determine if key details are easily accessible.',
  'Evaluate how my experience compares to industry benchmarks.',
];

function GoalSelector({ initialOpen = false, initialSearch, onGoalSelected }) {
  const [search, setSearch] = useState(initialSearch || '');

  const filteredPresetGoals = useMemo(() => {
    const searchLowerCase = search.toLocaleLowerCase();
    return PRESET_GOALS.filter((goal) => {
      return goal.toLocaleLowerCase().includes(searchLowerCase);
    });
  }, [search]);

  const renderPresetGoal = (goal) => {
    return goal.split('\n').map((line, index) => {
      return (
        <Typography key={index} fontSize={14} fontWeight={400}>
          {line}
        </Typography>
      );
    });
  };
  return (
    <Box>
      <Accordion
        variant="outlined"
        defaultExpanded={initialOpen}
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&.MuiAccordion-root': {
            borderRadius: '0 0 12px 12px',
            border: '1.5px solid #C7D6DF',
            borderTop: 0,
          },
          '.MuiAccordionSummary-root': {
            backgroundColor: 'rgba(243, 248, 251, 0.23)',
            borderRadius: '0 0 12px 12px',

            '&.Mui-expanded': {
              backgroundColor: 'rgba(243, 248, 251, 0.23)',
              borderRadius: 0,
            },
            '&.Mui-expanded > .MuiAccordionSummary-content': {},
          },
          '.MuiAccordionDetails-root': {
            backgroundColor: 'rgba(243, 248, 251, 0.23)',
            borderRadius: '0 0 12px 12px',
            padding: 0,
          },
        }}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreRoundedIcon sx={{ color: '#212A37 !important', fontSize: 28, cursor: 'pointer' }} />
          }>
          Popular Goals
        </AccordionSummary>
        <AccordionDetails>
          <Box mx={2}>
            <DebouncedInput
              value={search}
              onChange={(value) => setSearch(value)}
              debounceMs={500}
              renderInput={({ value, onChange }) => (
                <CustomTextField
                  value={value}
                  placeholder={'Search'}
                  sx={{
                    '& .MuiInputBase-root': {
                      fontSize: 14,
                      borderRadius: 6,
                    },
                    '& .MuiInputBase-input': {
                      paddingLeft: 0,
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MagnifyingGlassIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box mb={4} />
          <Box>
            {filteredPresetGoals.map((goal, index) => {
              return (
                <Box
                  key={index}
                  px={3}
                  py={4}
                  sx={{
                    borderBottom: index === filteredPresetGoals.length - 1 ? 'none' : '1px solid #C7D6DF',
                    cursor: 'pointer',
                    '&:hover': {
                      background: '#F3F8FB',
                    },
                  }}
                  onClick={() => onGoalSelected(goal)}>
                  {renderPresetGoal(goal)}
                </Box>
              );
            })}
            {filteredPresetGoals?.length === 0 && (
              <Box mx={2} p={4} textAlign="center">
                <Typography>No matching goals.</Typography>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default GoalSelector;
