import { Box, CircularProgress } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useContext, useState } from 'react';
import { snackbar } from '../../../../notifications';
import { CustomTextField, Header } from '../../components';
import DebouncedInput from '../../edit/DebouncedInput';
import useSaveWevo from '../../hooks/useSaveWevo';
import { GOAL_MAX_LENGTH } from '../constants';
import { IntakeWevoContext } from '../context/IntakeWevoContext';
import { optimisticMergeUpdates } from '../helpers/wevo';
import GoalSelector from './GoalSelector';
import { serializeErrors } from '../custom-questions/helpers';
import { ValidationErrorNotice } from '../../components/Notice';
import { ValidationKeys as WevoValidationKeys } from '../helpers/validation/wevo';

function StudyGoalsIntakeSection() {
  const { wevo, setWevo, setIsWevoSyncing, wevoErrors } = useContext(IntakeWevoContext);
  const { mutateAsync: saveWevoAsync } = useSaveWevo();

  const handleUpdateWevo = async ({ wevo, updateFields }) => {
    const previousState = cloneDeep(wevo);

    const newState = optimisticMergeUpdates({ wevo, updateFields });

    try {
      setIsWevoSyncing(true);
      setWevo(newState);
      await saveWevoAsync({ id: wevo.id, ...updateFields });
    } catch (err) {
      setWevo(previousState);
      snackbar.error('Failed to save changes. Please wait a moment and try again or contact us.');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleGoalChanged = async ({ newGoal }) => {
    return handleUpdateWevo({ wevo, updateFields: { description: newGoal } });
  };

  if (!wevo) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: 'calc(100vh - 64px)', width: '100%', overflowY: 'auto' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StudyGoalsSetup
      goal={wevo?.description || ''}
      onGoalChanged={handleGoalChanged}
      wevoErrors={wevoErrors?.wevoErrors?.[String(wevo.id)] || {}}
    />
  );
}

function StudyGoalsSetup({ goal, onGoalChanged, wevoErrors }) {
  // this is an internal helper to force remounting the debounced text field
  // whenever a preset goal is selected to prevent infinite loops and also losing focus
  // any time the component is remounted
  const [debouncedGoalCounter, setDebouncedGoalCounter] = useState(0);

  const handlePresetGoalSelected = (presetGoal) => {
    if (onGoalChanged) {
      onGoalChanged({ newGoal: goal ? `${goal}\n${presetGoal}` : presetGoal });
      setDebouncedGoalCounter(debouncedGoalCounter + 1);
    }
  };

  return (
    <Box>
      <Header
        name="Goal"
        description="Please describe the key questions you hope to answer in this study. This helps us design the most effective research approach to meet your objectives."
        tooltipProps={{
          title:
            'Providing specific details of what you want to learn from your results helps us tailor the analysis focusing on feedback you care about the most.',
          arrow: true,
          placement: 'right',
        }}
        isRequired={true}
      />
      <Box mb={2} />
      <Box>
        <DebouncedInput
          key={debouncedGoalCounter}
          value={goal || ''}
          onChange={(value) => onGoalChanged({ newGoal: value })}
          debounceMs={500}
          renderInput={({ value, onChange }) => (
            <CustomTextField
              value={value}
              multiline
              minRows={4}
              placeholder="Identify friction points in navigation and barriers to conversion."
              sx={{
                '& .MuiInputBase-root': {
                  fontSize: 14,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
              inputProps={{ maxLength: GOAL_MAX_LENGTH }}
              helperText={`${value.length} / ${GOAL_MAX_LENGTH}`}
              FormHelperTextProps={{
                sx: {
                  color: '#4D5B57',
                  fontSize: 10,
                  textAlign: 'right',
                  marginTop: '-25px',
                },
              }}
              onChange={onChange}
            />
          )}
        />
        <Box sx={{ marginTop: '7px' }}>
          <GoalSelector onGoalSelected={handlePresetGoalSelected} />
        </Box>
      </Box>
      {wevoErrors?.[WevoValidationKeys.Goal]?.length > 0 && (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(wevoErrors[WevoValidationKeys.Goal])} />
        </Box>
      )}
    </Box>
  );
}

export default StudyGoalsIntakeSection;
