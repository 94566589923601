import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { ReactComponent as CustomQuestionsIcon } from '../../../../assets/custom-questions.svg';
import { CustomQuestionTypes, RANK_ORDER_CHART_COLORS } from '../../../../modules/report/constants';
import {
  isCustomSurveyType,
  isJourneyWideCustomQuestion,
  numberToOrdinal,
} from '../../../../modules/report/helpers';
import { WevoType } from '../../../../modules/wevos/constants';
import { Paths } from '../../../../routes';
import theme from '../../../../theme';
import QuoteBlock from '../../../reports/components/QuoteBlock';
import HeatMap from '../../components/Heatmap';
import CustomQuestionGraph from '../../custom-questions/CustomQuestionGraph';
import useCustomQuestion from '../../hooks/useCustomQuestion';

const CONTENT_CONTAINER_HEIGHT = 350;

//breakpoint where there could be more than one CustomQuestionCard component in a grid row on the dashboard
const BREAKPOINT = 1280;

export const GraphIdContext = React.createContext();

const styles = makeStyles((theme) => ({
  card: {
    borderRadius: '20px',
  },
  cardContent: {
    display: 'flex',
    height: '100%',
  },
  customQuestionIconGrid: {
    marginRight: theme.spacing(2),
  },
  customQuestionIcon: {
    color: theme.palette.primary.main,
    width: '25px',
    height: '25px',
    marginTop: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
    marginBottom: theme.spacing(-0.25),
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    textAlign: 'end',
    padding: '6px 0px',
  },
  rightArrow: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'bottom',
  },
  graph: {
    textAlign: ({ questionType }) => questionType === CustomQuestionTypes.CustomQualitative && 'left',
    marginRight: ({ questionType }) =>
      questionType === CustomQuestionTypes.CustomQualitative ? theme.spacing(2) : theme.spacing(3),
    marginLeft: theme.spacing(2),
  },
  tab: {
    color: theme.palette.primary.light,
    minWidth: '80px',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  tabsGrid: {
    marginLeft: theme.spacing(4),
  },
  underline: {
    textDecoration: 'none',
  },
  subText: {
    color: theme.palette.primary.dark,
  },
  icon: {
    backgroundColor: grey[100],
    borderRadius: '50%',
    padding: '3px',
  },
  iconButton: {
    backgroundColor: grey[100],
  },
  textGrid: {
    marginLeft: '48px',
    marginRight: '36px',
    marginTop: '16px',
  },
  text: {
    fontSize: '14px',
  },
  customQuestionContainer: {
    [theme.breakpoints.up('lg')]: {
      overflow: 'hidden',
      height: ({ isExpanded }) => (isExpanded ? '' : CONTENT_CONTAINER_HEIGHT),
    },
  },
  expandButton: {
    fontSize: '12px',
    textTransform: 'capitalize',
    lineHeight: 'inherit',
  },
  quotesContainer: {
    minHeight: 300,
  },
  footer: {
    minHeight: '48px',
  },
}));

const QuestionGraph = ({ wevoId, pageId, questionId, questionType, labels, isDashboard, id }) => {
  const { data: customQuestion } = useCustomQuestion({
    wevoId: wevoId,
    pageId: pageId,
    questionId: questionId,
  });

  const scores = useMemo(() => customQuestion?.scores ?? [], [customQuestion]);

  const rankOrderLabels = labels?.map((label, index) => `Rank - ${numberToOrdinal(index + 1)}`);

  const scoresToLabels = useMemo(() => {
    if (questionType === CustomQuestionTypes.RankOrder) {
      const datasets = labels.map((label, index) => {
        const dataset = {};
        const scoreElement = scores.find((score) => String(score.label) === String(labels.indexOf(label)));
        dataset.label = label;
        dataset.backgroundColor = RANK_ORDER_CHART_COLORS[index];
        dataset.data = Object.values(scoreElement?.scoreDetails ?? {});
        return dataset;
      });
      return datasets;
    } else {
      return labels.map((label) => {
        const scoreElement = scores.find((score) => String(score.label) === String(labels.indexOf(label)));
        const score = scoreElement?.score ?? 0;
        return score;
      });
    }
  }, [labels, questionType, scores]);

  const numsOfRespondents = useMemo(() => {
    let numRespondentsPerResponse;
    if (questionType === CustomQuestionTypes.RankOrder) {
      numRespondentsPerResponse = scoresToLabels?.map((labelData) =>
        labelData?.data?.reduce((acc, curr) => acc + curr, 0)
      );
    } else {
      numRespondentsPerResponse = labels.map((label) => {
        const scoreElement = scores.find((score) => String(score.label) === String(labels.indexOf(label)));
        const numRespondents = scoreElement?.numRespondents ?? 0;
        return numRespondents;
      });
    }
    return numRespondentsPerResponse;
  }, [labels, questionType, scores, scoresToLabels]);
  return (
    <CustomQuestionGraph
      questionType={questionType}
      labels={questionType === CustomQuestionTypes.RankOrder ? rankOrderLabels : labels}
      scores={scoresToLabels}
      numsOfRespondents={numsOfRespondents}
      isDashboard={isDashboard}
    />
  );
};

const QuestionQuotes = ({ wevo, page, questionId }) => {
  const classes = styles();
  const { data: customQuestion } = useCustomQuestion({
    wevoId: wevo.id,
    pageId: page.id,
    questionId: questionId,
  });

  const quotes = useMemo(() => customQuestion?.quotes ?? [], [customQuestion]);

  return (
    <div className={classes.quotesContainer}>
      {quotes?.slice(0, 3)?.map((quote, index) => (
        <QuoteBlock wevo={wevo} pageId={String(page.id)} quote={quote} key={index} />
      ))}
    </div>
  );
};

const CustomHeatmap = ({ wevo, page, step, questionId }) => {
  const heatmapImage = page?.images?.heatmap || step?.images?.heatmap;

  const { data: customQuestion } = useCustomQuestion({
    wevoId: wevo.id,
    pageId: page.id,
    questionId,
  });

  const quotes = useMemo(() => customQuestion?.quotes ?? [], [customQuestion]);

  if (!heatmapImage || !quotes) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }
  return <HeatMap image={heatmapImage} data={quotes} onSelectRegionChange={() => {}} customSize={false} />;
};

const CustomQuestionContent = ({ wevo, page, group, questionId, isDashboard, id }) => {
  const questionType = group?.question?.type;
  const labels = group?.question?.labels ?? [];
  const stepId = group?.scopes?.find((scope) => String(scope.questionId) === String(questionId)).stepId;
  const step = page?.steps?.find((step) => String(step.id) === String(stepId));

  const renderContent = () => {
    if (questionType === CustomQuestionTypes.CustomQualitative) {
      return (
        <Box p={1} mt={2}>
          <QuestionQuotes wevo={wevo} page={page} questionId={questionId} />
        </Box>
      );
    } else if (questionType === CustomQuestionTypes.Heatmap) {
      return (
        <Box mt={2}>
          <CustomHeatmap wevo={wevo} page={page} step={step} questionId={questionId} />
        </Box>
      );
    } else {
      return (
        <Box p={1}>
          <QuestionGraph
            wevoId={wevo.id}
            pageId={page.id}
            questionId={questionId}
            questionType={questionType}
            labels={labels}
            isDashboard={isDashboard}
          />
        </Box>
      );
    }
  };
  return renderContent();
};

// tabs for wevo journey
const CustomQuestionWithTabs = ({ wevo, page, group, isDashboard }) => {
  const questionType = group?.question?.type;
  const classes = styles({ group, questionType });
  const [tab, setTab] = useState(0);
  const scopes = useMemo(() => group?.scopes ?? [], [group]);

  const pageScopes = useMemo(
    () => scopes.filter((scope) => String(scope.wevoPageId) === String(page.id)),
    [page, scopes]
  );

  const questionsGraphs = pageScopes?.map(({ questionId }, index) => (
    <GraphIdContext.Provider value={`${group.groupId}-${wevo.id}-${page.id}-custom-question-${index}-journey`}>
      <CustomQuestionContent
        wevo={wevo}
        page={page}
        group={group}
        questionId={questionId}
        isDashboard={isDashboard}
      />
    </GraphIdContext.Provider>
  ));

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    setTab(0);
  }, [group]);

  const isJourneyLevel = isJourneyWideCustomQuestion(wevo, scopes);

  const scopeTabs = useMemo(() => {
    if (isJourneyLevel) {
      return [
        <Tab
          key={`wevo-${wevo.id}-page-${page.id}-journey-wide`}
          label={'Journey Level'}
          className={classes.tab}
          aria-labelledby={`${group.groupId}-${wevo.id}-${page.id}-custom-question-0-journey`}
        />,
      ];
    }

    return pageScopes.map((scope, index) => {
      const assetNumber = page?.steps?.find((step) => step.id === scope.stepId)?.label;
      return (
        <Tab
          key={index}
          label={`Page ${assetNumber}`}
          className={classes.tab}
          aria-labelledby={`${group.groupId}-${wevo.id}-${page.id}-custom-question-${index}-journey`}
        />
      );
    });
  }, [classes, isJourneyLevel, page, pageScopes, wevo, group.groupId]);

  return (
    <>
      <Tabs value={tab} indicatorColor="primary" onChange={handleChange} className={classes.tabsGrid}>
        {scopeTabs}
      </Tabs>
      <Grid item xs={12}>
        {questionsGraphs[tab]}
      </Grid>
    </>
  );
};

const CustomQuestionCard = (props) => {
  const { wevo, page, group, index, isDashboard, isLimitedReport } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState();
  const contentRef = useRef();

  const isCustomSurvey = isCustomSurveyType(wevo);

  const questionType = group?.question?.type;
  const classes = styles({ questionType, isExpanded });
  const scopes = group?.scopes ?? [];
  const { questionId } = scopes?.find((scope) => scope?.wevoPageId === String(page.id));

  const customQuestionPath = generatePath(Paths.reports.questionDetails, {
    wevoId: wevo?.id,
    groupNum: index + 1,
  });

  const handleExpandButton = useCallback(() => {
    if (contentRef?.current?.offsetHeight) {
      // the expand button is only necessary when custom question cards share a grid row,
      // which in this is case is when the window width is >= breakpoint
      if (window.innerWidth >= BREAKPOINT) {
        // show expand button when height of content is greater than its parent container + ~16px padding
        setShowExpandButton(contentRef?.current?.offsetHeight > CONTENT_CONTAINER_HEIGHT + 16);
      } else {
        setShowExpandButton(false);
      }
    }
  }, [contentRef]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    handleExpandButton();
  }, [handleExpandButton]);

  useEffect(() => {
    window.addEventListener('resize', handleExpandButton);

    return () => {
      window.removeEventListener('resize', handleExpandButton);
    };
  }, [handleExpandButton]);

  return (
    <Card className={classes.card} elevation={4}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Grid item container className={classes.noWrap} xs={12}>
              <Grid item className={classes.customQuestionIconGrid}>
                {isLimitedReport ? (
                  <Box className={classes.icon}>
                    <CustomQuestionsIcon
                      fill={theme.palette.primary.main}
                      className={classes.customQuestionIcon}
                      aria-labelledby={`${group.groupId}-custom-question-heading custom-question-sub-heading ${group.groupId}-custom-question ${group.groupId}-${wevo.id}-${page.id}-custom-question-0`}
                    />
                  </Box>
                ) : (
                  <IconButton
                    size="small"
                    className={classes.iconButton}
                    aria-labelledby={`${group.groupId}-custom-question-heading custom-question-sub-heading ${group.groupId}-custom-question ${group.groupId}-${wevo.id}-${page.id}-custom-question-0`}>
                    <Link to={customQuestionPath}>
                      <CustomQuestionsIcon
                        fill={theme.palette.primary.main}
                        className={classes.customQuestionIcon}
                      />
                    </Link>
                  </IconButton>
                )}
              </Grid>
              <Grid item>
                <Typography variant="h5" id={`${group.groupId}-custom-question-heading`}>
                  {group?.name || `Custom Question ${index + 1}`}
                </Typography>
                <Typography variant="caption" className={classes.subText} id="custom-question-sub-heading">
                  Customized questions made to visitors about the experience
                </Typography>
              </Grid>
            </Grid>
            <Grid container item className={classes.customQuestionContainer}>
              <Grid container item ref={contentRef}>
                <Grid item xs={12} className={classes.textGrid}>
                  <Typography
                    variant="h5"
                    display="inline"
                    className={classes.text}
                    id={`${group.groupId}-custom-question`}>
                    {group?.question?.questionText}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.graph}
                  aria-labelledby={
                    wevo?.type === WevoType.Journey && !isCustomSurvey
                      ? `${group.groupId}-custom-question-heading custom-question-sub-heading
                  ${group.groupId}-custom-question`
                      : ''
                  }>
                  {wevo?.type === WevoType.Journey && !isCustomSurvey ? (
                    <CustomQuestionWithTabs wevo={wevo} page={page} group={group} isDashboard={isDashboard} />
                  ) : (
                    <GraphIdContext.Provider
                      value={`${group.groupId}-${wevo.id}-${page.id}-custom-question-0`}>
                      <CustomQuestionContent
                        wevo={wevo}
                        page={page}
                        group={group}
                        questionId={questionId}
                        isDashboard={isDashboard}
                      />
                    </GraphIdContext.Provider>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isLimitedReport ? (
            <Grid container item justifyContent="space-between" alignItems="center" className={classes.footer}>
              {showExpandButton && (
                <Grid item xs={12}>
                  <Button
                    className={classes.expandButton}
                    endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    onClick={toggleExpanded}
                    cursor={'pointer'}>
                    {isExpanded ? 'Show Less' : 'Show More'}
                  </Button>
                </Grid>
              )}
            </Grid>
          ) : showExpandButton ? (
            <Grid container item justifyContent="space-between" alignItems="center" className={classes.footer}>
              <Grid item>
                <Button
                  className={classes.expandButton}
                  endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  onClick={toggleExpanded}
                  cursor={'pointer'}>
                  {isExpanded ? 'Show Less' : 'Show More'}
                </Button>
              </Grid>
              <Grid item>
                <Link to={customQuestionPath} className={classes.underline}>
                  <Typography variant="body2" className={classes.link}>
                    See Custom Question
                    <ChevronRightIcon fontSize="small" className={classes.rightArrow} />
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          ) : (
            <Grid container item justifyContent="flex-end" className={classes.footer}>
              <Grid item>
                <Link to={customQuestionPath} className={classes.underline}>
                  <Typography variant="body2" className={classes.link}>
                    See Custom Question
                    <ChevronRightIcon fontSize="small" className={classes.rightArrow} />
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CustomQuestionCard;
