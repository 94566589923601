import { Box, CircularProgress, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useContext } from 'react';
import { snackbar } from '../../../../notifications';
import { CustomTextField, Header } from '../../components';
import { ValidationErrorNotice } from '../../components/Notice';
import DebouncedInput from '../../edit/DebouncedInput';
import useSaveWevo from '../../hooks/useSaveWevo';
import { IntakeWevoContext } from '../context/IntakeWevoContext';
import { serializeErrors } from '../helpers/validation/shared';
import { ValidationKeys } from '../helpers/validation/wevo';
import { optimisticMergeUpdates } from '../helpers/wevo';

function ExpectationsIntakeSection() {
  const { wevo, setWevo, setIsWevoSyncing, wevoErrors } = useContext(IntakeWevoContext);

  const { mutateAsync: saveWevoAsync } = useSaveWevo();

  const handleUpdateWevo = async ({ wevo, updateFields }) => {
    const previousState = cloneDeep(wevo);

    const newState = optimisticMergeUpdates({ wevo, updateFields });

    try {
      setIsWevoSyncing(true);
      setWevo(newState);
      await saveWevoAsync({ id: wevo.id, ...updateFields });
    } catch (err) {
      setWevo(previousState);
      snackbar.error('Failed to save changes. Please wait a moment and try again or contact us.');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleVisitorObjectiveChanged = async ({ newVisitorObjective }) => {
    return handleUpdateWevo({ wevo, updateFields: { visitorObjective: newVisitorObjective } });
  };

  if (!wevo) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: 'calc(100vh - 64px)', width: '100%', overflowY: 'auto' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ExpectationsSetup
      wevo={wevo}
      onVisitorObjectiveChanged={handleVisitorObjectiveChanged}
      errors={wevoErrors?.wevoErrors?.[String(wevo.id)] || {}}
    />
  );
}

function ExpectationsSetup({ wevo, onVisitorObjectiveChanged, errors }) {
  return (
    <Box>
      <Header
        name="Expectations"
        description="Please describe what your audience would be doing when engaging with this experience."
        hasPreview={true}
        isRequired={true}
      />
      <Box mb={3} />
      <Typography fontSize={14} fontWeight={400} color="#878787">
        Example: When &nbsp;
        <Box component="span" fontWeight={700}>
          shopping for shoes
        </Box>
        , what are your greatest hopes/concerns?
      </Typography>
      <Box mb={2} />
      <Box>
        <DebouncedInput
          value={wevo?.visitorObjective || ''}
          onChange={(value) => onVisitorObjectiveChanged({ newVisitorObjective: value })}
          debounceMs={500}
          renderInput={({ value, onChange }) => (
            <CustomTextField
              value={value}
              placeholder={'Shopping for shoes'}
              sx={{
                '& .MuiInputBase-root': {
                  fontSize: 14,
                },
              }}
              onChange={onChange}
            />
          )}
        />
      </Box>
      {errors?.[ValidationKeys.VisitorObjective]?.length > 0 && (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(errors[ValidationKeys.VisitorObjective])} />
        </Box>
      )}
    </Box>
  );
}

export default ExpectationsIntakeSection;
