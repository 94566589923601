import {
  CustomScreenerTypes,
  MultiSelectOptions,
  OutcomeOptions,
  ScreenerSelectOptions,
} from '../../../../modules/intake/constants';
import { AudienceCategory, DEFAULT_AUDIENCE_ATTRIBUTES } from '../../../../modules/wevos/constants';

export const CustomizationOptions = {
  None: 'none',
  Custom: 'custom',
  Quota: 'quota',
};

export const SegmentDisplayType = {
  Checkbox: 'checkbox',
  Slider: 'slider',
};

export const DefaultAttributesByAudienceCategoryId = {
  [AudienceCategory.B2C.id]: [
    { ...DEFAULT_AUDIENCE_ATTRIBUTES.gender },
    { ...DEFAULT_AUDIENCE_ATTRIBUTES.age },
    { ...DEFAULT_AUDIENCE_ATTRIBUTES.income },
    { ...DEFAULT_AUDIENCE_ATTRIBUTES.education },
  ],
  [AudienceCategory.B2B.id]: [
    { ...DEFAULT_AUDIENCE_ATTRIBUTES.revenue },
    { ...DEFAULT_AUDIENCE_ATTRIBUTES.jobRole },
    { ...DEFAULT_AUDIENCE_ATTRIBUTES.companySize },
  ],
};

export const AttributeIdToDisplayName = {
  [DEFAULT_AUDIENCE_ATTRIBUTES.gender.id]: 'gender',
  [DEFAULT_AUDIENCE_ATTRIBUTES.age.id]: 'age',
  [DEFAULT_AUDIENCE_ATTRIBUTES.income.id]: 'income',
  [DEFAULT_AUDIENCE_ATTRIBUTES.education.id]: 'education',
  [DEFAULT_AUDIENCE_ATTRIBUTES.revenue.id]: 'revenue',
  [DEFAULT_AUDIENCE_ATTRIBUTES.jobRole.id]: 'job role',
  [DEFAULT_AUDIENCE_ATTRIBUTES.companySize.id]: 'company size',
};

export const AttributeCustomizationComponentType = {
  //B2C
  [DEFAULT_AUDIENCE_ATTRIBUTES.gender.id]: SegmentDisplayType.Checkbox,
  [DEFAULT_AUDIENCE_ATTRIBUTES.age.id]: SegmentDisplayType.Slider,
  [DEFAULT_AUDIENCE_ATTRIBUTES.income.id]: SegmentDisplayType.Slider,
  [DEFAULT_AUDIENCE_ATTRIBUTES.education.id]: SegmentDisplayType.Checkbox,
  //B2B
  [DEFAULT_AUDIENCE_ATTRIBUTES.revenue.id]: SegmentDisplayType.Slider,
  [DEFAULT_AUDIENCE_ATTRIBUTES.jobRole.id]: SegmentDisplayType.Checkbox,
  [DEFAULT_AUDIENCE_ATTRIBUTES.companySize.id]: SegmentDisplayType.Checkbox,
};

export const SliderMarks = {
  [DEFAULT_AUDIENCE_ATTRIBUTES.age.id]: [
    { value: 1, label: '18', intValue: 18 },
    { value: 2, label: '25', intValue: 25 },
    { value: 3, label: '35', intValue: 35 },
    { value: 4, label: '45', intValue: 45 },
    { value: 5, label: '55', intValue: 55 },
    { value: 6, label: '55+', intValue: Infinity },
  ],
  [DEFAULT_AUDIENCE_ATTRIBUTES.income.id]: [
    { value: 1, label: '0', intValue: 0 },
    { value: 2, label: '50k', intValue: 50 },
    { value: 3, label: '100k', intValue: 100 },
    { value: 4, label: '150k', intValue: 150 },
    { value: 5, label: '150k+', intValue: Infinity },
  ],
  [DEFAULT_AUDIENCE_ATTRIBUTES.revenue.id]: [
    { value: 1, label: '0', intValue: 0 },
    { value: 2, label: '50k', intValue: 50 },
    { value: 3, label: '250k', intValue: 250 },
    { value: 4, label: '500k', intValue: 500 },
    { value: 5, label: '750k', intValue: 750 },
    { value: 6, label: '1M', intValue: 1000 },
    { value: 7, label: '2M', intValue: 2000 },
    { value: 8, label: '5M', intValue: 5000 },
  ],
};

export const CustomScreenerQuestionType = {
  [CustomScreenerTypes.YesNoTrueFalse]: 'Yes/No',
  [CustomScreenerTypes.FiveLikertScale]: 'Likert 5',
  [CustomScreenerTypes.SevenLikertScale]: 'Likert 7',
  [CustomScreenerTypes.MultipleChoice]: 'Multiple Choice',
  [CustomScreenerTypes.MultiSelect]: 'Multiple Selection',
};

export const CustomScreenerTypeOptions = [
  {
    label: 'Five likert scale',
    description: 'Five-point scale measuring agreement or satisfaction',
    value: CustomScreenerTypes.FiveLikertScale,
    choiceLimits: {
      default: 5,
      min: 5,
      max: 5,
    },
  },
  {
    label: 'Seven likert scale',
    description: 'Seven-point scale for more nuanced responses',
    value: CustomScreenerTypes.SevenLikertScale,
    choiceLimits: {
      default: 7,
      min: 7,
      max: 7,
    },
  },
  {
    label: 'Multiple choice',
    description: 'Single selection from predefined options',
    value: CustomScreenerTypes.MultipleChoice,
    choiceLimits: {
      default: 2,
      min: 2,
      max: 10,
    },
  },
  {
    label: 'Multiple selection',
    description: 'Multiple options from a list',
    value: CustomScreenerTypes.MultiSelect,
    // note: multiselect limits are dynamic based on range types, so this is more like the "default" choice limits
    // the exact values are based on user-defined input and range type, so they can't be hardcoded unlike the others
    choiceLimits: {
      default: 2,
      min: 2,
      max: 15,
    },
  },
  {
    label: 'Yes/no or true/false',
    description: 'Binary choice questions',
    value: CustomScreenerTypes.YesNoTrueFalse,
    choiceLimits: {
      default: 2,
      min: 2,
      max: 2,
    },
  },
];

export const CustomScreenerTypeOptionsByType = CustomScreenerTypeOptions.reduce((acc, cur) => {
  acc[cur.value] = cur;
  return acc;
}, {});

export const LikertScaleOptions = [
  {
    label: 'Agree - Disagree',
    value: ScreenerSelectOptions.AgreeDisagree,
  },
  {
    label: 'Like - Dislike',
    value: ScreenerSelectOptions.LikeDislike,
  },
  {
    label: 'Satisfied - Dissatisfied',
    value: ScreenerSelectOptions.SatisfiedDissatisfied,
  },
  {
    label: 'Likely - Unlikely',
    value: ScreenerSelectOptions.LikelyUnlikely,
  },
  {
    label: 'Easy - Difficult',
    value: ScreenerSelectOptions.EasyDifficult,
  },
  {
    label: 'Custom',
    value: ScreenerSelectOptions.Custom,
  },
];

export const PresetLikertScales = {
  [ScreenerSelectOptions.AgreeDisagree]: {
    likert5: {
      values: ['Strongly Disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly Agree'],
    },
    likert7: {
      values: [
        'Strongly Disagree',
        'Disagree',
        'Somewhat Disagree',
        'Neutral',
        'Somewhat Agree',
        'Agree',
        'Strongly Agree',
      ],
    },
  },
  [ScreenerSelectOptions.LikeDislike]: {
    likert5: { values: ['Strongly Dislike', 'Dislike', 'Neutral', 'Like', 'Strongly Like'] },
    likert7: {
      values: [
        'Strongly Dislike',
        'Dislike',
        'Somewhat Dislike',
        'Neutral',
        'Somewhat Like',
        'Like',
        'Strongly Like',
      ],
    },
  },
  [ScreenerSelectOptions.SatisfiedDissatisfied]: {
    likert5: {
      values: ['Strongly Dissatisfied', 'Dissatisfied', 'Neutral', 'Satisfied', 'Strongly Satisfied'],
    },
    likert7: {
      values: [
        'Strongly Dissatisfied',
        'Dissatisfied',
        'Somewhat Dissatisfied',
        'Neutral',
        'Somewhat Satisfied',
        'Satisfied',
        'Strongly Satisfied',
      ],
    },
  },
  [ScreenerSelectOptions.LikelyUnlikely]: {
    likert5: { values: ['Very Unlikely', 'Unlikely', 'Neutral', 'Likely', 'Very Likely'] },
    likert7: {
      values: [
        'Very Unlikely',
        'Unlikely',
        'Somewhat Unlikely',
        'Neutral',
        'Somewhat Likely',
        'Likely',
        'Very Likely',
      ],
    },
  },
  [ScreenerSelectOptions.EasyDifficult]: {
    likert5: { values: ['Very Difficult', 'Difficult', 'Neutral', 'Easy', 'Very Easy'] },
    likert7: {
      values: [
        'Very Difficult',
        'Difficult',
        'Somewhat Difficult',
        'Neutral',
        'Somewhat Easy',
        'Easy',
        'Very Easy',
      ],
    },
  },
};

export const BinaryScaleOptions = [
  {
    label: 'Yes / No',
    value: ScreenerSelectOptions.YesNo,
  },
  {
    label: 'True / False',
    value: ScreenerSelectOptions.TrueFalse,
  },
  {
    label: 'Custom',
    value: ScreenerSelectOptions.Custom,
  },
];

export const PresetBinaryScales = {
  [ScreenerSelectOptions.YesNo]: { values: ['Yes', 'No'] },
  [ScreenerSelectOptions.TrueFalse]: { values: ['True', 'False'] },
};

export const MULTISELECT_MIN_REQUIRED = 1;

export const MultipleSelectionRequirementsOptions = [
  { label: 'All that apply', value: MultiSelectOptions.AllThatApply },
  { label: 'At least', value: MultiSelectOptions.AtLeast },
  { label: 'Up to', value: MultiSelectOptions.UpTo },
  { label: 'Exactly', value: MultiSelectOptions.Exactly },
];

export const DefaultOutcomeOptions = [
  { label: 'Is accepted', value: OutcomeOptions.Accept },
  { label: 'Is rejected', value: OutcomeOptions.Reject },
];

export const MultipleSelectionOutcomeOptions = [
  { label: 'Must select', value: OutcomeOptions.Accept },
  { label: 'May select', value: OutcomeOptions.MaySelect },
  { label: 'Must not select', value: OutcomeOptions.Reject },
];

export const ValidationKeys = {
  Global: 'global',
  LimitExceeded: 'limitExceeded',
  QuestionText: 'questionText',
  Labels: 'labels',
  LabelsType: 'labelsType',
  QuestionName: 'name',
};

export const CustomScreenerQuestionLimit = 5;
