import { Box, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import IntakeTooltip from './IntakeTooltip';

const StyledListItemButton = ({
  primaryListItemText,
  secondaryListItemText,
  secondaryTypographyProps,
  listItemIcon,
  endText,
  isDisabled,
  isSelected,
  tooltipProps,
  onClick,
  sx = {},
}) => {
  return (
    <IntakeTooltip disableHoverListener={!isDisabled} {...tooltipProps}>
      <div>
        <ListItemButton
          selected={isSelected}
          disabled={isDisabled}
          onClick={onClick}
          sx={{
            width: '100%',
            paddingX: 2,
            paddingY: 2.5,
            marginTop: 1.5,
            border: isSelected ? '1.5px solid #3B6CAB' : '1.5px solid #C7D6DF',
            borderRadius: '10px',
            backgroundColor: '#FFFFFF',
            ...sx,
            '&:hover': {
              backgroundColor: '#F3F8FB',
            },
            '&:focus-visible': {
              backgroundColor: '#F3F8FB',
            },
            '&.Mui-selected': {
              backgroundColor: '#F3F8FB',
              '&:hover': {
                backgroundColor: '#F3F8FB',
              },
              '&:focus-visible': {
                backgroundColor: '#F3F8FB',
              },
            },
          }}>
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <ListItemText
              primary={primaryListItemText}
              primaryTypographyProps={{
                fontSize: 13,
                fontWeight: isSelected ? 500 : 400,
              }}
              secondary={secondaryListItemText}
              secondaryTypographyProps={{
                color: '#878787',
                fontSize: 12,
                fontWeight: 400,
                ...secondaryTypographyProps,
              }}
              sx={{ margin: 0 }}
            />
            {endText && (
              <Typography sx={{ color: '#878787', fontSize: '12px', fontStyle: 'italic' }}>
                {endText}
              </Typography>
            )}
          </Box>
          {listItemIcon && <ListItemIcon>{listItemIcon}</ListItemIcon>}
        </ListItemButton>
      </div>
    </IntakeTooltip>
  );
};

export default StyledListItemButton;
