import { Box, Typography } from '@mui/material';
import { WevoTestType } from '../../../../../../modules/wevos/constants';
import { SelectableTile } from '../../../../components';
import { CustomQuestionScopeType } from '../../constants';

function ScopeTypeSelector({
  wevo,
  customQuestion,
  value = CustomQuestionScopeType.Page,
  isPageScopeDisabled = false,
  isStepScopeDisabled = false,
  onChange,
}) {
  const isCompare = wevo?.testType === WevoTestType.Compare;

  const pageScopeLabel = isCompare ? 'Experiences' : 'All assets';

  const handlePageScopeSelected = () => {
    if (value !== CustomQuestionScopeType.Page) {
      onChange({ customQuestion, newScopeType: CustomQuestionScopeType.Page });
    }
  };

  const handleStepScopeSelected = () => {
    if (value !== CustomQuestionScopeType.Step) {
      onChange({ customQuestion, newScopeType: CustomQuestionScopeType.Step });
    }
  };

  return (
    <Box>
      <Typography mb={1}>Select the asset(s) this custom question applies to*</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
        <SelectableTile
          label={pageScopeLabel}
          isSelected={value === CustomQuestionScopeType.Page}
          onSelect={handlePageScopeSelected}
          width={{ sx: '100%', sm: '100%', md: '49%' }}
          fontSize="16px"
          disabled={isPageScopeDisabled}
        />
        <SelectableTile
          label={'Specific assets'}
          isSelected={value === CustomQuestionScopeType.Step}
          onSelect={handleStepScopeSelected}
          width={{ sx: '100%', sm: '100%', md: '49%' }}
          fontSize="16px"
          disabled={isStepScopeDisabled}
        />
      </Box>
    </Box>
  );
}

export default ScopeTypeSelector;
