import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { CustomQuestionTypes, RANK_ORDER_CHART_COLORS } from '../../../modules/report/constants';
import {
  isCustomSurveyType,
  isJourneyWideCustomQuestion,
  numberToOrdinal,
} from '../../../modules/report/helpers';
import { WevoType } from '../../../modules/wevos/constants';
import { filterQuotes } from '../../../modules/wevos/quotes';
import {
  buildAudienceFilterProperties,
  buildWordFilterProperties,
  TrackEvent,
  useTrackPageLoad,
} from '../../analytics';
import { shouldTrackAudienceFilterChange } from '../../analytics/eventPropertyHelpers';
import HeatmapWithQuotes from '../components/HeatmapWithQuotes';
import { RatingChip } from '../components/QuoteChip';
import QuotesTable from '../components/QuotesTable';
import { RatingWordFilter } from '../components/QuoteWordFilter';
import { RightDrawerButton } from '../components/RightDrawer';
import useCustomQuestion from '../hooks/useCustomQuestion';
import CustomQuestionGraph from './CustomQuestionGraph';
import ScopeCard from './ScopeCard';

function AssetScopeSelector({
  isPickableTitle,
  selectedTitle,
  assets,
  scopes,
  isMatchingScope,
  renderAssetComponent,
  orientation = 'horizontal',
}) {
  const isPickable = scopes.length > 1;

  const assetsInScopes = assets?.filter((asset) =>
    Boolean(scopes.find((scope) => isMatchingScope(asset, scope)))
  );

  const containerProps =
    orientation === 'vertical'
      ? { display: 'block', ml: 2, mt: 2 }
      : {
          spacing: 2,
          justifyContent: 'flex-start',
          alignItems: 'center',
          mt: 1,
        };

  return (
    <>
      <Typography variant="body2" gutterBottom>
        {isPickable ? isPickableTitle : selectedTitle}
      </Typography>
      <Grid container sx={{ ...containerProps }}>
        {scopes?.map((scope) => {
          const asset = assetsInScopes.find((asset) => isMatchingScope(asset, scope));

          if (!asset) {
            return <></>;
          }

          return (
            <Grid item id={scope.questionId} key={scope.questionId}>
              {renderAssetComponent({ asset, scope, isPickable })}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

AssetScopeSelector.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  scopes: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMatchingScope: PropTypes.func.isRequired,
  renderAssetComponent: PropTypes.func.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

function JourneyLevelPageScopeSelector({ wevo, scopes, selectedScope, handleScopeClick }) {
  const assets = wevo?.pages || [];
  const isMatchingScope = (asset, scope) => String(scope.wevoPageId) === String(asset.id);

  return (
    <AssetScopeSelector
      isPickableTitle={'Select an experience to view its results:'}
      selectedTitle={'Selected experience:'}
      assets={assets}
      scopes={scopes}
      selectedScope={selectedScope}
      isMatchingScope={isMatchingScope}
      orientation="vertical"
      renderAssetComponent={({ asset, scope, isPickable }) => {
        const isSelected = String(selectedScope?.questionId) === String(scope.questionId);
        let checkbox;

        if (isSelected) {
          checkbox = <CheckCircleOutlineIcon color="primary" />;
        } else {
          checkbox = <RadioButtonUncheckedIcon color="primary" />;
        }

        return (
          <Grid
            container
            spacing={2}
            onClick={isPickable ? (ev) => handleScopeClick(ev, scope) : null}
            disabled={!isPickable}
            sx={{ cursor: 'pointer', mb: 0.5 }}>
            {isPickable && <Grid item>{checkbox}</Grid>}
            <Grid item>
              <Typography variant="h6">{asset.name}</Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

JourneyLevelPageScopeSelector.propTypes = {
  wevo: PropTypes.object.isRequired,
  scopes: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedScope: PropTypes.object.isRequired,
  handleScopeClick: PropTypes.func,
};

function ClassicPageScopeSelector({ wevo, scopes, selectedScope, handleScopeClick }) {
  const assets = wevo?.pages || [];
  const isMatchingScope = (asset, scope) => String(scope.wevoPageId) === String(asset.id);

  return (
    <AssetScopeSelector
      isPickableTitle={'Select a page to view its results:'}
      selectedTitle={'Selected insights page:'}
      assets={assets}
      scopes={scopes}
      selectedScope={selectedScope}
      isMatchingScope={isMatchingScope}
      renderAssetComponent={({ asset, scope, isPickable }) => (
        <ScopeCard
          name={asset?.name}
          images={asset?.images}
          isSelected={String(selectedScope?.questionId) === String(scope.questionId)}
          isPickable={isPickable}
          onChange={(ev) => handleScopeClick(ev, scope)}
        />
      )}
    />
  );
}

ClassicPageScopeSelector.propTypes = {
  wevo: PropTypes.object.isRequired,
  scopes: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedScope: PropTypes.object.isRequired,
  handleScopeClick: PropTypes.func,
};

function JourneyStepScopeSelector({ wevo, scopes, selectedScope, handleScopeClick }) {
  const pages = wevo?.pages || [];
  const isCompare = pages?.length > 1;

  const isMatchingScope = (asset, scope) =>
    String(scope.wevoPageId) === String(asset.wevoPageId) && String(scope.stepId) === String(asset.id);

  const assets = (wevo?.pages ?? []).flatMap((page) => page?.steps ?? []);
  const pagesById = pages.reduce((acc, cur) => {
    acc[String(cur.id)] = cur;
    return acc;
  }, {});

  return (
    <AssetScopeSelector
      isPickableTitle={'Select a page to view its results:'}
      selectedTitle={'Selected insights page:'}
      assets={assets}
      scopes={scopes}
      selectedScope={selectedScope}
      isMatchingScope={isMatchingScope}
      renderAssetComponent={({ asset, scope, isPickable }) => {
        let assetName = asset?.name;

        if (isCompare) {
          const page = pagesById[String(scope.wevoPageId)];

          assetName = (
            <>
              {page.name}
              <br />
              {assetName}
            </>
          );
        }

        return (
          <ScopeCard
            name={assetName}
            images={asset?.images}
            isSelected={String(selectedScope?.questionId) === String(scope.questionId)}
            isPickable={isPickable}
            onChange={(ev) => handleScopeClick(ev, scope)}
          />
        );
      }}
    />
  );
}

const CustomQuestions = ({
  wevo,
  group,
  questionId,
  rightDrawerOpen,
  setRightDrawerOpen,
  isPptComponent,
  width,
  height,
  componentId,
  fixedPageId,
  fixedStepId,
}) => {
  const scopes = useMemo(() => group?.scopes ?? [], [group]);
  const question = group?.question ?? '';
  const name = group?.name ?? '';
  const questionText = question?.questionText ?? '';
  const followUpQuestionText = question?.followUpQuestionText ?? '';
  const questionType = question?.type;

  const [page, setPage] = useState(() => {
    // Assumption: We assume that a custom question group's scopes can only be associated with 1 wevo page.
    const wevoPageId = group?.scopes?.[0]?.wevoPageId;
    return Boolean(fixedPageId)
      ? wevo?.pages?.find((page) => String(page.id) === String(fixedPageId))
      : wevo?.pages?.find((page) => String(page.id) === String(wevoPageId));
  });
  const [step, setStep] = useState(() => {
    const wevoStepId = group?.scopes?.[0]?.stepId;
    return Boolean(fixedStepId)
      ? wevo?.pages?.[0]?.steps?.find((step) => String(step.id) === String(fixedStepId))
      : wevo?.pages?.[0]?.steps?.find((step) => String(step.id) === String(wevoStepId));
  });
  const [quotes, setQuotes] = useState([]);

  const labels = useMemo(() => question?.labels ?? [], [question]);

  const { track } = useAnalytics();

  const [selectedScope, setSelectedScope] = useState(
    questionId ? _.find(scopes, (s) => s.questionId === questionId) ?? scopes[0] : scopes[0]
  ); // First scope is selected by default unless the component is initialized with a valid questionId

  const { data: customQuestion, isLoading } = useCustomQuestion(
    {
      wevoId: wevo.id,
      pageId: page.id,
      questionId: selectedScope?.questionId,
    },
    {
      onSuccess: (data) => {
        setQuotes(data?.quotes);
      },
    }
  );

  const scores = useMemo(() => customQuestion?.scores ?? [], [customQuestion]);

  const [filters, setFilters] = useState({ ratings: [] });
  const filteredQuotes = useMemo(() => filterQuotes(quotes, filters), [quotes, filters]);
  const selectedRatings = useMemo(() => filters.ratings, [filters]);

  useTrackPageLoad({
    name: TrackEvent.VIEWED_REPORT_JOURNEY_CUSTOM_QUESTIONS,
    properties: { wevoId: wevo?.analyticsId, pageId: page?.id },
  });

  const isJourneyLevel = isJourneyWideCustomQuestion(wevo, scopes);

  const onQuoteUpdate = ({ id, updates }) => {
    const quoteIndex = quotes.findIndex((quote) => quote.id === id);
    const updatedQuote = { ...quotes[quoteIndex], ...updates };
    const updatedQuotes = [...quotes];
    updatedQuotes[quoteIndex] = updatedQuote;
    setQuotes(updatedQuotes);
  };

  // Called by QuoteSearch if search query has changed.
  const onSearchChange = (searchQuery) => {
    if (searchQuery) {
      track(TrackEvent.SEARCHED_QUOTE, {
        searchQuery,
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      });
    }

    setFilters((filters) => ({
      ...filters,
      search: searchQuery,
    }));
  };

  // Called by QuoteAudienceFilter if audience filters are changed.
  const onAudienceFilterChange = (audienceFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      audienceFilters,
      filters.audience,
      segmentToAudienceMap,
      {
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, audienceFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_AUDIENCE, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      audience: audienceFilters,
    }));
  };

  // Called by CustomScreenerFilter if custom screener filters are changed.
  const onCustomScreenerFilterChange = (screenerFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      screenerFilters,
      filters.screeners,
      segmentToAudienceMap,
      {
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, screenerFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_CUSTOM_SCREENER, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      screeners: screenerFilters,
    }));
  };

  /**
   * Called by RatingsFilter if selected ratings list is changed.
   * @param {string[]} selectedRatingsNames
   */
  const onRatingChange = (selectedRatings) => {
    track(
      TrackEvent.FILTERED_QUOTES_BY_RATING,
      buildWordFilterProperties(selectedRatings, filters.ratings, {
        location: 'dropdown',
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      })
    );

    setFilters((filters) => ({
      ...filters,
      ratings: selectedRatings,
    }));
  };

  /**
   * Remove a given rating from the filters
   * @param {string} deselectedWord rating
   */
  const deselectWord = (deselectedWord) => {
    track(TrackEvent.DESELECTED_QUOTE_FILTER, {
      filters: [deselectedWord],
      deselected: true,
      location: 'chip',
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      testType: wevo?.type,
    });

    setFilters((filters) => ({
      ...filters,
      ratings: filters.ratings.filter((rating) => rating !== deselectedWord),
    }));
  };

  /**
   * Clear ratings filters
   */
  const clearWordFilters = () => {
    track(TrackEvent.CLEARED_QUOTE_FILTERS, {
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      testType: wevo?.type,
    });

    setFilters((filters) => ({
      ...filters,
      ratings: [],
    }));
  };

  const handleScopeClick = useCallback(
    (ev, scope) => {
      if (scope) {
        setSelectedScope(scope);
        const page = wevo?.pages?.find((page) => String(page.id) === String(scope?.wevoPageId));
        setPage(page);
        if (wevo.type === WevoType.Journey) {
          const step = page?.steps.find((step) => String(step.id) === String(scope?.stepId));
          setStep(step);
        }
      }
    },
    [setSelectedScope, setPage, setStep, wevo]
  );

  let title = `${filteredQuotes.length} Quotes`;
  if (quotes.length !== filteredQuotes.length) {
    title += ` (out of ${quotes.length})`;
  }

  const rankOrderLabels = labels?.map((label, index) => `Rank - ${numberToOrdinal(index + 1)}`);

  const scoresToLabels = useMemo(() => {
    if (questionType === CustomQuestionTypes.RankOrder) {
      const datasets = labels.map((label, index) => {
        const dataset = {};
        const scoreElement = scores.find((score) => String(score.label) === String(labels.indexOf(label)));
        dataset.label = label;
        dataset.backgroundColor = RANK_ORDER_CHART_COLORS[index];
        dataset.data = Object.values(scoreElement?.scoreDetails || {});
        return dataset;
      });

      return datasets;
    } else {
      return labels.map((label) => {
        const scoreElement = scores.find((score) => String(score.label) === String(labels.indexOf(label)));
        const score = scoreElement?.score ?? 0;
        return score;
      });
    }
  }, [labels, questionType, scores]);

  const numsOfRespondents = useMemo(() => {
    let numRespondentsPerResponse;
    if (questionType === CustomQuestionTypes.RankOrder) {
      numRespondentsPerResponse =
        scoresToLabels?.map((labelData) => labelData?.data?.reduce((acc, curr) => acc + curr, 0)) || [];
    } else {
      numRespondentsPerResponse = labels.map((label) => {
        const scoreElement = scores.find((score) => String(score.label) === String(labels.indexOf(label)));
        const numRespondents = scoreElement?.numRespondents ?? 0;
        return numRespondents;
      });
    }
    return numRespondentsPerResponse;
  }, [labels, questionType, scores, scoresToLabels]);

  const scopeSelector = useMemo(() => {
    if (isJourneyLevel && wevo?.pages?.length > 1) {
      return (
        <JourneyLevelPageScopeSelector
          wevo={wevo}
          scopes={scopes}
          selectedScope={selectedScope}
          handleScopeClick={handleScopeClick}
        />
      );
    }

    if (wevo?.type === WevoType.Journey && !isJourneyLevel) {
      return (
        <JourneyStepScopeSelector
          wevo={wevo}
          scopes={scopes}
          selectedScope={selectedScope}
          handleScopeClick={handleScopeClick}
        />
      );
    }

    if (wevo?.type === WevoType.Classic) {
      return (
        <ClassicPageScopeSelector
          wevo={wevo}
          scopes={scopes}
          selectedScope={selectedScope}
          handleScopeClick={handleScopeClick}
        />
      );
    }

    return <></>;
  }, [isJourneyLevel, handleScopeClick, selectedScope, scopes, wevo]);

  return (
    <Box mr={5}>
      <Paper elevation={4} sx={{ padding: 4, width: '100%', borderRadius: '20px' }}>
        {!isCustomSurveyType(wevo) && (
          <Typography variant="h4" gutterBottom>
            {isJourneyLevel ? 'Journey-Wide Question' : 'Insights Page Question'}
          </Typography>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{name || 'Question:'} &nbsp;&nbsp;</Typography>
            <Typography component="span" sx={{ fontSize: 16, whiteSpace: 'pre-line' }}>
              "{questionText}"
            </Typography>
            <br />
            <Box py={1} />
            {scopeSelector}
          </Grid>
          {questionType === CustomQuestionTypes.Heatmap ? (
            quotes.length > 0 && (
              <Grid item xs={12} style={{ paddingTop: 32 }}>
                <HeatmapWithQuotes
                  wevo={wevo}
                  page={page}
                  step={step}
                  heatmapPoints={quotes}
                  questionType={questionType}
                  followUpQuestion={followUpQuestionText}
                  showClusterTypes={false}
                  showBorders={false}
                />
              </Grid>
            )
          ) : (
            <>
              {isLoading && questionType !== CustomQuestionTypes.CustomQualitative && (
                <Grid item xs={12} sx={{ textAlign: '-webkit-center' }}>
                  <Skeleton variant="rectangular" height={200} width="40%" animation="wave" />
                </Grid>
              )}
              {scores.length > 0 && (
                <Grid item xs={12} sx={{ alignSelf: 'center', textAlign: '-webkit-center' }}>
                  <CustomQuestionGraph
                    questionType={questionType}
                    labels={questionType === CustomQuestionTypes.RankOrder ? rankOrderLabels : labels}
                    scores={scoresToLabels}
                    numsOfRespondents={numsOfRespondents}
                    isPptComponent={isPptComponent}
                    width={width}
                    height={height}
                    componentId={componentId}
                  />
                </Grid>
              )}
              {isLoading &&
                (questionType === CustomQuestionTypes.CustomQualitative || Boolean(followUpQuestionText)) && (
                  <Grid item xs={12} sx={{ textAlign: '-webkit-center' }}>
                    <Skeleton width="75%" />
                    <Skeleton width="75%" />
                    <Skeleton width="75%" />
                    <Skeleton width="75%" />
                  </Grid>
                )}
              {quotes.length > 0 && (
                <Grid item xs={12}>
                  {followUpQuestionText && (
                    <>
                      <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                        Follow-up Question: &nbsp;&nbsp;
                      </Typography>
                      <Typography component="span" sx={{ fontSize: 16, whiteSpace: 'pre-line' }}>
                        "{followUpQuestionText}"
                      </Typography>
                    </>
                  )}
                  <QuotesTable
                    wevo={wevo}
                    page={page}
                    fullQuotes={quotes}
                    displayQuotes={filteredQuotes}
                    onQuoteUpdate={onQuoteUpdate}
                    onAudienceFilterChange={onAudienceFilterChange}
                    onCustomScreenerFilterChange={onCustomScreenerFilterChange}
                    clearWordFilters={clearWordFilters}
                    onSearchChange={onSearchChange}
                    title={title}
                    selectedWords={[...selectedRatings]}
                    deselectWord={deselectWord}
                    filters={
                      questionType !== CustomQuestionTypes.RankOrder
                        ? [
                            <RatingWordFilter
                              ratings={labels}
                              selectedRatings={selectedRatings}
                              onRatingFilterChange={onRatingChange}
                            />,
                          ]
                        : null
                    }
                    chips={questionType !== CustomQuestionTypes.CustomQualitative ? [RatingChip] : []}
                    questionType={questionType}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Paper>
      <RightDrawerButton
        wevo={wevo}
        rightDrawerOpen={rightDrawerOpen}
        handleRightDrawerOpenClick={setRightDrawerOpen}
      />
    </Box>
  );
};

CustomQuestions.propTypes = {
  wevo: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  questionId: PropTypes.string,
  rightDrawerOpen: PropTypes.bool,
  setRightDrawerOpen: PropTypes.func,
  isPptComponent: PropTypes.bool,
  componentId: PropTypes.string,
  fixedPageId: PropTypes.string,
  fixedStepId: PropTypes.string,
};

export default CustomQuestions;
