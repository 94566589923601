import { isEmpty } from 'lodash';

export function isMastercardUUIDTag({ tag }) {
  return isMastercardUUID(tag?.name);
}

export function isMastercardUUID(value) {
  // mc could not confirm that tags are always of a particular uuid format
  // but did confirm that the all have the same length
  return value?.length === 36;
}

export function isValidMastercardUUIDInput(value) {
  // Used for validating UUID text fields where "clearing out" the field is also permitted
  return isMastercardUUID(value) || isEmpty(value);
}
