import { Checkbox, FormControlLabel, Radio } from '@mui/material';
import React from 'react';

export const CustomFormControlLabel = ({
  value,
  label,
  control,
  onChange,
  labelStyles = {},
  ...otherProps
}) => {
  return (
    <FormControlLabel
      value={value}
      label={label}
      control={control}
      onChange={onChange}
      sx={{
        '.MuiFormControlLabel-label': {
          fontSize: '13px',
          lineHeight: 'normal',
          ...labelStyles,
        },
      }}
      {...otherProps}
    />
  );
};

export const CustomFormControlRadio = ({
  value,
  label,
  checked,
  onChange,
  radioProps,
  labelStyles,
  controlProps = {},
  ...otherProps
}) => {
  return (
    <CustomFormControlLabel
      value={value}
      label={label}
      checked={checked}
      labelStyles={labelStyles}
      control={
        <Radio
          sx={{
            color: '#212A37',
            '&.Mui-checked': {
              color: '#212A37',
            },
          }}
          {...controlProps}
        />
      }
      onChange={onChange}
      {...otherProps}
    />
  );
};
export const CustomFormControlCheckbox = ({
  value,
  label,
  checked,
  onChange,
  labelStyles,
  controlProps = {},
  ...otherProps
}) => {
  return (
    <CustomFormControlLabel
      value={value}
      label={label}
      checked={checked}
      labelStyles={labelStyles}
      control={
        <Checkbox
          sx={{
            color: '#212A37',
            '&.Mui-checked': {
              color: '#212A37',
            },
          }}
          {...controlProps}
        />
      }
      onChange={onChange}
      {...otherProps}
    />
  );
};
