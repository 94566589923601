import { Grid } from '@mui/material';
import React from 'react';
import { WevoType } from '../../../../../modules/wevos/constants';
import { serializeErrors } from '../../custom-questions/helpers';
import { ValidationKeys } from '../../helpers/validation/assets';
import AlertMessage from '../shared/AlertMessage';
import AssetPreview from '../shared/AssetPreview';

const Assets = ({ draft, page, errors }) => {
  const assets = draft?.type === WevoType.Journey ? page?.steps ?? [] : [page];

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      {assets.map((asset) => (
        <Grid item xs={12} lg={6} key={asset.id}>
          <AssetPreview asset={asset} maxHeight={82} maxWidth={130} />
        </Grid>
      ))}
      {errors?.pageErrors?.[String(page.id)]?.[ValidationKeys.StepLimitExceeded]?.length > 0 && (
        <AlertMessage
          message={serializeErrors(errors.pageErrors[String(page.id)][ValidationKeys.StepLimitExceeded])}
        />
      )}

      {errors?.pageErrors?.[String(page.id)]?.[ValidationKeys.InsufficientSteps]?.length > 0 && (
        <AlertMessage
          message={serializeErrors(errors.pageErrors[String(page.id)][ValidationKeys.InsufficientSteps])}
        />
      )}
    </Grid>
  );
};

export default Assets;
