import { Box, Typography } from '@mui/material';
import { CustomSelect } from '../../../../components';
import { CustomScreenerTypeOptions } from '../../constants';

const CustomScreenerTypeSelector = ({ value, onChange }) => {
  const handleQuestionTypeChanged = (ev) => {
    const newValue = ev.target.value;
    if (value === newValue) {
      return;
    }
    onChange && onChange(newValue);
  };

  return (
    <Box>
      <Typography mb={1} fontSize={13} fontWeight={500}>
        Select question type*
      </Typography>
      <CustomSelect
        size={'medium'}
        value={value}
        items={CustomScreenerTypeOptions}
        onChange={handleQuestionTypeChanged}
      />
    </Box>
  );
};

export default CustomScreenerTypeSelector;
