import { Box, CircularProgress } from '@mui/material';
import cuid from 'cuid';
import { cloneDeep, isNil, isNumber } from 'lodash';
import { useContext } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { DEVICE_NAME_TO_DEVICE_ID, WevoTestType } from '../../../../modules/wevos/constants';
import { snackbar } from '../../../../notifications';
import { Paths } from '../../../../routes';
import { CustomTextField, Header } from '../../components';
import { ValidationErrorNotice } from '../../components/Notice';
import DebouncedInput from '../../edit/DebouncedInput';
import useAddPage from '../../hooks/useAddPage';
import { useBulkUpdateAssetsV2 as useBulkUpdateAssets } from '../../hooks/useBulkUpdateAssets';
import useDeletePage from '../../hooks/useDeletePage';
import useDeleteStep from '../../hooks/useDeleteStep';
import useSaveWevo from '../../hooks/useSaveWevo';
import useUpdateCustomQuestion from '../../hooks/useUpdateCustomQuestion';
import useUpdatePage from '../../hooks/useUpdatePage';
import useUpdatePageSortOrder from '../../hooks/useUpdatePageSortOrder';
import useUpdateStep from '../../hooks/useUpdateStep';
import useUpdateStepSortOrder from '../../hooks/useUpdateStepSortOrder';
import useUploadStep from '../../hooks/useUploadStep';
import { MAX_DEEP_DIVE_STEPS } from '../constants';
import { IntakeWevoContext } from '../context/IntakeWevoContext';
import { serializeFileRejections } from '../helpers/files';
import { serializeErrors, ValidationKeys } from '../helpers/validation/assets';
import {
  getIndexOfPage,
  getIndexOfPageById,
  getIndexOfStepForPage,
  isCustomSurveyType,
  optimisticMergePageUpdates,
  optimisticMergeStepUpdates,
  optimisticMergeUpdates,
} from '../helpers/wevo';
import CompareConfiguration from './CompareConfiguration';
import DeviceTypeSelector from './DeviceTypeSelector';
import PageConfiguration from './PageConfiguration';

function StudyAssetsIntakeSection() {
  const {
    wevo,
    setWevo,
    setIsWevoSyncing,
    reloadWevoFromRemote,
    reloadCustomQuestionsFromRemote,
    assetErrors,
    customQuestions,
  } = useContext(IntakeWevoContext);
  const history = useHistory();
  const { mutateAsync: saveWevoAsync } = useSaveWevo();
  const { mutateAsync: bulkUpdateAssetsAsync } = useBulkUpdateAssets();
  const { mutateAsync: updateStepSortOrderAsync } = useUpdateStepSortOrder();
  const { mutateAsync: updatePageSortOrderAsync } = useUpdatePageSortOrder();
  const { mutateAsync: addPageAsync, isLoading: isAddingPage } = useAddPage();
  const { mutateAsync: uploadStepImageAsync, isLoading: isUploadingSteps } = useUploadStep();
  const { mutateAsync: updatePageAsync } = useUpdatePage();
  const { mutateAsync: updateStepAsync } = useUpdateStep();
  const { mutateAsync: deletePageAsync, isLoading: isDeletingPage } = useDeletePage();
  const { mutateAsync: deleteStepAsync, isLoading: isDeletingStep } = useDeleteStep();

  // n.b. this is used to 'repair' custom questions that are scopeless after a compare page is deleted
  const { mutateAsync: updateCustomQuestionAsync } = useUpdateCustomQuestion();

  // Page Handlers
  const handlePageAdded = async ({ wevo }) => {
    try {
      setIsWevoSyncing(true);

      await addPageAsync({
        wevoId: wevo.id,
        name: `${wevo.name} - Journey ${(wevo?.pages ?? []).length + 1}`,
        deviceId: wevo.devices[0],
        primerId: wevo?.pages?.[0]?.primerId,
        primerContext: wevo?.pages?.[0]?.primerContext,
      });

      // hard reload to capture the proper page id and server default values from remote
      await reloadWevoFromRemote();

      snackbar.success('Sucessfully added page.');
    } catch (err) {
      snackbar.error(
        err?.response?.data?.humanReadableMessage ??
          'Failed to save changes. Please wait a moment and try again or contact us.'
      );
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handlePageChanged = async ({ page, updateFields }) => {
    const pageIndex = getIndexOfPage(wevo, page);

    if (pageIndex < 0) return;

    const previousState = cloneDeep(wevo);

    const newState = cloneDeep(wevo);
    const newPageState = optimisticMergePageUpdates({ page, updateFields });
    newState.pages[pageIndex] = newPageState;

    setWevo(newState);

    try {
      setIsWevoSyncing(true);
      await updatePageAsync({ wevoId: wevo.id, pageId: page.id, ...updateFields });
    } catch (err) {
      snackbar.error(
        err?.response?.data?.humanReadableMessage ??
          'Failed to save changes. Please wait a moment and try again or contact us.'
      );
      setWevo(previousState);
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handlePageReordered = async ({ page, sourceIndex, destinationIndex }) => {
    if (isNil(sourceIndex) || isNil(destinationIndex)) return;

    const pageIndex = getIndexOfPageById(wevo, page.id);

    if (pageIndex < 0) return;

    const previousState = cloneDeep(wevo);
    const optimisticState = cloneDeep(wevo);

    const clonedPages = optimisticState.pages;
    const clonedPage = clonedPages[sourceIndex];

    if (!clonedPages || clonedPages?.length < 1) return;
    clonedPages.splice(sourceIndex, 1);
    clonedPages.splice(destinationIndex, 0, clonedPage);

    setWevo(optimisticState);

    try {
      setIsWevoSyncing(true);
      await updatePageSortOrderAsync({ id: wevo.id, pageId: page.id, sortOrder: destinationIndex });
    } catch (err) {
      setWevo(previousState);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error reordering pages');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const tryReassignCustomQuestions = async ({ page }) => {
    // goal of reassign is so that when pages get deleted, they don't get orphaned and end up in a
    // state where they can only be fixed by switching back to a wevo study type; to do this, we
    // detect when it is safe to reassign a custom question and then assign it to the default page
    // this is generally only safe to attempt in the context of deleting a page

    // need to update these serially to ensure they land in the correct state
    for (const customQuestion of customQuestions) {
      const defaultPageId = String(wevo?.pages?.[0]?.id);

      const remainingScopes = (customQuestion?.scopes ?? []).filter(
        (scope) => String(scope.wevoPageId) !== String(page.id)
      );
      const hasDefaultPageScope = customQuestion?.scopes?.find(
        (scope) => String(scope.wevoPageId) === defaultPageId
      );

      if (!hasDefaultPageScope && remainingScopes.length < 1) {
        const newScopes = (customQuestion?.scopes ?? [])
          .filter((scope) => String(scope.wevoPageId) !== page.id)
          .concat([{ wevoPageId: defaultPageId, stepId: null }]);

        await updateCustomQuestionAsync({
          id: wevo.id,
          groupId: customQuestion.groupId,
          scopes: newScopes,
        });
      }
    }
  };

  const handlePageRemoved = async ({ page }) => {
    const pageIndex = getIndexOfPage(wevo, page);
    if (pageIndex < 0) return;

    const previousState = cloneDeep(wevo);
    const optimisticState = cloneDeep(wevo);

    // remove the deleted item and recompute the sort order
    optimisticState.pages.splice(pageIndex, 1);
    optimisticState.pages.sort((a, b) => a.sortOrder - b.sortOrder);
    optimisticState.pages = optimisticState.pages.map((page, index) => {
      page.sortOrder = index;
      return page;
    });

    setWevo(optimisticState);

    try {
      setIsWevoSyncing(true);

      // reassign custom questions when custom survey
      if (isCustomSurveyType(optimisticState)) {
        await tryReassignCustomQuestions({ page });
      }

      await deletePageAsync({ wevoId: wevo.id, pageId: page.id });

      snackbar.success('Sucessfully deleted page.');

      // hard reload custom questions from remote since some scopes may no longer
      // be valid ... it's probably possible to do this optimistically client side, but better not to risk it
      // also, we do it in a separate branch in order to optimize the non-custom survey path
      await reloadCustomQuestionsFromRemote();

      // if all compare pages in a custom survey have been deleted, auto-advance to the goal page
      // since there's nothing to do on the assets page anymore, note: we're using the optimistic state here
      // since it's not guaranteed the state of the wevo will be updated in this render
      if (isCustomSurveyType(optimisticState)) {
        if (optimisticState?.pages?.length < 2) {
          history.push(generatePath(Paths.intake.intakeGoal, { wevoId: wevo.id }));
        }
      }
    } catch (err) {
      setWevo(previousState);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error removing page');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  // Step Handlers
  const handleStepsUploaded = async ({ files, fileRejections, page }) => {
    const currentSteps = page?.steps ?? [];

    files = Array.isArray(files) ? files : [files];

    const maxAssets = MAX_DEEP_DIVE_STEPS;

    if ((!files || files?.length < 1) && fileRejections?.length > 0) {
      snackbar.error(`Error uploading assets. ${serializeFileRejections(fileRejections)}.`);
      return;
    }

    if (files.length > maxAssets || files.length + currentSteps?.length > maxAssets) {
      snackbar.error('Page limit would be exceeded');
      return;
    }

    // at this point, some files are valid, but some may have failed, so we should also warn
    if (fileRejections?.length > 0) {
      snackbar.error(`An error occurred for one or more assets. ${serializeFileRejections(fileRejections)}.`);
    }
    const uploadParams = {
      uploadId: cuid(),
      wevoId: wevo.id,
      pageId: page.id,
      files,
      deviceId: wevo.devices[0],
      primerId: wevo?.pages?.[0]?.primerId,
      primerContext: wevo?.pages?.[0]?.primerContext,
    };

    try {
      setIsWevoSyncing(true);
      await uploadStepImageAsync(uploadParams);

      // force reload the wevo from remote go get server ids and images
      await reloadWevoFromRemote();

      snackbar.success('Sucessfully uploaded images.');
    } catch (err) {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error uploading asset(s)');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleStepChanged = async ({ step, updateFields }) => {
    const pageIndex = getIndexOfPageById(wevo, step.wevoPageId);
    const page = wevo?.pages?.[pageIndex];

    if (!page) return;

    const stepIndex = getIndexOfStepForPage(page, step);

    const previousState = cloneDeep(wevo);

    const optimisticState = cloneDeep(wevo);

    optimisticState.pages[pageIndex].steps[stepIndex] = optimisticMergeStepUpdates({
      step: optimisticState.pages[pageIndex].steps[stepIndex],
      updateFields,
    });

    setWevo(optimisticState);

    try {
      setIsWevoSyncing(true);
      await updateStepAsync({ wevoId: wevo.id, stepId: step.id, ...updateFields });
    } catch (err) {
      snackbar.error(
        err?.response?.data?.humanReadableMessage ??
          'Failed to save changes. Please wait a moment and try again or contact us.'
      );
      setWevo(previousState);
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleStepRemoved = async ({ step }) => {
    const pageIndex = getIndexOfPageById(wevo, step.wevoPageId);
    const page = wevo?.pages?.[pageIndex];
    if (!page) return;

    const stepIndex = getIndexOfStepForPage(page, step);
    if (stepIndex < 0) return;

    const previousState = cloneDeep(wevo);
    const optimisticState = cloneDeep(wevo);

    // remove the deleted item and recompute the sort order
    const newSteps = optimisticState.pages[pageIndex].steps;
    newSteps.splice(stepIndex, 1);
    newSteps.sort((a, b) => a.sortOrder - b.sortOrder);
    optimisticState.pages[pageIndex].steps = newSteps.map((step, index) => {
      step.sortOrder = index;
      return step;
    });

    setWevo(optimisticState);

    try {
      setIsWevoSyncing(true);
      await deleteStepAsync({ wevoId: wevo.id, stepId: step.id });

      // hard reload custom questions from remote since some scopes may no longer
      // be valid ... it's probably possible to do this optimistically client side, but better not to risk it
      await reloadCustomQuestionsFromRemote();

      snackbar.success('Sucessfully deleted images.');
    } catch (err) {
      setWevo(previousState);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error removing page');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleStepReordered = async ({ step, sourceIndex, destinationIndex }) => {
    if (isNil(sourceIndex) || isNil(destinationIndex)) return;

    const pageIndex = getIndexOfPageById(wevo, step.wevoPageId);
    const page = wevo?.pages?.[pageIndex];
    if (!page) return;

    const stepIndex = getIndexOfStepForPage(page, step);
    if (stepIndex < 0) return;

    const previousState = cloneDeep(wevo);
    const optimisticState = cloneDeep(wevo);

    const clonedSteps = optimisticState.pages[pageIndex]?.steps ?? [];
    const clonedStep = clonedSteps[stepIndex];

    if (!clonedSteps || clonedSteps?.length < 1) return;
    clonedSteps.splice(sourceIndex, 1);
    clonedSteps.splice(destinationIndex, 0, clonedStep);

    setWevo(optimisticState);

    try {
      setIsWevoSyncing(true);
      await updateStepSortOrderAsync({ id: wevo.id, stepId: step.id, sortOrder: destinationIndex });
    } catch (err) {
      snackbar.error(
        err?.response?.data?.humanReadableMessage ??
          'Failed to save changes. Please wait a moment and try again or contact us.'
      );
      setWevo(previousState);
    } finally {
      setIsWevoSyncing(false);
    }
  };

  // Device Update Handler - it's strange because it requires updating both the wevo and the pages
  const handleUpdateDeviceType = async ({ deviceId }) => {
    const deviceIdNumeric = Number(deviceId);
    if (isNil(deviceIdNumeric) || !isNumber(deviceIdNumeric) || isNaN(deviceIdNumeric)) {
      return;
    }
    const devices = [deviceIdNumeric];

    const previousWevoState = cloneDeep(wevo);

    const optimisticPageState = wevo?.pages?.map((page) =>
      optimisticMergePageUpdates({ page, updateFields: { deviceId: deviceIdNumeric } })
    );

    const optimisticWevoState = optimisticMergeUpdates({
      wevo,
      updateFields: { devices, pages: optimisticPageState },
    });

    setWevo(optimisticWevoState);

    setIsWevoSyncing(true);

    try {
      await saveWevoAsync({ id: wevo.id, devices });
    } catch (err) {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
      setWevo(previousWevoState);
      setIsWevoSyncing(false);
      return;
    }

    const pages = wevo?.pages ?? [];

    if (pages?.length < 1) {
      return;
    }

    try {
      const changes = pages.map((page) => ({
        pageId: page.id,
        deviceId: deviceIdNumeric,
      }));

      await bulkUpdateAssetsAsync({
        wevoId: wevo.id,
        changes,
      });
    } catch (err) {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating asset');
      // rollback to after updating devices on wevo, but before updating deviceId on pages
      setWevo(
        optimisticMergeUpdates({ wevo: optimisticWevoState, updateFields: { pages: previousWevoState.pages } })
      );
      setIsWevoSyncing(false);
      return;
    }

    setIsWevoSyncing(false);
  };

  if (!wevo) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: 'calc(100vh - 64px)', width: '100%', overflowY: 'auto' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StudyAssets
      wevo={wevo}
      onDeviceTypeChanged={handleUpdateDeviceType}
      onPageAdded={handlePageAdded}
      onPageChanged={handlePageChanged}
      onPageRemoved={handlePageRemoved}
      onPageReordered={handlePageReordered}
      onStepChanged={handleStepChanged}
      onStepRemoved={handleStepRemoved}
      onStepReordered={handleStepReordered}
      onFilesUploaded={handleStepsUploaded}
      isAddingPage={isAddingPage}
      isDeletingPage={isDeletingPage}
      isDeletingStep={isDeletingStep}
      isUploadingSteps={isUploadingSteps}
      errors={assetErrors}
    />
  );
}

function StudyAssets({
  wevo,
  onDeviceTypeChanged,
  onPageAdded,
  onPageChanged,
  onPageRemoved,
  onPageReordered,
  onStepChanged,
  onStepRemoved,
  onStepReordered,
  onFilesUploaded,
  isAddingPage,
  isDeletingPage,
  isDeletingStep,
  isUploadingSteps,
  errors,
}) {
  const focusPage = wevo?.pages?.[0];
  const competitorPages = wevo?.pages?.slice(1) ?? [];
  const isCustomSurvey = isCustomSurveyType(wevo);

  // we should show the compare page when the test type is a compare,
  // and also when the study is a page-study, but there is more than one pages.
  // Since we're not auto-deleting pages when the user toggles between types, we need to give the user
  // the opportunity to delete pages, and we'll show a validation error in that case as well
  const shouldShowCompare = wevo?.testType === WevoTestType.Compare || wevo?.pages?.length > 1;

  const handlePageNameChanged = ({ page, newName }) => {
    onPageChanged && onPageChanged({ page, updateFields: { name: newName } });
  };

  const handleStepNameChanged = ({ step, newName }) => {
    onStepChanged && onStepChanged({ step, updateFields: { name: newName } });
  };

  const handleAssetTypeChanged = ({ page, newAssetType }) => {
    onPageChanged && onPageChanged({ page, updateFields: { assetType: newAssetType } });
  };

  const handleIsPrototypeChanged = ({ page, newIsPrototype }) => {
    onPageChanged && onPageChanged({ page, updateFields: { isPrototype: newIsPrototype } });
  };

  const handleJourneyUrlChanged = ({ page, newJourneyUrl }) => {
    onPageChanged && onPageChanged({ page, updateFields: { journeyStartUrl: newJourneyUrl } });
  };

  return (
    <Box>
      {!isCustomSurvey && (
        <>
          <Box>
            <DeviceTypeSelector
              value={wevo?.devices?.[0] || DEVICE_NAME_TO_DEVICE_ID.desktop}
              onChange={onDeviceTypeChanged}
            />
          </Box>
          <Box mb={4} />
          <Box>
            <Header
              name={'Name'}
              description={`Your asset will be referred to by this name in your study.`}
              hasPreview={false}
              isRequired={true}
            />
            <Box mb={2} />
            <DebouncedInput
              value={focusPage?.name || ''}
              onChange={(value) => handlePageNameChanged({ page: focusPage, newName: value })}
              debounceMs={500}
              renderInput={({ value, onChange }) => (
                <CustomTextField
                  value={value}
                  sx={{
                    background: 'white',
                    borderRadius: 4,
                    '& .MuiInputBase-root': {
                      fontSize: 13,
                    },
                  }}
                  onChange={onChange}
                />
              )}
            />
            {errors?.pageErrors?.[String(focusPage?.id)]?.[ValidationKeys.Name] && (
              <Box my={1}>
                <ValidationErrorNotice
                  message={serializeErrors(errors.pageErrors[String(focusPage?.id)][ValidationKeys.Name])}
                />
              </Box>
            )}
          </Box>
          <Box mb={4} />
          <Box>
            <PageConfiguration
              wevo={wevo}
              page={focusPage}
              onFilesUploaded={onFilesUploaded}
              onReorderStep={onStepReordered}
              onDeleteStep={onStepRemoved}
              onStepNameChanged={handleStepNameChanged}
              onAssetTypeChanged={handleAssetTypeChanged}
              onIsPrototypeChanged={handleIsPrototypeChanged}
              onJourneyUrlChanged={handleJourneyUrlChanged}
              isDeletingStep={isDeletingStep}
              isUploadingSteps={isUploadingSteps}
              errors={errors}
            />
          </Box>
          <Box mb={4} />
        </>
      )}
      {shouldShowCompare && (
        <Box>
          <CompareConfiguration
            wevo={wevo}
            competitorPages={competitorPages}
            onAddCompetitor={onPageAdded}
            onDeleteCompetitor={onPageRemoved}
            onPageNameChanged={handlePageNameChanged}
            onReorderCompetitor={onPageReordered}
            onReorderStep={onStepReordered}
            onDeleteStep={onStepRemoved}
            onStepNameChanged={handleStepNameChanged}
            onFilesUploaded={onFilesUploaded}
            onAssetTypeChanged={handleAssetTypeChanged}
            onIsPrototypeChanged={handleIsPrototypeChanged}
            onJourneyUrlChanged={handleJourneyUrlChanged}
            isAddingPage={isAddingPage}
            isDeletingPage={isDeletingPage}
            isDeletingStep={isDeletingStep}
            isUploadingSteps={isUploadingSteps}
            errors={errors}
          />
        </Box>
      )}
    </Box>
  );
}

export default StudyAssetsIntakeSection;
