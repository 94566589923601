import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress } from '@mui/material';

const RoundedButton = styled(Button)(({ theme }) => ({
  height: '34px',
  textTransform: 'none',
  color: '#3B6CAB',
  border: '1.5px solid #3B6CAB',
  borderRadius: '27px',
  '&:hover': {
    border: '1.5px solid #C7D6DF',
  },
}));

const RoundedBackButton = styled(Button)(({ theme }) => ({
  height: '34px',
  textTransform: 'none',
  color: '#212A37',
  border: '1px solid #C7D6DF',
  borderRadius: '27px',
  '&:hover': {
    border: '1.5px solid #C7D6DF',
  },
}));

const RoundedConfirmButton = styled(Button)(({ theme, variant }) => ({
  height: '34px',
  textTransform: 'none',
  color: variant === 'contained' ? '#FFFFFF' : '#3B6CAB',
  border: '1.5px solid #3B6CAB',
  borderRadius: '27px',
  '&:hover': {
    border: '1.5px solid #C7D6DF',
  },
}));

export function DeleteButton({ label = 'Delete', startIcon = <DeleteIcon />, isLoading = false, ...rest }) {
  const icon = isLoading ? <CircularProgress size="1rem" /> : startIcon;

  return (
    <RoundedButton variant="outlined" startIcon={icon} {...rest}>
      {label}
    </RoundedButton>
  );
}

export function ConfirmButton({ label = 'Confirm', isLoading = false, ...rest }) {
  const icon = isLoading ? <CircularProgress size="1rem" /> : <></>;

  return (
    <RoundedConfirmButton variant="contained" startIcon={icon} {...rest}>
      {label}
    </RoundedConfirmButton>
  );
}

export function CancelButton({ label = 'Cancel', isLoading = false, ...rest }) {
  const icon = isLoading ? <CircularProgress size="1rem" /> : <></>;

  return (
    <RoundedBackButton
      variant="outlined"
      sx={{ border: '1px solid #C7D6DF', color: '#212A37' }}
      startIcon={icon}
      {...rest}>
      {label}
    </RoundedBackButton>
  );
}

export default RoundedButton;
