import {
  CustomQuestionTypeOptionsByType,
  CustomQuestionTypes,
  MULTISELECT_MIN_REQUIRED,
  MultiSelectOptions,
  PresetBinaryScales,
  PresetLikertScales,
  RandomizationTypes,
  SelectOptions,
} from '../constants';

export const makeEmptyArray = (size, value = '') => new Array(size).fill(value);

const customQualitativeDefaults = () => {
  return {
    questionText: '',
    followUpQuestionText: null,
    type: CustomQuestionTypes.CustomQualitative,
    labelsType: null,
  };
};

const multipleChoiceDefaults = () => {
  const limits = CustomQuestionTypeOptionsByType[CustomQuestionTypes.MultipleChoice].choiceLimits;

  return {
    questionText: '',
    followUpQuestionText: null,
    type: CustomQuestionTypes.MultipleChoice,
    labels: makeEmptyArray(limits.default),
    labelsType: SelectOptions.Custom,
    randomization: { type: RandomizationTypes.All },
  };
};

const multipleSelectionDefaults = () => {
  const limits = CustomQuestionTypeOptionsByType[CustomQuestionTypes.MultiSelect].choiceLimits;

  return {
    questionText: '',
    followUpQuestionText: null,
    type: CustomQuestionTypes.MultiSelect,
    labels: makeEmptyArray(limits.default),
    labelsType: SelectOptions.Custom,
  };
};

const likert5Defaults = () => {
  return {
    questionText: '',
    followUpQuestionText: null,
    type: CustomQuestionTypes.FiveLikertScale,
    labelsType: null,
  };
};

const likert7Defaults = () => {
  return {
    questionText: '',
    followUpQuestionText: null,
    type: CustomQuestionTypes.SevenLikertScale,
    labelsType: null,
  };
};

const yesNoTrueFalseDefaults = () => {
  return {
    questionText: '',
    followUpQuestionText: null,
    type: CustomQuestionTypes.YesNoTrueFalse,
    labelsType: null,
  };
};

const heatmapDefaults = () => {
  return {
    questionText: '',
    followUpQuestionText: null,
    type: CustomQuestionTypes.Heatmap,
    labelsType: null,
  };
};

const rankOrderDefaults = () => {
  const limits = CustomQuestionTypeOptionsByType[CustomQuestionTypes.RankOrder].choiceLimits;

  return {
    questionText: '',
    followUpQuestionText: null,
    type: CustomQuestionTypes.RankOrder,
    labels: makeEmptyArray(limits.default),
    labelsType: SelectOptions.Custom,
    randomization: { type: RandomizationTypes.All },
  };
};

export const getDefaultsForQuestionType = (questionType) => {
  switch (questionType) {
    case CustomQuestionTypes.MultipleChoice:
      return multipleChoiceDefaults();
    case CustomQuestionTypes.MultiSelect:
      return multipleSelectionDefaults();
    case CustomQuestionTypes.FiveLikertScale:
      return likert5Defaults();
    case CustomQuestionTypes.SevenLikertScale:
      return likert7Defaults();
    case CustomQuestionTypes.YesNoTrueFalse:
      return yesNoTrueFalseDefaults();
    case CustomQuestionTypes.Heatmap:
      return heatmapDefaults();
    case CustomQuestionTypes.RankOrder:
      return rankOrderDefaults();
    case CustomQuestionTypes.CustomQualitative:
    default:
      return customQualitativeDefaults();
  }
};

export const likertForScale = ({ type, scaleType }) => {
  return settingsForScale({ type, scaleType, PresetLikertScales });
};

export const yesNoTrueFalseForScale = ({ type, scaleType }) => {
  return settingsForScale({ type, scaleType, PresetBinaryScales });
};

const settingsForScale = ({ type, scaleType, scalesLabels }) => {
  const limits = CustomQuestionTypeOptionsByType[type].choiceLimits;

  const labels =
    scaleType === SelectOptions.Custom ? makeEmptyArray(limits.default) : scalesLabels[scaleType][type].values;

  return {
    type: type,
    labels: labels,
    labelsType: scaleType,
  };
};

export const multipleSelectionQuestionRequirements = ({ rangeType, number, choices }) => {
  let validation = {
    type: 'range',
    rangeType,
  };

  if (rangeType === MultiSelectOptions.AllThatApply) {
    validation.min = MULTISELECT_MIN_REQUIRED;
    validation.max = choices.length;
  } else if (rangeType === MultiSelectOptions.Exactly) {
    validation.number = number;
    validation.min = number;
    validation.max = number;
  } else if (rangeType === MultiSelectOptions.AtLeast) {
    validation.number = number;
    validation.min = number;
    validation.max = choices.length;
  } else if (rangeType === MultiSelectOptions.UpTo) {
    validation.number = number;
    validation.min = MULTISELECT_MIN_REQUIRED;
    validation.max = number;
  }

  return {
    validation,
  };
};
