import { Box, Typography } from '@mui/material';

function DeepDiveIntroBlock() {
  return (
    <Box>
      <Typography>
        The next series of questions will ask you about the pages you visited and ask you to give us your
        feedback.
      </Typography>
      <Box mb={2} />
      <Typography>Please click "Next &gt;&gt;" to continue.</Typography>
    </Box>
  );
}

export default DeepDiveIntroBlock;
