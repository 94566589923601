import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { getUserProfile } from '../../modules/user/selectors';
import { Paths } from '../../routes';
import ErrorDialog from '../../ui/ErrorDialog';
import Notification from '../../ui/Notification';
import AppBar from '../appBar/AppBar';
import AppBarMC from '../appBar/AppBarMC';
import AutomatedInsights from '../automated-insights';
import Dashboard from '../dashboard';
import Accelerate from '../dashboard/Accelerate';
import FAQsPage from '../dashboard/faq';
import { Start } from '../dashboard/Start';
import {
  CreateFeasibilityRequestFormContainer,
  CreateFeasibilitySuccessPage,
} from '../feasibility-requests/CreateFeasibilityRequestForm';
import CreateWevo from '../intake/create';
import EditWevo from '../intake/edit';
import LearnMoreCard from '../intake/edit/LearnMoreCard';
import LoginInterstitial from '../intake/edit/LoginInterstitial';
import SuccessPage from '../intake/edit/SuccessPage';
import UserlyticsLanding from '../intake/edit/UserlyticsLanding';
import ViewTest from '../intake/edit/ViewTest';
import IntakeForm from '../intake/form';
import Profile from '../profile';
import ChangePassword from '../profile/Password';
import WevoReport from '../reports';
import ProtectedRoute from '../router/ProtectedRoute';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const AppContainer = () => {
  const user = useSelector(getUserProfile);

  // sets 'FAQpage' to be true only when the "FAQ" page is opened
  const FAQpage = window.location.pathname === '/faq';

  const automatedInsightsPathMatch = useRouteMatch({
    path: Paths.automatedInsights.basePath,
  });

  const newIntakePathMatch = useRouteMatch({
    path: Paths.intake.intakeBasePath,
  });

  const getAppBar = () => {
    if (FAQpage) {
      return <AppBarMC />;
    } else if (automatedInsightsPathMatch) {
      // the app bar is rendered within the automated insights (pulse) component
      return <></>;
    } else if (newIntakePathMatch) {
      // the app bar is rendered within the new intake form component
      return <></>;
    } else return <AppBar />;
  };

  if (!user) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100vh', width: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="App" style={{ flexGrow: 1, paddingTop: '64px' }}>
      <ScrollToTop />
      {getAppBar()}
      <Switch>
        <Route exact path="/accelerate" component={Accelerate} />
        <ProtectedRoute exact path="/" component={Start} />
        <ProtectedRoute exact path="/profile" component={Profile} />
        <ProtectedRoute exact path="/profile/password" component={ChangePassword} />
        <ProtectedRoute exact path="/start" component={LoginInterstitial} />
        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <ProtectedRoute path="/success" component={SuccessPage} />
        <ProtectedRoute path="/wevos/create" component={CreateWevo} />
        <ProtectedRoute path="/landing" component={UserlyticsLanding} />
        <ProtectedRoute path="/learnMore" component={LearnMoreCard} />
        <ProtectedRoute exact path="/faq" component={FAQsPage} />
        <ProtectedRoute
          exact
          path="/feasibility-request/create"
          component={CreateFeasibilityRequestFormContainer}
        />
        <ProtectedRoute exact path="/feasibility-request/success" component={CreateFeasibilitySuccessPage} />
        <Route path="/wevo/:wevoId/details" component={ViewTest} />
        <ProtectedRoute path="/wevos/:wevoId/edit/:stepId?" component={EditWevo} />
        <ProtectedRoute path="/wevos/:wevoId/intake" component={IntakeForm} />
        <Route path={[Paths.automatedInsights.basePath]} component={AutomatedInsights} />
        <Route path={[Paths.reports.basePath, Paths.reports.limitedReportBasePath]} component={WevoReport} />
        {/* if there's no match, redirect everything to /, which will redirect to dashboard */}
        <Redirect to="/" />
      </Switch>
      <ErrorDialog />
      <Notification />
    </div>
  );
};

export default AppContainer;
