import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { ReactComponent as ImageIcon } from '../../../../assets/intake-image-icon.svg';
import { ReactComponent as PaperclipIcon } from '../../../../assets/intake-paperclip-icon.svg';
import { CustomTextField, Header, SelectableTile } from '../../components';
import { ValidationErrorNotice } from '../../components/Notice';
import DebouncedInput from '../../edit/DebouncedInput';
import { WevoPageAssetType } from '../constants';
import { serializeErrors, ValidationKeys } from '../helpers/validation/assets';

function AssetTypeSelector({
  assetType,
  onAssetTypeChanged,
  isPrototype,
  onIsPrototypeChanged,
  journeyUrl,
  onJourneyUrlChanged,
  errors,
}) {
  const handleUrlSelected = () => {
    if (assetType !== WevoPageAssetType.URL) {
      onAssetTypeChanged && onAssetTypeChanged({ newAssetType: WevoPageAssetType.URL });
    }
  };

  const handleImageSelected = () => {
    if (assetType !== WevoPageAssetType.Image) {
      onAssetTypeChanged && onAssetTypeChanged({ newAssetType: WevoPageAssetType.Image });
    }
  };

  return (
    <Box>
      <Header
        name="Visual"
        description="Select the visual your audience will give feedback on."
        isRequired={true}
      />
      <Box mb={2} />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <SelectableTile
            width="100%"
            size="large"
            label="URL"
            description="A live website or prototype"
            iconComponent={
              <Box>
                <PaperclipIcon />
              </Box>
            }
            isSelected={assetType === WevoPageAssetType.URL}
            onSelect={handleUrlSelected}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectableTile
            width="100%"
            size="large"
            label="Image"
            description="A static image of my experience"
            iconComponent={
              <Box>
                <ImageIcon />
              </Box>
            }
            isSelected={assetType === WevoPageAssetType.Image}
            onSelect={handleImageSelected}
          />
        </Grid>
      </Grid>
      {errors?.[ValidationKeys.AssetType]?.length > 0 && (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(errors[ValidationKeys.AssetType])} />
        </Box>
      )}
      {assetType === WevoPageAssetType.URL && (
        <>
          <Box mb={3} />
          <Box>
            <DebouncedInput
              value={journeyUrl || ''}
              onChange={(value) => onJourneyUrlChanged({ newJourneyUrl: value })}
              debounceMs={500}
              renderInput={({ value, onChange }) => (
                <CustomTextField
                  value={value}
                  sx={{
                    '& .MuiInputBase-root': {
                      fontSize: 18,
                      border: '1px solid #3B6CAB',
                      background: '#F3F8FB',
                    },
                    '& .MuiInputAdornment-root': {
                      padding: '28px 16px',
                      borderRight: '1px solid #3B6CAB',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography fontSize={18} sx={{ color: '#B1B1B2' }}>
                          https://
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          {errors?.[ValidationKeys.JourneyStartUrl]?.length > 0 && (
            <Box my={1}>
              <ValidationErrorNotice message={serializeErrors(errors[ValidationKeys.JourneyStartUrl])} />
            </Box>
          )}
          <Box mb={3} />
          <Header
            name="Prototype"
            description="Inform my respondents that not everything in the flow is clickable"
            toggleSwitchProps={{
              onChange: (ev) => onIsPrototypeChanged({ newIsPrototype: ev.target.checked }),
              checked: isPrototype,
            }}
            nameProps={{ sx: { fontWeight: 500 } }}
          />
        </>
      )}
    </Box>
  );
}

export default AssetTypeSelector;
