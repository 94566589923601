import { styled, TextField } from '@mui/material';
import React from 'react';

const StyledTextField = styled((props) => <TextField {...props} />)(({ theme, multiline }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1.5px solid #C7D6DF',
    },
    '&:hover fieldset': {
      border: '1.5px solid #3B6CAB',
    },
    '&.Mui-focused fieldset': {
      border: '1.5px solid #3B6CAB',
    },
  },
  '& .MuiInputBase-root': {
    borderRadius: '10px',
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
  },
  '& .MuiInputBase-input': {
    paddingTop: multiline ? 0 : theme.spacing(1.5),
    paddingBottom: multiline ? 0 : theme.spacing(1.5),
    paddingLeft: multiline ? '2px' : theme.spacing(2),
    paddingRight: multiline ? '2px' : theme.spacing(2),
    '&::placeholder': {
      color: '#C1C2C3',
      opacity: 1,
    },
  },
}));

// this text field may be used as a single or multiline input field
const CustomTextField = ({ placeholder, multiline = false, ...props }) => {
  return (
    <StyledTextField variant="outlined" fullWidth multiline={multiline} placeholder={placeholder} {...props} />
  );
};

export default CustomTextField;
