import { Typography } from '@mui/material';
import IntakeModal from '../../../components/IntakeModal';

function ConvertToCustomSurveyModal(props) {
  const content = (
    <Typography fontSize={13}>
      This WEVO will only have Custom Questions. Some of your selections may no longer be valid for the study.
      Any resulting errors must be resolved before submitting your study. Do you wish to continue?
    </Typography>
  );
  return <IntakeModal title="Wevo Setup" content={content} {...props} />;
}

function ConvertToWevoSurveyModal(props) {
  const content = (
    <Typography fontSize={13}>
      Selecting additional report sections may clear information and some custom questions may no longer be
      valid. Do you wish to continue?
    </Typography>
  );
  return <IntakeModal title="Wevo Setup" content={content} {...props} />;
}

export { ConvertToCustomSurveyModal, ConvertToWevoSurveyModal };
