import { Card } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ImagePreview from './ImagePreview';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '92px',
    width: '140px',
    border: '1px solid #C7D6DF',
    borderRadius: '8px',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.2)',
  },
}));

const AssetPreview = ({ asset, maxHeight = 82, maxWidth = 130 }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <ImagePreview image={asset?.images} maxHeight={maxHeight} maxWidth={maxWidth} />
    </Card>
  );
};

export default AssetPreview;
