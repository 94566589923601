import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as PulsePlusIcon } from '../../assets/pulse-plus-icon.svg';
import { BlockedFeature } from '../../modules/automated-insights/constants';
import { getUserProfile } from '../../modules/user/selectors';
import FeatureTooltip from './FeatureTooltip';
import ImportURLField from './ImportURLField';

export default function ImportURLList({
  urls,
  onChange,
  decorators,
  errorMessage,
  showAddButton = true,
  enableAddButton = true,
  disableFeatures,
  freeTrialExpired,
  subscriptionExpired,
  reachedUsageLimit,
  hasGlowingInputField,
}) {
  const [selectedUrlIndex, setSelectedUrlIndex] = useState(0);

  const user = useSelector(getUserProfile);

  const handleURLChanged = useCallback(
    (newUrl, replaceIndex) =>
      onChange(
        urls.map((urlObj, index) => (replaceIndex === index ? { url: newUrl, hasError: false } : urlObj))
      ),
    [onChange, urls]
  );

  const handleAddURL = useCallback(() => {
    onChange([...urls].concat([{ url: '', hasError: false }]));
    setSelectedUrlIndex(urls.length);
  }, [onChange, urls]);

  const handleDeleteURL = useCallback(
    (index) => {
      onChange(urls.slice(0, index).concat(urls.slice(index + 1)));
      setSelectedUrlIndex(urls.length - 2);
    },
    [onChange, urls]
  );

  const handleSelectedURL = useCallback((index) => {
    setSelectedUrlIndex(index);
  }, []);

  return (
    <Box>
      <Grid container alignItems="center" rowGap={2} justifyContent="center">
        {urls.map((urlObj, index) => {
          return (
            <Fragment key={index}>
              <Grid item key={index} xs={12}>
                <Grid container alignItems="center" rowGap={1} sx={{ justifyContent: { md: 'center' } }}>
                  <Grid item md={1} sx={{ minWidth: { xs: '100%', md: 0 } }}>
                    {urls.length > 1 && (
                      <Typography color="primary" variant="body2">
                        Page {index + 1}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={9} lg={8} sx={{ flexGrow: 1 }}>
                    <ImportURLField
                      initialURL={urlObj?.url}
                      isFocused={index === selectedUrlIndex}
                      onStateChange={(importURL) => handleURLChanged(importURL, index)}
                      onRemove={urls.length > 1 ? () => handleDeleteURL(index) : null}
                      onFocus={() => handleSelectedURL(index)}
                      showErrors={false} // disable per-line errors because they throw off the formatting
                      notifyErrorStates={true} // this makes it so that onStateChange emits all state changes, even invalid urls
                      hasGlow={hasGlowingInputField}
                      disableFeatures={disableFeatures}
                      freeTrialExpired={freeTrialExpired}
                      subscriptionExpired={subscriptionExpired}
                      reachedUsageLimit={reachedUsageLimit}
                      user={user}
                    />
                  </Grid>

                  <Grid
                    item
                    md={1}
                    sx={{
                      display: { xs: 'none', md: 'flex' },
                      alignItems: 'center',
                    }}>
                    {decorators?.[index] ? decorators[index] : <></>}
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
      <Box mt={2}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={9} lg={8}>
            {disableFeatures ? (
              <FeatureTooltip
                disableFeatures={disableFeatures}
                blockedFeature={BlockedFeature.UrlImport}
                freeTrialExpired={freeTrialExpired}
                subscriptionExpired={subscriptionExpired}
                reachedUsageLimit={reachedUsageLimit}
                user={user}>
                <Box
                  onClick={undefined}
                  sx={{
                    display: 'inline-flex',
                    cursor: disableFeatures ? 'not-allowed' : 'pointer',
                    opacity: enableAddButton ? 1.0 : 0.7,
                    visibility: showAddButton ? 'initial' : 'hidden',
                  }}>
                  <PulsePlusIcon style={{ height: '16px', width: '16px' }} />
                  <Typography color="primary" fontSize={12} ml={1}>
                    Add
                  </Typography>
                </Box>
              </FeatureTooltip>
            ) : (
              <Tooltip title={enableAddButton ? '' : 'Reached max number of URLs'} placement="right">
                <Box
                  onClick={enableAddButton ? () => handleAddURL() : undefined}
                  sx={{
                    display: 'inline-flex',
                    cursor: disableFeatures ? 'not-allowed' : 'pointer',
                    opacity: enableAddButton ? 1.0 : 0.7,
                    visibility: showAddButton ? 'initial' : 'hidden',
                  }}>
                  <PulsePlusIcon style={{ height: '16px', width: '16px' }} />
                  <Typography color="primary" fontSize={12} ml={1}>
                    Add
                  </Typography>
                </Box>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box mt={1}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10} lg={8}>
            <Typography color="error" fontSize={12}>
              {urls?.length && errorMessage}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
