import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { WevoPageAssetType } from '../../../constants';
import { getImageOnlyPreviewRespondentLink } from '../../../helpers/pages';
import { isCustomSurveyType } from '../../../helpers/wevo';

function formatTaskToComplete(taskToComplete) {
  return (taskToComplete || '').split('\n').map((taskToCompleteLine, index) => (
    <Typography
      key={index}
      component={index === 0 ? 'span' : 'p'}
      sx={index === 0 ? {} : { mb: 1, py: isEmpty(taskToCompleteLine) ? 1 : 0 }}>
      {taskToCompleteLine}
    </Typography>
  ));
}

function TaskInstructionsPreview({ wevo, page }) {
  const isCustomSurvey = isCustomSurveyType(wevo);
  const showExperienceLink = !isCustomSurvey;
  const taskToComplete = wevo?.details?.taskToComplete;

  const getInstructionsComponent = () => {
    if (!isCustomSurvey) {
      return <WevoStudyTaskInstruction wevo={wevo} page={page} />;
    }

    if (isCustomSurvey && taskToComplete?.length > 0) {
      return <CustomSurveyTaskInstruction wevo={wevo} page={page} />;
    }

    return <Box></Box>;
  };

  return (
    <Box>
      {getInstructionsComponent()}
      <Box mb={4}></Box>
      {showExperienceLink && <TaskLinkInstruction wevo={wevo} page={page} />}
    </Box>
  );
}

function WevoStudyTaskInstruction({ wevo, page }) {
  const taskToComplete = wevo?.details?.taskToComplete;
  const taskToCompleteWithFormatting = formatTaskToComplete(taskToComplete);

  return (
    <>
      <Box mb={2}>
        <Box mb={2}>
          <Typography fontWeight={600} sx={{ textDecoration: 'underline' }}>
            Instructions - PLEASE READ CAREFULLY
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography>1. Click the link below. A new tab (or window) will open.</Typography>
        </Box>

        <Box mb={2}>
          <Typography mb={1}>
            2.{' '}
            {taskToComplete ? (
              taskToCompleteWithFormatting
            ) : (
              <Typography component="span" fontWeight={600}>
                {' {{ Your Task to Complete Goes Here}} '}
              </Typography>
            )}
          </Typography>
          <Typography>
            You may need to click through multiple pages in the new window. Please pay close attention to the
            content and the experience of these pages.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            3. To return to this survey: click on the tab (or window) that this survey is in. Once you complete
            this experience, you will be asked detailed questions about it.
          </Typography>
        </Box>
      </Box>
    </>
  );
}

function CustomSurveyTaskInstruction({ wevo, page }) {
  const taskToComplete = wevo?.details?.taskToComplete;
  const taskToCompleteWithFormatting = formatTaskToComplete(taskToComplete);

  return (
    <Box mb={2}>
      <Box mb={2}>
        <Typography fontWeight={600} sx={{ textDecoration: 'underline' }}>
          Instructions - PLEASE READ CAREFULLY
        </Typography>
      </Box>
      <Box mb={1}>
        <Typography>
          {taskToComplete ? (
            taskToCompleteWithFormatting
          ) : (
            <Typography component="span" fontWeight={600}>
              {' {{ Your Task to Complete Goes Here}} '}
            </Typography>
          )}
        </Typography>
      </Box>
    </Box>
  );
}

function TaskLinkInstruction({ wevo, page }) {
  const journeyStartUrl = page?.journeyStartUrl;

  const absoluteJourneyStartUrl = () => {
    if (page?.assetType === WevoPageAssetType.Image) {
      return getImageOnlyPreviewRespondentLink({ page });
    }

    if (!journeyStartUrl?.startsWith('http') || !journeyStartUrl?.startsWith('https')) {
      return `https://${journeyStartUrl}`;
    }

    return journeyStartUrl;
  };

  return (
    <Box>
      <Typography fontWeight={600}>
        Please click{' '}
        <a href={absoluteJourneyStartUrl()} target="_blank" rel="noopener nofollow noreferrer">
          here
        </a>{' '}
        to open the experience that you will be evaluating.
      </Typography>
      <Box mb={2} />
      <Typography fontWeight={600}>
        Keep this page open throughout the duration of the survey so you can reference it as needed.
      </Typography>
    </Box>
  );
}

export default TaskInstructionsPreview;
