import { useIsMutating, useQuery } from 'react-query';
import axios from '../../../api.js';
import { CustomQuestionMutationKeys } from '../../../modules/intake/constants';

export const getCustomQuestions = async ({ wevoId }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/custom-questions/groups`,
    method: 'GET',
  });
  return response.data.groups;
};

const fetchCustomQuestions = async ({ queryKey: [key, { wevoId }] }) => {
  if (!wevoId) {
    return [];
  }

  return getCustomQuestions({ wevoId });
};

export default function useFetchCustomQuestions(wevoId, options) {
  const numOngoingMutations = useIsMutating({
    predicate: (mutation) =>
      Object.values(CustomQuestionMutationKeys).includes(mutation.options.mutationKey) &&
      mutation.options.variables.wevoId === wevoId,
  });
  return useQuery(['customQuestionsData', { wevoId }], fetchCustomQuestions, {
    notifyOnChangeProps: 'tracked',
    enabled: numOngoingMutations === 0, // don't refetch this query while relevant mutations are ongoing,
    ...(options ?? {}),
  });
}
