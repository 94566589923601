import { useMutation, useQuery } from 'react-query';
import { default as api, default as axios } from '../api';

const fetchWevosTags = async () => {
  const response = await api({
    url: `/api/v2/tags`,
    method: 'GET',
  });
  return response?.data?.wevosTags;
};

const deleteTag = async ({ id: wevoId, tagId }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/tags/${tagId}`,
    method: 'DELETE',
  });
  return response.data;
};

const addTags = async ({ id: wevoId, tags }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/tags`,
    method: 'PUT',
    data: { tags },
  });

  return response.data;
};

export function useWevosTags() {
  return useQuery('wevosTags', fetchWevosTags, {
    notifyOnChangeProps: 'tracked',
    placeholderData: [],
  });
}

export function useDeleteTag(options) {
  return useMutation(deleteTag);
}

export function useAddTags() {
  return useMutation(addTags);
}
