import { Box, Typography } from '@mui/material';

function Subheader({ name, description }) {
  return (
    <Box>
      <Typography>{name}</Typography>
      <Typography variant="caption" sx={{ color: '#878787' }}>
        {description}
      </Typography>
    </Box>
  );
}

export default Subheader;
