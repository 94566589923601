import { Box, Button, CircularProgress, Grid, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import wevoSuccessGIF from '../../../assets/wevoSuccessGIF.gif';
import { getUserCustomizations, getUserProfile, getUserTeamId } from '../../../modules/user/selectors';
import { snackbar } from '../../../notifications';
import { Paths } from '../../../routes';
import { TrackEvent } from '../../analytics';
import useCreateWevo from '../../dashboard/hooks/useCreateWevo';

export const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(4),
  },
  finalGif: {
    height: '165px',
  },
}));

const SuccessPage = () => {
  const classes = useStyles();
  let history = useHistory();
  const { track } = useAnalytics();
  const userCustomizations = useSelector(getUserCustomizations);
  const user = useSelector(getUserProfile);
  const userTeamId = useSelector(getUserTeamId);
  const hasNewIntake = userCustomizations?.features?.newIntake !== false;
  const isMastercard = Boolean(userCustomizations?.isMastercard);
  const { mutateAsync: createWevoAsync, isLoading: isCreatingNewWevo } = useCreateWevo();

  const handleStartNewWevoClick = async () => {
    if (hasNewIntake) {
      await handleNewIntakeClick();
    } else {
      history.push('/wevos/create');
    }
  };

  const handleReturnToDashboardClick = () => {
    history.push('./dashboard');
  };

  const handleNewIntakeClick = async () => {
    const firstName = user?.firstName;
    const lastName = user?.lastName;
    const ownerName = `${firstName} ${lastName}`;
    const ownerEmail = user?.email;

    try {
      const wevo = await createWevoAsync({ ownerName, ownerEmail });
      const wevoId = wevo.id;
      track(TrackEvent.CREATED_TEST, {
        wevoId: wevoId,
        ownerName,
        ownerEmail,
        teamId: userTeamId,
      });

      const entryPath = isMastercard ? Paths.intake.intakeStart : Paths.intake.intakeAudience;

      history.push({
        pathname: generatePath(entryPath, { wevoId }),
      });
    } catch (err) {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error creating new wevo');
    }
  };

  const email = user?.email;
  const approval = userCustomizations?.approval;
  const requiresApproval =
    approval?.approvalList?.length > 0 &&
    !approval?.approvalList.includes(email) &&
    !approval?.whitelist?.includes(email);

  return (
    <Box paddingTop="30px">
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <Typography align="center">
            <img className={classes.finalGif} src={wevoSuccessGIF} alt="the WEVO confetti Final logo" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.title} variant="h4" align="center">
            Your WEVO was submitted {requiresApproval ? 'for approval!' : 'successfully!'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            A WEVO team member may reach out if there are specific items to confirm for your test.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '70px' }}>
          <Typography variant="body1" align="center">
            Need to submit another WEVO?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">
            <Button
              variant="contained"
              size="large"
              onClick={handleStartNewWevoClick}
              disabled={isCreatingNewWevo}>
              {isCreatingNewWevo && <CircularProgress size={16} sx={{ mr: 1, color: 'white' }} />}
              START A NEW WEVO
            </Button>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="center">
            {`Or return to your WEVO `}
            <Link
              color="primary"
              style={{ cursor: 'pointer' }}
              onClick={handleReturnToDashboardClick}
              underline="hover">
              Dashboard
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SuccessPage;
