import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { IntakeSectionNames, IntakeSubsectionNames } from '../../../../modules/intake/constants';
import { CustomSelect } from '../../components';
import { IntakeWevoContext } from '../context/IntakeWevoContext';
import SummaryPreviewTabs from './components/SummaryPreviewTabs';
import { CustomQuestionsPreview } from './components/previews/CustomQuestionsPreview';
import CustomScreenersPreview from './components/previews/CustomScreenersPreview';
import { ExpectationsHopesFearsPreview } from './components/previews/ExpectationsPreview';
import FullSurveyPreview from './components/previews/FullSurveyPreview';
import PrimerPreview from './components/previews/PrimerPreview';
import { SentimentMapPreviews } from './components/previews/SentimentMapPreview';
import TaskInstructionsPreview from './components/previews/TaskInstructionsPreview';
import PageBreakIndicator from './components/survey-elements/PageBreakIndicator';

function IntakeSummaryPreviewPanel({ selectedTabIndex, onTabChanged, hasPreview, section }) {
  const {
    wevo,
    selectedPageIndex,
    setSelectedPageIndex,
    customQuestions,
    customScreeners,
    userCustomizations,
    proPricing,
  } = useContext(IntakeWevoContext);

  const previewComponent = useMemo(
    () =>
      getPreviewForSection({
        section,
        wevo,
        page: wevo?.pages?.[selectedPageIndex],
        customQuestions,
        customScreeners,
      }),
    [customQuestions, customScreeners, section, selectedPageIndex, wevo]
  );

  const handlePageChanged = useCallback(
    (pageId) => {
      const newPageIndex = (wevo?.pages ?? []).map((page) => String(page.id)).indexOf(String(pageId));

      if (newPageIndex > -1) {
        setSelectedPageIndex(newPageIndex);
      }
    },
    [wevo, setSelectedPageIndex]
  );

  const controlsComponent = useMemo(
    () =>
      getControlsForSection({
        section,
        wevo,
        page: wevo?.pages?.[selectedPageIndex],
        onPageChanged: handlePageChanged,
      }),
    [handlePageChanged, section, selectedPageIndex, wevo]
  );

  return (
    <Box>
      <SummaryPreviewTabs
        wevo={wevo}
        customQuestions={customQuestions}
        customScreeners={customScreeners}
        userCustomizations={userCustomizations}
        proPricing={proPricing}
        selectedTabIndex={selectedTabIndex}
        onChange={onTabChanged}
        hasPreview={hasPreview}
        previewComponent={previewComponent}
        controls={controlsComponent}
      />
    </Box>
  );
}

function getControlsForSection({ section, wevo, page, onPageChanged }) {
  if (isEmpty(section)) return null;

  if (!page) return null;

  // controls are only present for compares
  if (wevo?.pages?.length < 2) return null;

  const sectionsWithControls = [IntakeSectionNames.Review];

  const subSectionsWithControls = [
    IntakeSubsectionNames.Asset,
    IntakeSubsectionNames.CustomQuestions,
    IntakeSubsectionNames.PrimerAndTask,
  ];

  const hasControls =
    sectionsWithControls.includes(section.sectionName) ||
    subSectionsWithControls.includes(section.subSectionName);

  if (hasControls) {
    return (
      <Box p={2} borderBottom="1px solid #C7D6DF">
        <Box display="flex" justifyContent="flex-end">
          <Box sx={{ width: '100%' }}>
            <Typography mb={1} fontSize={12}>
              Experience
            </Typography>
            <CustomSelect
              fontSize={12}
              size={'small'}
              value={String(page.id)}
              onChange={(ev) => onPageChanged(ev.target.value)}
              items={(wevo?.pages ?? []).map((page) => ({ label: page.name, value: String(page.id) }))}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
}

function getPreviewForSection({ section, wevo, page, customQuestions, customScreeners }) {
  if (isEmpty(section)) return <></>;

  if (section.sectionName === IntakeSectionNames.Audience) {
    return (
      <Box>
        <CustomScreenersPreview wevo={wevo} page={page} customScreeners={customScreeners} showName={true} />
      </Box>
    );
  }
  if (section.subSectionName === IntakeSubsectionNames.Expectations) {
    return <ExpectationsHopesFearsPreview wevo={wevo} showDisabledNotice={true} />;
  }

  if (section.subSectionName === IntakeSubsectionNames.PrimerAndTask) {
    return (
      <Box>
        <PrimerPreview wevo={wevo} />
        {wevo?.pages?.[0]?.primerContext && (
          <>
            <Box mb={2} />
            <PageBreakIndicator />
            <Box mb={2} />
          </>
        )}
        <TaskInstructionsPreview wevo={wevo} page={page} />
      </Box>
    );
  }

  if (section.subSectionName === IntakeSubsectionNames.Asset) {
    return <SentimentMapPreviews wevo={wevo} page={page} />;
  }

  if (section.subSectionName === IntakeSubsectionNames.CustomQuestions) {
    return <CustomQuestionsPreview wevo={wevo} page={page} customQuestions={customQuestions} />;
  }

  if (section.sectionName === IntakeSectionNames.Review) {
    return (
      <FullSurveyPreview
        wevo={wevo}
        page={page}
        customQuestions={customQuestions}
        customScreeners={customScreeners}
      />
    );
  }

  return <></>;
}

export default IntakeSummaryPreviewPanel;
