import { groupBy, isEmpty, isNil } from 'lodash';
import {
  CustomQuestionTypeOptionsByType,
  CustomQuestionTypes,
  MultiSelectOptions,
  ValidationKeys,
} from '../constants';

const CustomQuestionTypeToValidationHandler = {
  [CustomQuestionTypes.CustomQualitative]: validateCustomQualitativeQuestion,
  [CustomQuestionTypes.FiveLikertScale]: validateLikertQuestion,
  [CustomQuestionTypes.SevenLikertScale]: validateLikertQuestion,
  [CustomQuestionTypes.YesNoTrueFalse]: validateBinaryQuestion,
  [CustomQuestionTypes.Heatmap]: validateHeatmapQuestion,
  [CustomQuestionTypes.MultipleChoice]: validateMultipleChoiceQuestion,
  [CustomQuestionTypes.MultiSelect]: validateMultipleSelectionQuestion,
  [CustomQuestionTypes.RankOrder]: validateRankOrderQuestion,
};

export const validateCustomQuestions = (customQuestions, configuration) => {
  const isCustomSurvey = configuration?.global?.isCustomSurvey;

  const errors = (customQuestions ?? []).reduce((acc, cur) => {
    acc[cur.groupId] = [];
    return acc;
  }, {});

  for (const customQuestion of customQuestions ?? []) {
    errors[customQuestion.groupId] = validateCustomQuestion(customQuestion, { isCustomSurvey });
  }

  const globalErrors = validateGlobalErrors(customQuestions ?? [], configuration?.global || {});

  return {
    questionErrors: errors,
    globalErrors,
  };
};

export const validateCustomQuestion = (customQuestion, configuration) => {
  const isCustomSurvey = configuration?.isCustomSurvey ?? false;

  const errors = [];

  const questionTypeValidator = CustomQuestionTypeToValidationHandler?.[customQuestion?.question?.type];

  if (questionTypeValidator) {
    errors.push(...questionTypeValidator({ customQuestion, isCustomSurvey }));
  }

  errors.push(...validateScopes({ customQuestion, isCustomSurvey }));

  return groupBy(errors, 'key');
};

const validateGlobalErrors = (customQuestions, { questionLimit, forceNoLimit, isCustomSurvey }) => {
  const globalErrors = [];

  if (!isNil(questionLimit) && !forceNoLimit) {
    const limitExceeded = (customQuestions?.length || 0) - questionLimit;

    if (limitExceeded > 0) {
      globalErrors.push({
        key: ValidationKeys.LimitExceeded,
        reason: `Custom question limit exceeded. Please remove ${limitExceeded} questions.`,
      });
    }
  }

  return groupBy(globalErrors, 'key');
};

function validateCustomQualitativeQuestion({ customQuestion, isCustomSurvey }) {
  return [
    ...validateQuestionName({ customQuestion, isCustomSurvey }),
    ...validateQuestionText({ customQuestion, isCustomSurvey }),
    ...validateFollowUpQuestionText({ customQuestion, isCustomSurvey }),
  ];
}

function validateMultipleChoiceQuestion({ customQuestion, isCustomSurvey }) {
  return validateGenericMultipleChoiceQuestion({ customQuestion, isCustomSurvey });
}

function validateLikertQuestion({ customQuestion, isCustomSurvey }) {
  return validateGenericMultipleChoiceQuestion({ customQuestion, isCustomSurvey });
}

function validateBinaryQuestion({ customQuestion, isCustomSurvey }) {
  return validateGenericMultipleChoiceQuestion({ customQuestion, isCustomSurvey });
}

function validateRankOrderQuestion({ customQuestion, isCustomSurvey }) {
  return validateGenericMultipleChoiceQuestion({ customQuestion, isCustomSurvey });
}

function validateMultipleSelectionQuestion({ customQuestion, isCustomSurvey }) {
  const errors = [
    ...validateQuestionName({ customQuestion, isCustomSurvey }),
    ...validateQuestionText({ customQuestion, isCustomSurvey }),
    ...validateFollowUpQuestionText({ customQuestion, isCustomSurvey }),
    ...validateLabelsContent({ customQuestion, isCustomSurvey }),
  ];

  const rangeType = customQuestion?.question?.rangeType;
  const number = customQuestion?.question?.number;
  const hasExclusiveChoice = customQuestion?.question?.randomization?.exclusiveChoices?.length > 0;
  const hasAnchorChoice = customQuestion?.question?.randomization?.anchorChoices?.length > 0;

  if (!rangeType) {
    errors.push({
      key: ValidationKeys.ChoiceRequirements,
      reason: 'Choice type is required.',
    });
  }

  if (hasExclusiveChoice) {
    if (rangeType === MultiSelectOptions.Exactly && number !== 1) {
      errors.push({
        key: ValidationKeys.ChoiceRequirements,
        reason:
          "Since 'None of the above' is an exclusive option, respondents should choose 'Exactly 1 option.'",
      });
    }

    if (rangeType === MultiSelectOptions.AtLeast && number !== 1) {
      errors.push({
        key: ValidationKeys.ChoiceRequirements,
        reason:
          "Since 'None of the above' is an exclusive option, respondents should choose 'At least 1 option.'",
      });
    }
  }

  if (rangeType && rangeType !== MultiSelectOptions.AllThatApply) {
    const number = customQuestion?.question?.number;

    if (!number) {
      errors.push({
        key: ValidationKeys.ChoiceRequirements,
        reason: 'Number of choices is required.',
      });
    } else {
      const limits = CustomQuestionTypeOptionsByType[CustomQuestionTypes.MultiSelect]?.choiceLimits;

      const labels = customQuestion?.question?.labels ?? [];

      let min = limits.min;
      const max = limits.max;

      switch (rangeType) {
        case MultiSelectOptions.AtLeast:
          min = number + 1;
          break;
        case MultiSelectOptions.Exactly:
          min = number + 1;
          break;
        case MultiSelectOptions.UpTo:
          min = number;
          break;
        default:
          break;
      }

      if (min && labels?.length && labels.length < min) {
        if (hasAnchorChoice || hasExclusiveChoice) {
          errors.push({
            key: ValidationKeys.Labels,
            reason: `At least ${min} choices are required (including 'Other' and/or 'None of the above').`,
          });
        } else {
          errors.push({ key: ValidationKeys.Labels, reason: `At least ${min} choices are required.` });
        }
      }

      if (max && labels?.length && labels.length > max) {
        if (hasAnchorChoice || hasExclusiveChoice) {
          errors.push({
            key: ValidationKeys.Labels,
            reason: `At most ${max} choices are required (including 'Other' and/or 'None of the above').`,
          });
        } else {
          errors.push({ key: ValidationKeys.Labels, reason: `At most ${max} choices are required.` });
        }
      }
    }
  }

  return errors;
}

function validateHeatmapQuestion({ customQuestion, isCustomSurvey }) {
  const errors = [
    ...validateQuestionName({ customQuestion, isCustomSurvey }),
    ...validateQuestionText({ customQuestion, isCustomSurvey }),
  ];

  const followUpQuestionText = customQuestion?.question?.followUpQuestionText;

  if (isEmpty(followUpQuestionText)) {
    errors.push({
      key: ValidationKeys.FollowUpQuestionText,
      reason: 'A follow-up question is required for custom clickmaps.',
    });
  } else if (followUpQuestionText.length < 1) {
    errors.push({
      key: ValidationKeys.FollowUpQuestionText,
      reason: 'Follow-up question text must contain at least two characters.',
    });
  }

  if (isCustomSurvey) {
    errors.push({
      key: ValidationKeys.UnsupportedQuestionType,
      reason: 'Clickmap questions are not available in custom question only studies.',
    });
  }

  return errors;
}

function validateGenericMultipleChoiceQuestion({ customQuestion, isCustomSurvey }) {
  // this function abstracts over the common validations for any question type
  // that presents multiple choices to a respondent and requires that they pick exactly one option
  const errors = [
    ...validateQuestionName({ customQuestion, isCustomSurvey }),
    ...validateQuestionText({ customQuestion, isCustomSurvey }),
    ...validateFollowUpQuestionText({ customQuestion, isCustomSurvey }),
    ...validateLabelsContent({ customQuestion, isCustomSurvey }),
  ];

  const limits = CustomQuestionTypeOptionsByType[customQuestion?.question?.type]?.choiceLimits;

  if (!limits) {
    return errors;
  }
  const labelsType = customQuestion?.question?.labelsType;

  if (isEmpty(labelsType)) {
    errors.push({ key: ValidationKeys.LabelsType, reason: 'Scale is required.' });
  }

  const labels = customQuestion?.question?.labels ?? [];

  if (limits?.min && labels?.length && labels.length < limits.min) {
    errors.push({ key: ValidationKeys.Labels, reason: `At least ${limits.min} choices are required.` });
  }

  if (limits?.max && labels?.length && labels.length > limits.max) {
    errors.push({ key: ValidationKeys.Labels, reason: `At most ${limits.max} choices are required.` });
  }

  return errors;
}

function validateQuestionName({ customQuestion, isCustomSurvey }) {
  // This field is optional, so don't validate it if not present
  // If present, then it should be at least one character and at most 20 characters
  const questionName = customQuestion?.name;

  if (isEmpty(questionName)) {
    return [];
  }

  if (questionName.length < 1 || questionName.length > 20) {
    return [
      {
        key: ValidationKeys.QuestionName,
        reason:
          'Question name must contain between 2-20 characters. This name will be used to identify your question throughout the report.',
      },
    ];
  }

  return [];
}

function validateQuestionText({ customQuestion, isCustomSurvey }) {
  // Required, must be not null and greater than two characters
  const questionText = customQuestion?.question?.questionText;

  if (isEmpty(questionText)) {
    return [
      {
        key: ValidationKeys.QuestionText,
        reason: 'Required field.',
      },
    ];
  }

  if (questionText.length < 2) {
    return [
      {
        key: ValidationKeys.QuestionText,
        reason: 'Question text must contain at least two characters.',
      },
    ];
  }

  return [];
}

function validateFollowUpQuestionText({ customQuestion, isCustomSurvey }) {
  // Optional (except for heatmap questions - see 'validateHeatmapQuestion' for that implementation),
  // but must be greater than 2 characters
  const followUpQuestionText = customQuestion?.question?.followUpQuestionText;

  if (isEmpty(followUpQuestionText)) {
    return [];
  }

  if (followUpQuestionText.length < 2) {
    return [
      {
        key: ValidationKeys.FollowUpQuestionText,
        reason: 'Follow up question must contain at least two characters.',
      },
    ];
  }

  return [];
}

function validateLabelsContent({ customQuestion, isCustomSurvey }) {
  const labels = customQuestion?.question?.labels ?? [];

  if (labels.some((label) => isEmpty(label))) {
    return [{ key: ValidationKeys.Labels, reason: 'Please make sure none of your choices are blank.' }];
  }

  // Check for duplicates
  const labelsByValue = groupBy(labels);

  if (Object.entries(labelsByValue).some(([label, instances]) => instances.length > 1)) {
    return [{ key: ValidationKeys.Labels, reason: 'Please make sure none of your choices are duplicates.' }];
  }

  return [];
}

function validateScopes({ customQuestion, isCustomSurvey }) {
  // validate that all scopes for the question are either page-level or step-level, but not both
  const scopes = customQuestion?.scopes ?? [];

  if (scopes.length < 1) {
    // note: for studies with images, it's possible to unselect everything; however for custom surveys,
    // we'll assign all custom questions to the page scope behind the scenes and this should never trigger.
    return [
      {
        key: ValidationKeys.Scopes,
        reason: 'Please assign your custom question to at least one experience or specific asset.',
      },
    ];
  }

  if (scopes.some((scope) => isNil(scope.wevoPageId) && isNil(scope.stepId))) {
    return [
      {
        key: ValidationKeys.Scopes,
        reason: 'Please assign your custom question to at least one experience or specific asset.',
      },
    ];
  }
  const onlyPageScopes = scopes.every((scope) => !isNil(scope.wevoPageId) && isNil(scope.stepId));
  const onlyStepScopes = scopes.every((scope) => !isNil(scope.wevoPageId) && !isNil(scope.stepId));

  if (onlyPageScopes || onlyStepScopes) {
    return [];
  }

  return [
    {
      key: ValidationKeys.Scopes,
      reason: 'Please assign your custom question to experiences or specific assets, but not both.',
    },
  ];
}

export const serializeErrors = (errors) => (errors ?? []).map((error) => error.reason).join(' ');

export function hasCustomQuestionErrors(validationResult) {
  for (const errors of Object.values(validationResult?.questionErrors ?? {})) {
    if (Object.keys(errors)?.length > 0) return true;
  }

  if (Object.keys(validationResult?.globalErrors ?? {})?.length > 0) return true;

  return false;
}
