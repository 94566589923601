import { Box } from '@mui/material';
import ImageCard, { PlaceholderImagePreviewCard } from '../../../../components/ImageCard';
import { getReportFeatures } from '../../../helpers/wevo';
import PageBreakIndicator from '../survey-elements/PageBreakIndicator';
import { QuestionNotice } from '../survey-elements/QuestionLogic';
import { QuestionText, ReadOnlyVerticalTextField } from '../survey-elements/ReadOnlyField';

function SentimentMapPreview({ wevo, page, step }) {
  return (
    <Box>
      <SentimentMapClickQuestion
        wevo={wevo}
        page={page}
        step={step}
        clickQuestionText={
          <>
            Click <b>one</b> area of the page that you <b>like the most.</b>
          </>
        }
      />
      <PageBreakIndicator spacing={3} />
      <SentimentMapClickQuestion
        wevo={wevo}
        page={page}
        step={step}
        clickQuestionText={
          <>
            Click <b>one</b> area of the page that you <b>like 2nd most.</b>
          </>
        }
      />
      <PageBreakIndicator spacing={3} />
      <SentimentMapClickQuestion
        wevo={wevo}
        page={page}
        step={step}
        clickQuestionText={
          <>
            Click <b>one</b> area of the page that you <b>dislike the most.</b>
          </>
        }
      />
      <PageBreakIndicator spacing={3} />
      <SentimentMapClickQuestion
        wevo={wevo}
        page={page}
        step={step}
        clickQuestionText={
          <>
            Click <b>one</b> area of the page that you <b>dislike 2nd most.</b>
          </>
        }
      />
    </Box>
  );
}

function SentimentMapClickQuestion({ wevo, page, step, clickQuestionText }) {
  return (
    <Box>
      <QuestionText questionText={clickQuestionText} />
      <Box mb={2} />
      <Box> {step?.images ? <ImageCard images={step.images} /> : <PlaceholderImagePreviewCard />}</Box>
      <Box mb={3} />
      <Box>
        <QuestionText
          questionText={
            <>
              In a full sentence, please tell us why you selected this area. <i>Please be specific.</i>
            </>
          }
        />
        <Box mb={2} />
        <ReadOnlyVerticalTextField />
      </Box>
    </Box>
  );
}

function SentimentMapPreviews({ wevo, page }) {
  const features = getReportFeatures({ wevo });

  if (!features?.includeSentimentMaps) {
    return <QuestionNotice spacing={0} noticeText="This study does not include sentiment maps." />;
  }

  return (
    <Box>
      <QuestionNotice
        spacing={0}
        noticeText="Additional questions may appear between the sentiment maps for each page."
      />
      <Box mb={3} />
      {(page?.steps ?? []).map((step) => {
        return (
          <Box mb={4} key={step.id}>
            <SentimentMapPreview wevo={wevo} page={page} step={step} />
          </Box>
        );
      })}
    </Box>
  );
}

export { SentimentMapPreview, SentimentMapPreviews };
