import { Box } from '@mui/material';
import ImagePreview from '../../../ui/ImagePreview';

/**
 *
 * Note that images is an object containing all of the variant types, e.g. {original: {...}, mini: {...}}
 * and the `ImagePreview` component picks the appropriate one.
 */
function Thumbnail({ images, boxStyles = {}, imageStyles = {} }) {
  return (
    <Box px={1} pt={1} sx={boxStyles}>
      <ImagePreview
        image={images}
        sx={{ border: '0.535px solid #3B6CAB', borderRadius: 16, height: '140px', ...imageStyles }}
      />
    </Box>
  );
}

export default Thumbnail;
