import { Box, CircularProgress, Grid } from '@mui/material';
import { cloneDeep, isNil } from 'lodash';
import { useContext } from 'react';
import { snackbar } from '../../../../../../notifications';
import { CustomTextField, Header } from '../../../../components';
import { ValidationErrorNotice } from '../../../../components/Notice';
import { ReorderableList } from '../../../../components/ReorderableList';
import { AddListItemButton } from '../../../../components/SelectableTile';
import DebouncedInput from '../../../../edit/DebouncedInput';
import useAddCustomScreener from '../../../../hooks/useAddCustomScreener';
import useAddCustomScreenerOption from '../../../../hooks/useAddCustomScreenerOption';
import useDeleteCustomScreener from '../../../../hooks/useDeleteCustomScreener';
import useDeleteCustomScreenerOption from '../../../../hooks/useDeleteCustomScreenerOption';
import useUpdateCustomScreener from '../../../../hooks/useUpdateCustomScreener';
import useUpdateCustomScreenerQuestions from '../../../../hooks/useUpdateCustomScreenerQuestions';
import { IntakeWevoContext } from '../../../context/IntakeWevoContext';
import { ValidationKeys } from '../../constants';
import { mergeUpdates } from '../../helpers/optimisticUpdates';
import { serializeErrors } from '../../helpers/validation';
import IntakeCustomScreener from './IntakeCustomScreener';

const CustomScreeners = () => {
  const {
    wevo,
    customScreeners,
    setCustomScreeners,
    setIsWevoSyncing,
    reloadCustomScreenersFromRemote,
    customScreenerErrors,
  } = useContext(IntakeWevoContext);

  // Custom Questions
  const { mutateAsync: addCustomScreenerAsync, isLoading: isAddingCustomScreener } = useAddCustomScreener();
  const { mutateAsync: updateCustomScreenerAsync } = useUpdateCustomScreener();
  const { mutateAsync: updateCustomScreenerQuestionsAsync } = useUpdateCustomScreenerQuestions();
  const { mutateAsync: addCustomScreenerOptionAsync, isLoading: isAddingCustomScreenerOption } =
    useAddCustomScreenerOption();
  const { mutateAsync: deleteCustomScreenerOptionAsync, isLoading: isDeletingCustomScreenerOption } =
    useDeleteCustomScreenerOption();
  const { mutateAsync: deleteCustomScreenerAsync, isLoading: isDeletingCustomScreener } =
    useDeleteCustomScreener();

  const handleCustomScreenerCreated = async ({ wevo }) => {
    setIsWevoSyncing(true);
    const prevScreeners = cloneDeep(customScreeners);

    try {
      await addCustomScreenerAsync({ wevoId: wevo.id });
      // there's some weirdness with creating groups and initial group number,
      // so we hard refresh after creating a new custom question
      await reloadCustomScreenersFromRemote();
      snackbar.success('Custom screener question successfully created!');
    } catch (err) {
      snackbar.error('Failed to add custom screener question.');
      setCustomScreeners(prevScreeners);
    }
  };

  const handleCustomScreenerDestroyed = async ({ customScreener }) => {
    const customScreenerId = customScreener.id;
    const originalArray = Array.from(customScreeners);
    const index = originalArray.findIndex((question) => question.id === customScreenerId);
    if (index < 0) {
      return;
    }
    const items = Array.from(customScreeners);
    items.splice(index, 1); // removes 1 element at index
    setCustomScreeners(items); //update parent's array
    try {
      setIsWevoSyncing(true);
      await deleteCustomScreenerAsync({ wevoId: wevo.id, filterId: customScreenerId });
      snackbar.success('Custom screener question successfully deleted!');
    } catch (err) {
      setCustomScreeners(originalArray);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting custom screener question');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleCustomScreenerChanged = async ({ customScreener, optionId, updateFields }) => {
    setIsWevoSyncing(true);
    const customScreenerId = customScreener.id;
    const previousState = cloneDeep(customScreener);
    // optimistic update custom question fields
    const newState = mergeUpdates({ customScreener, optionId, updateFields });
    setCustomScreeners(
      customScreeners.map((customScreener) => {
        if (customScreener.id === customScreenerId) {
          return newState;
        }
        return customScreener;
      })
    );

    const changes = optionId ? { options: [{ id: optionId, ...updateFields }] } : updateFields;
    try {
      await updateCustomScreenerAsync({
        wevoId: wevo.id,
        filterId: customScreenerId,
        changes: { ...changes },
      });

      // when question type or labels type changes, new options may be created so we need a hard refresh
      if (updateFields?.questionType || updateFields?.details) {
        await reloadCustomScreenersFromRemote();
      }
    } catch (err) {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating custom screener question');
      // rollback client state because of failed update
      setCustomScreeners(
        customScreeners.map((customScreener) => {
          if (customScreener.id === previousState.id) {
            return previousState;
          }
          return customScreener;
        })
      );
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleCustomScreenerUpdateSortOrder = async ({ sourceIndex, destinationIndex }) => {
    const originalScreeners = Array.from(customScreeners);
    // Reorder custom screeners
    let items = Array.from(customScreeners);
    const [itemToReorder] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, itemToReorder);
    let sortOrder = 0;
    items = items.map((item) => {
      return {
        ...item,
        sortOrder: sortOrder++,
      };
    });
    setCustomScreeners(items);
    try {
      setIsWevoSyncing(true);
      await updateCustomScreenerQuestionsAsync({
        wevoId: wevo.id,
        changes: items,
      });
      snackbar.success('Custom screener questions successfully reordered!');
    } catch (err) {
      setCustomScreeners(originalScreeners);
      snackbar.error(
        err?.response?.data?.humanReadableMessage ?? 'Error updating custom screener question sort order'
      );
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleAddScreenerOption = async (customScreenerId, option = {}) => {
    setIsWevoSyncing(true);
    const prevScreeners = cloneDeep(customScreeners);

    try {
      await addCustomScreenerOptionAsync({
        wevoId: wevo.id,
        filterId: customScreenerId,
        option,
      });

      //  a new option object is added to the options array
      // so we hard refresh after creating it
      await reloadCustomScreenersFromRemote();
      snackbar.success('Custom screener option successfully added!');
    } catch (err) {
      setCustomScreeners(prevScreeners);
      snackbar.error('Failed to add custom screener option.');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleDeleteScreenerOption = async ({ customScreenerId, optionId }) => {
    const originalArray = Array.from(customScreeners);
    const index = originalArray.findIndex((question) => question.id === customScreenerId);
    if (index < 0) {
      return;
    }

    const optionIndex = originalArray[index].options.findIndex((option) => option.id === optionId);
    if (optionIndex < 0) {
      return;
    }

    const items = Array.from(customScreeners);
    items[index].options.splice(optionIndex, 1); // removes 1 element from options array
    setCustomScreeners(items); //update parent's array

    try {
      setIsWevoSyncing(true);
      await deleteCustomScreenerOptionAsync({ wevoId: wevo.id, filterId: customScreenerId, optionId });
      snackbar.success('Custom screener option successfully deleted!');
    } catch (err) {
      setCustomScreeners(originalArray);
      snackbar.error('Error deleting custom screener question option.');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  if (!wevo || isNil(customScreeners)) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: 'calc(100vh - 64px)', width: '100%', overflowY: 'auto' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <CustomScreenersList
      wevo={wevo}
      customScreeners={customScreeners}
      onCustomScreenerAdded={handleCustomScreenerCreated}
      onCustomScreenerRemoved={handleCustomScreenerDestroyed}
      onCustomScreenerSortChanged={handleCustomScreenerUpdateSortOrder}
      onCustomScreenerChanged={handleCustomScreenerChanged}
      onOptionAdded={handleAddScreenerOption}
      onOptionDeleted={handleDeleteScreenerOption}
      isAdding={isAddingCustomScreener}
      isAddingOption={isAddingCustomScreenerOption}
      isDeleting={isDeletingCustomScreener}
      isDeletingOption={isDeletingCustomScreenerOption}
      errors={customScreenerErrors}
    />
  );
};

function CustomScreenersList({
  wevo,
  customScreeners,
  onCustomScreenerAdded,
  onCustomScreenerRemoved,
  onCustomScreenerSortChanged,
  onCustomScreenerChanged,
  onOptionAdded,
  onOptionDeleted,
  isAdding,
  isAddingOption,
  isDeleting,
  isDeletingOption,
  errors,
}) {
  const limitExceededErrors = errors?.globalErrors?.[ValidationKeys.LimitExceeded];
  const limitExceeded = limitExceededErrors?.length > 0;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Header
          name={'Additional Screeners'}
          description={`Target a more specific audience by adding your custom screener questions.`}
          tooltipProps={{
            title: `By leveraging Additional Screeners, you can ensure that our team recruits the
              precise audience you are targeting. You will be able to filter qualitative
              results based on the responses to those screeners.`,
            arrow: true,
            placement: 'right',
          }}
          hasPreview={true}
          isRequired={false}
        />
        {limitExceeded && (
          <Box my={1}>
            <ValidationErrorNotice message={serializeErrors(limitExceededErrors)} />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReorderableList
          onDragEnd={(props) => {
            const source = props.source;
            const destination = props.destination;

            if (source.index === destination.index) {
              return;
            }

            onCustomScreenerSortChanged({
              sourceIndex: source.index,
              destinationIndex: destination.index,
            });
          }}
          items={(customScreeners ?? []).map((customScreener) => {
            return {
              label: customScreener?.name || `Screener ${customScreener?.sortOrder + 1}`,
              id: customScreener.id,
              customScreener,
            };
          })}
          renderTitle={(item) => (
            <DebouncedInput
              value={item.label}
              onChange={(value) => {
                onCustomScreenerChanged({
                  customScreener: item.customScreener,
                  updateFields: { name: value },
                });
              }}
              debounceMs={500}
              renderInput={({ value, onChange }) => (
                <CustomTextField
                  value={value}
                  sx={{
                    width: '90%',
                    background: 'white',
                    borderRadius: 4,
                    '& .MuiInputBase-root': {
                      fontWeight: 600,
                      fontSize: 14,
                    },
                  }}
                  onChange={onChange}
                />
              )}
            />
          )}
          renderItem={(item) => {
            const questionErrors = errors?.questionErrors?.[item.customScreener.id] ?? {};
            return (
              <>
                {questionErrors?.name?.length > 0 && (
                  <Box mb={2}>
                    <ValidationErrorNotice message={serializeErrors(questionErrors.name)} />
                  </Box>
                )}
                <IntakeCustomScreener
                  customScreener={item.customScreener}
                  onDelete={onCustomScreenerRemoved}
                  isDeleting={isDeleting}
                  isDeletingOption={isDeletingOption}
                  isAddingOption={isAddingOption}
                  onChange={onCustomScreenerChanged}
                  onOptionAdded={onOptionAdded}
                  onOptionDeleted={onOptionDeleted}
                  errors={errors?.questionErrors?.[item.customScreener.id] ?? {}}
                />
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column">
          <AddListItemButton
            isLoading={isAdding}
            onClick={isAdding ? () => {} : () => onCustomScreenerAdded({ wevo })}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default CustomScreeners;
