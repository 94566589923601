import { Box, Typography } from '@mui/material';

function AcceptedBlock() {
  return (
    <Box>
      <Typography>
        Thank you for agreeing to take our survey! Your participation in this survey is valuable, and your
        feedback is appreciated.
      </Typography>
      <Box mb={2} />
      <Typography>Please click "Next &gt;&gt;" to continue.</Typography>
    </Box>
  );
}

export default AcceptedBlock;
