import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';

/**
 * Creates a link between two sessions
 */

const createSessionLink = async ({ sessionId, otherSessionId }) => {
  const { data } = await axios({
    url: `/api/v2/automated-insight-sessions/${sessionId}/links/${otherSessionId}`,
    method: 'PUT',
  });
  return data;
};

export function useCreateSessionLink(options = {}) {
  const queryClient = useQueryClient();
  return useMutation(createSessionLink, {
    onSuccess: () => {
      queryClient.invalidateQueries('segments');
      queryClient.invalidateQueries('automatedInsightSession');
    },
    ...options,
  });
}

/**
 * Removes a link between two sessions
 */

const deleteSessionLink = async ({ sessionId, otherSessionId, shouldDeleteSession }) => {
  const { data } = await axios({
    url: `/api/v2/automated-insight-sessions/${sessionId}/links/${otherSessionId}`,
    method: 'DELETE',
    data: { shouldDeleteSession },
  });
  return data;
};

export function useDeleteSessionLink(options = {}) {
  const queryClient = useQueryClient();
  return useMutation(deleteSessionLink, {
    onSuccess: () => {
      queryClient.invalidateQueries('automatedInsightSession');
    },
    ...options,
  });
}

const reorderSessionLink = async ({ sessionId, otherSessionId, sortOrder }) => {
  const { data } = await axios({
    url: `/api/v2/automated-insight-sessions/${sessionId}/links/${otherSessionId}/reorder`,
    method: 'PUT',
    data: { sortOrder },
  });
  return data;
};

export function useReorderSessionLink(options = {}) {
  const queryClient = useQueryClient();
  return useMutation(reorderSessionLink, {
    onSuccess: () => {
      queryClient.invalidateQueries('automatedInsightSession');
    },
    ...options,
  });
}
