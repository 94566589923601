import { Box, CircularProgress, Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useAnalytics } from 'use-analytics';
import axios from '../../../api';
import { useAddTags } from '../../../hooks/useTags';
import { IntakeStepIds, MetricTypes, Metrics } from '../../../modules/intake/constants';
import * as UserActions from '../../../modules/user/actions';
import { isUserLoaded } from '../../../modules/user/helpers';
import { getUserCustomizations, getUserProfile, getUserTeamId } from '../../../modules/user/selectors';
import * as WevoActions from '../../../modules/wevos/actions';
import {
  CustomQuestionOption,
  JTBDType,
  MastercardCdsOption,
  MastercardDqsOption,
  WevoType,
} from '../../../modules/wevos/constants';
import { getActiveWevos } from '../../../modules/wevos/selectors';
import { snackbar } from '../../../notifications';
import Stepper from '../../../ui/IntakeStepper';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import BottomBar from '../edit/BottomBar';
import CreateTestForm from './CreateTestForm';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(24),
  },
  container: {
    padding: 0,
  },
}));

const createWevo = async (createObj) => {
  const response = await axios({
    url: `/api/v2/wevos/create`,
    method: 'POST',
    data: createObj,
  });
  return response.data.wevo;
};

const CreateWevo = (props) => {
  const { wevos, fetchWevos, teamId, userCustomizations, user, fetchUser } = props;

  const classes = useStyles();
  const history = useHistory();
  const createFormRef = useRef();
  const queryClient = useQueryClient();

  const [emailsToNotify, setEmailsToNotify] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState(Metrics.Wevo); // Default selection is Standard WEVO

  const isDQS = selectedMetric === Metrics.DQS;
  const isCDS = selectedMetric === Metrics.CDS;
  const enabledDQS = userCustomizations?.features?.mastercardDqs === MastercardDqsOption.Enabled;
  const enabledCDS = userCustomizations?.features?.mastercardCds === MastercardCdsOption.Enabled;
  const enabledCustomQuestions =
    userCustomizations?.features?.customQuestions !== CustomQuestionOption.Disabled;
  const showMetricSelection = enabledDQS || enabledCDS;

  // Make sure this stays in sync with the steps array in intake/edit/index.js
  const steps = useMemo(() => {
    const testTypeLabel = isDQS || isCDS ? 'Task' : 'Test Type';

    const steps = [
      { id: IntakeStepIds.Start, label: 'Start', completed: false, ref: null },
      { id: IntakeStepIds.TestGoal, label: 'Test Goal', completed: false, ref: null },
      { id: IntakeStepIds.TestType, label: testTypeLabel, completed: false, ref: null },
      ...(isCDS || !enabledCustomQuestions
        ? []
        : [{ id: IntakeStepIds.Details, label: 'Details', completed: false, ref: null }]),
      { id: IntakeStepIds.Audience, label: 'Audience', completed: false, ref: null },
      { id: IntakeStepIds.Review, label: 'Review', completed: false, ref: null },
    ];
    return steps;
  }, [isDQS, isCDS, enabledCustomQuestions]);

  const { track } = useAnalytics();

  useEffect(() => {
    if (!wevos.length > 0) {
      fetchWevos(); // get current user's wevos if not already fetched
    }

    if (!isUserLoaded(user)) {
      fetchUser(); // get the current user if not populated
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: addTags } = useAddTags();

  const { mutate } = useMutation(createWevo, {
    onSuccess: (wevo) => {
      const tagsNames = tagsList?.map((tag) => tag.name);
      addTags(
        { id: wevo?.id, tags: tagsNames },
        {
          onSuccess: (group, variables) => {
            queryClient.invalidateQueries(['tags', { wevoId: variables.id }]);
            queryClient.invalidateQueries('wevosTags');
            queryClient.invalidateQueries('wevos');
            queryClient.invalidateQueries('wevoData');
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error adding tag');
          },
        }
      );

      // Replace the `/wevos/create` path with `.../edit/start` path, so when pressing the back button when in audience step,
      // it will not show as if there is no data in the start step
      history.replace(`/wevos/${wevo.id}/edit/start`);
      history.push({ pathname: `/wevos/${wevo.id}/edit/test-goal`, state: { initialSteps: steps } });
    },
    onError: (err) => {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? '');
    },
  });

  const onSubmit = (data) => {
    const { testName, ownerName, ownerEmail } = data;

    track(TrackEvent.CREATED_TEST, {
      name: testName,
      ownerName,
      ownerEmail,
      teamId,
    });

    const createObj = {
      name: testName,
      ownerName,
      ownerEmail,
      emailsToNotify,
      // default values for JTBD and description
      description: '',
      jobToBeDone: JTBDType.Other,
      teamId,
      type: WevoType.Journey,
      devices: [1], // TODO remove this, only here because current create endpoint requires it
      audienceCategoryId: 1, // TODO remove this, only here because current create endpoint requires it
    };

    if (isDQS) {
      createObj.metricType = MetricTypes.MastercardDqs;
    } else if (isCDS) {
      createObj.metricType = MetricTypes.MastercardCds;
    }

    mutate(createObj);
  };

  const handleBottomBarNextClick = () => {
    if (createFormRef && createFormRef.current) createFormRef.current.submitForm();
  };

  const handleMetricChange = (newMetric) => {
    setSelectedMetric(newMetric);
  };

  useTrackPageLoad({ name: TrackEvent.STARTED_CREATE_TEST });

  return (
    <Fragment>
      <Stepper
        step={0}
        steps={steps}
        setStep={() => {}}
        disableStepClicks={true}
        isDQS={isDQS}
        isCDS={isCDS}
      />
      <Container maxWidth="sm" className={classes.paper}>
        <Typography variant="h3" gutterBottom>
          Create Your WEVO
        </Typography>
        {isUserLoaded(user) ? (
          <CreateTestForm
            onSubmit={onSubmit}
            updateDefinitionValidity={setIsFormValid}
            user={user}
            selectedMetric={selectedMetric}
            showMetricSelection={showMetricSelection}
            showDiscardMetricDialog={false}
            newMetricName={''}
            toggleShowMetricDialog={() => {}}
            switchMetric={() => {}}
            handleMetricChange={handleMetricChange}
            enabledDQS={enabledDQS}
            enabledCDS={enabledCDS}
            emailsToNotify={emailsToNotify}
            onEmailsToNotifyChanged={setEmailsToNotify}
            tagsList={tagsList}
            onTagsChanged={setTagsList}
          />
        ) : (
          <Box mt={4}>
            <CircularProgress size={24} />
          </Box>
        )}
      </Container>
      <BottomBar
        handleNextClick={handleBottomBarNextClick}
        nextLabel="Next"
        isNextDisabled={!isFormValid}
        nextButtonType="submit"
        nextButtonForm="create-test-form"
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    wevos: getActiveWevos(state),
    teamId: getUserTeamId(state),
    userCustomizations: getUserCustomizations(state),
    user: getUserProfile(state),
  };
};

const actionCreators = {
  fetchWevos: WevoActions.fetchWevos,
  fetchUser: UserActions.fetchUserInfo,
};

export default connect(mapStateToProps, actionCreators)(CreateWevo);
