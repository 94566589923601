import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import CustomTextField from './CustomTextField';

// For an explanation as to why this is not a custom component
// see: https://stackoverflow.com/questions/68378474/mui-select-custom-menuitem-not-working-properly
const defaultRenderMenuOption = ({ key, item, isSelected }) => {
  return (
    <MenuItem key={key} value={item.value} disabled={item?.disabled || false}>
      <Box width="100%" py={0.5} display="flex" justifyContent="space-between" alignItems="center">
        <ListItemText
          primary={item?.label || ''}
          primaryTypographyProps={{
            fontSize: 13,
            fontWeight: isSelected ? 500 : 400,
          }}
          secondary={item?.description || ''}
          secondaryTypographyProps={{
            color: '#878787',
            fontSize: 12,
            fontWeight: 400,
          }}
          sx={{ margin: 0 }}
        />
        {item?.endText && (
          <Typography sx={{ color: '#878787', fontSize: '12px', fontStyle: 'italic' }}>
            {item.endText}
          </Typography>
        )}
      </Box>
      {item?.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
    </MenuItem>
  );
};

const defaultRenderSelectedItem = ({ item }) => {
  return <Typography sx={{ fontWeight: 500, fontSize: 14, lineHeight: 'normal' }}>{item?.label}</Typography>;
};

function CustomSelect({
  value,
  onChange,
  items,
  disabled,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  maxHeight,
  renderMenuItem = defaultRenderMenuOption,
  renderSelectedItem = defaultRenderSelectedItem,
  ...rest
}) {
  const selectedItem = items.find((item) => item.value === value);

  return (
    <CustomTextField
      select
      value={value}
      onChange={onChange}
      SelectProps={{
        IconComponent: (props) => (
          <ExpandMoreRoundedIcon {...props} sx={{ color: '#212A37 !important', fontSize: 28 }} />
        ),
        renderValue: () => renderSelectedItem({ item: selectedItem }),
        MenuProps: {
          className: 'transparentScrollBar',
          anchorOrigin: anchorOrigin,
          transformOrigin: transformOrigin,
          PaperProps: {
            sx: {
              marginTop: 1,
              borderRadius: 2,
              boxShadow: 'none',
              border: '1px solid rgba(160, 160, 160, 0.37)',
              maxHeight: maxHeight,
            },
          },
        },
      }}
      disabled={disabled}
      {...rest}>
      {items.map((item) => renderMenuItem({ key: item.value, item, isSelected: item.value === value }))}
    </CustomTextField>
  );
}

export default CustomSelect;
