import AccessAlarmsRoundedIcon from '@mui/icons-material/AccessAlarmsRounded';
import DoneIcon from '@mui/icons-material/Done';
import FlashOnRoundedIcon from '@mui/icons-material/FlashOnRounded';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Box,
  Button,
  CardActionArea,
  CircularProgress,
  Grid,
  Icon,
  ListItem,
  ListItemIcon,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Tools } from '../edit/ToolSelection';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '684px',
    width: '280px',
  },
  cardSelected: {
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  image: {
    height: 48,
    width: 60,
  },
  startButton: {
    marginTop: theme.spacing(2),
    color: 'white',
    height: '30px',
    width: '100px',
  },
  checkIcon: {
    color: theme.palette.primary.main,
    fontSize: 'medium',
    stroke: theme.palette.primary.main,
    strokeWidth: 2,
  },
  img: {
    width: '50px',
  },

  toolImage: {
    textAlign: 'center',
  },
  subTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(1.5),
  },
  testTypeFont: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  titleFont: {
    fontSize: '14px',
  },
  subTitleFont: {
    fontSize: '13px',
  },
  bulletFont: {
    fontSize: '12px',
  },
  textAlignCenter: {
    textAlign: 'center',
  },

  alignItemsCenter: {
    alignItems: 'flex-start',
  },
  topSectionGrid: {
    height: '96px',
  },
  goalsSection: {
    height: '180px',
  },
  outputsSection: {
    height: '172px',
  },
  creditsSection: {
    height: '140px',
  },
  testTypes: {
    textAlign: 'center',
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1.5),
    height: '14px',
  },
  effort: {
    textAlign: 'center',
    marginBottom: theme.spacing(1.5),
  },
  effortIcon: {
    fontSize: '14px',
    verticalAlign: 'middle',
    marginBottom: theme.spacing(0.5),
  },
  output: {
    color: 'black',
    fontSize: '12px',
    textDecoration: 'underline',
    marginLeft: theme.spacing(-4),
  },
  checkBoxImage: {
    height: theme.spacing(7),
  },
  diagnosticOutputs: {
    marginTop: theme.spacing(2.5),
  },
  goalsDiagnostic: {
    marginTop: theme.spacing(2),
  },
  goal: {
    marginBottom: theme.spacing(1),
  },
}));

const Image = (props) => {
  const { image } = props;
  const classes = useStyles();

  return (
    <Icon className={classes.image}>
      <img src={image} alt="selectable" className={classes.img} />
    </Icon>
  );
};

const SelectableToolCard = (props) => {
  const {
    handleOutputClicked,
    image,
    title,
    testTypes,
    tool,
    effort,
    participants,
    goals,
    outputs,
    isSelected,
    isLoading,
    setToolSelected,
    onStartClick,
  } = props;
  const classes = useStyles();

  let checkbox;
  if (isSelected) {
    checkbox = <RadioButtonChecked color="primary" fontSize="small" />;
  } else {
    checkbox = <RadioButtonUncheckedIcon color="primary" fontSize="small" />;
  }

  const isWevoTool = [Tools.Diagnostic, Tools.Compare].includes(tool);
  const numberOfCredits = isWevoTool ? '1' : '8';
  const effortIcon = isWevoTool ? (
    <FlashOnRoundedIcon className={classes.effortIcon} />
  ) : (
    <AccessAlarmsRoundedIcon className={classes.effortIcon} />
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Card
          className={classNames(classes.root, { [classes.cardSelected]: isSelected })}
          elevation={isSelected ? 4 : 1}
          style={{ alignItems: 'flex-start' }}>
          <CardActionArea onClick={setToolSelected}>
            <CardContent>
              <Box pb={1}>
                <Grid
                  container
                  justifyContent="space-between"
                  direction="row"
                  className={classes.checkBoxImage}>
                  <Grid item xs={4}>
                    {checkbox}
                  </Grid>
                  <Grid item xs={4} className={classes.toolImage}>
                    <Image image={image} />
                  </Grid>
                  <Grid item xs={4} />
                </Grid>
              </Box>
              <Grid container className={classes.topSectionGrid}>
                <Grid item xs={12} className={classes.subTitle}>
                  <Typography variant="h5" className={classes.titleFont}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.testTypes}>
                  <Typography variant="body2" className={classes.testTypeFont}>
                    {testTypes || ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.effort}>
                  <Typography variant="body2" className={classes.subTitleFont}>
                    {effortIcon}
                    {` ${effort}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.textAlignCenter}>
                  <Typography variant="body2" className={classes.subTitleFont}>
                    {participants}
                  </Typography>
                </Grid>
              </Grid>

              <Box pt={2} />

              <Grid container className={classes.goalsSection}>
                {title === 'Compare' && (
                  <div className={classes.goalsDiagnostic}>
                    <Typography variant="body2" className={classes.bulletFont}>
                      All of
                      <b>
                        <i> Diagnostic</i>
                      </b>
                      , plus:
                    </Typography>
                  </div>
                )}
                <ul style={{ marginLeft: '-24px', marginTop: title === 'Compare' ? '-32px' : '' }}>
                  {goals?.map((goal, index) => {
                    return (
                      <li className={classes.goal} key={index}>
                        <Typography variant="body2" className={classes.bulletFont}>
                          {goal}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </Grid>

              <Box pt={1} />

              <Grid container className={classes.outputsSection}>
                {title === 'Compare' && (
                  <div className={classes.diagnosticOutputs}>
                    <Typography variant="body2" className={classes.bulletFont}>
                      All of
                      <b>
                        <i> Diagnostic</i>
                      </b>
                      , plus:
                    </Typography>
                  </div>
                )}
                <ul style={{ marginTop: title === 'Compare' ? '-16px' : '', marginLeft: '-40px' }}>
                  {outputs?.map((output, index) => {
                    return (
                      <ListItem key={index} className={classes.alignItemsCenter}>
                        <ListItemIcon>
                          <DoneIcon className={classes.checkIcon} />
                        </ListItemIcon>
                        <div
                          onClick={() => {
                            handleOutputClicked(output);
                          }}>
                          <Typography variant="h5" className={classes.output}>
                            {output}
                          </Typography>
                        </div>
                      </ListItem>
                    );
                  })}
                </ul>
              </Grid>

              <Box pt={2} />

              <Grid
                container
                alignItems="center"
                spacing={2}
                direction="column"
                className={classes.creditsSection}>
                <Grid item className={classes.textAlignCenter}>
                  <Typography variant="h5">
                    <b>2</b>
                  </Typography>
                  <Typography variant="body2" className={classes.bulletFont}>
                    credits {title === 'Compare' && ' per page'}
                  </Typography>
                </Grid>
                <Grid item className={classes.textAlignCenter}>
                  <Typography variant="h5">
                    <b>{numberOfCredits}</b>
                  </Typography>
                  <Typography variant="body2" className={classes.bulletFont}>
                    {numberOfCredits > 1 ? 'credits' : 'credit'}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      {isSelected && (
        <Grid item>
          <Button variant="contained" className={classes.startButton} onClick={onStartClick}>
            <Typography className={classes.subTitleFont}>
              {isLoading && <CircularProgress size={16} sx={{ mr: 1, color: 'white' }} />}
              <b>START</b>
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

SelectableToolCard.propTypes = {
  handleOutputClicked: PropTypes.func,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  testTypes: PropTypes.string,
  tool: PropTypes.string,
  effort: PropTypes.string.isRequired,
  participants: PropTypes.string.isRequired,
  goals: PropTypes.array.isRequired,
  outputs: PropTypes.array.isRequired,
  isSelected: PropTypes.bool.isRequired,
  setToolSelected: PropTypes.func,
  onStartClick: PropTypes.func,
};
export default SelectableToolCard;
