import { Box } from '@mui/material';
import { ValidationErrorNotice } from '../../../../components/Notice';
import {
  AttributeCustomizationComponentType,
  AttributeIdToDisplayName,
  SegmentDisplayType,
  SliderMarks,
} from '../../constants';
import { serializeErrors } from '../../helpers/validation';
import AttributeQuotasTable from './AttributeQuotasTable';
import RangeSlider from './RangeSlider';

const AttributeQuotas = ({
  attributeId,
  segments,
  displayType,
  onCheckboxChange,
  onRangeChange,
  onQuotaChange,
  errors,
}) => {
  const label = AttributeIdToDisplayName[attributeId];

  return (
    <Box>
      {displayType === SegmentDisplayType.Slider && (
        <RangeSlider
          label={label}
          segments={segments}
          marks={SliderMarks[attributeId]}
          onChange={onRangeChange}
        />
      )}
      <AttributeQuotasTable
        attributeId={attributeId}
        attributeSegments={segments}
        includeCheckbox={AttributeCustomizationComponentType[attributeId] === SegmentDisplayType.Checkbox}
        includeTotal={true}
        onCheckboxChange={onCheckboxChange}
        onQuotaChange={onQuotaChange}
      />
      {errors?.[attributeId]?.length > 0 && (
        <Box my={1}>
          <ValidationErrorNotice message={serializeErrors(errors[attributeId])} />
        </Box>
      )}
    </Box>
  );
};

export default AttributeQuotas;
