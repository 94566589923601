import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, CircularProgress, Grid, Paper } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as PencilIcon } from '../../../src/assets/pencil.svg';
import {
  AutomatedInsightSectionTabToFeatureKey,
  AutomatedInsightSectionToDisplayName,
  AutomatedInsightSections,
  AutomatedInsightSessionType,
  ClusterTypes,
  MainContainerDimensions,
  ProgressKeys,
  QueryParams,
  SentimentTypes,
  TabNameToSectionId,
  TaskStatus,
  TaskType,
} from '../../modules/automated-insights/constants';
import {
  canUseAutomatedInsightSessions,
  getProgressKeysAfter,
  isAutomatedInsightSessionShareLink,
  pointsToSentiment,
} from '../../modules/automated-insights/helpers';
import { isAuthenticated } from '../../modules/user/helpers';
import { getUserCustomizations, getUserProfile, getUserTeamId } from '../../modules/user/selectors';
import { snackbar } from '../../notifications';
import { Paths } from '../../routes';
import theme from '../../theme';
import { TrackEvent, useTrackPageLoad } from '../analytics';
import Carousel from '../reports/components/Carousel';
import ChatDrawer from './ChatDrawer';
import { FeedbackButton } from './FeedbackButton';
import ImagePlaceholder from './ImagePlaceholder';
import Insights from './Insights';
import LinkedSessionsDropdown from './LinkedSessionsDropdown';
import RoundedBottomBar from './RoundedBottomBar';
import SentimentMap from './SentimentMap';
import SessionAssetGrid from './SessionAssetGrid';
import SessionDetailsDialog from './SessionDetailsDialog';
import SessionMenu from './SessionMenu';
import ShareDialog from './ShareDialog';
import useFetchSession from './hooks/useFetchSession';
import useGenerateInsights from './hooks/useGenerateInsights';
import useUpdateSessionDetails from './hooks/useUpdateSessionDetails';
import { TitleTextField } from './ui/TextField';

const PENDO_PULSE_NPS_GUIDE_ID = 'CRtDaDLrMAuwMZTxRSH5mJ5AG3o';
const NPS_KEY = 'NPSdisplayed';

const SectionIdToTabName = Object.keys(TabNameToSectionId).reduce((acc, tabName) => {
  acc[TabNameToSectionId[tabName]] = tabName;
  return acc;
}, {});

function getAssetURL(asset) {
  return asset?.image?.url;
}
function isSessionPopulated(session) {
  const assets =
    session?.type === AutomatedInsightSessionType.Compare
      ? (session?.linkedSessions ?? []).flatMap((linkedSession) => linkedSession?.assets ?? [])
      : session?.assets;

  return assets && assets.length > 0 && assets.every((asset) => !!getAssetURL(asset));
}

function SessionAssetCarousel({
  session,
  currentAsset,
  onSelection,
  itemHeight = 130,
  numItemsDisplayed = 4,
}) {
  const assets = useMemo(() => session?.assets ?? [], [session]);

  const handleSelection = ({ index }) => {
    onSelection(index === 0 ? null : assets[index], index - 1);
  };

  const selectedItemIndex = useMemo(() => {
    // to get the current asset index, we need to take into account that the  "overview" pane is the item at index 0
    // and remap the asset indices so that they are 1-based
    return currentAsset ? assets.map((asset) => asset.id).indexOf(currentAsset.id) + 1 : 0;
  }, [assets, currentAsset]);

  const carouselItems = useMemo(() => {
    // since we need to include an overview tile, we can't pass assets directly;
    // instead we pass the components and remap indices so that consumers of the component
    // can handle it as if they were just dealing with assets
    return [
      <SessionAssetGrid session={session} baseHeight={`${itemHeight}px`} sx={{ background: 'white' }} />,
    ].concat(assets.map((asset) => <img src={getAssetURL(asset)} alt="page" style={{ width: '100%' }} />));
  }, [assets, itemHeight, session]);

  return (
    <Carousel
      selectedItemIndex={selectedItemIndex}
      items={assets}
      itemComponent={({ index }) => carouselItems[index]}
      onSelection={handleSelection}
      itemHeight={itemHeight}
      numItemsDisplayed={numItemsDisplayed}
      containerStyles={{ backgroundColor: 'white', borderRadius: 4 }}
    />
  );
}

function InsightsReportAssets({
  session,
  selectedAssetIndex,
  onChange,
  showCarousel = false,
  openChat,
  setOpenChat,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClick = useCallback(() => {
    document.body.style.overflow = isOpen ? '' : 'hidden';
    setIsOpen((isOpen) => !isOpen);
  }, [setIsOpen, isOpen]);

  const handleOnClickArrows = useCallback(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      setOpenChat(false);
    },
    [setOpenChat]
  );

  const assets = useMemo(() => {
    if (session?.type === AutomatedInsightSessionType.Compare) {
      return (session?.linkedSessions ?? []).map((linkedSession) => linkedSession?.assets?.[0] ?? []);
    }

    return session?.assets ?? [];
  }, [session]);

  const height = useMemo(
    () => (showCarousel ? 'calc(100vh - 210px - 130px)' : MainContainerDimensions.Height),
    [showCarousel]
  );

  const selectedAsset = useMemo(() => {
    if (
      _.isNil(selectedAssetIndex) &&
      [AutomatedInsightSessionType.Compare, AutomatedInsightSessionType.Experience].includes(session?.type) &&
      assets?.length > 1
    ) {
      return (
        <>
          {openChat && (
            <>
              <Grid
                container
                justifyContent={'flex-end'}
                sx={{
                  backgroundColor: 'black',
                  height: height,
                  minHeight: 530,
                  borderRadius: '20px',
                  marginLeft: -2,
                  position: 'absolute',
                  opacity: 0.5,
                }}
              />
              <Grid
                container
                justifyContent={'flex-end'}
                sx={{
                  height: height,
                  minHeight: 530,
                  borderRadius: '20px',
                  marginLeft: -2,
                  position: 'absolute',
                }}>
                <Grid item sx={{ height: '100%', alignContent: 'center' }}>
                  <Button
                    onClick={openChat ? handleOnClickArrows : () => {}}
                    style={{ height: '100%', borderRadius: '20px' }}>
                    <KeyboardDoubleArrowRightRoundedIcon sx={{ fontSize: '36px' }} />
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          <Box
            sx={{ height: height, minHeight: 530, overflow: 'auto', background: 'primary' }}
            className="transparentScrollBar">
            <SessionAssetGrid
              session={session}
              baseHeight={showCarousel ? 'calc(100vh - 160px - 160px)' : 'calc(100vh - 160px - 40px)'}
              openChat={openChat}
              sx={{ height: 'inherit' }}
            />
          </Box>
        </>
      );
    }

    return (
      <>
        {openChat && (
          <>
            <Grid
              container
              justifyContent={'flex-end'}
              sx={{
                display: { xs: 'none', md: 'flex' },
                backgroundColor: 'black',
                height: height,
                minHeight: 530,
                borderRadius: '20px',
                marginLeft: -2,
                position: 'absolute',
                opacity: 0.5,
              }}
            />
            <Grid
              container
              justifyContent={'flex-end'}
              sx={{
                display: { xs: 'none', md: 'flex' },
                height: height,
                minHeight: 530,
                borderRadius: '20px',
                marginLeft: -2,
                position: 'absolute',
              }}>
              <Grid item sx={{ height: '100%', alignContent: 'center' }}>
                <Button
                  onClick={openChat ? handleOnClickArrows : () => {}}
                  style={{ height: '100%', borderRadius: '20px' }}>
                  <KeyboardDoubleArrowRightRoundedIcon sx={{ fontSize: '36px' }} />
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        <Box
          sx={{
            height: height,
            minHeight: 530,
            overflow: 'auto',
            lineHeight: 0, //removes gap at the bottom of the image
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              display: 'none', // chrome and safari
            },
            msOverflowStyle: 'none', // IE and edge
            scrollbarWidth: 'none', // firefox
          }}>
          <img
            src={getAssetURL(assets?.[selectedAssetIndex || 0])}
            alt="page"
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={openChat ? () => {} : handleOnClick}
          />
        </Box>
        {isOpen && (
          <Lightbox
            mainSrc={getAssetURL(assets?.[selectedAssetIndex || 0])}
            onCloseRequest={handleOnClick}
            reactModalStyle={{ overlay: { zIndex: 9000 } }}
          />
        )}
      </>
    );
  }, [
    assets,
    height,
    selectedAssetIndex,
    session,
    showCarousel,
    isOpen,
    openChat,
    handleOnClick,
    handleOnClickArrows,
  ]);

  return (
    <Paper elevation={0} sx={{ borderRadius: '20px', overflow: 'hidden' }}>
      {showCarousel && (
        <SessionAssetCarousel
          currentAsset={assets[selectedAssetIndex]}
          session={session}
          onSelection={onChange}
        />
      )}
      {selectedAsset}
    </Paper>
  );
}

const InsightsReport = ({
  showShareDialog,
  onToggleShareDialog,
  onToggleInviteColleagueDialog,
  showAudienceList,
  onShowAudienceList,
  isAudienceDisabled,
  setIsAudienceDisabled,
  previousSessions,
}) => {
  const location = useLocation();
  const sessionDetails = location?.state?.sessionDetails;
  const { sessionId } = useParams();
  const { track } = useAnalytics();
  const history = useHistory();

  const isShareLink = isAutomatedInsightSessionShareLink(sessionId);

  const user = useSelector(getUserProfile);
  const userTeamId = useSelector(getUserTeamId);
  const userCustomizations = useSelector(getUserCustomizations);

  const showExperienceQuant = useMemo(
    () => userCustomizations?.automatedInsights?.experienceQuant !== false,
    [userCustomizations]
  );

  const [isEditable, setIsEditable] = useState(false);
  const [session, setSession] = useState(null);
  const [sessionSegments, setSessionSegments] = useState([]);
  const [isEditingName, setIsEditingName] = useState(false);
  const [sessionName, setSessionName] = useState(sessionDetails?.name || 'Untitled');
  const [taskStatus, setTaskStatus] = useState(null);
  const [previousTaskProgress, setPreviousTaskProgress] = useState({});
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedQuantTheme, setSelectedQuantTheme] = useState(null);
  const [selectedClusters, setSelectedClusters] = useState([
    ClusterTypes.Likes,
    ClusterTypes.Dislikes,
    ClusterTypes.Contentious,
  ]);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [isSessionValid, setIsSessionValid] = useState(true);
  const [selectedAssetIndex, setSelectedAssetIndex] = useState(null);
  const [showInsightsNotification, setShowInsightsNotification] = useState(false);
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  const [isCustomizingSegment, setIsCustomizingSegment] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [hasConfirmedPersona, setHasConfirmedPersona] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [urls, setUrls] = useState([]);

  const showQuant = useMemo(() => {
    const hasQuantEnabled = userCustomizations?.automatedInsights?.quant !== false;
    const isExperienceSession = [AutomatedInsightSessionType.Experience].includes(session?.type);
    const hasExperienceQuantEnabled = showExperienceQuant && isExperienceSession;
    return hasQuantEnabled && (!isExperienceSession || hasExperienceQuantEnabled);
  }, [userCustomizations, session, showExperienceQuant]);

  const shouldShowNps = useMemo(() => {
    const NPSDisplayed = JSON.parse(localStorage.getItem(NPS_KEY));

    // If user hasn't been loaded yet, we can't determine if we should show the NPS guide
    if (!user?.id) {
      return;
    }

    if (NPSDisplayed?.[user?.id]?.[PENDO_PULSE_NPS_GUIDE_ID]) {
      window?.pendo?.onGuideDismissed(PENDO_PULSE_NPS_GUIDE_ID);
      return false;
    }

    const sessionCompletedAt = session?.data?.[0]?.completedAt;
    if (!sessionCompletedAt) {
      return false;
    }

    return Boolean(previousSessions?.length === 1 && previousSessions?.[0]?.id === session?.id);
  }, [previousSessions, session, user?.id]);

  const sessionRef = useRef(null);

  const queryClient = useQueryClient();

  const { mutate: generateInsights } = useGenerateInsights();

  // We are hiding the content if the feature is not enabled for the current session.
  // If the session's type is experience, we are not hiding the content for the "sentiment map", "persona intent" and
  // "score" tabs (since these features are currently not available for experience), and we only display a "coming soon" message for these tabs.
  const hideReportContent = useMemo(() => {
    const selectedTabName = AutomatedInsightSectionTabToFeatureKey[selectedTab];

    const hasFeature =
      selectedTabName === AutomatedInsightSectionToDisplayName.Audience ||
      session?.features?.[selectedTabName] === true;

    const isExperience = [AutomatedInsightSessionType.Experience].includes(session?.type);
    const blockedExperienceSections = showExperienceQuant
      ? [AutomatedInsightSections.SentimentMap, AutomatedInsightSections.PersonaIntent]
      : [
          AutomatedInsightSections.SentimentMap,
          AutomatedInsightSections.PersonaIntent,
          AutomatedInsightSections.Quant,
        ];

    if (isExperience && blockedExperienceSections.includes(selectedTab)) {
      return false;
    }

    return !hasFeature;
  }, [session, selectedTab, showExperienceQuant]);

  if (!_.isEmpty(previousSessions) && sessionId) {
    const filteredSession = previousSessions?.filter((previousSession) => previousSession.id === sessionId);
    if (filteredSession?.[0]?.sessionUrls?.length && filteredSession?.[0]?.sessionUrls !== urls) {
      setUrls(filteredSession?.[0]?.sessionUrls);
    }
  }

  // Function to update NPS status
  const updateNPSstatus = useCallback(
    (pendoID) => {
      const NPSDisplayed = JSON.parse(localStorage.getItem(NPS_KEY)) || {};

      const updatedStatus = {
        ...NPSDisplayed,
        [user?.id]: {
          [pendoID]: true,
        },
      };

      // Update the local storage with the new NPS status
      localStorage.setItem(NPS_KEY, JSON.stringify(updatedStatus));
    },
    [user?.id]
  );

  const handleOnClickArrows = useCallback(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      setOpenChat(false);
    },
    [setOpenChat]
  );

  useEffect(() => {
    if (shouldShowNps) {
      setTimeout(() => {
        window?.pendo?.showGuideById(PENDO_PULSE_NPS_GUIDE_ID);
        updateNPSstatus(PENDO_PULSE_NPS_GUIDE_ID);
      }, 120000);
    }
  }, [shouldShowNps, updateNPSstatus]);

  // reset state when the session changes
  useEffect(() => {
    if (session && session?.id === sessionId) {
      return;
    }

    setSession(null);
    setSessionSegments([]);
    setTaskStatus(null);
    setPreviousTaskProgress({});
    setSelectedTab(null);
    setSelectedQuantTheme(null);
    // If there are no Contentious quotes, we will remove it once we've seen the heatmap data
    setSelectedClusters([ClusterTypes.Likes, ClusterTypes.Dislikes, ClusterTypes.Contentious]);
    setSelectedQuote(null);
    setSelectedLocation(null);
    setSelectedSegment(null);
    onShowAudienceList(false);
    setIsSessionValid(true);
    setSelectedAssetIndex(null);
    setOpenChat(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    const eventToTrack = isShareLink
      ? TrackEvent.VIEWED_PULSE_WITH_SHARE_LINK
      : TrackEvent.VIEWED_PULSE_AS_USER;
    track(eventToTrack, { sessionId });
  }, [sessionId, isShareLink, track]);

  useEffect(() => {
    if (showAudienceList) {
      setSelectedTab(AutomatedInsightSections.MyAudience);
      setSelectedQuantTheme(null);
    }
  }, [showAudienceList]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      const eventData = {
        sessionId: session?.id,
        segmentId: selectedSegment?.id,
        status: taskStatus,
        hasNotification: showInsightsNotification,
      };

      if (document.hidden) {
        track(TrackEvent.PULSE_USER_LEFT_SESSION_WINDOW, eventData);
      } else {
        track(TrackEvent.PULSE_USER_RETURNED_SESSION_WINDOW, eventData);
      }

      if (!document.hidden && showInsightsNotification) {
        changeFavicon('/favicon.ico');
        setShowInsightsNotification(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [showInsightsNotification, taskStatus, track, session, selectedSegment]);

  useEffect(() => {
    /**
     * This useEffect hook synchronizes the component state with the serialized state in the query string for the page.
     * Note that after the first page load, this hook watches for state changes and writes those changes to the query string of the URL.
     * This means that the component state is the source of truth, not the query params.
     *
     * The only time query params should be used to set the state of the component is on initial load,
     * where session goes from null to populated.
     *
     * To get around stale data and unintended rerenders, the query params are read from and pushed to the window object's location and history
     * directly instead of using the react router
     **/

    // If there is no session yet associated to the component, then the component is being loaded for the session for the first time.
    // We do not want to overwrite the query parameters for the initial load since we need them to be availbe to restore the component state
    if (!session) {
      return;
    }

    // otherwise, start observing state changes and stuff them into the URL
    const newParams = new URLSearchParams(window.location.search);

    selectedSegment && !selectedSegment?.isGenerated
      ? newParams.set(QueryParams.SegmentId, selectedSegment?.id)
      : newParams.delete(QueryParams.SegmentId);

    selectedTab && TabNameToSectionId?.[selectedTab]
      ? newParams.set(QueryParams.Section, TabNameToSectionId[selectedTab])
      : newParams.delete(QueryParams.Section);

    openChat ? newParams.set(QueryParams.ChatOpen, openChat) : newParams.delete(QueryParams.ChatOpen);

    window.history.replaceState({}, '', `${location.pathname}?${newParams.toString()}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, selectedSegment, selectedTab, openChat]);

  useTrackPageLoad({
    name: TrackEvent.VIEWED_PULSE_SESSION_INSIGHTS,
    properties: { sessionId },
  });

  const ownerUserId = String(session?.userId);

  const toggleSessionMenu = () => {
    setOpenMenu((openMenu) => !openMenu);
  };

  const toggleDetailsDialog = () => {
    setOpenDetailsDialog((openDetailsDialog) => !openDetailsDialog);
  };

  const handleDetailsButtonClick = () => {
    track(TrackEvent.CLICKED_PULSE_DETAILS_BUTTON, { sessionId });
    toggleSessionMenu();
    toggleDetailsDialog();
  };

  const handleShareButtonClick = () => {
    track(TrackEvent.CLICKED_PULSE_SHARE_BUTTON, { sessionId });
    toggleSessionMenu();
    onToggleShareDialog(true);
  };

  const changeFavicon = (faviconPath) => {
    const faviconLink = document.querySelector("link[rel*='icon']");
    if (faviconLink) {
      faviconLink.href = faviconPath;
    }
  };

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const querySegmentId = useMemo(() => queryParams.get(QueryParams.SegmentId) || null, [queryParams]);

  const selectedSegmentIsGenerated = useMemo(() => {
    if (querySegmentId) {
      return false;
    }
    const isGenerated = selectedSegment?.isGenerated || _.isNil(selectedSegment);
    return isGenerated;
  }, [selectedSegment, querySegmentId]);

  const segmentToResultMap = useMemo(() => {
    const segmentResults = (session?.data ?? []).filter(
      (result) =>
        result.automatedInsightSessionId && !result.automatedInsightSessionAssetId && result.segmentId
    );

    const mapping = segmentResults?.reduce((acc, segment) => {
      acc[segment?.segmentId] = {
        allTags: segment?.allTags,
        benchmarks: segment?.benchmarks,
        findings: segment?.findings,
        heatmap: segment?.heatmap,
        heatmapThemes: segment?.heatmapThemes,
        automatedInsightSessionGoalId: segment?.automatedInsightSessionGoalId,
        personaIntent: segment?.personaIntent,
        recommendedExperiments: segment?.recommendedExperiments,
        quant: segment?.quant,
        themedFindings: segment?.themedFindings,
      };
      return acc;
    }, {});
    // session data does not include the segment id for the proposed segment results so we'll need to find the id and add it
    const proposedSegment = (sessionSegments ?? []).find((segment) => segment?.isGenerated)?.id;
    const proposedSegmentResults = (session?.data ?? []).find(
      (result) =>
        result.automatedInsightSessionId && !result.automatedInsightSessionAssetId && result.segmentId === null
    );

    if (proposedSegmentResults) {
      mapping[proposedSegment] = {
        allTags: proposedSegmentResults?.allTags,
        automatedInsightSessionGoalId: proposedSegmentResults?.automatedInsightSessionGoalId,
        benchmarks: proposedSegmentResults?.benchmarks,
        findings: proposedSegmentResults?.findings,
        heatmap: proposedSegmentResults?.heatmap,
        heatmapThemes: proposedSegmentResults?.heatmapThemes,
        personaIntent: proposedSegmentResults?.personaIntent,
        recommendedExperiments: proposedSegmentResults?.recommendedExperiments,
        quant: proposedSegmentResults?.quant,
        themedFindings: proposedSegmentResults?.themedFindings,
      };
    }

    return mapping || {};
  }, [session?.data, sessionSegments]);

  const { data: sessionAndTasks, isFetching } = useFetchSession(
    {
      sessionId,
      segmentId: !selectedSegment || selectedSegment?.isGenerated ? null : selectedSegment?.id,
    },
    {
      onSuccess: (data) => {
        const automatedInsightSession = data?.automatedInsightSession;
        const segments = automatedInsightSession?.segments;
        const name = automatedInsightSession?.name;

        // handling re-routing of unlaunched experience and compare sessions back to the appropriate step
        // this is not required for single page experiences because they don't have additional setup

        const isExperience = data?.automatedInsightSession?.type === AutomatedInsightSessionType.Experience;
        const isCompare = data?.automatedInsightSession?.type === AutomatedInsightSessionType.Compare;
        const alreadyLaunched = Object.values(data?.tasks).filter((task) => !!task.status)?.length > 0;

        if (isExperience && !alreadyLaunched) {
          const assets = data?.automatedInsightSession?.assets ?? [];
          const isPopulated = assets.length >= 1 && assets.every((asset) => !!asset.image);

          if (isPopulated) {
            history.replace({
              pathname: generatePath(Paths.automatedInsights.sessionSettings, {
                sessionId,
              }),
            });
          } else {
            history.replace({
              pathname: generatePath(Paths.automatedInsights.sessionAssets, {
                sessionId,
              }),
            });
          }
        }

        if (isCompare && !alreadyLaunched) {
          history.replace({
            pathname: generatePath(Paths.automatedInsights.sessionSettings, {
              sessionId,
            }),
          });
        }

        let querySegment = null;

        // set states when fetching a new session
        if (!session) {
          setSession(automatedInsightSession);

          // set the segment from the URL if possible to do so
          const sessionSegment = automatedInsightSession?.segments?.find(
            (segment) => segment.id === querySegmentId
          );

          if (querySegmentId && sessionSegment) {
            setSelectedSegment(sessionSegment);
            querySegment = sessionSegment;
          }
        }

        if (!session || (sessionName !== name && !isEditingName)) {
          setSessionName(name);
        }

        if (!session || !_.isEqual(sessionSegments, segments)) {
          setSessionSegments(segments);
        }

        // if there isn't a segment selected and we didn't get a valid one from a query parameter,
        // we will set the first segment as the currently selected one
        if (_.isNil(selectedSegment) && !querySegment) {
          setSelectedSegment(segments?.[0]);
        }

        // if a session with a custom persona is generated or it's a compare pulse or there are key findings,
        // the proposed persona does not need to be confirmed by the user
        if (!selectedSegmentIsGenerated || isCompare || keyFindings) {
          setHasConfirmedPersona(true);
        }

        let taskData;
        if (selectedSegmentIsGenerated) {
          taskData = Array.isArray(data) ? data?.tasks?.[0] : data?.tasks?.[TaskType.AutomatedInsightSession];
        } else if (selectedSegment || querySegmentId) {
          taskData = Array.isArray(data)
            ? data?.tasks?.[0]
            : data?.tasks?.[TaskType.AutomatedInsightSessionAudience];
        }

        setIsSessionValid(!_.isNil(taskData) && taskData?.status !== TaskStatus.Failed);

        const status = taskData?.status;
        const sessionHasKeyFindings =
          !!automatedInsightSession?.results?.findings ||
          automatedInsightSession?.segmentResults?.some((result) => !!result.findings);

        // disable personas button in app bar until there are key findings (for any segment) or for compares when the task has started
        if (
          (isAudienceDisabled && sessionHasKeyFindings) ||
          (automatedInsightSession?.type === AutomatedInsightSessionType.Compare &&
            [TaskStatus.Enqueued, TaskStatus.Running, TaskStatus.Completed, TaskStatus.Failed].includes(
              status
            ))
        ) {
          setIsAudienceDisabled(false);
        } else if (!isAudienceDisabled && !sessionHasKeyFindings) {
          setIsAudienceDisabled(true);
        }

        const taskId = taskData?.id;
        const progressKey = taskData?.progressKey;
        const previousProgressKey = previousTaskProgress[taskId];

        if (status !== taskStatus) {
          if (
            ((taskStatus === TaskStatus.Running && status === TaskStatus.Completed) ||
              status === TaskStatus.Failed) &&
            document.hidden
          ) {
            changeFavicon('/notificationIcon.ico');
            setShowInsightsNotification(true);
          }
          setTaskStatus(status);

          if (session?.type === AutomatedInsightSessionType.Compare) {
            // for compares which have linked sessions, when the status changes
            // we need to refresh the session state so that anything that depends on linked sessions have the latest info
            setSession(automatedInsightSession);

            // communicate changes to other components like app bar that may need to refresh session data
            queryClient.setQueryData(
              [
                'automatedInsightSession',
                {
                  sessionId: data.automatedInsightSession.id,
                  // purposely use chain operator since undefined will be excluded from the object, and react-query is
                  // fairly particular about this w.r.t. cache keys
                  segmentId: selectedSegment?.id,
                },
              ],
              data
            );
          }

          // show the report section from the URL if possible to do so or key findings for previously generated pulses
          // but if the user selects another segment to view its insights and selects a tab, do nothing because the tab will not be null
          if (status === TaskStatus.Completed && taskStatus !== TaskStatus.Running) {
            const querySection = queryParams.get(QueryParams.Section);
            // completed pulses will not ask users to confirm if the proposed persona is correct
            if (!hasConfirmedPersona) {
              setHasConfirmedPersona(true);
            }

            // n.b. we guard on session being set to ensure we only restore section state the first time the page loads
            if (querySection && SectionIdToTabName?.[querySection] && !session) {
              setSelectedTab(SectionIdToTabName?.[querySection]);
              setSelectedQuantTheme(null);
            } else if (selectedTab === null) {
              // show key findings when loading a completed compare session
              if (isCompare) {
                setSelectedTab(AutomatedInsightSections.KeyFindings);
              } else {
                setSelectedTab(AutomatedInsightSections.Audience);
              }
              setSelectedQuantTheme(null);
            }

            const queryChat = queryParams.get(QueryParams.ChatOpen);
            const isChatOpen = queryChat === 'true' || queryChat === true;
            if (isChatOpen) {
              setOpenChat(isChatOpen);
              track(TrackEvent.VIEWED_PULSE_CHAT, { sessionId: data.automatedInsightSession.id });
            }
          }
        }

        if (
          // while insights are getting ready to be generated or are generating, the selected tab should be null in order to display the loading screen
          // but if the user is viewing anything persona-related, don't switch the tab to null
          ([TaskStatus.Pending, TaskStatus.Enqueued].includes(status) ||
            (status === TaskStatus.Running &&
              [ProgressKeys.Initialized, ProgressKeys.TextExtract, ProgressKeys.TargetAudience].includes(
                progressKey
              ))) &&
          taskStatus !== TaskStatus.Failed
        ) {
          if (isCompare) {
            setSelectedTab(null);
          } else if (
            selectedTab !== null &&
            ![
              AutomatedInsightSections.MyAudience, // My Personas
              AutomatedInsightSections.Audience, // Personas rendered while key findings are generating
            ].includes(selectedTab)
          ) {
            setSelectedTab(null);
            setSelectedQuantTheme(null);
          }
        } else if (
          // show the Persona section while key findings or experience goal are generating after the page is refreshed or after switching sessions
          status === TaskStatus.Running &&
          [ProgressKeys.Insights, ProgressKeys.Goal].includes(progressKey) &&
          selectedTab === null
        ) {
          // show loading screen if it's a compare
          if (automatedInsightSession?.type === AutomatedInsightSessionType.Compare) {
            setSelectedQuantTheme(null);
          } else {
            setSelectedTab(AutomatedInsightSections.Audience);
            setSelectedQuantTheme(null);
          }
        } else if (
          //  show Key Findings section when we move onto generating heatmap points (or recommended experiments if concurrent steps) after the page is refreshed or after switching sessions
          status === TaskStatus.Running &&
          getProgressKeysAfter(AutomatedInsightSessionType.Page, ProgressKeys.Insights).includes(
            progressKey
          ) &&
          selectedTab === null
        ) {
          setSelectedTab(AutomatedInsightSections.KeyFindings);
          setSelectedQuantTheme(null);
        }

        if (!previousProgressKey || progressKey !== previousProgressKey) {
          if (
            // show the Persona section after the target audience finishes generating or show the My Personas section for compares
            [ProgressKeys.Initialized, ProgressKeys.TextExtract, ProgressKeys.TargetAudience].includes(
              previousProgressKey
            ) &&
            [ProgressKeys.Goal, ProgressKeys.Insights].includes(progressKey) &&
            selectedTab !== AutomatedInsightSections.Audience &&
            automatedInsightSession?.type !== AutomatedInsightSessionType.Compare
          ) {
            setSelectedTab(AutomatedInsightSections.Audience);
            setSelectedQuantTheme(null);
          } else if (
            // show Key Findings section when heatmap points are generating, or when the task finishes for experiences
            // check all progress keys prior to heatmap points because the previousProgressKey will not update unless the segment is selected
            [
              ProgressKeys.Initialized,
              ProgressKeys.TextExtract,
              ProgressKeys.TargetAudience,
              ProgressKeys.Insights,
            ].includes(previousProgressKey) &&
            getProgressKeysAfter(data?.automatedInsightSession?.type, ProgressKeys.Insights).includes(
              progressKey
            )
          ) {
            setShowSplashScreen(true);
            // if key findings are finished generating and the user is not currently editing/creating a new persona, show the key findings
            if (!isCustomizingSegment) {
              setSelectedTab(AutomatedInsightSections.KeyFindings);
              setSelectedQuantTheme(null);
              onShowAudienceList(false);
            }
          }
          setPreviousTaskProgress((prevState) => ({ ...prevState, [taskId]: progressKey }));
        }

        // when findings, heatmap, persona intent, or audience is finished generating,
        // we will update the state of session with the current session data
        if (selectedSegmentIsGenerated) {
          if (
            (progressKey === ProgressKeys.HeatmapPoints && !keyFindings) ||
            (progressKey === ProgressKeys.PersonaIntent && _.isEmpty(heatmapData)) ||
            (progressKey === ProgressKeys.RecommendedExperiments && _.isEmpty(personaIntentData)) ||
            (progressKey === ProgressKeys.Done && _.isEmpty(recommendedExperimentsData)) ||
            (progressKey === ProgressKeys.Done && _.isEmpty(quantData)) ||
            (progressKey === ProgressKeys.Insights && _.isEmpty(session?.segments))
          ) {
            setSession(automatedInsightSession);

            // force a refresh of chat state in any case where we know we got new data
            queryClient.invalidateQueries([
              'automatedInsightSessionChatMessages',
              { sessionId, segmentId: selectedSegment?.id },
            ]);
          }
        }
        // when findings, persona intent, or heatmap is finished generating with the new segment,
        // we will update the state of session with the current session data
        if (!selectedSegmentIsGenerated) {
          if (
            (progressKey === ProgressKeys.HeatmapPoints &&
              !segmentToResultMap[selectedSegment?.id]?.findings) ||
            (progressKey === ProgressKeys.PersonaIntent &&
              _.isEmpty(segmentToResultMap[selectedSegment?.id]?.heatmap)) ||
            (progressKey === ProgressKeys.RecommendedExperiments &&
              _.isEmpty(segmentToResultMap[selectedSegment?.id]?.personaIntent)) ||
            (progressKey === ProgressKeys.Done &&
              _.isEmpty(segmentToResultMap[selectedSegment?.id]?.recommendedExperiments)) ||
            (progressKey === ProgressKeys.Done && _.isEmpty(segmentToResultMap[selectedSegment?.id]?.quant))
          ) {
            setSession(automatedInsightSession);

            // force a refresh of chat state in any case where we know we got new data
            queryClient.invalidateQueries([
              'automatedInsightSessionChatMessages',
              { sessionId, segmentId: selectedSegment?.id },
            ]);
          }
        }
      },
      onError: (err) => {
        if (querySegmentId && [400, 404, 500].includes(err?.response?.status)) {
          history.replace({ pathname: generatePath(Paths.automatedInsights.session, { sessionId }) });
        } else if ([400, 403, 404, 500].includes(err?.response?.status)) {
          history.replace({
            pathname: Paths.automatedInsights.basePath,
          });
        } else {
          setTaskStatus(TaskStatus.Failed);
          snackbar.error(
            err?.response?.humanReadableMessage ??
              'Something went wrong getting this Pulse. Please try again later.'
          );
        }
      },
      retry: 1,
      refetchInterval: [TaskStatus.Completed, TaskStatus.Failed].includes(taskStatus) ? false : 5000,
      refetchIntervalInBackground: true,
    }
  );

  const { mutate: updateSessionDetails } = useUpdateSessionDetails();

  const shareURL = useMemo(() => {
    if (!session?.shareUrl) {
      return '';
    }

    let shareUrl = session?.shareUrl || '';

    if ((!selectedSegment || selectedSegment?.isGenerated) && !openChat) {
      return shareUrl;
    }

    const queryParams = new URLSearchParams('');
    if (selectedSegment && !selectedSegment?.isGenerated) {
      queryParams.set(QueryParams.SegmentId, selectedSegment.id);
    }

    if (openChat) {
      queryParams.set(QueryParams.ChatOpen, openChat);
    }

    return `${shareUrl}?${queryParams.toString()}`;
  }, [session, selectedSegment, openChat]);

  const selectedResults = useMemo(() => {
    if (selectedSegmentIsGenerated) {
      return session?.data?.find(
        (result) =>
          result.automatedInsightSessionId && !result.automatedInsightSessionAssetId && !result.segmentId
      );
    } else if (segmentToResultMap[selectedSegment?.id]) {
      return segmentToResultMap[selectedSegment?.id];
    } else {
      return null;
    }
  }, [selectedSegmentIsGenerated, selectedSegment, segmentToResultMap, session?.data]);

  const keyFindings = useMemo(() => {
    return selectedResults?.findings ?? null;
  }, [selectedResults]);

  const themedFindings = useMemo(() => {
    return selectedResults?.themedFindings ?? [];
  }, [selectedResults]);

  const heatmapData = useMemo(() => {
    const heatmapPoints = selectedResults?.heatmap ?? [];

    if (session?.type === AutomatedInsightSessionType.Page) {
      return heatmapPoints;
    }

    if (session?.type === AutomatedInsightSessionType.Experience) {
      const selectedAssetId = session?.assets?.[selectedAssetIndex || 0]?.id;

      if (_.isNil(selectedAssetId)) {
        return heatmapPoints;
      }

      return heatmapPoints.filter((elem) => {
        return elem?.points?.some((point) => point?.assetId === selectedAssetId);
      });
    }

    return heatmapPoints;
  }, [selectedResults, selectedAssetIndex, session]);

  const themeData = useMemo(() => {
    return selectedResults?.heatmapThemes ?? [];
  }, [selectedResults]);

  const personaIntentData = useMemo(() => {
    return selectedResults?.personaIntent ?? [];
  }, [selectedResults]);

  const recommendedExperimentsData = useMemo(() => {
    return selectedResults?.recommendedExperiments ?? [];
  }, [selectedResults]);

  const quantData = useMemo(() => {
    return selectedResults?.quant ?? {};
  }, [selectedResults]);

  const benchmarksData = useMemo(() => {
    return selectedResults?.benchmarks ?? {};
  }, [selectedResults]);

  const allTags = useMemo(() => {
    return selectedResults?.allTags ?? {};
  }, [selectedResults]);

  const isQuantReady = useMemo(() => {
    return showQuant && !_.isEmpty(quantData) && taskStatus === TaskStatus.Completed;
  }, [quantData, showQuant, taskStatus]);

  const sessionGoal = useMemo(() => {
    // TODO: this is a hack that assumes the session has only one active goal at any given time
    // we'll probably need this to be selectable at time of viewing
    return session?.goals.find((goal) => goal.isSelected);
  }, [session]);

  const sessionDirective = useMemo(() => {
    return session?.directive;
  }, [session]);

  const hasContentiousQuotes = useMemo(
    () =>
      heatmapData?.some((item) =>
        item?.points?.some((point) => point?.sentiment === SentimentTypes.Contentious)
      ),
    [heatmapData]
  );

  const selectedClustersList = useMemo(() => {
    if (selectedClusters?.includes(ClusterTypes.Contentious) && !hasContentiousQuotes) {
      return selectedClusters?.filter((cluster) => cluster !== ClusterTypes.Contentious);
    }
    return selectedClusters;
  }, [selectedClusters, hasContentiousQuotes]);

  const selectedSentiments = useMemo(() => {
    const selected = [];
    if (selectedClustersList.includes(ClusterTypes.Likes)) {
      selected.push(SentimentTypes.Positive);
    }
    if (selectedClustersList.includes(ClusterTypes.Dislikes)) {
      selected.push(SentimentTypes.Negative);
    }
    if (selectedClustersList.includes(ClusterTypes.Contentious)) {
      selected.push(SentimentTypes.Contentious);
    }
    return selected;
  }, [selectedClustersList]);

  const filteredData = useMemo(() => {
    return heatmapData
      ?.map((item) => {
        return {
          ...item,
          points: item?.points?.filter((point) => selectedSentiments.includes(point?.sentiment)),
          sentiment: pointsToSentiment(item?.points),
        };
      })
      .filter((item) => item.points.length > 0);
  }, [heatmapData, selectedSentiments]);

  const taskProgress = useMemo(() => {
    const tasks = sessionAndTasks?.tasks;
    if (!tasks) {
      return null;
    }

    let taskData;
    if (selectedSegmentIsGenerated) {
      taskData = tasks?.[TaskType.AutomatedInsightSession];
    } else if (selectedSegment) {
      taskData = tasks?.[TaskType.AutomatedInsightSessionAudience];
    }

    return {
      sessionId: sessionAndTasks?.automatedInsightSession?.id,
      segmentId: selectedSegment?.id,
      taskType: taskData?.type,
      progressKey: taskData?.progressKey,
      startedAt: taskData?.startedAt,
      createdAt: taskData?.createdAt,
      dependencyTasks: taskData?.dependencyTasks ?? [],
      timingEstimates: taskData?.timingEstimates,
      displayMessage: taskData?.displayMessage,
      canRetry: taskData?.canRetry,
    };
  }, [
    sessionAndTasks?.automatedInsightSession?.id,
    sessionAndTasks?.tasks,
    selectedSegment,
    selectedSegmentIsGenerated,
  ]);

  const handleTextFieldClick = () => {
    if (isShareLink || !isAuthenticated()) {
      return;
    }

    if (userTeamId === sessionDetails?.teamId || userTeamId === session?.teamId) {
      setIsEditable(true);
    } else return;
  };

  const handleChange = (e) => {
    setSessionName(e.target.value);
  };

  const handleFocus = () => {
    setIsEditingName(true);
  };

  //save new name when clicking outside of the text field
  const handleBlur = () => {
    if (isShareLink || !isAuthenticated()) {
      return;
    }

    setIsEditingName(false);
    setIsEditable(false);
    if (sessionName === '') {
      setSessionName('Untitled');
    }
    if (!isUntitled) {
      updateSessionInfo({ sessionId, name: sessionName });
    }
  };
  // save new name when enter key is pressed
  const handleKeyDown = (e) => {
    if (isShareLink || !isAuthenticated()) {
      return;
    }

    if (e.key === 'Enter') {
      setIsEditable(false);
      if (sessionName === '') {
        setSessionName('Untitled');
      }
      if (!isUntitled) {
        updateSessionInfo({ sessionId, name: sessionName });
      }
    }
  };

  const handlePersonaConfirmationClick = (isConfirmed) => {
    setHasConfirmedPersona(isConfirmed);
  };

  const handleInsightsRegeneration = useCallback(
    ({ segment, goalId, trackEventName }) => {
      if (trackEventName) {
        track(trackEventName, {
          sessionId,
        });
      }
      if (openChat) {
        setOpenChat(false);
      }
      generateInsights(
        { sessionId, segmentId: segment?.id, goalId: goalId ?? sessionGoal?.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries('automatedInsightSession');
            setSelectedSegment(segment);
            setSessionSegments([...sessionSegments, segment]);
            setSelectedTab(null);
            setSelectedQuantTheme(null);
            setTaskStatus(null);
            onShowAudienceList(false);

            if (isCustomizingSegment) {
              setIsCustomizingSegment(false);
            }
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error generating insights');
          },
        }
      );
    },
    [
      track,
      generateInsights,
      sessionGoal,
      sessionId,
      queryClient,
      sessionSegments,
      onShowAudienceList,
      isCustomizingSegment,
      openChat,
    ]
  );

  const selectTab = (tabName) => {
    if (tabName === AutomatedInsightSections.KeyFindings) {
      track(TrackEvent.VIEWED_PULSE_KEY_FINDINGS, { sessionId });
    } else if (tabName === AutomatedInsightSections.SentimentMap) {
      track(TrackEvent.VIEWED_PULSE_SENTIMENT_MAP, { sessionId });
    } else if (tabName === AutomatedInsightSections.PersonaIntent) {
      track(TrackEvent.VIEWED_PULSE_PERSONA_INTENT, { sessionId });
    } else if (tabName === AutomatedInsightSections.RecommendedExperiments) {
      track(TrackEvent.VIEWED_PULSE_RECOMMENDED_EXPERIMENTS, { sessionId });
    } else if (tabName === AutomatedInsightSections.Quant) {
      track(TrackEvent.VIEWED_PULSE_QUANT, { sessionId });
    } else if (tabName === AutomatedInsightSections.MyAudience) {
      track(TrackEvent.VIEWED_PULSE_MY_AUDIENCES, { sessionId });
    } else if (tabName === AutomatedInsightSections.Audience) {
      track(TrackEvent.VIEWED_PULSE_AUDIENCE, { sessionId });
    }
    setSelectedTab(tabName);
    setSelectedQuantTheme(null);
    setSelectedAssetIndex(null);
  };

  const toggleClusterSelections = (cluster) => {
    if (selectedClustersList.includes(cluster) && selectedClustersList.length === 1) {
      return;
    }
    let newClusters = [];
    if (selectedClustersList.includes(cluster)) {
      newClusters = selectedClustersList.filter((item) => item !== cluster);
      setSelectedClusters(newClusters);
    } else {
      newClusters = [...selectedClustersList, cluster];
      setSelectedClusters(newClusters);
    }
    track(TrackEvent.FILTERED_PULSE_QUOTES_BY_SENTIMENT, { sessionId, sentiment: newClusters });
  };

  const selectCluster = (cluster) => {
    toggleClusterSelections(cluster);
    clearSelections();
  };

  const selectQuote = (point) => {
    if (_.isEqual(point, selectedQuote)) {
      setSelectedQuote(null);
    } else {
      setSelectedQuote(point);
      setSelectedLocation(null);
    }
  };

  const clearSelections = () => {
    setSelectedQuote(null);
    setSelectedLocation(null);
  };

  const selectLocation = (location) => {
    // make sure there isn't a quote selected because we will be filtering
    // quotes by location if a location is selected on the image
    setSelectedQuote(null);
    setSelectedLocation(location);
  };

  const selectSegment = (segment) => {
    if (openChat) {
      setOpenChat(false);
    }

    setSelectedSegment(segment);
    setTaskStatus(null);

    // n.b. it seems like sometimes when we set the selected segment
    // the session data isn't refetched which results in an infinite loading screen
    // query invalidation is sort of a hammer / temporary fix and the root cause should be investigated.
    queryClient.invalidateQueries('automatedInsightSession');
    queryClient.invalidateQueries([
      'automatedInsightSessionChatMessages',
      { sessionId, segmentId: selectedSegment?.id },
    ]);
  };

  const toggleChat = (open) => {
    if (session?.features?.[AutomatedInsightSections.Chat]) {
      setOpenChat(open);
      if (open) {
        track(TrackEvent.VIEWED_PULSE_CHAT, { sessionId });
      }
    }
  };

  const updateSessionInfo = ({ sessionId, name }) => {
    updateSessionDetails(
      { sessionId, updates: { name } },
      {
        onError: (err) => {
          snackbar.error(
            err?.response?.data?.humanReadableMessage ?? 'Error updating name. Please try again.'
          );
        },
      }
    );
  };

  const isUntitled = sessionName.toLowerCase() === 'untitled' || sessionName === '';

  if (
    ((_.isNil(user) || _.isNil(userCustomizations)) && !isShareLink) ||
    // fetching a session that has not been set into state yet
    (sessionId !== session?.id && isFetching)
  ) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  const hasAutomatedInsightsFeatureEnabled = canUseAutomatedInsightSessions(user, userCustomizations);

  if (!isShareLink && !hasAutomatedInsightsFeatureEnabled) {
    return window.location.replace('/');
  }

  return (
    <Grid container spacing={2} pt={3} pb={!user?.id ? 10 : 0}>
      {[AutomatedInsightSessionType.Page, AutomatedInsightSessionType.Experience].includes(session?.type) && (
        <Grid container item alignItems="center" spacing={6}>
          <Grid item xs={12} xl={5} sx={{ marginTop: { xs: 2, md: 0 } }}>
            <TitleTextField
              sx={{ '&:hover .menu-icon': { display: 'initial' } }}
              fullWidth
              isEditable={isEditable}
              isUntitled={isUntitled}
              value={sessionName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onClick={handleTextFieldClick}
              onKeyDown={handleKeyDown}
              ref={sessionRef}
              InputProps={{
                readOnly: !isEditable,
                startAdornment: <PencilIcon style={{ height: '16px', width: '16px', fill: '#FFFFFF' }} />,
                endAdornment: (
                  <MoreVertIcon
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      toggleSessionMenu();
                    }}
                    sx={{ display: 'none', color: 'white', fontSize: 24, cursor: 'pointer' }}
                    className="menu-icon"
                  />
                ),
              }}
            />
          </Grid>
          {sessionRef?.current && (
            <SessionMenu
              id={'menu-main-session-details-share'}
              anchorEl={sessionRef.current}
              open={openMenu}
              onClose={toggleSessionMenu}
              onDetailsClick={handleDetailsButtonClick}
              onShareClick={handleShareButtonClick}
            />
          )}
          <SessionDetailsDialog
            session={session}
            urls={urls}
            goals={session?.goals}
            isOpen={openDetailsDialog || false}
            onToggleDetailsDialog={() => toggleDetailsDialog()}
          />
        </Grid>
      )}
      {[AutomatedInsightSessionType.Compare].includes(session?.type) && (
        <Grid container item alignItems="center" spacing={6} sx={{ display: 'flex' }}>
          <Grid item xs={12} sx={{ marginTop: { xs: 2, md: 0 } }}>
            <LinkedSessionsDropdown session={session} />
          </Grid>
        </Grid>
      )}
      <Grid
        container
        item
        spacing={{ xs: 3, md: 2 }}
        sx={{
          [theme.breakpoints.up('md')]: {
            flexWrap: openChat ? 'nowrap' : '',
          },
        }}>
        <Grid
          item
          xs={12}
          md={openChat ? 2 : 5}
          xl={openChat ? 2 : 5}
          pb={2}
          order={{ xs: 1, sm: 1, md: 3 }}
          position={'relative'}
          sx={{
            [theme.breakpoints.up('md')]: {
              minWidth: openChat ? '400px' : '',
              marginLeft: openChat ? '-380px' : '',
            },
          }}>
          {isSessionPopulated(session) ? (
            <>
              {selectedTab === AutomatedInsightSections.SentimentMap && heatmapData?.length > 0 ? (
                <>
                  {openChat && (
                    <>
                      <Grid
                        container
                        justifyContent={'flex-end'}
                        sx={{
                          display: { xs: 'none', md: 'flex' },

                          backgroundColor: 'black',
                          height: MainContainerDimensions.Height,
                          minHeight: MainContainerDimensions.MinHeight,
                          borderRadius: '20px',
                          marginLeft: -2,
                          position: 'absolute',
                          opacity: 0.5,
                          zIndex: 1,
                        }}
                      />
                      <Grid
                        container
                        justifyContent={'flex-end'}
                        sx={{
                          display: { xs: 'none', md: 'flex' },
                          height: MainContainerDimensions.Height,
                          minHeight: MainContainerDimensions.MinHeight,
                          borderRadius: '20px',
                          marginLeft: -2,
                          position: 'absolute',
                          zIndex: 2,
                        }}>
                        <Grid item sx={{ height: '100%', alignContent: 'center' }}>
                          <Button
                            onClick={openChat ? handleOnClickArrows : () => {}}
                            style={{ height: '100%', borderRadius: '20px' }}>
                            <KeyboardDoubleArrowRightRoundedIcon sx={{ fontSize: '36px' }} />
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Box
                    sx={{
                      height: MainContainerDimensions.Height,
                      minHeight: MainContainerDimensions.MinHeight,
                      borderRadius: '20px',
                      overflow: 'hidden',
                      lineHeight: 0, //removes gap at the bottom of the image
                    }}>
                    <SentimentMap
                      image={session?.assets?.[selectedAssetIndex || 0]?.image || session?.image}
                      data={filteredData}
                      selectedQuote={selectedQuote}
                      selectedLocation={selectedLocation}
                      onClear={clearSelections}
                      onSelection={selectLocation}
                      openChat={openChat}
                    />
                  </Box>
                </>
              ) : (
                <InsightsReportAssets
                  session={session}
                  selectedAssetIndex={selectedAssetIndex}
                  onChange={(item, index) => {
                    // if index is -1, that means the user selected on the "overview" pane for the entire experience
                    setSelectedAssetIndex(index < 0 ? null : index);
                  }}
                  openChat={openChat}
                  setOpenChat={setOpenChat}
                />
              )}
            </>
          ) : (
            <ImagePlaceholder />
          )}
        </Grid>
        <Grid item xs={12} md={openChat ? 6 : 7} xl={openChat ? 6 : 7} pb={2} order={{ xs: 0, sm: 0, md: 1 }}>
          {!session ? (
            <Paper
              elevation={0}
              sx={{
                height: MainContainerDimensions.Height,
                minHeight: MainContainerDimensions.MinHeight,
                borderRadius: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <CircularProgress color="secondary" />
            </Paper>
          ) : (
            <Paper
              elevation={0}
              sx={{
                height: MainContainerDimensions.Height,
                minHeight: MainContainerDimensions.MinHeight,
                borderRadius: '20px',
                position: 'relative',
              }}>
              <Insights
                taskProgress={taskProgress}
                taskStatus={taskStatus}
                heatmapData={heatmapData}
                themeData={themeData}
                personaIntentData={personaIntentData}
                themedFindings={themedFindings}
                keyFindings={keyFindings}
                recommendedExperimentsData={recommendedExperimentsData}
                quantData={quantData}
                sessionDirective={sessionDirective}
                sessionGoal={sessionGoal}
                session={session}
                sessionSegments={sessionSegments}
                selectedSegment={selectedSegment}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                selectedClustersList={selectedClustersList}
                hasContentiousQuotes={hasContentiousQuotes}
                selectedQuote={selectedQuote}
                selectedLocation={selectedLocation}
                segmentToResultMap={segmentToResultMap}
                onTabClick={selectTab}
                onClusterClick={selectCluster}
                onQuoteClick={selectQuote}
                onShowAudienceList={onShowAudienceList}
                onSelectSegment={selectSegment}
                onInsightsRegeneration={handleInsightsRegeneration}
                onToggleShareDialog={onToggleShareDialog}
                onToggleInviteColleagueDialog={onToggleInviteColleagueDialog}
                isSessionValid={isSessionValid}
                showSplashScreen={showSplashScreen}
                setShowSplashScreen={setShowSplashScreen}
                isCustomizingSegment={isCustomizingSegment}
                setIsCustomizingSegment={setIsCustomizingSegment}
                openChat={openChat}
                onToggleChat={toggleChat}
                showQuant={showQuant}
                setSelectedQuantTheme={setSelectedQuantTheme}
                selectedQuantTheme={selectedQuantTheme}
                isQuantReady={isQuantReady}
                hasConfirmedPersona={hasConfirmedPersona}
                onPersonaConfirmation={handlePersonaConfirmationClick}
                isShareLink={isShareLink}
                hideReportContent={hideReportContent}
                benchmarksData={benchmarksData}
                allTags={allTags}
                selectedAssetIndex={selectedAssetIndex}
                setSelectedAssetIndex={setSelectedAssetIndex}
              />
            </Paper>
          )}
        </Grid>
        {openChat && (
          <Grid
            item
            xs={12}
            md={6}
            xl={6}
            pb={2}
            order={{ xs: 0, sm: 0, md: 2 }}
            sx={{
              minHeight: MainContainerDimensions.MinHeight,
            }}>
            <ChatDrawer
              sessionId={session?.id}
              selectedSegment={selectedSegment}
              ownerUserId={ownerUserId}
              chatUserId={!!user?.id && String(user?.id)}
              open={openChat && session?.features?.[AutomatedInsightSections.Chat]}
              onClose={toggleChat}
              selectedQuantTheme={selectedQuantTheme}
              sessionType={session?.type}
              hideReportContent={hideReportContent}
              selectedTab={selectedTab}
              chatEnabled={session?.features?.[AutomatedInsightSections.Chat]}
            />
          </Grid>
        )}
      </Grid>
      <ShareDialog
        sessionId={sessionId}
        url={shareURL}
        isOpen={showShareDialog || false}
        onToggleShareDialog={onToggleShareDialog}
      />
      {!user?.id && <RoundedBottomBar />}
      <FeedbackButton />
    </Grid>
  );
};

export default InsightsReport;
