import { Box, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { ReactComponent as InfoIconFilled } from '../../../../../assets/info-icon-filled.svg';
import { getSelectedMetricName } from '../../../../../modules/intake/helpers';
import { AudienceCategory } from '../../../../../modules/wevos/constants';
import { IntakeTooltip } from '../../../components';
import { isCustomSurveyType } from '../../helpers/wevo';
import Panel from './Panel';

function generateCostEstimate({ wevo, proPricing }) {
  if (!proPricing?.supportsPricingEstimates) {
    return null;
  }

  const isCustomSurvey = isCustomSurveyType(wevo);

  const audienceBaseCreditCost =
    wevo?.audienceCategory?.id === AudienceCategory.B2B.id ? proPricing?.b2bCredits : proPricing?.b2cCredits;

  const targetN = wevo?.numTargetedRespondentsPerPage;

  const respondentMultiplier =
    proPricing?.sampleSizes?.find((sampleSize) => sampleSize.n === targetN)?.multiplier || 1.0;

  const respondentCreditCost = audienceBaseCreditCost * respondentMultiplier;

  const keyFindingsCharge = targetN < 60 && wevo?.details?.includeKeyFindings ? 0.25 : 0;

  // custom surveys only consider the page cost since there are no deep dives
  // also we enforce only one page at time of submit, so while displaying higher credit cost is possible
  // in error states, the cost must settle to the single page cost in order to submit
  if (isCustomSurvey) {
    return {
      audienceBaseCreditCost,
      basePrice: respondentCreditCost,
      keyFindingsCharge,
      additionalDeepDivesPrice: 0,
      totalCost: respondentCreditCost + keyFindingsCharge,
    };
  }

  const pageCount = (wevo?.pages ?? []).length;

  const basePrice = respondentCreditCost * pageCount;

  const stepCountTotal = (wevo?.pages ?? []).flatMap((page) => page?.steps ?? []).length;
  const sentimentMapMultiplier = proPricing?.sentimentMapMultiplier ?? 1.0;
  const stepCountLessPages = Math.max(stepCountTotal - pageCount, 0);
  const additionalDeepDivesPrice = respondentCreditCost * stepCountLessPages * sentimentMapMultiplier;

  const totalCost = basePrice + additionalDeepDivesPrice + keyFindingsCharge;

  return {
    audienceBaseCreditCost,
    basePrice,
    keyFindingsCharge,
    additionalDeepDivesPrice,
    totalCost,
  };
}

function SummaryPanel({ wevo, customQuestions, customScreeners, userCustomizations, proPricing }) {
  const costEstimate = generateCostEstimate({ wevo, proPricing });
  const hasPricingEstimates = proPricing?.supportsPricingEstimates;

  return (
    <Panel>
      <Typography fontWeight={700} fontSize={16} color="#212A37">
        Your Selections
      </Typography>
      <Box mb={2} />
      <Box display="flex" flexDirection="column" sx={{ height: '100%' }}>
        <Box sx={{ flexGrow: 1 }}>
          {generateLineItems({ wevo, customQuestions, customScreeners, userCustomizations, costEstimate })}
        </Box>
        <Box sx={{ height: 120 }}>
          <Box sx={{ height: '1px', background: '#C7D6DF', mb: 3 }} />
          {hasPricingEstimates && (
            <SubtotalLineItem
              label="Estimated price"
              value={`${costEstimate?.totalCost} credit(s)`}
              tooltipProps={{
                title: `Estimation only - Consult WEVO team for final price and delivery time.`,
                arrow: true,
                placement: 'right',
              }}
            />
          )}
        </Box>
      </Box>
    </Panel>
  );
}

function generateLineItems({ wevo, customQuestions, customScreeners, userCustomizations, costEstimate }) {
  const lineItems = [];

  if (!wevo) return lineItems;

  const hasPricingEstimates = !isNil(costEstimate);
  const isMastercard = Boolean(userCustomizations?.isMastercard);
  const isCustomSurvey = isCustomSurveyType(wevo);

  if (isMastercard) {
    const studyType = getSelectedMetricName(wevo?.metricType);
    lineItems.push(<SummaryLineItem key="study-type" label={studyType} cost="-" />);
  }

  // AUDIENCE
  const audienceLabel = wevo?.audienceCategory?.id === AudienceCategory.B2B.id ? 'Businesses' : 'Consumers';
  lineItems.push(<SummaryLineItem key="audience" label={audienceLabel} cost="-" />);

  // CUSTOM SCREENERS
  if (customScreeners?.length > 0) {
    lineItems.push(
      <SummaryLineItem
        key="custom-screeners"
        label="Custom Screeners"
        value={customScreeners?.length}
        cost="-"
      />
    );
  }

  // RESPONDENTS
  const basePriceDisplay = hasPricingEstimates ? `${costEstimate?.basePrice} credit(s)` : '-';

  const pageCount = (wevo?.pages ?? []).length;

  const pageSubItems = isCustomSurvey ? [] : [{ label: `Experiences - ${pageCount}` }];

  lineItems.push(
    <SummaryLineItem
      key="respondents"
      label="Respondents"
      value={wevo?.numTargetedRespondentsPerPage || '-'}
      cost={basePriceDisplay}
      subItems={pageSubItems}
    />
  );

  // REPORT SECTIONS
  const reportSections = [];

  if (wevo?.details?.includeSentimentMaps) {
    reportSections.push({ label: 'Sentiment Maps' });
  }

  if (wevo?.details?.includeDiagnostics) {
    reportSections.push({ label: 'Diagnostics' });
  }

  if (wevo?.details?.includeKeyFindings && costEstimate?.keyFindingsCharge) {
    reportSections.push({ label: `Key Findings - ${costEstimate.keyFindingsCharge}` });
  } else if (wevo?.details?.includeKeyFindings) {
    reportSections.push({ label: 'Key Findings' });
  }

  if (wevo?.details?.includeExpectations && wevo?.details?.includeQualitativeExpectations) {
    reportSections.push({ label: 'Expectations - with Qualitative Insights' });
  } else if (wevo?.details?.includeExpectations) {
    reportSections.push({ label: 'Expectations' });
  }

  if (wevo?.details?.includeCustomQuestions) {
    reportSections.push({ label: `Custom Questions - ${wevo?.details?.customQuestionLimit || 5}` });
  }

  const reportSectionsCost = costEstimate?.keyFindingsCharge ? costEstimate.keyFindingsCharge : '-';

  lineItems.push(
    <SummaryLineItem
      key="report-sections"
      label="Report Sections"
      subItems={reportSections}
      cost={reportSectionsCost}
    />
  );

  // DEEP DIVES

  const stepCountTotal = (wevo?.pages ?? []).flatMap((page) => page?.steps ?? []).length;
  const stepCountLessPages = Math.max(stepCountTotal - pageCount, 0);

  // custom surveys do not have deep dives
  if (hasPricingEstimates && !isCustomSurvey) {
    const deepDivesDisplayValue =
      costEstimate?.additionalDeepDivesPrice > 0 ? `${costEstimate?.additionalDeepDivesPrice} credit(s)` : '-';

    lineItems.push(
      <SummaryLineItem
        key="steps"
        label="Sentiment Maps"
        value={stepCountTotal}
        cost={deepDivesDisplayValue}
        subItems={[
          { label: `Included Sentiment Maps - ${pageCount}` },
          { label: `Additional Sentiment Maps - ${stepCountLessPages}` },
        ]}
      />
    );
  } else if (!isCustomSurvey) {
    lineItems.push(<SummaryLineItem key="steps" label="Deep Dives" value={stepCountTotal} cost="-" />);
  }

  // CUSTOM QUESTIONS
  if (customQuestions?.length > 0) {
    lineItems.push(
      <SummaryLineItem
        key="custom-questions"
        label="Custom Questions"
        value={customQuestions?.length}
        cost="-"
      />
    );
  }

  return lineItems;
}

function SummaryLineItem({ label, value, subItems = [], cost }) {
  const lineItemDisplayValue = () => {
    if (label && value) return `${label} - ${value}`;
    return label;
  };

  const renderSubItems = () => {
    if (!subItems || subItems.length < 1) {
      return <></>;
    }

    return (
      <Box ml={2}>
        {subItems.map((subItem) => {
          return (
            <Box key={subItem.label} my={0.25}>
              <Typography fontSize={12} fontWeight={400} color="#878787">
                {subItem.label}
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };
  return (
    <Box display="flex" mb={1}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography fontSize={13} fontWeight={400} color="#212A37">
          {lineItemDisplayValue()}
        </Typography>
        {renderSubItems()}
      </Box>
      <Box sx={{ width: 100, justifyItems: 'end' }}>
        {cost ? (
          <Typography fontSize={13} fontWeight={600} color="#212A37">
            {cost}
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}

function SubtotalLineItem({ label, value, tooltipProps }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Typography variant="h5" component="h2" sx={{ fontWeight: 700 }}>
          {label}
        </Typography>
        <Box ml={1} display="flex" alignItems="center" columnGap={1}>
          {tooltipProps && (
            <IntakeTooltip {...tooltipProps}>
              <InfoIconFilled height="20px" width="20px" />
            </IntakeTooltip>
          )}
        </Box>
      </Box>
      <Box>
        <Box display="flex" alignItems="center">
          <Typography variant="h5" component="h2" sx={{ fontWeight: 700 }}>
            {value}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SummaryPanel;
