import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, CircularProgress, Divider, Grid, Link, Tooltip, tooltipClasses } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import _ from 'lodash';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as PulseInfoIcon } from '../../../src/assets/pulse-info-icon.svg';
import LockSectionIcon from '../../assets/lockSectionIcon.png';
import { customRenderers } from '../../helpers';
import { AutomatedInsightSections, TaskStatus } from '../../modules/automated-insights/constants';
import { getUserProfile } from '../../modules/user/selectors';
import { TrackEvent } from '../analytics';
import AudienceEditor from './AudienceEditor';
import Segment from './Segment';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  })
);

const CustomTooltip = styled(({ className, maxWidth, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: maxWidth,
  },
}));

const NewAudience = ({ sessionId, onClick }) => {
  const { track } = useAnalytics();
  const user = useSelector(getUserProfile);

  const isFreePlan = useMemo(() => {
    return user?.pulseSubscription?.isFreePlan;
  }, [user]);

  const pulseRunsBySeat = useMemo(() => {
    return user?.usage?.seatRunCount || 0;
  }, [user]);

  const pulseLimitPerSeat = useMemo(() => {
    return user?.usage?.seatRunLimit || 0;
  }, [user]);

  const reachedUsageLimit = pulseRunsBySeat >= pulseLimitPerSeat && user?.pulseSubscription?.isSeatBased;

  const handleClick = () => {
    onClick(null);
    track(TrackEvent.CLICKED_PULSE_NEW_AUDIENCE_BUTTON, {
      sessionId,
    });
  };

  return (
    <Box
      onClick={
        !isFreePlan && user?.pulseSubscription?.features?.personaCustomization && !reachedUsageLimit
          ? handleClick
          : () => {}
      }
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingY: 1,
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        border: '1px solid rgba(217, 217, 217, 0.83)',
        cursor: 'pointer',
      }}>
      <CustomTooltip
        title={
          isFreePlan || !user?.pulseSubscription?.features?.personaCustomization
            ? 'This feature is not included in your subscription.'
            : reachedUsageLimit
            ? 'You’ve reached your usage limit for the current plan.'
            : ''
        }
        maxWidth={'200px'}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item display="flex" alignItems="center" lineHeight="normal">
            <Typography
              variant="caption"
              component="div"
              sx={{ paddingLeft: 2, paddingY: 1, fontWeight: 500, flexWrap: 'nowrap' }}>
              New Persona
            </Typography>
            {(isFreePlan || !user?.pulseSubscription?.features?.personaCustomization || reachedUsageLimit) && (
              <Typography sx={{ marginLeft: 1 }}>
                <img src={LockSectionIcon} alt="selectable" style={{ width: '9px', height: 'auto' }} />
              </Typography>
            )}
          </Grid>
          <Grid item display="flex" alignItems="center">
            <Tooltip
              title="Create and define a new persona to tailor the analysis to your specific audience needs."
              placement="right">
              <PulseInfoIcon width="12px" />
            </Tooltip>
          </Grid>
        </Grid>
      </CustomTooltip>
    </Box>
  );
};

const GeneratingProposedAudience = () => {
  return (
    <Box
      sx={{
        paddingBottom: 2,
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        border: '1px solid rgba(217, 217, 217, 0.83)',
      }}>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="caption" component="div" sx={{ paddingX: 2, paddingY: 1, fontWeight: 500 }}>
            Proposed Persona
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Box
        sx={{
          height: 70,
          paddingTop: 2,
          paddingX: 2,
        }}>
        <Typography sx={{ fontSize: '12px', lineHeight: '20px' }}>
          Check back soon to see the proposed persona details.
        </Typography>
      </Box>
    </Box>
  );
};

const AudienceEditorComponent = ({
  sessionId,
  selectedSegment,
  sessionSegments,
  onInsightsRegeneration,
  onSaveAudience,
  onCancel,
}) => {
  return (
    <Box
      className="lightContainer"
      sx={{
        height: '100%',
        overflow: 'auto',
        paddingBottom: 6,
        paddingX: { xs: 1, sm: 3 },
        backgroundColor: '#F8F8F8',
      }}>
      <AudienceEditor
        sessionId={sessionId}
        selectedSegment={selectedSegment}
        sessionSegments={sessionSegments}
        onInsightsRegeneration={onInsightsRegeneration}
        onSaveAudience={onSaveAudience}
        onCancel={onCancel}
      />
    </Box>
  );
};

const PersonasContainer = ({
  taskStatus,
  session,
  sessionId,
  selectedSegment,
  sessionSegments,
  segmentsWithoutSessionInsights,
  segmentToResultMap,
  text,
  isCustomizingSegment,
  setIsCustomizingSegment,
  isSessionOwner,
  onSelectSegment,
  onSectionChange,
  onInsightsRegeneration,
}) => {
  const [segmentToEdit, setSegmentToEdit] = useState(null);

  useEffect(() => {
    if (selectedSegment) {
      const target = document.getElementById(selectedSegment.id);

      if (target) {
        target.scrollIntoView();
      }
    }
  }, [selectedSegment]);

  const selectSegmentToEdit = useCallback(
    (segment) => {
      setSegmentToEdit(segment);
      setIsCustomizingSegment(true);
    },
    [setIsCustomizingSegment]
  );

  const finishEditing = useCallback(() => {
    setIsCustomizingSegment(false);
  }, [setIsCustomizingSegment]);

  const cancelCustomization = useCallback(() => {
    setIsCustomizingSegment(false);
  }, [setIsCustomizingSegment]);

  if (isCustomizingSegment) {
    return (
      <AudienceEditorComponent
        sessionId={sessionId}
        selectedSegment={segmentToEdit}
        sessionSegments={sessionSegments}
        onInsightsRegeneration={onInsightsRegeneration}
        onSaveAudience={finishEditing}
        onCancel={cancelCustomization}
      />
    );
  }

  if (!sessionSegments) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  } else if (
    // there aren't any segments to show and the proposed audience isn't currently being generated
    _.isEmpty(sessionSegments) &&
    _.isEmpty(segmentsWithoutSessionInsights) &&
    [TaskStatus.Failed, TaskStatus.Completed].includes(taskStatus)
  ) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography>You don't have any personas yet!</Typography>
      </Box>
    );
  }

  const proposedAudience = (sessionSegments ?? []).find((segment) => segment?.isGenerated);
  const userGeneratedAudiences = (sessionSegments ?? []).filter((segment) => !segment?.isGenerated);

  const isGeneratingInsights = (segmentId) =>
    !segmentToResultMap[segmentId]?.findings && sessionSegments?.some((segment) => segment?.id === segmentId);

  return (
    <Box
      className="lightContainer"
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
        paddingX: 2,
        paddingBottom: 6,
        margin: 0,
      }}>
      <>
        <Box my={1}>
          <Typography fontSize={12} fontWeight={600}>
            {text}
          </Typography>
        </Box>
        {(_.isEmpty(sessionSegments) || !proposedAudience) &&
          ![TaskStatus.Failed, TaskStatus.Completed].includes(taskStatus) && <GeneratingProposedAudience />}
        {proposedAudience && (
          <Grid className="lightContainer" container spacing={2}>
            {[proposedAudience].map((segment) => (
              <Grid item key={segment?.id} xs={12}>
                <Segment
                  segment={segment}
                  isOwner={isSessionOwner}
                  onSelectSegment={onSelectSegment}
                  onSectionChange={onSectionChange}
                  onInsightsRegeneration={onInsightsRegeneration}
                  onSelectSegmentToEdit={selectSegmentToEdit}
                  hasSessionInsights={!!segmentToResultMap[segment?.id]?.findings}
                  hasInsightsGenerating={isGeneratingInsights(segment?.id)}
                  initialIsExpanded={segment?.id === selectedSegment?.id}
                  isGeneratedSegment={true}
                  isSelected={segment?.id === selectedSegment?.id}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Box my={2} />
        <Grid className="lightContainer" container spacing={2}>
          {userGeneratedAudiences?.map((segment) => (
            <Grid item key={segment?.id} xs={12} id={segment?.id}>
              <Segment
                segment={segment}
                isOwner={isSessionOwner}
                onSelectSegment={onSelectSegment}
                onSectionChange={onSectionChange}
                onInsightsRegeneration={onInsightsRegeneration}
                onSelectSegmentToEdit={selectSegmentToEdit}
                hasSessionInsights={!!segmentToResultMap[segment?.id]?.findings}
                hasInsightsGenerating={isGeneratingInsights(segment?.id)}
                initialIsExpanded={segment?.id === selectedSegment?.id}
                isSelected={segment?.id === selectedSegment?.id}
              />
            </Grid>
          ))}
        </Grid>
        <Box mt={2}>
          {isSessionOwner && (
            <NewAudience session={session} sessionId={sessionId} onClick={selectSegmentToEdit} />
          )}
        </Box>
      </>

      {!_.isEmpty(segmentsWithoutSessionInsights) && isSessionOwner && (
        <>
          <Box mt={4} mb={1}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography fontSize={12} fontWeight={600}>
                  Other personas
                </Typography>
              </Grid>
              <Grid item display="flex" alignItems="center">
                <Tooltip
                  title="Pick from personas you've created in past Pulses, making it easy to apply them again in this session. Reuse and go!"
                  placement="right">
                  <PulseInfoIcon width="12px" />
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <Grid className="lightContainer" container spacing={2}>
            {segmentsWithoutSessionInsights?.map((segment) => (
              <Grid item key={segment?.id} xs={12}>
                <Segment
                  segment={segment}
                  isOwner={isSessionOwner}
                  onSelectSegment={onSelectSegment}
                  onSectionChange={onSectionChange}
                  onInsightsRegeneration={onInsightsRegeneration}
                  onSelectSegmentToEdit={selectSegmentToEdit}
                  hasSessionInsights={!!segmentToResultMap[segment?.id]}
                  hasInsightsGenerating={isGeneratingInsights(segment?.id)}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default PersonasContainer;

export function MultiSessionSegmentsContainer({
  sessions,
  selectedSessions,
  taskStatus,
  sessionId,
  selectedSegment,
  sessionSegments,
  segmentsWithoutSessionInsights,
  segmentToResultMap,
  isSessionOwner,
  onSelectSegment,
  onSectionChange,
  onInsightsRegeneration,
  onSelectSegmentToEdit,
}) {
  const selectedSegmentInfo = useMemo(() => {
    if (!selectedSegment) {
      return <></>;
    }

    const segmentSession = sessions.find(
      (session) => !!(session?.segments ?? []).find((segment) => segment.id === selectedSegment?.id)
    );

    if (!segmentSession) {
      return <></>;
    }

    return (
      <Grid item xs={12}>
        <Box my={1}>
          <Typography fontWeight={600} fontSize={12}>
            {segmentSession.name}
          </Typography>
        </Box>
        <Segment
          segment={selectedSegment}
          isOwner={isSessionOwner}
          showActions={false}
          initialIsExpanded={true}
          canToggle={false}
        />
      </Grid>
    );
  }, [isSessionOwner, selectedSegment, sessions]);

  const proposedSegmentsList = useMemo(() => {
    return (sessions ?? []).map((session) => {
      const proposedAudience = (session?.segments ?? []).find((segment) => segment.isGenerated);

      return (
        <Grid item key={session?.id} xs={12}>
          <Box my={1}>
            <Typography fontWeight={600} fontSize={12}>
              {session.name}
            </Typography>
          </Box>
          {proposedAudience ? (
            <Segment
              segment={proposedAudience}
              isOwner={isSessionOwner}
              showActions={false}
              initialIsExpanded={false}
            />
          ) : (
            <GeneratingProposedAudience />
          )}
        </Grid>
      );
    });
  }, [isSessionOwner, sessions]);

  return (
    <Box
      className="lightContainer"
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
        paddingX: 2,
        margin: 0,
      }}>
      <Grid className="lightContainer" container spacing={2}>
        {selectedSegment ? selectedSegmentInfo : proposedSegmentsList}
      </Grid>
    </Box>
  );
}

const ProposedSegmentAccordion = ({
  proposedSegment,
  intentSection,
  proposedSegmentsIntent,
  index,
  openProposedSegments,
  handleOpenProposedAccordion,
  hasSingleSegment,
}) => {
  const [openIntentAccordion, setOpenIntentAccordion] = useState(false);

  const hasIntents = proposedSegmentsIntent?.length > 0;

  return (
    <Grid item xs={12}>
      <Accordion
        defaultExpanded
        expanded={openProposedSegments}
        onChange={(e, expanded) => {
          handleOpenProposedAccordion(index, expanded);
        }}
        sx={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          borderBottomRightRadius: openProposedSegments && hasIntents ? '0px' : '16px',
          borderBottomLeftRadius: openProposedSegments && hasIntents ? '0px' : '16px',
        }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            backgroundColor: '#F8F8F8',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            borderBottomRightRadius: openProposedSegments && hasIntents ? '0px' : '16px',
            borderBottomLeftRadius: openProposedSegments && hasIntents ? '0px' : '16px',
          }}>
          <Typography sx={{ fontWeight: 700, fontSize: '13px' }}>
            {hasSingleSegment ? `${proposedSegment?.name}` : `${index + 1}. ${proposedSegment?.name}`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginLeft: 1, fontSize: '13px' }}>
          <ReactMarkdown components={customRenderers}>{proposedSegment?.description}</ReactMarkdown>
        </AccordionDetails>
      </Accordion>
      {openProposedSegments && hasIntents ? (
        <Accordion
          onChange={(e, expanded) => {
            setOpenIntentAccordion(expanded);
          }}
          sx={{ borderBottomRightRadius: '16px', borderBottomLeftRadius: '16px' }}>
          <AccordionSummary
            disabled={!intentSection?.isCompleted}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{
              backgroundColor: '#F8F8F8',
              borderBottomRightRadius: openIntentAccordion ? '0px' : '16px',
              borderBottomLeftRadius: openIntentAccordion ? '0px' : '16px',
            }}>
            {intentSection?.isCompleted && (
              <Typography sx={{ marginLeft: 1.75, fontWeight: 700, fontSize: '13px' }}>
                {proposedSegmentsIntent[index]?.name}'s Goal
              </Typography>
            )}
            {!intentSection?.isCompleted && <CircularProgress color="secondary" size={20} />}
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ marginLeft: 2, fontSize: '13px' }}>
              {proposedSegmentsIntent[index]?.intent}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </Grid>
  );
};

const CustomSegmentAccordion = ({
  customSegment,
  intentSection,
  customSegmentsIntent,
  index,
  openCustomSegments,
  handleOpenCustomAccordion,
  hasSingleSegment,
}) => {
  const [openIntentAccordion, setOpenIntentAccordion] = useState(false);

  const hasIntents = customSegmentsIntent?.length > 0;

  return (
    <Grid item xs={12}>
      <Accordion
        defaultExpanded
        expanded={openCustomSegments}
        onChange={(e, expanded) => {
          handleOpenCustomAccordion(index, expanded);
        }}
        sx={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          borderBottomRightRadius: openCustomSegments && hasIntents ? '0px' : '16px',
          borderBottomLeftRadius: openCustomSegments && hasIntents ? '0px' : '16px',
        }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            backgroundColor: '#F8F8F8',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            borderBottomRightRadius: openCustomSegments ? '0px' : '16px',
            borderBottomLeftRadius: openCustomSegments ? '0px' : '16px',
          }}>
          <Typography sx={{ fontWeight: 700, fontSize: '13px' }}>
            {hasSingleSegment ? `${customSegment?.name}` : `${index + 1}. ${customSegment?.name}`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ marginLeft: 2, fontSize: '13px' }}>
          <ReactMarkdown components={customRenderers}>{customSegment?.description}</ReactMarkdown>
        </AccordionDetails>
      </Accordion>
      {openCustomSegments && hasIntents ? (
        <Accordion
          onChange={(e, expanded) => {
            setOpenIntentAccordion(expanded);
          }}
          sx={{ borderBottomRightRadius: '16px', borderBottomLeftRadius: '16px' }}>
          <AccordionSummary
            disabled={!intentSection?.isCompleted}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{
              backgroundColor: '#F8F8F8',
              borderBottomRightRadius: openIntentAccordion ? '0px' : '16px',
              borderBottomLeftRadius: openIntentAccordion ? '0px' : '16px',
            }}>
            {intentSection?.isCompleted && (
              <Typography sx={{ marginLeft: 2, fontWeight: 700, fontSize: '13px' }}>
                {customSegment?.name}'s Goal
              </Typography>
            )}
            {!intentSection?.isCompleted && <CircularProgress color="secondary" size={20} />}
          </AccordionSummary>
          <AccordionDetails>
            {customSegmentsIntent?.map((customSegment, index) => {
              return (
                <Grid
                  key={index}
                  container
                  spacing={2}
                  sx={{ marginBottom: index < customSegmentsIntent?.length - 1 ? 2 : 0 }}>
                  <Grid item sx={{ marginLeft: 2 }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '13px' }}>{customSegment.name}</Typography>
                  </Grid>
                  <Grid item sx={{ marginLeft: 2, marginTop: -1 }}>
                    <Typography sx={{ fontSize: '13px' }}>{customSegment.intent}</Typography>
                  </Grid>
                </Grid>
              );
            })}
          </AccordionDetails>
        </Accordion>
      ) : null}
    </Grid>
  );
};

const PersonaIntentComponent = ({
  isGenerated,
  proposedSegmentsDetails,
  customSegmentsDetails,
  proposedSegmentsIntent,
  customSegmentsIntent,
  intentSection,
  openCustomSegments,
  openProposedSegments,
  handleOpenProposedAccordion,
  handleOpenCustomAccordion,
}) => {
  return isGenerated
    ? proposedSegmentsDetails?.map((proposedSegment, index) => {
        return (
          <ProposedSegmentAccordion
            key={index}
            proposedSegment={proposedSegment}
            intentSection={intentSection}
            proposedSegmentsIntent={proposedSegmentsIntent}
            index={index}
            openProposedSegments={openProposedSegments?.[index] || false}
            handleOpenProposedAccordion={handleOpenProposedAccordion}
            hasSingleSegment={proposedSegmentsDetails?.length === 1}
          />
        );
      })
    : customSegmentsDetails?.map((customSegment, index) => {
        return (
          <CustomSegmentAccordion
            key={index}
            customSegment={customSegment}
            intentSection={intentSection}
            customSegmentsIntent={customSegmentsIntent}
            index={index}
            openCustomSegments={openCustomSegments?.[index] || false}
            handleOpenCustomAccordion={handleOpenCustomAccordion}
            hasSingleSegment={customSegmentsDetails?.length === 1}
          />
        );
      });
};

export function SessionPersonasIntent(props) {
  const { session, sessionSegments, segment, isGenerated, sections } = props;

  const intentSection = sections?.find((section) => section.name === AutomatedInsightSections.PersonaIntent);

  // Proposed Segments
  const proposedSegmentsDetails = useMemo(() => {
    return (sessionSegments ?? []).find((segment) => segment.isGenerated)?.segmentDetails;
  }, [sessionSegments]);

  const proposedSegmentsIntent = useMemo(() => {
    const proposedIntentSegment = (session?.data ?? []).find((data) => data.segmentId === null);
    return proposedIntentSegment?.personaIntent || [];
  }, [session]);

  // Custom Segments
  const customSegmentsDetails = useMemo(() => {
    return (sessionSegments ?? []).find(
      (sessionSegment) => sessionSegment.id === segment.id && !segment.isGenerated
    )?.segmentDetails;
  }, [sessionSegments, segment]);

  const customSegmentsIntent = useMemo(() => {
    const customIntentSegment = (session?.data ?? []).find((data) => data.segmentId === segment?.id);
    return customIntentSegment?.personaIntent || [];
  }, [session, segment]);

  const [openProposedSegments, setOpenProposedSegments] = useState(
    Array(proposedSegmentsDetails?.length).fill(true).flat()
  );

  const [openCustomSegments, setOpenCustomSegments] = useState(
    Array(customSegmentsDetails?.length).fill(true).flat()
  );

  const handleButtonClick = (openAll) => {
    if (openAll) {
      setOpenCustomSegments(Array(customSegmentsDetails?.length).fill(true).flat());
      setOpenProposedSegments(Array(proposedSegmentsDetails?.length).fill(true).flat());
    } else {
      setOpenCustomSegments([]);
      setOpenProposedSegments([]);
    }
  };

  const handleOpenProposedAccordion = (index, open) => {
    const array = [...openProposedSegments];
    array[index] = open;
    setOpenProposedSegments(array);
  };

  const handleOpenCustomAccordion = (index, open) => {
    const array = [...openCustomSegments];
    array[index] = open;
    setOpenCustomSegments(array);
  };

  return (
    <Box
      className="lightContainer"
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
        paddingX: 3,
        paddingBottom: 6,
        paddingTop: 2,
        margin: 0,
      }}>
      <Typography mb={3} fontSize={13} fontStyle="italic">
        Personas - Meet the people your experience is trying to reach.
      </Typography>

      <Grid container justifyContent={'end'} spacing={2} sx={{ paddingBottom: 1, paddingRight: 3 }}>
        <Grid item sx={{ marginRight: -0.75 }}>
          <Link
            onClick={() => handleButtonClick(true)}
            sx={{
              color: '#43BCFF',
              textDecoration: 'none',
              cursor: 'pointer',
            }}>
            <Typography
              sx={{
                fontWeight: 400,
                color: grey[900],
                fontSize: '12px',
                '&:hover': {
                  fontWeight: 500,
                  color: 'black',
                },
              }}>
              Expand all
            </Typography>
          </Link>
        </Grid>
        <Grid item sx={{ marginTop: -0.5 }}>
          <Typography variant="body1" sx={{ color: grey[400] }}>
            |
          </Typography>
        </Grid>
        <Grid item sx={{ marginLeft: -0.75 }}>
          <Link
            onClick={() => handleButtonClick(false)}
            sx={{
              color: '#43BCFF',
              textDecoration: 'none',
              cursor: 'pointer',
            }}>
            <Typography
              sx={{
                fontWeight: 400,
                color: grey[900],
                fontSize: '12px',
                '&:hover': {
                  fontWeight: 500,
                  color: 'black',
                },
              }}>
              Collapse all
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <PersonaIntentComponent
          isGenerated={isGenerated}
          proposedSegmentsDetails={proposedSegmentsDetails}
          customSegmentsDetails={customSegmentsDetails}
          proposedSegmentsIntent={proposedSegmentsIntent}
          customSegmentsIntent={customSegmentsIntent}
          intentSection={intentSection}
          openCustomSegments={openCustomSegments}
          openProposedSegments={openProposedSegments}
          handleOpenProposedAccordion={handleOpenProposedAccordion}
          handleOpenCustomAccordion={handleOpenCustomAccordion}
        />
      </Grid>
    </Box>
  );
}
