import _, { groupBy, isEmpty, isNil } from 'lodash';
import { WevoTestType } from '../../../../../modules/wevos/constants';
import {
  MAX_DEEP_DIVE_STEPS,
  MAX_PAGES_COMPARE,
  MIN_DEEP_DIVE_STEPS,
  MIN_PAGES_COMPARE,
  WevoPageAssetType,
} from '../../constants';
import { isCustomSurveyType } from '../wevo';
import { serializeErrors } from './shared';

export const ValidationKeys = {
  Name: 'name',
  JourneyStartUrl: 'journeyStartUrl',
  AssetType: 'assetType',
  InsufficientPages: 'insufficientPages',
  PageLimitExceeded: 'pageLimitExceeded',
  InsufficientSteps: 'insufficientSteps',
  StepLimitExceeded: 'stepLimitExceeded',
  PrimerContext: 'primerContext',
};

function validateAssets(
  wevo,
  options = {
    global: {
      forceNoLimit: false,
    },
    pages: {
      minPages: MIN_PAGES_COMPARE,
      maxPages: MAX_PAGES_COMPARE,
    },
    steps: {
      minSteps: MIN_DEEP_DIVE_STEPS,
      maxSteps: MAX_DEEP_DIVE_STEPS,
    },
  }
) {
  const wevoErrors = validateWevo({ wevo }, options);

  const pageErrors = validatePages({ wevo }, options);

  const stepErrors = validateSteps({ wevo }, options);

  return {
    wevoErrors,
    pageErrors,
    stepErrors,
  };
}

function validateWevo({ wevo }, options) {
  // if page test type then should be exactly one page
  // if compare test type there should be at least two pages
  const errors = [];

  // validate min/max pages
  const isCompare = wevo?.testType === WevoTestType.Compare;
  const forceNoLimit = options?.global?.forceNoLimit || false;
  const minPages = isCompare
    ? isNil(options?.pages?.minPages)
      ? MIN_PAGES_COMPARE
      : options?.pages?.minPages
    : 1;
  const maxPages = isCompare
    ? isNil(options?.pages?.maxPages)
      ? MAX_PAGES_COMPARE
      : options?.pages?.maxPages
    : 1;

  if ((wevo?.pages ?? []).length < minPages) {
    errors.push({
      key: ValidationKeys.InsufficientPages,
      // 'page' in this context is a deep dive page
      reason: 'Please add at least one page.',
    });
  }

  if (!forceNoLimit && (wevo?.pages ?? []).length > maxPages) {
    const exceededAmount = wevo?.pages.length - maxPages;

    const reason =
      exceededAmount > 1 ? `Please remove ${exceededAmount} pages.` : `Please remove ${exceededAmount} page.`;

    errors.push({
      key: ValidationKeys.PageLimitExceeded,
      // 'page' in this context is a deep dive page
      reason,
    });
  }

  return { [wevo?.id]: groupBy(errors, 'key') };
}

function validatePages({ wevo }, options) {
  const errorsByPageId = {};

  for (const page of wevo?.pages ?? []) {
    errorsByPageId[page.id] = validatePage({ wevo, page }, options);
  }

  return errorsByPageId;
}

function validatePage({ wevo, page }, options) {
  const errors = [];

  const isCustomSurvey = isCustomSurveyType(wevo);
  // custom surveys are not asset based, so skip all this validation
  if (isCustomSurvey) {
    return errors;
  }

  // page name should not be empty
  if (isEmpty(page.name)) {
    errors.push({
      key: ValidationKeys.Name,
      reason: 'Name is a required field.',
    });
  }

  // visual type is selected (we don't error on the page, but in review)
  if (isEmpty(page.assetType)) {
    errors.push({
      key: ValidationKeys.AssetType,
      reason: 'Please select a visual type your audience will give feedback on.',
    });
  }

  // if url type and no journeyStartUrl or it is not a valid url
  if (page?.assetType === WevoPageAssetType.URL && isEmpty(page.journeyStartUrl)) {
    errors.push({
      key: ValidationKeys.JourneyStartUrl,
      reason: 'Required field.',
    });
  }

  // if a primer image is set then a primer context is required
  if (!isNil(page?.primerId) && isEmpty(page?.primerContext)) {
    errors.push({
      key: ValidationKeys.PrimerContext,
      reason: 'Primer context is required when using a primer image.',
    });
  }

  // validate min/max steps
  const forceNoLimit = options?.global?.forceNoLimit || false;
  const minSteps = isNil(options?.steps?.minSteps) ? MIN_DEEP_DIVE_STEPS : options?.steps?.minSteps;
  const maxSteps = isNil(options?.steps?.maxSteps) ? MAX_DEEP_DIVE_STEPS : options?.steps?.maxSteps;

  if ((page?.steps ?? []).length < minSteps) {
    errors.push({
      key: ValidationKeys.InsufficientSteps,
      // 'page' in this context is a deep dive page
      reason: 'Please add at least one deep dive page.',
    });
  }

  if (!forceNoLimit && (page?.steps ?? []).length > maxSteps) {
    const exceededAmount = page.steps.length - maxSteps;

    const reason =
      exceededAmount > 1
        ? `Please remove ${exceededAmount} deep dive pages.`
        : `Please remove ${exceededAmount} deep dive page.`;

    errors.push({
      key: ValidationKeys.StepLimitExceeded,
      // 'page' in this context is a deep dive page
      reason,
    });
  }

  return groupBy(errors, 'key');
}

function validateSteps({ wevo }, options) {
  const errorsByStepId = {};

  for (const page of wevo?.pages ?? []) {
    for (const step of page?.steps ?? []) {
      errorsByStepId[step.id] = validateStep({ wevo, page, step }, options);
    }
  }
  return errorsByStepId;
}

function validateStep({ wevo, page, step }, options) {
  // todo: if step image size is too low resolution, provide a warning
  const errors = [];

  const isCustomSurvey = isCustomSurveyType(wevo);
  // custom surveys are not asset based, so skip all this validation
  if (isCustomSurvey) {
    return errors;
  }

  // step name should not be empty
  if (isEmpty(step.name)) {
    errors.push({
      key: ValidationKeys.Name,
      reason: 'Name is a required field.',
    });
  }

  return _.groupBy(errors, 'key');
}

const hasAssetErrors = (validationResult, options = { validatePrimer: true }) => {
  for (const errors of Object.values(validationResult?.wevoErrors ?? {})) {
    if (Object.keys(errors).length > 0) return true;
  }

  for (const errors of Object.values(validationResult?.pageErrors ?? {})) {
    let errorKeys = Object.keys(errors);

    // exclude primer errors, if they exist, from asset errors
    if (!options.validatePrimer) {
      errorKeys = errorKeys.filter((key) => key !== ValidationKeys.PrimerContext);
    }

    if (errorKeys.length > 0) return true;
  }

  for (const errors of Object.values(validationResult?.stepErrors ?? {})) {
    if (Object.keys(errors).length > 0) return true;
  }

  return false;
};

export { hasAssetErrors, serializeErrors, validateAssets };
