import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * A dialog to allow the user to confirm if they wish to discard their current test type
 * and therefore discard all of their current asssets (steps or pages)
 * @param {{open: boolean, discardTestType: function, closeCallback: function, message: string}} props
 * @returns The discard test type dialog component
 */
const DiscardTestTypeDialog = ({ open, discardTestType, closeCallback, message }) => {
  const classes = useStyles();

  const handleClose = () => {
    !!closeCallback && closeCallback();
  };

  const handleReset = () => {
    !!discardTestType && discardTestType();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="discard test type confirmation dialog"
        aria-describedby="discard-test-type-confirmation-description">
        <DialogTitle className={classes.title}>Switching Test Type</DialogTitle>
        <DialogContent>
          <DialogContentText id="discard-test-type-confirmation-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset}>Yup! Switch Test Type</Button>
          <Button onClick={handleClose}>Do Nothing</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DiscardTestTypeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  discardTestType: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default React.memo(DiscardTestTypeDialog);
