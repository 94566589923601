import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { getUserCustomizations, getUserProfile, getUserTeamId } from '../../modules/user/selectors';
import { clearDraft } from '../../modules/wevos/actions';
import { snackbar } from '../../notifications';
import { Paths } from '../../routes';
import { TrackEvent } from '../analytics';
import useCreateWevo from './hooks/useCreateWevo';
import { canUseCore } from '../../modules/automated-insights/helpers';

const CreateTestOptions = {
  Wevo: 'Pro',
  Video: 'Video',
};

export function CreatePulseButton() {
  const userCustomizations = useSelector(getUserCustomizations);
  const isMastercard = Boolean(userCustomizations?.isMastercard);
  const handleCreateClick = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}${Paths.automatedInsights.basePath}`;
  };

  return (
    <Button
      variant="outlined"
      onClick={handleCreateClick}
      sx={{
        backgroundColor: 'white',
        borderWidth: '2px',
        '&:hover': {
          borderWidth: '2px',
        },
      }}>
      {isMastercard ? 'Take a Pulse' : 'New Pulse'}
    </Button>
  );
}

export function CreateTestButton() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { track } = useAnalytics();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { mutateAsync: createWevoAsync, isLoading: isCreatingNewWevo } = useCreateWevo();

  const user = useSelector(getUserProfile);
  const userTeamId = useSelector(getUserTeamId);
  const userCustomizations = useSelector(getUserCustomizations);
  const hasProEnabled = canUseCore(userCustomizations);
  const hasNewIntake = userCustomizations?.features?.newIntake !== false;
  const isMastercard = Boolean(userCustomizations?.isMastercard);
  const isUserlytics = Boolean(userCustomizations?.integrations?.userlytics);
  const userlyticsDomain = userCustomizations?.integrations?.userlyticsDomain;
  const userlyticsTeamId = userCustomizations?.integrations?.userlyticsTeamId;
  const userlyticsProjectId = userCustomizations?.integrations?.userlyticsProjectId;

  const handleCreateClick = async () => {
    dispatch(clearDraft());

    if (isMastercard) {
      // Mastercard WEVOs must be launched from the landing page
      history.push('/landing');
    } else if (!hasProEnabled && isUserlytics) {
      const userlyticsBaseUrl = `${process.env.REACT_APP_USERLYTICS_HOST}${process.env.REACT_APP_USERLYTICS_SSO_PATH}/${userlyticsDomain}`;
      const userlyticsStudyPath = `/client/teams/${userlyticsTeamId}/projects/${userlyticsProjectId}/study`;
      window.location.href = `${userlyticsBaseUrl}?redirect_uri=${userlyticsStudyPath}`;
    } else if (hasNewIntake) {
      await handleNewIntakeClick();
    } else {
      history.push('/wevos/create');
    }
  };

  const handleNewIntakeClick = async () => {
    const firstName = user?.firstName;
    const lastName = user?.lastName;
    const ownerName = `${firstName} ${lastName}`;
    const ownerEmail = user?.email;

    try {
      const wevo = await createWevoAsync({ ownerName, ownerEmail });
      const wevoId = wevo.id;
      track(TrackEvent.CREATED_TEST, {
        wevoId: wevoId,
        ownerName,
        ownerEmail,
        teamId: userTeamId,
      });

      const entryPath = isMastercard ? Paths.intake.intakeStart : Paths.intake.intakeAudience;

      history.push({
        pathname: generatePath(entryPath, { wevoId }),
      });
    } catch (err) {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error creating new wevo');
    }
  };

  const handleMenuItemClick = async (event, selectedOption) => {
    setOpen(false);

    if (selectedOption === CreateTestOptions.Wevo) {
      await handleCreateClick();
    } else if (selectedOption === CreateTestOptions.Video) {
      const userlyticsBaseUrl = `${process.env.REACT_APP_USERLYTICS_HOST}${process.env.REACT_APP_USERLYTICS_SSO_PATH}/${userlyticsDomain}`;
      const userlyticsStudyPath = `/client/teams/${userlyticsTeamId}/projects/${userlyticsProjectId}/study`;
      window.location.href = `${userlyticsBaseUrl}?redirect_uri=${userlyticsStudyPath}`;
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  if (!isUserlytics || isMastercard || (!hasProEnabled && isUserlytics)) {
    return (
      <Button variant="contained" endIcon={<AddCircleOutlinedIcon />} onClick={handleCreateClick}>
        {isCreatingNewWevo && <CircularProgress size={16} sx={{ mr: 1, color: 'white' }} />}
        {isMastercard || (!hasProEnabled && isUserlytics) ? 'Run a Study' : 'New Pro'}
      </Button>
    );
  } else {
    return (
      <>
        <ButtonGroup variant="contained" ref={anchorRef}>
          <Button onClick={handleCreateClick}>
            {isCreatingNewWevo && <CircularProgress size={16} sx={{ mr: 1, color: 'white' }} />}
            {isMastercard ? 'Run a Study' : 'New Study'}
          </Button>
          <Button size="small" onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="create-test-menu" autoFocusItem sx={{ pr: 3 }}>
                    {hasProEnabled && (
                      <MenuItem
                        key={`option-create-wevo-${CreateTestOptions.Wevo}`}
                        onClick={(event) => handleMenuItemClick(event, CreateTestOptions.Wevo)}>
                        {isMastercard ? 'WEVO Study' : CreateTestOptions.Wevo}
                      </MenuItem>
                    )}
                    {isUserlytics && !isMastercard && (
                      <MenuItem
                        key={`option-create-wevo-${CreateTestOptions.Video}`}
                        onClick={(event) => handleMenuItemClick(event, CreateTestOptions.Video)}>
                        {isMastercard ? 'Video Study' : CreateTestOptions.Video}
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }
}
