import styled from '@emotion/styled';
import { Box, CircularProgress, Grid, IconButton, Tooltip, Typography, tooltipClasses } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as AccentureLogo } from '../../assets/customer-logos/accenture.svg';
import { ReactComponent as HarvardLogo } from '../../assets/customer-logos/harvard.svg';
import { ReactComponent as IntuitLogo } from '../../assets/customer-logos/intuit.svg';
import { ReactComponent as MastercardLogo } from '../../assets/customer-logos/mastercard.svg';
import { ReactComponent as PearsonLogo } from '../../assets/customer-logos/pearson.svg';
import { ReactComponent as QuickenLogo } from '../../assets/customer-logos/quicken.svg';
import { ReactComponent as InfoIconFilled } from '../../assets/info-icon-filled.svg';
import { ReactComponent as QuotationIcon } from '../../assets/quotation-icon.svg';
import {
  AnnuallyPulseSubscriptionsTypes,
  MonthlyPulseSubscriptionsTypes,
  PULSE_SUBSCRIPTION_TYPES,
  SeatsBillingMethods,
} from '../../modules/automated-insights/constants';
import { getUserCompany, getUserCustomizations, getUserProfile } from '../../modules/user/selectors';
import { Paths } from '../../routes';
import { TrackEvent, useTrackPageLoad } from '../analytics';
import ContactSupportModal from './ContactSupportModal';
import FreeTierDialog from './FreeTierDialog';
import SeatsBillingMethodToggle from './SeatsBillingMethodToggle';
import SeatsCounter from './SeatsCounter';
import SelectableSubscriptionCard from './SelectableSubscriptionCard';
import useChangeSubscription from './hooks/useChangeSubscription';
import useFetchSubscriptionTiers from './hooks/useFetchSubscriptionTiers';

const FREE_TIER_SUBSCRIPTION_ID = '7cfe621b-8f0d-4270-a845-d4237c984e65';

const ServicesTooltip = styled(({ className, maxWidth, ...props }) => <Tooltip {...props} />)(
  ({ theme, maxWidth }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FCFCFC',
      color: theme.palette.text.primary,
      borderRadius: '24px',
      border: '1px solid #E0E0E0',
      maxWidth: maxWidth,
    },
  })
);

const styles = {
  fieldSubTitleGrid: {
    padding: 0,
    height: '30px',
  },
  fieldText: {
    marginTop: '-20px',
    color: (theme) => theme.palette.text.tertiary,
    fontSize: '13px',
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  logoGridItem: {
    minWidth: 100,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  info: {
    fontSize: 12,
  },
};

const CustomerTestimonials = () => {
  return (
    <Grid
      container
      rowGap={4}
      alignItems="center"
      justifyContent={{ md: 'space-between', lg: 'end' }}
      sx={{ flexWrap: { lg: 'nowrap' } }}>
      <Grid item>
        <Grid container rowGap={2}>
          <Grid item alignSelf="center" sx={{ flexGrow: 1 }}>
            <Typography
              variant="h4"
              sx={{
                fontSize: 20,
                fontWeight: 700,
                color: (theme) => theme.palette.text.tertiary,
                textAlign: { lg: 'right' },
              }}>
              WEVO is trusted by
            </Typography>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Grid
              container
              alignItems="center"
              sx={{ height: '100%', paddingY: { lg: 5 }, columnGap: { xs: 1, sm: 3, lg: 0 }, rowGap: 2 }}>
              <Grid item lg={4} sx={styles.logoGridItem}>
                <Box sx={styles.logoContainer}>
                  <AccentureLogo />
                </Box>
              </Grid>
              <Grid item lg={4} sx={styles.logoGridItem}>
                <Box sx={styles.logoContainer}>
                  <HarvardLogo />
                </Box>
              </Grid>
              <Grid item lg={4} sx={styles.logoGridItem}>
                <Box sx={styles.logoContainer}>
                  <IntuitLogo />
                </Box>
              </Grid>
              <Grid item lg={4} sx={styles.logoGridItem}>
                <Box sx={styles.logoContainer}>
                  <QuickenLogo />
                </Box>
              </Grid>
              <Grid item lg={4} sx={styles.logoGridItem}>
                <Box sx={styles.logoContainer}>
                  <MastercardLogo />
                </Box>
              </Grid>
              <Grid item lg={4} sx={styles.logoGridItem}>
                <Box sx={styles.logoContainer}>
                  <PearsonLogo />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ maxWidth: 430 }}>
        <Box sx={{ position: 'relative' }}>
          <Grid
            container
            sx={{
              paddingX: 2,
              paddingTop: 3,
              paddingBottom: 2,
              backgroundColor: '#F0F5F7',
              borderRadius: '10px',
              gap: 2,
            }}>
            <Grid item>
              <Typography variant="body2">
                In our quest to enhance experiences, we often start with certain hypotheses about how users
                might interact with our content. Pulse allows us to test these assumptions right at the outset,
                ensuring we’re not just relying on gut feelings but on real, actionable data.
              </Typography>
            </Grid>
            <Grid item>
              <Box>
                <Typography variant="body3" fontWeight={700}>
                  Ludmila Ziegler
                </Typography>
                <Typography variant="body3" sx={{ display: 'block', color: '#5E5E5E' }}>
                  Senior Manager Web Marketing at Pearson
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: '16px',
              transform: 'translateY(-40%)',
            }}>
            <QuotationIcon />
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

const SubscriptionSelection = (props) => {
  const [hoveredLine, setHoveredLine] = useState(null);
  const [numberOfSeats, setNumberOfSeats] = useState(1);
  const isFreeTrial = props.isFreeTrial;
  const isFreePlan = props.isFreePlan;

  useTrackPageLoad({ name: TrackEvent.VIEWED_PULSE_SUBSCRIPTION_PLANS });
  const { track } = useAnalytics();

  const { data: tiers, isLoading: isLoadingTiers } = useFetchSubscriptionTiers();
  const history = useHistory();

  const user = useSelector(getUserProfile);
  const companyInfo = useSelector(getUserCompany);
  const userCustomizations = useSelector(getUserCustomizations);

  const userSelectedTierId = user?.pulseSubscription?.pulseSubscriptionTierId;
  const userSelectedTier = user?.pulseSubscription?.pulseSubscriptionTier?.type;

  const [showContactSupportDialog, setShowContactSupportDialog] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalAction, setModalAction] = useState('');

  const [billingMethod, setBillingMethod] = useState(null);
  const [showFreeTierDialog, setShowFreeTierDialog] = useState(false);

  const selfServiceSubscription = useMemo(() => {
    if (!_.isNil(user?.pulseSubscription?.overrideRules?.hasSelfServiceBilling)) {
      return user?.pulseSubscription?.overrideRules?.hasSelfServiceBilling;
    }

    return userCustomizations?.features?.selfServiceSubscription !== 'false';
  }, [userCustomizations, user]);

  const { mutate: changeSubscription } = useChangeSubscription();

  useEffect(() => {
    setBillingMethod(
      [
        PULSE_SUBSCRIPTION_TYPES.EssentialMonth,
        PULSE_SUBSCRIPTION_TYPES.PremiumMonth,
        PULSE_SUBSCRIPTION_TYPES.InnovatorMonth,
      ].includes(userSelectedTier)
        ? SeatsBillingMethods.Monthly
        : SeatsBillingMethods.Annually
    );
  }, [userSelectedTier]);

  // Redirect the user to /pulse if selfServiceSubscription = false or is undefined
  if (!_.isNil(userCustomizations) && !selfServiceSubscription) {
    history.replace({
      pathname: Paths.automatedInsights.basePath,
    });
  }

  const handleNumberOfSeatsChanged = (newNumber) => {
    const eventName =
      !newNumber || newNumber > numberOfSeats
        ? TrackEvent.PULSE_USER_PLAN_SELECTION_CLICKED_ADD_SEATS
        : TrackEvent.PULSE_USER_PLAN_SELECTION_CLICKED_REMOVE_SEATS;

    track(eventName, { previousValue: numberOfSeats, currentValue: newNumber });

    if (newNumber === '') {
      setNumberOfSeats('');
    }
    if (newNumber >= 1 && newNumber <= 20) {
      setNumberOfSeats(newNumber);
    }
    // Maximum number of seats = 20
    if (newNumber > 20) {
      setNumberOfSeats(20);
    }
  };

  const handleBillingMethodChanged = (newMethod) => {
    track(TrackEvent.PULSE_USER_PLAN_SELECTION_TOGGLED_BILLING_FREQUENCY, { newMethod });
    setBillingMethod(newMethod);
  };

  const handleClose = () => {
    track(TrackEvent.PULSE_USER_CLICKED_EXPLORE_PLANS);
    toggleShowFreeTierDialog();
  };

  const toggleShowFreeTierDialog = (open) => {
    setShowFreeTierDialog(open);
  };

  const toggleShowContactSupportDialog = (open, modalHeader, modalAction) => {
    if (open) {
      setModalHeader(modalHeader);
      setModalAction(modalAction);
    }
    setShowContactSupportDialog(open);
  };

  const approveFreeTier = (newPulseSubscriptionTierId) => {
    track(TrackEvent.PULSE_USER_PLAN_SELECTION_CHANGED_PLAN, {
      pulseSubscriptionId: user?.pulseSubscription?.id,
      oldPulseSubscriptionTierId: user?.pulseSubscription?.pulseSubscriptionTier?.id,
      newPulseSubscriptionTierId,
    });

    changeSubscription(
      {
        pulseSubscriptionId: user?.pulseSubscription?.id,
        newPulseSubscriptionTierId,
      },
      {
        onSuccess: () => {
          window.location.replace(Paths.automatedInsights.subscription);
        },
      }
    );
  };

  const mapTierToFeatures = (tiers) => {
    const mapping = {};

    tiers
      ?.filter((tier) => tier.isActive)
      .forEach((tier) => {
        mapping[tier.type] = tier.tierFeatures;
      });

    return mapping;
  };

  const getSubtitleForTier = (tier) => {
    let subTitle = '';
    if (tier.type === PULSE_SUBSCRIPTION_TYPES.Free) {
      subTitle = 'Up to 1 seat';
    } else if (tier.type === PULSE_SUBSCRIPTION_TYPES.Enterprise) {
      subTitle = "Let's talk!";
    } else if (
      [
        PULSE_SUBSCRIPTION_TYPES.EssentialAnnual,
        PULSE_SUBSCRIPTION_TYPES.PremiumAnnual,
        PULSE_SUBSCRIPTION_TYPES.InnovatorAnnual,
      ].includes(tier.type)
    ) {
      subTitle = 'Billed annually';
    }

    return subTitle;
  };

  const handleActionButtonClicked = (tier) => {
    if (tier.type === PULSE_SUBSCRIPTION_TYPES.Enterprise) {
      track(TrackEvent.PULSE_USER_PLAN_SELECTION_SELECTED_ENTERPRISE_TIER, {
        id: tier.id,
        type: tier.type,
        name: tier.name,
      });
      const url = 'https://info.wevo.ai/demo-revenue';
      window.location.href = url;
    } else if (!isFreePlan && !isFreeTrial) {
      track(TrackEvent.PULSE_USER_PLAN_SELECTION_SELECTED_SELF_SERVICE_PAID_TIER, {
        id: tier.id,
        type: tier.type,
        name: tier.name,
      });

      toggleShowContactSupportDialog(true, 'Change subscription', 'change your subscription');
    } else if (tier.type === PULSE_SUBSCRIPTION_TYPES.Free) {
      track(TrackEvent.PULSE_USER_PLAN_SELECTION_SELECTED_FREE_TIER, {
        id: tier.id,
        type: tier.type,
        name: tier.name,
      });

      toggleShowFreeTierDialog(true);
    } else {
      track(TrackEvent.PULSE_USER_PLAN_SELECTION_SELECTED_SELF_SERVICE_PAID_TIER, {
        id: tier.id,
        type: tier.type,
        name: tier.name,
      });

      const firsName = user?.firstName;
      const lastName = user?.lastName;
      const email = user?.email;
      const company = companyInfo.name;

      const reference = `reference=${user?.publicId}`;
      const maxioComponent = `components[${tier['maxio_components'][0]['maxio_component_id']}]`;
      const seatsDetails = `[allocated_quantity]=${numberOfSeats}`;
      const userDetails = `first_name=${encodeURIComponent(firsName)}&last_name=${encodeURIComponent(
        lastName
      )}&email=${encodeURIComponent(email)}&organization=${encodeURIComponent(company)}`;

      const url = `${tier['maxio_url']}?${maxioComponent}${seatsDetails}&${reference}&${userDetails}`;
      window.location.replace(url);
    }
  };

  const tiersToFeatures = mapTierToFeatures(tiers);
  const services = [
    'Takeaways and personas',
    'Recommendations',
    'Persona customization',
    'Journey analysis',
    'Compare',
    'Scoring and benchmarking',
    'SSO',
    'Customer Success Manager',
    'Admin reports and access',
    'Number of Pulses (per user / month)',
  ];

  const servicesInfoContent = [
    <>
      <Typography sx={styles.info}>
        Takeaways: Insights into your target audience's response to your digital asset.
      </Typography>
      <Typography sx={styles.info}>
        Personas: Characteristics of the audience being targeted by the experience. Customize it as you see
        fit!
      </Typography>
    </>,
    <Typography sx={styles.info}>
      Actionable suggestions tailored to improve your user experience based on this Pulse's results.
    </Typography>,
    <Typography sx={styles.info}>
      Modify the initial persona(s) that Pulse recommended, or create a new one to better fit your target
      audience, and then re-run the Pulse through that lens.
    </Typography>,
    <Typography sx={styles.info}>
      Overall experience analysis of a sequence of pages that are part of a flow.
    </Typography>,
    <Typography sx={styles.info}>Comparison analysis between multiple experiences.</Typography>,
    <>
      <Typography sx={styles.info}>
        The Experience Quality Score (EQS) measures your digital experience, based on how Pulse predicts your
        intended audience would react, on three key metrics: Intuitive, Trusted, and Valuable.
      </Typography>
      <Typography sx={styles.info}>
        Benchmarks compare your experience to other experiences of the same type, industry, or product
      </Typography>
    </>,
    <Typography sx={styles.info}>Single sign-on using one set of credentials.</Typography>,
    <Typography sx={styles.info}>Priority support from the WEVO team.</Typography>,
    <Typography sx={styles.info}>Access to usage and team reports.</Typography>,
  ];

  const filteredTiers = tiers?.filter(
    (tier) =>
      tier.isActive &&
      (billingMethod === SeatsBillingMethods.Annually
        ? AnnuallyPulseSubscriptionsTypes.includes(tier.type)
        : MonthlyPulseSubscriptionsTypes.includes(tier.type))
  );

  const orderedTiers = (
    billingMethod === SeatsBillingMethods.Annually
      ? AnnuallyPulseSubscriptionsTypes
      : MonthlyPulseSubscriptionsTypes
  ).map((tierType) => filteredTiers?.find((filteredTier) => tierType === filteredTier.type));

  const getDiscountAmount = (tierType) => {
    switch (tierType) {
      case PULSE_SUBSCRIPTION_TYPES.EssentialAnnual:
        const essentialAnnualMonthPrice = tiers?.find(
          (tier) => tier.type === PULSE_SUBSCRIPTION_TYPES.EssentialMonth
        )?.rules.price;
        const essentialAnnualPrice = tiers?.find(
          (tier) => tier.type === PULSE_SUBSCRIPTION_TYPES.EssentialAnnual
        )?.rules.price;
        return (essentialAnnualMonthPrice - essentialAnnualPrice) * numberOfSeats;

      case PULSE_SUBSCRIPTION_TYPES.PremiumAnnual:
        const premiumMonthPrice = tiers?.find((tier) => tier.type === PULSE_SUBSCRIPTION_TYPES.PremiumMonth)
          ?.rules.price;
        const premiumAnnualPrice = tiers?.find((tier) => tier.type === PULSE_SUBSCRIPTION_TYPES.PremiumAnnual)
          ?.rules.price;
        return (premiumMonthPrice - premiumAnnualPrice) * numberOfSeats;

      case PULSE_SUBSCRIPTION_TYPES.InnovatorAnnual:
        const innovatorMonthPrice = tiers?.find(
          (tier) => tier.type === PULSE_SUBSCRIPTION_TYPES.InnovatorMonth
        )?.rules.price;
        const innovatorAnnualPrice = tiers?.find(
          (tier) => tier.type === PULSE_SUBSCRIPTION_TYPES.InnovatorAnnual
        )?.rules.price;
        return (innovatorMonthPrice - innovatorAnnualPrice) * numberOfSeats;

      default:
        break;
    }
  };

  if (
    _.isNil(user) ||
    isLoadingTiers ||
    !user?.pulseSubscription?.pulseSubscriptionTier?.type ||
    !selfServiceSubscription
  ) {
    return (
      <Box
        sx={{
          height: 'calc(100vh - 65px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <Box>
      <Grid
        container
        sx={{
          paddingTop: { xs: 3, sm: 2 },
          paddingBottom: 2,
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          columnGap: 2,
        }}>
        <Grid item sx={{ mt: 1 }}>
          <SeatsCounter
            numberOfSeats={numberOfSeats}
            setNumberOfSeats={handleNumberOfSeatsChanged}
            titleStyles={{ color: (theme) => theme.palette.text.tertiary }}
            textFieldStyles={{
              inputBackgroundColor: '#FFFFFF',
              inputBorderColor: '#FFFFFF',
              textColor: (theme) => theme.palette.text.tertiary,
              width: '64px',
            }}
            iconColor={'#359DAB'}
          />
        </Grid>
        <Grid item sx={{ height: '40px', mt: 1.5 }}>
          <SeatsBillingMethodToggle
            billingMethod={billingMethod}
            setBillingMethod={handleBillingMethodChanged}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        className="transparentScrollBar"
        alignItems="flex-end"
        sx={{ marginTop: -1, flexWrap: 'nowrap', overflowX: 'auto' }}>
        <Grid
          item
          sx={{
            minWidth: '300px',
            marginBottom: '80px',
          }}
          xs={2}>
          <Grid container spacing={2} alignItems="flex-end" sx={{ marginBottom: 2 }}>
            {services.map((service, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  onMouseEnter={() => setHoveredLine(index)}
                  onMouseLeave={() => setHoveredLine(null)}
                  key={index}
                  sx={{
                    ...styles.fieldSubTitleGrid,
                    backgroundColor: hoveredLine === index && 'rgba(53, 157, 171, 0.06)',
                  }}>
                  <Typography variant="body2" sx={{ ...styles.fieldText }}>
                    {service}
                  </Typography>
                  {index < services.length - 1 && (
                    <ServicesTooltip
                      placement="right"
                      title={servicesInfoContent[index]}
                      PopperProps={{ sx: { p: 2 } }}>
                      <IconButton
                        aria-label="services"
                        size="small"
                        sx={{ marginTop: -2.5, width: '25px', height: '25px' }}>
                        <InfoIconFilled fill="#2E5968" />
                      </IconButton>
                    </ServicesTooltip>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        {orderedTiers.map((tier, index) => {
          const isTierSelected = tier.id === userSelectedTierId;
          const subTitle = getSubtitleForTier(tier);
          const tierToFeatures = tiersToFeatures[tier.type];

          const includeFeatures = [
            tierToFeatures.keyFindingsAndPersonas,
            tierToFeatures.recommendations,
            tierToFeatures.personaCustomization,
            tierToFeatures.journeyAnalysis,
            tierToFeatures.compareAnalysis,
            tierToFeatures.scoringAndBenchmarking,
            tierToFeatures.sso,
            tierToFeatures.csm,
            tierToFeatures.adminReportsAndAccess,
          ];

          const displayTotalPrice = [
            PULSE_SUBSCRIPTION_TYPES.EssentialAnnual,
            PULSE_SUBSCRIPTION_TYPES.EssentialMonth,
            PULSE_SUBSCRIPTION_TYPES.PremiumAnnual,
            PULSE_SUBSCRIPTION_TYPES.PremiumMonth,
            PULSE_SUBSCRIPTION_TYPES.InnovatorAnnual,
            PULSE_SUBSCRIPTION_TYPES.InnovatorMonth,
          ].includes(tier.type);

          return (
            <SelectableSubscriptionCard
              key={index}
              user={user}
              tier={tier}
              title={tier.name}
              subTitle={subTitle}
              price={tier.rules.price || 0}
              totalPrice={displayTotalPrice && numberOfSeats * tier.rules.price}
              includeFeatures={includeFeatures}
              numberOfPulses={
                tier.type === PULSE_SUBSCRIPTION_TYPES.Enterprise
                  ? 'Up to 50 Pulses'
                  : `${tierToFeatures.usagePerSeat} Pulses`
              }
              hoveredLine={hoveredLine}
              setHoveredLine={setHoveredLine}
              subscriptionType={tier.type}
              numberOfSeats={numberOfSeats}
              getDiscountAmount={getDiscountAmount}
              isTierSelected={isTierSelected}
              onActionButtonClick={handleActionButtonClicked}
              isFreePlan={isFreePlan}
              isFreeTrial={isFreeTrial}
            />
          );
        })}
      </Grid>
      <Box sx={{ paddingTop: 3, paddingBottom: 6 }}>
        <CustomerTestimonials />
      </Box>
      <FreeTierDialog
        open={showFreeTierDialog}
        approveFreeTier={() => approveFreeTier(FREE_TIER_SUBSCRIPTION_ID)}
        closeCallback={handleClose}
      />
      <ContactSupportModal
        open={showContactSupportDialog}
        closeCallback={toggleShowContactSupportDialog}
        modalHeader={modalHeader}
        modalAction={modalAction}
      />
    </Box>
  );
};

export default SubscriptionSelection;
