import { Box } from '@mui/material';
import { InfoNotice } from '../../../../components/Notice';

function QuestionNotice({ noticeText, spacing = 1 }) {
  return (
    <Box my={spacing} sx={{ border: '1px solid #C7D6DF', borderRadius: 2, padding: 2 }}>
      <InfoNotice message={noticeText} />
    </Box>
  );
}

export { QuestionNotice };
