import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { CustomSelect } from '../../../../../components';
import {
  CustomQuestionTypeOptionsByType,
  CustomQuestionTypes,
  MultipleSelectionRequirementsOptions,
  MultiSelectOptions,
} from '../../../constants';
import { makeEmptyArray } from '../../../helpers/questionTypes';

function ChoiceRequirementsSelect({ selectedRangeType, selectedNumber, onChange }) {
  const limits = CustomQuestionTypeOptionsByType[CustomQuestionTypes.MultiSelect].choiceLimits;
  const max = limits.max;
  const numberOptions = makeEmptyArray(max).map((_, index) => ({ label: index + 1, value: index + 1 }));

  const shouldShowNumberOptions =
    !isEmpty(selectedRangeType) && selectedRangeType !== MultiSelectOptions.AllThatApply;
  const requirementsBoxWidth = shouldShowNumberOptions ? '65%' : '100%';
  const numberSelectWidth = '35%';

  const handleRangeTypeChanged = (newRangeType) => {
    if (!onChange || !newRangeType) return;
    onChange({ rangeType: newRangeType, number: selectedNumber });
  };

  const handleNumberChanged = (newNumber) => {
    if (!onChange || !newNumber) return;
    onChange({ rangeType: selectedRangeType, number: newNumber });
  };

  return (
    <Box display="flex" sx={{ gap: 1 }}>
      <Box sx={{ width: { xs: '100%', sm: '100%', md: requirementsBoxWidth } }}>
        <CustomSelect
          size={'medium'}
          value={selectedRangeType || ''}
          items={[{ label: 'Select requirement', value: '' }].concat(MultipleSelectionRequirementsOptions)}
          onChange={(ev) => handleRangeTypeChanged(ev.target.value)}
        />
      </Box>
      <Box
        sx={{
          width: { xs: '100%', sm: '100%', md: numberSelectWidth },
          display: shouldShowNumberOptions ? 'initial' : 'none',
        }}>
        <CustomSelect
          size={'medium'}
          value={selectedNumber || ''}
          items={[{ label: 'Select number of options', value: '' }].concat(numberOptions)}
          onChange={(ev) => handleNumberChanged(ev.target.value)}
          maxHeight="350px"
        />
      </Box>
    </Box>
  );
}

export default ChoiceRequirementsSelect;
