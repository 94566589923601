import {
  Box,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { generatePath, Link as RouteLink } from 'react-router-dom';
import { ReactComponent as OpenLinkIcon } from '../../assets/pulse-open-link.svg';
import {
  AutomatedInsightSessionType,
  MAX_PULSE_COMPARE_SESSIONS,
} from '../../modules/automated-insights/constants';
import { Paths } from '../../routes';
import SearchInputField from './SearchInputField';
import CustomTooltip from './ui/Tooltip';

const SessionList = ({
  sessions,
  header,
  headerId,
  selectedSessionIds,
  linkedSessions,
  canSelectMultiple,
  onSessionClick,
}) => {
  const isLinked = (sessionId) => {
    return !!linkedSessions?.find((session) => session?.id === sessionId);
  };

  const cannotBeCompared = (sessionType, sessionResults) => {
    const sessionWithGeneratedSegment = sessionResults?.find((session) => !session?.segmentId);
    const sessionCompletedAt = sessionWithGeneratedSegment?.completedAt;
    // cutoff date for compares to exclude any sessions that are pre-vision
    const targetDate =
      sessionType === AutomatedInsightSessionType.Experience
        ? new Date('2024-06-15T00:00:00.000Z')
        : new Date('2024-01-30T00:00:00.000Z');
    return new Date(sessionCompletedAt) < targetDate;
  };

  const isDisabled = (sessionId, sessionType, sessionResults) => {
    return (!canSelectMultiple && isLinked(sessionId)) || cannotBeCompared(sessionType, sessionResults);
  };

  const isChecked = (sessionId) => {
    return selectedSessionIds?.includes(sessionId);
  };

  const getTooltipMessage = (sessionId, sessionType, sessionResults) => {
    if (!canSelectMultiple && isLinked(sessionId)) {
      return 'This Pulse has already been added';
    } else if (cannotBeCompared(sessionType, sessionResults)) {
      return 'Older Pulses do not support Compare. Choose a more recent Pulse or run a new one with the same asset.';
    }
    return '';
  };

  return (
    <List
      aria-labelledby={headerId}
      dense
      subheader={
        <ListSubheader
          component="div"
          id={headerId}
          disableSticky
          sx={{
            backgroundColor: '#082434',
            color: 'rgba(255, 255, 255, 0.60)',
            fontSize: '11px',
            fontWeight: 700,
          }}>
          {header}
        </ListSubheader>
      }
      sx={{ paddingBottom: 0 }}>
      {sessions?.map((session) => (
        <ListItem
          key={session?.id}
          disableGutters
          disablePadding
          sx={{ color: (theme) => theme.palette.text.secondary, display: 'flex', flexWrap: 'wrap' }}>
          <CustomTooltip
            title={getTooltipMessage(session?.id, session?.type, session?.automatedInsightSessionResults)}
            placement="right"
            color={'rgba(255, 255, 255, 0.6)'}
            maxWidth={isLinked(session?.id) && '100%'}>
            <span>
              <ListItemButton
                disableRipple
                disabled={isDisabled(session?.id, session?.type, session?.automatedInsightSessionResults)}
                sx={{ paddingY: 0, '&:hover .open-link': { display: 'initial' } }}>
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <Checkbox
                    checked={isChecked(session?.id)}
                    edge="start"
                    onChange={() => onSessionClick(session?.type, session?.id)}
                    tabIndex={-1}
                    disableRipple
                    size="small"
                    sx={{ color: '#CACCCD' }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={session?.name}
                  primaryTypographyProps={{ fontSize: '12px' }}
                  sx={{ flexGrow: 1, marginRight: 2 }}
                />
                <Box sx={{ display: 'none' }} className="open-link">
                  <RouteLink
                    to={generatePath(Paths.automatedInsights.session, {
                      sessionId: session?.id,
                    })}
                    aria-label="open link"
                    target="_blank"
                    rel="noopener noreferrer">
                    <OpenLinkIcon />
                  </RouteLink>
                </Box>
              </ListItemButton>
            </span>
          </CustomTooltip>
        </ListItem>
      ))}
    </List>
  );
};

const AllSessionsList = ({
  previousSessionsByTimeframe,
  selectedSessionIds,
  linkedSessions,
  canSelectMultiple,
  onSessionClick,
}) => {
  return (
    <>
      {previousSessionsByTimeframe.recent.length > 0 && (
        <SessionList
          sessions={previousSessionsByTimeframe.recent}
          selectedSessionIds={selectedSessionIds}
          linkedSessions={linkedSessions}
          header={'Recent'}
          headerId={'recent'}
          canSelectMultiple={canSelectMultiple}
          onSessionClick={onSessionClick}
        />
      )}
      {previousSessionsByTimeframe.week.length > 0 && (
        <SessionList
          sessions={previousSessionsByTimeframe.week}
          selectedSessionIds={selectedSessionIds}
          linkedSessions={linkedSessions}
          header={'Previous 7 days'}
          headerId={'week'}
          canSelectMultiple={canSelectMultiple}
          onSessionClick={onSessionClick}
        />
      )}
      {previousSessionsByTimeframe.month.length > 0 && (
        <SessionList
          sessions={previousSessionsByTimeframe.month}
          selectedSessionIds={selectedSessionIds}
          linkedSessions={linkedSessions}
          header={'Previous 30 days'}
          headerId={'month'}
          canSelectMultiple={canSelectMultiple}
          onSessionClick={onSessionClick}
        />
      )}
      {previousSessionsByTimeframe.earlier.length > 0 && (
        <SessionList
          sessions={previousSessionsByTimeframe.earlier}
          selectedSessionIds={selectedSessionIds}
          linkedSessions={linkedSessions}
          header={'Earlier'}
          headerId={'earlier'}
          canSelectMultiple={canSelectMultiple}
          onSessionClick={onSessionClick}
        />
      )}
    </>
  );
};

const SessionSelector = ({
  previousSessions,
  selectedSessionIds,
  linkedSessions,
  canSelectMultiple = true,
  onSessionSelectionChange,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const previousSessionsByTimeframe = useMemo(() => {
    const now = new Date();

    return (previousSessions ?? []).reduce(
      (acc, cur) => {
        // filter out compares because compare sessions cannot be compared
        if (cur.type === AutomatedInsightSessionType.Compare) {
          return acc;
        }

        const withoutResults =
          !cur.automatedInsightSessionResults?.length ||
          cur.automatedInsightSessionResults?.every((results) => !results?.completedAt);

        if (withoutResults) {
          return acc;
        }

        if (searchTerm?.length && !cur.name?.toLowerCase().includes(searchTerm.toLowerCase())) {
          return acc;
        }

        if (!cur.createdAt) {
          acc.earlier.push(cur);
          return acc;
        }

        const daysDelta = Math.floor((now - new Date(cur.createdAt)) / 1000 / 60 / 60 / 24);

        if (daysDelta < 1) {
          acc.recent.push(cur);
        } else if (daysDelta <= 7) {
          acc.week.push(cur);
        } else if (daysDelta <= 30) {
          acc.month.push(cur);
        } else {
          acc.earlier.push(cur);
        }

        return acc;
      },
      {
        recent: [],
        week: [],
        month: [],
        earlier: [],
      }
    );
  }, [previousSessions, searchTerm]);

  const allSessionByTimeframeAreEmpty = useMemo(() => {
    for (const key in previousSessionsByTimeframe) {
      if (previousSessionsByTimeframe[key]?.length > 0) {
        return false;
      }
    }
    return true;
  }, [previousSessionsByTimeframe]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSessionClick = (type, sessionId) => {
    let newSessionIds;
    if (canSelectMultiple) {
      if (selectedSessionIds?.includes(sessionId)) {
        newSessionIds = selectedSessionIds?.filter((id) => id !== sessionId);
      } else {
        newSessionIds = [...selectedSessionIds, sessionId];
      }
      onSessionSelectionChange(newSessionIds);
    } else {
      onSessionSelectionChange([sessionId]);
    }
  };

  return (
    <Grid
      container
      sx={{
        height: '330px',
        flexDirection: { xs: 'column', md: 'row' },
        border: '1px solid rgba(217, 217, 217, 0.20)',
        borderRadius: '10px',
        backgroundColor: '#082434',
        flexWrap: 'nowrap',
      }}>
      <Grid item sx={{ flexGrow: 1, minHeight: 0 }}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingX: 2,
            paddingTop: 2,
            paddingBottom: 1,
            rowGap: 1,
          }}>
          <Box>
            <SearchInputField
              searchTerm={searchTerm}
              onInputChange={handleSearchInputChange}
              borderColor={'rgba(217, 217, 217, 0.20)'}
              color={'rgba(255, 255, 255, 0.80)'}
              hoverBorderColor={'rgba(217, 217, 217, 0.40)'}
              iconColor={'rgba(217, 217, 217, 0.40)'}
            />
          </Box>
          <Box
            sx={{
              height: '100%',
              flexGrow: 1,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}>
            {allSessionByTimeframeAreEmpty ? (
              <Typography sx={{ fontSize: 12, color: (theme) => theme.palette.text.secondary, paddingY: 1 }}>
                No Pulses found
              </Typography>
            ) : (
              <AllSessionsList
                previousSessionsByTimeframe={previousSessionsByTimeframe}
                selectedSessionIds={selectedSessionIds}
                linkedSessions={linkedSessions}
                canSelectMultiple={canSelectMultiple}
                onSessionClick={handleSessionClick}
              />
            )}
          </Box>
          {canSelectMultiple && (
            <Box>
              <Typography sx={{ fontSize: 12, color: 'rgba(255, 255, 255, .60)', textAlign: 'end' }}>
                {selectedSessionIds?.length === 1
                  ? `1 pulse selected (Max. ${MAX_PULSE_COMPARE_SESSIONS})`
                  : `${selectedSessionIds?.length} Pulses selected (Max. ${MAX_PULSE_COMPARE_SESSIONS})`}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SessionSelector;
