import { Box, Divider, Typography } from '@mui/material';
import { Fragment, useMemo } from 'react';
import { HIDDEN_SEGMENT_IDS_BY_ATTRIBUTE_ID } from '../../../../../../modules/wevos/constants';
import { CustomFormControlCheckbox } from '../../../../components';
import { getNumberOfSegmentsSelected, getQuotasTotal } from '../../helpers';
import QuotaPercentageInput from './QuotaPercentageInput';

const AttributeQuotasTable = ({
  attributeId,
  attributeSegments,
  includeCheckbox,
  includeTotal,
  onCheckboxChange,
  onQuotaChange,
}) => {
  const segmentsToDisplay = useMemo(() => {
    let segments;
    const hiddenSegments = HIDDEN_SEGMENT_IDS_BY_ATTRIBUTE_ID[attributeId];
    segments = !!hiddenSegments
      ? attributeSegments?.filter((segment) => !hiddenSegments.includes(segment?.id))
      : attributeSegments;
    if (!includeCheckbox) {
      segments = segments?.filter((segment) => segment?.isSelected);
    }
    return segments;
  }, [attributeId, attributeSegments, includeCheckbox]);

  const quotasTotal = getQuotasTotal(segmentsToDisplay);
  const invalidTotal = quotasTotal !== 100;

  return (
    <Box>
      <Box sx={{ border: '1px solid #C7D6DF', borderRadius: '10px' }}>
        {segmentsToDisplay?.map((segment, index) => (
          <Fragment key={segment?.id}>
            <Box display="flex" alignItems="center" justifyContent="space-between" paddingX={1}>
              {includeCheckbox && onCheckboxChange ? (
                <CustomFormControlCheckbox
                  value={segment?.id}
                  label={segment?.label}
                  checked={segment?.isSelected}
                  onChange={(ev) =>
                    onCheckboxChange({
                      segment,
                      isSelected: ev.target.checked,
                    })
                  }
                />
              ) : (
                <Typography sx={{ paddingY: 1, marginRight: 3, fontSize: '13px' }}>
                  {segment?.label}
                </Typography>
              )}
              <QuotaPercentageInput
                attributeId={attributeId}
                segment={segment}
                percentage={segment?.percentage}
                isDisabled={!segment?.isSelected}
                isReadOnly={getNumberOfSegmentsSelected(attributeSegments) === 1}
                onChange={({ value }) => onQuotaChange({ segment, value })}
              />
            </Box>
            {index !== segmentsToDisplay?.length - 1 && <Divider sx={{ borderColor: '#C7D6DF' }} />}
          </Fragment>
        ))}
      </Box>
      {includeTotal && (
        <Box mt={1}>
          <Typography textAlign="right" fontSize={11} fontWeight={700}>
            Total:{' '}
            <Typography
              component="span"
              fontSize={11}
              fontWeight={700}
              sx={{ color: (theme) => (invalidTotal ? theme.palette.error.main : 'initial') }}>
              {quotasTotal}%
            </Typography>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AttributeQuotasTable;
