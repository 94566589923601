import { Box, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { Fragment, useMemo } from 'react';
import { ReactComponent as PersonasIcon } from '../../../src/assets/Personas.svg';
import { ProgressKeys, TaskStatus } from '../../modules/automated-insights/constants';
import { InfoBox, WaitingMessage } from './InfoBox';
import SplashLoadingIndicator from './SplashLoadingIndicator';

const STATUS_MESSAGES = {
  pending: 'Request to generate persona intent is pending',
  enqueued: 'Getting ready to generate persona intent',
  running: 'Generating persona intent',
  failed: 'Failed - please try again',
};

const PersonaIntent = ({
  personaIntentData,
  taskStatus,
  taskProgress,
  isTaskDelayed,
  isTaskWaiting,
  hideReportContent,
  isSessionOwner,
}) => {
  const showTaskStatus = useMemo(
    () =>
      TaskStatus.Running &&
      ProgressKeys.PersonaIntent === taskProgress?.progressKey &&
      personaIntentData?.length === 0,
    [taskProgress, personaIntentData]
  );

  const showLoadingIndicatorForShareLink = !isSessionOwner && hideReportContent;

  if (showTaskStatus) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          paddingX: '5%',
        }}>
        <SplashLoadingIndicator
          message={STATUS_MESSAGES?.[taskStatus] || STATUS_MESSAGES.pending}
          isDelayed={isTaskDelayed}
          isFailed={taskStatus === TaskStatus.Failed}
        />
        {isTaskWaiting && (
          <Box
            sx={{
              width: 'inherit',
              height: 'fit-content',
              position: 'absolute',
              bottom: 0,
              paddingY: 0,
              display: [TaskStatus.Pending, TaskStatus.Enqueued].includes(taskStatus) ? 'initial' : 'none',
            }}
            p={2}>
            <InfoBox sx={{ height: '100%' }} isWarning={true}>
              <WaitingMessage />
            </InfoBox>
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <>
        {!personaIntentData || showLoadingIndicatorForShareLink ? (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Box
            className="lightContainer"
            sx={{
              height: '100%',
              paddingBottom: 6,
              fontSize: '14px',
              overflow: hideReportContent ? 'hidden' : 'auto',
            }}>
            <Box alignItems={'center'} sx={{ marginBottom: 1.25, marginLeft: 2.25 }}>
              <Grid container spacing={1}>
                <Grid item flexWrap={'nowrap'} mt={0.25}>
                  <PersonasIcon fontSize="small" height={'22px'} />
                </Grid>
                <Grid item mt={0.5} ml={0.2}>
                  <Typography fontSize={13} fontStyle="italic">
                    Persona Intent - Understand why people are coming to your website.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {personaIntentData.map(
              (intent, index) =>
                !_.isEmpty(intent?.name) && (
                  <Fragment key={index}>
                    <Box
                      key={`name-${index}`}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingX: { xs: 2, sm: 3 },
                        mb: '12px',
                      }}>
                      <Tooltip
                        title={<Grid sx={{ padding: 0.5 }}>{intent?.summary}</Grid>}
                        arrow
                        placement="right">
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 600,
                            color: '#00A4FF',
                          }}>
                          {intent?.name}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box
                      key={`intent-${index}`}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingX: { xs: 2, sm: 3 },
                        paddingBottom: '21px',
                      }}>
                      <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{intent?.intent}</Typography>
                    </Box>
                  </Fragment>
                )
            )}
          </Box>
        )}
      </>
    );
  }
};

export default PersonaIntent;
