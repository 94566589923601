import _ from 'lodash';
import {
  AutomatedInsightSessionType,
  ClusterTypeNames,
  IncludeExperienceRecommendations,
  MAX_PULSE_COMPARE_SESSIONS,
  MAX_PULSE_JOURNEY_ASSETS,
  MAX_PULSE_PAGE_ASSETS,
  Percentiles,
  ProgressKeys,
  SentimentTypes,
} from './constants';

const AUTOMATED_INSIGHT_SESSION_SHARE_LINK_LENGTH = 12;

/**
 * Determines if link is a share link:
 *  - Must be a string
 *  - Must not be fully numeric
 *  - Must be at least 12 characters long
 *
 * @param {string} link
 * @returns
 */
export function isAutomatedInsightSessionShareLink(link) {
  return typeof link === 'string' && link?.length === AUTOMATED_INSIGHT_SESSION_SHARE_LINK_LENGTH;
}

/**
 * Determines if user is permitted to create / update automated insights sessions
 * @param {object} user
 * @param {object} userCustomizations
 * @returns
 */
export function canUseAutomatedInsightSessions(user, userCustomizations) {
  if (_.isEmpty(user)) {
    return false;
  }

  const companyHasAutomatedInsightsEnabled = userCustomizations?.automatedInsights?.enabled !== false;

  const allowedUserIds = userCustomizations?.automatedInsights?.allowedUserIds ?? [];

  const userHasAutomatedInsightsEnabled =
    allowedUserIds?.length === 0 || allowedUserIds.includes(String(user?.id));

  return companyHasAutomatedInsightsEnabled && userHasAutomatedInsightsEnabled;
}

/**
 * Determines if user can access Pro/Core.
 */
export function canUseCore(userCustomizations) {
  return userCustomizations?.core?.enabled !== false;
}

/**
 * Determines if the user is pulse-only.
 */
export function canOnlyUseAutomatedInsightSessions(user, userCustomizations) {
  const hasAutomatedInsightEnabled = canUseAutomatedInsightSessions(user, userCustomizations);
  const hasCoreEnabled = canUseCore(userCustomizations);
  const isUserlytics = Boolean(userCustomizations?.integrations?.userlytics);
  return hasAutomatedInsightEnabled && !hasCoreEnabled && !isUserlytics;
}

/**
 * Determines if the user is pro-only.
 */
export function canOnlyUseWevoPro(user, userCustomizations) {
  const hasAutomatedInsightEnabled = canUseAutomatedInsightSessions(user, userCustomizations);
  const hasCoreEnabled = canUseCore(userCustomizations);
  return hasCoreEnabled && !hasAutomatedInsightEnabled;
}

/**
 * Determines if the user can launch pulse experiences. Note: still requires a check that the user can use pulse at all.
 */
export function canUsePulseExperiences(userCustomizations) {
  return true;
}

export function canUsePulseCompares(userCustomizations) {
  return true;
}

export function secondsToMinutes(seconds) {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60 ? `${Math.round(seconds % 60)}s` : '';
  return `${mins}m${secs}`;
}

export function sentimentBackgroundColor(sentiment) {
  switch (sentiment) {
    case SentimentTypes.Negative:
      return 'rgba(243, 91, 81, 0.30)';
    case SentimentTypes.Contentious:
      return 'rgba(230, 243, 81, 0.30)';
    default:
      return 'rgba(129, 186, 138, 0.30)';
  }
}

export function sentimentBorderColor(sentiment) {
  switch (sentiment) {
    case SentimentTypes.Negative:
      return '#F35B51';
    case SentimentTypes.Contentious:
      return 'rgba(230, 243, 81, 0.30)';
    default:
      return '#81BA8A';
  }
}

export function sentimentToCluster(sentiment) {
  switch (sentiment) {
    case SentimentTypes.Negative:
      return ClusterTypeNames.Dislikes;
    case SentimentTypes.Contentious:
      return ClusterTypeNames.Contentious;
    default:
      return ClusterTypeNames.Likes;
  }
}

export function pointsToSentiment(points) {
  const allPositive = points?.every((point) => point?.sentiment === SentimentTypes.Positive);
  const allNegative = points?.every((point) => point?.sentiment === SentimentTypes.Negative);
  if (allPositive) {
    return SentimentTypes.Positive;
  } else if (allNegative) {
    return SentimentTypes.Negative;
  } else {
    return SentimentTypes.Contentious;
  }
}

export function formatKeyFindingsText(keyFindings) {
  return keyFindings.replace(
    /(?:__|[*#])|\[(.*?)\]\(.*?\)|`([^`]+)`|!\[.*?\]\(.*?\)|\[.*?\]\(.*?\)|<\/?[^>]+(>|$)/g,
    '$1'
  );
}

export const AssetBadgeLabelStyle = {
  Alphabetical: 'alphabetical',
  Numeric: 'numeric',
};

const getNumericBadge = (index) => index + 1;

const getAlphabeticalBadge = (index) => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return letters[index % letters.length];
};

export const getBadgeLabel = (index, badgeLabelStyle = AssetBadgeLabelStyle.Numeric) =>
  badgeLabelStyle === 'alphabetical' ? getAlphabeticalBadge(index) : getNumericBadge(index);

/**
 * Convenience method for determining which progress keys could be transitioned to from the current progress key,
 * which may differ depending on the session type and feature flags, e.g. concurrent processing of some steps
 * @param {AutomatedInsightSessionType} sessionType
 * @param {ProgressKeys} progressKey
 * @returns
 */
export const getProgressKeysAfter = (sessionType, progressKey) => {
  switch (sessionType) {
    case AutomatedInsightSessionType.Page:
      return getPageProgressKeysAfter(progressKey);
    case AutomatedInsightSessionType.Experience:
      return getExperienceProgressKeysAfter(progressKey);
    case AutomatedInsightSessionType.Compare:
      return getCompareProgressKeysAfter(progressKey);
    default:
      return [];
  }
};

const getPageProgressKeysAfter = (progressKey) => {
  switch (progressKey) {
    case ProgressKeys.Initialized:
      return [ProgressKeys.TextExtract];
    case ProgressKeys.TextExtract:
      // Generated audience goes to Target Audience, Custom goes directly to Insights
      return [ProgressKeys.TargetAudience, ProgressKeys.Insights];
    case ProgressKeys.TargetAudience:
      return [ProgressKeys.Insights];
    case ProgressKeys.Insights:
      return [ProgressKeys.HeatmapPoints, ProgressKeys.PersonaIntent, ProgressKeys.RecommendedExperiments];
    case ProgressKeys.HeatmapPoints:
      return [ProgressKeys.PersonaIntent, ProgressKeys.RecommendedExperiments];
    case ProgressKeys.PersonaIntent:
      return [ProgressKeys.RecommendedExperiments];
    case ProgressKeys.RecommendedExperiments:
      return [ProgressKeys.Done];
    default:
      return [ProgressKeys.Initialized];
  }
};

const getExperienceProgressKeysAfter = (progressKey) => {
  switch (progressKey) {
    case ProgressKeys.Initialized:
      return [ProgressKeys.TextExtract];
    case ProgressKeys.TextExtract:
      // Generated audience goes to Target Audience, Custom goes directly to Insights
      return [ProgressKeys.TargetAudience, ProgressKeys.Insights];
    case ProgressKeys.TargetAudience:
      return [ProgressKeys.Insights];
    case ProgressKeys.Insights:
      return IncludeExperienceRecommendations ? [ProgressKeys.RecommendedExperiments] : [ProgressKeys.Done];
    case ProgressKeys.HeatmapPoints:
      return [ProgressKeys.PersonaIntent, ProgressKeys.RecommendedExperiments];
    case ProgressKeys.PersonaIntent:
      return [ProgressKeys.RecommendedExperiments];
    case ProgressKeys.RecommendedExperiments:
      return [ProgressKeys.Done];
    default:
      return [ProgressKeys.Initialized];
  }
};

const getCompareProgressKeysAfter = (progressKey) => {
  switch (progressKey) {
    case ProgressKeys.Initialized:
      return [ProgressKeys.Insights];
    case ProgressKeys.Insights:
      return [ProgressKeys.Done];
    default:
      return [ProgressKeys.Initialized];
  }
};

/**
 * Filters rejected Dropzone files by Dropzone error code
 */
export const getFilesWithError = (fileRejections, errorCode) => {
  return fileRejections.filter((file) => file?.errors?.some((error) => error?.code === errorCode));
};

export const withinFileLimit = (numFiles, sessionType) => {
  if (sessionType === AutomatedInsightSessionType.Page) {
    return numFiles <= MAX_PULSE_PAGE_ASSETS;
  } else {
    return numFiles <= MAX_PULSE_JOURNEY_ASSETS;
  }
};

export const exceedFileAmount = (numFiles, sessionType) => {
  if (sessionType === AutomatedInsightSessionType.Page) {
    return numFiles - MAX_PULSE_PAGE_ASSETS;
  } else if (sessionType === AutomatedInsightSessionType.Experience) {
    return numFiles - MAX_PULSE_JOURNEY_ASSETS;
  } else {
    return numFiles - MAX_PULSE_COMPARE_SESSIONS;
  }
};

export const getPercentileMessage = (percentileType, percentile) => {
  return Percentiles?.[percentileType]?.[percentile];
};

export const ProgressKeyHandlers = [
  // Initialization
  {
    matches: (taskProgress, session) =>
      [ProgressKeys.Initialized, ProgressKeys.TextExtract, ProgressKeys.TargetAudience].includes(
        taskProgress?.progressKey
      ),
    // If we are looking at one of the inital grouped steps, we care about startedAt (timeElapsed is by step)
    // We wouldn't be able to use this code for other grouped progress keys because we are assuming that
    // started at for the whole process is where this group started (not halfway through).
    // If we wanted to group later steps, we would need to capture the start time for the first step
    // in that group.
    getTimingEstimate: (taskProgress, session) =>
      [ProgressKeys.Initialized, ProgressKeys.TextExtract, ProgressKeys.TargetAudience].reduce((acc, key) => {
        return acc + (taskProgress?.timingEstimates?.[_.camelCase(key)] || 0);
      }, 0),
    getTimeElapsed: (taskProgress, session) => (new Date() - new Date(taskProgress.startedAt)) / 1000,
  },
  // quant and recommendations --- maybe quant is quant - (everything else)
  {
    matches: (taskProgress, session) =>
      [ProgressKeys.RecommendedExperiments, ProgressKeys.Quant, ProgressKeys.Tags].includes(
        taskProgress?.progressKey
      ),
    getTimingEstimate: (taskProgress, session) => {
      return Math.max(
        ...[ProgressKeys.RecommendedExperiments, ProgressKeys.Quant].map(
          (key) => taskProgress?.timingEstimates?.[_.camelCase(key)] || 0
        )
      );
    },
    getTimeElapsed: (taskProgress, session) => taskProgress?.timingEstimates?.timeElapsed || 0,
  },
  // default handler
  {
    matches: (taskProgress, session) => true,
    getTimingEstimate: (taskProgress, session) =>
      taskProgress?.timingEstimates?.[_.camelCase(taskProgress?.progressKey)] || 0,
    getTimeElapsed: (taskProgress, session) => taskProgress?.timingEstimates?.timeElapsed || 0,
  },
];
