import { Box, styled, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as AlertIcon } from '../../../assets/alert-icon.svg';
import { ReactComponent as PencilIcon } from '../../../assets/pencil.svg';
import { TrackEvent } from '../../analytics';
import { IntakeTooltip } from '../../intake/components';

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isEditing',
})(({ theme, isEditing }) => ({
  '& .MuiInputBase-root': {
    color: '#FFFFFF',
    fontWeight: isEditing ? 400 : 600,
    backgroundColor: isEditing ? '#4D79B3' : 'transparent',
    borderRadius: '27px',
  },
  '& .MuiInputBase-input': {
    padding: '4px 12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& fieldset': { border: 'none' },
}));

const NameTextField = ({ name, onNameChanged, isReadOnly = true }) => {
  const [inputValue, setInputValue] = useState(name);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { track } = useAnalytics();
  const wevoId = useParams();

  // this updates the name because there could potentially be
  // two components mounted at the same time that could change the name
  useEffect(() => {
    setInputValue(name);
  }, [name]);

  const handleNameClick = () => {
    if (isReadOnly) {
      return;
    }

    setIsEditingName(true);
    track(TrackEvent.CLICKED_STUDY_NAME_FROM_APP_BAR, {
      wevoId,
    });
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  //save new name when clicking outside of the text field
  const handleBlur = async () => {
    if (isReadOnly || !isEditingName || !onNameChanged) {
      return;
    }

    let newName = inputValue.trim();
    setIsEditingName(false);

    if (inputValue === name) {
      return;
    }

    if (inputValue?.length > 0) {
      setIsUpdating(true);
    } else {
      newName = name;
      setInputValue(newName);
    }

    await onNameChanged({ updateFields: { name: newName } });
    setIsUpdating(false);
  };

  // save new name when enter key is pressed
  const handleKeyDown = async (e) => {
    if (isReadOnly || !isEditingName || !onNameChanged) {
      return;
    }

    if (e.key === 'Enter') {
      let newName = inputValue.trim();
      setIsEditingName(false);

      if (inputValue === name) {
        return;
      }

      if (inputValue?.length > 0) {
        setIsUpdating(true);
      } else {
        newName = name;
        setInputValue(newName);
      }

      await onNameChanged({ updateFields: { name: newName } });
      setIsUpdating(false);
    }
  };

  if (isReadOnly) {
    return (
      <Typography noWrap fontSize={18}>
        {name}
      </Typography>
    );
  } else if (!isEditingName) {
    return (
      <>
        {!isUpdating ? (
          <IntakeTooltip title="Edit study name">
            <Box
              onClick={handleNameClick}
              sx={{
                maxWidth: '90%',
                display: 'flex',
                alignItems: 'center',
                columnGap: 1.5,
                cursor: 'pointer',
              }}>
              <Typography noWrap fontSize={18} fontWeight={600}>
                {name}
              </Typography>

              <PencilIcon style={{ height: '16px', width: '16px', fill: '#FFFFFF' }} />
            </Box>
          </IntakeTooltip>
        ) : (
          <Typography fontSize={14}>Updating...</Typography>
        )}
      </>
    );
  }

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <StyledTextField
        variant="outlined"
        value={inputValue}
        autoFocus
        placeholder="Name this study"
        onChange={handleInputChange}
        onBlur={handleBlur}
        onClick={handleNameClick}
        onKeyDown={handleKeyDown}
        isEditing={isEditingName}
        InputProps={{
          startAdornment: <PencilIcon style={{ height: '16px', width: '16px', fill: '#FFFFFF' }} />,
        }}
        sx={{ width: { xs: '90%', lg: '80%' } }}
      />
      {inputValue?.length < 1 && (
        <Box ml={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <IntakeTooltip title="Study name is required">
            <AlertIcon style={{ height: '18px', width: '18px' }} />
          </IntakeTooltip>
        </Box>
      )}
    </Box>
  );
};

export default NameTextField;
