import InfoIcon from '@mui/icons-material/Info';
import { Box, CircularProgress, MenuItem, Paper, Select, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import axios from '../../../api';
import {
  ExpectationOutcome,
  ExpectationOutcomeToColor,
  ExpectationOutcomeToLabel,
} from '../../../modules/report/constants';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import { RightDrawerButton } from '../components/RightDrawer';
import ExpectationQuotes from './ExpectationQuotes';
import ExpectationsGraph from './ExpectationsGraph';

export const useExpectationsStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: '20px',
  },
  dropdownItem: {
    whiteSpace: 'normal',
  },
  chartItemContainer: {
    paddingTop: 8,
    maxWidth: '170px',
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
  chartContainer: {
    width: '160px',
    marginLeft: theme.spacing(-0.25),
  },
  graphContainer: {
    marginLeft: theme.spacing(-1),
    paddingRight: theme.spacing(1),
  },
  chartTooltip: {
    padding: 0,
    width: '220px',
  },
  tooltipText: {
    fontSize: '10px',
  },
  chartTooltipList: {
    paddingLeft: theme.spacing(2),
  },
  tooltipListItem: {
    marginBottom: theme.spacing(1),
  },
  chartKeyList: {
    paddingLeft: 0,
  },
  chartKey: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  chartKeyDash: {
    width: 16,
    height: 7,
    marginRight: 12,
  },
  chartKeyLabel: {
    fontSize: '9px',
    margin: 0,
  },
  infoIcon: {
    color: grey[600],
    marginTop: theme.spacing(-0.6),
  },
  chartHeaderText: {
    fontSize: '10px',
    fontWeight: 'bold',
    color: grey[700],
  },
  chartHeaderContainer: {
    marginBottom: theme.spacing(-1),
  },
  questionContainer: {
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.75),
  },
  quotesHeader: {
    marginLeft: theme.spacing(1.5),
  },
  quotesTable: {
    marginLeft: theme.spacing(1.5),
  },
  expectationsGraph: {
    marginLeft: theme.spacing(1.5),
  },
  legend: {
    marginTop: theme.spacing(-1),
  },
  questionHeader: {
    marginBottom: theme.spacing(-1),
  },
}));

const Expectations = (props) => {
  const classes = useExpectationsStyles();
  const { wevo, page, rightDrawerOpen, setRightDrawerOpen, componentId, isPptComponent, width, height } =
    props;

  const [expectations, setExpectations] = useState([]);
  const [selectedExpectation, setSelectedExpectation] = useState(null);
  const [expectationHopeQuestion, setExpectationHopeQuestion] = useState('');
  const [expectationFearQuestion, setExpectationFearQuestion] = useState('');

  // todo: remove 'includeExpectations' after fully cutting over to includeQualitativeExpectations
  const includeExpectationsSectionFlag =
    typeof wevo?.details?.includeExpectations !== 'boolean' || wevo?.details?.includeExpectations; // legacy condition
  const includeQualitativeExpectationsFlag = wevo?.details?.includeQualitativeExpectations !== false;
  const includeQualitativeExpectations = includeExpectationsSectionFlag && includeQualitativeExpectationsFlag;

  const { track } = useAnalytics();

  useEffect(() => {
    axios({
      url: `/api/v2/wevos/${wevo.id}/pages/${page.id}/expectations`,
      method: 'GET',
    }).then((response) => {
      const expectations = _.get(response, 'data.expectations') || [];
      if (expectations.length) {
        setSelectedExpectation(expectations[0]);
        expectations.push({
          id: '00000',
          totalMentions: 0,
          isLast: true,
        });
        setExpectations(expectations);

        // The regexes below remove the parentheses part from the Expectation Hope and Fear questions
        const modifiedExpectationHopeQuestion = response?.data?.expectationHopeQuestion?.replace(
          / *\([^)]*\) */,
          ''
        );
        const modifiedExpectationFearQuestion = response?.data?.expectationFearQuestion?.replace(
          / *\([^)]*\) */,
          ''
        );

        setExpectationHopeQuestion(modifiedExpectationHopeQuestion || '');
        setExpectationFearQuestion(modifiedExpectationFearQuestion || '');
      }
    });
  }, [wevo, page]);

  useTrackPageLoad({
    name: TrackEvent.VIEWED_REPORT_JOURNEY_EXPECTATIONS,
    properties: { wevoId: wevo?.analyticsId, pageId: page?.id },
  });

  const handleQuoteClick = (event) => {
    const expectationId = _.get(event, 'target.value');
    if (expectationId) {
      selectExpectation(expectationId);
    }
  };

  const selectExpectation = (expectationId) => {
    if (expectationId !== '00000') {
      const expectation = expectations.find((expectation) => expectation.id === expectationId);
      if (expectation) {
        track(TrackEvent.FILTERED_QUOTES_BY_EXPECTATION, {
          wevoId: wevo?.analyticsId,
          pageId: page?.id,
          expectationId,
          testType: wevo?.type,
        });
        setSelectedExpectation(expectation);
      }
    }
  };

  const calculateExpectationsGraphHeight = () => {
    // ideally, give 60px of height per bar
    return Math.max(expectations.length * 61, 200);
  };

  const updateQuote = ({ id, updates }) => {
    for (let theme of expectations) {
      const quotes = theme?.quotes;
      if (quotes) {
        for (let quote of quotes) {
          if (quote?.id === id) {
            Object.assign(quote, updates);
            break;
          }
        }
      }
    }
  };

  // Wait until expectations are fetched.
  if (!expectations.length) {
    return (
      <Box p={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box mr={5}>
      <Typography variant="body1" gutterBottom>
        See how your journey addressed the visitors’ biggest hopes and concerns
      </Typography>
      <Paper elevation={4} className={classes.paper}>
        <Grid container spacing={4}>
          <Grid
            item
            container
            justifyContent="flex-start"
            spacing={2}
            className={classes.questionContainer}
            xs={12}>
            <Grid item xs={12} className={classes.questionHeader}>
              <Typography variant="body2" gutterBottom>
                <b>Expectation Question</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                {`${expectationHopeQuestion} ${expectationFearQuestion}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.expectationsGraph}
            xs={12}
            style={{ maxWidth: !isPptComponent && '100%', maxHeight: !isPptComponent && '100%' }}>
            <Grid item xs={12} lg={isPptComponent ? 12 : 10} className={classes.graphContainer}>
              <Box style={{ height: calculateExpectationsGraphHeight() }}>
                <ExpectationsGraph
                  expectations={expectations}
                  selectedExpectation={selectedExpectation}
                  onChange={selectExpectation}
                  isPptComponent={isPptComponent}
                  height={height}
                  width={width}
                  componentId={componentId}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={isPptComponent ? 12 : 2} className={classes.chartItemContainer}>
              <Grid container justifyContent="flex-start" className={classes.chartContainer}>
                <Grid item container justifyContent="space-between" className={classes.chartHeaderContainer}>
                  <Grid item xs={11}>
                    <Typography component="p" variant="body1" className={classes.chartHeaderText}>
                      How do I read this chart?
                    </Typography>
                  </Grid>
                  {!isPptComponent && (
                    <Grid item xs={1}>
                      <Tooltip
                        placement="bottom-end"
                        title={
                          <div className={classes.chartTooltip}>
                            <ul className={classes.chartTooltipList}>
                              <li className={classes.tooltipListItem}>
                                <Typography
                                  component="p"
                                  gutterBottom
                                  variant="body2"
                                  className={classes.tooltipText}>
                                  The length of each bar represents the number of responses listing that hope
                                  or concern as something they expected to be addressed by your page or
                                  experience
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  component="p"
                                  gutterBottom
                                  variant="body2"
                                  className={classes.tooltipText}>
                                  The segments of each bar represent the percentage of respondents who listed
                                  that expectation who felt that your experience/page fully met, somewhat, or
                                  did not meet their expectation (right to left)
                                </Typography>
                              </li>
                            </ul>
                          </div>
                        }>
                        <InfoIcon className={classes.infoIcon} />
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.legend}>
                  <ul className={classes.chartKeyList}>
                    {Object.values(ExpectationOutcome).map((outcome) => (
                      <li key={outcome} className={classes.chartKey}>
                        <div
                          className={classes.chartKeyDash}
                          style={{ backgroundColor: ExpectationOutcomeToColor[outcome] }}
                        />
                        <p className={classes.chartKeyLabel}>{ExpectationOutcomeToLabel[outcome]}</p>
                      </li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {includeQualitativeExpectations && (
            <Grid item lg={10} xs={12} className={classes.quotesHeader}>
              <Typography variant="h5" display="inline">
                {`Quotes `}
              </Typography>
              <Typography variant="body1" display="inline">
                {`about `}
              </Typography>
              <Select variant="standard" value={selectedExpectation.id} onChange={handleQuoteClick} autoWidth>
                {expectations.map((expectation) => (
                  <MenuItem key={expectation.id} value={expectation.id} className={classes.dropdownItem}>
                    {expectation.theme}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {includeQualitativeExpectations && (
            <Grid item lg={8} xs={12} className={classes.quotesTable}>
              <ExpectationQuotes
                wevo={wevo}
                page={page}
                expectation={selectedExpectation}
                onQuoteUpdate={updateQuote}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      <RightDrawerButton
        wevo={wevo}
        rightDrawerOpen={rightDrawerOpen}
        handleRightDrawerOpenClick={setRightDrawerOpen}
      />
    </Box>
  );
};

Expectations.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  rightDrawerOpen: PropTypes.bool.isRequired,
  setRightDrawerOpen: PropTypes.func.isRequired,
  isPptComponent: PropTypes.bool,
  componentId: PropTypes.string,
};

export default Expectations;
