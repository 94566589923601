import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ScoreIcon } from '../../../src/assets/Score.svg';
import { ReactComponent as PulseInfoWhiteIcon } from '../../assets/pulse-info-icon-white.svg';
import { ReactComponent as PulseInfoIcon } from '../../assets/pulse-info-icon.svg';
import { ReactComponent as WarningIcon } from '../../assets/pulse-warning-icon.svg';
import { customRenderers } from '../../helpers';
import {
  QuantDiagnosticColors,
  QueryParams,
  getQuantChartColor,
  getQuantScoreSubText,
} from '../../modules/automated-insights/constants';
import { getPercentileMessage } from '../../modules/automated-insights/helpers';
import { getUserCustomizations } from '../../modules/user/selectors';
import { snackbar } from '../../notifications';
import HalfDoughnutScoreGraph from '../reports/components/HalfDoughnutScoreGraph';
import AnnouncementPopup from './AnnoucementPopup';
import Benchmarks from './Benchmarks';
import { ExploreButton } from './ExploreButton';
import useGenerateBenchmarks from './hooks/useBenchmarks';

const DIAGNOSTIC_THEME_TOOLTIPS = {
  Intuitive:
    'Visitors’ ability to find all the information they are looking for and easily navigate and interact with the page',
  Trusted:
    'Level of trustworthiness, professionalism, and authenticity. Visitors’ confidence that the brand will meet their expectations',
  Valuable:
    'The efficacy in conveying the value of this product or service and compelling visitors to take the next step',
};

function getInitialTags(benchmarksData) {
  const initialTags = benchmarksData?.tags?.reduce((acc, tag) => {
    acc[tag.type] = tag.id;
    return acc;
  }, {});

  return initialTags;
}

const LinearScoreBar = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'barColor' && prop !== 'barBackgroundColor',
})(({ theme, barColor, barBackgroundColor }) => ({
  height: 16,
  width: '100%',
  borderRadius: '10px',
  backgroundColor: barBackgroundColor,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '20px',
    backgroundColor: barColor,
  },
}));

const WarningMessage = () => {
  return (
    <Box
      p={2}
      sx={{ display: 'flex', alignItems: 'center', gap: 1, borderRadius: '10px', backgroundColor: '#F8F8F8' }}>
      <WarningIcon style={{ width: '20px' }} />
      <Typography fontSize={14}>Due to limited content in this study, scores may not be accurate.</Typography>
    </Box>
  );
};

const QuantScore = ({ score = -1, benchmarkScore }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        paddingTop: { xs: 2 },
      }}>
      <Box>
        <Typography fontSize={16} fontWeight={700} textAlign="center">
          Experience Quality Score
        </Typography>
      </Box>
      <Box sx={{ width: 'inherit', marginTop: -4, paddingBottom: 3 }}>
        <HalfDoughnutScoreGraph
          score={score}
          benchmark={benchmarkScore}
          backgroundColor={getQuantChartColor(score)?.primary}
          secondaryBackgroundColor={getQuantChartColor(score)?.secondary}
          hoverBackgroundColor={getQuantChartColor(score)?.primary}
          textFont={'Montserrat'}
          textFontSize={1.8}
          textFontWeight={700}
          showBorders={true}
          showScale={false}
        />
        <Typography sx={{ marginTop: -4, fontSize: 12, textAlign: 'center' }}>
          {score >= 0 ? getQuantScoreSubText(score) : ''}
        </Typography>
      </Box>
    </Box>
  );
};

const DiagnosticScore = ({ name, score, scoreColor, scoreBarColor, scoreBarBgColor, benchmarkScore }) => {
  const roundedScore = useMemo(() => Math.round(score), [score]);

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1} sx={{ marginBottom: 1 }}>
        <Typography variant="h6" fontWeight={700}>
          {name}
        </Typography>
        <Tooltip title={DIAGNOSTIC_THEME_TOOLTIPS[name]}>
          <PulseInfoIcon width="15px" />
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Box
          style={{
            position: 'relative',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
          <LinearScoreBar
            variant="determinate"
            barColor={scoreBarColor}
            barBackgroundColor={scoreBarBgColor}
            value={roundedScore}
          />
          {benchmarkScore && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: `${benchmarkScore}%`,
              }}>
              <Tooltip title="Benchmark" sx={{ fontWeight: 700 }}>
                <div>
                  <Box
                    sx={{
                      height: '21px',
                      width: '3px',
                      borderRadius: '10px',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'rgba(117, 116, 116, 0.53)',
                    }}
                  />
                  <Typography
                    sx={{ fontSize: 10, fontWeight: 700, color: '#757474', transform: 'translateX(-50%)' }}>
                    {benchmarkScore}
                  </Typography>
                </div>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Typography component="span" fontSize={14} fontWeight={700} sx={{ paddingLeft: 2, color: scoreColor }}>
          {roundedScore}
        </Typography>
      </Box>
    </Box>
  );
};

const DiagnosticDetails = ({
  children,
  header,
  name,
  setSelectedQuantTheme,
  setSelectedTab,
  onQuestionsClick,
  initialExpandDetails = false,
}) => {
  const [expandDetails, setExpandDetails] = useState(initialExpandDetails);

  if (!expandDetails) {
    return (
      <Grid container justifyContent="space-between" alignItems="center" sx={{ minHeight: 30 }}>
        <Grid item>
          <Typography
            fontSize={10}
            fontWeight={700}
            sx={{ textTransform: 'capitalize', color: QuantDiagnosticColors[name]?.text }}>
            {header}
          </Typography>
        </Grid>
        <Grid item>
          <ExploreButton
            quantCategory={name}
            setSelectedQuantTheme={setSelectedQuantTheme}
            setSelectedTab={setSelectedTab}
            onQuestionsClick={onQuestionsClick}
            expandDetails={expandDetails}
            setExpandDetails={setExpandDetails}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container flexDirection="column" sx={{ height: '100%' }}>
      <Grid item sx={{ flexGrow: 1 }}>
        <Box>
          <Box sx={{ display: 'flex', minHeight: 30, alignItems: 'center' }}>
            <Typography
              fontSize={10}
              fontWeight={700}
              sx={{ textTransform: 'capitalize', color: QuantDiagnosticColors[name]?.text }}>
              {header}
            </Typography>
          </Box>
          {children}
        </Box>
      </Grid>
      <Grid item sx={{ marginTop: 2, alignSelf: 'flex-end' }}>
        <ExploreButton
          quantCategory={name}
          setSelectedQuantTheme={setSelectedQuantTheme}
          setSelectedTab={setSelectedTab}
          onQuestionsClick={onQuestionsClick}
          expandDetails={expandDetails}
          setExpandDetails={setExpandDetails}
        />
      </Grid>
    </Grid>
  );
};

const QuantAnnouncementBanner = ({ sessionId }) => {
  const title = (
    <Box>
      <Typography>
        <Typography fontWeight={600} component="span" pr={1}>
          The Experience Quality Score is Currently in
        </Typography>
        <Box
          sx={{
            display: 'inline-block',
            paddingX: '4px',
            borderRadius: '4px',
            border: '1px solid rgba(161, 161, 161, 0.23)',
            backgroundColor: 'rgba(224, 230, 234, 0.08)',
          }}>
          <Typography sx={{ fontSize: '13px', textTransform: 'uppercase' }}>beta</Typography>
        </Box>
      </Typography>
    </Box>
  );

  const message = (
    <>
      While we're actively refining this Score, please note it may not yet fully reflect the final accuracy
      we're aiming for. Thank you for your patience as we work to make it even more accurate. Good news - Score
      enhancements are coming soon!
    </>
  );

  return (
    <AnnouncementPopup
      localStorageKey={'pulse_announcement'}
      bannerId={'pulse_quant_announcement_nov_2024'}
      keyPath={[sessionId]}
      title={title}
      message={message}
      confirmText="OK"
    />
  );
};

const Quant = ({
  sessionId,
  quantData,
  benchmarksData,
  allTags,
  onQuestionsClick,
  setSelectedTab,
  setSelectedQuantTheme,
  hideReportContent,
  isSessionOwner,
}) => {
  const [benchmarks, setBenchmarks] = useState(benchmarksData?.benchmarks);
  const [benchmarkTags, setBenchmarkTags] = useState(allTags);
  const [percentileType, setPercentileType] = useState(benchmarksData?.percentileType);
  const [selectedTags, setSelectedTags] = useState(getInitialTags(benchmarksData));

  const userCustomizations = useSelector(getUserCustomizations);
  const showBenchmarks =
    userCustomizations?.automatedInsights?.benchmarks !== false && !_.isEmpty(benchmarksData?.benchmarks);

  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const querySegmentId = useMemo(() => queryParams.get(QueryParams.SegmentId) || null, [queryParams]);

  const { mutate: generateBenchmarks } = useGenerateBenchmarks();

  const handleTagSelection = useCallback(
    ({ tagType, tagId }) => {
      const currentTags = { ...selectedTags };
      const newTags = { ...selectedTags, [tagType]: tagId };
      setSelectedTags(newTags);

      const tagIds = Object.values(newTags).filter((tag) => Boolean(tag));

      generateBenchmarks(
        {
          sessionId,
          segmentId: querySegmentId,
          tagIds: tagIds,
        },
        {
          onSuccess: (data) => {
            setBenchmarks(data?.benchmarks?.benchmarks);
            setBenchmarkTags(data?.tags || allTags);
            setPercentileType(data?.benchmarks?.percentileType);
          },
          onError: (err) => {
            setSelectedTags(currentTags);
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error calculating benchmarks');
          },
        }
      );
    },
    [allTags, selectedTags, sessionId, querySegmentId, generateBenchmarks]
  );

  const showLoadingIndicatorForShareLink = !isSessionOwner && hideReportContent;

  if (showLoadingIndicatorForShareLink) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <Box
      className="lightContainer"
      sx={{ height: '100%', paddingBottom: 6, overflow: hideReportContent ? 'hidden' : 'auto' }}>
      <QuantAnnouncementBanner sessionId={sessionId} />
      <Box alignItems={'center'} sx={{ marginLeft: 2.3, marginBottom: 1.5 }}>
        <Grid container spacing={1}>
          <Grid item flexWrap={'nowrap'} mt={0.25}>
            <ScoreIcon fontSize="small" height={'22px'} />
          </Grid>
          <Grid item mt={0.5} ml={0.25}>
            <Typography fontSize={13} fontStyle="italic">
              Experience Quality Score - Find out how user-friendly, valuable, and trustworthy your experience
              is.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {quantData?.isFlagged && (
        <Box px={2} pb={1}>
          <WarningMessage />
        </Box>
      )}
      {quantData?.isFlagged && (
        <Box px={2} pb={1}>
          <WarningMessage />
        </Box>
      )}
      <Box paddingX={2} paddingBottom={2}>
        <Grid
          container
          columnGap={2}
          sx={{
            position: 'relative',
            flexDirection: { xs: 'column', lg: 'row' },
            alignItems: { xs: 'center', lg: 'initial' },
            paddingY: 2,
            paddingX: { md: 2 },
            borderRadius: '12px',
            backgroundColor: (theme) => theme.palette.background.default,
            color: (theme) => theme.palette.text.secondary,
          }}>
          <Grid
            item
            sx={{
              width: { xs: '90%', lg: '30%' },
              minWidth: { lg: 254 },
              maxWidth: { xs: 270, lg: 300 },
            }}>
            <QuantScore
              score={quantData?.average}
              benchmarkScore={showBenchmarks && benchmarks?.topLevel?.score}
            />
          </Grid>
          <Grid item xs>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingX: { xs: 2, md: 0 },
                paddingTop: 2,
                fontSize: '14px',
                lineHeight: 'normal',
              }}>
              {showBenchmarks && (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
                  <Typography variant="h6" fontWeight={700}>
                    You're in the {getPercentileMessage(percentileType, benchmarks?.topLevel?.percentile)} of
                    similar experiences
                  </Typography>
                  <Tooltip title="The criteria used to compare you to other similar experiences can be modified using the filters in the Benchmarks section below.">
                    <PulseInfoWhiteIcon width="15px" height="15px" />
                  </Tooltip>
                </Box>
              )}
              <ReactMarkdown components={customRenderers}>{quantData?.summary}</ReactMarkdown>
            </Box>
          </Grid>
          <Box
            sx={{
              position: 'absolute',
              top: 10,
              right: 14,
              paddingX: '4px',
              borderRadius: '4px',
              border: '1px solid rgba(161, 161, 161, 0.23)',
              backgroundColor: 'rgba(224, 230, 234, 0.08)',
            }}>
            <Typography sx={{ fontSize: '11px', textTransform: 'uppercase' }}>beta</Typography>
          </Box>
        </Grid>
      </Box>
      <Grid container>
        {quantData?.scores?.map((item, index) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            key={item?.name}
            sx={{
              borderTop: '1px solid #ECECEC',
              borderBottom: '1px solid #ECECEC',
              borderRight: {
                xs: 'none',
                md: '1px solid #ECECEC',
                lg: index < quantData?.scores?.length - 1 ? '1px solid #ECECEC' : 'none',
              },
            }}>
            <Grid container flexDirection="column" sx={{ height: '100%', paddingTop: 3.5 }}>
              <Grid
                item
                sx={{ width: '100%', maxWidth: { sm: 390, md: 'none' }, paddingX: 2, paddingBottom: 3 }}>
                <DiagnosticScore
                  name={item?.name}
                  score={item?.score}
                  scoreColor={QuantDiagnosticColors[item?.name]?.text}
                  scoreBarColor={QuantDiagnosticColors[item?.name]?.primary}
                  scoreBarBgColor={QuantDiagnosticColors[item?.name]?.secondary}
                  benchmarkScore={showBenchmarks && benchmarks?.[item?.name]?.score}
                />
              </Grid>
              <Grid
                item
                sx={{
                  flexGrow: 1,
                  paddingX: 2,
                  paddingY: 1.5,
                  backgroundColor: QuantDiagnosticColors[item?.name]?.background,
                }}>
                <DiagnosticDetails
                  header={
                    showBenchmarks
                      ? getPercentileMessage(percentileType, benchmarks?.[item?.name]?.percentile)
                      : ''
                  }
                  name={item?.name}
                  setSelectedQuantTheme={setSelectedQuantTheme}
                  setSelectedTab={setSelectedTab}
                  onQuestionsClick={onQuestionsClick}
                  initialExpandDetails={!showBenchmarks}>
                  {item?.positiveReason && item?.negativeReason ? (
                    <Box>
                      <Typography fontSize={12} fontWeight={600}>
                        Positive
                      </Typography>
                      <Typography fontSize={12}>{item?.positiveReason}</Typography>
                      <Typography fontSize={12} fontWeight={600} pt={1}>
                        Negative
                      </Typography>
                      <Typography fontSize={12}>{item?.negativeReason}</Typography>
                    </Box>
                  ) : (
                    <Typography fontSize={12}>{item?.reason}</Typography>
                  )}
                </DiagnosticDetails>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {showBenchmarks && (
        <Grid container flexDirection="column" sx={{ padding: 2 }}>
          <Grid item>
            <Benchmarks
              scores={quantData?.scores}
              benchmarks={benchmarks}
              percentileType={percentileType}
              allTags={benchmarkTags}
              selectedTags={selectedTags}
              onTagSelection={handleTagSelection}
              isSessionOwner={isSessionOwner}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Quant;
