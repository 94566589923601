import { Box, Typography } from '@mui/material';
import { CustomSelect } from '../../../components';
import { CustomQuestionTypeOptions } from '../constants';

function CustomQuestionTypeSelector({ value, onChange }) {
  const handleQuestionTypeChanged = (ev) => {
    const newValue = ev.target.value;

    if (value === newValue) {
      return;
    }

    onChange && onChange(newValue);
  };

  return (
    <Box>
      <Typography mb={1}>Select question type*</Typography>
      <CustomSelect
        size={'medium'}
        value={value}
        items={CustomQuestionTypeOptions}
        onChange={handleQuestionTypeChanged}
      />
    </Box>
  );
}

export default CustomQuestionTypeSelector;
