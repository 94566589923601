import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { cloneDeep, isNil } from 'lodash';
import { useContext } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { snackbar } from '../../../../notifications';
import { Paths } from '../../../../routes';
import { CustomTextField, Header } from '../../components';
import { ValidationErrorNotice } from '../../components/Notice';
import { ReorderableList } from '../../components/ReorderableList';
import { AddListItemButton } from '../../components/SelectableTile';
import DebouncedInput from '../../edit/DebouncedInput';
import useAddCustomQuestion from '../../hooks/useAddCustomQuestion';
import useDeleteQuestions from '../../hooks/useDeleteQuestions';
import useUpdateCustomQuestion from '../../hooks/useUpdateCustomQuestion';
import useUpdateSortOrder from '../../hooks/useUpdateSortOrder';
import { IntakeWevoContext } from '../context/IntakeWevoContext';
import IntakeCustomQuestion from './components/IntakeCustomQuestion';
import { ValidationKeys } from './constants';
import { mergeUpdates, serializeErrors } from './helpers';

export function CustomQuestionsIntakeSection() {
  const {
    wevo,
    isSyncingWevo,
    customQuestions,
    setCustomQuestions,
    setIsWevoSyncing,
    reloadCustomQuestionsFromRemote,
    customQuestionErrors,
  } = useContext(IntakeWevoContext);
  // Custom Questions
  const { mutateAsync: addCustomQuestionAsync, isLoading: isAddingCustomQuestion } = useAddCustomQuestion();
  const { mutateAsync: updateCustomQuestionAsync, isLoading: isUpdatingCustomQuestions } =
    useUpdateCustomQuestion();
  const { mutateAsync: updateCustomQuestionSortOrderAsync, isLoading: isUpdatingCustomQuestionSortOrder } =
    useUpdateSortOrder();
  const { mutateAsync: deleteQuestionsAsync, isLoading: isDeletingCustomQuestion } = useDeleteQuestions();

  const handleCustomQuestionCreated = async ({ wevo }) => {
    setIsWevoSyncing(true);

    const prevQuestions = cloneDeep(customQuestions);
    const pagesToAdd = wevo?.pages ?? [];
    const scopesToAdd = pagesToAdd.map((page) => ({ wevoPageId: page.id.toString(), stepId: null }));

    try {
      await addCustomQuestionAsync({ id: wevo.id, scopesToAdd });
      // there's some weirdness with creating groups and initial group number,
      // so we hard refresh after creating a new custom question
      await reloadCustomQuestionsFromRemote();
      snackbar.success('Custom question successfully created!');
    } catch (err) {
      snackbar.error('Failed to add custom question.');
      setCustomQuestions(prevQuestions);
    }
  };

  const handleCustomQuestionDestroyed = async ({ customQuestion }) => {
    const groupIdToDelete = customQuestion.groupId;

    const originalArray = Array.from(customQuestions);
    const index = originalArray.findIndex((question) => question.groupId === groupIdToDelete);
    if (index < 0) {
      return;
    }

    const items = Array.from(customQuestions);
    items.splice(index, 1); // removes 1 element at index
    setCustomQuestions(items); //update parent's array

    try {
      setIsWevoSyncing(true);
      await deleteQuestionsAsync({ id: wevo.id, groupId: groupIdToDelete });
      snackbar.success('Custom question successfully deleted!');
    } catch (err) {
      setCustomQuestions(originalArray);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting custom question');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleCustomQuestionChanged = async ({ customQuestion, updateFields }) => {
    setIsWevoSyncing(true);

    const groupId = customQuestion.groupId;
    const previousState = cloneDeep(customQuestion);

    // optimistic update custom question fields
    const newState = mergeUpdates({ customQuestion, updateFields });

    setCustomQuestions(
      customQuestions.map((customQuestion) => {
        if (customQuestion.groupId === groupId) {
          return newState;
        }
        return customQuestion;
      })
    );

    try {
      await updateCustomQuestionAsync({ id: wevo.id, groupId, ...updateFields });
    } catch (err) {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating custom question');

      // rollback client state because of failed update
      setCustomQuestions(
        customQuestions.map((customQuestion) => {
          if (customQuestion.groupId === previousState.groupId) {
            return previousState;
          }
          return customQuestion;
        })
      );
    } finally {
      setIsWevoSyncing(false);
    }
  };

  const handleCustomQuestionUpdateSortOrder = async ({ customQuestion, sourceIndex, destinationIndex }) => {
    const groupId = customQuestion.groupId;
    const originalQuestions = Array.from(customQuestions);

    // Reorder custom questions
    let items = Array.from(customQuestions);
    const [itemToReorder] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, itemToReorder);

    let groupSortOrder = 0;
    items = items.map((item) => {
      return {
        ...item,
        groupSortOrder: groupSortOrder++,
      };
    });

    setCustomQuestions(items);

    try {
      setIsWevoSyncing(true);
      await updateCustomQuestionSortOrderAsync({
        id: wevo.id,
        groupId: groupId,
        groupSortOrder: destinationIndex,
      });
      snackbar.success('Custom questions succesffully reordered!');
    } catch (err) {
      setCustomQuestions(originalQuestions);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating custom question sor order');
    } finally {
      setIsWevoSyncing(false);
    }
  };

  if (!wevo || isNil(customQuestions)) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: 'calc(100vh - 64px)', width: '100%', overflowY: 'auto' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!wevo?.pages || wevo?.pages?.length < 1) {
    // in theory a user should never see this in the new intake form, but just in case
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography>
          Please upload an <Link to={generatePath(Paths.intake.intakeAsset, { wevoId: wevo.id })}>asset</Link>{' '}
          in order to set up custom questions.
        </Typography>
      </Box>
    );
  }

  return (
    <CustomQuestionsList
      wevo={wevo}
      customQuestions={customQuestions}
      onCustomQuestionAdded={handleCustomQuestionCreated}
      onCustomQuestionRemoved={handleCustomQuestionDestroyed}
      onCustomQuestionSortChanged={handleCustomQuestionUpdateSortOrder}
      onCustomQuestionChanged={handleCustomQuestionChanged}
      isAdding={isAddingCustomQuestion}
      isDeleting={isDeletingCustomQuestion}
      isLoading={isSyncingWevo || isUpdatingCustomQuestions || isUpdatingCustomQuestionSortOrder}
      errors={customQuestionErrors}
    />
  );
}

function CustomQuestionsList({
  wevo,
  customQuestions,
  onCustomQuestionAdded,
  onCustomQuestionRemoved,
  onCustomQuestionSortChanged,
  onCustomQuestionChanged,
  isAdding,
  isDeleting,
  errors,
}) {
  const limitExceededErrors = errors?.globalErrors?.[ValidationKeys.LimitExceeded];
  const limitExceeded = limitExceededErrors?.length > 0;
  const customQuestionLimit = wevo?.details?.customQuestionLimit || 5;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Header
          name={'Custom Questions'}
          description={`You may add up to ${customQuestionLimit} custom questions with (if applicable) one additional follow-up question each.
          If your test requires more than ${customQuestionLimit} custom questions, please reach
          out to your customer success manager by emailing customersupport@wevo.ai.`}
          tooltipProps={{
            title: `What, if any, custom question would you like to include? A WEVO account
            manager may contact you to review the feasibility of your desired custom question.`,
            arrow: true,
            placement: 'right',
          }}
          hasPreview={true}
          isRequired={false}
        />
        {limitExceeded && (
          <Box my={1}>
            <ValidationErrorNotice message={serializeErrors(limitExceededErrors)} />
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <ReorderableList
          onDragEnd={(props) => {
            const source = props.source;
            const destination = props.destination;

            if (source.index === destination.index) {
              return;
            }

            const customQuestion = customQuestions[source.index];

            onCustomQuestionSortChanged({
              customQuestion,
              sourceIndex: source.index,
              destinationIndex: destination.index,
            });
          }}
          items={(customQuestions ?? []).map((customQuestion) => {
            return {
              label: customQuestion?.name || `Custom Question #${customQuestion.groupNumber}`,
              id: customQuestion.groupId,
              customQuestion,
            };
          })}
          renderTitle={(item) => (
            <DebouncedInput
              value={item.label}
              onChange={(value) => {
                onCustomQuestionChanged({
                  customQuestion: item.customQuestion,
                  updateFields: { name: value },
                });
              }}
              debounceMs={500}
              renderInput={({ value, onChange }) => (
                <Box width="100%">
                  <CustomTextField
                    value={value}
                    sx={{
                      width: '95%',
                      background: 'white',
                      borderRadius: 4,
                      '& .MuiInputBase-root': {
                        fontWeight: 600,
                        fontSize: 14,
                      },
                    }}
                    onChange={onChange}
                  />
                </Box>
              )}
            />
          )}
          renderItem={(item) => {
            const questionErrors = errors?.questionErrors?.[item.customQuestion.groupId] ?? {};
            return (
              <>
                {questionErrors?.name?.length > 0 && (
                  <Box mb={2}>
                    <ValidationErrorNotice message={serializeErrors(questionErrors.name)} />
                  </Box>
                )}
                <IntakeCustomQuestion
                  wevo={wevo}
                  customQuestion={item.customQuestion}
                  onDelete={onCustomQuestionRemoved}
                  isDeleting={isDeleting}
                  onChange={onCustomQuestionChanged}
                  errors={questionErrors}
                />
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column">
          <AddListItemButton isLoading={isAdding} onClick={() => onCustomQuestionAdded({ wevo })} />
        </Box>
      </Grid>
    </Grid>
  );
}
