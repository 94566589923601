import {
  CustomQuestionTypes,
  MAX_NUM_HEATMAP_QUESTIONS,
  MultiSelectOptions,
  Scales,
  SelectOptions,
} from '../../../../modules/intake/constants';

export const CustomQuestionScopeType = {
  Page: 'page', // question applies to all steps in a page / journey as a whole
  Step: 'step', // question applies to each of the selected steps in a page / journey individually
};

export const QuestionType = {
  [CustomQuestionTypes.CustomQualitative]: 'Text',
  [CustomQuestionTypes.YesNoTrueFalse]: 'Yes/No',
  [CustomQuestionTypes.MultipleChoice]: 'Multiple Choice',
  [CustomQuestionTypes.FiveLikertScale]: 'Likert 5',
  [CustomQuestionTypes.SevenLikertScale]: 'Likert 7',
  [CustomQuestionTypes.MultiSelect]: 'Multiple Selection',
  [CustomQuestionTypes.Heatmap]: 'Click Map',
  [CustomQuestionTypes.RankOrder]: 'Rank Order',
};

export const LabelsType = {
  [SelectOptions.AgreeDisagree]: 'Agree Disagree',
  [SelectOptions.EasyDifficult]: 'Easy Difficult',
  [SelectOptions.LikeDislike]: 'Like Dislike',
  [SelectOptions.LikelyUnlikely]: 'Likely Unlikely',
  [SelectOptions.SatisfiedDissatisfied]: 'Satisfied Dissatisfied',
  [SelectOptions.TrueFalse]: 'True/False',
  [SelectOptions.YesNo]: 'Yes/No',
  [SelectOptions.Custom]: 'Custom',
};

export const CustomQuestionTypeOptions = [
  {
    label: 'Custom qualitative',
    description: 'Open-ended questions',
    value: CustomQuestionTypes.CustomQualitative,
  },
  {
    label: 'Five likert scale',
    description: 'Five-point scale measuring agreement or satisfaction',
    value: CustomQuestionTypes.FiveLikertScale,
    choiceLimits: {
      default: 5,
      min: 5,
      max: 5,
    },
  },
  {
    label: 'Seven likert scale',
    description: 'Seven-point scale for more nuanced responses',
    value: CustomQuestionTypes.SevenLikertScale,
    choiceLimits: {
      default: 7,
      min: 7,
      max: 7,
    },
  },
  {
    label: 'Multiple choice',
    description: 'Single selection from predefined options',
    value: CustomQuestionTypes.MultipleChoice,
    choiceLimits: {
      default: 2,
      min: 2,
      max: 10,
    },
  },
  {
    label: 'Multiple selection',
    description: 'Multiple options from a list',
    value: CustomQuestionTypes.MultiSelect,
    // note: multiselect limits are dynamic based on range types, so this is more like the "default" choice limits
    // the exact values are based on user-defined input and range type, so they can't be hardcoded unlike the others
    choiceLimits: {
      default: 2,
      min: 2,
      max: 15,
    },
  },
  {
    label: 'Yes/no or true/false',
    description: 'Binary choice questions',
    value: CustomQuestionTypes.YesNoTrueFalse,
    choiceLimits: {
      default: 2,
      min: 2,
      max: 2,
    },
  },
  {
    label: 'Custom click map',
    description: 'Visual questions where respondents click on specific areas',
    value: CustomQuestionTypes.Heatmap,
  },
  {
    label: 'Rank order',
    description: 'Ordering items based on preference or importance',
    value: CustomQuestionTypes.RankOrder,
    choiceLimits: {
      default: 2,
      min: 2,
      max: 10,
    },
  },
];

export const CustomQuestionTypeOptionsByType = CustomQuestionTypeOptions.reduce((acc, cur) => {
  acc[cur.value] = cur;
  return acc;
}, {});

export const LikertScaleOptions = [
  {
    label: 'Agree - Disagree',
    value: SelectOptions.AgreeDisagree,
  },
  {
    label: 'Like - Dislike',
    value: SelectOptions.LikeDislike,
  },
  {
    label: 'Satisfied - Dissatisfied',
    value: SelectOptions.SatisfiedDissatisfied,
  },
  {
    label: 'Likely - Unlikely',
    value: SelectOptions.LikelyUnlikely,
  },
  {
    label: 'Easy - Difficult',
    value: SelectOptions.EasyDifficult,
  },
  {
    label: 'Custom',
    value: SelectOptions.Custom,
  },
];

export const PresetLikertScales = {
  [SelectOptions.AgreeDisagree]: {
    likert5: {
      values: ['Strongly Disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly Agree'],
    },
    likert7: {
      values: [
        'Strongly Disagree',
        'Disagree',
        'Somewhat Disagree',
        'Neutral',
        'Somewhat Agree',
        'Agree',
        'Strongly Agree',
      ],
    },
  },
  [SelectOptions.LikeDislike]: {
    likert5: { values: ['Strongly Dislike', 'Dislike', 'Neutral', 'Like', 'Strongly Like'] },
    likert7: {
      values: [
        'Strongly Dislike',
        'Dislike',
        'Somewhat Dislike',
        'Neutral',
        'Somewhat Like',
        'Like',
        'Strongly Like',
      ],
    },
  },
  [SelectOptions.SatisfiedDissatisfied]: {
    likert5: {
      values: ['Strongly Dissatisfied', 'Dissatisfied', 'Neutral', 'Satisfied', 'Strongly Satisfied'],
    },
    likert7: {
      values: [
        'Strongly Dissatisfied',
        'Dissatisfied',
        'Somewhat Dissatisfied',
        'Neutral',
        'Somewhat Satisfied',
        'Satisfied',
        'Strongly Satisfied',
      ],
    },
  },
  [SelectOptions.LikelyUnlikely]: {
    likert5: { values: ['Very Unlikely', 'Unlikely', 'Neutral', 'Likely', 'Very Likely'] },
    likert7: {
      values: [
        'Very Unlikely',
        'Unlikely',
        'Somewhat Unlikely',
        'Neutral',
        'Somewhat Likely',
        'Likely',
        'Very Likely',
      ],
    },
  },
  [SelectOptions.EasyDifficult]: {
    likert5: { values: ['Very Difficult', 'Difficult', 'Neutral', 'Easy', 'Very Easy'] },
    likert7: {
      values: [
        'Very Difficult',
        'Difficult',
        'Somewhat Difficult',
        'Neutral',
        'Somewhat Easy',
        'Easy',
        'Very Easy',
      ],
    },
  },
};

export const BinaryScaleOptions = [
  {
    label: 'Yes / No',
    value: SelectOptions.YesNo,
  },
  {
    label: 'True / False',
    value: SelectOptions.TrueFalse,
  },
  {
    label: 'Custom',
    value: SelectOptions.Custom,
  },
];

export const PresetBinaryScales = {
  [SelectOptions.YesNo]: { values: ['Yes', 'No'] },
  [SelectOptions.TrueFalse]: { values: ['True', 'False'] },
};

export const MULTISELECT_MIN_REQUIRED = 1;

export const MultipleSelectionRequirementsOptions = [
  { label: 'All that apply', value: MultiSelectOptions.AllThatApply },
  { label: 'At least', value: MultiSelectOptions.AtLeast },
  { label: 'Up to', value: MultiSelectOptions.UpTo },
  { label: 'Exactly', value: MultiSelectOptions.Exactly },
];

export const ValidationKeys = {
  Global: 'global',
  LimitExceeded: 'limitExceeded',
  Scopes: 'scopes',
  QuestionText: 'questionText',
  FollowUpQuestionText: 'followUpQuestionText',
  Labels: 'labels',
  LabelsType: 'labelsType',
  ChoiceRequirements: 'choiceRequirements', // Multiple Selection only
  QuestionName: 'name',
  ConfigurationError: 'configurationError',
  UnsupportedQuestionType: 'unsupportedQuestionType',
};

// N.b. This will be dynamic in the future! This is just a "placeholder" for now
export const JourneyQuestionLimit = 5;

export const AnchorLabels = {
  Other: 'Other',
  NoneOfTheAbove: 'None of the above',
};

export const RandomizationTypes = {
  All: 'all',
  None: 'none',
};
// pass throughs for consistency, but also so that we can eventually retire the module level constants
export { CustomQuestionTypes, MAX_NUM_HEATMAP_QUESTIONS, MultiSelectOptions, Scales, SelectOptions };
