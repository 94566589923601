import { Typography } from '@mui/material';
import SurveySectionPlaceholder from './SurveySectionPlaceholder';

function FraudPreventionBlock() {
  return (
    <SurveySectionPlaceholder title="Fraud Prevention Block">
      <Typography>
        Your survey starts with a series of checks designed to detect fraud. Respondents who fail these checks
        will be <b>Screened Out</b>.
      </Typography>
    </SurveySectionPlaceholder>
  );
}

export default FraudPreventionBlock;
