import sumBy from 'lodash/sumBy';
import { CustomizationOptions, MultipleSelectionRequirementsOptions } from './constants';

export const isCustomizedAudience = (attributes) => {
  return attributes?.some((attribute) => attribute?.isSelected);
};

export const getCustomizationOption = (attribute) => {
  const hasCustomizedQuotas = attribute?.isCustomizedQuota;
  const hasCustomSegments = attribute?.isSelected;
  if (hasCustomizedQuotas) {
    return CustomizationOptions.Quota;
  } else if (hasCustomSegments) {
    return CustomizationOptions.Custom;
  } else {
    return CustomizationOptions.None;
  }
};

export const getAttributeById = (attributes, attributeId) => {
  return Object.values(attributes).find((attribute) => attribute.id === attributeId);
};

export const getNumberOfSegmentsSelected = (segments) => {
  return segments.filter((segment) => segment?.isSelected)?.length;
};

export const getQuotasTotal = (segments) => {
  return sumBy(segments, (segment) => segment.percentage);
};

export const mergeAttributeChanges = ({ audience, attributeId, changes }) => {
  const updatedAudience = { ...audience };

  if (attributeId) {
    // Update segments for a specific attribute
    updatedAudience.attributes = audience.attributes.map((attr) => {
      if (attr.id === attributeId) {
        return {
          ...attr,
          segments: attr.segments.map((segment) => {
            const change = changes?.segments.find((update) => update.id === segment.id);
            return change ? { ...segment, ...change } : segment;
          }),
        };
      }
      return attr;
    });
  } else {
    // Update attributes
    updatedAudience.attributes = audience.attributes.map((attribute) => {
      const change = changes?.attributes.find((updatedAttribute) => updatedAttribute.id === attribute.id);
      return change ? change : attribute;
    });
  }

  return updatedAudience;
};

export const updateSegmentQuotas = (segments) => {
  // Calculate the base percentage and remainder
  const numSelectedSegments = segments.filter((segment) => segment.isSelected).length;
  const basePercentage = Math.floor(100 / numSelectedSegments);
  let remainder = 100 - basePercentage * numSelectedSegments;

  // Update each segment with the calculated percentage
  return segments.map((segment) => {
    if (!segment.isSelected) {
      return { ...segment, percentage: 0 }; // Unselected segments get 0%
    } else {
      // Distribute the remainder
      if (remainder > 0) {
        remainder -= 1;
        return { ...segment, percentage: basePercentage + 1 };
      }
      return { ...segment, percentage: basePercentage };
    }
  });
};

export const getCurrentDateTimeFormatted = () => {
  const now = new Date();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11, so add 1
  const date = String(now.getDate()).padStart(2, '0'); // pad single digits with leading zero
  const year = now.getFullYear();

  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${month}.${date}.${year} ${hours}:${minutes}:${seconds}`;
};

export const getFormattedSelectionRequirements = (rangeType, number = '') => {
  if (!rangeType) {
    return '';
  }

  const rangeTypeDisplayName = MultipleSelectionRequirementsOptions?.find(
    (option) => option.value === rangeType
  )?.label;

  return `${rangeTypeDisplayName} ${number}`;
};
