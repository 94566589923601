import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { ReactComponent as IntakeCloseIcon } from '../../../assets/intake-close-icon.svg';
import { CancelButton, ConfirmButton } from './Buttons';

function IntakeModal({
  title,
  content,
  open,
  onClose,
  onCancel,
  cancelText = 'Cancel',
  onConfirm,
  confirmText = 'Confirm',
}) {
  const shouldShowDialogActions = onCancel || onConfirm;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 2,
          position: 'relative',
        },
      }}>
      <IconButton sx={{ position: 'absolute', right: 15, top: 10 }} onClick={onClose}>
        <IntakeCloseIcon />
      </IconButton>
      {title && (
        <DialogTitle sx={{ fontSize: 16, fontWeight: 700, my: 1, color: '#212A37' }}>{title}</DialogTitle>
      )}
      {content && <DialogContent sx={{ color: '#212A37' }}>{content}</DialogContent>}
      {shouldShowDialogActions && (
        <DialogActions sx={{ m: 1 }}>
          {onCancel && <CancelButton label={cancelText} onClick={onCancel} />}
          {onConfirm && <ConfirmButton label={confirmText} onClick={onConfirm} />}
        </DialogActions>
      )}
    </Dialog>
  );
}

export default IntakeModal;
