import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { AudienceCategory, audienceIdToName } from '../../../../../../modules/wevos/constants';
import { CustomFormControlRadio, Header } from '../../../../components';
import { DeleteButton } from '../../../../components/Buttons';
import AttributeTable from './AttributeTable';
import CustomScreeners from '../custom-screeners';

const AudienceAccordion = ({ audienceGroup, isSelected, onSelect, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);

  const audienceCategoryId = Number(audienceGroup?.['audience_categories']?.[0]?.audienceCategoryId);

  return (
    <Accordion
      square={true} // this is needed in order to change the border radius
      expanded={isOpen}
      onChange={() => setIsOpen(!isOpen)}
      sx={{
        backgroundColor: isSelected ? '#F3F8FB' : '#FFFFFF',
        border: isSelected ? '1.5px solid #3B6CAB' : '1px solid #C7D6DF',
        borderRadius: '10px',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#F3F8FB',
        },
        '&:focus-visible': {
          backgroundColor: '#F3F8FB',
          borderRadius: '10px',
        },
        '&.Mui-expanded': {
          '&:hover': {
            backgroundColor: isSelected ? '#F3F8FB' : '#FFFFFF',
          },
        },
      }}>
      <AccordionSummary
        expandIcon={<ExpandMoreRoundedIcon />}
        sx={{
          '&.Mui-focusVisible': {
            backgroundColor: '#F3F8FB',
            borderRadius: '10px',
          },
        }}>
        <CustomFormControlRadio
          value={audienceGroup?.id}
          label={audienceGroup?.name}
          labelStyles={{ fontWeight: isSelected ? 500 : 400 }}
          checked={isSelected}
          onClick={(ev) => {
            ev.stopPropagation(); // to stop toggling expansion of accordion when clicking radio button
            onSelect({ audienceCategoryId, groupId: ev.target.value });
          }}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container sx={{ columnGap: 5 }}>
          <Grid item>
            <Typography fontSize={11} fontWeight={700}>
              Audience
            </Typography>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Typography fontSize={10}>
              {AudienceCategory.B2C.id ===
              Number(audienceGroup?.['audience_categories']?.[0]?.audienceCategoryId)
                ? AudienceCategory.B2C.name
                : AudienceCategory.B2B.name}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginY: 2, borderColor: '#C7D6DF' }} />
        <Grid container gap={1}>
          {audienceGroup?.audiences?.map((audience, index) => {
            const nonZeroSegments = audience?.['audience_segments'].filter(
              (segment) => segment.percentage > 0
            );
            const audienceId = audience?.audience?.['audience_id'];
            return (
              <Grid item key={index}>
                <AttributeTable
                  label={audienceIdToName(Number(audienceId))}
                  attribute={audience?.audience}
                  audienceId={audienceId}
                  segments={nonZeroSegments}
                />
              </Grid>
            );
          })}
        </Grid>
        <Divider sx={{ marginTop: 2, borderColor: '#C7D6DF' }} />

        {/* audience description */}
        <Grid container sx={{ marginY: 2, flexWrap: { lg: 'nowrap' }, columnGap: 2 }}>
          <Grid item sx={{ textWrap: 'nowrap' }}>
            <Typography fontSize={11} fontWeight={700}>
              Other Criteria
            </Typography>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Box
              sx={{
                padding: 1.5,
                backgroundColor: '#F4F4F6',
                border: '1px solid #C7D6DF',
                borderRadius: '10px',
              }}>
              <Typography sx={{ fontSize: 10 }}>{audienceGroup?.audienceDescription}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box my={1}>
          <DeleteButton onClick={() => onDelete(audienceGroup?.id)} />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const SavedAudiences = ({ audiences, selectedAudienceId, onSelectSavedAudience, onDeleteSavedAudience }) => {
  return (
    <Box>
      <Header
        name={'Your Audiences'}
        description={'Which previously saved audience are you using?'}
        isRequired={true}
      />
      <Box mt={3} sx={{ borderRadius: '10px' }}>
        {audiences?.map((audience) => (
          <Box key={audience?.id} mb={2}>
            <AudienceAccordion
              audienceGroup={audience}
              isSelected={selectedAudienceId === audience?.id}
              onSelect={onSelectSavedAudience}
              onDelete={onDeleteSavedAudience}
            />
          </Box>
        ))}
      </Box>
      {/* Additional screeners */}
      <Box mt={5}>
        <CustomScreeners />
      </Box>
    </Box>
  );
};

export default SavedAudiences;
