import { Box } from '@mui/material';
import { DEVICE_NAME_TO_DEVICE_ID } from '../../../../modules/wevos/constants';
import { Header, SelectableTile } from '../../components';

function DeviceTypeSelector({ value, onChange }) {
  const handleDesktopSelected = () => {
    if (value !== DEVICE_NAME_TO_DEVICE_ID.desktop) {
      onChange && onChange({ deviceId: DEVICE_NAME_TO_DEVICE_ID.desktop });
    }
  };

  const handleMobileSelected = () => {
    if (value !== DEVICE_NAME_TO_DEVICE_ID.mobile) {
      onChange && onChange({ deviceId: DEVICE_NAME_TO_DEVICE_ID.mobile });
    }
  };
  return (
    <Box>
      <Header name="Device" description="Select the device of your experience design." isRequired={true} />
      <Box mb={4} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
        <SelectableTile
          label="Desktop"
          isSelected={value === DEVICE_NAME_TO_DEVICE_ID.desktop}
          onSelect={handleDesktopSelected}
          width={{ sx: '100%', sm: '100%', md: '49%' }}
          fontSize="16px"
        />
        <SelectableTile
          label="Mobile"
          isSelected={value === DEVICE_NAME_TO_DEVICE_ID.mobile}
          onSelect={handleMobileSelected}
          width={{ sx: '100%', sm: '100%', md: '49%' }}
          fontSize="16px"
        />
      </Box>
    </Box>
  );
}

export default DeviceTypeSelector;
