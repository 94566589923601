import styled from '@emotion/styled';
import { Box, Slider } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { IntakeTooltip } from '../../components';

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: '#3B6CAB', //color of the slider between thumbs
  height: '18px',
  borderRadius: '9px',
  '& .MuiSlider-thumb': {
    backgroundColor: '#fff', //color of thumbs
    border: '1px solid #3B6CAB',
    width: '32px',
    height: '32px',
  },
  '& .MuiSlider-rail': {
    height: '18px',
    borderRadius: '8px',
    background: '#C7D6DF',
  },
  '& .MuiSlider-mark': {
    display: 'none',
  },
  '& .MuiSlider-valueLabel': {
    background: '#3B6CAB',
    height: '38px',
    width: '64px',
    top: '-15px',
  },
  '& .MuiSlider-valueLabelLabel': {
    fontSize: '20px',
    fontWeight: 700,
  },
}));

function NumRespondentsSlider({
  value,
  marks,
  min = 10,
  max = 200,
  onChange,
  debounceMs = 500,
  disabled,
  isMastercardTestType,
}) {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    const invocation = debounce(() => onChange(internalValue), debounceMs);

    if (value !== internalValue) {
      // prevents unnecessary calls on mount
      invocation();
    }

    return () => invocation.cancel();
  }, [debounceMs, internalValue, onChange, value]);

  return (
    <IntakeTooltip
      title={disabled ? 'Flexible sample sizes are currently not supported for this study type.' : ''}>
      <Box>
        <CustomSlider
          valueLabelDisplay="on"
          min={min}
          max={max}
          step={null}
          value={internalValue}
          onChange={(ev, value) => setInternalValue(value)}
          marks={marks}
          disabled={disabled}
        />
      </Box>
    </IntakeTooltip>
  );
}

export { CustomSlider, NumRespondentsSlider };
